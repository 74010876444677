import { Box, Skeleton, Stack } from '@mui/material';

export const SummariesColumnSkeleton = () => {
	return (
		<Stack p={1.5} spacing={1.5}>
			<Stack direction="row" spacing={1} alignItems="center">
				<Skeleton variant="rounded" height={25} width={25} />
				<Skeleton variant="text" width={100} />
				<Box flex={1} />
				<Skeleton variant="circular" height={40} width={40} />
			</Stack>
			<Skeleton variant="rounded" height={50} />
		</Stack>
	);
};
