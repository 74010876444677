import { Link } from '@mui/material';
import { FolderReference } from 'middleware-types';
import { useCurrentFolderId } from '../hooks/use-current-folder-id';
import { useDocumentSearchContext } from './document-search-provider';
import { useVersionHistoryContext } from './version-history-provider';

interface FolderLinkProps {
	folder: FolderReference | undefined;
}

export const FolderLink = ({ folder }: FolderLinkProps) => {
	const [, setCurrentFolderId] = useCurrentFolderId();
	const { isVersionHistory } = useVersionHistoryContext();
	const { isSearchActive } = useDocumentSearchContext();

	if (!folder || !isSearchActive || isVersionHistory) return <></>;

	return (
		<Link
			component="button"
			variant="caption"
			color="GrayText"
			maxWidth="100%"
			noWrap
			onClick={(e) => {
				e.stopPropagation();
				setCurrentFolderId(folder.id);
			}}>
			{folder.name}
		</Link>
	);
};
