import { SearchOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment, OutlinedInput, OutlinedInputProps } from '@mui/material';
import { useState } from 'react';

/**
 * Search bar props.
 */
interface SearchBarProps extends Omit<OutlinedInputProps, 'onChange'> {
	onChange?: (s: string) => void;
	countFilters?: () => number;
}

/**
 * Renders search bar. For pages where search is the primary function, set collapsible={false} for a search bar
 * does not toggle. For pages where search is a secondary function, set collapsible={true} for a search bar that
 * starts as collapsed and can be toggled open/close. Can set dropdown and/or filter information if required.
 * @param props
 * @returns
 */
export const Search = (props: SearchBarProps) => {
	const [searchText, setSearchText] = useState<string>((props.value as string | undefined) ?? '');

	/**
	 * Searches on text. Toggles searc bar closed if there is no text.
	 *
	 * @param {string} text
	 */
	const search = (text: string) => {
		if (props.onChange) {
			props.onChange(text);
		}
	};

	/**
	 * Searches when the search icon is clicked.
	 *
	 */
	const searchClick = () => {
		search(searchText);
	};

	/**
	 * Searches when 'Enter' is pressed.
	 *
	 * @param {*} e
	 */
	const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (e.key === 'Enter') {
			search(searchText);
		}
	};

	return (
		<>
			<OutlinedInput
				sx={{
					backgroundColor: 'background.paper',
				}}
				{...props}
				size="small"
				value={searchText}
				onChange={(e) => setSearchText(e.target.value)}
				onKeyDown={onKeyDown}
				disabled={props.disabled}
				placeholder={props.placeholder}
				startAdornment={
					<InputAdornment position="start">
						<IconButton
							disableRipple
							disabled={props.disabled}
							edge="start"
							sx={{
								padding: 0,
								marginLeft: '-0.5rem',
							}}
							onClick={() => searchClick()}>
							<SearchOutlined fontSize="small" />
						</IconButton>
					</InputAdornment>
				}
			/>
		</>
	);
};
