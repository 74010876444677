import { AddOutlined, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	IconButton,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { Alert } from 'components/ui/alert';
import { Formik, FormikProps } from 'formik';
import { AccountEmergencyContactUpdate } from 'middleware-types';
import EmergencyContactEdit from './emergency-contact-edit';
import useEmergencyContactForm from './emergency-contact-hooks';
import EmergencyContactRead from './emergency-contact-read';

export type EmergencyContactProps = { userId: string };

/**
 * EmergencyContactForm is the account settings Emergency Contact card
 *
 * @param {{ userId: string }} { userId }
 * @return {*}  {React.JSX.Element}
 */
const EmergencyContact = ({ userId }: EmergencyContactProps): React.JSX.Element => {
	const {
		initialValues,
		onSubmit,
		isEditing,
		toggleEdit,
		emergencyContact,
		validationSchema,
		onDelete,
		error,
		loading,
		canEdit,
	} = useEmergencyContactForm(userId);

	return (
		<Formik<AccountEmergencyContactUpdate>
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
			enableReinitialize>
			{(props: FormikProps<AccountEmergencyContactUpdate>) => (
				<Card>
					<CardHeader
						title={
							<Typography variant="h2" height="2rem">
								Emergency Contact
							</Typography>
						}
						action={
							isEditing ? (
								<Stack direction="row" spacing={1}>
									<Button
										variant="outlined"
										onClick={() => {
											props.resetForm();
											toggleEdit();
										}}>
										Cancel
									</Button>
									<LoadingButton
										color="primary"
										variant="contained"
										loading={props.isSubmitting}
										onClick={props.submitForm}
										disabled={
											!props.isValid || !props.dirty || props.isSubmitting
										}>
										Save
									</LoadingButton>
								</Stack>
							) : (
								!loading &&
								canEdit && (
									<>
										{emergencyContact && (
											<IconButton onClick={onDelete} data-test="delete-btn">
												<DeleteOutline />
											</IconButton>
										)}
										<IconButton
											edge="end"
											onClick={toggleEdit}
											data-test="edit-add-btn">
											{emergencyContact ? <EditOutlined /> : <AddOutlined />}
										</IconButton>
									</>
								)
							)
						}
					/>
					<Divider />
					<CardContent>
						<Box pt={1}>
							{isEditing && <Alert error={error} />}
							{loading ? (
								<EmergencyContactSkeleton />
							) : isEditing ? (
								<EmergencyContactEdit />
							) : (
								<EmergencyContactRead emergencyContact={emergencyContact} />
							)}
						</Box>
					</CardContent>
				</Card>
			)}
		</Formik>
	);
};

/**
 * EmergencyContactSkeleton - Loading Skeleton for emergency contact section.
 *
 * @return {*}
 */
const EmergencyContactSkeleton = () => (
	<Grid container alignItems="stretch">
		<Grid item sm={4} md={4}>
			<Skeleton height="10rem" width="10rem" sx={{ margin: 'auto' }} variant="circular" />
			<Stack display="flex" alignItems="center">
				<Skeleton height="2.5rem" width="70%" />
				<Skeleton height="2.5rem" width="50%" />
			</Stack>
		</Grid>
		<Grid item sm={8} md={6}>
			<Stack display="flex" alignItems="center">
				<Skeleton height="2.5rem" width="100%" />
				<Skeleton height="2.5rem" width="100%" />
				<Skeleton height="2.5rem" width="100%" />
			</Stack>
		</Grid>
	</Grid>
);

export default EmergencyContact;
