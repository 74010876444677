import { Box } from '@mui/material';
import { OrgNavigation } from 'components/pages/org/org-nav';
import { DelayedLoading } from 'components/ui/delayed-loading';
import { useFindIdFromHandleType } from 'components/ui/handle';
import { SideRail } from 'components/ui/side-rail/side-rail';
import { EntitySearchType } from 'middleware-types';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useIsMobile } from 'utils/useScreenSize';

const OrgOutlet = () => {
	const { id, loading } = useFindIdFromHandleType(EntitySearchType.Organization);
	const { user } = useSession();
	const isMobile = useIsMobile();
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(id);
	const showOrgNav = hasAssociateUserPermission(Permission.Org_Member) || user.siteUserId;

	if (loading) return <DelayedLoading />;

	return (
		<>
			{id !== undefined && showOrgNav && <OrgNavigation orgId={id} />}
			<Box flex={1} overflow="auto">
				{!isMobile && !user.siteUserId ? (
					<SideRail>
						<Outlet context={id satisfies OrgOutletContext} />
					</SideRail>
				) : (
					<Outlet context={id satisfies OrgOutletContext} />
				)}
			</Box>
		</>
	);
};

export type OrgOutletContext = string;

export const useOrgId = () => useOutletContext<OrgOutletContext>();

export default OrgOutlet;
