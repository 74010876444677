import {
	useAssociateUserNavigationItems,
	useSiteUserNavigationItems,
} from 'components/pages/org/org-nav';
import { useOrgId } from 'components/pages/org/outlet';
import { Navigate } from 'react-router-dom';
import { PageError } from 'utils/errors';
import { useSession } from 'utils/session';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';

export const OrganizationRedirect = () => {
	const orgId = useOrgId();
	const { user } = useSession();
	const associateUserNavigationItems = useAssociateUserNavigationItems(orgId);
	const siteUserNavigationItems = useSiteUserNavigationItems();

	const { hasPermission: hasAssociateUserPermission, loading: associateUserLoading } =
		useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission, loading: siteUserLoading } = useSiteUser();

	const isSiteUser = !!user.siteUserId;
	const navItems = isSiteUser ? siteUserNavigationItems : associateUserNavigationItems;
	const hasPermission = isSiteUser ? hasSiteUserPermission : hasAssociateUserPermission;
	const loading = isSiteUser ? siteUserLoading : associateUserLoading;

	if (loading) return null;

	const navItem = navItems.find((item) => hasPermission(item.access ?? []));

	if (navItem !== undefined) return <Navigate to={navItem.link} replace />;
	throw new PageError('Invalid Organization Permissions');
};
