import { gql, useMutation } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	Mutation,
	MutationConversationAddParticipantArgs,
	MutationConversationRemoveParticipantArgs,
	Participant,
	ParticipantEntityType,
	ParticipantHistoryType,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useCommunicationsContext } from '../communications-provider';
import { CONVERSATION_FIELDS } from '../fragments.graphql';
import { CONVERSATION_SEARCH } from './conversation-search-hooks';

/**
 * hook to add participants
 */
const ADD_PARTICIPANT = gql`
	${CONVERSATION_FIELDS}
	mutation AddParticipant(
		$entityType: ConversationApiEntityType!
		$entityId: String!
		$conversationId: String!
		$request: NewParticipantOptions!
	) {
		conversationAddParticipant(
			entityType: $entityType
			entityId: $entityId
			conversationId: $conversationId
			request: $request
		) {
			conversation {
				...ConversationFields
			}
		}
	}
`;

export const useAddParticipants = () => {
	const toast = useToast();
	const {
		entityType,
		entityId,
		selectedConversationId: conversationId,
	} = useCommunicationsContext();

	const [addParticipant] = useMutation<
		Pick<Mutation, 'conversationAddParticipant'>,
		MutationConversationAddParticipantArgs
	>(ADD_PARTICIPANT, { refetchQueries: [CONVERSATION_SEARCH] });

	const addParticipants = async (
		participants: { entityId: string; entityType: ParticipantEntityType }[],
		historyType: ParticipantHistoryType,
		historyShareFromUtc: Date | null
	) => {
		if (conversationId === null) return false;

		let anyFailed = false;
		for (const participant of participants) {
			await addParticipant({
				variables: {
					entityType,
					entityId,
					conversationId,
					request: { ...participant, historyType, historyShareFromUtc },
				},
			})
				.then((res) => {
					if (responseHasErrors(res.errors, { toast })) {
						anyFailed = true;
					}
				})
				.catch(() => {
					handleNoResponse({ toast });
					anyFailed = true;
				});
		}

		return !anyFailed;
	};

	return addParticipants;
};

/**
 * hook to remove participants
 */
const REMOVE_PARTICIPANT = gql`
	${CONVERSATION_FIELDS}
	mutation RemoveParticipant(
		$entityType: ConversationApiEntityType!
		$entityId: String!
		$conversationId: String!
		$request: Participant!
	) {
		conversationRemoveParticipant(
			entityType: $entityType
			entityId: $entityId
			conversationId: $conversationId
			request: $request
		) {
			conversation {
				...ConversationFields
			}
		}
	}
`;

export const useRemoveParticipant = () => {
	const toast = useToast();
	const {
		entityType,
		entityId,
		selectedConversationId: conversationId,
	} = useCommunicationsContext();

	const [_removeParticipant, { loading }] = useMutation<
		Pick<Mutation, 'conversationRemoveParticipant'>,
		MutationConversationRemoveParticipantArgs
	>(REMOVE_PARTICIPANT, { refetchQueries: [CONVERSATION_SEARCH] });

	const removeParticipant = async (request: Participant) => {
		if (conversationId === null) return false;
		return await _removeParticipant({
			variables: { entityType, entityId, conversationId, request },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { removeParticipant, loading };
};
