import { Checkbox, FormControlLabel } from '@mui/material';
import { useFormikContext } from 'formik';
import { AllowedTransports, NotificationSettingDefault } from 'middleware-types';
import { SettingFormValues, SettingsFormValues } from './notifications';

interface GroupCheckboxProps {
	label: string;
	columnName: keyof SettingFormValues;
	allowedTransportType: AllowedTransports;
	items: NotificationSettingDefault[];
}

export const GroupCheckbox = ({
	label,
	columnName,
	allowedTransportType,
	items: _items,
}: GroupCheckboxProps) => {
	const items = _items.filter(
		(item) =>
			item.allowedTransportsList.includes(AllowedTransports.All) ||
			item.allowedTransportsList.includes(allowedTransportType)
	);
	const { values, setFieldValue } = useFormikContext<SettingsFormValues>();
	const checkboxValues = items.map((item) => values[item.notificationType][columnName]);

	const checked = checkboxValues.some((v) => v);
	const indeterminate = checked && !checkboxValues.every((v) => v);
	const disabled = checkboxValues.length === 0;

	const onChange = async (_e, checked: boolean) => {
		for (const item of items) {
			await setFieldValue(`${item.notificationType}.${columnName}`, checked);
		}
	};

	return (
		<FormControlLabel
			sx={{ m: 0 }}
			label={label}
			labelPlacement="top"
			slotProps={{ typography: { variant: 'caption' } }}
			control={
				<Checkbox
					checked={checked}
					indeterminate={indeterminate}
					disabled={disabled}
					onChange={onChange}
				/>
			}
		/>
	);
};
