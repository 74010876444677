import { useMutation } from '@apollo/client';
import {
	AccountCircleOutlined,
	DeleteOutlined,
	ErrorOutlined,
	ForumOutlined,
	HistoryOutlined,
	MoreHorizOutlined,
	PowerSettingsNewOutlined,
	SendOutlined,
} from '@mui/icons-material';
import { Badge, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { DeactivateIcon, MaskIcon } from 'components/ui/icons';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	useModal,
} from 'components/ui/modal';
import { useToast } from 'components/ui/toast';
import gql from 'graphql-tag';
import {
	Mutation,
	MutationUserAccountActivationUpdateArgs,
	MutationUserSiteUserInvitationDeleteArgs,
	MutationUserSiteUserInvitationResendArgs,
} from 'middleware-types';
import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Permission } from 'utils/permissions';
import { assume, useSession } from 'utils/session';
import { useSiteUser } from 'utils/useSiteUser';

/* Mutation for deleting an invitation to a site user. */
export const DELETE_INVITATION = gql`
	mutation UserSiteUserInvitationDelete($invitationId: String!) {
		userSiteUserInvitationDelete(invitationId: $invitationId)
	}
`;

/* Mutation for resending an invitation to a site user. */
export const RESEND_INVITATION = gql`
	mutation UserSiteUserInvitationResend($invitationId: String!) {
		userSiteUserInvitationResend(invitationId: $invitationId)
	}
`;

/* Mutation for activating/deactivating a user account. */
export const UPDATE_USERACCOUNT_ACTIVATION = gql`
	mutation UserAccountActivationUpdate($userId: ID!, $operation: ActivationOperation!) {
		userAccountActivationUpdate(userId: $userId, operation: $operation) {
			id
			emailAddress
			handle
			deactivated
		}
	}
`;

/**
 * Props for the context menu.
 *
 * @interface ContextMenuProps
 */
interface PeopleContextMenuProps {
	name: string;
	id: string | undefined | null;
	siteUserId?: string | undefined | null;
	siteUserInvitationId?: string | undefined | null;
	refetch: () => void;
	status: Status;
	handle: string | undefined | null;
}

/**
 * The activation Operation (Activate / Deactivate).
 *
 * @enum {number}
 */
enum ActivationOperation {
	Deactivate = 'Deactivate',
	Activate = 'Activate',
}

/**
 * The Row Action.
 *
 * @enum {number}
 */
enum RowAction {
	DeleteInvitation,
	ActivateSiteUser,
	ActivateSocialUser,
	DeactivateSiteUser,
	DeactivateSocialUser,
	AssumeIdentity,
	ResendInvitation,
}

/**
 * The user status.
 *
 * @enum {number}
 */
enum Status {
	Invited = 'Invited',
	Active = 'Active',
	Inactive = 'Inactive',
	SocialActive = 'SocialActive',
	SocialInactive = 'SocialInactive',
}

/**
 * useAssumeIdentityConfirmation() is a custom hook for opening up a confirmation modal
 * that confirms assuming an identity.  This is accessed in both the profile and people pages.
 *
 * @param {(string | undefined)} siteUserId
 * @param {(string | undefined)} name
 * @returns {(() => void)}
 */
export const useAssumeIdentityConfirmation = (
	id: string | undefined | null,
	siteUserId: string | undefined | null,
	name: string | undefined
): (() => void) => {
	const { showModal } = useModal();

	const assumeConfirm = () => {
		if (siteUserId || !id) return;

		showModal({
			title: 'Assuming Identity',
			content: (
				<ConfirmModalContent
					visual={
						<Badge
							color="warning"
							overlap="circular"
							sx={{
								'& .MuiBadge-badge': {
									height: '2.25rem',
									borderRadius: '50%',
								},
							}}
							badgeContent={
								<MaskIcon
									sx={{
										padding: '4px 0 0 4px',
									}}
								/>
							}
							anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
							<UserEmblemAvatar id={id} size={160} />
						</Badge>
					}
					subheadline={`Assume ${name}?`}
					informativeContent={`While assuming ${name}'s identity, you are responsible for all actions taken.`}
				/>
			),
			actions: (
				<ModalActions>
					<ModalActionButton size="large" variant="outlined">
						Cancel
					</ModalActionButton>
					<ModalActionButton
						size="large"
						variant="contained"
						color="primary"
						onClick={() => assume(id)}>
						Assume Identity
					</ModalActionButton>
				</ModalActions>
			),
		});
	};
	return assumeConfirm;
};

/**
 * Renders the context menu for site user rows.
 * @param props
 * @returns
 */
export const PeopleContextMenu = (props: PeopleContextMenuProps): React.JSX.Element => {
	const assume = useAssumeIdentityConfirmation(props.id, props?.siteUserId, props?.name);
	const { user } = useSession();
	const { hasPermission } = useSiteUser();
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void =>
		setAnchorEl(event.currentTarget);
	const { showModal } = useModal();
	const toast = useToast();
	const [resendInvitation] = useMutation<
		Pick<Mutation, 'userSiteUserInvitationResend'>,
		MutationUserSiteUserInvitationResendArgs
	>(RESEND_INVITATION, {
		onCompleted: () => props.refetch(),
	});
	const [deleteInvitation] = useMutation<
		Pick<Mutation, 'userSiteUserInvitationDelete'>,
		MutationUserSiteUserInvitationDeleteArgs
	>(DELETE_INVITATION, {
		onCompleted: () => props.refetch(),
	});
	const [updateUserActivation] = useMutation<
		Pick<Mutation, 'userAccountActivationUpdate'>,
		MutationUserAccountActivationUpdateArgs
	>(UPDATE_USERACCOUNT_ACTIVATION, {
		onCompleted: () => props.refetch(),
		update: (cache) => {
			cache.evict({
				id: `Emblem:${props.id}`,
			});
		},
	});

	const onAction = (action: RowAction): void => {
		switch (action) {
			case RowAction.AssumeIdentity:
				assume();
				break;
			case RowAction.ResendInvitation: {
				if (!props.siteUserInvitationId) break;
				resendInvitation({
					variables: { invitationId: props.siteUserInvitationId },
				})
					.then(() =>
						toast.push(
							`${props.name}'s Site User invitation was successfully resent.`,
							{ variant: 'success' }
						)
					)
					.catch((err) => {
						toast.push(
							`An error occurred. Please try again later or contact Support.`,
							{ variant: 'error' }
						);
						console.log(err);
					});
				break;
			}
			case RowAction.DeleteInvitation: {
				showModal({
					title: 'Delete Invitation',
					content: (
						<ConfirmModalContent
							visual={
								<EmptyStateAvatar
									avatarProps={{ bgcolor: 'error.50' }}
									iconProps={{ color: 'error.500' }}
									icon={<ErrorOutlined />}
								/>
							}
							subheadline="Are you sure?"
							informativeContent={`Do you really want to delete ${props.name}'s invitation? This process cannot be undone.`}
						/>
					),
					actions: (
						<ModalActions>
							<ModalActionButton size="large" variant="outlined">
								Cancel
							</ModalActionButton>
							<ModalActionButton
								size="large"
								variant="contained"
								color="error"
								onClick={() => {
									if (!props.siteUserInvitationId) return;
									return deleteInvitation({
										variables: { invitationId: props.siteUserInvitationId },
									})
										.then(() =>
											toast.push(`${props.name} was successfully deleted.`, {
												variant: 'success',
											})
										)
										.catch((err) => {
											toast.push(
												`An error occurred. Please try again later or contact Support.`,
												{ variant: 'error' }
											);
											console.log(err);
										});
								}}>
								Delete Invitation
							</ModalActionButton>
						</ModalActions>
					),
				});
				break;
			}
			case RowAction.ActivateSiteUser:
			case RowAction.DeactivateSiteUser: {
				const verb = action === RowAction.ActivateSiteUser ? 'Activate' : 'Deactivate';
				const verbed = action === RowAction.ActivateSiteUser ? 'activated' : 'deactivated';
				const inverseVerb =
					action === RowAction.ActivateSiteUser ? 'Deactivate' : 'Reactivate';
				const btnColor = action === RowAction.ActivateSiteUser ? 'primary' : 'error';
				const modalVisual =
					verb === 'Activate' ? (
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'primary.50' }}
							iconProps={{ color: 'primary.500' }}
							icon={<PowerSettingsNewOutlined />}
						/>
					) : (
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'error.50' }}
							iconProps={{ color: 'error.500' }}
							icon={<ErrorOutlined />}
						/>
					);
				showModal({
					title: `${verb} User`,
					content: (
						<ConfirmModalContent
							visual={modalVisual}
							subheadline="Are you sure?"
							informativeContent={`Do you really want to ${verb.toLowerCase()} ${
								props.name
							}'s site user account? You can ${inverseVerb.toLowerCase()} this user anytime.`}
						/>
					),
					actions: (
						<ModalActions>
							<ModalActionButton size="large" variant="outlined">
								Cancel
							</ModalActionButton>
							<ModalActionButton
								size="large"
								variant="contained"
								color={btnColor}
								onClick={() => {
									if (!props.id) return;
									return updateUserActivation({
										variables: {
											userId: props.id,
											operation:
												action === RowAction.ActivateSiteUser
													? ActivationOperation.Activate
													: ActivationOperation.Deactivate,
										},
									})
										.then(() => {
											toast.push(
												`${props.name} was successfully ${verbed}.`,
												{
													variant: 'success',
												}
											);
										})
										.catch((err) => {
											toast.push(
												`An error occurred. Please try again later or contact Support.`,
												{ variant: 'error' }
											);
											console.log(err);
										});
								}}>
								{`${verb} User`}
							</ModalActionButton>
						</ModalActions>
					),
				});
				break;
			}
			case RowAction.ActivateSocialUser:
			case RowAction.DeactivateSocialUser: {
				const verb = action === RowAction.ActivateSocialUser ? 'Activate' : 'Deactivate';
				const verbed =
					action === RowAction.ActivateSocialUser ? 'activated' : 'deactivated';
				const inverseVerb =
					action === RowAction.ActivateSocialUser ? 'Deactivate' : 'Reactivate';
				const btnColor = action === RowAction.ActivateSocialUser ? 'primary' : 'error';
				const modalVisual =
					verb === 'Activate' ? (
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'primary.50' }}
							iconProps={{ color: 'primary.500' }}
							icon={<PowerSettingsNewOutlined />}
						/>
					) : (
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'error.50' }}
							iconProps={{ color: 'error.500' }}
							icon={<ErrorOutlined />}
						/>
					);
				showModal({
					title: `${verb} User`,
					content: (
						<ConfirmModalContent
							visual={modalVisual}
							subheadline="Are you sure?"
							informativeContent={`Do you really want to ${verb.toLowerCase()} ${
								props.name
							}'s standard user account? You can ${inverseVerb.toLowerCase()} this user anytime.`}
						/>
					),
					actions: (
						<ModalActions>
							<ModalActionButton size="large" variant="outlined">
								Cancel
							</ModalActionButton>
							<ModalActionButton
								size="large"
								variant="contained"
								color={btnColor}
								onClick={() => {
									if (!props.id) return;
									return updateUserActivation({
										variables: {
											userId: props.id,
											operation:
												action === RowAction.ActivateSocialUser
													? ActivationOperation.Activate
													: ActivationOperation.Deactivate,
										},
									})
										.then(() => {
											toast.push(
												`${props.name} was successfully ${verbed}.`,
												{
													variant: 'success',
												}
											);
										})
										.catch((err) => {
											toast.push(
												`An error occurred. Please try again later or contact Support.`,
												{ variant: 'error' }
											);
											console.log(err);
										});
								}}>
								{`${verb} User`}
							</ModalActionButton>
						</ModalActions>
					),
				});
				break;
			}
		}
	};

	const handleRowAction = (action: RowAction): void => {
		setAnchorEl(null);
		if (onAction) {
			onAction(action);
		}
	};

	/**
	 * The actions possible for a context menu.
	 *  @type {*} */
	const tableActions = [
		{
			status: ['Invited'],
			permissions: [Permission.Site_Inv_D],
			action: RowAction.DeleteInvitation,
			text: 'Delete Invitation',
			icon: <DeleteOutlined />,
		},
		{
			status: ['Invited'],
			permissions: [Permission.Site_Inv_C],
			action: RowAction.ResendInvitation,
			text: 'Resend Invitation',
			icon: <SendOutlined />,
		},
		{
			status: ['Active'],
			disabled: props.siteUserId === user.siteUserId,
			permissions: [Permission.Site_User_U],
			action: RowAction.DeactivateSiteUser,
			text: 'Deactivate User',
			icon: <DeactivateIcon />,
		},
		{
			status: ['Inactive'],
			permissions: [Permission.Site_User_U],
			action: RowAction.ActivateSiteUser,
			text: 'Activate User',
			icon: <PowerSettingsNewOutlined />,
		},
		{
			status: ['SocialActive'],
			disabled:
				props.id === user?.userId ||
				user?.siteUserId === null ||
				user?.siteUserId === undefined,
			permissions: [Permission.SocialUser_Account_U],
			action: RowAction.DeactivateSocialUser,
			text: 'Deactivate User',
			icon: <DeactivateIcon />,
		},
		{
			status: ['SocialInactive'],
			disabled:
				props.id === user?.userId ||
				user?.siteUserId === null ||
				user?.siteUserId === undefined,
			permissions: [Permission.SocialUser_Account_U],
			action: RowAction.ActivateSocialUser,
			text: 'Activate User',
			icon: <PowerSettingsNewOutlined />,
		},
		{
			status: ['SocialActive'],
			disabled:
				props.id === user?.userId ||
				user?.siteUserId === null ||
				user?.siteUserId === undefined,
			permissions: [Permission.SocialUser_Assume_R],
			action: RowAction.AssumeIdentity,
			text: 'Assume Identity',
			icon: <MaskIcon />,
		},
		{
			status: ['SocialActive'],
			permissions: [Permission.SocialUser_Profile_R],
			link: `/${props.handle}`,
			text: 'View Profile',
			icon: <AccountCircleOutlined />,
		},
		{
			status: ['Active', 'Inactive', 'SocialActive', 'SocialInactive'],
			link: `/app/history/${props.id}`,
			text: 'History',
			icon: <HistoryOutlined />,
		},
		{
			status: ['SocialActive'],
			permissions: Permission.SocialUser_EvoX_R,
			link: `/site/evox/user/${props.id}`,
			text: 'View EVO-X Conversations',
			icon: <ForumOutlined />,
		},
	];

	let menu = tableActions
		.filter((ta) => hasPermission(ta.permissions ?? []) && ta.status.includes(props.status))
		.map((ta, k) => {
			if (ta.link) {
				return (
					<MenuItem key={k} component={Link} to={ta.link}>
						<ListItemIcon>{ta.icon}</ListItemIcon>
						<ListItemText>{ta.text}</ListItemText>
					</MenuItem>
				);
			}

			return (
				<MenuItem
					key={k}
					onClick={
						ta.action !== undefined ? (): void => handleRowAction(ta.action) : undefined
					}
					disabled={ta.disabled}>
					<ListItemIcon>{ta.icon}</ListItemIcon>
					<ListItemText>{ta.text}</ListItemText>
				</MenuItem>
			);
		});

	return (
		<>
			{menu.length > 0 && (
				<IconButton
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					onClick={handleClick}>
					<MoreHorizOutlined />
				</IconButton>
			)}
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={(): void => setAnchorEl(null)}>
				{menu}
			</Menu>
		</>
	);
};
