import {
	BusinessOutlined,
	CampaignOutlined,
	ForumOutlined,
	InsertDriveFileOutlined,
	PeopleAltOutlined,
} from '@mui/icons-material';
import { Box, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { LinkCard } from 'components/pages/dashboard/linkCard';
import { useOrgId } from 'components/pages/org/outlet';
import { useEmblem } from 'components/ui/emblem/hooks';
import { EmblemEntityType, OrgDashboardConfigurationResponse } from 'middleware-types';
import { Fragment } from 'react';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';
import { useOrgAccountGetConversationInsurance } from '../account/conversationInsurance/hooks';
import { useOrgOrDescendentIsLinked } from '../account/hierarchy/hooks';

interface CardItem {
	link: string;
	text: string;
	icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>>;
	access: Permission | Permission[] | undefined;
	all?: boolean;
}

const getAssociateCardItems = (isPrivate: boolean | undefined): CardItem[] => {
	return [
		{
			link: '../profile',
			text: 'Profile',
			icon: BusinessOutlined,
			access: isPrivate ? Permission.Org_Profile_U : undefined,
			all: isPrivate,
		},
		{
			link: '../network/connections',
			text: 'Network',
			icon: PeopleAltOutlined,
			access: [Permission.Org_Social_Network_R],
		},
		{
			link: '../conversations',
			text: 'Conversations',
			icon: ForumOutlined,
			access: [Permission.Org_Messages_R],
		},
		{
			link: '../documents',
			text: 'Documents',
			icon: InsertDriveFileOutlined,
			access: Permission.Org_Member,
		},
		{
			link: '../associate-notifications',
			text: 'Announcements',
			icon: CampaignOutlined,
			access: Permission.Org_Notification_R,
		},
	];
};

const getSiteUserCardItems = (isPrivate: boolean | undefined): CardItem[] => {
	return [
		{
			link: '../profile',
			text: 'Profile',
			icon: BusinessOutlined,
			access: isPrivate
				? [Permission.Site_OrgProfile_R, Permission.Site_OrgProfile_U]
				: [Permission.Site_OrgProfile_R],
			all: isPrivate,
		},
		{
			link: '../network/connections',
			text: 'Network',
			icon: PeopleAltOutlined,
			access: [Permission.Site_OrgNetwork_R],
		},
		{
			link: '../conversations',
			text: 'Conversations',
			icon: PeopleAltOutlined,
			access: [Permission.Site_OrgMsgs_R],
		},
		{
			link: '../documents',
			text: 'Documents',
			icon: InsertDriveFileOutlined,
			access: [Permission.Site_OrgFile_R, Permission.Site_OrgFile_Sens_R],
		},
		{
			link: '../associate-notifications',
			text: 'Announcements',
			icon: CampaignOutlined,
			access: Permission.Site_OrgNotification_R,
		},
	];
};

interface OrgLinkCardsProps {
	settings?: OrgDashboardConfigurationResponse;
}

export const OrgLinkCards = ({ settings }: OrgLinkCardsProps) => {
	const orgId = useOrgId();
	const { user } = useSession();

	const { conversationInsurance } = useOrgAccountGetConversationInsurance(orgId);
	const { orgOrDescendentIsLinked } = useOrgOrDescendentIsLinked(orgId);

	const { hasPermission: hasAssociateUserPermission, loading: associateUserLoading } =
		useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission, loading: siteUserLoading } = useSiteUser();
	const { emblem, loading: emblemLoading } = useEmblem(orgId, EmblemEntityType.Organization);

	const isSiteUser = !!user.siteUserId;
	const userLoading = (isSiteUser && siteUserLoading) || (!isSiteUser && associateUserLoading);

	if (userLoading || emblemLoading) return <></>;

	const items = isSiteUser
		? getSiteUserCardItems(emblem?.private)
		: getAssociateCardItems(emblem?.private);
	const hasPermission = isSiteUser ? hasSiteUserPermission : hasAssociateUserPermission;

	return (
		<Box
			display="grid"
			gridTemplateColumns={{
				xs: 'repeat(2, 1fr)',
				md: 'repeat(3, 1fr)',
				lg: 'repeat(4, 1fr)',
			}}
			gridAutoRows="1fr"
			gap={2}>
			{orgOrDescendentIsLinked && (
				<LinkCard
					Icon={'/img/FT-logo.svg'}
					text="FileTrac"
					accentColor={settings?.secondaryColor}
					link="../../../app/legacy/linked-companies"
				/>
			)}
			{conversationInsurance?.conversationInsuranceUrl && (
				<LinkCard
					Icon="/img/conversation-insurance-color-logo.svg"
					text="Conversation Insurance"
					accentColor={settings?.secondaryColor}
					link={conversationInsurance?.conversationInsuranceUrl}
					externalLink
				/>
			)}
			{items.map((item) => {
				const showCard = hasPermission(item.access ?? [], { all: item.all });
				if (!showCard) return <Fragment key={item.text}></Fragment>;
				return (
					<LinkCard
						key={item.text}
						Icon={item.icon}
						text={item.text}
						accentColor={settings?.secondaryColor}
						link={item.link}
					/>
				);
			})}
		</Box>
	);
};
