import { gql } from '@apollo/client';
import { InputBaseComponentProps, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { useField, useFormikContext } from 'formik';
import { parseDigit } from 'input-format';
import ReactInput from 'input-format/react';
import parsePhoneNumberFromString, { AsYouType, isPossiblePhoneNumber } from 'libphonenumber-js';
import { PhoneNumber, PhoneNumberInput } from 'middleware-types';
import React, { useCallback, useState } from 'react';

export const PHONE_FIELDS = gql`
	fragment PhoneFields on PhoneNumber {
		countryCode
		number
	}
`;

const PhoneInputCustom = React.forwardRef<HTMLInputElement, InputBaseComponentProps>(
	(props, ref) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { onChange, onKeyDown, customOnChange, ...rest } = props;
		const refObj = { ref };
		return (
			<ReactInput
				{...rest}
				// The type declarations file for this package is incorrect and does not allow a ref to be passed in,
				// even though it should be allowed.
				// Passing the ref as a spread object instead is allowed.
				{...refObj}
				onChange={customOnChange}
				parse={(character, value) => {
					if (character === '+' && !value) return character;
					if (value.length >= 15) return '';
					return parseDigit(character);
				}}
				format={(value) => {
					if (value === undefined) return { text: '', template: '' };
					try {
						const asYouType = new AsYouType('US');
						const text = asYouType.input(value);
						return {
							text,
							template: asYouType.getTemplate(),
						};
					} catch (error: any /*ParseError*/) {
						if (error.message.indexOf('Unknown country') === 0) {
							return { text: value, template: value.replace(/./g, 'x') };
						}
						throw error;
					}
				}}
			/>
		);
	}
);
PhoneInputCustom.displayName = 'PhoneInputCustom';

/**
 * PhoneNumberField is a phone number input with a country code.
 *
 * @param {PhoneNumberFieldProps} props
 * @returns
 */

export const PhoneNumberField = (
	props: PhoneNumberFieldProps & {
		InputLabelProps?: any;
		InputProps?: any;
		FormHelperTextProps?: any;
	}
) => {
	const { isSubmitting } = useFormikContext();
	const [field, meta] = useField<Partial<PhoneNumberInput>>({
		name: props.name,
		onReset: () => reset(),
		validate: (value: Partial<PhoneNumberInput>) => {
			if (
				(value?.number ||
					value?.countryCode ||
					(phoneNumber.length < 9 && phoneNumber.length > 0)) &&
				!isPossiblePhoneNumber(`+${value?.countryCode}${value?.number}`)
			) {
				return 'Invalid Phone Number';
			}
			if (props.required === true && (!value?.number || !value?.countryCode)) {
				return 'Required Field';
			}
		},
	});
	const [phoneNumber, setPhoneNumber] = useState<string>(
		meta.initialValue?.number
			? `+${meta.initialValue.countryCode}${meta.initialValue.number}`
			: ''
	);

	const reset = useCallback(() => {
		setPhoneNumber(
			meta.initialValue?.number
				? `+${meta.initialValue.countryCode}${meta.initialValue.number}`
				: ''
		);
	}, [meta.initialValue]);

	const onChange = useCallback(
		(value: string) => {
			setPhoneNumber(value);

			try {
				const asYouType = new AsYouType('US');
				asYouType.input(value);
				const num = asYouType.getNumber();
				field.onChange({
					target: {
						name: field.name,
						value: {
							countryCode: num?.countryCallingCode ?? '',
							number: num?.nationalNumber ?? '',
						},
					},
				});
			} catch (error: any /*ParseError*/) {
				if (error.message.indexOf('Unknown country') !== 0) {
					throw error;
				}
			}
		},
		[field, setPhoneNumber]
	);

	return (
		<TextField
			name={props.name}
			label={props.label}
			value={phoneNumber}
			error={meta.touched && !!meta.error}
			required={props.required}
			onBlur={field.onBlur}
			disabled={props.disabled || isSubmitting}
			helperText={meta.touched ? meta.error : undefined}
			FormHelperTextProps={props.FormHelperTextProps}
			inputProps={{
				customOnChange: onChange,
			}}
			InputProps={{
				inputComponent: PhoneInputCustom,
				...props.InputProps,
			}}
			InputLabelProps={props.InputLabelProps}
			sx={props.sx}
		/>
	);
};

type PhoneNumberFieldProps = {
	name: string;
	label: string | undefined;
	disabled?: boolean;
	required?: boolean;
	sx?: SxProps;
};

/**
 * Renders out a phone number using the country specific number format.
 *
 * @param {{ number: PhoneNumber}} props
 * @return {*}
 */
export const PhoneNumberText = (props: { number: PhoneNumber }) => {
	let parsedNumber = '';
	try {
		parsedNumber =
			parsePhoneNumberFromString(
				`+${props.number.countryCode}${props.number.number}`
			)?.formatInternational() ?? '';
	} catch (error: any /*ParseError*/) {
		if (error.message.indexOf('Unknown country') !== 0) {
			throw error;
		}
		parsedNumber = 'Invalid Phone Number';
	}

	return <>{parsedNumber}</>;
};
