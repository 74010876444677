import { ToggleButton, ToggleButtonProps, Tooltip } from '@mui/material';

interface RichTextToolbarButtonProps extends Omit<ToggleButtonProps, 'value'> {
	tooltipText?: string;
}

export const RichTextToolbarButton = ({
	tooltipText = '',
	...props
}: RichTextToolbarButtonProps) => {
	return (
		<Tooltip title={tooltipText}>
			<span>
				<ToggleButton
					value=""
					color="primary"
					sx={{
						'border': 'none',
						'padding': 0.5,
						'&.Mui-selected': { background: 'transparent' },
						'&.Mui-disabled': { border: 'none' },
					}}
					{...props}
				/>
			</span>
		</Tooltip>
	);
};
