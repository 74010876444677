import { gql, useLazyQuery } from '@apollo/client';
import {
	Autocomplete,
	Chip,
	ListItem,
	ListItemAvatar,
	ListItemText,
	TextField,
} from '@mui/material';
import { entitySearchTypeToEmblemEntityType } from 'components/pages/quick-search';
import { useField, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import {
	ConversationApiEntityType,
	EntitySearchType,
	Query,
	QueryParticipantSearchArgs,
	QuickSearchEntity,
} from 'middleware-types';
import { useCallback, useState } from 'react';
import { EmblemAvatar } from '../../../ui/emblem/emblem-avatar';
import { useCommunicationsContext } from '../communications-provider';

const RESULTS_LIMIT = 10;
const DEBOUNCE_TIMER_IN_MS = 500;

interface ParticipantSearchFieldProps {
	name: string;
	label: string;
	helperText?: string;
	required?: boolean;
	omittedIds?: string[];
}

export const ParticipantSearchField = ({
	name,
	label,
	helperText,
	required,
	omittedIds = [],
}: ParticipantSearchFieldProps) => {
	const [{ value }, { error }, { setValue }] = useField<QuickSearchEntity[]>(name);
	const { isSubmitting } = useFormikContext();
	const { participantSearch, results, loading } = useParticipantSearch();

	const [open, setOpen] = useState(false);

	const helperTextValue = error ?? helperText;
	const options = results.filter((result) => !omittedIds.includes(result.id));

	const debouncedSearch = useCallback(
		debounce((searchText: string) => {
			if (!searchText) return;
			setOpen(true);
			participantSearch(searchText, RESULTS_LIMIT);
		}, DEBOUNCE_TIMER_IN_MS),
		[]
	);

	return (
		<Autocomplete
			multiple
			noOptionsText="Can't find the person or organization you're looking for? You can only message your connections, or your associated users and organizations."
			disabled={isSubmitting}
			options={options}
			value={value}
			open={open}
			onClose={() => setOpen(false)}
			onChange={(_e, newValue) => setValue(newValue)}
			loading={loading}
			isOptionEqualToValue={(option, val) => option.id === val.id}
			filterOptions={(x) => x}
			onInputChange={(_e, inputValue) => {
				if (!inputValue) setOpen(false);
				debouncedSearch(inputValue);
			}}
			renderInput={(props) => (
				<TextField
					{...props}
					placeholder="Type to search"
					required={required}
					label={label}
					error={Boolean(error)}
					helperText={helperTextValue}
				/>
			)}
			getOptionLabel={(option) => option.displayName}
			renderTags={(value, getTagProps) =>
				value.map((entity, index) => {
					// have to explicitly set key to make linter happy...
					const { key, ...tagProps } = getTagProps({ index });
					return (
						<Chip
							key={key}
							{...tagProps}
							label={entity.displayName}
							avatar={
								<EmblemAvatar
									entityId={entity.id}
									entityType={entitySearchTypeToEmblemEntityType(
										entity.entityType
									)}
									variant="circular"
									noDropdown
								/>
							}
						/>
					);
				})
			}
			renderOption={(props, entity) => (
				<ListItem {...props}>
					<ListItemAvatar>
						<EmblemAvatar
							entityId={entity.id}
							entityType={entitySearchTypeToEmblemEntityType(entity.entityType)}
							noDropdown
						/>
					</ListItemAvatar>
					<ListItemText primary={entity.displayName} />
				</ListItem>
			)}
		/>
	);
};

/**
 * Hook for getting the possible participants
 */
const useParticipantSearch = () => {
	const { entityId, entityType } = useCommunicationsContext();
	const entitySearchType =
		entityType === ConversationApiEntityType.Users
			? EntitySearchType.User
			: entityType === ConversationApiEntityType.Organizations
			? EntitySearchType.Organization
			: undefined;

	const [_participantSearch, { data, loading, called }] = useLazyQuery<
		Pick<Query, 'participantSearch'>,
		QueryParticipantSearchArgs
	>(
		gql`
			query ParticipantSearch(
				$entityType: EntitySearchType!
				$entityId: String!
				$query: String
				$limit: Float!
			) {
				participantSearch(
					query: $query
					limit: $limit
					entityType: $entityType
					entityId: $entityId
				) {
					id
					entityType
					handle
					displayName
					location
					relevance
				}
			}
		`,
		{
			fetchPolicy: 'no-cache',
		}
	);

	const participantSearch = (query: string, limit: number) => {
		if (!entitySearchType) return;
		_participantSearch({
			variables: { query, limit, entityId, entityType: entitySearchType },
		});
	};

	const results = data?.participantSearch ?? [];
	return { participantSearch, results, loading, called };
};
