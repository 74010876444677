import { ManageSearchOutlined, PeopleOutlined } from '@mui/icons-material';
import {
	Divider,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemSecondaryAction,
	ListItemText,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { EmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { ConnectionPageType, ConnectionState, Emblem, EmblemEntityType } from 'middleware-types';
import { Fragment } from 'react';
import { useIsMobile } from 'utils/useScreenSize';
import { ComprehensiveConnectionState, ConnectionStateExtended } from '../../shared/types';
import { UserConnectionButton } from '../UserConnectionButton/UserConnectionButton';
import { useUserConnectionsOrRequestsQuery } from './UserConnections.graphql';

/**
 * Displays the list of a user's outbound requests
 */
export const UserConnectionsOrRequestsList = ({
	userId,
	pageType,
	searchFor,
}: {
	userId: string;
	pageType: ConnectionPageType;
	searchFor: string;
}) => {
	const { userConnectionsOrRequests, loading } = useUserConnectionsOrRequestsQuery(
		pageType,
		userId,
		true,
		searchFor
	);

	let connectionState: ComprehensiveConnectionState;
	if (pageType === ConnectionPageType.Requests)
		connectionState = ConnectionStateExtended.IncomingRequest;
	if (pageType === ConnectionPageType.MyConnections) connectionState = ConnectionState.Connected;
	if (pageType === ConnectionPageType.RequestsISent) connectionState = ConnectionState.Pending;

	if (loading) return <UserOutboundRequestsLoadingSkeleton />;

	// When there are no search results
	if (userConnectionsOrRequests.length === 0 && searchFor !== '') {
		return (
			<Stack spacing={2} justifyContent="center" alignItems="center" height="100%">
				<EmptyStateAvatar
					icon={<ManageSearchOutlined />}
					avatarProps={{ bgcolor: 'primary.50' }}
					iconProps={{ color: 'primary.500' }}
				/>
				<Typography variant="h5" px={3} textAlign="center">
					No results found for {searchFor}.
				</Typography>
			</Stack>
		);
	}
	if (userConnectionsOrRequests.length == 0)
		return (
			<Stack spacing={2} justifyContent="center" alignItems="center" height="100%">
				<EmptyStateAvatar
					icon={<PeopleOutlined />}
					avatarProps={{ bgcolor: 'primary.50' }}
					iconProps={{ color: 'primary.500' }}
				/>
				<Typography variant="h5" px={3} textAlign="center">
					{pageType === ConnectionPageType.MyConnections
						? 'You have no connections at this time.'
						: 'You have no pending connection requests at this time.'}
				</Typography>
			</Stack>
		);

	return (
		<List className="pb-0">
			{userConnectionsOrRequests.map((request, i) => (
				<Fragment key={request.connectionId}>
					<UserNetworkListDisplayItem
						connectionId={request.connectionId}
						connectionState={connectionState}
						otherUserEmblem={request.otherUserEmblem}
						otherOrganizationEmblem={request.otherOrganizationEmblem}
					/>
					{i !== userConnectionsOrRequests.length - 1 && <Divider />}
				</Fragment>
			))}
		</List>
	);
};

/**
 * Display item for the user outbound requests list
 * @summary It is expected that either the UserEmblem OR the OrganizationEmblem will be provided.
 */
const UserNetworkListDisplayItem = ({
	connectionId,
	connectionState,
	otherUserEmblem,
	otherOrganizationEmblem,
}: {
	connectionId: string;
	connectionState: ComprehensiveConnectionState;
	otherUserEmblem: Emblem | undefined;
	otherOrganizationEmblem: Emblem | undefined;
}) => {
	const emblem = otherUserEmblem ?? otherOrganizationEmblem;
	if (emblem === undefined) return null;
	const isMobile = useIsMobile();
	let link = emblem?.private ? undefined : `/${emblem.handle}`;

	return (
		<Stack>
			<ListItem disableGutters disablePadding className="p-0">
				<ListItemButton
					component={Link}
					href={link}
					className="w-full rounded p-4 pb-6"
					disableRipple>
					<ListItemAvatar>
						<EmblemAvatar emblem={emblem} />
					</ListItemAvatar>
					{emblem.entityType === EmblemEntityType.User && (
						<ListItemText
							primary={
								<Typography variant="h4">{emblem.extraData?.fullName}</Typography>
							}
							secondary={
								<Typography variant="subtitle2">
									{emblem.extraData?.companyTitle}
									{emblem.extraData?.companyName && (
										<> at {emblem.extraData?.companyName}</>
									)}
								</Typography>
							}
						/>
					)}
					{emblem.entityType === EmblemEntityType.Organization && (
						<ListItemText
							primary={<Typography variant="h4">{emblem.displayName}</Typography>}
						/>
					)}
				</ListItemButton>
				{!isMobile && (
					<ListItemSecondaryAction className="right-4">
						<UserConnectionButton
							user={{
								connectionId,
								connectionState,
								entityId: emblem.id,
								entityName: emblem.displayName,
							}}
						/>
					</ListItemSecondaryAction>
				)}
			</ListItem>
			{isMobile && (
				<Stack pb={2} px={2}>
					<UserConnectionButton
						user={{
							connectionId,
							connectionState,
							entityId: emblem.id,
							entityName: emblem.displayName,
						}}
					/>
				</Stack>
			)}
		</Stack>
	);
};

const UserOutboundRequestsLoadingSkeleton = () => {
	return (
		<List component={Stack} spacing={1}>
			<ListItem disableGutters disablePadding>
				<Skeleton variant="rectangular" width="100%" height="4rem" />
			</ListItem>
			<ListItem disableGutters disablePadding>
				<Skeleton variant="rectangular" width="100%" height="4rem" />
			</ListItem>
			<ListItem disableGutters disablePadding>
				<Skeleton variant="rectangular" width="100%" height="4rem" />
			</ListItem>
		</List>
	);
};
