import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { gql } from 'graphql-tag';
import { Query } from 'middleware-types';
import { PageError } from 'utils/errors';

export const useZendeskJwtUrl = () => {
	const { data, error } = useQuery<Pick<Query, 'zendeskAuth'>>(ZENDESK_JWT_QUERY);

	return { data, error };
};

const ZENDESK_JWT_QUERY = gql`
	query zendeskAuth {
		zendeskAuth {
			url
			jwt
		}
	}
`;

export const ZendeskRedirect = () => {
	const { data, error } = useZendeskJwtUrl();
	if (data?.zendeskAuth?.url && data?.zendeskAuth?.jwt) {
		const form = document.createElement('form');
		form.setAttribute('method', 'post');
		form.setAttribute('action', data.zendeskAuth.url);

		const hiddenInput = document.createElement('input');
		hiddenInput.setAttribute('type', 'hidden');
		hiddenInput.setAttribute('name', 'jwt');
		hiddenInput.setAttribute('value', data.zendeskAuth.jwt);
		form.appendChild(hiddenInput);

		document.body.appendChild(form);
		form.submit();
	}
	if (error) {
		throw PageError;
	}
	return (
		<div className="flex h-full items-center justify-center">
			<CircularProgress />
		</div>
	);
};
