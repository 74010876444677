import { Close, ErrorOutlined, PowerSettingsNewOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useSetOrgPrivate } from 'components/pages/org/account/context-menu-hooks';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { Visibility } from './hierarchy-context-menu';

interface ConfirmChangeOrgVisibilityModalProps {
	organizationId: string;
	displayName: string | undefined;
	visibilityToSet: Visibility;
	onClose: () => void;
}

export const ConfirmChangeOrgVisibilityModal = ({
	organizationId,
	displayName,
	visibilityToSet,
	onClose,
}: ConfirmChangeOrgVisibilityModalProps) => {
	const { setOrgPrivate } = useSetOrgPrivate(organizationId);

	const onSubmit = () => {
		setOrgPrivate(visibilityToSet === Visibility.Private ? true : false).then((res) => {
			if (!res) return;
			onClose();
		});
	};

	const btnColor = visibilityToSet === Visibility.Public ? 'primary' : 'error';
	const modalVisual =
		visibilityToSet === Visibility.Public ? (
			<EmptyStateAvatar
				avatarProps={{ bgcolor: 'primary.50' }}
				iconProps={{ color: 'primary.500' }}
				icon={<PowerSettingsNewOutlined />}
			/>
		) : (
			<EmptyStateAvatar
				avatarProps={{ bgcolor: 'error.50' }}
				iconProps={{ color: 'error.500' }}
				icon={<ErrorOutlined />}
			/>
		);

	return (
		<Stack>
			<Stack
				px={2}
				py={1}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				spacing={1}>
				<Typography variant="h3">Make Organization {visibilityToSet}</Typography>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</Stack>
			<Divider />
			<Stack alignItems="center" textAlign="center" px={10} py={3}>
				{modalVisual}
				<Typography variant="h1" marginTop={2} sx={{ wordBrToLowereak: 'break-word' }}>
					Are you sure?
				</Typography>
				<Typography variant="body1" sx={{ marginTop: '4px' }}>
					Do you really want to make the organization {displayName}{' '}
					{visibilityToSet.toString().toLowerCase()}?
				</Typography>
			</Stack>
			<Divider />
			<Stack direction="row" justifyContent="flex-end" px={2} py={1.5} spacing={1.5}>
				<Button size="large" variant="outlined" onClick={onClose}>
					Cancel
				</Button>
				<LoadingButton size="large" variant="contained" color={btnColor} onClick={onSubmit}>
					Make {visibilityToSet}
				</LoadingButton>
			</Stack>
		</Stack>
	);
};
