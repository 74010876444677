import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, FormControlLabel, IconButton, Stack, Typography } from '@mui/material';
import { TextField } from 'components/ui/fields';
import { defaultColorFieldOptions } from 'components/ui/fields/colorField';
import { Formik, FormikProps } from 'formik';
import { UserDashboardConfigurationPatch } from 'middleware-types';
import { useState } from 'react';
import { BlockPicker } from 'react-color';
import { useSession } from 'utils/session';
import { theme } from 'utils/theme';
import { useValidation } from 'utils/useValidation';
import {
	usePatchUserDashboardConfigurationSettings,
	useUserDashboardConfigurationSettings,
} from './hooks';

let colorOptions: string[] = [
	theme.palette.neutral[700],
	theme.palette.primary.main,
	...defaultColorFieldOptions.map((cfo) => cfo.hex),
];

export const UserDashboardSettingsModal = ({ onClose }: { onClose: () => void }) => {
	const { user } = useSession();
	const { settings } = useUserDashboardConfigurationSettings();
	const { patchUserDashboardConfigurationSettings } =
		usePatchUserDashboardConfigurationSettings();
	const [primaryColor, setPrimaryColor] = useState<string | undefined>(settings?.primaryColor);
	const [secondaryColor, setSecondaryColor] = useState<string | undefined>(
		settings?.secondaryColor
	);

	const onSubmit = async (values: UserDashboardConfigurationPatch) => {
		patchUserDashboardConfigurationSettings(user.userId, values).then((success) => {
			if (success) onClose();
		});
	};

	const { schema } = useValidation('UserDashboardConfigurationPatch');

	const initialValues: UserDashboardConfigurationPatch = {
		primaryColor: settings?.primaryColor ?? '',
		secondaryColor: settings?.secondaryColor ?? '',
		title: settings?.title ?? '',
		subTitle: settings?.subTitle ?? '',
	};

	return (
		<Formik<UserDashboardConfigurationPatch>
			onSubmit={onSubmit}
			validationSchema={schema}
			initialValues={initialValues}
			enableReinitialize>
			{(props: FormikProps<UserDashboardConfigurationPatch>) => (
				<>
					<Stack
						px={2}
						py={2}
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={1}>
						<Typography variant="h3">Edit Dashboard Theme</Typography>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</Stack>
					<Divider />
					<Stack spacing={3} p={2.5} overflow="auto">
						<TextField label="Title" required name="title" fullWidth />
						<TextField label="Subtitle" required name="subTitle" fullWidth />
						<Stack
							spacing={1}
							display="flex"
							flexDirection="row"
							justifyContent="space-evenly"
							flexWrap="wrap">
							<FormControlLabel
								required
								control={
									<Stack pt={1}>
										<BlockPicker
											styles={{
												default: {
													card: {
														boxShadow: 'none',
													},
												},
											}}
											onChange={(val, e) => {
												// Prevent auto selection of hex value input
												// which was opening keyboard on mobile
												e.preventDefault();

												props.setFieldValue('primaryColor', val.hex);
												setPrimaryColor(val.hex);
											}}
											color={primaryColor ?? theme.palette.neutral[700]}
											colors={colorOptions}
											triangle="hide"
										/>
									</Stack>
								}
								label={'Primary Color'}
								labelPlacement="top"
							/>
							<FormControlLabel
								required
								control={
									<Stack pt={1}>
										<BlockPicker
											styles={{
												default: {
													card: {
														boxShadow: 'none',
													},
												},
											}}
											onChange={(val, e) => {
												// Prevent auto selection of hex value input
												// which was opening keyboard on mobile
												e.preventDefault();

												props.setFieldValue('secondaryColor', val.hex);
												setSecondaryColor(val.hex);
											}}
											color={secondaryColor ?? theme.palette.primary.main}
											colors={colorOptions}
											triangle="hide"
										/>
									</Stack>
								}
								label={'Secondary Color'}
								labelPlacement="top"
							/>
						</Stack>
					</Stack>
					<Divider />
					<Stack direction="row" justifyContent="flex-end" px={2} py={1.5} spacing={1.5}>
						<Button size="large" variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							size="large"
							variant="contained"
							color="primary"
							loading={props.isSubmitting}
							disabled={!props.isValid || !props.dirty}
							onClick={props.submitForm}>
							Save
						</LoadingButton>
					</Stack>
				</>
			)}
		</Formik>
	);
};

/* const ColorMenuItem = ({ option, isDefault }: { option: ColorOption; isDefault: boolean }) => {
	return (
		<Stack direction="row" spacing={1.5} alignItems="center">
			<Box height={25} width={25} borderRadius="50%" sx={{ backgroundColor: option.hex }} />
			<ListItemText primary={option.name + (isDefault ? ' (Default)' : '')} />
		</Stack>
	);
};
*/
