import { useSearchParams } from 'react-router-dom';

export const useSearchParam = (key: string): [string | null, (value: string | null) => void] => {
	const [searchParams, setSearchParams] = useSearchParams();

	const value = searchParams.get(key);
	const setValue = (newValue: string | null) =>
		setSearchParams((params) => {
			if (newValue) params.set(key, newValue);
			else params.delete(key);
			return params;
		});

	return [value, setValue];
};
