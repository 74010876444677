import { LabelType } from 'middleware-types';
import { LabelWithColor } from './label-colors';

// helper function to sort our default labels correctly
const getLabelIndex = (label: LabelWithColor) => {
	switch (label.type) {
		case LabelType.Inbox:
			return 0;
		case LabelType.All:
			return 1;
		case LabelType.Trash:
			return 2;
		default:
			return -1;
	}
};

// return a list of only the default labels
export const getDefaultLabels = (labels: LabelWithColor[]) => {
	return labels
		.filter((label) => label.type !== LabelType.Custom)
		.sort((a, b) => getLabelIndex(a) - getLabelIndex(b));
};

// return a list of strings that represents how this label is nested
const getLabelPath = (label: LabelWithColor, labels: LabelWithColor[]): string[] => {
	let parentId = label.parentLabelId;
	const path: string[] = [];

	for (;;) {
		if (!parentId) break;
		const parentLabel = labels.find((l) => l.id === parentId);
		if (!parentLabel) break;
		path.unshift(parentLabel.name);
		parentId = parentLabel.parentLabelId;
	}

	return path;
};

export interface LabelWithPathInfo {
	label: LabelWithColor;
	path: string[];
}

// return a list of only custom labels, sorted according to the parent child heirarchy
// optionally include the inbox label, since it is used alongside custom labels in some components
export const getCustomLabels = (
	labels: LabelWithColor[],
	includeInbox?: boolean
): LabelWithPathInfo[] => {
	const customLabels = labels.filter(
		(label) =>
			label.type === LabelType.Custom || (label.type === LabelType.Inbox && includeInbox)
	);

	const labelsWithPathInfo = customLabels.map((label) => ({
		label,
		path: getLabelPath(label, labels),
	}));

	return labelsWithPathInfo.sort((a, b) => {
		// put the inbox label first
		if (a.label.type === LabelType.Inbox) return -1;
		if (b.label.type === LabelType.Inbox) return 1;

		const aPathString = a.path.join('') + a.label.name;
		const bPathString = b.path.join('') + b.label.name;
		return aPathString.localeCompare(bPathString);
	});
};
