import { ArrowDownward, ArrowDropDown, ArrowUpward, Check } from '@mui/icons-material';
import { Button, ListSubheader, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { SortDirection } from 'middleware-types';
import { useEffect, useState } from 'react';
import { useVersionHistoryContext } from './version-history-provider';

export enum FilesSortColumn {
	Name = 'Name',
	LastUpdated = 'LastUpdated',
	UpdatedBy = 'UpdatedBy',
	Privacy = 'Privacy',
	VirusStatus = 'VirusStatus',
	Size = 'Size',
}

const getSortLabel = (value: FilesSortColumn, isVersionHistory: boolean): string => {
	switch (value) {
		case FilesSortColumn.Name:
			return 'Name';
		case FilesSortColumn.LastUpdated:
			return isVersionHistory ? 'Upload Date' : 'Last Updated';
		case FilesSortColumn.UpdatedBy:
			return isVersionHistory ? 'Uploaded By' : 'Updated By';
		case FilesSortColumn.Privacy:
			return 'Privacy';
		case FilesSortColumn.VirusStatus:
			return 'Virus Status';
		case FilesSortColumn.Size:
			return 'Size';
	}
};

interface SortOptionsDropdownProps {
	column: FilesSortColumn;
	setColumn: (value: FilesSortColumn) => void;
	direction: SortDirection;
	setDirection: (value: SortDirection) => void;
	flex?: boolean;
}

export const SortOptionsDropdown = ({
	column,
	setColumn,
	direction,
	setDirection,
	flex = false,
}: SortOptionsDropdownProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [dropdownWidth, setDropdownWidth] = useState<number>();

	const { isVersionHistory } = useVersionHistoryContext();

	useEffect(() => {
		if (!anchorEl) return;
		setDropdownWidth(anchorEl.offsetWidth);
	}, [anchorEl, column]);

	return (
		<>
			<Button
				variant="outlined"
				fullWidth={flex}
				startIcon={
					direction === SortDirection.Ascending ? <ArrowUpward /> : <ArrowDownward />
				}
				endIcon={<ArrowDropDown />}
				onClick={(e) => setAnchorEl(e.currentTarget)}>
				Sort by {getSortLabel(column, isVersionHistory).toLowerCase()}
			</Button>
			{/** menu */}
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				MenuListProps={{
					sx: { width: dropdownWidth },
				}}>
				<ListSubheader sx={{ bgcolor: 'neutral.50', lineHeight: 1 }}>
					<Typography variant="overline">COLUMN</Typography>
				</ListSubheader>
				{Object.values(FilesSortColumn).map((option) => (
					<MenuItem key={option} onClick={() => setColumn(option)}>
						<Stack
							py={0.5}
							width="100%"
							direction="row"
							alignItems="center"
							justifyContent="space-between">
							{getSortLabel(option, isVersionHistory)}
							{option === column && <Check color="primary" />}
						</Stack>
					</MenuItem>
				))}
				<ListSubheader sx={{ bgcolor: 'neutral.50', lineHeight: 1 }}>
					<Typography variant="overline">ORDER</Typography>
				</ListSubheader>
				{Object.values(SortDirection).map((option) => (
					<MenuItem key={option} onClick={() => setDirection(option)}>
						<Stack
							py={0.5}
							width="100%"
							direction="row"
							alignItems="center"
							justifyContent="space-between">
							{option}
							{option === direction && <Check color="primary" />}
						</Stack>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
