export enum CompanyRoleCategory {
	ADMIN = 'Admin',
	ADJUSTER = 'Adjuster',
	CLERICAL = 'Clerical',
	CLAIMS_REP = 'Claims Representative',
	OTHER = 'Other',
}

type RoleType = string | undefined;

export const getCompanyRoleCategory = (role: RoleType): CompanyRoleCategory => {
	switch (role) {
		case 'ADMIN':
		case 'AdminHR':
		case 'BranchAdmin':
			return CompanyRoleCategory.ADMIN;
		case 'Adjuster':
		case 'StaffAdjuster':
		case 'SrAdjuster':
			return CompanyRoleCategory.ADJUSTER;
		case 'Clerical':
		case 'AdminClerical':
			return CompanyRoleCategory.CLERICAL;
		case 'ClaimsRep':
		case 'ClaimsRep - NO NOTES':
		case 'ClaimsRepMgr':
		case 'ClaimsRepMgr - NO NOTES':
			return CompanyRoleCategory.CLAIMS_REP;
		default:
			return CompanyRoleCategory.OTHER;
	}
};

export const getCompanyRoleDisplayName = (role: RoleType): RoleType => {
	switch (role) {
		case 'ADMIN':
			return 'Admin';
		case 'AdminHR':
			return 'Admin - HR';
		case 'BranchAdmin':
			return 'Branch Admin';
		case 'Adjuster':
			return 'Adjuster';
		case 'StaffAdjuster':
			return 'Staff Adjuster';
		case 'SrAdjuster':
			return 'Senior Adjuster';
		case 'Clerical':
			return 'Clerical';
		case 'AdminClerical':
			return 'Admin - Clerical';
		case 'ClaimsRep':
			return 'Claims Rep';
		case 'ClaimsRep - NO NOTES':
			return 'Claims Rep - No Notes';
		case 'ClaimsRepMgr':
			return 'Claims Rep Manager';
		case 'ClaimsRepMgr - NO NOTES':
			return 'Claims Rep Manager - No Notes';
		case 'ReadOnly':
			return 'Read Only';
		default:
			return role;
	}
};

export const getCompanyRolePermissions = (role: RoleType) => {
	role = role ?? '';
	const roleCategory = getCompanyRoleCategory(role);
	return {
		hasUserPermission:
			[
				CompanyRoleCategory.ADMIN,
				CompanyRoleCategory.ADJUSTER,
				CompanyRoleCategory.CLAIMS_REP,
			].includes(roleCategory) && !['AdminHR'].includes(role),
		hasGroupPermission:
			[
				CompanyRoleCategory.ADMIN,
				CompanyRoleCategory.CLERICAL,
				CompanyRoleCategory.CLAIMS_REP,
			].includes(roleCategory) && !['ClaimsRep', 'ClaimsRep - NO NOTES'].includes(role),
	};
};

export const getMyJobsText = (role: RoleType) => {
	const roleCategory = getCompanyRoleCategory(role);
	if (roleCategory === CompanyRoleCategory.CLAIMS_REP) return 'Responsible Jobs';
	return 'My Jobs';
};

export const getCompanyJobsText = (role: RoleType) => {
	const roleCategory = getCompanyRoleCategory(role);
	if (roleCategory === CompanyRoleCategory.CLAIMS_REP) return 'Carrier Jobs';
	if (role === 'BranchAdmin') return 'Branch Jobs';
	return 'Company Jobs';
};
