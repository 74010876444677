import { FilterListOutlined, SearchOutlined } from '@mui/icons-material';
import {
	Badge,
	IconButton,
	InputAdornment,
	MenuItem,
	Popover,
	Stack,
	Typography,
} from '@mui/material';
import { SelectField, TextField } from 'components/ui/fields';
import { useFormikContext } from 'formik';
import {
	EntitySearchFormValues,
	EntitySystemSearchType,
} from 'pages/site/system-search/system-search';
import { useState } from 'react';
import { Permission } from 'utils/permissions';
import { useSiteUser } from 'utils/useSiteUser';

export const SearchFields = () => {
	const { submitForm, values, isValid, isSubmitting } =
		useFormikContext<EntitySearchFormValues>();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const open = Boolean(anchorEl);

	const { hasPermission } = useSiteUser();

	/**
	 * Searches when 'Enter' is pressed.
	 *
	 * @param {*} e
	 */
	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter') {
			submitForm();
		}
	};

	return (
		<Stack direction="row" spacing={1} alignItems="center">
			<TextField
				name="searchText"
				onKeyDown={onKeyDown}
				placeholder="Name, Email, Phone..."
				sx={{ minWidth: 250 }}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchOutlined fontSize="small" />
						</InputAdornment>
					),
				}}
			/>
			<SelectField
				name="entitySystemSearchType"
				disabled={!isValid || isSubmitting}
				required
				sx={{ minWidth: 180 }}>
				{hasPermission([
					Permission.SocialUser_Account_R,
					Permission.SocialUser_Profile_R,
				]) && <MenuItem value="SocialUser">Social Users</MenuItem>}
				{hasPermission(Permission.Site_User_R) && (
					<MenuItem value="SiteUser">Site Users</MenuItem>
				)}
				{hasPermission(Permission.Site_Inv_R) && (
					<MenuItem value="SiteUserInvitation">Invited Site Users</MenuItem>
				)}
				{hasPermission([Permission.Site_OrgProfile_R, Permission.Site_OrgAcct_R]) && (
					<MenuItem value="Organization">Organizations</MenuItem>
				)}
			</SelectField>
			<Badge badgeContent={getBadgeCount(values)} color="primary">
				<IconButton aria-haspopup="true" onClick={(e) => setAnchorEl(e.currentTarget)}>
					<FilterListOutlined fontSize="small" />
				</IconButton>
			</Badge>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}>
				<Stack p={1} spacing={1}>
					<Typography>Filter by</Typography>
					{/* Filter options for site users */}
					{values.entitySystemSearchType === 'SiteUser' && (
						<>
							<SelectField
								disabled={!isValid || isSubmitting}
								name="activationStatus"
								SelectProps={{
									multiple: true,
								}}
								label={'Status'}
								variant="standard"
								fullWidth>
								<MenuItem value="Active">Active</MenuItem>
								<MenuItem value="Inactive">Inactive</MenuItem>
							</SelectField>
							<TextField
								disabled={!isValid || isSubmitting}
								label={'Affiliation'}
								type="text"
								name="affiliation"
								variant="standard"
							/>
						</>
					)}
					{/* Filter options for social users */}
					{values.entitySystemSearchType === 'SocialUser' && (
						<>
							<SelectField
								disabled={!isValid || isSubmitting}
								name="activationStatus"
								SelectProps={{
									multiple: true,
								}}
								label={'Status'}
								variant="standard"
								fullWidth>
								<MenuItem value="Active">Active</MenuItem>
								<MenuItem value="Inactive">Inactive</MenuItem>
							</SelectField>
							<TextField
								disabled={!isValid || isSubmitting}
								label={'Company'}
								type="text"
								name="companyName"
								variant="standard"
							/>
						</>
					)}
					{/* Filter options for site user invitations */}
					{values.entitySystemSearchType === 'SiteUserInvitation' && (
						<TextField
							disabled={!isValid || isSubmitting}
							label={'Affiliation'}
							type="text"
							name="affiliation"
							variant="standard"
						/>
					)}
					{/* Filter options for organizations */}
					{values.entitySystemSearchType === 'Organization' && (
						<>
							{/* This doesn't render wide because it doesn't have a text search */}
							<SelectField
								disabled={!isValid || isSubmitting}
								name="activationStatus"
								SelectProps={{
									multiple: true,
								}}
								label={'Status'}
								variant="standard"
								fullWidth>
								<MenuItem value="Active">Active</MenuItem>
								<MenuItem value="Inactive">Inactive</MenuItem>
							</SelectField>
							<TextField
								disabled={!isValid || isSubmitting}
								label={'Location'}
								type="text"
								name="location"
								variant="standard"
							/>
						</>
					)}
				</Stack>
			</Popover>
		</Stack>
	);
};

const getBadgeCount = (values: EntitySearchFormValues): number => {
	let badgeCount = 0;

	if (
		values.entitySystemSearchType === EntitySystemSearchType.SocialUser ||
		values.entitySystemSearchType === EntitySystemSearchType.SiteUser ||
		values.entitySystemSearchType === EntitySystemSearchType.Organization
	) {
		if (values.activationStatus.length > 1) {
			badgeCount++;
		}
		if (values.activationStatus.length > 2) {
			badgeCount++;
		}
		if (values.location) {
			badgeCount++;
		}
	}
	if (values.entitySystemSearchType === EntitySystemSearchType.SocialUser) {
		if (values.companyName) {
			badgeCount++;
		}
	}
	if (
		values.entitySystemSearchType === EntitySystemSearchType.SiteUser ||
		values.entitySystemSearchType === EntitySystemSearchType.SiteUserInvitation
	) {
		if (values.affiliation) {
			badgeCount++;
		}
	}

	return badgeCount;
};
