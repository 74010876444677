import { gql, useQuery } from '@apollo/client';
import { debounce } from 'lodash';
import {
	FolderSearchType,
	Query,
	QuerySearchFoldersArgs,
	SearchFoldersRequest,
} from 'middleware-types';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDocumentsContext } from '../documents-page';
import { FOLDER_CONTENTS_FIELDS } from '../utils/fragments.graphql';

const DEBOUNCE_TIMER_IN_MS = 500;

export const SEARCH_DOCUMENTS = gql`
	${FOLDER_CONTENTS_FIELDS}
	query SearchDocuments(
		$entityType: FoldersApiEntityType!
		$entityId: String!
		$request: SearchFoldersRequest!
	) {
		searchFolders(entityType: $entityType, entityId: $entityId, request: $request) {
			...FolderContentsFields
		}
	}
`;

export const documentSearchDefaultVariables: Omit<SearchFoldersRequest, 'searchValue'> = {
	caseSensitive: false,
	currentInstanceOnly: false,
	searchType: FolderSearchType.Any,
};

export const useDocumentsSearch = () => {
	const [searchText, _setSearchText] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const isSearchActive = Boolean(searchValue);

	// set the search value
	const debouncedSetSearchValue = useCallback(
		debounce((value: string) => {
			if (!value) return;
			setSearchValue(value);
		}, DEBOUNCE_TIMER_IN_MS),
		[]
	);

	const setSearchText = (value: string) => {
		_setSearchText(value);
		debouncedSetSearchValue(value);
		if (!value) setSearchValue(value);
	};

	// clear the search if we get the clearSearch flag
	const [searchParams, setSearchParams] = useSearchParams();
	const clearSearch = searchParams.get('clearSearch');

	useEffect(() => {
		if (clearSearch) {
			setSearchText('');
			setSearchParams((params) => {
				params.delete('clearSearch');
				return params;
			});
		}
	}, [clearSearch]);

	// fetch the data
	const { entityId, entityType } = useDocumentsContext();
	const { data, loading } = useQuery<Pick<Query, 'searchFolders'>, QuerySearchFoldersArgs>(
		SEARCH_DOCUMENTS,
		{
			skip: !searchValue,
			fetchPolicy: 'cache-and-network',
			variables: {
				entityId,
				entityType,
				request: {
					searchValue,
					...documentSearchDefaultVariables,
				},
			},
		}
	);

	return { isSearchActive, searchText, setSearchText, searchValue, data, loading };
};
