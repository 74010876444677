import { ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { IconButton, Stack, Typography } from '@mui/material';
import { TextField } from 'components/ui/fields';
import { Formik, FormikProps } from 'formik';
import { PromptClippingRequest, PromptClippingResponse } from 'middleware-types';
import { theme } from 'utils/theme';
import { useValidation } from 'utils/useValidation';
import { useCreatePromptClipping, useUpdatePromptClipping } from './hooks';

interface UpsertPromptClippingProps {
	onClose: () => void;
	clipping?: PromptClippingResponse;
}

export const UpsertPromptClipping = ({ onClose, clipping }: UpsertPromptClippingProps) => {
	const { createPromptClipping } = useCreatePromptClipping();
	const { updatePromptClipping } = useUpdatePromptClipping();

	const onSubmit = async (input: PromptClippingRequest) => {
		let success;
		if (clipping && clipping.id) {
			success = await updatePromptClipping(clipping.id, input);
		} else {
			success = await createPromptClipping(input);
		}
		if (success) onClose();
	};

	const validation = useValidation('PromptClippingRequest');

	return (
		<Stack height="100%" width="100%">
			<Stack
				px={1}
				py={0.5}
				direction="row"
				alignItems="center"
				spacing={1}
				borderBottom={`1px solid ${theme.palette.divider}`}>
				<IconButton onClick={onClose}>
					<ArrowBack />
				</IconButton>
				<Typography variant="h4">{clipping ? 'Update' : 'Add'} Clipping</Typography>
			</Stack>
			<Formik<PromptClippingRequest>
				onSubmit={onSubmit}
				initialValues={{
					title: clipping?.title ?? '',
					content: clipping?.content ?? '',
				}}
				validationSchema={validation.schema}
				enableReinitialize>
				{(props: FormikProps<PromptClippingRequest>) => (
					<Stack flex={1} overflow="auto">
						<Stack spacing={4} p={2} flex={1} overflow="auto">
							<TextField
								required
								name="title"
								label="Title"
								placeholder="e.g. Summarize Email Thread"
								minRows={3}
							/>
							<TextField
								required
								name="content"
								label="Content"
								placeholder={`Create a concise and comprehensive summary of the provided email thread
- List all major topics discussed
- Itemize any action items identified
- Rely strictly on the provided text, without including external information

Email Thread Contents:
======================`}
								multiline
								minRows={3}
							/>
						</Stack>
						<Stack direction="row" spacing={1} p={2} justifyContent="flex-end">
							<LoadingButton
								loading={props.isSubmitting}
								variant="outlined"
								color="primary"
								onClick={onClose}>
								Cancel
							</LoadingButton>
							<LoadingButton
								loading={props.isSubmitting}
								variant="contained"
								color="primary"
								disabled={!props.dirty || props.isSubmitting || !props.isValid}
								onClick={() => {
									props.submitForm();
								}}>
								Save
							</LoadingButton>
						</Stack>
					</Stack>
				)}
			</Formik>
		</Stack>
	);
};
