import { Box, Stack, Typography } from '@mui/material';
import { useMapColors } from 'utils/useMapColors';

interface MapKeyProps {
	includeInactive?: boolean;
}

export const MapKey = ({ includeInactive = false }: MapKeyProps) => {
	const { activeColor, expiredColor, inactiveColor, borderColor } = useMapColors();
	return (
		<Stack direction="row" spacing={2.5} flexWrap="wrap">
			<Stack direction="row" alignItems="center" spacing={0.75}>
				<Box
					height={16}
					width={16}
					bgcolor={activeColor}
					border="2px solid"
					borderColor={borderColor}
				/>
				<Typography variant="body2">Active</Typography>
			</Stack>
			<Stack direction="row" alignItems="center" spacing={0.75}>
				<Box
					height={16}
					width={16}
					bgcolor={expiredColor}
					border="2px solid"
					borderColor={borderColor}
				/>
				<Typography variant="body2">Expired</Typography>
			</Stack>
			{includeInactive && (
				<Stack direction="row" alignItems="center" spacing={0.75}>
					<Box
						height={16}
						width={16}
						bgcolor={inactiveColor}
						border="2px solid"
						borderColor={borderColor}
					/>
					<Typography variant="body2">Inactive</Typography>
				</Stack>
			)}
		</Stack>
	);
};
