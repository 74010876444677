import gql from 'graphql-tag';
import { MutationHookOptions, useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import {
	Mutation,
	MutationOrganizationRoleDeleteArgs,
	Query,
	QueryRolesArgs,
} from 'middleware-types';

/**
 * Query for a paginated list of roles belonging to the current organization.
 *
 */
export const ORGROLES = gql`
	query roles(
		$organizationId: ID!
		$pageSize: Float!
		$offset: Float!
		$sortedBy: String!
		$sortDirection: String!
	) {
		roles(
			organizationId: $organizationId
			pageSize: $pageSize
			offset: $offset
			sortedBy: $sortedBy
			sortDirection: $sortDirection
		) {
			totalCount
			items {
				id
				name
				isAdminRole
				associateCount
				category {
					name
				}
			}
		}
	}
`;

/**
 * useOrgRolesQuery() - hook to pull the provided organization's roles (paged).
 *
 * @param {string} organizationId
 * @param {number} pageSize
 * @param {number} page
 * @param {string} sortedBy
 * @param {('Ascending' | 'Descending')} sortDirection
 * @return {*}
 */
export const useOrgRolesQuery = (
	organizationId: string,
	pageSize: number,
	page: number,
	sortedBy: string,
	sortDirection: 'Ascending' | 'Descending'
) => {
	const toast = useToast();
	const { data, error, loading, refetch } = useQuery<Pick<Query, 'roles'>, QueryRolesArgs>(
		ORGROLES,
		{
			// checks cache and fetches (important for updating queries after updating a role)
			fetchPolicy: 'cache-and-network',
			variables: {
				organizationId,
				pageSize,
				sortedBy,
				sortDirection,
				offset: page * pageSize,
			},
			onError: () => {
				toast.push(`An error occurred. Please try again later or contact Support.`, {
					variant: 'error',
				});
			},
		}
	);

	return {
		roles:
			data?.roles?.items?.map((r) => ({
				id: r.id,
				isAdminRole: r.isAdminRole,
				name: r.name,
				category: r.category?.name,
				associatedContacts: r.associateCount,
			})) ?? [],
		totalCount: data?.roles?.totalCount ?? 0,
		loading,
		error,
		refetch,
	};
};

/* Mutation for deleting an existing organization role. */
export const DELETE_ORGROLE = gql`
	mutation organizationRoleDelete($organizationId: ID!, $roleId: ID!) {
		organizationRoleDelete(organizationId: $organizationId, roleId: $roleId)
	}
`;

/**
 * useOrgRoleMutation(orgId) - Send an invitation to an associate.
 *
 * @param {string} organizationId
 * @return {*}
 */
export const useOrgRoleDeleteMutation = (
	organizationId: string,
	options: MutationHookOptions<
		Pick<Mutation, 'organizationRoleDelete'>,
		MutationOrganizationRoleDeleteArgs
	>
) => {
	const toast = useToast();
	const [deleteRole, status] = useMutation<
		Pick<Mutation, 'organizationRoleDelete'>,
		MutationOrganizationRoleDeleteArgs
	>(DELETE_ORGROLE, {
		...options,
	});

	return {
		deleteRole: async (roleId: string) => {
			return deleteRole({
				variables: {
					roleId,
					organizationId,
				},
			})
				.then((res) => {
					if (responseHasErrors(res.errors, { toast })) {
						return false;
					}
					toast.push(`The role was successfully deleted.`, {
						variant: 'success',
					});
					return true;
				})
				.catch(() => {
					handleNoResponse({ toast });
					return false;
				});
		},
		status,
	};
};
