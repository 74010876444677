import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { LabelWithPathInfo } from '../helpers/labels';
import { LabelIcon } from '../shared/label-icon';
import { useApplyLabel, useRemoveLabel } from '../hooks/conversation-labels-hooks';

interface LabelsPopupProps {
	labels: LabelWithPathInfo[];
	activeLabelIds: string[];
}

export const LabelsPopup = ({ labels, activeLabelIds }: LabelsPopupProps) => {
	return (
		<Box p={1} pr={2}>
			{labels.map((label) => (
				<LabelCheckbox
					key={label.label.id}
					label={label}
					checked={activeLabelIds.includes(label.label.id)}
				/>
			))}
		</Box>
	);
};

interface LabelCheckboxProps {
	label: LabelWithPathInfo;
	checked: boolean;
}

const LabelCheckbox = ({ label, checked }: LabelCheckboxProps) => {
	const { applyLabel, loading: applyLoading } = useApplyLabel();
	const { removeLabel, loading: removeLoading } = useRemoveLabel();
	const loading = applyLoading || removeLoading;

	const handleChange = (newValue: boolean) => {
		newValue ? applyLabel(label.label.id) : removeLabel(label.label.id);
	};

	const indent = label.path.length;

	return (
		<Stack direction="row" alignItems="center" pl={indent * 3}>
			<Checkbox
				checked={checked}
				disabled={loading}
				onChange={(_e, newValue) => handleChange(newValue)}
			/>
			<LabelIcon label={label.label} />
			<Box width={8} />
			<Box flex={1} overflow="hidden">
				<Typography>{label.label.name}</Typography>
			</Box>
		</Stack>
	);
};
