import { gql, useMutation } from '@apollo/client';

/* Mutation to store information about a remembered device */
export const STORECOGNITODEVICE = gql`
	mutation storeCognitoDevice($storeCognitoDeviceRequest: StoreCognitoDeviceRequest!) {
		storeCognitoDevice(storeCognitoDeviceRequest: $storeCognitoDeviceRequest)
	}
`;

/**
 * useStoreRememberedDevice() is a custom hook to handle storing information
 * about a remembered device*
 */
export const useStoreCognitoDevice = () => {
	const [storeCognitoDeviceMut, { loading, data, error }] = useMutation(STORECOGNITODEVICE);

	const storeCognitoDevice = async (deviceKey: string) => {
		return storeCognitoDeviceMut({
			variables: {
				storeCognitoDeviceRequest: {
					deviceKey,
				},
			},
		});
	};

	return { storeCognitoDevice, loading, data, error };
};
