import { gql, useMutation } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	ActivationOperation,
	Mutation,
	MutationOrganizationAccountActivateArgs,
	MutationOrganizationAccountDeactivateArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';

export const ACTIVATE_ORGANIZATION = gql`
	mutation organizationAccountActivate($organizationId: ID!) {
		organizationAccountActivate(organizationId: $organizationId)
	}
`;

export const DEACTIVATE_ORGANIZATION = gql`
	mutation organizationAccountDeactivate($organizationId: ID!) {
		organizationAccountDeactivate(organizationId: $organizationId)
	}
`;

/**
 * useUpdateOrganizationActivationMutation(userId) - Hook that updates organization activation status.
 *
 * @param {string} userId
 * @return {*}
 */
export const useUpdateOrganizationActivationMutation = (organizationId: string) => {
	const toast = useToast();
	const sharedOptions = {
		update: (cache: any) => {
			cache.evict({
				id: `OrganizationAccount:${organizationId}`,
				fieldName: 'deactivated',
			});

			cache.evict({
				id: `Emblem:${organizationId}`,
			});

			cache.gc();
		},
	};

	const [activateOrganizationMutation] = useMutation<
		Pick<Mutation, 'organizationAccountActivate'>,
		MutationOrganizationAccountActivateArgs
	>(ACTIVATE_ORGANIZATION, sharedOptions);
	const [deactivateOrganizationMutation] = useMutation<
		Pick<Mutation, 'organizationAccountDeactivate'>,
		MutationOrganizationAccountDeactivateArgs
	>(DEACTIVATE_ORGANIZATION, sharedOptions);

	const updateOrganizationActivation = async (operation: ActivationOperation) => {
		const mutation =
			operation === ActivationOperation.Activate
				? activateOrganizationMutation
				: deactivateOrganizationMutation;

		return await mutation({ variables: { organizationId } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push(
					`Successfully ${
						operation === ActivationOperation.Activate ? 'activated' : 'deactivated'
					} organization.`,
					{
						variant: 'success',
					}
				);

				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { updateOrganizationActivation };
};
