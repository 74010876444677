import { useQuery } from '@apollo/client';
import { Close } from '@mui/icons-material';
import { Dialog, Divider, IconButton, Stack, Typography } from '@mui/material';
import { GET_EMBLEM } from 'components/ui/emblem/hooks';
import { TextField } from 'components/ui/fields';
import { Formik, FormikErrors } from 'formik';
import { Emblem, EmblemEntityType, Participant, Query, QueryEmblemArgs } from 'middleware-types';
import { useSearchParams } from 'react-router-dom';
import { useIsMobile } from 'utils/useScreenSize';
import { useValidation } from 'utils/useValidation';
import { useCommunicationsContext } from '../communications-provider';
import { NewMessageFormValues } from '../conversation-column/conversation-message-box';
import { convertEntityType, removeTagsFromString } from '../helpers/utils';
import { useCreateConversation } from '../hooks/conversations-hooks';
import { MessageBox } from '../shared/message-box';
import { ParticipantSearchField } from '../shared/participant-search-field';

interface NewConversationFormValues extends NewMessageFormValues {
	subject: string;
	participants: Emblem[];
}

interface CreateConversationModalProps {
	open: boolean;
	onClose: () => void;
}

export const CreateConversationModal = ({ open, onClose }: CreateConversationModalProps) => {
	const isMobile = useIsMobile();
	const [searchParams] = useSearchParams();
	const entityId = searchParams.get('entityId');
	const entityType = searchParams.get('entityType');

	const { data } = useQuery<Pick<Query, 'emblem'>, QueryEmblemArgs>(GET_EMBLEM, {
		skip: !entityId || !entityType,
		variables: { entityId: entityId as string, entityType: entityType as EmblemEntityType },
	});

	return (
		<Dialog open={open} onClose={onClose} fullScreen={isMobile} fullWidth maxWidth="md">
			<Stack height={{ xs: '100%', sm: 'auto' }}>
				<Stack
					px={{ xs: 1.5, sm: 2.5 }}
					py={1}
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					spacing={1}>
					<Typography variant="h4">Create New Conversation</Typography>
					<IconButton onClick={onClose}>
						<Close />
					</IconButton>
				</Stack>
				<Divider />
				<Content onClose={onClose} initialParticipant={data?.emblem} />
			</Stack>
		</Dialog>
	);
};

interface ContentProps {
	onClose: () => void;
	initialParticipant: Emblem | undefined;
}

const Content = ({ onClose, initialParticipant }: ContentProps) => {
	const { entityId } = useCommunicationsContext();

	const initialValues: NewConversationFormValues = {
		subject: '',
		messageText: '',
		messageAttachments: [],
		participants: initialParticipant ? [initialParticipant] : [],
	};

	const validate = (values: NewConversationFormValues) => {
		const errors: FormikErrors<NewConversationFormValues> = {};
		if (!removeTagsFromString(values.messageText)) errors.messageText = 'Required Field';
		if (values.messageAttachments.some((a) => a.uploadProgress !== 1))
			errors.messageAttachments = 'Upload in progress';
		return errors;
	};

	const validation = useValidation('NewConversationRequest');

	const createConversation = useCreateConversation();
	const onSubmit = async (values: NewConversationFormValues) => {
		const participants: Participant[] = values.participants.map((p) => ({
			entityId: p.id,
			entityType: convertEntityType(p.entityType),
		}));
		await createConversation({
			subject: values.subject,
			participants,
			messageText: values.messageText,
			attachmentUploadTokens: values.messageAttachments.map((a) => a.uploadToken),
		}).then((success) => {
			if (success) onClose();
		});
	};

	return (
		<Formik<NewConversationFormValues>
			onSubmit={onSubmit}
			initialValues={initialValues}
			validate={validate}
			validationSchema={validation.schema}>
			<Stack p={{ xs: 1.5, sm: 2.5 }} flex={1} overflow="hidden" spacing={1}>
				<TextField label="Conversation Name" required name="subject" />
				<ParticipantSearchField
					name="participants"
					label="Participants"
					helperText="You can add or remove participants later."
					required
					omittedIds={[entityId]}
				/>
				<Stack spacing={0.5} flex={1} overflow="hidden">
					<Typography variant="h6">Initial Message *</Typography>
					<Stack
						flex={1}
						overflow="hidden"
						minHeight={{ xs: 'auto', sm: 250 }}
						maxHeight={{ xs: 'auto', sm: 350 }}>
						<MessageBox isNewConversation />
					</Stack>
				</Stack>
			</Stack>
		</Formik>
	);
};
