import { ConversationApiEntityType } from 'middleware-types';
import { createContext, useContext } from 'react';
import { LabelWithColor } from './helpers/label-colors';

interface CommunicationsContextState {
	entityType: ConversationApiEntityType;
	entityId: string;
	readOnly: boolean;
	labels: LabelWithColor[];
	labelsLoading: boolean;
	selectedLabelId: string | null;
	setSelectedLabelId: (value: string) => void;
	selectedLabel: LabelWithColor | undefined;
	selectedConversationId: string | null;
	setSelectedConversationId: (value: string | null) => void;
	setNewConversationModalOpen: (value: boolean) => void;
}

const CommunicationsContext = createContext<CommunicationsContextState | undefined>(undefined);

export const CommunicationsProvider = ({
	value,
	children,
}: {
	value: CommunicationsContextState;
	children: React.ReactNode;
}) => {
	return (
		<CommunicationsContext.Provider value={value}>{children}</CommunicationsContext.Provider>
	);
};

export const useCommunicationsContext = () => {
	const value = useContext(CommunicationsContext);
	if (value === undefined)
		throw new Error('useCommunicationsContext must be used inside a CommunicationsProvider');
	return value;
};
