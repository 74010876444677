import { gql, useQuery } from '@apollo/client';
import { Query, QueryConversationSearchArgs } from 'middleware-types';
import { useCommunicationsContext } from '../communications-provider';
import { CONVERSATION_SUMMARY_FIELDS } from '../fragments.graphql';
import { useToast } from 'components/ui/toast';
import { useCallback, useState } from 'react';

const PAGE_SIZE = 25;

export const CONVERSATION_SEARCH = gql`
	${CONVERSATION_SUMMARY_FIELDS}
	query ConversationSearch(
		$entityType: ConversationApiEntityType!
		$entityId: String!
		$labelId: String!
		$searchText: String!
		$pageSize: Int!
		$offset: Int!
	) {
		conversationSearch(
			entityType: $entityType
			entityId: $entityId
			labelId: $labelId
			searchText: $searchText
			pageSize: $pageSize
			offset: $offset
		) {
			totalCount
			labelId
			conversationSummaries {
				...ConversationSummaryFields
			}
		}
	}
`;

export const useConversationSearch = (labelId: string, searchText: string) => {
	const toast = useToast();
	const { entityType, entityId } = useCommunicationsContext();
	const {
		data,
		loading,
		fetchMore: _fetchMore,
	} = useQuery<Pick<Query, 'conversationSearch'>, QueryConversationSearchArgs>(
		CONVERSATION_SEARCH,
		{
			variables: {
				entityType,
				entityId,
				labelId,
				searchText,
				offset: 0,
				pageSize: PAGE_SIZE,
			},
			fetchPolicy: 'network-only',
			onError: () => toast.push('Unable to load conversations', { variant: 'error' }),
		}
	);

	const [isRefetching, setIsRefetching] = useState(false);
	const loadMore = useCallback(async () => {
		if (!data) return;
		setIsRefetching(true);
		await _fetchMore({
			variables: { offset: data.conversationSearch.conversationSummaries.length },
		});
		setIsRefetching(false);
	}, [data]);

	const summaries = data?.conversationSearch.conversationSummaries ?? [];
	const canLoadMore =
		!loading &&
		!isRefetching &&
		data !== undefined &&
		data.conversationSearch.conversationSummaries.length < data.conversationSearch.totalCount;

	return { summaries, loading, loadMore, canLoadMore, isRefetching };
};
