import { Drawer, DrawerProps } from '@mui/material';

interface ContainedDrawerProps extends DrawerProps {
	containerid: string;
}

export const ContainedDrawer = (props: ContainedDrawerProps) => {
	return (
		<Drawer
			{...props}
			PaperProps={{ ...props.PaperProps, style: { position: 'absolute' } }}
			slotProps={{
				backdrop: { style: { position: 'absolute' } },
			}}
			ModalProps={{
				container: document.getElementById(props.containerid),
				style: { position: 'absolute' },
			}}
		/>
	);
};
