import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { Alert } from 'components/ui/alert';
import { FileFieldUploadValues } from 'components/ui/emblem/user-emblem-uploader';
import { FileField } from 'components/ui/fields';
import { FileType } from 'components/ui/fields/file';
import { ModalActionButton, ModalActions, ModalContent, useModal } from 'components/ui/modal';
import { useToast } from 'components/ui/toast';
import { Formik } from 'formik';
import { Mutation, UserChatFile } from 'middleware-types';
import { useState } from 'react';
import { handleNoResponse, responseHasErrors } from 'utils/errors';

/**
 * Hook for opening the profile picture uploader.
 */
export const useEvoxFileUploader = ({
	userId,
	setRequestFiles,
}: {
	userId: string;
	setRequestFiles: React.Dispatch<React.SetStateAction<UserChatFile[]>>;
}) => {
	const { showModal } = useModal();
	const openEvoxFileUploader = () => {
		showModal({
			title: 'Attach File',
			maxWidth: 'md',
			content: <EvoxFileUploaderModal userId={userId} setRequestFiles={setRequestFiles} />,
		});
	};

	return { openEvoxFileUploader };
};

const EvoxFileUploaderModal = ({
	userId,
	setRequestFiles,
}: {
	userId: string;
	setRequestFiles: React.Dispatch<React.SetStateAction<UserChatFile[]>>;
}) => {
	const { uploadFile, error } = useEvoxFileMutation();
	const { closeModal } = useModal();
	const toast = useToast();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isUploading, setIsUploading] = useState(false);

	return (
		<Formik<{ evoxFile: FileFieldUploadValues }>
			initialValues={{
				evoxFile: {
					uploadToken: '',
				},
			}}
			onSubmit={(values: { evoxFile: FileFieldUploadValues }) => {
				uploadFile({
					variables: {
						userId,
						request: {
							fileUploadToken: values.evoxFile.uploadToken ?? undefined,
						},
					},
				})
					.then((res) => {
						if (responseHasErrors(res.errors, { toast })) {
							return false;
						}
						if (!res.data?.chatFileRequest) {
							toast.push(
								'There was an issue uploading this file. Please try again later.',
								{
									variant: 'error',
								}
							);
							return false;
						}
						setRequestFiles((prev) => {
							// For some reason it knows chatFileRequest isn't undefined
							// until it's put in an array with `...prev,`.
							if (!res.data) return prev;
							return [...prev, res.data?.chatFileRequest];
						});
						closeModal();
					})
					.catch(() => handleNoResponse({ toast }));
			}}>
			{({ submitForm, isValid, dirty, isSubmitting }) => (
				<>
					<ModalContent>
						<Alert error={error} />
						<h3 className="mt-0">Drag & drop a file below or click to attach</h3>
						<FileField
							name="evoxFile"
							type={FileType.Evox}
							setIsUploading={setIsUploading}
						/>
					</ModalContent>
					<ModalActions>
						<Grid container justifyContent="flex-end" spacing={1}>
							<Grid item>
								<ModalActionButton variant="outlined">Cancel</ModalActionButton>
							</Grid>
							<Grid item>
								<LoadingButton
									variant="contained"
									color="primary"
									onClick={submitForm}
									disabled={!isValid || !dirty || isSubmitting}
									loading={isSubmitting}>
									Attach
								</LoadingButton>
							</Grid>
						</Grid>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};

/**
 * Hook to update the user profile picture
 */
export const useEvoxFileMutation = () => {
	const [uploadFile, { loading, error }] = useMutation<Pick<Mutation, 'chatFileRequest'>>(
		gql`
			mutation chatFileRequest($userId: ID!, $request: ChatFileRequest!) {
				chatFileRequest(userId: $userId, request: $request) {
					chatFileId
					name
				}
			}
		`
	);

	return { uploadFile, loading, error };
};
