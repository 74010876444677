import { gql } from '@apollo/client';
import { Mutation, MutationCommitFileArgs } from 'middleware-types';
import { client } from 'utils/apollo';
import { updateFolderContents } from '../../utils/cache-helpers';
import { FILE_INFO_FIELDS } from '../../utils/fragments.graphql';

const COMMIT_FILE = gql`
	${FILE_INFO_FIELDS}
	mutation CommitFile(
		$entityType: FoldersApiEntityType!
		$entityId: String!
		$request: FileUploadCommitRequest!
	) {
		commitFile(entityType: $entityType, entityId: $entityId, request: $request) {
			...FileInfoFields
		}
	}
`;

export const commitFile = async (variables: MutationCommitFileArgs) => {
	const {
		entityType,
		entityId,
		request: { folderId },
	} = variables;
	await client.mutate<Pick<Mutation, 'commitFile'>, MutationCommitFileArgs>({
		mutation: COMMIT_FILE,
		variables,
		update: (cache, { data }) => {
			if (!data) return;
			updateFolderContents({ cache, folderId, entityType, entityId }, (existing) => ({
				...existing,
				files: [...(existing.files ?? []), data.commitFile],
			}));
		},
	});
};
