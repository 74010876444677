import { Assignment } from '@mui/icons-material';
import {
	Avatar,
	Box,
	Card,
	CardActionArea,
	CardContent,
	Chip,
	Stack,
	Typography,
} from '@mui/material';
import {
	ClaimSummarySubset,
	LegacyClaimSummary,
	LegacyUserAdjustmentCompanyWithMetrics,
} from 'middleware-types';
import { postLegacyAuth, useSession } from 'utils/session';

export const ClaimSummaryCard = ({
	claim,
	company,
	subset,
}: {
	claim: LegacyClaimSummary;
	company: LegacyUserAdjustmentCompanyWithMetrics;
	subset: ClaimSummarySubset;
}) => {
	const { user } = useSession();
	const city = (claim.lossCity ?? '').trim();
	const state = (claim.lossState ?? '').trim();
	return (
		<Card variant="outlined" sx={{ flexShrink: 0 }}>
			<CardActionArea
				onClick={() =>
					postLegacyAuth(
						company.systemUrl,
						company.userId,
						user.userId,
						`claimList.asp?allBranches=1&expand=${claim.claimId}&searchType=claimID&searchTgt=${claim.claimId}`
					)
				}>
				<CardContent>
					<Stack direction="row" spacing={2} alignItems="center">
						<Avatar>
							<Assignment />
						</Avatar>
						<Box flex={1}>
							<Typography variant="h3">Claim #: {claim.companyFileId}</Typography>
							<Typography display="inline">
								{claim.insuredFirstName} {claim.insuredLastName}
							</Typography>
							{city && state && (
								<Typography display="inline">
									{' '}
									· {city}, {state}
								</Typography>
							)}
							<Typography>{claim.company}</Typography>
						</Box>
						<StatusChip
							subset={subset}
							dueDate={claim.dueDate}
							receievedDate={claim.receivedDate}
						/>
					</Stack>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

const StatusChip = ({
	subset,
	dueDate,
	receievedDate,
}: {
	subset: ClaimSummarySubset;
	dueDate: string;
	receievedDate: string;
}) => {
	if (subset === ClaimSummarySubset.Overdue) {
		return <Chip label="OVERDUE" color="error" />;
	}

	const today = new Date().setHours(0, 0, 0, 0);

	if (subset === ClaimSummarySubset.DueNext3) {
		const inputDate = new Date(dueDate).setHours(0, 0, 0, 0);

		const tomorrowDate = new Date();
		tomorrowDate.setDate(tomorrowDate.getDate() + 1);
		const tomorrow = tomorrowDate.setHours(0, 0, 0, 0);

		let text = '';

		if (inputDate === today) text = 'DUE TODAY';
		else if (inputDate === tomorrow) text = 'DUE TOMORROW';
		else return null;

		return <Chip label={text} color="warning" />;
	}

	if (subset === ClaimSummarySubset.RecLast3) {
		const inputDate = new Date(receievedDate).setHours(0, 0, 0, 0);

		const yesterdayDate = new Date();
		yesterdayDate.setDate(yesterdayDate.getDate() - 1);
		const yesterday = yesterdayDate.setHours(0, 0, 0, 0);

		let text = '';

		if (inputDate === today) text = 'RECEIVED TODAY';
		else if (inputDate === yesterday) text = 'RECEIVED YESTERDAY';
		else return null;

		return <Chip label={text} color="success" />;
	}

	// we should never get here, but just to make the linter happy
	return null;
};
