import { useQuery } from '@apollo/client';
import { Loading } from 'components/ui/loading';
import { useToast } from 'components/ui/toast';
import { gql } from 'graphql-tag';
import {
	EmblemEntityType,
	ParticipantEntityType,
	Query,
	QueryConversationParticipantGetArgs,
	QueryEmblemArgs,
	RelatedEntityType,
} from 'middleware-types';
import { Navigate, useParams } from 'react-router-dom';
import { useSession } from 'utils/session';

const GET_HANDLE = gql`
	query emblem($entityType: EmblemEntityType!, $entityId: ID!) {
		emblem(entityType: $entityType, entityId: $entityId) {
			id
			handle
		}
	}
`;

const GET_CONVERSATION_PARTICIPANT = gql`
	query ConversationParticipantGet($conversationId: String!, $userId: String!) {
		conversationParticipantGet(conversationId: $conversationId, userId: $userId) {
			entityId
			entityType
		}
	}
`;

/**
 * The Notification Redirect page.
 * @returns
 */
export const RedirectPage = () => {
	const { entityType, entityId } = useParams<{
		entityType: string;
		entityId: string;
	}>();

	const entityTypeString = entityType?.toLowerCase();

	const user = (RelatedEntityType.User as string).toLowerCase();
	const organization = (RelatedEntityType.Organization as string).toLowerCase();
	const message = (RelatedEntityType.Message as string).toLowerCase();

	if (!entityId) return <Navigate to="/" />;
	switch (entityTypeString) {
		case user:
			return <UserRedirect entityId={entityId} />;
		case organization:
			return <OrgRedirect entityId={entityId} />;
		case message:
			return <MessageRedirect entityId={entityId} />;
		default:
			return <Navigate to="/" />;
	}
};

/**
 * redirect to a user
 */
const UserRedirect = ({ entityId }: { entityId: string }) => {
	const toast = useToast();
	const { data, loading, error } = useQuery<Pick<Query, 'emblem'>, QueryEmblemArgs>(GET_HANDLE, {
		variables: { entityType: EmblemEntityType.User, entityId },

		onError: () => {
			toast.push(
				'Error redirecting from notification. Please try again or contact support.',
				{
					variant: 'error',
				}
			);
			console.log(error);
		},
	});

	if (loading) return <Loading />;
	return <Navigate to={`/${data?.emblem?.handle}`} />;
};

/**
 * redirect to an org
 */
const OrgRedirect = ({ entityId }: { entityId: string }) => {
	const toast = useToast();
	const { data, loading, error } = useQuery<Pick<Query, 'emblem'>, QueryEmblemArgs>(GET_HANDLE, {
		variables: { entityType: EmblemEntityType.Organization, entityId },
		onError: () => {
			toast.push(
				'Error redirecting from notification. Please try again or contact support.',
				{
					variant: 'error',
				}
			);
			console.log(error);
		},
	});

	if (loading) return <Loading />;
	return <Navigate to={`/${data?.emblem?.handle}`} />;
};

/**
 * redirect to a conversation
 */
const MessageRedirect = ({ entityId }: { entityId: string }) => {
	const toast = useToast();
	const userId = useSession().user.userId;

	const { data, loading, error } = useQuery<
		Pick<Query, 'conversationParticipantGet'>,
		QueryConversationParticipantGetArgs
	>(GET_CONVERSATION_PARTICIPANT, {
		fetchPolicy: 'no-cache',
		variables: { conversationId: entityId, userId },
		onError: () => {
			toast.push(
				'This conversation no longer exists in any message box you have access to.',
				{
					variant: 'error',
				}
			);
			console.log(error);
		},
	});

	if (loading) return <Loading />;
	switch (data?.conversationParticipantGet.entityType) {
		case ParticipantEntityType.User:
			return <Navigate to={`/app/conversations?conversationId=${entityId}`} />;
		case ParticipantEntityType.Organization:
			return (
				<OrgMessageRedirect
					orgId={data.conversationParticipantGet.entityId}
					conversationId={entityId}
				/>
			);
		default:
			return <Navigate to="/" />;
	}
};

const OrgMessageRedirect = ({
	orgId,
	conversationId,
}: {
	orgId: string;
	conversationId: string;
}) => {
	const toast = useToast();
	const { data, loading, error } = useQuery<Pick<Query, 'emblem'>, QueryEmblemArgs>(GET_HANDLE, {
		variables: { entityType: EmblemEntityType.Organization, entityId: orgId },
		onError: () => {
			toast.push(
				'This conversation no longer exists in any message box you have access to.',
				{
					variant: 'error',
				}
			);
			console.log(error);
		},
	});

	if (loading) return <Loading />;
	return (
		<Navigate
			to={`/orgs/${data?.emblem?.handle}/conversations?conversationId=${conversationId}`}
		/>
	);
};
