import { useMutation } from '@apollo/client';
import { Mutation, MutationUserRegistrationArgs, UserRegistration } from 'middleware-types';
import {
	Button,
	Card,
	CardContent,
	Container,
	Grid,
	IconButton,
	TextField as MTTextField,
	Skeleton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { Alert } from 'components/ui/alert';
import {
	AddressField,
	AddressFieldSkeleton,
	DatePickerField,
	PhoneNumberField,
	TextField,
} from 'components/ui/fields';
import { useToast } from 'components/ui/toast';
import { format } from 'date-fns';
import { Formik, FormikProps } from 'formik';
import gql from 'graphql-tag';
import { Navigate } from 'react-router-dom';
import { logout, refreshToken, useSession } from 'utils/session';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useDefaults } from 'utils/useDefaults';
import { geocode } from 'utils/useGeocode';
import { useValidation } from 'utils/useValidation';
import { LightShell } from 'components/ui/shell';
import { PageTitle } from 'components/ui/page';
import { InfoOutlined } from '@mui/icons-material';

/**
 * useRegistrationMutation(userId) - mutation wrapper for registering a user.
 *
 * @param {(string | undefined)} userId
 * @return {*}
 */
export const useRegistrationMutation = (userId: string | undefined) => {
	const [updateProfile, { error }] = useMutation<
		Pick<Mutation, 'userRegistration'>,
		MutationUserRegistrationArgs
	>(
		gql`
			mutation Register($userId: ID!, $registration: UserRegistration!) {
				userRegistration(userId: $userId, registration: $registration) {
					id
					emailAddress
					handle
					name {
						firstName
						lastName
					}
					cellPhone {
						countryCode
						number
					}
				}
			}
		`,
		{
			update: async (cache) => {
				await refreshToken();
				cache.evict({
					id: 'ROOT_QUERY',
					fieldName: 'loginInformation',
				});
				cache.evict({
					id: `UserAccount:${userId}`,
				});
				cache.evict({
					id: `UserEmblem:${userId}`,
				});
				cache.evict({
					id: `UserQuery:{}`,
					fieldName: 'loginInformation',
				});
				cache.gc();
			},
		}
	);

	const register = async (registration: UserRegistration) => {
		if (!userId) return;
		return await updateProfile({
			variables: {
				userId,
				registration,
			},
		});
	};

	return {
		register,
		error,
	};
};

/**
 * RegistrationUpdate - Value type for the registration mutation.
 */
interface RegistrationUpdateValues extends Omit<UserRegistration, 'birthDate' | 'languageIds'> {
	birthDate: Date | undefined | null;
}

/**
 * useProfileForm() is a custom hook to handle all the data-loading
 * and processing for the profile form.
 *
 * @returns
 */
export const useRegistrationForm = () => {
	const { user } = useSession();
	const toast = useToast();
	const { register, error } = useRegistrationMutation(user.userId);

	const validation = useValidation('UserRegistration');
	const defaults = useDefaults();

	const initialValues: RegistrationUpdateValues = {
		name: {
			firstName: user.siteUserInvitation?.firstName ?? '',
			lastName: user.siteUserInvitation?.lastName ?? '',
		},
		cellPhone: {
			countryCode: defaults.country.phonePrefix,
			number: '',
		},
		birthDate: null,
		primaryAddress: {
			countryId: defaults.country.id,
			address1: '',
			address2: '',
			municipality: '',
			adminArea1Id: '',
			adminArea2Id: '',
			postalCode: '',
			coordinate: undefined,
		},
	};

	const onSubmit = async (values: RegistrationUpdateValues): Promise<void> => {
		// Run geocode query on primary address in background before registering
		if (!values.primaryAddress) return Promise.resolve();
		await geocode(values.primaryAddress)
			.then((location) =>
				register({
					...values,
					birthDate: values?.birthDate ? format(values?.birthDate, 'yyyy-MM-dd') : null,
					primaryAddress: {
						...values.primaryAddress,
						coordinate: location,
					},
					languageIds: [],
				})
			)
			.then(async (res) => {
				if (responseHasErrors(res?.errors, { toast })) {
					return;
				}
				toast.push('Thank you for submitting your profile information.', {
					variant: 'success',
				});
				return;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return;
			});

		return;
	};

	return {
		loading: defaults.loading || validation.loading,
		validationSchema: validation.schema,
		initialValues,
		onSubmit,
		error,
	};
};

/**
 * RegistrationRoutes are all routes related to registration and it's sub-pages.
 *
 * @returns
 */
export const RegisterPage = () => {
	const { loading, validationSchema, initialValues, onSubmit, error } = useRegistrationForm();
	const { user } = useSession();

	if (user.registered) {
		return <Navigate to="/" />;
	}

	return (
		<LightShell right={<Button onClick={logout}>Logout</Button>}>
			<PageTitle title="Register" />
			<Container maxWidth="xl">
				<Grid container justifyContent="center">
					<Grid item sm={1} md={2} lg={3} xl={4} />
					<Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
						<>
							<Formik<RegistrationUpdateValues>
								initialValues={initialValues}
								onSubmit={onSubmit}
								validationSchema={validationSchema}
								enableReinitialize>
								{(props: FormikProps<RegistrationUpdateValues>) => (
									<Stack
										alignItems="center"
										justifyContent="center"
										mt={2}
										spacing={2}>
										<img width={75} src="/img/evolve-logo.svg" alt="FTEvolve" />
										<Typography variant="h1">
											Your account is almost ready.
										</Typography>
										<Typography variant="body2" width="55%" textAlign="center">
											Please complete the form below to validate your user
											account. No information input in this form will be
											shared with third parties.
										</Typography>
										<Card>
											<CardContent>
												<Stack gap={2}>
													{loading ? (
														<RegisterSkeleton />
													) : (
														<>
															<Typography variant="h3">
																General Information
															</Typography>
															<Grid
																container
																spacing={1}
																columnSpacing={2}>
																<Grid xs={12} md={6} item>
																	<TextField
																		label="First Name"
																		required
																		name="name.firstName"
																		fullWidth
																		disabled={
																			props.isSubmitting
																		}
																	/>
																</Grid>
																<Grid xs={12} md={6} item>
																	<TextField
																		label="Last Name"
																		required
																		name="name.lastName"
																		fullWidth
																		disabled={
																			props.isSubmitting
																		}
																	/>
																</Grid>
																<Grid xs={12} md={6} item>
																	<PhoneNumberField
																		label="Cell Phone Number"
																		required
																		name="cellPhone"
																		disabled={
																			props.isSubmitting
																		}
																	/>
																</Grid>
																<Grid xs={12} md={6} item>
																	<DatePickerField
																		label="Date of Birth"
																		name="birthDate"
																		disabled={
																			props.isSubmitting
																		}
																	/>
																</Grid>
															</Grid>
															<Stack
																direction="row"
																justifyContent="space-between"
																alignItems="center">
																<Typography
																	variant="h3"
																	display="inline">
																	Primary Address
																</Typography>
																<Tooltip
																	title="Your primary address is used to find insurance work in your area."
																	arrow
																	placement="right-end">
																	<IconButton>
																		<InfoOutlined fontSize="small" />
																	</IconButton>
																</Tooltip>
															</Stack>
															<AddressField
																name="primaryAddress"
																required
																disabled={props.isSubmitting}
															/>
															<Button
																color="primary"
																variant="contained"
																onClick={props.submitForm}
																disabled={
																	!props.isValid ||
																	!props.dirty ||
																	props.isSubmitting
																}>
																Submit Registration
															</Button>
															<Alert error={error} />
														</>
													)}
												</Stack>
											</CardContent>
										</Card>
									</Stack>
								)}
							</Formik>
						</>
					</Grid>
					<Grid item sm={1} md={2} lg={3} xl={4} />
				</Grid>
			</Container>
		</LightShell>
	);
};

/**
 * A loading skeleton for the profile registration form.
 *
 * @returns
 */
const RegisterSkeleton = () => {
	return (
		<>
			<Typography variant="h3">General Information</Typography>
			<Grid container spacing={1} columnSpacing={2}>
				<Grid xs={12} md={6} item>
					<Skeleton width="100%">
						<MTTextField />
					</Skeleton>
				</Grid>
				<Grid xs={12} md={6} item>
					<Skeleton width="100%">
						<MTTextField />
					</Skeleton>
				</Grid>
				<Grid xs={12} md={6} item>
					<Skeleton width="100%">
						<MTTextField />
					</Skeleton>
				</Grid>
				<Grid xs={12} md={6} item>
					<Skeleton width="100%">
						<MTTextField />
					</Skeleton>
				</Grid>
			</Grid>
			<Typography variant="h3">Primary Address</Typography>
			<AddressFieldSkeleton />
		</>
	);
};
