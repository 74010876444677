import { gql, useMutation } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	FileAccessLevelChangeRequest,
	Mutation,
	MutationFileInstanceAccessLevelChangeArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { FILE_INSTANCE_INFO_FIELDS } from '../../utils/fragments.graphql';

const CHANGE_INSTANCE_ACCESS_LEVEL = gql`
	${FILE_INSTANCE_INFO_FIELDS}
	mutation ChangeInstanceAccessLevel(
		$fileInstanceId: String!
		$request: FileAccessLevelChangeRequest!
	) {
		fileInstanceAccessLevelChange(fileInstanceId: $fileInstanceId, request: $request) {
			...FileInstanceInfoFields
		}
	}
`;

export const useInstanceAccessLevelChange = () => {
	const toast = useToast();

	const [_changeInstanceAccessLevel] = useMutation<
		Pick<Mutation, 'fileInstanceAccessLevelChange'>,
		MutationFileInstanceAccessLevelChangeArgs
	>(CHANGE_INSTANCE_ACCESS_LEVEL, { onError: (e) => console.log(JSON.stringify(e)) });

	const changeInstanceAccessLevel = async (
		fileInstanceId: string,
		request: FileAccessLevelChangeRequest
	) => {
		return await _changeInstanceAccessLevel({ variables: { fileInstanceId, request } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Privacy updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return changeInstanceAccessLevel;
};
