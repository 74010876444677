import { gql, useQuery } from '@apollo/client';
import { Stack } from '@mui/material';
import { SiteUserInformation } from 'components/pages/user/account/siteuser';
import { Loading } from 'components/ui/loading';
import { Query, QueryAccountArgs } from 'middleware-types';
import { PageError } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { useSiteUser } from 'utils/useSiteUser';

/**
 * Site User account edit screen.
 * @returns
 */
export const SiteUserAccountPage = ({ userId }: { userId: string }) => {
	const { hasPermission } = useSiteUser();
	const { data, loading, error } = useQuery<Pick<Query, 'account'>, QueryAccountArgs>(
		gql`
			query account($userId: ID!) {
				account(userId: $userId) {
					id
					siteUserId
				}
			}
		`,
		{
			fetchPolicy: 'cache-first',
			variables: {
				userId,
			},
		}
	);

	if (loading) return <Loading />;
	if (error) throw new PageError(error);
	if (!data?.account?.siteUserId) throw new PageError('404 Not Found');
	if (!hasPermission(Permission.Site_User_R)) throw new PageError('403 Forbidden');

	return (
		<Stack spacing={2}>
			<SiteUserInformation siteUserId={data?.account?.siteUserId} />
		</Stack>
	);
};
