import { LockPersonOutlined, LockResetOutlined } from '@mui/icons-material';
import { CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useGetCognitoUser } from 'components/pages/user/account/security/hooks';
import { useModal } from 'components/ui/modal';
import { ResetMfaModal } from './reset-mfa-modal';

interface MfaSectionProps {
	userId: string;
	canEdit: boolean;
}

export const MfaSection = ({ userId, canEdit }: MfaSectionProps) => {
	const { loading, user } = useGetCognitoUser(userId);
	const { showModal } = useModal();
	return (
		<Grid container item xs={12} alignItems="center">
			<Grid item xs={12} sm={4}>
				<Stack direction="row" spacing={{ xs: 2, sm: 3 }}>
					<LockPersonOutlined fontSize="small" />
					<Typography variant="h5">MFA</Typography>
				</Stack>
			</Grid>
			<Grid item xs>
				{user?.preferredMfaSetting === 'SMS_MFA' && <Typography>SMS</Typography>}
				{user?.preferredMfaSetting === 'SOFTWARE_TOKEN_MFA' && (
					<Typography>Authenticator</Typography>
				)}
				{!user?.preferredMfaSetting &&
					(loading ? (
						<CircularProgress style={{ height: `1rem`, width: `1rem` }} />
					) : (
						<Typography>NO MFA CONFIGURED</Typography>
					))}
			</Grid>
			{user?.preferredMfaSetting && canEdit && (
				<Grid item xs="auto">
					<IconButton
						onClick={() =>
							showModal({
								title: 'Reset MFA',
								content: <ResetMfaModal userId={userId} />,
							})
						}>
						<LockResetOutlined />
					</IconButton>
				</Grid>
			)}
		</Grid>
	);
};
