import { QuickLink } from 'middleware-types';
import { useDownloadUrl } from 'utils/fileUtils';

export const useQuickLinkIconUrl = (quickLink: QuickLink, skip = false) => {
	const fileId = quickLink.iconFile?.currentInstance?.id;
	const { data } = useDownloadUrl({
		skip: !fileId || skip,
		variables: { fileInstanceId: fileId!, sasUrlOnly: true },
	});
	return data?.getFileInstanceDownloadUrl.downloadUrl;
};
