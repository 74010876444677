import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Collapse,
	Grid,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { Alert } from 'components/ui/alert';
import { Loading } from 'components/ui/loading';
import { PageContent, PageTitle } from 'components/ui/page';
import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { version } from '../../../../package.json';
import { AppSettings, Query } from 'middleware-types';
/**
 * Type AppSetting for name SimpleAppSetting component.
 */
export type AppSetting = {
	name: string;
	value: AppSettingValue;
};
type AppSettingValue = string | AppSetting[] | null | undefined;

/**
 *
 * Component that runs useQuery to store system diagnostics
 * @return {*}
 */
export const useSystemDiagnosticsQuery = () => {
	const { loading, error, data } = useQuery<Pick<Query, 'environment' | 'appSettings'>>(gql`
		query getDiagnostics {
			environment {
				environmentName
			}
			appSettings {
				name
				value
				subSettings {
					name
					value
					subSettings {
						name
						value
						subSettings {
							name
							value
						}
					}
				}
			}
		}
	`);
	return {
		loading,
		error,
		envName: data?.environment?.environmentName,
		appSettings: data?.appSettings ? toAppSetting(data?.appSettings) : undefined,
	};
};

/**
 * Component that renders system diagnostics.
 *
 * @return {*}
 */
export const DiagnosticsPage: React.FC = () => {
	const { loading, error, envName, appSettings } = useSystemDiagnosticsQuery();

	if (loading) return <Loading />;

	function createSentryError() {
		class TestSentryError extends Error {
			constructor(message: string) {
				super(message);
				this.name = `TestSentryError: ${message} from ${version}`;
			}
		}

		throw new TestSentryError('Houston we have a problem');
	}

	return (
		<PageContent>
			<PageTitle title="System Diagnostics" />
			<Typography variant="h1" gutterBottom>
				System Diagnostics
			</Typography>
			<Grid container>
				<Grid item xs={12}>
					<Stack spacing={1}>
						<Card variant="outlined">
							<CardHeader
								titleTypographyProps={{
									variant: 'h4',
								}}
								title="System Information"
							/>
							<CardContent>
								<Stack spacing={2}>
									<Typography
										variant="body1"
										sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
										{envName} Version: {version}
									</Typography>
									<Stack spacing={2} alignItems="start">
										<Typography variant="h5">Testing</Typography>
										<Button
											onClick={createSentryError}
											variant="contained"
											color="primary">
											Test Sentry
										</Button>
									</Stack>
								</Stack>
							</CardContent>
						</Card>
						{error && <Alert error={error} severity="warning" />}
						{appSettings && <ComplexAppSetting appSetting={appSettings} />}
					</Stack>
				</Grid>
			</Grid>
		</PageContent>
	);
};

/**
 * Fetches all appSettings data
 *
 * @param {AppSettingsResponse} [as]
 * @return {*}
 */
const toAppSetting = (as: AppSettings): AppSetting => {
	return {
		name: as.name,
		value: as.value ?? as.subSettings?.map((ss) => toAppSetting(ss)),
	};
};

/**
 *
 * Renders system diagnostics data with no subsettings into table rows
 * @param {{ name: string; value: string }} { name, value }
 * @return {*}
 */
export const SimpleAppSetting = ({ name, value }: { name: string; value: string }) => {
	return (
		<TableRow>
			<Tooltip title={name} placement="bottom-start" enterDelay={1300} arrow>
				<TableCell width="50%" align="left">
					{name}
				</TableCell>
			</Tooltip>
			<Tooltip title={value} placement="bottom-start" enterDelay={1300} arrow>
				<TableCell width="50%" align="left">
					{value}
				</TableCell>
			</Tooltip>
		</TableRow>
	);
};

/**
 * Recursively renders system diagnostics data into nested tables with name/value pairs.
 *
 * @param {{ appSetting: AppSetting }} { appSetting }
 * @return {*}
 */
export const ComplexAppSetting = ({ appSetting }: { appSetting: AppSetting }) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	if (!appSetting.value) {
		return (
			<Card variant="outlined">
				<CardHeader
					titleTypographyProps={{
						variant: 'body1',
					}}
					title={appSetting?.name}
				/>
			</Card>
		);
	}

	if (typeof appSetting.value == 'string') {
		return <SimpleAppSetting name={appSetting.name} value={appSetting.value} />;
	}

	const simpleSettings = appSetting.value.filter((ss) => typeof ss.value == 'string');
	const complexSettings = appSetting.value.filter((ss) => typeof ss.value != 'string');

	return (
		<Card variant="outlined">
			<CardHeader
				titleTypographyProps={{
					variant: 'h4',
				}}
				sx={{ cursor: 'pointer' }}
				title={appSetting?.name}
				action={
					isExpanded ? (
						<KeyboardArrowDownOutlined sx={{ verticalAlign: 'middle' }} />
					) : (
						<KeyboardArrowUpOutlined sx={{ verticalAlign: 'middle' }} />
					)
				}
				onClick={() => setIsExpanded(!isExpanded)}
			/>

			<Collapse in={isExpanded}>
				<CardContent>
					<Stack spacing={1}>
						{simpleSettings.length > 0 && (
							<TableContainer>
								<Table stickyHeader>
									<TableHead>
										<TableRow>
											<TableCell align="left">Name</TableCell>
											<TableCell align="left">Value</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{simpleSettings.map((ss, index) => (
											<SimpleAppSetting
												key={index}
												name={ss.name}
												value={ss.value as string}
											/>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}

						{complexSettings.map((cs, index) => (
							<ComplexAppSetting key={index} appSetting={cs} />
						))}
					</Stack>
				</CardContent>
			</Collapse>
		</Card>
	);
};
