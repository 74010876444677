import { Grid, GridProps } from '@mui/material';
import { FileInstanceInformation, FolderReference } from 'middleware-types';
import { FileGridItem } from './file-grid-item';
import { FolderGridItem } from './folder-grid-item';
import { useVersionHistoryContext } from './version-history-provider';

const gridItemProps: GridProps = {
	xs: 6,
	md: 4,
	xl: 3,
	xxl: 2,
};

interface DocumentsGridProps {
	folders: FolderReference[];
	files: FileInstanceInformation[];
}

export const DocumentsGrid = ({ folders, files }: DocumentsGridProps) => {
	const { isVersionHistory } = useVersionHistoryContext();
	return (
		<Grid container spacing={2}>
			{folders.map((folder) => (
				<Grid key={folder.id} item {...gridItemProps}>
					<FolderGridItem folder={folder} />
				</Grid>
			))}
			{files.map((file) => (
				<Grid key={isVersionHistory ? file.id : file.fileId} item {...gridItemProps}>
					<FileGridItem file={file} />
				</Grid>
			))}
		</Grid>
	);
};
