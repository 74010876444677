import { createContext, useContext, useState } from 'react';

interface LoginRedirectContextState {
	redirectUrl: string | null;
	setRedirectUrl: React.Dispatch<React.SetStateAction<string | null>>;
}

const LoginRedirectContext = createContext<LoginRedirectContextState | undefined>(undefined);

export const LoginRedirectProvider = ({ children }: { children: React.ReactNode }) => {
	const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
	return (
		<LoginRedirectContext.Provider value={{ redirectUrl, setRedirectUrl }}>
			{children}
		</LoginRedirectContext.Provider>
	);
};

export const useLoginRedirect = () => {
	const value = useContext(LoginRedirectContext);
	if (value === undefined)
		throw new Error('useLoginRedirect must be used inside a LoginRedirectProvider');
	return value;
};
