import {
	CloseOutlined,
	ErrorOutlineRounded,
	TaskAltOutlined,
	WarningAmberOutlined,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { MaterialDesignContent, SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import React from 'react';
import tailwindConfig from '../../../tailwind.config';

export type ToastOptions = {
	variant: 'success' | 'warning' | 'error' | 'info' | 'default' | undefined;
};

export type ToastState = {
	push: (msg: string, properties?: ToastOptions) => void;
};

/** Customized Snackbars for Notistack
 * This method is used to override existing vairants styles provided by Notistack
 * Docs for reference can be found here:
 * https://notistack.com/features/customization
 */
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
	'&.notistack-MuiContent-info': {
		backgroundColor: tailwindConfig.theme.colors.navy[600],
	},
	'&.notistack-MuiContent-success': {
		backgroundColor: tailwindConfig.theme.colors.green[600],
	},
	'&.notistack-MuiContent-error': {
		backgroundColor: tailwindConfig.theme.colors.red[600],
	},
	'&.notistack-MuiContent-warning': {
		backgroundColor: tailwindConfig.theme.colors.yellow[600],
	},
}));

/**
 * ToastContainer houses the toast messages.  It should wrap the root of the application.
 *
 * @param {*} { children }
 * @returns
 */
export const ToastContainer = ({
	children,
}: {
	children?: React.ReactNode[] | React.ReactNode;
}) => {
	return (
		<SnackbarProvider
			style={{ display: 'flex', flexWrap: 'nowrap' }}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			iconVariant={{
				error: <ErrorOutlineRounded style={{ marginRight: '1rem' }} />,
				info: <ErrorOutlineRounded style={{ marginRight: '1rem' }} />,
				success: <TaskAltOutlined style={{ marginRight: '1rem' }} />,
				warning: <WarningAmberOutlined style={{ marginRight: '1rem' }} />,
			}}
			autoHideDuration={5000}
			maxSnack={5}
			Components={{
				info: StyledMaterialDesignContent,
				success: StyledMaterialDesignContent,
				error: StyledMaterialDesignContent,
				warning: StyledMaterialDesignContent,
			}}>
			{children}
		</SnackbarProvider>
	);
};

/**
 * useToast() hook provides an API to our usage of:
 * https://iamhosseindhv.com/notistack/
 * We wrap the package entirely so we can customize all toast messages in a single location.
 * We can also discard the package if it ever becomes an issue.
 *
 * @returns {ToastState}
 */
export const useToast = (): ToastState => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	// customized
	const action = (key: SnackbarKey) => (
		<>
			<IconButton
				onClick={() => closeSnackbar(key)}
				style={{
					color: '#FFF',
					padding: '6px',
				}}>
				<CloseOutlined />
			</IconButton>
		</>
	);

	const push = (message: string, properties?: ToastOptions) => {
		enqueueSnackbar(message, {
			variant: properties?.variant,
			action,
		});
	};

	return { push };
};
