import { EmployeeCount, Industry } from 'middleware-types';

export const industryToString = (value: Industry): string => {
	switch (value) {
		case Industry.Accounting:
			return 'Accounting';
		case Industry.Banking:
			return 'Banking';
		case Industry.BuildingMaterials:
			return 'Building Materials';
		case Industry.CivicAndSocialOrganization:
			return 'Civic & Social Organization';
		case Industry.CommercialRealEstate:
			return 'Commercial Real Estate';
		case Industry.ComputerAndNetworkSecurity:
			return 'Computer & Network Security';
		case Industry.ComputerSoftware:
			return 'Computer Software';
		case Industry.Construction:
			return 'Construction';
		case Industry.ConsumerServices:
			return 'Consumer Services';
		case Industry.ELearning:
			return 'E-Learning';
		case Industry.EnvironmentalServices:
			return 'Environmental Services';
		case Industry.EventsServices:
			return 'Events Services';
		case Industry.FinancialServices:
			return 'Financial Services';
		case Industry.GovernmentAdministration:
			return 'Government Administration';
		case Industry.InformationTechnologyAndServices:
			return 'Information Technology and Services';
		case Industry.Insurance:
			return 'Insurance';
		case Industry.LawPractice:
			return 'Law Practice';
		case Industry.MarketingAndAdvertising:
			return 'Marketing and Advertising';
		case Industry.MechanicalOrIndustrialEngineering:
			return 'Mechanical or Industrial Engineering';
		case Industry.NonProfitOrganizationManagement:
			return 'Non-Profit Organization Management';
		case Industry.ProfessionalTrainingAndCoaching:
			return 'Professional Training & Coaching';
		case Industry.PublicRelationsAndCommunications:
			return 'Public Relations and Communications';
		case Industry.RealEstate:
			return 'Real Estate';
		case Industry.SecurityAndInvestigations:
			return 'Security and Investigations';
	}
};

export const employeeCountToString = (value: EmployeeCount): string => {
	switch (value) {
		case EmployeeCount.Employees0To1:
			return '0-1 employees';
		case EmployeeCount.Employees2To10:
			return '2-10 employees';
		case EmployeeCount.Employees11To50:
			return '11-50 employees';
		case EmployeeCount.Employees51To200:
			return '51-200 employees';
		case EmployeeCount.Employees201To500:
			return '201-500 employees';
		case EmployeeCount.Employees501To1000:
			return '501-1,000 employees';
		case EmployeeCount.Employees1001To5000:
			return '1,001-5,000 employees';
		case EmployeeCount.Employees5001To10000:
			return '5,001-10,000 employees';
		case EmployeeCount.EmployeesMoreThan10000:
			return '10,001+ employees';
	}
};
