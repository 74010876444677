import { Box, Container, Divider, Paper, Stack } from '@mui/material';
import { ContainedDrawer } from 'components/ui/containedDrawer';
import { PageContent, PageTitle } from 'components/ui/page';
import { ConversationApiEntityType } from 'middleware-types';
import { useState } from 'react';
import { useIsMobile } from 'utils/useScreenSize';
import { useCommunicationsContext } from './communications-provider';
import { CommunicationsWrapper } from './communications-wrapper';
import { ConversationColumn } from './conversation-column/conversation-column';
import { CompactLabelsColumn } from './labels-column/compact-labels-column';
import { LabelsColumn } from './labels-column/labels-column';
import { SummariesColumn } from './summaries-column/summaries-column';

interface CommunicationsPageProps {
	entityType: ConversationApiEntityType;
	entityId: string;
	readOnly?: boolean;
}

export const CommunicationsPage = ({
	entityType,
	entityId,
	readOnly = false,
}: CommunicationsPageProps) => {
	const isMobile = useIsMobile();
	return (
		<CommunicationsWrapper entityType={entityType} entityId={entityId} readOnly={readOnly}>
			{isMobile ? <MobileCommunicationsPage /> : <DesktopCommunicationsPage />}
		</CommunicationsWrapper>
	);
};

const DesktopCommunicationsPage = () => {
	return (
		<>
			<PageTitle title="Conversations" />
			<PageContent>
				<Container maxWidth="xl" disableGutters sx={{ height: '100%' }}>
					<Stack height="100%" direction="row" spacing={6}>
						<Box width={220}>
							<LabelsColumn />
						</Box>
						<Stack
							direction="row"
							flex={1}
							component={Paper}
							elevation={1}
							overflow="hidden">
							<Box width={360}>
								<SummariesColumn />
							</Box>
							<Divider orientation="vertical" />
							<Box flex={1} overflow="hidden">
								<ConversationColumn />
							</Box>
						</Stack>
					</Stack>
				</Container>
			</PageContent>
		</>
	);
};

const MobileCommunicationsPage = () => {
	const [labelsDrawerOpen, setLabelsDrawerOpen] = useState(false);

	const { selectedConversationId, setSelectedConversationId } = useCommunicationsContext();
	const conversationSelected = selectedConversationId !== null;

	const containerId = 'communications-container';

	return (
		<Stack
			height="100%"
			bgcolor="background.paper"
			direction="row"
			position="relative"
			id={containerId}>
			<Box width={64}>
				<CompactLabelsColumn onOpen={() => setLabelsDrawerOpen(true)} />
			</Box>
			<Divider orientation="vertical" />
			<Box flex={1} overflow="hidden">
				<Box height="100%" display={conversationSelected ? 'none' : undefined}>
					<SummariesColumn />
				</Box>
				<Box height="100%" display={conversationSelected ? undefined : 'none'}>
					<ConversationColumn />
				</Box>
			</Box>
			<ContainedDrawer containerid={containerId} open={labelsDrawerOpen}>
				<Box height="100%" width={324} maxWidth="90vw" p={2} pb={1}>
					<LabelsColumn
						onClose={() => setLabelsDrawerOpen(false)}
						onLabelSelected={() => {
							setLabelsDrawerOpen(false);
							setSelectedConversationId(null);
						}}
					/>
				</Box>
			</ContainedDrawer>
		</Stack>
	);
};
