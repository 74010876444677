import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import { Alert } from 'components/ui/alert';
import { ModalActionButton, ModalActions, ModalContent, useModal } from 'components/ui/modal';
import { useToast } from 'components/ui/toast';
import { Formik } from 'formik';
import { gql } from 'graphql-tag';
import {
	FileInstanceInformation,
	Mutation,
	MutationUserAvatarOrBannerUpdateArgs,
} from 'middleware-types';
import { useState } from 'react';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { FileField, FileType, FileValue } from '../fields/file';

/**
 * The FileFieldValue upload type.
 */
export interface FileFieldUploadValues extends Omit<FileValue, 'uploadToken'> {
	uploadToken: string;
}

/**
 * Hook to update the user profile picture
 */
export const useAvatarMutation = (userId: string) => {
	const [updateAvatarOrBanner, { loading, error }] = useMutation<
		Pick<Mutation, 'userAvatarOrBannerUpdate'>,
		MutationUserAvatarOrBannerUpdateArgs
	>(
		gql`
			mutation userAvatarOrBannerUpdate(
				$userId: ID!
				$avatarUploadToken: String
				$bannerUploadToken: String
			) {
				userAvatarOrBannerUpdate(
					userId: $userId
					avatarUploadToken: $avatarUploadToken
					bannerUploadToken: $bannerUploadToken
				) {
					id
					avatarFile {
						fileId
						file {
							id
							currentInstance {
								id
								cdnUrl
								fileName
								fileSize
								virusStatus
								uploadedUtc
							}
						}
					}
					bannerFile {
						fileId
						file {
							id
							currentInstance {
								id
								cdnUrl
								fileName
								fileSize
								virusStatus
								uploadedUtc
							}
						}
					}
				}
			}
		`,
		{
			update: (cache) => {
				// Updating the profile picture can change the picture on account, profile, search, and emblem.
				cache.evict({
					id: `UserAccount:${userId}`,
					fieldName: 'avatarFile',
				});
				cache.evict({
					id: `UserProfile:${userId}`,
					fieldName: 'avatarFile',
				});
				cache.evict({
					id: `UserEmblem:${userId}`,
					fieldName: 'avatarFile',
				});
				cache.evict({
					id: `QuickSearchEntity:${userId}`,
					fieldName: 'avatarFile',
				});

				cache.gc();
			},
		}
	);

	return { updateAvatarOrBanner, loading, error };
};

/**
 * Hook for opening the profile picture uploader.
 */
export const useAvatarOrBannerUploader = (
	userId: string,
	type: 'avatar' | 'banner',
	fileInstance?: FileInstanceInformation
) => {
	const { showModal } = useModal();
	const openAvatarOrBannerUploader = () => {
		showModal({
			title: `Upload Profile ${type === 'avatar' ? 'Picture' : 'Banner'}`,
			maxWidth: 'md',
			content: (
				<AvatarOrBannerUploaderModal
					userId={userId}
					type={type}
					fileInstance={fileInstance}
				/>
			),
		});
	};

	return { openAvatarOrBannerUploader };
};

const AvatarOrBannerUploaderModal = ({
	userId,
	type,
	fileInstance,
}: {
	userId: string;
	type: 'avatar' | 'banner';
	fileInstance?: FileInstanceInformation;
}) => {
	const { updateAvatarOrBanner, error } = useAvatarMutation(userId);

	const { closeModal } = useModal();
	const toast = useToast();
	const [isUploading, setIsUploading] = useState(false);

	return (
		<Formik<{ avatarOrBanner: FileFieldUploadValues }>
			initialValues={{
				avatarOrBanner: {
					uploadToken: '',
					id: fileInstance?.id,
					fileName: fileInstance?.fileName,
					fileSize: fileInstance?.fileSize,
					uploadedUtc: fileInstance?.uploadedUtc,
					virusStatus: fileInstance?.virusStatus,
				},
			}}
			onSubmit={(values: { avatarOrBanner: FileFieldUploadValues }) => {
				updateAvatarOrBanner({
					variables: {
						userId,
						avatarUploadToken:
							type === 'avatar' ? values.avatarOrBanner.uploadToken : undefined,
						bannerUploadToken:
							type === 'banner' ? values.avatarOrBanner.uploadToken : undefined,
					},
				})
					.then((res) => {
						if (responseHasErrors(res.errors, { toast })) {
							return false;
						}
						toast.push(
							`Profile ${
								type === 'avatar' ? 'Picture' : 'Banner'
							} updated successfully. It may take a few minutes for your picture to appear.`,
							{ variant: 'success' }
						);
						closeModal();
					})
					.catch(() => handleNoResponse({ toast }));
			}}>
			{({ submitForm, isValid, dirty, isSubmitting }) => (
				<>
					<ModalContent>
						<Alert error={error} />

						<Typography variant="h3" className="mt-0">
							Drag & drop an image below or click to upload
						</Typography>
						<FileField
							name="avatarOrBanner"
							type={FileType.Image}
							updatesFileId={fileInstance?.fileId}
							setIsUploading={setIsUploading}
						/>
					</ModalContent>
					<ModalActions>
						<Grid container justifyContent="flex-end" spacing={1}>
							<Grid item>
								<ModalActionButton variant="outlined">Cancel</ModalActionButton>
							</Grid>
							<Grid item>
								<LoadingButton
									variant="contained"
									color="primary"
									onClick={submitForm}
									disabled={!isValid || !dirty || isSubmitting || isUploading}
									loading={isSubmitting || isUploading}>
									Save
								</LoadingButton>
							</Grid>
						</Grid>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};
