import * as Yup from 'yup';

// Code Messages
const CodeRequiredMessage = 'Code is required';
const CodeLengthMessage = 'Code must be 6 digits';
const CodeMustBeNumericalMessage = 'Code must contain only digits (0-9)';

// Username Messages
const EmailAddressTypeMessage = 'Invalid email address';
const EmailAddressRequiredMessage = 'Email address is required';
const EmailAddressMaxLengthMessage = 'Email address must be 128 characters or fewer';

// Password Messages
const PasswordRequiredMessage = 'Password is required';
const NewPasswordRequiredMessage = 'New Password is required';
const PasswordMinLengthMessage = 'Password must be at least 8 characters';

// Confirm Password Messages
const ConfirmPasswordRequiredMessage = 'Confirm password is required';
const ConfirmNewPasswordRequiredMessage = 'Confirm New Password is required';

// Passwords Must Match Message
const PasswordsMustMatchMessage = 'Passwords must match';

// Old Password Messages
const OldPasswordRequiredMessage = 'Current Password is required';

// This Yup allows whitespace in addition to digits, and counts
// length after filtering out whitespace characters, because
// the whitespace will be filtered out when the values are submitted.
// Right now that's just for the Authenticator because that's the only
// spot where the code to input is presented to users with a space in it.
export const codeValidationWhiteSpaceAllowed = Yup.string()
	.required(CodeRequiredMessage)
	.matches(/^[\d\s]+$/, CodeMustBeNumericalMessage)
	.test('code', CodeLengthMessage, function (value) {
		return value.replace(/\s/g, '').length === 6;
	});

// Validation Yups

// This Yup does not allow whitespace for codes where whitespace is not filtered out.
export const codeValidationWhiteSpaceDisallowed = Yup.string()
	.required(CodeRequiredMessage)
	.length(6, CodeLengthMessage)
	.matches(/^\d+$/, CodeMustBeNumericalMessage);

export const emailValidation = Yup.string()
	.email(EmailAddressTypeMessage)
	.required(EmailAddressRequiredMessage)
	.max(128, EmailAddressMaxLengthMessage);

export const passwordValidation = Yup.string()
	.min(8, PasswordMinLengthMessage)
	.required(PasswordRequiredMessage);

export const confirmPasswordValidation = Yup.string()
	.required(ConfirmPasswordRequiredMessage)
	.test(
		'passwords-match',
		PasswordsMustMatchMessage,
		(value, ctx) => ctx.parent.password === value
	);

export const confirmNewPasswordValidation = Yup.string()
	.required(ConfirmNewPasswordRequiredMessage)
	.test(
		'passwords-match',
		PasswordsMustMatchMessage,
		(value, ctx) => ctx.parent.newPassword === value
	);

export const newPasswordValidation = Yup.string()
	.min(8, PasswordMinLengthMessage)
	.required(NewPasswordRequiredMessage);

export const oldPasswordValidation = Yup.string().required(OldPasswordRequiredMessage);
