import { Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { useSession } from 'utils/session';
import { PasswordSection } from './password-section';
import { MfaSection } from './mfa-section';

interface LoginSettingsCardProps {
	userId: string;
	canEdit: boolean;
}

export const LoginSettingsCard = ({ userId, canEdit }: LoginSettingsCardProps) => {
	const { user } = useSession();
	return (
		<Card>
			<CardHeader title="Login Settings" />
			<Divider />
			<CardContent>
				<Grid container spacing={1}>
					{userId === user.userId && <PasswordSection canEdit={canEdit} />}
					<MfaSection userId={userId} canEdit={canEdit} />
				</Grid>
			</CardContent>
		</Card>
	);
};
