import { gql, useMutation, useQuery } from '@apollo/client';
import {
	Box,
	Button,
	CardContent,
	CardHeader,
	Grid,
	MenuItem,
	Paper,
	Skeleton,
	Stack,
} from '@mui/material';
import {
	PermissionRowsSkeleton,
	PermissionsTable,
	RoleCategory,
} from 'components/pages/roles/permissions';
import { Alert } from 'components/ui/alert';
import { SelectField, TextField } from 'components/ui/fields';
import { PageContent, PageTitle } from 'components/ui/page';
import { useToast } from 'components/ui/toast';
import { Formik, FormikProps } from 'formik';
import {
	Mutation,
	MutationUserSiteRoleAddArgs,
	MutationUserSiteRoleUpdateArgs,
	PermissionCategory,
	Query,
	QuerySiteRoleArgs,
	SiteRoleUpdate,
} from 'middleware-types';
import { useNavigate, useParams } from 'react-router-dom';
import { handleNoResponse, PageError, responseHasErrors } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { useSiteUser } from 'utils/useSiteUser';
import { useValidation } from 'utils/useValidation';

/* Mutation for adding a site role. */
export const ADD_SITEUSER_ROLE = gql`
	mutation UserSiteRoleAdd($siteRoleUpdate: SiteRoleUpdate!) {
		userSiteRoleAdd(siteRoleUpdate: $siteRoleUpdate) {
			id
			name
			isAdminRole
			userCount
			permissionKeys
			siteRoleCategory {
				id
				name
			}
		}
	}
`;

/* Mutation for updating a site role. */
export const UPDATE_SITEUSER_ROLE = gql`
	mutation UserSiteRoleUpdate($id: String!, $siteRoleUpdate: SiteRoleUpdate!) {
		userSiteRoleUpdate(id: $id, siteRoleUpdate: $siteRoleUpdate) {
			id
			name
			isAdminRole
			userCount
			permissionKeys
			siteRoleCategory {
				id
				name
			}
			lastModifiedUtc
		}
	}
`;

/**
 * useSiteRole - Custom hook to manage getting all the required data for a create/update site role form.
 *
 * @param {(string | undefined)} id
 * @returns
 */
const useSiteRole = (id: string | undefined) => {
	const navigate = useNavigate();
	const toast = useToast();

	// Get the list of categories and permissions
	const [updateRole, { error, loading: submitting }] = useMutation<
		Pick<Mutation, 'userSiteRoleAdd' | 'userSiteRoleUpdate'>,
		MutationUserSiteRoleAddArgs | MutationUserSiteRoleUpdateArgs
	>(!id ? ADD_SITEUSER_ROLE : UPDATE_SITEUSER_ROLE);
	const siteRoleMetadataQuery = useQuery<
		Pick<Query, 'siteRoleCategories' | 'sitePermissionCategories'>
	>(gql`
		query siteRoleCategories {
			siteRoleCategories {
				id
				name
			}
			sitePermissionCategories {
				categories {
					name
					displayOrder
					permissionGroups {
						name
						description
						displayOrder
						permissions {
							operationType
							permissionKey
						}
					}
				}
			}
		}
	`);
	const validation = useValidation('SiteRoleUpdate');

	// State for the existing role.
	const existingRoleQuery = useQuery<Pick<Query, 'siteRole'>, QuerySiteRoleArgs>(
		gql`
			query siteRole($id: String!) {
				siteRole(id: $id) {
					id
					name
					isAdminRole
					userCount
					permissionKeys
					siteRoleCategory {
						id
						name
					}
					lastModifiedUtc
				}
			}
		`,
		{
			fetchPolicy: 'cache-and-network',
			variables: {
				id: id as string,
			},
			skip: !id,
		}
	);
	const role = existingRoleQuery.data?.siteRole;
	const isAdminRole = role?.isAdminRole ?? false;
	const initialRole: SiteRoleUpdate = {
		name: role?.name ?? '',
		siteRoleCategoryId: role?.siteRoleCategory?.id ?? '',
		permissionKeys: role?.permissionKeys ?? [],
	};

	const loading =
		siteRoleMetadataQuery.loading || existingRoleQuery.loading || validation.loading;

	const categories: RoleCategory[] = siteRoleMetadataQuery.data?.siteRoleCategories ?? [];
	const permissions: PermissionCategory[] =
		siteRoleMetadataQuery.data?.sitePermissionCategories.categories ?? [];

	// If isAdminRole, automatically set all permissions
	if (isAdminRole) {
		initialRole.permissionKeys = permissions.flatMap((category) => {
			return category.permissionGroups.flatMap((permissionGroup) => {
				return permissionGroup.permissions.map((permission) => {
					return permission.permissionKey;
				});
			});
		});
	}

	// Any errors on loading should be thrown to be caught at the error boundary.
	if (siteRoleMetadataQuery.error || existingRoleQuery.error) {
		const errors = [siteRoleMetadataQuery.error, existingRoleQuery.error].filter(
			(p) => p !== undefined
		);
		throw new PageError(errors);
	}

	/**
	 * saveRole switches on update and add to have a single "save" function.
	 * Results are passed throhugh a promise.
	 *
	 * @returns
	 */
	const saveRole = async (values: SiteRoleUpdate) => {
		// Build the mutation out of the values from formik.
		const variables: { id?: string; siteRoleUpdate: SiteRoleUpdate } = {
			siteRoleUpdate: values,
		};
		if (id) variables.id = id;

		updateRole({
			variables,
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push(
					`The ${values.name} role was successfully ${!id ? 'created' : 'updated'}.`,
					{
						variant: 'success',
					}
				);
				return navigate('/site/roles');
			})
			.catch(() => handleNoResponse({ toast }));
	};

	return {
		error,
		loading,
		submitting,
		categories,
		initialRole,
		saveRole,
		permissions,
		validationSchema: validation.schema,
		isAdminRole,
	};
};

/**
 * SiteRole - The Update and Create Site Role pages.
 *
 * @param {*} props
 * @returns
 */
const SiteRole = () => {
	const navigate = useNavigate();
	const { hasPermission } = useSiteUser();
	// Get the list of categories and permissions
	let { id } = useParams();

	const {
		error,
		loading,
		submitting,
		categories,
		initialRole,
		permissions,
		validationSchema,
		saveRole,
		isAdminRole,
	} = useSiteRole(id);

	// Check update permission only when editing a role, not creating
	const readOnly = id ? !hasPermission(Permission.Site_Role_U) : false;

	// Render Categories
	const categoryMenuItems = categories.map((src, i) => (
		<MenuItem key={i} value={src.id}>
			{src.name !== '' ? src.name : 'None'}
		</MenuItem>
	));

	let verb = 'Create';
	if (readOnly) {
		verb = 'View';
	} else if (id) {
		verb = 'Update';
	}

	const pageTitle =
		verb === 'Create'
			? 'Create Role'
			: initialRole?.name
			? verb + ' ' + initialRole.name + ' Role'
			: '';

	// Render all
	return (
		<>
			<PageTitle title={pageTitle} />
			<PageContent>
				<Paper sx={{ height: '100%' }}>
					<Formik
						validationSchema={validationSchema}
						onSubmit={async (values) => {
							await saveRole({
								...values,
								siteRoleCategoryId: values.siteRoleCategoryId,
							});
						}}
						enableReinitialize={true}
						initialValues={initialRole}>
						{(props: FormikProps<SiteRoleUpdate>) => (
							<Grid container alignItems="stretch" sx={{ height: '100%' }}>
								<Grid item xs={4} lg={3} xl={2} sx={{ height: '100%' }}>
									<Box display="flex" height="100%" flexDirection="column">
										<CardHeader title="Site Role" />
										<CardContent
											sx={{
												flex: '1 1 auto',
												overflow: 'hidden',
											}}>
											{loading ? (
												<Stack spacing={0.25}>
													<Skeleton animation="wave" height="4rem" />
													<Skeleton animation="wave" height="4rem" />
												</Stack>
											) : (
												<Stack spacing={1} height="100%">
													<Stack spacing={2} pt={1}>
														<TextField
															label="Role Name"
															name="name"
															required
															fullWidth
															disabled={
																readOnly ||
																isAdminRole ||
																submitting
															}
														/>
														<SelectField
															label="Category"
															value={
																props.values.siteRoleCategoryId ??
																''
															}
															name="siteRoleCategoryId"
															required
															fullWidth
															disabled={
																readOnly ||
																isAdminRole ||
																submitting
															}>
															{categoryMenuItems}
														</SelectField>
													</Stack>
												</Stack>
											)}
										</CardContent>
									</Box>
								</Grid>
								<Grid item xs={8} lg={9} xl={10} sx={{ height: '100%' }}>
									<Box display="flex" height="100%" flexDirection="column">
										<CardHeader
											title="Site User Permissions"
											action={
												!loading && (
													<Stack direction="row" spacing={1}>
														<Button
															variant="outlined"
															onClick={() => navigate('/site/roles')}>
															Cancel
														</Button>
														{!(readOnly || isAdminRole) && (
															<Button
																type="submit"
																color="primary"
																disabled={
																	!props.isValid ||
																	!props.dirty ||
																	props.isSubmitting
																}
																onClick={() => props.submitForm()}
																variant="contained">
																{id ? 'Save Role' : 'Create Role'}
															</Button>
														)}
													</Stack>
												)
											}
										/>
										{error && (
											<div className="px-4">
												<Alert error={error} />
											</div>
										)}
										<CardContent
											sx={{
												flex: '1 1 auto',
												overflow: 'auto',
											}}>
											{loading ? (
												<PermissionRowsSkeleton />
											) : (
												<PermissionsTable
													name="permissionKeys"
													permissions={permissions}
													disabled={readOnly || isAdminRole}
												/>
											)}
										</CardContent>
									</Box>
								</Grid>
							</Grid>
						)}
					</Formik>
				</Paper>
			</PageContent>
		</>
	);
};

export default SiteRole;
