import { gql, useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	Mutation,
	MutationOrgAccountQuickLinkCreateArgs,
	MutationOrgAccountQuickLinkDeleteArgs,
	MutationOrgAccountQuickLinkUpdateArgs,
	Query,
	QueryOrgQuickLinksArgs,
	QuickLinkCreate,
	QuickLinkPatch,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { QUICK_LINK_FIELDS } from './userQuickLinkHooks';

const GET_QUICK_LINKS = gql`
	${QUICK_LINK_FIELDS}
	query GetQuickLinks($orgId: ID!) {
		orgQuickLinks(orgId: $orgId) {
			...QuickLinkFields
		}
	}
`;

const ADD_QUICK_LINK = gql`
	${QUICK_LINK_FIELDS}
	mutation AddQuickLink($orgId: ID!, $request: QuickLinkCreate!) {
		orgAccountQuickLinkCreate(orgId: $orgId, request: $request) {
			...QuickLinkFields
		}
	}
`;

const UPDATE_QUICK_LINK = gql`
	${QUICK_LINK_FIELDS}
	mutation UpdateQuickLink($orgId: ID!, $quickLinkId: ID!, $request: QuickLinkPatch!) {
		orgAccountQuickLinkUpdate(orgId: $orgId, quickLinkId: $quickLinkId, request: $request) {
			...QuickLinkFields
		}
	}
`;

const DELETE_QUICK_LINK = gql`
	mutation DeleteQuickLink($orgId: ID!, $quickLinkId: ID!) {
		orgAccountQuickLinkDelete(orgId: $orgId, quickLinkId: $quickLinkId)
	}
`;

export const useOrgQuickLinks = (orgId: string) => {
	const toast = useToast();

	// GET
	const { data, loading } = useQuery<Pick<Query, 'orgQuickLinks'>, QueryOrgQuickLinksArgs>(
		GET_QUICK_LINKS,
		{
			variables: { orgId },
			onError: () => toast.push('Unable to load Quick Links', { variant: 'error' }),
		}
	);

	const quickLinks = data?.orgQuickLinks ?? [];

	// ADD
	const [_addQuickLink] = useMutation<
		Pick<Mutation, 'orgAccountQuickLinkCreate'>,
		MutationOrgAccountQuickLinkCreateArgs
	>(ADD_QUICK_LINK, {
		refetchQueries: [GET_QUICK_LINKS],
		awaitRefetchQueries: true,
	});

	const addQuickLink = async (request: QuickLinkCreate) => {
		return await _addQuickLink({ variables: { orgId, request } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Quick Link added successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	// UPDATE
	const [_updateQuickLink] = useMutation<
		Pick<Mutation, 'orgAccountQuickLinkUpdate'>,
		MutationOrgAccountQuickLinkUpdateArgs
	>(UPDATE_QUICK_LINK);

	const updateQuickLink = async (quickLinkId: string, request: QuickLinkPatch) => {
		return await _updateQuickLink({ variables: { orgId, quickLinkId, request } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Quick Link updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	// DELETE
	const [_deleteQuickLink, { loading: deleteLoading }] = useMutation<
		Pick<Mutation, 'orgAccountQuickLinkDelete'>,
		MutationOrgAccountQuickLinkDeleteArgs
	>(DELETE_QUICK_LINK, {
		refetchQueries: [GET_QUICK_LINKS],
		awaitRefetchQueries: true,
	});

	const deleteQuickLink = async (quickLinkId: string) => {
		return await _deleteQuickLink({ variables: { orgId, quickLinkId } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Quick Link deleted successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		quickLinks,
		loading,
		addQuickLink,
		updateQuickLink,
		deleteQuickLink,
		deleteLoading,
	};
};
