import { Icon, IconProps, SvgIcon, SvgIconProps, createSvgIcon } from '@mui/material';

export const DeactivateIcon = (props: IconProps) => (
	<Icon {...props}>
		<img src="/img/icon-deactivate.svg" alt="deactivate icon" />
	</Icon>
);

export const OrangeMaskIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 30 30">
			<g id="Mask-Orange" transform="translate(-722 -355)">
				<rect
					id="Rectangle_1"
					data-name="Rectangle 1"
					width="30"
					height="30"
					transform="translate(722 355)"
					fill="none"
				/>
				<path
					id="Path_1"
					data-name="Path 1"
					d="M29.34,3.653a48.637,48.637,0,0,0-21.28,0A1.085,1.085,0,0,0,7.2,4.718v10.33a15.165,15.165,0,0,0,1.129,5.778,11.214,11.214,0,0,0,20.743,0A15.165,15.165,0,0,0,30.2,15.048V4.718A1.17,1.17,0,0,0,29.34,3.653ZM11.5,11.827a.609.609,0,0,1-.054-.639A3.223,3.223,0,0,1,14.347,9a3.212,3.212,0,0,1,2.9,2.183.674.674,0,0,1-.054.639.667.667,0,0,1-.564.293H12.063A.7.7,0,0,1,11.5,11.827Zm7.2,9.931a5.037,5.037,0,0,1-4.971-4.1.781.781,0,0,1,.779-.905h8.41a.781.781,0,0,1,.779.905A5.088,5.088,0,0,1,18.7,21.758Zm7.2-9.931a.667.667,0,0,1-.564.293H20.769a.7.7,0,0,1-.564-.293.609.609,0,0,1-.054-.639A3.223,3.223,0,0,1,23.053,9a3.251,3.251,0,0,1,2.9,2.183A.758.758,0,0,1,25.9,11.827Z"
					transform="translate(718.3 354.875)"
					fill="#ff9e18"
				/>
			</g>
		</svg>
	</SvgIcon>
);

export const MaskIcon = (props: IconProps) => (
	<Icon {...props}>
		<img src="/img/impersonation.svg" alt="impersonation icon" />
	</Icon>
);

export const EvolutionConnectLogoIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props}>
			<svg
				id="Group_5452"
				data-name="Group 5452"
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24">
				<path
					id="Path_1670"
					data-name="Path 1670"
					d="M12.578,8.7a4.2,4.2,0,0,0,.036-.562,3.431,3.431,0,0,0-.042-.562l1.222-.944a.292.292,0,0,0,.072-.365L12.71,4.283a.3.3,0,0,0-.355-.132l-1.438.574a4.258,4.258,0,0,0-.975-.562L9.726,2.645A.291.291,0,0,0,9.437,2.4H7.126a.284.284,0,0,0-.283.245L6.626,4.163a4.352,4.352,0,0,0-.975.562L4.213,4.151a.288.288,0,0,0-.355.132L2.709,6.268a.271.271,0,0,0,.072.365L4,7.577A3.3,3.3,0,0,0,3.991,8.7l-1.222.944a.292.292,0,0,0-.072.365l1.155,1.985a.3.3,0,0,0,.355.132l1.438-.574a4.258,4.258,0,0,0,.975.562l.217,1.518a.3.3,0,0,0,.289.245H9.437a.28.28,0,0,0,.283-.245l.217-1.518a4.134,4.134,0,0,0,.975-.562l1.438.574a.288.288,0,0,0,.355-.132L13.86,10.01a.276.276,0,0,0-.072-.365Zm-4.3,1.59a2.152,2.152,0,1,1,2.166-2.152A2.166,2.166,0,0,1,8.281,10.291Z"
					transform="translate(9.98 10.074)"
				/>
				<path
					id="Path_7376"
					data-name="Path 7376"
					d="M2904.673,1315.054s-7.624-9.083-15.741,0l2.421-.027S2896.821,1309.064,2904.673,1315.054Zm-16.732,1.578s-3.667,10.728,7.794,12.808l-1.1-1.959C2892.991,1327.057,2887.086,1324.951,2887.941,1316.632Zm17.612-.089-1.224,1.887c.423,1.607,1.407,7.643-6.484,10.972C2897.848,1329.4,2909.271,1327.286,2905.553,1316.542Zm-4.2,5.648,1.329-2.237H2895l1.479,2.237Zm-3.646,1.894-1.219,2.035-4.96-8.357h12.509l1.207-2.114h-16.971l8.485,14.283,3.475-5.847Z"
					transform="translate(-2887.182 -1310.864)"
				/>
			</svg>
		</SvgIcon>
	);
};

export const SiteUserDashboardIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props} focusable="false">
			<path fill="none" d="M0,0H24V24H0Z" />
			<path
				fill="#949494"
				d="M19,5V7H15V5h4M9,5v6H5V5H9m10,8v6H15V13h4M9,17v2H5V17H9M21,3H13V9h8ZM11,3H3V13h8Zm10,8H13V21h8ZM11,15H3v6h8Z"
			/>
		</SvgIcon>
	);
};

export const ActiveSiteUserDashboardIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props} focusable="false">
			<path fill="none" d="M0,0H24V24H0Z" />
			<path
				fill="#186597"
				d="M3,13h8V3H3Zm0,8h8V15H3Zm10,0h8V11H13ZM13,3V9h8V3Z"
				transform="translate(0 0)"
			/>
		</SvgIcon>
	);
};

export const ActivePeopleSearchIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props} focusable="false">
			<rect fill="none" width="24" height="24" />
			<g transform="translate(1.983 4.2)">
				<g transform="translate(0 0)">
					<circle
						fill="#186597"
						cx="4.5"
						cy="4.5"
						r="4.5"
						transform="translate(3.018 -0.2)"
					/>
					<path
						fill="#186597"
						d="M10.279,14.01C7.572,13.911,2,15.259,2,17.966v1.983h9.458A5.9,5.9,0,0,1,10.279,14.01Z"
						transform="translate(-2 -4.32)"
					/>
					<path
						fill="#186597"
						d="M19.366,17.968a3.831,3.831,0,0,0,.565-2,3.966,3.966,0,1,0-3.966,3.966,3.925,3.925,0,0,0,2-.565l2.548,2.548,1.4-1.4Zm-3.4-.02a1.983,1.983,0,1,1,1.983-1.983A1.989,1.989,0,0,1,15.966,17.949Z"
						transform="translate(-2.086 -4.303)"
					/>
				</g>
			</g>
		</SvgIcon>
	);
};

export const PeopleSearchIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props} focusable="false">
			<g transform="translate(-0.252)">
				<rect fill="none" width="24" height="24" transform="translate(0.252)" />
				<g transform="translate(2 4)">
					<path
						fill="#949494"
						d="M10,12A4,4,0,1,0,6,8,4,4,0,0,0,10,12Zm0-6A2,2,0,1,1,8,8,2.006,2.006,0,0,1,10,6Z"
						transform="translate(-2 -4)"
					/>
					<path
						fill="#949494"
						d="M4,18c.22-.72,3.31-2,6-2a5.945,5.945,0,0,1,.35-1.99C7.62,13.91,2,15.27,2,18v2h9.54a5.875,5.875,0,0,1-1.19-2Z"
						transform="translate(-2 -4)"
					/>
					<path
						fill="#949494"
						d="M19.43,18.02A3.864,3.864,0,0,0,20,16a4,4,0,1,0-4,4,3.959,3.959,0,0,0,2.02-.57L20.59,22,22,20.59C20.5,19.09,21.21,19.79,19.43,18.02ZM16,18a2,2,0,1,1,2-2A2.006,2.006,0,0,1,16,18Z"
						transform="translate(-2 -4)"
					/>
				</g>
			</g>
		</SvgIcon>
	);
};
export const ActiveSiteUsersIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props} focusable="false">
			<path fill="none" d="M0,0H24V24H0Z" />
			<circle fill="#186597" cx="4" cy="4" r="4" transform="translate(6 4)" />
			<path
				fill="#186597"
				d="M10.67,13.02c-.22-.01-.44-.02-.67-.02a12.876,12.876,0,0,0-6.61,1.82A2.922,2.922,0,0,0,2,17.35V20h9.26A6.963,6.963,0,0,1,10,16,7.072,7.072,0,0,1,10.67,13.02Z"
			/>
			<path
				fill="#186597"
				d="M20.75,16a4.338,4.338,0,0,0-.06-.63l1.14-1.01-1-1.73-1.45.49a3.647,3.647,0,0,0-1.08-.63L18,11H16l-.3,1.49a3.647,3.647,0,0,0-1.08.63l-1.45-.49-1,1.73,1.14,1.01a4.338,4.338,0,0,0-.06.63,4.338,4.338,0,0,0,.06.63l-1.14,1.01,1,1.73,1.45-.49a3.647,3.647,0,0,0,1.08.63L16,21h2l.3-1.49a3.647,3.647,0,0,0,1.08-.63l1.45.49,1-1.73-1.14-1.01A4.338,4.338,0,0,0,20.75,16ZM17,18a2,2,0,1,1,2-2A2.006,2.006,0,0,1,17,18Z"
			/>
		</SvgIcon>
	);
};

export const SiteUsersIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props} focusable="false">
			<path fill="none" d="M0,0H24V24H0Z" />
			<path
				fill="#949494"
				d="M4,18v-.65a.946.946,0,0,1,.41-.81A10.889,10.889,0,0,1,10,15a.22.22,0,0,1,.08.01,6.886,6.886,0,0,1,.59-1.98c-.22-.02-.44-.03-.67-.03a12.876,12.876,0,0,0-6.61,1.82A2.922,2.922,0,0,0,2,17.35V20h9.26a7.133,7.133,0,0,1-.97-2Z"
			/>
			<path
				fill="#949494"
				d="M10,12A4,4,0,1,0,6,8,4,4,0,0,0,10,12Zm0-6A2,2,0,1,1,8,8,2.006,2.006,0,0,1,10,6Z"
			/>
			<path
				fill="#949494"
				d="M20.75,16a4.338,4.338,0,0,0-.06-.63l1.14-1.01-1-1.73-1.45.49a3.647,3.647,0,0,0-1.08-.63L18,11H16l-.3,1.49a3.647,3.647,0,0,0-1.08.63l-1.45-.49-1,1.73,1.14,1.01a4.338,4.338,0,0,0-.06.63,4.338,4.338,0,0,0,.06.63l-1.14,1.01,1,1.73,1.45-.49a3.647,3.647,0,0,0,1.08.63L16,21h2l.3-1.49a3.647,3.647,0,0,0,1.08-.63l1.45.49,1-1.73-1.14-1.01A4.338,4.338,0,0,0,20.75,16ZM17,18a2,2,0,1,1,2-2A2.006,2.006,0,0,1,17,18Z"
			/>
		</SvgIcon>
	);
};

export const ActiveSiteRolesIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon htmlColor="#186597" focusable="false" viewBox="0 0 24 24" {...props}>
			<g transform="translate(-1696 -317)">
				<rect
					fill="#fff"
					opacity="0"
					width="24"
					height="24"
					transform="translate(1720 317) rotate(90)"
				/>
				<path
					fill="186597"
					d="M17.182,21.42h-.818V18.147a1.638,1.638,0,0,0-1.636-1.636H9.818V13.238h.818a.821.821,0,0,0,.818-.818V9.146a.821.821,0,0,0-.818-.818H7.364a.821.821,0,0,0-.818.818v3.273a.821.821,0,0,0,.818.818h.818v3.273H3.273a1.638,1.638,0,0,0-1.636,1.636V21.42H.818A.821.821,0,0,0,0,22.238v3.273a.821.821,0,0,0,.818.818H4.091a.821.821,0,0,0,.818-.818V22.238a.821.821,0,0,0-.818-.818H3.273V18.147H8.182V21.42H7.364a.821.821,0,0,0-.818.818v3.273a.821.821,0,0,0,.818.818h3.273a.821.821,0,0,0,.818-.818V22.238a.821.821,0,0,0-.818-.818H9.818V18.147h4.909V21.42h-.818a.821.821,0,0,0-.818.818v3.273a.821.821,0,0,0,.818.818h3.273A.821.821,0,0,0,18,25.511V22.238A.821.821,0,0,0,17.182,21.42Z"
					transform="translate(1699 311.672)"
				/>
			</g>
		</SvgIcon>
	);
};

export const SiteRolesIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props} focusable="false">
			<g transform="translate(341 -1696) rotate(90)">
				<rect opacity="0" width="24" height="24" transform="translate(1696 317)" />
				<g transform="translate(1696 317)">
					<path
						d="M13.429,5.571h2.857a2.143,2.143,0,0,0,2.143,2.143h1.429A2.143,2.143,0,0,0,22,5.571V4.143A2.143,2.143,0,0,0,19.857,2H18.429a2.143,2.143,0,0,0-2.143,2.143H13.429a2.143,2.143,0,0,0-2.143,2.143v5H7.714A2.143,2.143,0,0,0,5.571,9.143H4.143A2.143,2.143,0,0,0,2,11.286v1.429a2.143,2.143,0,0,0,2.143,2.143H5.571a2.143,2.143,0,0,0,2.143-2.143h3.571v5a2.143,2.143,0,0,0,2.143,2.143h2.857A2.143,2.143,0,0,0,18.429,22h1.429A2.143,2.143,0,0,0,22,19.857V18.429a2.143,2.143,0,0,0-2.143-2.143H18.429a2.143,2.143,0,0,0-2.143,2.143H13.429a.714.714,0,0,1-.714-.714v-5h3.571a2.143,2.143,0,0,0,2.143,2.143h1.429A2.143,2.143,0,0,0,22,12.714V11.286a2.143,2.143,0,0,0-2.143-2.143H18.429a2.143,2.143,0,0,0-2.143,2.143H12.714v-5A.714.714,0,0,1,13.429,5.571Zm4.286-1.429a.714.714,0,0,1,.714-.714h1.429a.714.714,0,0,1,.714.714V5.571a.714.714,0,0,1-.714.714H18.429a.714.714,0,0,1-.714-.714ZM6.286,12.714a.714.714,0,0,1-.714.714H4.143a.714.714,0,0,1-.714-.714V11.286a.714.714,0,0,1,.714-.714H5.571a.714.714,0,0,1,.714.714Zm11.429,5.714a.714.714,0,0,1,.714-.714h1.429a.714.714,0,0,1,.714.714v1.429a.714.714,0,0,1-.714.714H18.429a.714.714,0,0,1-.714-.714Zm0-7.143a.714.714,0,0,1,.714-.714h1.429a.714.714,0,0,1,.714.714v1.429a.714.714,0,0,1-.714.714H18.429a.714.714,0,0,1-.714-.714Z"
						transform="translate(0)"
					/>
				</g>
			</g>
		</SvgIcon>
	);
};

export const ActiveAssociateIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props} focusable="false">
			<g transform="translate(-4104 -1557)">
				<rect
					width="24"
					height="24"
					transform="translate(4104 1557)"
					fill="#fff"
					opacity="0"
				/>
				<g transform="translate(4107.873 1560.95)">
					<path
						d="M21.68,7.5A4.152,4.152,0,0,0,17.7,3.2a4.152,4.152,0,0,0-3.978,4.3A4.153,4.153,0,0,0,17.7,11.8,4.153,4.153,0,0,0,21.68,7.5Z"
						transform="translate(-9.573 -3.2)"
						fill="#186597"
					/>
					<path
						d="M25.569,30.131l.772-1.385c.289.031.578.067.858.118,2.594.464,4.646,1.7,5.491,3.295a3.212,3.212,0,0,1,.146,2.8H26.842Z"
						transform="translate(-16.835 -18.859)"
						fill="#186597"
					/>
					<path
						d="M9.249,34.955H3.255a3.47,3.47,0,0,1-.161-.481,3.339,3.339,0,0,1,.591-2.781c1.109-1.566,3.37-2.665,6.064-2.954l.774,1.388Z"
						transform="translate(-3.001 -18.856)"
						fill="#186597"
					/>
				</g>
			</g>
		</SvgIcon>
	);
};

export const AssociateIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props} focusable="false">
			<g transform="translate(-4104 -1557)">
				<rect
					width="24"
					height="24"
					transform="translate(4104 1557)"
					fill="#fff"
					opacity="0"
				/>
				<g transform="translate(4107.873 1560.95)">
					<path
						d="M21.68,7.5A4.152,4.152,0,0,0,17.7,3.2a4.152,4.152,0,0,0-3.978,4.3A4.153,4.153,0,0,0,17.7,11.8,4.153,4.153,0,0,0,21.68,7.5Z"
						transform="translate(-9.573 -3.2)"
						fill="none"
						stroke="#707070"
						strokeWidth="1"
					/>
					<path
						d="M25.569,30.131l.772-1.385c.289.031.578.067.858.118,2.594.464,4.646,1.7,5.491,3.295a3.212,3.212,0,0,1,.146,2.8H26.842Z"
						transform="translate(-16.835 -18.859)"
						fill="none"
						stroke="#707070"
						strokeWidth="1"
					/>
					<path
						d="M9.249,34.955H3.255a3.47,3.47,0,0,1-.161-.481,3.339,3.339,0,0,1,.591-2.781c1.109-1.566,3.37-2.665,6.064-2.954l.774,1.388Z"
						transform="translate(-3.001 -18.856)"
						fill="none"
						stroke="#707070"
						strokeWidth="1"
					/>
					<path
						d="M6.691,9.886a13.514,13.514,0,0,1,2.723,0c-.2-.028-.1-.015,0,0"
						transform="translate(0.093)"
						fill="none"
						stroke="#707070"
						strokeWidth="1"
					/>
				</g>
				<line
					x2="3"
					transform="translate(4114.5 1577.05)"
					fill="none"
					stroke="#707070"
					strokeWidth="1"
				/>
			</g>
		</SvgIcon>
	);
};

export const LanguageIcon = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		<g transform="translate(0)">
			<path
				d="M14.145,17.058a2.421,2.421,0,0,0-1.64.64.5.5,0,0,0-.322-.12,2.784,2.784,0,0,1-1.065-.2,4.207,4.207,0,0,0,.849-2.158h.226a.514.514,0,0,0,0-1.027H10.724v-.8a.514.514,0,0,0-1.027,0v.8H8.238a.514.514,0,1,0,0,1.027h2.685a3.079,3.079,0,0,1-.658,1.558,3.182,3.182,0,0,1-.6-1,.514.514,0,1,0-.966.342,4.236,4.236,0,0,0,.723,1.243,3.181,3.181,0,0,1-1.171.205.514.514,0,0,0,0,1.027,3.871,3.871,0,0,0,2.017-.49,3.561,3.561,0,0,0,1.613.5,2.4,2.4,0,0,0-.175.9v.384H8.132a.511.511,0,0,0-.507.592c0,.034.065.421.182.966a.343.343,0,0,1-.538.342A10.158,10.158,0,0,1,4.68,19.369a.691.691,0,0,0-.051-.079,1.4,1.4,0,0,1-.253-.8V12.962a1.415,1.415,0,0,1,1.414-1.414H14.6a1.415,1.415,0,0,1,1.414,1.414v4.11ZM24.375,19.5v5.51a1.367,1.367,0,0,1-.253.8.392.392,0,0,0-.051.092,9.828,9.828,0,0,1-2.589,2.428.343.343,0,0,1-.538-.342c.116-.548.178-.935.182-.969a.51.51,0,0,0-.507-.592H14.146a1.415,1.415,0,0,1-1.414-1.421V19.5a1.415,1.415,0,0,1,1.414-1.414h8.815A1.415,1.415,0,0,1,24.375,19.5Zm-3.568,4.794-.592-1.476-1.171-2.931a.513.513,0,0,0-.027-.058l-.021-.034-.031-.041-.031-.034-.034-.031-.045-.034-.027-.021-.055-.031a.115.115,0,0,0-.034,0l-.055-.017h-.2l-.058.017a.115.115,0,0,0-.034,0l-.051.031-.038.017-.041.034-.034.031-.031.034-.034.041a.043.043,0,0,1-.017.034l-.031.058-1.2,2.935L16.324,24.3a.518.518,0,0,0,.288.685.542.542,0,0,0,.192.038.517.517,0,0,0,.476-.342l.459-1.147H19.4l.459,1.147a.511.511,0,0,0,.476.342.528.528,0,0,0,.188-.038.514.514,0,0,0,.288-.685ZM18.142,22.5h.836l-.418-1.027Z"
				transform="translate(-2.375 -7.547)"
			/>
			<path d="M0,0H24V24H0Z" transform="translate(0)" fill="none" />
		</g>
	</svg>,
	'Language'
);

/**
 * Following Icon (RssFeed with check).
 */
export const FollowingIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<path fill="none" d="M0,0H22V22H0Z" />
				<g>
					<circle cx="4.71" cy="15.38" r="2.07" />
					<path d="M11,17.29H9.15A6.36,6.36,0,0,0,7.29,12.8,6.28,6.28,0,0,0,2.8,10.94V8.39a8.83,8.83,0,0,1,5.41,1.83,9,9,0,0,1,3.15,4.64A7,7,0,0,0,11,17.09v.2Zm2-5.13h0A11.47,11.47,0,0,0,8.88,7.6,11.29,11.29,0,0,0,2.8,5.85V3.3a14,14,0,0,1,12.34,7.4A6.82,6.82,0,0,0,13,12.16Z" />
					<polygon points="22.98 10.77 17.28 16.48 15.01 14.22 13.55 15.68 17.28 19.4 24.45 12.23 22.98 10.77" />
				</g>
			</svg>
		</SvgIcon>
	);
};

/**
 * Unfollow Icon (RssFeed with x).
 */
export const UnfollowIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<path fill="none" d="M0,0H22V22H0Z" />
				<g>
					<circle fill="#fff" cx="4.71" cy="16.29" r="2.07" />
					<path
						fill="#fff"
						d="M11.51,18.2H9.15a6.26,6.26,0,0,0-1.86-4.49A6.33,6.33,0,0,0,2.8,11.84V9.3A8.93,8.93,0,0,1,11.1,15a4.87,4.87,0,0,0-.1,1,4.93,4.93,0,0,0,.51,2.2Zm1.11-5.88h0a11.55,11.55,0,0,0-4.1-4A11.41,11.41,0,0,0,2.79,6.76V4.21A14,14,0,0,1,9.85,6.12a14.09,14.09,0,0,1,5,5,5,5,0,0,0-2.25,1.19Z"
					/>
					<g>
						<path fill="none" d="M12,12H24V24H12Z" />
						<polygon
							fill="#fff"
							points="22.71 14.81 21.19 13.29 18 16.49 14.81 13.29 13.29 14.81 16.49 18 13.29 21.19 14.81 22.71 18 19.51 21.19 22.71 22.71 21.19 19.51 18 22.71 14.81"
						/>
					</g>
				</g>
			</svg>
		</SvgIcon>
	);
};

/**
 * Cancel connection request icon.
 *
 * @param {SvgIconProps} props
 */
export const RequestCancelledIcon = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		<g id="Group_4766" data-name="Group 4766" transform="translate(-453 -770)">
			<rect
				id="Rectangle_1981"
				data-name="Rectangle 1981"
				width="24"
				height="24"
				transform="translate(453 770)"
				fill="#e3e3e3"
				opacity="0"
			/>
			<g id="send_black_24dp" transform="translate(454 771)">
				<path id="Path_1978" data-name="Path 1978" d="M0,0H18V18H0Z" fill="none" />
				<path
					id="Path_1979"
					data-name="Path 1979"
					d="M2.007,15,17,9,2.007,3,2,7.667,12.714,9,2,10.333Z"
				/>
				<g id="Group_4595" data-name="Group 4595" transform="translate(10.969 10)">
					<g id="close_black_24dp_1_" data-name="close_black_24dp (1)">
						<path id="Path_7279" data-name="Path 7279" d="M0,0H12V12H0Z" fill="none" />
						<path
							id="Path_7280"
							data-name="Path 7280"
							d="M13,5.806,12.2,5,9,8.195,5.806,5,5,5.806,8.195,9,5,12.2,5.806,13,9,9.807,12.2,13,13,12.2,9.807,9Z"
							transform="translate(-3 -3)"
						/>
						<path
							id="Path_7280_-_Outline"
							data-name="Path 7280 - Outline"
							d="M5.806,4.646,9,7.841,12.2,4.646l1.159,1.159L10.16,9,13.355,12.2,12.2,13.355,9,10.16,5.806,13.355,4.646,12.2,7.841,9,4.646,5.806ZM9,8.548,5.806,5.354l-.452.452L8.548,9,5.354,12.2l.452.452L9,9.453,12.2,12.648l.452-.452L9.453,9l3.195-3.195L12.2,5.354Z"
							transform="translate(-3 -3)"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>,
	'RequestCancelled'
);

/**
 * Remove connection icon.
 *
 * @param {SvgIconProps} props
 */
export const RemoveConnectionIcon = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		<g id="Group_4768" data-name="Group 4768" transform="translate(-415 -796)">
			<path
				id="Path_7355"
				data-name="Path 7355"
				d="M0,0H24V24H0Z"
				transform="translate(415 796)"
				fill="none"
			/>
			<g id="Group_4607" data-name="Group 4607" transform="translate(416 797.925)">
				<path
					id="Subtraction_3"
					data-name="Subtraction 3"
					d="M10.56,14.8H0v-1.85c0-.759.481-1.856,2.774-2.773A13.734,13.734,0,0,1,7.4,9.253a13.721,13.721,0,0,1,3.91.667,4.624,4.624,0,0,0-.75,4.882ZM7.4,7.4a3.7,3.7,0,1,1,3.7-3.7A3.7,3.7,0,0,1,7.4,7.4Z"
				/>
				<g
					id="close_black_24dp_1_"
					data-name="close_black_24dp (1)"
					transform="translate(11.101 9.251)">
					<path id="Path_7279" data-name="Path 7279" d="M0,0H10.9V10.9H0Z" fill="none" />
					<path
						id="Path_7280"
						data-name="Path 7280"
						d="M12.2,5.725,11.474,5,8.6,7.874,5.725,5,5,5.725,7.874,8.6,5,11.474l.725.725L8.6,9.324,11.474,12.2l.725-.725L9.324,8.6Z"
						transform="translate(-3.15 -3.15)"
					/>
					<path
						id="Path_7280_-_Outline"
						data-name="Path 7280 - Outline"
						d="M5.66,4.293,8.546,7.179l2.886-2.886L12.8,5.66,9.913,8.546,12.8,11.433,11.433,12.8,8.546,9.913,5.66,12.8,4.293,11.433,7.179,8.546,4.293,5.66ZM8.546,8.457,5.66,5.571l-.089.089L8.457,8.546,5.571,11.433l.089.089L8.546,8.635l2.886,2.886.089-.089L8.635,8.546,11.522,5.66l-.089-.089Z"
						transform="translate(-3.097 -3.097)"
					/>
				</g>
			</g>
		</g>
	</svg>,
	'RemoveConnectionIcon'
);

export const EvoxLogoIcon = createSvgIcon(
	<svg width="71" height="80" viewBox="0 0 71 80" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M70.7001 17.6L35.4 0L0 17.6V62L35.4 79.7L70.7001 62V17.6Z"
			fill="url(#paint0_linear_2394_2)"
		/>
		<path
			opacity="0.4"
			d="M35.4 0L0 17.6L35.4 35.3L70.7001 17.6L35.4 0Z"
			fill="url(#paint1_linear_2394_2)"
		/>
		<path d="M45.3 43.6001L40.4 47.3001L58 61.3001V53.1001L45.3 43.6001Z" fill="white" />
		<path
			d="M35.1 35.9999L12.7001 18.3999V25.7999L35.1 43.3999L58 25.7999V18.3999L35.1 35.9999Z"
			fill="white"
		/>
		<path d="M12.7001 40.9L25.4 50.6L29.8 47.1L12.7001 34V40.9Z" fill="white" />
		<path d="M12.7001 61.3L20.7001 54.5L12.7001 48V61.3Z" fill="white" />
		<defs>
			<linearGradient
				id="paint0_linear_2394_2"
				x1="0"
				y1="39.85"
				x2="70.7001"
				y2="39.85"
				gradientUnits="userSpaceOnUse">
				<stop offset="0.3" stopColor="#33CCCC" />
				<stop offset="0.4" stopColor="#32C5CA" />
				<stop offset="0.5" stopColor="#2DA2C3" />
				<stop offset="0.8" stopColor="#206391" />
				<stop offset="1" stopColor="#1C4C7F" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_2394_2"
				x1="35.35"
				y1="31.3"
				x2="35.35"
				y2="1.2"
				gradientUnits="userSpaceOnUse">
				<stop stopColor="white" stopOpacity="0.2" />
				<stop offset="0.3" stopColor="white" stopOpacity="0.4" />
				<stop offset="0.5" stopColor="white" stopOpacity="0.6" />
				<stop offset="0.6" stopColor="white" stopOpacity="0.8" />
				<stop offset="0.8" stopColor="white" stopOpacity="0.9" />
				<stop offset="0.9" stopColor="white" />
				<stop offset="1" stopColor="white" />
			</linearGradient>
		</defs>
	</svg>,
	'EvoxLogoIcon'
);

export const EvoxLogoIconOutlined = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
		<path d="M14.8808 11.6459L15.883 9.90979H10.0808L11.1963 11.6459H14.8808Z" fill="#767C80" />
		<path
			d="M11.2111 14.6947L12.1311 13.1144H14.0326L11.4117 17.6534L5.01172 6.56799H17.8117L16.902 8.20851H7.4663L11.2111 14.6947Z"
			fill="#767C80"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.5408 10C21.0191 4.82733 16.4919 0.838867 11.0568 0.838867C5.27631 0.838867 0.521484 5.35033 0.521484 11C0.521484 13.357 1.35582 15.5234 2.74401 17.2418L1.17418 20.318L5.11639 19.3919C6.80984 20.5092 8.85703 21.1612 11.0578 21.1612C16.8373 21.1612 21.592 16.6496 21.592 11V10H21.5408ZM11.0568 2.83887C6.30462 2.83887 2.52148 6.52964 2.52148 11C2.52148 13.0737 3.33151 14.9706 4.67362 16.4165L5.14911 16.9288L5.53644 17.2387L5.90681 17.5073C7.33581 18.5434 9.11803 19.1612 11.0578 19.1612C15.762 19.1612 19.5174 15.5428 19.5909 11.1319V11C19.5909 6.5295 15.8087 2.83887 11.0568 2.83887Z"
			fill="#767C80"
		/>
	</svg>,
	'EvoxLogoIconOutlined'
);

export const AccountCancelOutlined = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M10 4C8.93913 4 7.92172 4.42143 7.17157 5.17157C6.42143 5.92172 6 6.93913 6 8C6 9.06087 6.42143 10.0783 7.17157 10.8284C7.92172 11.5786 8.93913 12 10 12C11.0609 12 12.0783 11.5786 12.8284 10.8284C13.5786 10.0783 14 9.06087 14 8C14 6.93913 13.5786 5.92172 12.8284 5.17157C12.0783 4.42143 11.0609 4 10 4ZM10 6C10.5304 6 11.0391 6.21071 11.4142 6.58579C11.7893 6.96086 12 7.46957 12 8C12 8.53043 11.7893 9.03914 11.4142 9.41421C11.0391 9.78929 10.5304 10 10 10C9.46957 10 8.96086 9.78929 8.58579 9.41421C8.21071 9.03914 8 8.53043 8 8C8 7.46957 8.21071 6.96086 8.58579 6.58579C8.96086 6.21071 9.46957 6 10 6ZM10 13C7.33 13 2 14.33 2 17V20H11.5C11.2483 19.394 11.0899 18.7534 11.03 18.1H3.9V17C3.9 16.36 7.03 14.9 10 14.9C10.5 14.9 11 14.95 11.5 15.03C11.7566 14.3985 12.1109 13.8114 12.55 13.29C11.61 13.1 10.71 13 10 13ZM17.5 13C15 13 13 15 13 17.5C13 20 15 22 17.5 22C20 22 22 20 22 17.5C22 15 20 13 17.5 13ZM17.5 14.5C19.16 14.5 20.5 15.84 20.5 17.5C20.5 18.06 20.35 18.58 20.08 19L16 14.92C16.42 14.65 16.94 14.5 17.5 14.5ZM14.92 16L19 20.08C18.58 20.35 18.06 20.5 17.5 20.5C15.84 20.5 14.5 19.16 14.5 17.5C14.5 16.94 14.65 16.42 14.92 16Z"
			fill="#767C80"
		/>
	</svg>,
	'AccountCancelOutlined'
);

export const AccountQuestionOutlined = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
		<path
			d="M19.5 10.5V12H18V10.5H19.5ZM17.5 5.5H16V5C16 4.20435 16.3161 3.44129 16.8787 2.87868C17.4413 2.31607 18.2044 2 19 2C19.7956 2 20.5587 2.31607 21.1213 2.87868C21.6839 3.44129 22 4.20435 22 5C22 5.97 21.5 6.88 20.71 7.41L20.41 7.6C19.84 8 19.5 8.61 19.5 9.3V9.5H18V9.3C18 8.11 18.6 7 19.59 6.35L19.88 6.16C20.27 5.9 20.5 5.47 20.5 5C20.5 4.60218 20.342 4.22064 20.0607 3.93934C19.7794 3.65804 19.3978 3.5 19 3.5C18.6022 3.5 18.2206 3.65804 17.9393 3.93934C17.658 4.22064 17.5 4.60218 17.5 5V5.5ZM8 9C10.67 9 16 10.34 16 13V16H0V13C0 10.34 5.33 9 8 9ZM8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4C12 5.06087 11.5786 6.07828 10.8284 6.82843C10.0783 7.57857 9.06087 8 8 8C6.93913 8 5.92172 7.57857 5.17157 6.82843C4.42143 6.07828 4 5.06087 4 4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0ZM8 10.9C5.03 10.9 1.9 12.36 1.9 13V14.1H14.1V13C14.1 12.36 10.97 10.9 8 10.9ZM8 1.9C7.44305 1.9 6.9089 2.12125 6.51508 2.51508C6.12125 2.9089 5.9 3.44305 5.9 4C5.9 4.55695 6.12125 5.0911 6.51508 5.48492C6.9089 5.87875 7.44305 6.1 8 6.1C8.55695 6.1 9.0911 5.87875 9.48492 5.48492C9.87875 5.0911 10.1 4.55695 10.1 4C10.1 3.44305 9.87875 2.9089 9.48492 2.51508C9.0911 2.12125 8.55695 1.9 8 1.9Z"
			fill="#767C80"
		/>
	</svg>,
	'AccountQuestionOutlined'
);

export const TwitterX = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
		<path d="M8 2H1L9.26086 13.0145L1.44995 21.9999H4.09998L10.4883 14.651L16 22H23L14.3917 10.5223L21.8001 2H19.1501L13.1643 8.88578L8 2ZM17 20L5 4H7L19 20H17Z"></path>
	</svg>,
	'TwitterX'
);

export const LinkedIn = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
		<path d="M6.94048 4.99993C6.94011 5.81424 6.44608 6.54702 5.69134 6.85273C4.9366 7.15845 4.07187 6.97605 3.5049 6.39155C2.93793 5.80704 2.78195 4.93715 3.1105 4.19207C3.43906 3.44699 4.18654 2.9755 5.00048 2.99993C6.08155 3.03238 6.94097 3.91837 6.94048 4.99993ZM7.00048 8.47993H3.00048V20.9999H7.00048V8.47993ZM13.3205 8.47993H9.34048V20.9999H13.2805V14.4299C13.2805 10.7699 18.0505 10.4299 18.0505 14.4299V20.9999H22.0005V13.0699C22.0005 6.89993 14.9405 7.12993 13.2805 10.1599L13.3205 8.47993Z"></path>
	</svg>,
	'LinkedIn'
);

export const Facebook = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
		<path d="M14 13.5H16.5L17.5 9.5H14V7.5C14 6.47062 14 5.5 16 5.5H17.5V2.1401C17.1743 2.09685 15.943 2 14.6429 2C11.9284 2 10 3.65686 10 6.69971V9.5H7V13.5H10V22H14V13.5Z"></path>
	</svg>,
	'Facebook'
);

export const WordDocumentOutlined = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M5 3C3.89 3 3 3.89 3 5V19C3 20.11 3.89 21 5 21H19C20.11 21 21 20.11 21 19V5C21 3.89 20.11 3 19 3H5ZM5 5H19V19H5V5ZM17.9 7L15.5 17H14L12 9.5L10 17H8.5L6.1 7H7.8L9.34 14.5L11.3 7H12.7L14.67 14.5L16.2 7H17.9Z"
			fill="#767C80"
		/>
	</svg>,
	'WordDocumentOutlined'
);

export const ExcelFileOutlined = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M5 3C3.89 3 3 3.89 3 5V19C3 20.11 3.89 21 5 21H19C20.11 21 21 20.11 21 19V5C21 3.89 20.11 3 19 3H5ZM5 5H19V19H5V5ZM13 12L16.2 17H14.2L12 13.2L9.8 17H7.8L11 12L7.8 7H9.8L12 10.8L14.2 7H16.2L13 12Z"
			fill="#767C80"
		/>
	</svg>,
	'ExcelFileOutlined'
);

export const PowerPointFileOutlined = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M5 3C3.89 3 3 3.89 3 5V19C3 20.11 3.89 21 5 21H19C20.11 21 21 20.11 21 19V5C21 3.89 20.11 3 19 3H5ZM5 5H19V19H5V5ZM15.1 7.88C14.45 7.29 13.83 7 12.3 7H8V17H9.8V13.4H12.3C13.8 13.4 14.46 13.12 15.1 12.58C15.74 12.03 16 11.25 16 10.23C16 9.26 15.75 8.5 15.1 7.88ZM13.6 11.5C13.28 11.81 12.9 12 12.22 12H9.8V8.4H12.1C12.76 8.4 13.27 8.65 13.6 9C13.93 9.35 14.1 9.72 14.1 10.24C14.1 10.8 13.92 11.19 13.6 11.5Z"
			fill="#767C80"
		/>
	</svg>,
	'PowerPointFileOutlined'
);

export const CsvFileOutlined = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20ZM10 19L12 15H9V10H15V15L13 19H10Z"
			fill="#767C80"
		/>
	</svg>,
	'CsvFileOutlined'
);

export const PdfFileOutlined = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19 5H5L5 19H19V5ZM5 3C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5Z"
			fill="#767C80"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.30769 12.923C9.04597 12.923 9.69231 12.2769 9.69231 11.5384V10.6153C9.69231 9.87682 9.04597 9.23071 8.30769 9.23071H6V14.7692H7.38462V12.923H8.30769ZM8.30769 10.6153H7.38462V11.5384H8.30769V10.6153Z"
			fill="#767C80"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.9231 14.7692C13.6614 14.7692 14.3077 14.1231 14.3077 13.3846V10.6153C14.3077 9.87682 13.6614 9.23071 12.9231 9.23071H10.6154V14.7692H12.9231ZM12.9231 10.6153H12V13.3846H12.9231V10.6153Z"
			fill="#767C80"
		/>
		<path
			d="M16.6154 10.6153H18V9.23071H15.2308V14.7692H16.6154V12.923H18V11.5384H16.6154V10.6153Z"
			fill="#767C80"
		/>
	</svg>,
	'PdfFileOutlined'
);

export const XactimateFileOutlined = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M1.09082 21.3384L9.78113 11.1325C9.02774 9.63768 8.27663 8.14802 7.5278 6.66351C6.78069 5.18471 6.03187 3.70677 5.2779 2.19541C5.39442 2.19027 5.46296 2.18427 5.53493 2.18427H10.9583C11.0078 2.1795 11.0576 2.18123 11.1066 2.18941C11.1512 2.20312 11.1906 2.23 11.2197 2.26652C11.2494 2.31482 11.273 2.36663 11.2899 2.42074L13.1243 6.86657C13.1508 6.93083 13.1808 6.99508 13.2237 7.0919C13.2811 7.01907 13.3248 6.96852 13.3633 6.9154C14.4223 5.43775 15.4816 3.96237 16.5411 2.48928C16.8059 2.12087 16.6808 2.19027 17.1229 2.18941C18.8975 2.18598 20.6722 2.18598 22.4469 2.18941H22.7039C22.7262 2.29908 22.6525 2.3462 22.6071 2.40189C22.0097 3.13358 21.4116 3.86413 20.813 4.59353C19.0709 6.71777 17.3288 8.8423 15.5867 10.9671C15.5421 11.0211 15.501 11.0785 15.4547 11.1428L19.6915 19.4355C20.7272 21.2726 20.7272 21.2726 20.7272 21.4121C18.3134 21.4052 17.5758 21.4038 15.1626 21.4078H15.0135C14.9743 21.4115 14.935 21.4017 14.9022 21.3798C14.8694 21.358 14.8452 21.3255 14.8336 21.2879C14.8121 21.2356 14.7856 21.1851 14.7616 21.1337C13.8825 19.2339 13.0032 17.3347 12.1236 15.4361C12.091 15.3667 12.0559 15.2981 12.0165 15.2184C11.9154 15.2579 11.8734 15.3444 11.8168 15.4121C10.839 16.5927 9.86195 17.7742 8.8858 18.9566C8.25692 19.7165 7.62633 20.4748 7.00088 21.239C6.95961 21.2952 6.90506 21.3402 6.84211 21.3701C6.77916 21.4 6.70978 21.4139 6.64018 21.4104C4.87264 21.4053 3.10511 21.4104 1.33757 21.4104C1.26218 21.4104 1.18764 21.4027 1.11224 21.3984L1.09082 21.3384Z"
			fill="#767C80"
		/>
	</svg>,
	'XactimateFileOutlined'
);
