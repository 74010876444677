import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material';
import { useOrgAssociatesQuery } from 'components/pages/org/associates/hooks';
import { useAssociateInvitationModal } from 'components/pages/org/associates/modals/invitation';
import { useOrgId } from 'components/pages/org/outlet';
import { PageContent, PageTitle } from 'components/ui/page';
import { Search } from 'components/ui/search';
import SortableColumnCell from 'components/ui/sortable-column-cell';
import React, { useState } from 'react';
import { PageError } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { DEFAULTPAGESIZES } from 'utils/theme';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';
import { AssociateRow } from './associate-row';
import { AssociateStatusFilter, AssociateStatusFilters } from './associate-status-filter';

/**
 * OrgAssociates contains the entire organization associates page.
 *
 * @returns
 */
export const OrgAssociatesPage = () => {
	const orgId = useOrgId();

	const [page, setPage] = useState<number>(0);
	const [isAsc, setIsAsc] = useState<boolean>(true);
	const [sortBy, setSortBy] = useState<string>('status');
	const [searchFor, setSearchFor] = useState('');
	const [statusFilters, setStatusFilters] = useState<AssociateStatusFilters>({
		includeActive: true,
		includeInactive: false,
		includeInvited: false,
		includeDeclined: false,
	});
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const [pageSize, setPageSize] = useState<number>(DEFAULTPAGESIZES[0]);
	const { totalCount, associates, loading, refetch, error } = useOrgAssociatesQuery(
		orgId,
		pageSize,
		page,
		sortBy,
		isAsc ? 'Ascending' : 'Descending',
		searchFor,
		statusFilters.includeActive,
		statusFilters.includeInactive,
		statusFilters.includeInvited,
		statusFilters.includeDeclined
	);

	const canInvite =
		hasAssociateUserPermission(Permission.Org_Assoc_Inv_C) ||
		hasSiteUserPermission(Permission.Site_OrgAssocInv_C);

	if (error) throw PageError;

	/**
	 * On page changed.
	 *
	 * @param {number} p
	 */
	const handlePageChange = (_: unknown, p: number): void => {
		setPage(p);
	};

	/**
	 * On page size changed.
	 *
	 * @param {React.ChangeEvent<{ value: unknown; }>} e
	 */
	const handlePageSizeChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	): void => {
		const value = parseInt(e.target.value);
		setPageSize(value);
		setPage(0);
		refetch();
	};

	/**
	 * Handle column sorting pressed.
	 *
	 * @param {string} dataKey
	 */
	const handleSortClick = (dataKey: string): void => {
		if (dataKey === sortBy) {
			setIsAsc(!isAsc);
		} else {
			setSortBy(dataKey);
		}
	};

	/**
	 * on Search (enter or button pressed).
	 *
	 * @param {string} str
	 */
	const handleSearch = (str: string): void => {
		setSearchFor(str);
	};

	/**
	 * When the associate invitation modal is closed and it is successful
	 * clear out the search, sort and page states and
	 * sort by id.
	 *
	 * @param {boolean} success
	 */
	const handleInviteAssociateClose = (): void => {
		setSortBy('status');
		setIsAsc(true);
		setSearchFor('');
		setPage(0);
		refetch();
	};

	const { openInvitation } = useAssociateInvitationModal(orgId, handleInviteAssociateClose);

	return (
		<PageContent>
			<PageTitle title="Associates" />
			<Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
				<CardHeader
					title="Associates"
					action={
						<Stack direction="row" spacing={1.5}>
							<Search onChange={handleSearch} />
							<AssociateStatusFilter
								currentFilters={statusFilters}
								setCurrentFilters={setStatusFilters}
							/>
							{canInvite && (
								<Button
									onClick={(): void => openInvitation()}
									variant="contained"
									color="primary">
									Invite Associate
								</Button>
							)}
						</Stack>
					}></CardHeader>
				<CardContent
					sx={{
						flex: '1 1 auto',
						overflow: 'hidden',
					}}>
					<TableContainer sx={{ maxHeight: '100%' }}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<SortableColumnCell
										column="displayName"
										onSort={handleSortClick}
										active={'displayName' === sortBy}
										direction={isAsc ? 'asc' : 'desc'}>
										Name
									</SortableColumnCell>
									<SortableColumnCell
										column="emailAddress"
										onSort={handleSortClick}
										active={'emailAddress' === sortBy}
										direction={isAsc ? 'asc' : 'desc'}>
										Contact
									</SortableColumnCell>
									<SortableColumnCell
										column="organizationRoleName"
										onSort={handleSortClick}
										active={'organizationRoleName' === sortBy}
										direction={isAsc ? 'asc' : 'desc'}>
										Role
									</SortableColumnCell>
									<SortableColumnCell
										column="status"
										onSort={handleSortClick}
										active={'status' === sortBy}
										direction={isAsc ? 'asc' : 'desc'}>
										Status
									</SortableColumnCell>
									<SortableColumnCell
										column="displayOnProfile"
										onSort={handleSortClick}
										active={'displayOnProfile' === sortBy}
										direction={isAsc ? 'asc' : 'desc'}>
										Display on Profile?
									</SortableColumnCell>
									<TableCell size="small" style={{ width: '10%' }}></TableCell>
								</TableRow>
							</TableHead>
							{loading ? (
								<TableBody>
									{[...Array(pageSize)].map((_, index) => (
										<TableRow key={index}>
											<TableCell colSpan={6}>
												<Skeleton variant="text" />
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							) : (
								<TableBody>
									{associates.map((associate, i) => (
										<AssociateRow key={i} orgId={orgId} associate={associate} />
									))}
								</TableBody>
							)}
						</Table>
					</TableContainer>
				</CardContent>
				<CardActions disableSpacing sx={{ justifyContent: 'flex-end' }}>
					<TablePagination
						component={Stack}
						direction="row"
						justifyContent="flex-end"
						alignItems="center"
						count={totalCount}
						page={page}
						rowsPerPage={pageSize}
						onPageChange={handlePageChange}
						onRowsPerPageChange={handlePageSizeChange}
					/>
				</CardActions>
			</Card>
		</PageContent>
	);
};
