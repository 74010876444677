import { FileAccessLevel, FoldersApiEntityType } from 'middleware-types';
import { DesktopDocumentsPage } from './desktop/desktop-documents-page';
import { createContext, useContext } from 'react';
import { useIsMobile } from 'utils/useScreenSize';
import { MobileDocumentsPage } from './mobile/mobile-documents-page';
import { PageTitle } from 'components/ui/page';

interface DocumentsPageProps {
	entityType: FoldersApiEntityType;
	entityId: string;
	entityName: string;
	permissions: {
		update: FileAccessLevel[];
		delete: FileAccessLevel[];
	};
}

const DocumentsContext = createContext<DocumentsPageProps | undefined>(undefined);

export const DocumentsPage = (props: DocumentsPageProps) => {
	const isMobile = useIsMobile();
	return (
		<DocumentsContext.Provider value={props}>
			<PageTitle title="Documents" />
			{isMobile ? <MobileDocumentsPage /> : <DesktopDocumentsPage />}
		</DocumentsContext.Provider>
	);
};

export const useDocumentsContext = () => {
	const value = useContext(DocumentsContext);
	if (value === undefined)
		throw new Error('useDocumentsContext must be used inside a DocumentsPage');
	return value;
};
