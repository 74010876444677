import { Business } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { LegacyUserAdjustmentCompanyWithMetrics } from 'middleware-types';

export const CompanyAvatar = ({ company }: { company: LegacyUserAdjustmentCompanyWithMetrics }) => {
	return (
		<Avatar
			variant="rounded"
			sx={{
				'& img': { objectFit: 'contain' },
				'backgroundColor': 'transparent',
			}}
			src={
				company.systemUrl && company.adjCoLogo
					? `${company.systemUrl}/../images/logos/${company.adjCoLogo}`
					: undefined
			}>
			<Business />
		</Avatar>
	);
};
