import { CheckCircle, CheckCircleOutlined, CircleOutlined } from '@mui/icons-material';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { useEmblem } from 'components/ui/emblem/hooks';
import { Message, MessageParticipant, ParticipantEntityType } from 'middleware-types';
import { useCommunicationsContext } from '../communications-provider';
import { convertParticipantType, currentEntityStrings } from '../helpers/utils';

interface ReadReceiptProps {
	message: Message;
}

export const ReadReceipt = ({ message }: ReadReceiptProps) => {
	const { entityId, entityType } = useCommunicationsContext();

	// remove the participant who sent this message
	const participants = message.messageParticipants.filter(
		(mp) =>
			mp.entityType !== ParticipantEntityType.System &&
			mp.entityId !== message.creatorEntityId
	);
	// get the participants who have read the message
	const _readParticipants = participants.filter((mp) => mp.firstReadUtc !== null);
	// put the current entity last
	const readParticipants = _readParticipants.sort((a, b) => {
		const aValue = a.entityId === entityId ? 1 : 0;
		const bValue = b.entityId === entityId ? 1 : 0;
		return aValue - bValue;
	});

	const numberTotal = participants.length;
	const numberRead = readParticipants.length;
	const noneRead = readParticipants.length === 0;
	const allRead = numberTotal === numberRead;

	return (
		<Box>
			<Tooltip
				placement="right"
				arrow
				enterTouchDelay={0}
				title={
					noneRead ? (
						''
					) : (
						<Box>
							{readParticipants.map((p) => (
								<Typography
									key={p.entityId}
									variant="caption"
									color="white"
									display="block">
									{p.entityId === entityId ? (
										currentEntityStrings[entityType]
									) : (
										<MessageParticipantDisplayName participant={p} />
									)}
								</Typography>
							))}
						</Box>
					)
				}>
				<Stack
					direction="row"
					alignItems="center"
					spacing={1}
					width="fit-content"
					color={noneRead ? 'neutral.600' : 'primary.main'}
					fontSize={18}>
					{noneRead ? (
						<CircleOutlined color="inherit" fontSize="inherit" />
					) : allRead ? (
						<CheckCircle color="inherit" fontSize="inherit" />
					) : (
						<CheckCircleOutlined color="inherit" fontSize="inherit" />
					)}
					<Typography variant="h6" color="inherit">
						Read by {numberRead} of {numberTotal}
					</Typography>
				</Stack>
			</Tooltip>
		</Box>
	);
};

const MessageParticipantDisplayName = ({ participant }: { participant: MessageParticipant }) => {
	const { emblem } = useEmblem(
		participant.entityId,
		convertParticipantType(participant.entityType)
	);

	return <>{emblem?.displayName}</>;
};
