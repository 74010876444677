import { Info, InfoOutlined, Link, LinkOutlined } from '@mui/icons-material';
import { Container, Grid } from '@mui/material';
import { useOrgId } from 'components/pages/org/outlet';
import { NavList, NavListItem } from 'components/ui/nav-list';
import { PageContent, PageTitle } from 'components/ui/page';
import { Outlet } from 'react-router-dom';

export const OrgAccountOutlet = () => {
	return (
		<>
			<PageTitle title="Account Settings" />
			<PageContent mobileNoPadding>
				<Container disableGutters>
					<Grid container spacing={{ xs: 2, sm: 4 }}>
						<Grid item xs={12} md={3}>
							<NavList title="Settings" sticky px={{ xs: 1.5, sm: 0 }}>
								<NavListItem
									activeIcon={<Info color="primary" />}
									icon={<InfoOutlined sx={{ color: 'neutral.500' }} />}
									text="Account Information"
									end
									to="./"
								/>
								<NavListItem
									activeIcon={<Link color="primary" />}
									icon={<LinkOutlined sx={{ color: 'neutral.500' }} />}
									text="Account Linking"
									end
									to="./linking"
								/>
							</NavList>
						</Grid>
						<Grid item xs={12} md={9}>
							<Outlet context={useOrgId()} />
						</Grid>
					</Grid>
				</Container>
			</PageContent>
		</>
	);
};
