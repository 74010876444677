import { Badge, Box, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link, useMatch } from 'react-router-dom';
import { PrimaryNavLinkInfo } from './hooks';
import { BADGE_COUNT_MAX } from 'utils/useBadgeCounts';

export const MobilePrimaryNavLink = ({ linkInfo }: { linkInfo: PrimaryNavLinkInfo }) => {
	const { title, path, onClick, icon, activeIcon, isOpen, badgeCount, isExternal } = linkInfo;
	const isPathActive = useMatch(path ?? '') !== null;
	const isActive = isOpen ?? isPathActive;

	const Icon = isActive && activeIcon !== undefined ? activeIcon : icon;

	const props = path
		? {
				component: Link,
				to: path,
				target: isExternal ? '_blank' : undefined,
		  }
		: {
				onClick,
		  };

	return (
		<ListItem
			disablePadding
			secondaryAction={
				<Box pr={1.5}>
					<Badge badgeContent={badgeCount} max={BADGE_COUNT_MAX} color="error" />
				</Box>
			}>
			<ListItemButton
				{...props}
				selected={isActive}
				sx={{
					'&.Mui-selected': {
						borderWidth: '0 0 0 4px',
					},
					'borderWidth': '0 0 0 4px',
					'borderColor': 'transparent',
					'borderStyle': 'solid',
				}}>
				<ListItemIcon>
					<Icon />
				</ListItemIcon>
				<ListItemText primary={title} />
			</ListItemButton>
		</ListItem>
	);
};
