import { useMediaQuery, useTheme } from '@mui/material';

export const useIsMobile = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	return isMobile;
};

export const useIsSmallScreen = () => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.only('sm'));
	return isSmallScreen;
};

export const useIsMediumOrSmallerScreen = () => {
	const theme = useTheme();
	const isMediumOrSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));
	return isMediumOrSmallerScreen;
};

export const useIsLargeOrSmallerScreen = () => {
	const theme = useTheme();
	const isLargeOrSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));
	return isLargeOrSmallerScreen;
};
