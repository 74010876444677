import { useLazyQuery } from '@apollo/client';
import {
	ConversationApiEntityType,
	Query,
	QueryConversationsWithParticipantsArgs,
} from 'middleware-types';
import { CONVERSATIONS_WITH_PARTICIPANTS } from 'pages/app/conversation-emblem-redirect';
import { useNavigate } from 'react-router-dom';
import { OrgMessageButtonProps } from './OrgMessageButton';

export const useRedirectToOrgConversation = ({
	orgId,
	orgHandle,
	entityId,
	entityType,
}: OrgMessageButtonProps) => {
	const navigate = useNavigate();

	const [getConversations, { loading }] = useLazyQuery<
		Pick<Query, 'conversationsWithParticipants'>,
		QueryConversationsWithParticipantsArgs
	>(CONVERSATIONS_WITH_PARTICIPANTS, {
		fetchPolicy: 'no-cache',
		variables: {
			entityType: ConversationApiEntityType.Organizations,
			entityId: orgId,
			entityIds: [entityId],
		},
	});

	const redirectToOrgConversation = async () => {
		const { data } = await getConversations();
		const conversations = data?.conversationsWithParticipants.items ?? [];

		if (conversations.length === 0) {
			navigate(
				`/orgs/${orgHandle}/conversations?entityType=${entityType}&entityId=${entityId}`
			);
		} else {
			navigate(
				`/orgs/${orgHandle}/conversations?conversationId=${conversations[0].conversationId}`
			);
		}
	};

	return { redirectToOrgConversation, loading };
};
