import { Box, BoxProps } from '@mui/material';

export const FileDropOutline = ({ sx, ...props }: BoxProps) => {
	return (
		<Box
			position="absolute"
			top={0}
			left={0}
			right={0}
			bottom={0}
			border="2px dashed"
			borderColor="primary.main"
			bgcolor="rgba(24, 101, 151, 0.05)"
			zIndex={10}
			sx={{ pointerEvents: 'none', ...sx }}
			{...props}
		/>
	);
};
