import { Box, Card, Divider, Stack, Tooltip } from '@mui/material';
import { AdjusterLicensingSummary } from 'middleware-types';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { useCanProvinces } from 'utils/useCountries';
import { useMapColors } from 'utils/useMapColors';
import { CanContextOptions } from './can/modal';
import { LicensingSummaryDetails } from './licensing-summary-details';
import { MapKey } from './map-key';

interface CanLicensingSummaryProps {
	summary: AdjusterLicensingSummary;
}

export const CanLicensingSummary = ({ summary }: CanLicensingSummaryProps) => {
	const { provincesLookupMapById } = useCanProvinces();
	const activeProvinceNames =
		summary.activeRegionIds?.map((id) => provincesLookupMapById.get(id)?.displayName) ?? [];
	const expiredProvinceNames =
		summary.expiredRegionIds?.map((id) => provincesLookupMapById.get(id)?.displayName) ?? [];

	const { defaultColor, activeColor, expiredColor, borderColor } = useMapColors();

	return (
		<Card>
			<Stack direction="row" justifyContent="space-between" alignItems="center" p={1.5}>
				<LicensingSummaryDetails summary={summary} />
				<CanContextOptions summary={summary} />
			</Stack>
			<Divider />
			<Box p={2}>
				<ComposableMap
					projection="geoAlbers"
					projectionConfig={{ rotate: [90, -24, 0], scale: 800 }}>
					<Geographies geography="/maps/canada-topo.json">
						{({ geographies }) =>
							geographies.map((geo) => {
								const isActive = activeProvinceNames.includes(geo.properties.name);
								const isExpired = expiredProvinceNames.includes(
									geo.properties.name
								);
								const fill = isActive
									? activeColor
									: isExpired
									? expiredColor
									: defaultColor;
								return (
									<Tooltip
										key={geo.id}
										title={geo.properties.name}
										followCursor
										arrow>
										<Geography
											geography={geo}
											stroke={borderColor}
											fill={fill}
											tabIndex={-1}
											style={{
												default: { outline: 'none' },
												hover: { outline: 'none' },
												pressed: { outline: 'none' },
											}}
										/>
									</Tooltip>
								);
							})
						}
					</Geographies>
				</ComposableMap>
				<MapKey />
			</Box>
		</Card>
	);
};
