import { gql, useQuery } from '@apollo/client';
import { useOrgId } from 'components/pages/org/outlet';
import {
	Query,
	QueryOrganizationNotificationDetailsArgs,
	QuerySystemNotificationDetailsArgs,
} from 'middleware-types';
import { NotificationSummariesPageType } from '../notification-summaries-page';

const NOTIFICATION_DETAIL_FIELDS = gql`
	fragment NotificationDetailFields on NotificationDetail {
		id
		notificationType
		createdUtc
		inAppText
		smsText
		emailSubject
		emailPlainTextBody
		numUsersSent
		numUsersSending
		numUsersFailed
	}
`;

/** system notifications */
const GET_SYSTEM_NOTIFICATION_DETAILS = gql`
	${NOTIFICATION_DETAIL_FIELDS}
	query GetSystemNotificationDetails($notificationId: ID!) {
		systemNotificationDetails(notificationId: $notificationId) {
			...NotificationDetailFields
		}
	}
`;

const useSystemNotificationDetails = (notificationId: string | undefined) => {
	const { data, refetch, loading } = useQuery<
		Pick<Query, 'systemNotificationDetails'>,
		QuerySystemNotificationDetailsArgs
	>(GET_SYSTEM_NOTIFICATION_DETAILS, {
		fetchPolicy: 'cache-and-network',
		skip: !notificationId,
		variables: { notificationId: notificationId! },
	});

	const details = data?.systemNotificationDetails;
	return { details, refetch, loading };
};

/** organization notifications */
const GET_ORGANIZATION_NOTIFICATION_DETAILS = gql`
	${NOTIFICATION_DETAIL_FIELDS}
	query GetOrganizationNotificationDetails($organizationId: ID!, $notificationId: ID!) {
		organizationNotificationDetails(
			organizationId: $organizationId
			notificationId: $notificationId
		) {
			...NotificationDetailFields
		}
	}
`;

const useOrganizationNotificationDetails = (notificationId: string | undefined) => {
	const organizationId = useOrgId();

	const { data, refetch, loading } = useQuery<
		Pick<Query, 'organizationNotificationDetails'>,
		QueryOrganizationNotificationDetailsArgs
	>(GET_ORGANIZATION_NOTIFICATION_DETAILS, {
		fetchPolicy: 'cache-and-network',
		skip: !notificationId,
		variables: { organizationId, notificationId: notificationId! },
	});

	const details = data?.organizationNotificationDetails;
	return { details, refetch, loading };
};

/** choose which hook to use based on which page we are looking at */
export const useNotificationDetails = (
	type: NotificationSummariesPageType,
	notificationId: string | undefined
) => {
	const hook =
		type === 'system' ? useSystemNotificationDetails : useOrganizationNotificationDetails;
	return hook(notificationId);
};
