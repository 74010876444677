import { Box, BoxProps } from '@mui/material';
import { Editor, EditorContent } from '@tiptap/react';

interface RichTextContentProps extends BoxProps {
	editor: Editor | null;
}

export const RichTextContent = ({ editor, ...boxProps }: RichTextContentProps) => {
	return (
		<Box
			onClick={() => editor?.commands.focus()}
			sx={{
				'cursor': editor?.isEditable ? 'text' : undefined,
				'& .tiptap': {
					'outline': 'none',
					'& p, & ul, & ol': {
						fontSize: '0.875rem',
					},
					'& h1': {
						fontSize: '1.375rem',
					},
					'& h2': {
						fontSize: '1.125rem',
					},
					'& h1, & h2, & h3, & h4, & h5, & h6, & p, & ul, & ol': {
						marginBlockStart: 0,
						marginBlockEnd: 0,
					},
					'& a': {
						cursor: 'pointer',
						textDecoration: 'underline',
					},
				},
				...boxProps.sx,
			}}
			{...boxProps}>
			<EditorContent editor={editor} />
		</Box>
	);
};
