import {
	useNotificationDefaults,
	useNotificationSettings,
	useUpdateNotificationSettings,
} from './hooks';
import { InfoOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { Formik, FormikErrors } from 'formik';
import {
	NotificationSettingDefault,
	NotificationSettingInput,
	NotificationSettingsInput,
	NotificationType,
} from 'middleware-types';
import { useIsMobile } from 'utils/useScreenSize';
import { NotificationSettingsTable } from './notification-settings-table';

// form types
export type SettingFormValues = Omit<NotificationSettingInput, 'notificationType'>;
export type SettingsFormValues = Record<NotificationType, SettingFormValues>;

export const NotificationSettingsPage = ({ userId }: { userId: string }) => {
	const { defaults, loading: defaultsLoading } = useNotificationDefaults();
	const { settings, loading: settingsLoading } = useNotificationSettings(userId);
	const updateSettings = useUpdateNotificationSettings(userId);

	const isMobile = useIsMobile();

	// TODO return skeleton
	if (settingsLoading || defaultsLoading) return null;

	const initialValues: Partial<SettingsFormValues> = {};
	settings.forEach((setting) => {
		const { notificationType, ...rest } = setting;
		initialValues[notificationType] = rest;
	});

	const validate = (values: SettingsFormValues) => {
		const errors: FormikErrors<SettingsFormValues> = {};
		Object.entries(values).forEach(([key, settings]) => {
			// find the correct item in the defaults
			let currentItem: NotificationSettingDefault | undefined = undefined;
			for (let i = 0; i < defaults.length; i++) {
				currentItem = defaults[i].items.find((item) => item.notificationType === key);
				if (currentItem) break;
			}
			// return if this setting is not required
			if (currentItem === undefined || !currentItem.requiresAtLeastOne) return;
			// check if at least one method is selected
			if (!(settings.inApp || settings.sms || settings.email)) {
				errors[key] = 'Please select at least one notification method.';
			}
		});
		return errors;
	};

	const onSubmit = async (values: SettingsFormValues) => {
		const settingsInput: NotificationSettingsInput = {
			settings: Object.entries(values).map(([notificationType, settings]) => ({
				notificationType: notificationType as NotificationType,
				...settings,
			})),
		};
		return await updateSettings(settingsInput);
	};

	return (
		<Formik<SettingsFormValues>
			initialValues={initialValues as SettingsFormValues}
			validate={validate}
			onSubmit={onSubmit}
			enableReinitialize>
			{({ submitForm, dirty, isValid, isSubmitting }) =>
				isMobile ? (
					<Stack spacing={3}>
						<Paper>
							<Stack direction="row" p={2} spacing={1} alignItems="center">
								<Typography variant="h2">Notifications</Typography>
								<Tooltip title="Customize what notifications you receive, and where you receive them.">
									<InfoOutlined sx={{ color: 'neutral.500' }} />
								</Tooltip>
							</Stack>
							<NotificationSettingsTable defaults={defaults} columns="methods" />
						</Paper>
						<Paper>
							<Stack direction="row" p={2} spacing={1} alignItems="center">
								<Typography variant="h2">Email Digest</Typography>
								<Tooltip title="A user curated list of notifications in your inbox exactly when you want them.">
									<InfoOutlined sx={{ color: 'neutral.500' }} />
								</Tooltip>
							</Stack>
							<NotificationSettingsTable defaults={defaults} columns="emailDigest" />
						</Paper>
						<Stack px={2.5} direction="row" justifyContent="flex-end">
							<LoadingButton
								variant="contained"
								color="primary"
								onClick={submitForm}
								disabled={!dirty || !isValid}
								loading={isSubmitting}>
								Save Changes
							</LoadingButton>
						</Stack>
					</Stack>
				) : (
					<Paper>
						<Stack direction="row" p={2} spacing={1} alignItems="center">
							<Typography variant="h2">Notifications</Typography>
							<Tooltip title="Customize what notifications you receive, and where you receive them.">
								<InfoOutlined sx={{ color: 'neutral.500' }} />
							</Tooltip>
							<Box flex={1} />
							<LoadingButton
								variant="contained"
								color="primary"
								onClick={submitForm}
								disabled={!dirty || !isValid}
								loading={isSubmitting}>
								Save Changes
							</LoadingButton>
						</Stack>
						<Divider />
						<NotificationSettingsTable defaults={defaults} columns="all" />
					</Paper>
				)
			}
		</Formik>
	);
};
