import { ToastState } from 'components/ui/toast';
import { GraphQLError } from 'graphql';

export class PageError extends Error {
	constructor(errs: any) {
		// handle graphql error with user message if we have one
		let userMessages = [];
		if (errs.graphQLErrors) {
			userMessages = errs.graphQLErrors
				.map((e: any) => e.extensions['userMessage'])
				.filter((m: any) => !!m);
		}

		// Overload the errs arg with an array.
		// We only will use the first error.
		if (userMessages.length > 0) {
			super(userMessages[0]);
		} else if (Array.isArray(errs)) {
			super(errs[0]?.message ?? errs[0].toString());
		} else {
			super(errs?.message ?? errs.toString());
		}

		// Set the prototype explicitly.
		Object.setPrototypeOf(this, PageError.prototype);
	}
}

// Status codes where an alert should display to the user.
export const UserErrors = [402, 409, 410, 413, 415, 418, 422, 423, 428, 451];

/**
 * @deprecated The method should not be used
 */
export const MutationErrorText = (err?: readonly GraphQLError[], showErrors?: boolean) => {
	let errorDescriptions: string[] = [];
	if (err) {
		for (let e of err as any) {
			if (e.extensions?.response?.status === 432)
				return 'You do not have sufficient Assume Identity permissions.';
			if (showErrors && UserErrors.includes(e.extensions?.response?.status)) {
				errorDescriptions.push(e.extensions?.response?.body?.description);
			}
		}
		return errorDescriptions.length > 0
			? errorDescriptions.join('; ')
			: 'We could not submit this form because one or more fields were invalid.';
	}
	return fallbackErrorMessage;
};

type ErrorResponseHandlerOptions = {
	toast?: ToastState;
};

export const fallbackErrorMessage = 'An error occurred. Please try again later or contact Support.';

export const responseHasErrors = (
	errors: readonly GraphQLError[] | undefined,
	options: ErrorResponseHandlerOptions = {}
) => {
	if (!errors) {
		return false;
	}

	const { toast } = options;

	if (toast) {
		const userMessages = errors.map((e) => e.extensions['userMessage']).filter((m) => !!m);
		let message = userMessages.length ? userMessages.join('. ') : fallbackErrorMessage;
		const responses: any = errors.map((e) => e.extensions.response);
		if (UserErrors.includes(responses[0]?.status)) {
			message = 'We could not submit this form because one or more fields were invalid.';
		}

		toast.push(message, { variant: 'error' });
	}

	return true;
};

export const handleNoResponse = (options: ErrorResponseHandlerOptions) => {
	const { toast } = options;
	if (toast) {
		toast.push(fallbackErrorMessage, { variant: 'error' });
	}
};
