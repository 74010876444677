import { CloseOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Breakpoint,
	Button,
	ButtonProps,
	Container,
	Dialog,
	DialogActionsProps,
	DialogTitle,
	Divider,
	IconButton,
	DialogActions as MuiDialogActions,
	DialogContent as MuiDialogContent,
	Paper,
	Slide,
	Stack,
	Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useCallback, useState } from 'react';
import { useIsMobile } from 'utils/useScreenSize';

/**
 * Confirmation modal parameters.
 *
 */
export type ModalProps = {
	title: string;
	maxWidth?: Breakpoint;
	content?: string | React.JSX.Element | undefined;
	actions?: React.JSX.Element;
	hero?: string;
};

/**
 * Confirm context type
 *
 */
type ModalContextState = {
	showModal: (props: ModalProps) => void;
	closeModal: () => void;
};

/**
 * Modal context
 *
 */
const ModalContext = React.createContext<ModalContextState>({
	showModal: () => {
		return;
	},
	closeModal: () => {
		return;
	},
});

const ModalTransition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Modal Container
 *
 * @param {*} {children}
 * @returns
 */
export const ModalContainer = ({ children }: { children: React.ReactNode }) => {
	const [modalProps, setModalProps] = useState<ModalProps | undefined>();
	const isMobile = useIsMobile();

	/**
	 * showModal() opens the modal.
	 *
	 * @param {ConfirmProps} mprops
	 */
	const showModal = (mprops: ModalProps) => {
		setModalProps(mprops);
	};

	/**
	 *  closeModal() - close Modal
	 *
	 */
	const closeModal = () => setModalProps(undefined);

	return (
		<ModalContext.Provider value={{ closeModal, showModal }}>
			{children}
			<Dialog
				TransitionComponent={isMobile ? ModalTransition : undefined}
				transitionDuration={isMobile ? 500 : 0}
				open={modalProps !== undefined}
				onClose={closeModal}
				PaperComponent={({ children }) => (
					<Container
						sx={
							isMobile
								? { position: 'absolute', bottom: 0, padding: 'unset' }
								: undefined
						}
						maxWidth={modalProps?.maxWidth ?? 'sm'}>
						<Paper sx={isMobile ? { borderRadius: '8px 8px 0 0' } : undefined}>
							{children}
						</Paper>
					</Container>
				)}>
				{modalProps?.title && (
					<>
						<DialogTitle>
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center">
								<Typography variant="h3">{modalProps.title}</Typography>
								<IconButton onClick={() => closeModal()}>
									<CloseOutlined />
								</IconButton>
							</Stack>
						</DialogTitle>
						<Divider />
					</>
				)}
				{modalProps?.hero && <img style={{ width: '100%' }} src={modalProps.hero} />}
				{modalProps?.content && <>{modalProps?.content}</>}
				{modalProps?.actions}
			</Dialog>
		</ModalContext.Provider>
	);
};

/**
 * ModalContent - Rename of MUI's DialogContent.
 * @type {*}
 */
export const ModalContent = MuiDialogContent;

/**
 * ModalActionsProps - The properties of a modal actions panel
 * This is identical to DialogActionsProps with the addition of a justify content.
 *
 * @export
 * @interface ModalActionsProps
 * @extends {DialogActionsProps}
 */
export interface ModalActionsProps extends DialogActionsProps {
	justifyContent?: 'center' | 'flex-end' | undefined;
}

/**
 * ModalActions - Modal actions is a section of a modal where you can place
 * action buttons that a user takes within a modal.
 *
 * @param {ModalActionsProps} props
 */
export const ModalActions = (props: ModalActionsProps) => (
	<MuiDialogActions
		style={{ justifyContent: props.justifyContent, padding: '16px', margin: '0 auto' }}>
		<Stack spacing={'12px'} direction="row">
			{props.children}
		</Stack>
	</MuiDialogActions>
);

/**
 * ModalActionButton - Modal Action Buttons are just plain old MUI Buttons.
 * However, these buttons call to close their parent modal after the button's
 * onClick is called.
 *
 * @param {ButtonProps} props
 * @return {*}
 */
export const ModalActionButton = (props: ButtonProps) => {
	const { closeModal } = useModal();
	return (
		<Button
			{...props}
			onClick={async (ev) => {
				if (props.onClick) await props.onClick(ev);
				closeModal();
			}}
		/>
	);
};

/**
 * PropTypes for a Modal loading button.  This is inherited from MUI ButtonProps
 * The only difference being the onClick is required and must return a Promise<boolean>.
 */
export interface ModalLoadingButtonProps extends Omit<ButtonProps, 'onClick'> {
	onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent> | void) => Promise<boolean>;
}

/**
 * ModalLoadingButton - This is a modal button with a loading indicator.
 * This is useful for triggering an asynchronous request that requires
 * the modal to stay open incase the request fails.
 *
 * NOTE: This modal loading button callback action CAN NOT reference any external state from the modal,
 * if you need to do this, simply use a regular loading button then call closeModal().
 *
 * @param props MUI ButtonProps with one exception:
 * The onClick is required and must return a Promise<boolean>
 * @returns
 */
export const ModalLoadingButton = (props: ModalLoadingButtonProps) => {
	const { onClick, ...rest } = props;
	const { closeModal } = useModal();
	const [loading, setLoading] = useState(false);
	const onClickCallBack = useCallback(
		async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			setLoading(true);
			const result = await onClick(e);
			setLoading(false);
			if (result) closeModal();
		},
		[]
	);

	return <LoadingButton {...rest} loading={loading} onClick={onClickCallBack} />;
};

/**
 * ConfirmModalContent - This is a preformatted confirmation dialog content body.
 *
 * @param {({	visual: string | React.JSX.Element | undefined;
 *         subheadline?: string | undefined;
 *         informativeContent: string;})} props
 * @return {*}
 */
export const ConfirmModalContent = (props: {
	visual: string | React.JSX.Element | undefined;
	subheadline?: string | undefined;
	informativeContent?: string;
}) => {
	let visual: React.JSX.Element | undefined;
	if (props?.visual) {
		if (typeof props?.visual === 'string') {
			visual = (
				<div>
					<img src={props.visual} alt="Confirmation Graphic" />
				</div>
			);
		} else {
			visual = <div>{props?.visual}</div>;
		}
	}

	return (
		<>
			<MuiDialogContent
				sx={{
					padding: '24px 80px',
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}>
				{visual}
				<Typography variant="h1" marginTop={2} sx={{ wordBreak: 'break-word' }}>
					{props?.subheadline}
				</Typography>
				<Typography variant="body1" sx={{ marginTop: '4px' }}>
					{props?.informativeContent}
				</Typography>
			</MuiDialogContent>
			<Divider />
		</>
	);
};

/**
 * useModal - Hook to get the global modal context state.
 *
 * @return {*}  {ModalContextState}
 */
export const useModal = (): ModalContextState => React.useContext<ModalContextState>(ModalContext);
