import {
	FormatAlignCenter,
	FormatAlignLeft,
	FormatAlignRight,
	FormatBold,
	FormatClear,
	FormatItalic,
	FormatListBulleted,
	FormatListNumbered,
	FormatUnderlined,
} from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';
import { Editor } from '@tiptap/react';
import { RichTextLinkButton } from './rich-text-link-button';
import { RichTextToolbarButton } from './rich-text-toolbar-button';
import { RichTextFontSizeButton } from './rich-text-font-size-button';

interface RichTextToolbarProps {
	editor: Editor | null;
}

export const RichTextToolbar = ({ editor }: RichTextToolbarProps) => {
	if (!editor) return null;
	return (
		<Stack direction="row" spacing={1} alignItems="center" overflow="auto" flexShrink={0}>
			<Stack direction="row">
				<RichTextToolbarButton
					tooltipText="Bold"
					selected={editor.isActive('bold')}
					onChange={() => editor.chain().focus().toggleBold().run()}>
					<FormatBold />
				</RichTextToolbarButton>
				<RichTextToolbarButton
					tooltipText="Italic"
					selected={editor.isActive('italic')}
					onChange={() => editor.chain().focus().toggleItalic().run()}>
					<FormatItalic />
				</RichTextToolbarButton>
				<RichTextToolbarButton
					tooltipText="Underline"
					selected={editor.isActive('underline')}
					onChange={() => editor.chain().focus().toggleUnderline().run()}>
					<FormatUnderlined />
				</RichTextToolbarButton>
				<RichTextFontSizeButton editor={editor} />
			</Stack>
			<Divider orientation="vertical" flexItem />
			<Stack direction="row">
				<RichTextToolbarButton
					tooltipText="Bulleted List"
					selected={editor.isActive('bulletList')}
					onChange={() => editor.chain().focus().toggleBulletList().run()}>
					<FormatListBulleted />
				</RichTextToolbarButton>
				<RichTextToolbarButton
					tooltipText="Numbered List"
					selected={editor.isActive('orderedList')}
					onChange={() => editor.chain().focus().toggleOrderedList().run()}>
					<FormatListNumbered />
				</RichTextToolbarButton>
			</Stack>
			<Divider orientation="vertical" flexItem />
			<Stack direction="row">
				<RichTextToolbarButton
					tooltipText="Align Left"
					selected={editor.isActive({ textAlign: 'left' })}
					onClick={() => editor.chain().focus().setTextAlign('left').run()}>
					<FormatAlignLeft />
				</RichTextToolbarButton>
				<RichTextToolbarButton
					tooltipText="Align Center"
					selected={editor.isActive({ textAlign: 'center' })}
					onClick={() => editor.chain().focus().setTextAlign('center').run()}>
					<FormatAlignCenter />
				</RichTextToolbarButton>
				<RichTextToolbarButton
					tooltipText="Align Right"
					selected={editor.isActive({ textAlign: 'right' })}
					onClick={() => editor.chain().focus().setTextAlign('right').run()}>
					<FormatAlignRight />
				</RichTextToolbarButton>
			</Stack>
			<Divider orientation="vertical" flexItem />
			<Stack direction="row">
				<RichTextLinkButton editor={editor} />
			</Stack>
			<Divider orientation="vertical" flexItem />
			<Stack direction="row">
				<RichTextToolbarButton
					tooltipText="Clear Formatting"
					onClick={() => {
						editor.chain().focus().unsetAllMarks().run();
						editor.chain().focus().clearNodes().run();
					}}>
					<FormatClear />
				</RichTextToolbarButton>
			</Stack>
		</Stack>
	);
};
