import { AddCircle, ArrowForwardIos, ModeEditOutlineOutlined } from '@mui/icons-material';
import { Divider, IconButton, List, Skeleton, Stack } from '@mui/material';
import { useCommunicationsContext } from '../communications-provider';
import { getCustomLabels, getDefaultLabels } from '../helpers/labels';
import { useCommunicationsModal } from '../hooks/use-communications-modal';
import { CreateUpdateLabelModal } from '../modals/create-update-label-modal';
import { CompactLabelListItem } from './compact-label-list-item';

interface CompactLabelsColumnProps {
	onOpen: () => void;
}

export const CompactLabelsColumn = ({ onOpen }: CompactLabelsColumnProps) => {
	const { labels, labelsLoading, readOnly, setNewConversationModalOpen } =
		useCommunicationsContext();
	const { showModal } = useCommunicationsModal();

	const defaultLabels = getDefaultLabels(labels);
	const customLabels = getCustomLabels(labels);

	if (labelsLoading) {
		return (
			<Stack p={1} spacing={1}>
				{[...Array(6)].map((_, index) => (
					<Skeleton key={index} variant="rounded" height={40} />
				))}
			</Stack>
		);
	}

	return (
		<Stack height="100%">
			{!readOnly && (
				<Stack p={1} pb={0}>
					<IconButton
						sx={{ backgroundColor: 'primary.main', borderRadius: 1 }}
						onClick={() => setNewConversationModalOpen(true)}>
						<ModeEditOutlineOutlined htmlColor="white" />
					</IconButton>
				</Stack>
			)}
			<Stack flex={1} overflow="auto">
				<List>
					{defaultLabels.map((label) => (
						<CompactLabelListItem key={label.id} label={label} />
					))}
				</List>
				<Divider />
				{!readOnly && (
					<Stack p={1} pb={0}>
						<IconButton
							onClick={() =>
								showModal({
									title: 'Create New Label',
									content: <CreateUpdateLabelModal />,
								})
							}>
							<AddCircle color="primary" />
						</IconButton>
					</Stack>
				)}
				<List>
					{customLabels.map((label) => (
						<CompactLabelListItem key={label.label.id} label={label.label} />
					))}
				</List>
			</Stack>
			<Divider />
			<Stack p={1}>
				<IconButton onClick={onOpen}>
					<ArrowForwardIos />
				</IconButton>
			</Stack>
		</Stack>
	);
};
