import { Editor } from '@tiptap/react';
import { RichTextToolbarButton } from './rich-text-toolbar-button';
import { InsertLink } from '@mui/icons-material';
import { useState } from 'react';
import { Button, Popover, Stack, TextField } from '@mui/material';

interface RichTextLinkButtonProps {
	editor: Editor;
}

export const RichTextLinkButton = ({ editor }: RichTextLinkButtonProps) => {
	const [anchor, setAnchor] = useState<HTMLElement | null>(null);
	const menuOpen = Boolean(anchor);
	const closeMenu = () => setAnchor(null);

	const [url, setUrl] = useState('');

	const onMenuOpen = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		setUrl(editor.getAttributes('link').href ?? '');
		setAnchor(e.currentTarget);
	};

	const onSave = () => {
		if (url) {
			editor
				.chain()
				.focus()
				.extendMarkRange('link')
				.setLink({ href: url.startsWith('http') ? url : `http://${url}` })
				.run();
		} else {
			editor.chain().focus().extendMarkRange('link').unsetLink().run();
		}
		closeMenu();
	};

	return (
		<>
			<RichTextToolbarButton
				tooltipText="Create Link"
				disabled={editor.state.selection.empty && !editor.isActive('link')}
				selected={menuOpen}
				onClick={onMenuOpen}>
				<InsertLink />
			</RichTextToolbarButton>
			<Popover anchorEl={anchor} open={menuOpen} onClose={closeMenu}>
				<Stack direction="row" spacing={1} p={1}>
					<TextField
						value={url}
						placeholder="Enter url"
						onChange={(e) => setUrl(e.target.value)}
						onKeyDown={(e) => {
							// console.log(e);

							if (e.key === 'Enter') {
								e.preventDefault();
								onSave();
							}
						}}
					/>
					<Button variant="contained" color="primary" onClick={onSave}>
						Save
					</Button>
				</Stack>
			</Popover>
		</>
	);
};
