import { FilePresent } from '@mui/icons-material';
import { Chip } from '@mui/material';

interface EvoxFileChipProps {
	name: string;
	onDelete?: () => void;
}

export const EvoxFileChip = ({ name, onDelete }: EvoxFileChipProps) => {
	return (
		<Chip
			avatar={<FilePresent />}
			label={name}
			onDelete={onDelete}
			sx={{
				margin: 0.5,
			}}
		/>
	);
};
