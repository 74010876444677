import { gql, useQuery } from '@apollo/client';
import { Query, QueryFolderArgs, QueryRootFolderContentsArgs } from 'middleware-types';
import { PageError } from 'utils/errors';
import { useDocumentsContext } from '../../documents-page';
import { getRootFolderName } from '../../utils/enum-helpers';
import { FOLDER_CONTENTS_FIELDS, FOLDER_INFO_FIELDS } from '../../utils/fragments.graphql';
import { useCurrentFolderId } from '../use-current-folder-id';
import { useDocumentsSearch } from '../use-documents-search';

export const GET_ROOT_FOLDER_CONTENTS = gql`
	${FOLDER_CONTENTS_FIELDS}
	query GetRootFolderContents($entityType: FoldersApiEntityType!, $entityId: String!) {
		rootFolderContents(entityType: $entityType, entityId: $entityId) {
			...FolderContentsFields
		}
	}
`;

const GET_FOLDER_INFO = gql`
	${FOLDER_INFO_FIELDS}
	query GetFolderInfo(
		$entityType: FoldersApiEntityType!
		$entityId: String!
		$folderId: String!
	) {
		folder(entityType: $entityType, entityId: $entityId, folderId: $folderId) {
			...FolderInfoFields
		}
	}
`;

export const useFolder = () => {
	const { entityId, entityType } = useDocumentsContext();

	const [currentFolderId] = useCurrentFolderId();
	const isRoot = currentFolderId === undefined;

	// get root folder
	const { data: rootData, loading: rootLoading } = useQuery<
		Pick<Query, 'rootFolderContents'>,
		QueryRootFolderContentsArgs
	>(GET_ROOT_FOLDER_CONTENTS, {
		skip: !isRoot,
		fetchPolicy: 'cache-and-network',
		variables: {
			entityId,
			entityType,
		},
	});

	const rootFolders = rootData?.rootFolderContents.folders ?? [];
	const rootFiles = rootData?.rootFolderContents.files ?? [];

	// get folder by id
	const {
		data: folderData,
		loading: folderLoading,
		error: folderError,
	} = useQuery<Pick<Query, 'folder'>, QueryFolderArgs>(GET_FOLDER_INFO, {
		skip: isRoot,
		fetchPolicy: 'cache-and-network',
		variables: {
			entityId,
			entityType,
			folderId: currentFolderId!,
		},
	});

	if (folderError) throw new PageError(folderError);

	const folderName = folderData?.folder.name ?? '';
	const folderAncestors = folderData?.folder.folderAncestors ?? [];
	const folderFolders = folderData?.folder.contents.folders ?? [];
	const folderFiles = folderData?.folder.contents.files ?? [];

	// get search results
	const {
		isSearchActive,
		searchText,
		setSearchText,
		searchValue,
		data: searchData,
		loading: searchLoading,
	} = useDocumentsSearch();

	const searchFolders = searchData?.searchFolders.folders ?? [];
	const searchFiles = searchData?.searchFolders.files ?? [];

	// merge all the data
	const name = isRoot ? getRootFolderName(entityType) : folderName;
	const ancestors = isRoot ? [] : folderAncestors;
	const folders = isSearchActive ? searchFolders : isRoot ? rootFolders : folderFolders;
	const files = (isSearchActive ? searchFiles : isRoot ? rootFiles : folderFiles).map(
		(f) => f.currentInstance!
	);
	const loading = isSearchActive ? searchLoading : isRoot ? rootLoading : folderLoading;

	return {
		isSearchActive,
		isRoot,
		name,
		ancestors,
		folders,
		files,
		loading,
		searchText,
		setSearchText,
		searchValue,
	};
};
