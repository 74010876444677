import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import { useModal, ModalContent, ModalActions, ModalActionButton } from 'components/ui/modal';
import { useToast } from 'components/ui/toast';
import { Formik, FormikProps } from 'formik';
import { responseHasErrors, handleNoResponse } from 'utils/errors';
import {
	useOrgAccountUpdateConversationInsurance,
	GET_ORGANIZATION_CONVERSATION_INSURANCE,
} from './hooks';
import { useValidation } from 'utils/useValidation';

interface UpdateUrlForm {
	url: string;
}

export const ConversationInsuranceModal = ({ orgId, url }: { orgId: string; url?: string }) => {
	const toast = useToast();
	const { closeModal } = useModal();
	const { updateConversationInsurance, loading } = useOrgAccountUpdateConversationInsurance();

	const initialValues = {
		url: url || '',
	};

	const successToastText = url
		? 'Conversation Insurance URL updated successfully.'
		: 'Conversation Insurance URL added successfully.';

	const handleSubmit = async (values: UpdateUrlForm) => {
		const conversationInsuranceUrl: string | undefined =
			values.url === '' ? undefined : values.url;
		await updateConversationInsurance({
			refetchQueries: [
				{
					query: GET_ORGANIZATION_CONVERSATION_INSURANCE,
					variables: { organizationId: orgId },
				},
			],
			awaitRefetchQueries: true,
			variables: {
				organizationId: orgId,
				request: {
					conversationInsuranceUrl,
				},
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					closeModal();
					return false;
				}
				toast.push(successToastText, {
					variant: 'success',
				});
				closeModal();
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				closeModal();
				return false;
			});
	};

	const validation = useValidation('ConversationInsurance');

	return (
		<Formik<UpdateUrlForm>
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validation.schema}>
			{(props: FormikProps<UpdateUrlForm>) => (
				<>
					<ModalContent>
						<Stack spacing={1.5}>
							<TextField
								name="url"
								label="Conversation Insurance URL"
								fullWidth
								variant="outlined"
								value={props.values.url}
								onChange={props.handleChange}
								onBlur={props.handleBlur}
							/>
						</Stack>
					</ModalContent>
					<ModalActions
						sx={{
							direction: 'row',
							spacing: 1,
							padding: 2,
							justifyContent: 'flex-end',
						}}>
						<ModalActionButton variant="outlined">Cancel</ModalActionButton>
						<LoadingButton
							disabled={!props.dirty || !props.isValid}
							data-test="loading-btn"
							variant="contained"
							color="primary"
							onClick={props.submitForm}
							loading={loading || props.isSubmitting}>
							Update
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};
