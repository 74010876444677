import { Box, Button, ButtonProps, Tooltip } from '@mui/material';

export const SIDE_RAIL_BUTTON_SIZE_PX = 48;

type SideRailButtonProps = ButtonProps<'button' | 'a'> & {
	src: string;
	tooltipText: string;
};

export const SideRailButton = ({ src, tooltipText, ...buttonProps }: SideRailButtonProps) => {
	return (
		<Tooltip title={tooltipText} placement="right">
			<Button
				sx={{
					height: SIDE_RAIL_BUTTON_SIZE_PX,
					width: SIDE_RAIL_BUTTON_SIZE_PX,
					minWidth: 0,
					borderRadius: 0,
				}}
				{...(buttonProps as any)}>
				<Box component="img" src={src} height={40} width={40} />
			</Button>
		</Tooltip>
	);
};
