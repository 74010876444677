import { Close } from '@mui/icons-material';
import {
	Box,
	Divider,
	Drawer,
	IconButton,
	Popover,
	PopoverProps,
	Stack,
	Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { useIsMobile } from 'utils/useScreenSize';

interface PopoverOrDrawerProps {
	open: boolean;
	onClose: () => void;
	title: string;
	popoverProps: Omit<PopoverProps, 'open' | 'onClose'>;
	children: ReactNode;
}

/**
 * this component will render a popup on desktop, and a bottom drawer on mobile
 * this pattern is used at least twice so it made sense to create a component for it
 */
export const PopoverOrDrawer = ({
	open,
	onClose,
	title,
	popoverProps,
	children,
}: PopoverOrDrawerProps) => {
	const isMobile = useIsMobile();

	if (isMobile)
		return (
			<Drawer open={open} anchor="bottom">
				<Stack overflow="hidden">
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						p={1}
						pl={2}>
						<Typography variant="h4">{title}</Typography>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</Stack>
					<Divider />
					<Box flex={1} overflow="scroll">
						{children}
					</Box>
				</Stack>
			</Drawer>
		);

	return (
		<Popover {...popoverProps} open={open} onClose={onClose}>
			{children}
		</Popover>
	);
};
