import { gql } from '@apollo/client';
import { EMBLEM_FIELDS } from 'components/ui/emblem/fragments';

export const FILE_INSTANCE_INFO_FIELDS = gql`
	${EMBLEM_FIELDS}
	fragment FileInstanceInfoFields on FileInstanceInformation {
		id
		fileId
		fileName
		asciiFileName
		uploadedUtc
		accessLevel
		virusStatus
		fileSize
		versionNumber
		cdnUrl
		hasPermissionToDelete
		hasPermissionToDownload
		hasPermissionToUpdate
		allowedAccessLevelChangesList
		mimeType
		uploadedBy {
			...EmblemFields
		}
		folder {
			id
			name
		}
	}
`;

export const FILE_INFO_FIELDS = gql`
	${FILE_INSTANCE_INFO_FIELDS}
	fragment FileInfoFields on FileInformation {
		id
		currentInstance {
			...FileInstanceInfoFields
		}
	}
`;

export const FOLDER_REFERENCE_FIELDS = gql`
	fragment FolderReferenceFields on FolderReference {
		id
		name
		parentFolder {
			id
			name
		}
	}
`;

export const FOLDER_CONTENTS_FIELDS = gql`
	${FOLDER_REFERENCE_FIELDS}
	${FILE_INFO_FIELDS}
	fragment FolderContentsFields on FolderContents {
		folders {
			...FolderReferenceFields
		}
		files {
			...FileInfoFields
		}
	}
`;

export const FOLDER_CONTENTS_FIELDS_NO_FILES = gql`
	${FOLDER_REFERENCE_FIELDS}
	fragment FolderContentsFieldsNoFiles on FolderContents {
		folders {
			...FolderReferenceFields
		}
	}
`;

export const FOLDER_INFO_FIELDS = gql`
	${FOLDER_REFERENCE_FIELDS}
	${FOLDER_CONTENTS_FIELDS}
	fragment FolderInfoFields on FolderInformation {
		id
		name
		relatedEntityId
		itemsCount
		folderAncestors {
			...FolderReferenceFields
		}
		contents {
			...FolderContentsFields
		}
	}
`;
