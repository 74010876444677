import { useToast } from 'components/ui/toast';
import { Formik, FormikErrors } from 'formik';
import { removeTagsFromString } from '../helpers/utils';
import { useSendMessage } from '../hooks/conversations-hooks';
import { MessageBox } from '../shared/message-box';
import { useEffect, useRef } from 'react';

export interface FormMessageAttachment {
	file: File;
	uploadToken: string;
	uploadProgress: number;
}

export interface NewMessageFormValues {
	messageText: string;
	messageAttachments: FormMessageAttachment[];
}

interface ConversationMessageBoxProps {
	onSuccess?: () => void;
}

/**
 * Wrapper around MessageBox that handles sending a new message
 */
export const ConversationMessageBox = ({ onSuccess }: ConversationMessageBoxProps) => {
	let messageLoadingRef = useRef(false);
	const toast = useToast();
	const initialValues: NewMessageFormValues = {
		messageText: '',
		messageAttachments: [],
	};

	const validate = (values: NewMessageFormValues) => {
		const errors: FormikErrors<NewMessageFormValues> = {};
		if (!removeTagsFromString(values.messageText)) errors.messageText = 'Required Field';
		if (values.messageAttachments.some((a) => a.uploadProgress !== 1))
			errors.messageAttachments = 'Upload in progress';
		return errors;
	};

	const { sendMessage, loading: sendMessageLoading } = useSendMessage();
	const onSubmit = async (values: NewMessageFormValues) => {
		const { messageText, messageAttachments } = values;

		setTimeout(() => {
			if (messageLoadingRef.current) {
				toast.push(
					"This message is taking a while to send. If you've attached a large file please be patient while we process it. Feel free to leave this page. The message will appear in the conversation when processing is complete.",
					{
						variant: 'success',
					}
				);
			}
		}, 5000);

		const success = await sendMessage({
			messageText,
			attachmentUploadTokens: messageAttachments.map((a) => a.uploadToken),
		});

		if (success) onSuccess?.();
	};

	useEffect(() => {
		messageLoadingRef.current = sendMessageLoading;
	}, [sendMessageLoading]);

	return (
		<Formik<NewMessageFormValues>
			onSubmit={onSubmit}
			initialValues={initialValues}
			validate={validate}>
			<MessageBox />
		</Formik>
	);
};
