import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import { Alert } from 'components/ui/alert';
import { FileField } from 'components/ui/fields';
import { FileType, FileValue } from 'components/ui/fields/file';
import { ModalActionButton, ModalActions, ModalContent, useModal } from 'components/ui/modal';
import { useToast } from 'components/ui/toast';
import { Formik } from 'formik';
import { gql } from 'graphql-tag';
import { FileInstanceInformation } from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useState } from 'react';

export interface FileFieldUploadValues extends Omit<FileValue, 'uploadToken'> {
	uploadToken: string;
}

/**
 * useOrganizationAvatarOrBannerMutation() - Hook to update the org avatar
 */
export const useOrganizationAvatarOrBannerMutation = (organizationId: string) => {
	const [updateOrganizationAvatarOrBannerMutation, { loading, error }] =
		useMutation<FileFieldUploadValues>(
			gql`
				mutation organizationAvatarOrBannerUpdate(
					$organizationId: ID!
					$avatarUploadToken: String
					$bannerUploadToken: String
				) {
					organizationAvatarOrBannerUpdate(
						organizationId: $organizationId
						avatarUploadToken: $avatarUploadToken
						bannerUploadToken: $bannerUploadToken
					) {
						id
						avatarFile {
							fileId
							file {
								id
								currentInstance {
									id
									cdnUrl
									fileName
									fileSize
									virusStatus
									uploadedUtc
								}
							}
						}
						bannerFile {
							fileId
							file {
								id
								currentInstance {
									id
									cdnUrl
									fileName
									fileSize
									virusStatus
									uploadedUtc
								}
							}
						}
					}
				}
			`,
			{
				update: (cache, res, ctx) => {
					const fieldName = ctx.variables?.avatarUploadToken ? 'avatar' : 'banner';

					// Updating the profile picture can change the picture on account, profile, search, and emblem.
					cache.evict({
						id: `OrganizationAccount:${organizationId}`,
						fieldName,
					});
					cache.evict({
						id: `OrganizationEmblem:${organizationId}`,
						fieldName,
					});
					cache.evict({
						id: `QuickSearchEntity:${organizationId}`,
						fieldName: 'displayPicture',
					});

					cache.gc();
				},
			}
		);

	const updateOrganizationAvatarOrBanner = async (
		uploadToken: string,
		type: 'avatar' | 'banner'
	) =>
		updateOrganizationAvatarOrBannerMutation({
			variables: {
				organizationId,
				avatarUploadToken: type === 'avatar' ? uploadToken : null,
				bannerUploadToken: type === 'banner' ? uploadToken : null,
			},
		});

	return { updateOrganizationAvatarOrBanner, loading, error };
};

/**
 * Hook for opening the org emblem uploader that can upload company logo or banner.
 */
export const useOrganizationEmblemUploader = (
	organizationId: string,
	type: 'avatar' | 'banner',
	fileInstance?: FileInstanceInformation
) => {
	const { showModal } = useModal();

	const openOrganizationEmblemUploader = () => {
		showModal({
			title: `Upload Organization ${type === 'avatar' ? 'Logo' : 'Banner'}`,
			maxWidth: 'md',
			content: (
				<OrganizationEmblemUploaderModal
					organizationId={organizationId}
					fileInstance={fileInstance}
					type={type}
				/>
			),
		});
	};

	return { openOrganizationEmblemUploader };
};

const OrganizationEmblemUploaderModal = ({
	organizationId,
	fileInstance,
	type,
}: {
	organizationId: string;
	fileInstance?: FileInstanceInformation;
	type: 'avatar' | 'banner';
}) => {
	const { updateOrganizationAvatarOrBanner, error } =
		useOrganizationAvatarOrBannerMutation(organizationId);

	const { closeModal } = useModal();
	const toast = useToast();

	const [isUploading, setIsUploading] = useState(false);
	return (
		<Formik<{ organizationAvatarOrBanner: FileFieldUploadValues }>
			initialValues={{
				organizationAvatarOrBanner: {
					uploadToken: '',
					id: fileInstance?.id,
					fileName: fileInstance?.fileName,
					fileSize: fileInstance?.fileSize,
					uploadedUtc: fileInstance?.uploadedUtc,
					virusStatus: fileInstance?.virusStatus,
				},
			}}
			onSubmit={(values: { organizationAvatarOrBanner: FileFieldUploadValues }) => {
				return updateOrganizationAvatarOrBanner(
					values.organizationAvatarOrBanner.uploadToken,
					type
				)
					.then((res) => {
						if (responseHasErrors(res.errors, { toast })) {
							console.log(res.errors);
							return false;
						}
						toast.push(
							`Organization ${
								type === 'avatar' ? 'Logo' : 'Banner'
							} updated successfully. It may take a few minutes for your logo to appear.`,
							{
								variant: 'success',
							}
						);
						closeModal();
					})
					.catch((err) => {
						console.log(err);
						handleNoResponse({ toast });
					});
			}}>
			{({ submitForm, isValid, dirty, isSubmitting }) => (
				<>
					<ModalContent>
						<Alert error={error} />
						<Typography variant="h3" style={{ marginTop: 0 }}>
							Drag & drop an image below or click to upload
						</Typography>
						<FileField
							name="organizationAvatarOrBanner"
							type={FileType.Image}
							updatesFileId={fileInstance?.fileId}
							setIsUploading={setIsUploading}
						/>
					</ModalContent>
					<ModalActions>
						<Grid container justifyContent="flex-end" spacing={1}>
							<Grid item>
								<ModalActionButton variant="outlined">Cancel</ModalActionButton>
							</Grid>
							<Grid item>
								<LoadingButton
									variant="contained"
									color="primary"
									onClick={submitForm}
									disabled={!isValid || !dirty || isSubmitting || isUploading}
									loading={isSubmitting || isUploading}>
									Save
								</LoadingButton>
							</Grid>
						</Grid>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};
