import {
	ArrowBackOutlined,
	ChevronRight,
	Close,
	FolderOutlined,
	MoreHoriz,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Breadcrumbs,
	Button,
	Divider,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemButton,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import { FileInstanceInformation, FolderReference } from 'middleware-types';
import { useMemo, useState } from 'react';
import { navy } from 'utils/theme';
import { useMoveFile } from '../hooks/files/use-change-folder';
import { useFolderReferenceList } from '../hooks/folders/use-folder-reference-list';

interface MoveModalProps {
	file: FileInstanceInformation;
	onClose: () => void;
}

interface Breadcrumb {
	name: string;
	id?: string;
	last: boolean;
}

export const MoveFileModal = ({ file, onClose }: MoveModalProps) => {
	// Names and IDs for entire file structure
	const { folderReferences, rootName } = useFolderReferenceList();

	// Id of folder the file is in at time of initiating move
	const currentFolderId = file.folder?.id;

	// Id of folder currently being viewed, which also serves as destination folder on submit
	const [currentModalFolderId, setCurrentModalFolderId] = useState<string | undefined>(
		currentFolderId
	);

	const getBreadCrumbsList = (
		folderId: string | undefined,
		rootName: string,
		folderRefs?: FolderReference[]
	) => {
		const crumbs: Breadcrumb[] = [];

		let idToFind: string | undefined = folderId;
		while (idToFind) {
			const crumb = folderRefs?.find((ref) => ref.id === idToFind);
			if (!crumb) break;
			crumbs.unshift({ name: crumb?.name, id: crumb?.id, last: crumbs.length === 0 });
			if (!crumb?.parentFolder?.id) break;
			idToFind = crumb.parentFolder.id;
		}

		// Add the root folder's name at the end
		crumbs.unshift({ name: rootName, id: undefined, last: crumbs.length === 0 });

		return crumbs;
	};

	const sourceBreadCrumbsList = useMemo(() => {
		return getBreadCrumbsList(currentFolderId, rootName, folderReferences);
	}, [currentFolderId, rootName, folderReferences]);

	const destBreadCrumbsList = useMemo(() => {
		return getBreadCrumbsList(currentModalFolderId, rootName, folderReferences);
	}, [currentModalFolderId, rootName, folderReferences]);

	const { moveFile, loading: moveLoading } = useMoveFile();

	const onSubmit = async () => {
		const success = await moveFile(file.fileId, currentFolderId, currentModalFolderId);
		if (success) onClose();
	};

	const isValidDest = currentFolderId !== currentModalFolderId;

	const refToCurrentFolder = folderReferences?.find((ref) => ref.id === currentModalFolderId);

	const refsToChildFolders = folderReferences?.filter((ref) => {
		return ref.parentFolder?.id === currentModalFolderId;
	});

	return (
		<Stack overflow="hidden" width="100%">
			<Stack
				px={2}
				py={1}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				spacing={1}>
				<Stack
					spacing={1}
					display="flex"
					flexDirection="column"
					width="100%"
					overflow="auto">
					<Typography variant="h3" noWrap>
						Move {file.fileName}
					</Typography>
				</Stack>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</Stack>
			<Divider />
			<Stack p={2}>
				<Typography variant="h5">
					Current folder:
					<BreadcrumbList
						breadcrumbs={sourceBreadCrumbsList}
						onClick={setCurrentModalFolderId}
						currentFolderId={currentFolderId}
					/>
				</Typography>
			</Stack>
			<Divider />
			<Stack direction="row" alignItems="center" spacing={1} p={1}>
				{refToCurrentFolder ? (
					<IconButton
						onClick={() => {
							setCurrentModalFolderId(refToCurrentFolder.parentFolder?.id);
						}}>
						<ArrowBackOutlined />
					</IconButton>
				) : (
					<Box height="40px" />
				)}
				<Typography variant="h3" noWrap color={isValidDest ? 'primary' : ''}>
					{refToCurrentFolder?.name ?? rootName}
				</Typography>
			</Stack>
			<List sx={{ height: { xs: '100vh', md: 350, overflow: 'auto' } }}>
				{refsToChildFolders?.map((ref) => {
					return (
						<ListItem disablePadding key={ref.id}>
							<ListItemButton onClick={() => setCurrentModalFolderId(ref.id)}>
								<Stack
									direction="row"
									spacing={1}
									alignItems="center"
									sx={{
										overflow: 'hidden',
									}}>
									<FolderOutlined sx={{ color: 'neutral.500' }} />
									<Typography noWrap>{ref.name}</Typography>
								</Stack>
							</ListItemButton>
						</ListItem>
					);
				})}
				{refsToChildFolders?.length === 0 && (
					<ListItem disablePadding>
						<Stack
							direction="row"
							paddingLeft={2}
							paddingTop={1}
							spacing={1}
							alignItems="center">
							<Typography variant="body2">No subfolders</Typography>
						</Stack>
					</ListItem>
				)}
			</List>
			<Divider />
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				flexWrap="wrap"
				width="100%">
				<Stack p={2} width="100%">
					<Typography variant="h5">Move to:</Typography>
					<BreadcrumbList
						breadcrumbs={destBreadCrumbsList}
						isValidDest={isValidDest}
						onClick={setCurrentModalFolderId}
						currentFolderId={currentFolderId}
					/>
				</Stack>
				<Stack
					width="100%"
					direction="row"
					justifyContent="flex-end"
					px={2}
					py={1.5}
					spacing={1.5}>
					<Button size="large" variant="outlined" onClick={onClose}>
						Cancel
					</Button>
					<LoadingButton
						size="large"
						variant="contained"
						color="primary"
						loading={moveLoading}
						disabled={!isValidDest}
						onClick={onSubmit}>
						Move
					</LoadingButton>
				</Stack>
			</Stack>
		</Stack>
	);
};

const BreadcrumbList = ({
	breadcrumbs,
	isValidDest = false,
	onClick,
	currentFolderId,
}: {
	breadcrumbs: Breadcrumb[];
	isValidDest?: boolean;
	onClick: React.Dispatch<React.SetStateAction<string | undefined>>;
	currentFolderId?: string;
}) => {
	const [nestedAnchorEl, setNestedAnchorEl] = useState<HTMLElement | null>(null);

	return (
		<>
			<Breadcrumbs
				separator={<ChevronRight />}
				sx={{
					'flexShrink': 1,
					'overflow': 'hidden',
					'& .MuiBreadcrumbs-li': {
						flex: 1,
						overflow: 'hidden',
						maxWidth: 'fit-content',
					},
					'& .MuiLink-button': {
						width: '100%',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
					},
				}}>
				<Link
					component="button"
					onClick={() => onClick(breadcrumbs[0].id)}
					color={breadcrumbs[0].last && isValidDest ? 'primary' : 'netural.900'}
					sx={{
						':hover': {
							// It doesn't like using 'primary' here...
							color: currentFolderId !== breadcrumbs[0].id ? navy[500] : '',
						},
					}}>
					{breadcrumbs[0]?.name}
				</Link>
				{breadcrumbs.length > 2 &&
					(breadcrumbs.length === 3 ? (
						<Link
							component="button"
							onClick={() => onClick(breadcrumbs[1].id)}
							color={breadcrumbs[1].last && isValidDest ? 'primary' : 'netural.900'}
							sx={{
								':hover': {
									// It doesn't like using 'primary' here...
									color: currentFolderId !== breadcrumbs[1].id ? navy[500] : '',
									textDecoration:
										currentFolderId === breadcrumbs[1].id ? 'none' : '',
								},
							}}>
							{breadcrumbs[1]?.name}
						</Link>
					) : (
						<IconButton
							onClick={(e) => setNestedAnchorEl(e.currentTarget)}
							sx={{ borderRadius: 1, paddingY: 0, paddingX: 0.25 }}>
							<MoreHoriz />
						</IconButton>
					))}
				{breadcrumbs.length > 1 && (
					<Link
						component="button"
						color={
							breadcrumbs[breadcrumbs.length - 1].last && isValidDest
								? 'primary'
								: 'netural.900'
						}
						sx={{
							':hover': {
								// It doesn't like using 'primary' here...
								color:
									currentFolderId !== breadcrumbs[breadcrumbs.length - 1].id
										? navy[500]
										: '',
							},
						}}
						onClick={() => onClick(breadcrumbs[breadcrumbs.length - 1].id)}>
						{breadcrumbs[breadcrumbs.length - 1].name}
					</Link>
				)}
			</Breadcrumbs>
			<Menu
				open={Boolean(nestedAnchorEl)}
				anchorEl={nestedAnchorEl}
				onClose={() => setNestedAnchorEl(null)}
				onClick={() => setNestedAnchorEl(null)}>
				{breadcrumbs.slice(1, breadcrumbs.length - 1).map((breadcrumb) => (
					<MenuItem key={breadcrumb.id} onClick={() => onClick(breadcrumb.id)}>
						<Typography variant="inherit" noWrap>
							{breadcrumb.name}
						</Typography>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
