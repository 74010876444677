import {
	Box,
	List,
	ListItem,
	ListItemButton,
	Paper,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { UserChatMessage } from 'middleware-types';
import { formatDateDistance } from 'utils/utils';

interface EvoxMessageListProps {
	loading: boolean;
	messages: UserChatMessage[];
	selectedMessageId: string | undefined;
	onMessageSelected: (message: UserChatMessage) => void;
}

export const EvoxMessageList = ({
	loading,
	messages,
	selectedMessageId,
	onMessageSelected,
}: EvoxMessageListProps) => {
	if (loading) {
		return (
			<Stack spacing={2}>
				{[...Array(5)].map((_, index) => (
					<Skeleton key={index} variant="rounded" height={70} />
				))}
			</Stack>
		);
	}

	if (messages.length < 1) {
		return <Typography color="GrayText">Nothing to show.</Typography>;
	}

	return (
		<List component={Stack} spacing={2} overflow="auto" sx={{ overflowX: 'hidden' }}>
			{messages.map((message) => (
				<ListItem key={message.chatMessageId} component={Paper} disablePadding>
					<ListItemButton
						selected={selectedMessageId === message.chatMessageId}
						onClick={() => onMessageSelected(message)}>
						<Stack spacing={0.5}>
							<Typography variant="h5">
								{formatDateDistance(new Date(message.dateTimeUtc))}
							</Typography>
							<Box
								overflow="hidden"
								sx={{
									WebkitLineClamp: 3,
									display: '-webkit-box',
									WebkitBoxOrient: 'vertical',
								}}>
								<Typography color="GrayText">{message.content}</Typography>
							</Box>
						</Stack>
					</ListItemButton>
				</ListItem>
			))}
		</List>
	);
};
