import {
	AddOutlined,
	DeleteOutlined,
	EditOutlined,
	ErrorOutlined,
	StarOutlined,
} from '@mui/icons-material';
import {
	Box,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	Divider,
	Grid,
	IconButton,
	Skeleton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { Alert } from 'components/ui/alert';
import { PhoneNumberText } from 'components/ui/fields';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalContent,
	ModalLoadingButton,
	useModal,
} from 'components/ui/modal';
import { useToast } from 'components/ui/toast';
import { Representative, RepresentativeType } from 'middleware-types';
import { handleNoResponse } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';
import { useOrgRepresentativesDeleteMutation, useOrgRepresentativesQuery } from './hooks';
import { OrgRepresentativeForm } from './modal';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';

/**
 * Displays the organization representatives
 *
 * @param {{ orgId: string }} { orgId }
 * @return {*}
 */
export const OrgRepresentatives = ({ orgId }: { orgId: string }) => {
	const { representatives, loading } = useOrgRepresentativesQuery(orgId);
	const { orgRepresentativeDelete, error } = useOrgRepresentativesDeleteMutation();
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const { showModal, closeModal } = useModal();
	const toast = useToast();
	const canEdit =
		hasAssociateUserPermission(Permission.Org_Account_U) ||
		hasSiteUserPermission(Permission.Site_OrgAcct_U);

	const addOrUpdateRep = (rep?: Representative) => {
		showModal({
			title: rep ? 'Edit Representative' : 'Add New Representative',
			content: <OrgRepresentativeForm rep={rep} orgId={orgId} />,
			maxWidth: 'lg',
		});
	};

	const confirmDeleteRep = (rep) => {
		return orgRepresentativeDelete(orgId, rep.id)
			.then(() => {
				toast.push('Representative deleted successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	const deleteRep = (rep: Representative) => {
		showModal({
			title: 'Are you sure?',
			content: (
				<ModalContent>
					<Alert error={error} />
					<ConfirmModalContent
						visual={
							<EmptyStateAvatar
								avatarProps={{ bgcolor: 'error.50' }}
								iconProps={{ color: 'error.500' }}
								icon={<ErrorOutlined />}
							/>
						}
						subheadline={`Delete ${rep.name.firstName} ${rep.name.lastName}?`}
						informativeContent="Do you really want to delete this representative? This process cannot be undone."
					/>
				</ModalContent>
			),
			actions: (
				<ModalActions>
					<ModalActionButton size="large" variant="outlined" onClick={closeModal}>
						Cancel
					</ModalActionButton>
					<ModalLoadingButton
						size="large"
						variant="contained"
						color="error"
						onClick={() => confirmDeleteRep(rep)}>
						Delete
					</ModalLoadingButton>
				</ModalActions>
			),
		});
	};

	if (loading) return <OrgRepresentativesSkeleton />;
	return (
		<Card>
			<CardHeader
				title="Representative Information"
				action={
					canEdit && (
						<IconButton edge="end" onClick={() => addOrUpdateRep(undefined)}>
							<AddOutlined />
						</IconButton>
					)
				}
			/>
			<Divider />
			<CardContent>
				<Box
					pt={1}
					display="grid"
					gap={2}
					gridTemplateColumns={{
						xs: 'repeat(auto-fill)',
						sm: 'repeat(auto-fill, 15rem)',
					}}>
					{representatives
						.sort((a) => {
							if (a.title === 'Organization Creator') return -1;
							if (a.representativeType.includes(RepresentativeType.Primary)) {
								return -1;
							}

							return 1;
						})
						.map((rep) => (
							<Card
								key={rep.id}
								variant="outlined"
								sx={{ display: 'flex', flexDirection: 'column' }}>
								<CardHeader
									sx={{ paddingBottom: 0.5 }}
									title={
										<Stack direction="row" justifyContent="space-between">
											<span>
												{rep.name.firstName} {rep.name.middleName}{' '}
												{rep.name.lastName} {rep.name.suffix}
											</span>
											{rep.representativeType.includes(
												RepresentativeType.Primary
											) && (
												<Tooltip title="Primary Representative">
													<StarOutlined
														fontSize="small"
														color="secondary"
													/>
												</Tooltip>
											)}
										</Stack>
									}
									titleTypographyProps={{
										variant: 'h5',
									}}
									subheader={rep.title}
									subheaderTypographyProps={{
										variant: 'h6',
									}}
								/>
								<CardContent sx={{ flexGrow: 1 }}>
									<Stack
										sx={{ flexWrap: 'wrap' }}
										rowGap={1}
										direction="row"
										spacing={0.5}
										margin="0.25rem 0 0.5rem 0">
										{rep.representativeType.includes(
											RepresentativeType.Primary
										) && <Chip label="Primary" size="small" color="primary" />}
										{rep.representativeType.includes(
											RepresentativeType.Technical
										) && (
											<Chip label="Technical" size="small" color="success" />
										)}
										{rep.representativeType.includes(
											RepresentativeType.Operational
										) && (
											<Chip
												label="Operational"
												size="small"
												color="success"
											/>
										)}
										{rep.representativeType.includes(
											RepresentativeType.Billing
										) && <Chip label="Billing" size="small" color="success" />}
									</Stack>
									<Typography sx={{ wordBreak: 'break-word' }}>
										{rep.emailAddress}
									</Typography>
									<Typography>
										Cell: <PhoneNumberText number={rep.cellPhoneNumber} />
									</Typography>
									{rep.workPhoneNumber && (
										<Typography>
											Work: <PhoneNumberText number={rep.workPhoneNumber} />
										</Typography>
									)}
								</CardContent>
								{canEdit && (
									<CardActions disableSpacing>
										<IconButton onClick={() => addOrUpdateRep(rep)}>
											<EditOutlined />
										</IconButton>
										{(!rep.representativeType.includes(
											RepresentativeType.Primary
										) ||
											representatives.filter((rep) =>
												rep.representativeType.includes(
													RepresentativeType.Primary
												)
											).length > 1) && (
											<IconButton onClick={() => deleteRep(rep)}>
												<DeleteOutlined />
											</IconButton>
										)}
									</CardActions>
								)}
							</Card>
						))}
				</Box>
			</CardContent>
		</Card>
	);
};

const OrgRepresentativesSkeleton = () => (
	<Card>
		<CardHeader title="Representative Information" />
		<CardContent>
			<Grid container spacing={1}>
				<Grid item xs={6}>
					<Card variant="outlined">
						<CardContent>
							<Skeleton width="50%" />
							<Skeleton width="45%" />
							<br />
							<Skeleton width="35%" />
							<Skeleton width="40%" />
							<Skeleton width="35%" />
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={6}>
					<Card variant="outlined">
						<CardContent>
							<Skeleton width="50%" />
							<Skeleton width="45%" />
							<br />
							<Skeleton width="35%" />
							<Skeleton width="40%" />
							<Skeleton width="35%" />
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</CardContent>
	</Card>
);
