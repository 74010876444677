import { DocumentsPage } from 'components/pages/documents/documents-page';
import { useOrgId } from 'components/pages/org/outlet';
import { useEmblem } from 'components/ui/emblem/hooks';
import { Loading } from 'components/ui/loading';
import { EmblemEntityType, FileAccessLevel, FoldersApiEntityType } from 'middleware-types';
import { Permission } from 'utils/permissions';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';

export const OrgDocumentsPage = () => {
	const orgId = useOrgId();
	const { emblem, loading: emblemLoading } = useEmblem(orgId, EmblemEntityType.Organization);

	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();

	if (emblemLoading) return <Loading />;

	const permissions = { update: [] as FileAccessLevel[], delete: [] as FileAccessLevel[] };
	if (
		hasAssociateUserPermission(Permission.Org_File_Public_U) ||
		hasSiteUserPermission(Permission.Site_OrgFile_U)
	)
		permissions.update.push(FileAccessLevel.Public);
	if (
		hasAssociateUserPermission(Permission.Org_File_Conf_U) ||
		hasSiteUserPermission(Permission.Site_OrgFile_U)
	)
		permissions.update.push(FileAccessLevel.Confidential);
	if (
		hasAssociateUserPermission(Permission.Org_File_Sens_U) ||
		hasSiteUserPermission(Permission.Site_OrgFile_Sens_U)
	)
		permissions.update.push(FileAccessLevel.Sensitive);
	if (
		hasAssociateUserPermission(Permission.Org_File_Public_D) ||
		hasSiteUserPermission(Permission.Site_OrgFile_D)
	)
		permissions.delete.push(FileAccessLevel.Public);
	if (
		hasAssociateUserPermission(Permission.Org_File_Conf_D) ||
		hasSiteUserPermission(Permission.Site_OrgFile_D)
	)
		permissions.delete.push(FileAccessLevel.Confidential);
	if (
		hasAssociateUserPermission(Permission.Org_File_Sens_D) ||
		hasSiteUserPermission(Permission.Site_OrgFile_Sens_D)
	)
		permissions.delete.push(FileAccessLevel.Sensitive);

	return (
		<DocumentsPage
			entityType={FoldersApiEntityType.Organization}
			entityId={orgId}
			entityName={emblem!.displayName}
			permissions={permissions}
		/>
	);
};
