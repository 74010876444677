import { Add, ArrowBack, ForumOutlined } from '@mui/icons-material';
import { Avatar, IconButton, List, Skeleton, Stack, Typography } from '@mui/material';
import { PromptClippingResponse } from 'middleware-types';
import { useState } from 'react';
import { theme } from 'utils/theme';
import { usePromptClippings } from './hooks';
import { PromptClippingListItem } from './promptClippingListItem';
import { UpsertPromptClipping } from './upsertPromptClipping';

interface PromptClippingsProps {
	onClose: () => void;
	setRequestText: (value: React.SetStateAction<string>) => void;
}

export const PromptClippings = ({ onClose, setRequestText }: PromptClippingsProps) => {
	const { promptClippings, loading } = usePromptClippings();
	const [newClippingOpen, setNewClippingOpen] = useState(false);
	const [clippingToUpdate, setClippingToUpdate] = useState<PromptClippingResponse | null>(null);

	if (clippingToUpdate)
		return (
			<UpsertPromptClipping
				clipping={clippingToUpdate}
				onClose={() => setClippingToUpdate(null)}
			/>
		);

	if (newClippingOpen) return <UpsertPromptClipping onClose={() => setNewClippingOpen(false)} />;

	return (
		<Stack height="100%" width="100%" position="relative" id="evox-container">
			<Stack
				px={1}
				py={0.5}
				direction="row"
				alignItems="center"
				spacing={1}
				borderBottom={`1px solid ${theme.palette.divider}`}>
				<IconButton onClick={onClose}>
					<ArrowBack />
				</IconButton>
				<Typography variant="h4" pl={1} flex={1}>
					Prompt Clippings
				</Typography>
				<IconButton onClick={() => setNewClippingOpen(true)}>
					<Add />
				</IconButton>
			</Stack>
			{loading ? (
				<SavedClippingsSkeleton />
			) : promptClippings.length === 0 ? (
				<NoClippingsMessage />
			) : (
				<List
					sx={{
						overflow: 'auto',
					}}>
					{promptClippings.map((clipping) => (
						<PromptClippingListItem
							key={clipping.id}
							onClose={onClose}
							clipping={clipping}
							setClippingToUpdate={setClippingToUpdate}
							setRequestText={setRequestText}
						/>
					))}
				</List>
			)}
		</Stack>
	);
};

const SavedClippingsSkeleton = () => {
	return (
		<Stack p={2} spacing={1}>
			{[...Array(3)].map((_, index) => (
				<Stack key={index} direction="row" spacing={2} alignItems="center">
					<Skeleton variant="circular" height={48} width={48} />
					<Skeleton variant="text" width={200} />
				</Stack>
			))}
		</Stack>
	);
};

const NoClippingsMessage = () => {
	return (
		<Stack
			flex={1}
			justifyContent="center"
			alignItems="center"
			px={8}
			py={2}
			spacing={2}
			overflow="auto">
			<Avatar sx={{ height: 64, width: 64 }}>
				<ForumOutlined fontSize="large" />
			</Avatar>
			<Typography variant="h4" textAlign="center">
				No prompt clippings.
			</Typography>
			<Typography variant="body2" textAlign="center">
				Prompt clippings are snippets of text you&apos;d like to re-use in future EVO-X
				conversations. Create a clipping here to be retrieved and re-used later.
			</Typography>
		</Stack>
	);
};
