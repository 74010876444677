import { Close, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';

interface DocumentSearchFieldProps {
	searchText: string;
	setSearchText: (value: string) => void;
}

export const DocumentSearchField = ({ searchText, setSearchText }: DocumentSearchFieldProps) => {
	return (
		<TextField
			value={searchText}
			onChange={(e) => setSearchText(e.target.value)}
			placeholder="Search"
			fullWidth={false}
			sx={{ width: { sm: 300 } }}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<Search />
					</InputAdornment>
				),
				endAdornment: searchText ? (
					<InputAdornment position="end">
						<IconButton onClick={() => setSearchText('')}>
							<Close />
						</IconButton>
					</InputAdornment>
				) : undefined,
			}}
		/>
	);
};
