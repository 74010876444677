import { ErrorOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useForgetCognitoDevicesMutation } from 'components/pages/user/account/security/hooks';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	useModal,
} from 'components/ui/modal';

interface ForgetDevicesModalProps {
	userId: string;
}

export const ForgetDevicesModal = ({ userId }: ForgetDevicesModalProps) => {
	const { closeModal } = useModal();
	const { forgetCognitoDevices, loading } = useForgetCognitoDevicesMutation(userId);
	return (
		<>
			<ConfirmModalContent
				visual={
					<EmptyStateAvatar
						avatarProps={{ bgcolor: 'error.50' }}
						iconProps={{ color: 'error.500' }}
						icon={<ErrorOutlined />}
					/>
				}
				subheadline="Are you sure?"
				informativeContent="Do you really want to forget all devices? This process cannot be undone and will remove all stored device information."
			/>
			<ModalActions>
				<ModalActionButton size="large" variant="outlined">
					Cancel
				</ModalActionButton>
				<LoadingButton
					size="large"
					variant="contained"
					color="error"
					loading={loading}
					onClick={() => {
						forgetCognitoDevices().then((success) => {
							if (success) closeModal();
						});
					}}>
					Forget Devices
				</LoadingButton>
			</ModalActions>
		</>
	);
};
