import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	useModal,
} from 'components/ui/modal';
import { LoadingButton } from '@mui/lab';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { ErrorOutlined } from '@mui/icons-material';
import { BusinessLine } from 'middleware-types';
import { useDeleteLineOfBusiness } from '../hooks';

interface DeleteLineOfBusinesssModalProps {
	orgId: string;
	lineOfBusiness: BusinessLine;
}

export const DeleteLineOfBusinesssModal = ({
	orgId,
	lineOfBusiness,
}: DeleteLineOfBusinesssModalProps) => {
	const { closeModal } = useModal();
	const { deleteLineOfBusiness, loading } = useDeleteLineOfBusiness(orgId);

	const onSubmit = () => {
		deleteLineOfBusiness(lineOfBusiness.id).then((success) => {
			if (!success) return;
			closeModal();
		});
	};

	return (
		<>
			<ConfirmModalContent
				visual={
					<EmptyStateAvatar
						avatarProps={{ bgcolor: 'error.50' }}
						iconProps={{ color: 'error.500' }}
						icon={<ErrorOutlined />}
					/>
				}
				subheadline="Delete Line of Business"
				informativeContent={`Do you really want to delete ${lineOfBusiness.line}? This process cannot be undone.`}
			/>
			<ModalActions>
				<ModalActionButton size="large" variant="outlined">
					Cancel
				</ModalActionButton>
				<LoadingButton
					size="large"
					variant="contained"
					color="error"
					loading={loading}
					onClick={onSubmit}>
					Delete
				</LoadingButton>
			</ModalActions>
		</>
	);
};
