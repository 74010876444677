import { ApolloError, useQuery } from '@apollo/client';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { Box, CircularProgress, Link, Stack, Typography } from '@mui/material';
import {
	AccessLevelChip,
	AccessLevelIcon,
} from 'components/pages/documents/components/access-level';
import { Query, QueryGetUploadUrlArgs } from 'middleware-types';
import { useState } from 'react';
import { ExtensionIcon, GET_UPLOAD_URL, uploadFile } from 'utils/fileUtils';
import { useIsMobile } from 'utils/useScreenSize';
import { UploadsDrawerItem, UploadsDrawerItemState } from './uploads-drawer';

interface UploadsDrawerRowProps {
	item: UploadsDrawerItem;
	setState: (value: UploadsDrawerItemState) => void;
}

export const UploadsDrawerRow = ({ item, setState }: UploadsDrawerRowProps) => {
	const isMobile = useIsMobile();
	const [progress, setProgress] = useState(0);
	const [errorMessage, setErrorMessage] = useState('An error occurred.');

	const setApolloErrorMessage = (e: ApolloError) => {
		if (e.graphQLErrors.length > 0) {
			setErrorMessage(e.graphQLErrors[0].extensions.userMessage as string);
		} else {
			setErrorMessage(e.message);
		}
	};

	useQuery<Pick<Query, 'getUploadUrl'>, QueryGetUploadUrlArgs>(GET_UPLOAD_URL, {
		fetchPolicy: 'network-only',
		variables: {
			fileName: item.file.name,
			fileSize: item.file.size,
			requestedAccessLevel: item.accessLevel,
			updatesFileId: item.updatesFileId,
		},
		onCompleted: async (result) => {
			const { blobUploadUrl, fileUploadToken } = result.getUploadUrl;
			try {
				await uploadFile(blobUploadUrl, item.file, (e) => setProgress(e.progress ?? 0));
				await item.onCompleted(fileUploadToken);
				setState(UploadsDrawerItemState.Success);
			} catch (e) {
				if (e instanceof ApolloError) {
					setApolloErrorMessage(e);
				}
				setState(UploadsDrawerItemState.Error);
			}
		},
		onError: (e) => {
			setApolloErrorMessage(e);
			setState(UploadsDrawerItemState.Error);
		},
	});

	return (
		<Stack direction="row" alignItems="center" px={2} py={1} spacing={2}>
			<Stack flex={1} direction="row" alignItems="center" spacing={1.5} overflow="hidden">
				<ExtensionIcon filename={item.file.name} sx={{ color: 'neutral.500' }} />
				<Stack flex={1} overflow="hidden">
					<Typography variant="body1" noWrap textOverflow="ellipsis">
						{item.file.name}
					</Typography>
					{item.state === UploadsDrawerItemState.Error ? (
						<Typography variant="caption" color="error">
							{errorMessage}
						</Typography>
					) : (
						<Link
							href={item.url}
							variant="caption"
							color="GrayText"
							maxWidth="100%"
							noWrap>
							{item.subtitle}
						</Link>
					)}
				</Stack>
			</Stack>
			{item.state !== UploadsDrawerItemState.Error &&
				(isMobile ? (
					<AccessLevelIcon level={item.accessLevel} sx={{ color: 'neutral.500' }} />
				) : (
					<Box width="30%">
						<AccessLevelChip level={item.accessLevel} />
					</Box>
				))}
			{item.state === UploadsDrawerItemState.Loading ? (
				<CircularProgress size={24} variant="determinate" value={progress * 90} />
			) : item.state === UploadsDrawerItemState.Success ? (
				<CheckCircle color="success" />
			) : (
				<Cancel color="error" />
			)}
		</Stack>
	);
};
