import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	useModal,
} from 'components/ui/modal';
import { LabelWithColor } from '../helpers/label-colors';
import { useDeleteLabel } from '../hooks/labelsHooks';
import { LoadingButton } from '@mui/lab';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { ErrorOutlined } from '@mui/icons-material';

export const DeleteLabelModal = ({ label }: { label: LabelWithColor }) => {
	const { closeModal } = useModal();
	const { deleteLabel, loading } = useDeleteLabel();

	const onSubmit = () => {
		deleteLabel(label.id).then((success) => {
			if (!success) return;
			closeModal();
		});
	};

	return (
		<>
			<ConfirmModalContent
				visual={
					<EmptyStateAvatar
						avatarProps={{ bgcolor: 'error.50' }}
						iconProps={{ color: 'error.500' }}
						icon={<ErrorOutlined />}
					/>
				}
				subheadline="Delete Label"
				informativeContent={`Do you really want to delete ${label.name}? This process cannot be undone.`}
			/>
			<ModalActions>
				<ModalActionButton size="large" variant="outlined">
					Cancel
				</ModalActionButton>
				<LoadingButton
					size="large"
					variant="contained"
					color="error"
					loading={loading}
					onClick={onSubmit}>
					Delete
				</LoadingButton>
			</ModalActions>
		</>
	);
};
