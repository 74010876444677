import { Label } from 'middleware-types';
import {
	amber,
	blue,
	blueGrey,
	brown,
	cyan,
	deepOrange,
	deepPurple,
	green,
	grey,
	indigo,
	lightBlue,
	lightGreen,
	lime,
	orange,
	pink,
	purple,
	red,
	teal,
	yellow,
} from '@mui/material/colors';

interface LabelColor {
	50: string;
	500: string;
	700: string;
}

interface LabelColorOption {
	name: string;
	color: LabelColor;
}

export interface LabelWithColor extends Label {
	color: LabelColor;
}

export const labelColorOptions: LabelColorOption[] = [
	{
		name: 'Red',
		color: red,
	},
	{
		name: 'Pink',
		color: pink,
	},
	{
		name: 'Purple',
		color: purple,
	},
	{
		name: 'Deep Purple',
		color: deepPurple,
	},
	{
		name: 'Indigo',
		color: indigo,
	},
	{
		name: 'Blue',
		color: blue,
	},
	{
		name: 'Light Blue',
		color: lightBlue,
	},
	{
		name: 'Cyan',
		color: cyan,
	},
	{
		name: 'Teal',
		color: teal,
	},
	{
		name: 'Green',
		color: green,
	},
	{
		name: 'Light Green',
		color: lightGreen,
	},
	{
		name: 'Lime',
		color: lime,
	},
	{
		name: 'Yellow',
		color: yellow,
	},
	{
		name: 'Amber',
		color: amber,
	},
	{
		name: 'Orange',
		color: orange,
	},
	{
		name: 'Deep Orange',
		color: deepOrange,
	},
	{
		name: 'Brown',
		color: brown,
	},
	{
		name: 'Grey',
		color: grey,
	},
	{
		name: 'Blue Grey',
		color: blueGrey,
	},
];

export const mapLabelToColor = (label: Label): LabelWithColor => {
	const color =
		labelColorOptions.find((option) => option.name === label.colorName)?.color ?? grey;
	return {
		color,
		...label,
	};
};
