import { gql, useMutation } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	LabelType,
	Mutation,
	MutationConversationEmptyTrashArgs,
	MutationConversationLabelApplyArgs,
	MutationConversationLabelRemoveArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useCommunicationsContext } from '../communications-provider';
import { CONVERSATION_FIELDS } from '../fragments.graphql';
import { CONVERSATION_SEARCH } from './conversation-search-hooks';

/**
 * hook to apply a label to a conversation
 */
const APPLY_LABEL = gql`
	${CONVERSATION_FIELDS}
	mutation ApplyLabel(
		$entityType: ConversationApiEntityType!
		$entityId: String!
		$conversationId: String!
		$labelId: String!
	) {
		conversationLabelApply(
			entityType: $entityType
			entityId: $entityId
			conversationId: $conversationId
			labelId: $labelId
		) {
			conversation {
				...ConversationFields
			}
		}
	}
`;

export const useApplyLabel = () => {
	const toast = useToast();
	const {
		entityType,
		entityId,
		selectedConversationId: conversationId,
	} = useCommunicationsContext();

	const [_applyLabel, { loading }] = useMutation<
		Pick<Mutation, 'conversationLabelApply'>,
		MutationConversationLabelApplyArgs
	>(APPLY_LABEL, { refetchQueries: [CONVERSATION_SEARCH] });

	const applyLabel = async (labelId: string) => {
		if (conversationId === null) return false;
		return await _applyLabel({
			variables: { entityType, entityId, conversationId, labelId },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { applyLabel, loading };
};

/**
 * hook to remove a label from a conversation
 */
const REMOVE_LABEL = gql`
	${CONVERSATION_FIELDS}
	mutation RemoveLabel(
		$entityType: ConversationApiEntityType!
		$entityId: String!
		$conversationId: String!
		$labelId: String!
	) {
		conversationLabelRemove(
			entityType: $entityType
			entityId: $entityId
			conversationId: $conversationId
			labelId: $labelId
		) {
			conversation {
				...ConversationFields
			}
		}
	}
`;

export const useRemoveLabel = () => {
	const toast = useToast();
	const {
		entityType,
		entityId,
		selectedConversationId: conversationId,
	} = useCommunicationsContext();

	const [_removeLabel, { loading }] = useMutation<
		Pick<Mutation, 'conversationLabelRemove'>,
		MutationConversationLabelRemoveArgs
	>(REMOVE_LABEL, { refetchQueries: [CONVERSATION_SEARCH] });

	const removeLabel = async (labelId: string) => {
		if (conversationId === null) return false;
		return await _removeLabel({
			variables: { entityType, entityId, conversationId, labelId },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { removeLabel, loading };
};

/**
 * hook to restore a conversation from trash.
 * this is essentially just calling 'remove label' with the trash label
 */
export const useRestoreConversation = () => {
	const toast = useToast();
	const { removeLabel } = useRemoveLabel();
	const { labels } = useCommunicationsContext();

	const restoreConversation = () => {
		const trashLabel = labels.find((label) => label.type === LabelType.Trash);
		if (!trashLabel) return;

		removeLabel(trashLabel.id).then((success) => {
			if (success) toast.push('Conversation restored successfully.', { variant: 'success' });
		});
	};

	return restoreConversation;
};

/**
 * hook to empty the trash
 */
const EMPTY_TRASH = gql`
	mutation EmptyTrash($entityType: ConversationApiEntityType!, $entityId: String!) {
		conversationEmptyTrash(entityType: $entityType, entityId: $entityId)
	}
`;

export const useEmptyTrash = () => {
	const toast = useToast();
	const { entityType, entityId } = useCommunicationsContext();

	const [_emptyTrash, { loading }] = useMutation<
		Pick<Mutation, 'conversationEmptyTrash'>,
		MutationConversationEmptyTrashArgs
	>(EMPTY_TRASH, { refetchQueries: [CONVERSATION_SEARCH], awaitRefetchQueries: true });

	const emptyTrash = async () => {
		return await _emptyTrash({ variables: { entityType, entityId } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Trash emptied successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { emptyTrash, loading };
};
