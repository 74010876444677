import {
	AlternateEmail,
	BadgeOutlined,
	CakeOutlined,
	EmailOutlined,
	ErrorOutlined,
	PhoneOutlined,
	PowerSettingsNewOutlined,
	TranslateOutlined,
} from '@mui/icons-material';
import { Chip, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { UserAvatarUploadButton } from 'components/ui/emblem/avatar-upload-button';
import { PhoneNumberText } from 'components/ui/fields/phone';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalLoadingButton,
	useModal,
} from 'components/ui/modal';
import { format, isValid } from 'date-fns';
import { useSession } from 'utils/session';
import { useLanguages } from 'utils/useLanguages';
import { zoneDateOnly } from 'utils/utils';
import {
	ActivationOperation,
	PersonalInformation,
	useCanEditPersonalInformation,
	useUpdateAccountActivationMutation,
} from './personal-info-hooks';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';

export type PersonalInfoReadProps = { account: PersonalInformation };

/**
 * PersonalInfoRead - A read only view of the personal information
 * It hides content that does not have any values.
 *
 * @param {{ account: PersonalInformation }} props
 * @return {*}
 */
const PersonalInfoRead = (props: PersonalInfoReadProps) => {
	const { languages, loading } = useLanguages();
	const { user } = useSession();
	const { showModal } = useModal();
	const isSelf = user.userId === props.account.id;
	const { canEdit } = useCanEditPersonalInformation(props.account);
	const { updateAccountActivation } = useUpdateAccountActivationMutation(props.account.id);

	const onUpdateAccountActivation = () => {
		const deactivated = props.account?.deactivated;
		const verb = deactivated ? 'Activate' : 'Deactivate';

		showModal({
			title: `${verb} User?`,
			content: (
				<ConfirmModalContent
					visual={
						deactivated ? (
							<EmptyStateAvatar
								avatarProps={{ bgcolor: 'primary.50' }}
								iconProps={{ color: 'primary.500' }}
								icon={<PowerSettingsNewOutlined />}
							/>
						) : (
							<EmptyStateAvatar
								avatarProps={{ bgcolor: 'error.50' }}
								iconProps={{ color: 'error.500' }}
								icon={<ErrorOutlined />}
							/>
						)
					}
					subheadline="Are you sure?"
					informativeContent={`Do you really want to ${verb} ${props.account?.name?.firstName} ${props.account?.name?.lastName}?`}
				/>
			),
			actions: (
				<ModalActions>
					<ModalActionButton size="large" variant="outlined">
						Cancel
					</ModalActionButton>
					<ModalLoadingButton
						size="large"
						variant="contained"
						color={deactivated ? 'primary' : 'error'}
						onClick={async () =>
							await updateAccountActivation(
								deactivated
									? ActivationOperation.Activate
									: ActivationOperation.Deactivate
							)
						}>
						{`${verb} User`}
					</ModalLoadingButton>
				</ModalActions>
			),
		});
	};

	return (
		<Grid container alignItems="center" justifyContent="center">
			<Grid item sm={12} md={4} paddingBottom={{ xs: 2, md: 'unset' }}>
				<Stack alignItems="center" spacing={0.5}>
					<UserAvatarUploadButton id={props.account.id} disabled={!canEdit} />
					{user.siteUserId && (
						<Chip
							size="small"
							label={props.account.deactivated ? 'Inactive User' : 'Active User'}
							color={props.account.deactivated ? 'error' : 'primary'}
							clickable={!isSelf && canEdit}
							onClick={!isSelf && canEdit ? onUpdateAccountActivation : undefined}
							data-test="user-status-chip"
						/>
					)}
				</Stack>
			</Grid>
			<Grid item container sm={12} md={8} alignContent="flex-start" spacing={2}>
				<Grid container item>
					<Grid item sm={1} pr={{ xs: 1, sm: 'unset' }}>
						<BadgeOutlined fontSize="small" />
					</Grid>
					<Grid item sm={4}>
						<Typography variant="h5">Display Name</Typography>
					</Grid>
					<Grid item xs={12} sm={7} data-test="name">
						{props.account.name?.firstName} {props.account.name?.lastName}{' '}
						{props.account.name?.suffix}
					</Grid>
				</Grid>
				<Grid container item>
					<Grid item sm={1} pr={{ xs: 1, sm: 'unset' }}>
						<AlternateEmail fontSize="small" />
					</Grid>
					<Grid item sm={4}>
						<Typography variant="h5">Handle</Typography>
					</Grid>

					<Grid item xs={12} sm={7} data-test="handle">
						{props.account.handle}
					</Grid>
				</Grid>
				<Grid container item>
					{props.account.cellPhone?.number && (
						<>
							<Grid item sm={1} pr={{ xs: 1, sm: 'unset' }}>
								<PhoneOutlined fontSize="small" />
							</Grid>
							<Grid item sm={4}>
								<Typography variant="h5">Cell Phone Number</Typography>
							</Grid>
							<Grid item xs={12} sm={7}>
								<PhoneNumberText
									number={props.account.cellPhone}
									data-test="cellPhone"
								/>
							</Grid>
						</>
					)}
					{props.account.homePhone?.number && (
						<>
							<Grid item sm={1} pr={{ xs: 1, sm: 'unset' }}></Grid>
							<Grid item sm={4}>
								<Typography variant="h5">Home Phone Number</Typography>
							</Grid>
							<Grid item xs={12} sm={7}>
								<PhoneNumberText
									number={props.account.homePhone}
									data-test="homePhone"
								/>
							</Grid>
						</>
					)}
				</Grid>
				<Grid container item>
					<Grid item sm={1} pr={{ xs: 1, sm: 'unset' }}>
						<EmailOutlined fontSize="small" />
					</Grid>
					<Grid item sm={4}>
						<Typography variant="h5">Primary E-mail</Typography>
					</Grid>
					<Grid item xs={12} sm={7} data-test="emailAddress">
						{props.account.emailAddress}
					</Grid>
					{props.account.secondaryEmailAddress && (
						<>
							<Grid item sm={1} pr={{ xs: 1, sm: 'unset' }}></Grid>
							<Grid item sm={4}>
								<Typography variant="h5">Secondary E-mail</Typography>
							</Grid>
							<Grid item xs={12} sm={7} data-test="secondaryEmailAddress">
								{props.account.secondaryEmailAddress}
							</Grid>
						</>
					)}
				</Grid>
				<Grid container item>
					<Grid item sm={1} pr={{ xs: 1, sm: 'unset' }}>
						<TranslateOutlined fontSize="small" />
					</Grid>
					<Grid item sm={4}>
						<Typography variant="h5">Languages</Typography>
					</Grid>
					<Grid item xs={12} sm={7} data-test="languages">
						{loading ? (
							<Skeleton>English</Skeleton>
						) : (
							languages
								.filter((l) => props.account.languageIds?.includes(l.id))
								.map((l) => l.name)
								.join(', ') || ''
						)}
					</Grid>
				</Grid>
				{props.account.birthDate && (
					<Grid container item>
						<Grid item sm={1} pr={{ xs: 1, sm: 'unset' }}>
							<CakeOutlined fontSize="small" />
						</Grid>
						<Grid item sm={4}>
							<Typography variant="h5">Birthday</Typography>
						</Grid>
						<Grid item xs={12} sm={7} data-test="birthDate">
							{props.account.birthDate && isValid(new Date(props.account.birthDate))
								? format(zoneDateOnly(props.account.birthDate), 'MM/dd/yyyy')
								: ''}
						</Grid>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default PersonalInfoRead;
