import { Close } from '@mui/icons-material';
import { IconButton, Link, Stack, Typography } from '@mui/material';
import { ContainedDrawer } from 'components/ui/containedDrawer';
import { UserChatFootnote } from 'middleware-types';
import { theme } from 'utils/theme';
import { EvoxFileChip } from './evoxFileChip';

interface EvoxFileUploadInfoMessageDrawerProps {
	open: boolean;
	onClose: () => void;
	footnotes: UserChatFootnote[] | null;
}

export const EvoxFootnotesDrawer = ({
	open,
	onClose,
	footnotes,
}: EvoxFileUploadInfoMessageDrawerProps) => {
	return (
		<ContainedDrawer
			open={open}
			transitionDuration={0}
			anchor="bottom"
			onClose={() => onClose()}
			containerid="evox-container">
			<Stack overflow="auto">
				<Stack
					borderBottom={`1px solid ${theme.palette.divider}`}
					justifyContent="space-between"
					alignItems="center"
					flexDirection="row"
					px={2}
					py={1}>
					<Typography variant="h2" fontSize={16}>
						Sources
					</Typography>
					<IconButton onClick={onClose}>
						<Close />
					</IconButton>
				</Stack>
				<Stack spacing={2} padding={2} flex={1} overflow="auto">
					{footnotes &&
						footnotes.map((footnote, i) => {
							if (footnote.url) {
								return (
									<Typography key={i} variant="body1" fontSize={12} noWrap>
										<Link
											underline="always"
											target="_blank"
											href={footnote.url}>
											{footnote.urlDisplayName
												? footnote.urlDisplayName
												: footnote.url.replace(/^https?:\/\/(www\.)?/, '')}
										</Link>
										{footnote.fileName}
										{footnote.snippet}
									</Typography>
								);
							}

							if (footnote.fileName)
								return (
									<Stack key={i} display="block">
										<EvoxFileChip name={footnote.fileName} />
										<Typography variant="body1" fontSize={12} mx={1}>
											{footnote.snippet}
										</Typography>
									</Stack>
								);
						})}
				</Stack>
			</Stack>
		</ContainedDrawer>
	);
};
