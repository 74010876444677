import { Card, CardContent, Grid, Skeleton, Stack } from '@mui/material';

const CompanyCardSkeleton = () => {
	return (
		<Card variant="outlined">
			<CardContent>
				<Stack spacing={2}>
					<Stack direction="row" spacing={2} alignItems="center">
						<Skeleton variant="circular" height={40} width={40} />
						<Stack flex={1} spacing={0}>
							<Skeleton variant="text" width={80} />
							<Skeleton variant="text" width={80} />
						</Stack>
						<Skeleton variant="rounded" height={40} width={120} />
					</Stack>
					<Grid container spacing={1}>
						<Grid item xs={6}>
							<Skeleton variant="rounded" height={30} />
						</Grid>
						<Grid item xs={6}>
							<Skeleton variant="rounded" height={30} />
						</Grid>
						<Grid item xs={6}>
							<Skeleton variant="text" />
						</Grid>
						<Grid item xs={6}>
							<Skeleton variant="text" />
						</Grid>
						<Grid item xs={6}>
							<Skeleton variant="text" />
						</Grid>
						<Grid item xs={6}>
							<Skeleton variant="text" />
						</Grid>
						<Grid item xs={12}>
							<Skeleton variant="rounded" height={30} />
						</Grid>
						<Grid item xs={12}>
							<Skeleton variant="text" />
						</Grid>
					</Grid>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default CompanyCardSkeleton;
