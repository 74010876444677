import { ModalActionButton, ModalActions, ModalContent, useModal } from 'components/ui/modal';
import { Formik, FormikProps } from 'formik';
import { BusinessLine, BusinessLineUpdate } from 'middleware-types';
import { useCreateLineOfBusiness, useUpdateLineOfBusiness } from '../hooks';
import { useValidation } from 'utils/useValidation';
import { TextField } from 'components/ui/fields';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface LineOfBusinessModalProps {
	orgId: string;
	lineOfBusiness?: BusinessLine;
}

export const LineOfBusinessModal = ({ orgId, lineOfBusiness }: LineOfBusinessModalProps) => {
	const { closeModal } = useModal();
	const createLineOfBusiness = useCreateLineOfBusiness(orgId);
	const updateLineOfBusiness = useUpdateLineOfBusiness(orgId);

	const initialValues: BusinessLineUpdate = {
		line: lineOfBusiness?.line ?? '',
		text: lineOfBusiness?.text ?? '',
		specialty: lineOfBusiness?.specialty ?? false,
	};

	const onSubmit = async (values: BusinessLineUpdate) => {
		let success = false;
		if (lineOfBusiness === undefined) {
			success = await createLineOfBusiness(values);
		} else {
			success = await updateLineOfBusiness(lineOfBusiness.id, values);
		}
		if (success) closeModal();
	};

	const validation = useValidation('BusinessLineUpdate');

	return (
		<Formik<BusinessLineUpdate>
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validation.schema}
			enableReinitialize>
			{(props: FormikProps<BusinessLineUpdate>) => (
				<>
					<ModalContent>
						<Stack spacing={2}>
							<TextField name="line" label="Name" required />
							<TextField
								name="text"
								label="Description"
								multiline
								helperText="Limit of 200 characters"
							/>
						</Stack>
					</ModalContent>
					<ModalActions>
						<ModalActionButton variant="outlined">Close</ModalActionButton>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							loading={props.isSubmitting}
							disabled={!props.isValid || !props.dirty || props.isSubmitting}
							onClick={() => {
								props.submitForm();
							}}>
							Save
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};
