import { Badge, IconButton, Stack, Tooltip } from '@mui/material';
import { Link, useMatch } from 'react-router-dom';
import { PrimaryNavLinkInfo } from './hooks';
import { BADGE_COUNT_MAX } from 'utils/useBadgeCounts';

export const DesktopPrimaryNavLink = ({ linkInfo }: { linkInfo: PrimaryNavLinkInfo }) => {
	const { title, path, onClick, icon, activeIcon, isOpen, badgeCount, isExternal } = linkInfo;
	const isPathActive = useMatch(path ?? '') !== null;
	const isActive = isOpen ?? isPathActive;

	const Icon = isActive && activeIcon !== undefined ? activeIcon : icon;

	const props = path
		? {
				component: Link,
				to: path,
				target: isExternal ? '_blank' : undefined,
		  }
		: {
				onClick,
		  };

	return (
		<Stack
			alignSelf="stretch"
			justifyContent="center"
			position="relative"
			sx={{
				borderBottom: '3px solid',
				borderColor: isActive ? 'primary.main' : 'transparent',
			}}>
			<Tooltip title={title} leaveDelay={0}>
				<IconButton {...props}>
					<Badge badgeContent={badgeCount} max={BADGE_COUNT_MAX} color="primary">
						<Icon sx={{ color: isActive ? 'neutral.900' : 'neutral.500' }} />
					</Badge>
				</IconButton>
			</Tooltip>
		</Stack>
	);
};
