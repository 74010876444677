import { MoreHoriz } from '@mui/icons-material';
import {
	Box,
	IconButton,
	Link,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Skeleton,
	Stack,
	Tooltip,
} from '@mui/material';
import { useQuickLinkSrc } from 'components/pages/dashboard/quickLinks/quickLinkCard';
import { useUserQuickLinks } from 'components/pages/dashboard/quickLinks/userQuickLinkHooks';
import { QuickLink } from 'middleware-types';
import { useState } from 'react';
import { useElementSize } from 'utils/useElementSize';
import { SIDE_RAIL_BUTTON_SIZE_PX, SideRailButton } from './side-rail-button';

export const SideRailQuickLinks = () => {
	const [ref, { clientHeight }] = useElementSize<HTMLDivElement>();
	return (
		<Stack ref={ref} flex={1} overflow="hidden">
			<SideRailQuickLinksContent height={clientHeight} />
		</Stack>
	);
};

const SideRailQuickLinksContent = ({ height }: { height: number | undefined }) => {
	const { quickLinks, loading } = useUserQuickLinks();
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	if (loading || height === undefined) {
		return (
			<Stack>
				{[...Array(5)].map((_, index) => (
					<Stack
						key={index}
						alignItems="center"
						justifyContent="center"
						height={SIDE_RAIL_BUTTON_SIZE_PX}
						width={SIDE_RAIL_BUTTON_SIZE_PX}
						p={1}>
						<Skeleton variant="rounded" height="100%" width="100%" />
					</Stack>
				))}
			</Stack>
		);
	}

	const numberOfLinks = quickLinks.length;
	const numberOfButtonsThatCanFit = Math.floor(height / SIDE_RAIL_BUTTON_SIZE_PX);
	const numberOfLinksToShow =
		numberOfButtonsThatCanFit === numberOfLinks || numberOfButtonsThatCanFit === 0
			? numberOfButtonsThatCanFit
			: numberOfButtonsThatCanFit - 1;

	const visibleLinks = quickLinks.slice(0, numberOfLinksToShow);
	const hiddenLinks = quickLinks.slice(numberOfLinksToShow);

	const menuAnchor = hiddenLinks.length > 0 ? anchorEl : null;

	return (
		<Stack>
			{visibleLinks.map((quickLink) => (
				<SideRailQuickLink key={quickLink.id} quickLink={quickLink} />
			))}
			{hiddenLinks.length > 0 && (
				<Stack
					alignItems="center"
					justifyContent="center"
					height={SIDE_RAIL_BUTTON_SIZE_PX}
					width={SIDE_RAIL_BUTTON_SIZE_PX}>
					<Tooltip title="See more" placement="right">
						<IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
							<MoreHoriz />
						</IconButton>
					</Tooltip>
				</Stack>
			)}
			{/** menu for hidden links */}
			<Menu
				open={Boolean(menuAnchor)}
				anchorEl={menuAnchor}
				onClose={() => setAnchorEl(null)}
				onClick={() => setAnchorEl(null)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
				{hiddenLinks.map((quickLink) => (
					<SideRailMenuLink key={quickLink.id} quickLink={quickLink} />
				))}
			</Menu>
		</Stack>
	);
};

const SideRailQuickLink = ({ quickLink }: { quickLink: QuickLink }) => {
	const src = useQuickLinkSrc(quickLink);
	return (
		<SideRailButton
			src={src}
			tooltipText={quickLink.title}
			href={quickLink.url}
			target="_blank"
		/>
	);
};

const SideRailMenuLink = ({ quickLink }: { quickLink: QuickLink }) => {
	const src = useQuickLinkSrc(quickLink);
	return (
		<MenuItem component={Link} href={quickLink.url} target="_blank" sx={{ width: '100%' }}>
			<ListItemIcon>
				<Box component="img" src={src} height={28} width={28} />
			</ListItemIcon>
			<ListItemText>{quickLink.title}</ListItemText>
		</MenuItem>
	);
};
