import { gql, useQuery } from '@apollo/client';
import { Query, QueryFolderReferenceListArgs } from 'middleware-types';
import { PageError } from 'utils/errors';
import { useDocumentsContext } from '../../documents-page';
import { getRootFolderName } from '../../utils/enum-helpers';
import { FOLDER_REFERENCE_FIELDS } from '../../utils/fragments.graphql';

const GET_FOLDER_REFERENCE_LIST = gql`
	${FOLDER_REFERENCE_FIELDS}
	query GetFolderReferenceList($entityType: FoldersApiEntityType!, $entityId: String!) {
		folderReferenceList(entityType: $entityType, entityId: $entityId) {
			folderReferences {
				...FolderReferenceFields
			}
		}
	}
`;

export const useFolderReferenceList = () => {
	const { entityId, entityType } = useDocumentsContext();

	const {
		data,
		loading: folderReferencesLoading,
		error: folderReferencesError,
	} = useQuery<Pick<Query, 'folderReferenceList'>, QueryFolderReferenceListArgs>(
		GET_FOLDER_REFERENCE_LIST,
		{
			// fetchPolicy: 'cache-and-network',
			fetchPolicy: 'network-only',
			variables: {
				entityId,
				entityType,
			},
		}
	);

	if (folderReferencesError) throw new PageError(folderReferencesError);

	const folderReferences = data?.folderReferenceList.folderReferences;
	const rootName = getRootFolderName(entityType);

	return {
		folderReferences,
		folderReferencesLoading,
		rootName,
	};
};
