import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';

export const extensions = [
	StarterKit.configure({
		blockquote: false,
		codeBlock: false,
		hardBreak: false,
		heading: {
			levels: [1, 2],
		},
		horizontalRule: false,
		code: false,
		strike: false,
		dropcursor: false,
	}),
	Underline,
	TextAlign.configure({
		types: ['heading', 'paragraph'],
		alignments: ['left', 'right', 'center'],
	}),
	Link.extend({
		inclusive: false,
	}),
];
