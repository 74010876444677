import { Add, InfoOutlined } from '@mui/icons-material';
import {
	Box,
	Card,
	Divider,
	IconButton,
	Skeleton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { useModal } from 'components/ui/modal';
import { Fragment } from 'react';
import { useOrgProfile } from '../hooks';
import { LineOfBusinessListItem } from './line-of-business-list-item';
import { LineOfBusinessModal } from './line-of-business-modal';

interface OrgLinesOfBusinessCardProps {
	orgId: string;
	canEdit: boolean;
}

export const OrgLinesOfBusinessCard = ({ orgId, canEdit }: OrgLinesOfBusinessCardProps) => {
	const { showModal } = useModal();
	const { profile, loading } = useOrgProfile(orgId);

	if (loading) return <LinesOfBusinessSkeleton />;
	if (!profile) return <></>;

	const specialties = profile.businessLines?.filter((line) => line.specialty) ?? [];
	const canAddSpecialty = specialties.length < 3;

	return (
		<Card sx={{ borderRadius: { xs: 0, sm: 1 } }}>
			<Stack
				direction="row"
				px={{ xs: 2, sm: 2.5 }}
				py={1}
				alignItems="center"
				justifyContent="space-between">
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<Typography variant="h3">Lines of Business</Typography>
					{canEdit && (
						<Tooltip title="Feature up to three lines of business in your profile header card by clicking the star icon.">
							<IconButton>
								<InfoOutlined />
							</IconButton>
						</Tooltip>
					)}
				</Stack>
				{canEdit && (
					<IconButton
						disabled={profile.businessLines && profile.businessLines.length >= 10}
						onClick={() =>
							showModal({
								title: 'Add Line of Business',
								content: <LineOfBusinessModal orgId={profile.id} />,
							})
						}>
						<Add />
					</IconButton>
				)}
			</Stack>
			{profile.businessLines?.map((lineOfBusiness) => (
				<Fragment key={lineOfBusiness.id}>
					<Divider />
					<LineOfBusinessListItem
						lineOfBusiness={lineOfBusiness}
						canEdit={canEdit}
						canAddSpecialty={canAddSpecialty}
					/>
				</Fragment>
			))}
		</Card>
	);
};

const LinesOfBusinessSkeleton = () => {
	return (
		<Card sx={{ borderRadius: { xs: 0, sm: 1 } }}>
			<Stack
				direction="row"
				px={{ xs: 2, sm: 2.5 }}
				py={1}
				alignItems="center"
				justifyContent="space-between">
				<Stack direction="row" alignItems="center" spacing={0.5} margin={1}>
					<Typography variant="h3">Lines of Business</Typography>
				</Stack>
			</Stack>
			<Divider />
			<Stack
				px={{ xs: 2, sm: 2.5 }}
				py={2}
				spacing={{ xs: 1, sm: 2 }}
				direction={{ xs: 'column', sm: 'row' }}
				alignItems={{ xs: 'flex-start', sm: 'center' }}
				justifyContent="space-between">
				<Box overflow="hidden" width="100%">
					<Skeleton variant="text" width="100%" />
				</Box>
			</Stack>
		</Card>
	);
};
