import { LoadingButton } from '@mui/lab';
import {
	AutocompleteRenderGetTagProps,
	AutocompleteRenderInputParams,
	Box,
	Button,
	CardContent,
	CardHeader,
	Chip,
	Grid,
	Link as MuiLink,
	TextField as MuiTextField,
	Stack,
	Typography,
} from '@mui/material';
import { Alert } from 'components/ui/alert';
import { UserAvatarUploadButton } from 'components/ui/emblem/avatar-upload-button';
import { UserEmblemBanner } from 'components/ui/emblem/emblem-banner';
import { Field, TextField } from 'components/ui/fields';
import { useToast } from 'components/ui/toast';
import { Formik } from 'formik';
import { Autocomplete } from 'formik-mui';
import { UserProfile, UserProfileUpdate } from 'middleware-types';
import { Link } from 'react-router-dom';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useValidation } from 'utils/useValidation';
import * as Yup from 'yup';
import { useProfileHeaderMutation, useWorkTypesQuery } from './hooks';
import { useIsMobile } from 'utils/useScreenSize';

/**
 * useProfileHeader() - Hook for loading editing the ProfileHeader
 *
 * @param {string} userId
 * @return {*}
 */
const useProfileHeaderEdit = (profile: UserProfile, onCancel: () => void) => {
	const toast = useToast();

	// initialFocusedField references which Formik field name will be focused when edit mode is opened,
	// for instance companyTitle when clicking the Add Title link.
	const { updateProfile, error } = useProfileHeaderMutation(profile.id);
	const baseValidation = useValidation('UserProfileUpdate');
	const customValidator = Yup.object().shape({
		companyName: Yup.string().test(
			'title-not-null',
			'Required if Title is entered',
			function (value) {
				const { companyTitle } = this.parent;
				if (companyTitle) return value != null;
				return true;
			}
		),
		companyTitle: Yup.string().test(
			'company-not-null',
			'Required if Company Name is entered',
			function (value) {
				const { companyName } = this.parent;
				if (companyName) return value != null;
				return true;
			}
		),
		workCodes: Yup.array().max(3, 'Enter up to 3 work types'),
	});

	const initialValues: UserProfileUpdate = {
		name: {
			firstName: profile.name.firstName ?? '',
			middleName: profile.name?.middleName ?? '',
			lastName: profile.name?.lastName ?? '',
			suffix: profile.name?.suffix ?? '',
		},
		languageIds: profile.languageIds ?? [],
		companyName: profile.companyName ?? '',
		companyTitle: profile.companyTitle ?? '',
		about: profile.about ?? '',
		workCodes: profile.workCodes ?? [],
		xProfileLink: profile.xProfileLink ?? '',
		linkedinProfileLink: profile.linkedinProfileLink ?? '',
		facebookProfileLink: profile.facebookProfileLink ?? '',
		conversationInsuranceProfileLink: profile.conversationInsuranceProfileLink ?? '',
	};

	const onSubmit = async (values: UserProfileUpdate) => {
		await updateProfile({
			name: {
				firstName: values.name.firstName,
				middleName: values.name.middleName,
				lastName: values.name.lastName,
				suffix: values.name.suffix,
			},
			companyName: values.companyName === '' ? undefined : values.companyName,
			companyTitle: values.companyTitle === '' ? undefined : values.companyTitle,
			languageIds: profile?.languageIds ?? [],
			about: values.about === '' ? undefined : values.about,
			workCodes: values.workCodes,
			xProfileLink: values.xProfileLink === '' ? undefined : values.xProfileLink,
			linkedinProfileLink:
				values.linkedinProfileLink === '' ? undefined : values.linkedinProfileLink,
			facebookProfileLink:
				values.facebookProfileLink === '' ? undefined : values.facebookProfileLink,
			conversationInsuranceProfileLink:
				values.conversationInsuranceProfileLink === ''
					? undefined
					: values.conversationInsuranceProfileLink,
		})
			.then(async (res) => {
				if (responseHasErrors(res.errors, { toast })) {
					console.log(res.errors);
					return false;
				}
				toast.push('Profile updated successfully.', { variant: 'success' });
				onCancel();
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		profile,
		error,
		initialValues,
		onSubmit,
		validation: baseValidation.schema
			? baseValidation.schema.concat(customValidator)
			: customValidator,
	};
};

/**
 * ProfileHeaderEdit is the edit form for the Profile Header
 *
 * @param {ProfileHeaderEditProps} props
 */
export const ProfileHeaderEdit = (props: {
	profile: UserProfile;
	onCancel: () => void;
	initialFocusedField?: string | null;
}) => {
	const { workTypes, loading } = useWorkTypesQuery();
	const { onSubmit, initialValues, validation, error } = useProfileHeaderEdit(
		props.profile,
		props.onCancel
	);
	const isMobile = useIsMobile();

	return (
		<Formik<UserProfileUpdate>
			initialValues={initialValues}
			onSubmit={onSubmit}
			enableReinitialize
			initialTouched={{
				companyName: true,
				companyTitle: true,
			}}
			validationSchema={validation}>
			{(fProps) => (
				<>
					<UserEmblemBanner id={props.profile.id} enableUpload />
					<CardHeader
						// Move the avatar in edit mode to overlay on top of the cardmedia.
						sx={{
							'& .MuiCardHeader-avatar': {
								marginTop: isMobile ? '-3.75rem' : '-7.5rem',
								marginLeft: isMobile ? 'unset' : '.25rem',
							},
						}}
						avatar={
							<UserAvatarUploadButton
								id={props.profile.id}
								size={isMobile ? 100 : undefined}
							/>
						}
						action={
							<Stack direction="row" spacing={1}>
								<Button
									variant="outlined"
									onClick={() => {
										fProps.resetForm();
										props.onCancel();
									}}>
									Cancel
								</Button>
								<LoadingButton
									color="primary"
									variant="contained"
									loading={fProps.isSubmitting}
									onClick={fProps.submitForm}
									disabled={
										!fProps.isValid || !fProps.dirty || fProps.isSubmitting
									}>
									{`Save`}
								</LoadingButton>
							</Stack>
						}
					/>
					<CardContent>
						{error && <Alert error={error} />}
						<Stack>
							<Grid container spacing={1} columnSpacing={2}>
								<Grid xs={12} lg={3} item>
									<TextField
										label="First Name"
										name="name.firstName"
										required
										fullWidth
									/>
								</Grid>
								<Grid xs={12} lg={3} item>
									<TextField
										label="Middle Name"
										type="text"
										name="name.middleName"
										fullWidth
									/>
								</Grid>
								<Grid xs={12} lg={3} item>
									<TextField
										label="Last Name"
										required
										name="name.lastName"
										fullWidth
									/>
								</Grid>
								<Grid xs={12} lg={3} item>
									<TextField
										label="Suffix"
										type="text"
										name="name.suffix"
										fullWidth
									/>
								</Grid>
								<Grid xs={12} item>
									<TextField
										label="Company Name"
										name="companyName"
										fullWidth
										autoFocus
									/>
								</Grid>
								<Grid xs={12} item>
									<TextField
										label="Title"
										name="companyTitle"
										fullWidth
										autoFocus={props.initialFocusedField === 'companyTitle'}
									/>
								</Grid>
								<Grid xs={12} item>
									<MuiTextField
										label="Temporary Location"
										fullWidth
										disabled
										value={
											props.profile.displayTemporaryLocation?.displayLocation
										}
										helperText={
											<>
												<Typography variant="caption">
													This is based on your current or earliest
													upcoming temporary address. Edit your addresses
													in&nbsp;
													<MuiLink
														to={`./account`}
														color="primary"
														component={Link}>
														Account Settings.
													</MuiLink>
												</Typography>
											</>
										}
									/>
								</Grid>
								<Grid xs={12} item>
									<MuiTextField
										label="Primary Location"
										fullWidth
										disabled
										value={props.profile.displayLocation}
										helperText={
											<>
												<Typography variant="caption">
													This is based on your primary address. Edit your
													addresses in&nbsp;
													<MuiLink
														to={`./account`}
														color="primary"
														component={Link}>
														Account Settings.
													</MuiLink>
												</Typography>
											</>
										}
									/>
								</Grid>
								<Grid xs={12} item>
									<Field
										name="workCodes"
										component={Autocomplete}
										multiple
										loading={loading}
										options={workTypes ? [...workTypes.keys()] : []}
										getOptionLabel={(workCode: string) =>
											workTypes?.get(workCode)
										}
										disableClearable
										filterSelectedOptions
										renderInput={(params: AutocompleteRenderInputParams) => (
											<TextField
												{...params}
												name="workCodes"
												label="What type of work do you do?"
												variant="outlined"
												autoFocus={
													props.initialFocusedField === 'workCodes'
												}
											/>
										)}
										openOnFocus
										renderTags={(
											value: string[],
											getTagProps: AutocompleteRenderGetTagProps
										) =>
											value.map((option, index) => {
												// separate out the key to satisfy the linter
												const { key, ...tagProps } = getTagProps({ index });
												return (
													<Chip
														key={key}
														variant="outlined"
														label={workTypes?.get(option)}
														{...tagProps}
													/>
												);
											})
										}
									/>
								</Grid>
								<Grid xs={12} item>
									<TextField
										label="About"
										name="about"
										fullWidth
										autoFocus={props.initialFocusedField === 'about'}
										multiline
									/>
								</Grid>
								<Grid xs={12} item>
									<Typography pt={1.5} pb={1} variant="h3">
										Social Media
									</Typography>
									<Box>
										<Grid container rowSpacing={0.5} columnSpacing={1.5}>
											<Grid item xs={12} sm={6}>
												<TextField
													name="xProfileLink"
													label="X Profile Link"
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<TextField
													name="linkedinProfileLink"
													label="LinkedIn Profile Link"
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<TextField
													name="facebookProfileLink"
													label="Facebook Profile Link"
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<TextField
													name="conversationInsuranceProfileLink"
													label="Conversation Insurance Profile Link"
												/>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Grid>
						</Stack>
					</CardContent>
				</>
			)}
		</Formik>
	);
};
