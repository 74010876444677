import { CheckCircleOutlined, ErrorOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField as MTextField, MenuItem, Skeleton } from '@mui/material';
import { Alert } from 'components/ui/alert';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { SelectField, TextField } from 'components/ui/fields';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalContent,
	useModal,
} from 'components/ui/modal';
import { useToast } from 'components/ui/toast';
import { Formik, FormikProps } from 'formik';
import { AssociateInvitationRequest } from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useValidation } from 'utils/useValidation';
import {
	useAllOrgRolesQuery,
	useDeleteAssociateInvitationMutation,
	useInviteAssociateMutation,
} from '../hooks';

/**
 * useAssociateInvitationModal(orgId) - Creates an org invitation modal.
 *
 * @param {string} orgId
 * @return {*}
 */
export const useAssociateInvitationModal = (orgId: string, onSuccess?: () => void) => {
	const { showModal } = useModal();

	const openConfirmation = (request: AssociateInvitationRequest) => {
		onSuccess && onSuccess();
		showModal({
			title: 'Invite Associate',
			content: (
				<ModalContent>
					<ConfirmModalContent
						visual={
							<EmptyStateAvatar
								icon={<CheckCircleOutlined />}
								avatarProps={{ bgcolor: 'success.50' }}
								iconProps={{ color: 'success.500' }}
							/>
						}
						subheadline={`Invitation sent to ${request.displayName}`}
						informativeContent={`Would you like to invite another?`}
					/>
				</ModalContent>
			),
			actions: (
				<ModalActions>
					<ModalActionButton variant="outlined">Close</ModalActionButton>
					<Button
						color="primary"
						variant="contained"
						onClick={() => {
							openInvitation();
						}}>
						Invite Another
					</Button>
				</ModalActions>
			),
		});
	};

	const openInvitation = () => {
		showModal({
			title: 'Invite Associate',
			content: <InvitationForm orgId={orgId} onSubmit={openConfirmation} />,
		});
	};

	return { openInvitation };
};

/**
 * useDeleteAssociateInvitationModal(orgId, invitationId, displayName) - Creates an org delete invitation modal.
 *
 * @param {string} orgId
 * @param {string} invitationId
 * @param {string} displayName
 * @return {*}
 */
export const useDeleteAssociateInvitationModal = (
	orgId: string,
	invitationId: string,
	displayName: string
) => {
	const { showModal } = useModal();
	const { deleteInvitation } = useDeleteAssociateInvitationMutation();

	const openDeleteInvitation = () => {
		showModal({
			title: 'Delete Invitation',
			content: (
				<ModalContent>
					<ConfirmModalContent
						visual={
							<EmptyStateAvatar
								avatarProps={{ bgcolor: 'error.50' }}
								iconProps={{ color: 'error.500' }}
								icon={<ErrorOutlined />}
							/>
						}
						subheadline={`Delete Invitation`}
						informativeContent={`Are you sure you want to delete the invitation to ${displayName}?`}
					/>
				</ModalContent>
			),
			actions: (
				<ModalActions>
					<ModalActionButton variant="outlined">Cancel</ModalActionButton>
					<ModalActionButton
						color="error"
						variant="contained"
						onClick={() => deleteInvitation(orgId, invitationId)}>
						Delete
					</ModalActionButton>
				</ModalActions>
			),
		});
	};

	return { openDeleteInvitation };
};

/**
 * useInvAssociate - Custom hook for getting validation schema, handling submit, and loading.
 *
 * @returns {({
 * 	initialValues: MAssociateInvitationRequest;
 * 	roles: AllOrgRoles;
 * 	validationSchema: ValidationSchema | null;
 * 	submit: (values: MAssociateInvitationRequest) => Promise<void>;
 * 	loading: boolean;
 * 	error: ApolloError | undefined;
 * })}
 */
const useInvAssociate = (orgId: string) => {
	const validation = useValidation('AssociateInvitationRequest');
	const toast = useToast();
	const { invite, error } = useInviteAssociateMutation(orgId);
	const rolesQuery = useAllOrgRolesQuery(orgId);
	let initialValues = {
		emailAddress: '',
		displayName: '',
		roleId: '',
	};

	/**
	 * When the form submits
	 */
	const submit = async (values: AssociateInvitationRequest) => {
		return await invite(values)
			.then(async (res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		initialValues,
		roles: rolesQuery.roles,
		submit,
		validationSchema: validation.schema,
		loading: validation.loading || rolesQuery.loading,
		error,
	};
};

/**
 * Associate Invitation Modal
 *
 * @param {InvitationModalProps} props
 * @returns
 */
const InvitationForm = (props: {
	orgId: string;
	onSubmit: (invite: AssociateInvitationRequest) => void;
}) => {
	const { initialValues, roles, loading, submit, validationSchema, error } = useInvAssociate(
		props.orgId
	);
	if (loading) return <SkeletonForm />;

	const handleSubmit = async (values: AssociateInvitationRequest) => {
		if (await submit(values)) {
			props.onSubmit(values);
		}
	};

	return (
		<Formik<AssociateInvitationRequest>
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			initialValues={initialValues}
			enableReinitialize>
			{(props: FormikProps<AssociateInvitationRequest>) => (
				<>
					<ModalContent>
						<Grid container spacing={1} columnSpacing={2}>
							<Grid xs={12} sm={6} md={6} item>
								<TextField
									label="Display Name"
									required
									name="displayName"
									fullWidth
								/>
							</Grid>
							<Grid xs={12} sm={6} md={6} item>
								<TextField
									label="Email Address"
									required
									type="email"
									name="emailAddress"
									fullWidth
								/>
							</Grid>
							<Grid xs={12} sm={6} md={6} item>
								<SelectField
									label="Role"
									required
									name="roleId"
									value={props.values.roleId ?? ''}
									fullWidth>
									{roles.map((src, i) => (
										<MenuItem key={i} value={src.id}>
											{src.name !== '' ? src.name : <em>None</em>}
										</MenuItem>
									))}
								</SelectField>
							</Grid>
							{error && (
								<Grid xs={12} md={12} item>
									<Alert error={error} />
								</Grid>
							)}
						</Grid>
					</ModalContent>
					<ModalActions>
						<ModalActionButton variant="outlined">Cancel</ModalActionButton>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							disabled={!props.isValid || !props.dirty || props.isSubmitting}
							loading={props.isSubmitting}
							onClick={() => props.submitForm()}>
							Send Invite
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};

const SkeletonForm = (): React.JSX.Element => (
	<ModalContent>
		<Grid container spacing={1} columnSpacing={2}>
			<Grid xs={12} md={6} item>
				<Skeleton animation="wave" width="100%">
					<MTextField fullWidth />
				</Skeleton>
			</Grid>
			<Grid xs={12} sm={6} md={6} item>
				<Skeleton animation="wave" width="100%">
					<MTextField fullWidth />
				</Skeleton>
			</Grid>
			<Grid xs={12} sm={6} md={6} item>
				<Skeleton animation="wave" width="100%">
					<MTextField fullWidth />
				</Skeleton>
			</Grid>
		</Grid>
	</ModalContent>
);
