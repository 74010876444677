import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { TextField } from 'components/ui/fields';
import { Formik, FormikProps } from 'formik';
import { FolderReference, UpsertFolderRequest } from 'middleware-types';
import { useValidation } from 'utils/useValidation';
import { useCreateFolder } from '../hooks/folders/use-create-folder';
import { useUpdateFolder } from '../hooks/folders/use-update-folder';
import { useCurrentFolderId } from '../hooks/use-current-folder-id';

interface CreateUpdateFolderModalProps {
	folder?: FolderReference;
	onClose: () => void;
}

export const CreateUpdateFolderModal = ({ folder, onClose }: CreateUpdateFolderModalProps) => {
	const [currentFolderId] = useCurrentFolderId();
	const createFolder = useCreateFolder();
	const updateFolder = useUpdateFolder();

	const initialValues: UpsertFolderRequest = {
		parentFolderId: currentFolderId,
		name: folder?.name ?? '',
	};

	const onSubmit = async (values: UpsertFolderRequest) => {
		let success = false;
		if (folder) success = await updateFolder(folder.id, values);
		else success = await createFolder(values);
		if (success) onClose();
	};

	const validation = useValidation('UpsertFolderRequest');

	return (
		<Formik<UpsertFolderRequest>
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validation.schema}>
			{({ isSubmitting, isValid, dirty, submitForm }: FormikProps<UpsertFolderRequest>) => (
				<Stack>
					<Stack
						px={2}
						py={1}
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={1}>
						<Typography variant="h3">
							{folder ? 'Rename Folder' : 'Create Folder'}
						</Typography>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</Stack>
					<Divider />
					<Stack p={2.5}>
						<TextField required name="name" label="Folder Name" />
					</Stack>
					<Divider />
					<Stack direction="row" justifyContent="flex-end" px={2} py={1.5} spacing={1.5}>
						<Button size="large" variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							size="large"
							variant="contained"
							color="primary"
							loading={isSubmitting}
							disabled={!isValid || !dirty}
							onClick={submitForm}>
							Save
						</LoadingButton>
					</Stack>
				</Stack>
			)}
		</Formik>
	);
};
