import { useState, useEffect } from 'react';

export const useWindowDimensions = () => {
	const [largeScreen, setLargeScreen] = useState(
		window.innerHeight >= 700 && window.innerWidth >= 1025
	);

	useEffect(() => {
		const handleResize = () => {
			const newHeight = window.innerHeight;
			const newWidth = window.innerWidth;
			const newSizeEnough = newHeight >= 700 && newWidth >= 1025;

			setLargeScreen(newSizeEnough);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return largeScreen;
};
