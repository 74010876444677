import { Box, ListItem, ListItemButton, Stack, Typography } from '@mui/material';
import { LabelType } from 'middleware-types';
import { useCommunicationsContext } from '../communications-provider';
import { LabelIcon } from '../shared/label-icon';
import { LabelWithColor } from '../helpers/label-colors';

interface CompactLabelListItemProps {
	label: LabelWithColor;
}

export const CompactLabelListItem = ({ label }: CompactLabelListItemProps) => {
	const { selectedLabelId, setSelectedLabelId, setSelectedConversationId } =
		useCommunicationsContext();
	const selected = selectedLabelId === label.id;
	const isCustom = label.type === LabelType.Custom;

	const onClick = () => {
		setSelectedLabelId(label.id);
		setSelectedConversationId(null);
	};

	return (
		<ListItem disableGutters disablePadding>
			<ListItemButton
				disableGutters
				onClick={onClick}
				selected={selected}
				sx={{
					'&.Mui-selected': { border: 'none' },
				}}>
				<Stack width="100%" alignItems="center" px={1} py={isCustom ? 0 : 0.5}>
					<LabelIcon label={label} selected={selected} />
					{isCustom && (
						<Box
							alignSelf="stretch"
							textAlign="center"
							textOverflow="ellipsis"
							overflow="hidden">
							<Typography variant="caption" noWrap>
								{label.name}
							</Typography>
						</Box>
					)}
				</Stack>
			</ListItemButton>
		</ListItem>
	);
};
