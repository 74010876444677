import { gql, useMutation } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import { Mutation, MutationFolderDeleteArgs } from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useDocumentsContext } from '../../documents-page';
import { updateFolderContents } from '../../utils/cache-helpers';
import { useDocumentSearchContext } from '../../components/document-search-provider';
import { useCurrentFolderId } from '../use-current-folder-id';

const DELETE_FOLDER = gql`
	mutation DeleteFolder(
		$entityType: FoldersApiEntityType!
		$entityId: String!
		$folderId: String!
	) {
		folderDelete(entityType: $entityType, entityId: $entityId, folderId: $folderId)
	}
`;

export const useDeleteFolder = () => {
	const toast = useToast();
	const [currentFolderId, setCurrentFolderId] = useCurrentFolderId();
	const { entityType, entityId } = useDocumentsContext();
	const { searchValue } = useDocumentSearchContext();

	const [_deleteFolder, { loading }] = useMutation<
		Pick<Mutation, 'folderDelete'>,
		MutationFolderDeleteArgs
	>(DELETE_FOLDER);

	const deleteFolder = async (folderId: string, parentFolderId: string | undefined) => {
		return await _deleteFolder({
			variables: { entityType, entityId, folderId },
			update: (cache, { data }) => {
				if (!data) return;
				updateFolderContents(
					{ cache, folderId: parentFolderId, entityType, entityId, searchValue },
					(existing) => ({
						...existing,
						folders: existing.folders?.filter((f) => f.id !== folderId),
					})
				);
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Folder deleted successfully.', { variant: 'success' });
				if (folderId === currentFolderId) setCurrentFolderId(undefined, false);
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { deleteFolder, loading };
};
