import { PeopleOutlined, ManageSearchOutlined } from '@mui/icons-material';
import {
	Divider,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemSecondaryAction,
	ListItemText,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import {
	ComprehensiveConnectionState,
	ConnectionStateExtended,
} from 'components/pages/network/shared/types';
import { EmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { ConnectionPageType, ConnectionState, Emblem, EmblemEntityType } from 'middleware-types';
import React from 'react';
import { OrgConnectionButton } from '../OrgConnections/OrgConnectionButton';
import { useOrgConnectionsOrRequestsQuery } from './OrgConnectionsOrRequests.graphql';
import { useIsMobile } from 'utils/useScreenSize';

/**
 * Displays the list of org connections, inbound requests, or outbound requests
 */
export const OrgConnectionsOrRequestsList = ({
	orgId,
	pageType,
	searchFor,
}: {
	orgId: string;
	pageType: ConnectionPageType;
	searchFor: string;
}) => {
	const { connectionsOrRequests, loading } = useOrgConnectionsOrRequestsQuery(
		pageType,
		orgId,
		true,
		searchFor
	);

	let connectionState: ComprehensiveConnectionState;
	if (pageType === ConnectionPageType.Requests)
		connectionState = ConnectionStateExtended.IncomingRequest;
	if (pageType === ConnectionPageType.MyConnections) connectionState = ConnectionState.Connected;
	if (pageType === ConnectionPageType.RequestsISent) connectionState = ConnectionState.Pending;

	if (loading) return <OrgNetworkListLoadingSkeleton />;

	// When there are no search results
	if (connectionsOrRequests.length == 0 && searchFor !== '') {
		return (
			<Stack spacing={2} justifyContent="center" alignItems="center" height="100%">
				<EmptyStateAvatar
					icon={<ManageSearchOutlined />}
					avatarProps={{ bgcolor: 'primary.50' }}
					iconProps={{ color: 'primary.500' }}
				/>
				<Typography variant="h5" px={3} textAlign="center">
					No results found for {searchFor}.
				</Typography>
			</Stack>
		);
	}

	if (connectionsOrRequests.length == 0)
		return (
			<Stack spacing={2} justifyContent="center" alignItems="center" height="100%">
				<EmptyStateAvatar
					icon={<PeopleOutlined />}
					avatarProps={{ bgcolor: 'primary.50' }}
					iconProps={{ color: 'primary.500' }}
				/>
				<Typography variant="h5" px={3} textAlign="center">
					{pageType === ConnectionPageType.MyConnections
						? 'You have no connections at this time.'
						: 'You have no pending connection requests at this time.'}
				</Typography>
			</Stack>
		);

	return (
		<List className="pb-0">
			{connectionsOrRequests.map((request, i) => (
				<React.Fragment key={request.connectionId}>
					<OrgNetworkListDisplayItem
						connectionId={request.connectionId}
						connectionState={connectionState}
						otherUserEmblem={request.otherUserEmblem}
						otherOrganizationEmblem={request.otherOrganizationEmblem}
						orgId={orgId}
					/>
					{i !== connectionsOrRequests.length - 1 && <Divider />}
				</React.Fragment>
			))}
		</List>
	);
};

/**
 * Display item for org network lists
 * @summary It is expected that either the UserEmblem OR the OrganizationEmblem will be provided via the connection param.
 */
const OrgNetworkListDisplayItem = ({
	connectionId,
	connectionState,
	otherUserEmblem,
	otherOrganizationEmblem,
	orgId,
}: {
	connectionId: string;
	connectionState: ComprehensiveConnectionState;
	otherUserEmblem: Emblem | undefined;
	otherOrganizationEmblem: Emblem | undefined;
	orgId: string | undefined;
}) => {
	let emblem = otherUserEmblem ?? otherOrganizationEmblem;
	if (emblem === undefined || !orgId) return null;
	const isMobile = useIsMobile();
	return (
		<Stack>
			<ListItem disableGutters disablePadding className="p-0">
				<ListItemButton
					component={Link}
					href={`/${emblem.handle}`}
					className="w-full rounded p-4"
					disableRipple>
					<ListItemAvatar>
						<EmblemAvatar emblem={emblem} />
					</ListItemAvatar>
					{emblem.entityType === EmblemEntityType.User && (
						<ListItemText
							primary={
								<Typography variant="h4">{emblem.extraData?.fullName}</Typography>
							}
							secondary={
								<Typography variant="subtitle2">
									{emblem.extraData?.companyTitle}
									{emblem.extraData?.companyName && (
										<> at {emblem.extraData?.companyName}</>
									)}
								</Typography>
							}
						/>
					)}
					{emblem.entityType === EmblemEntityType.Organization && (
						<ListItemText
							primary={<Typography variant="h4">{emblem.displayName}</Typography>}
						/>
					)}
				</ListItemButton>
				{!isMobile && (
					<ListItemSecondaryAction className="right-4">
						<OrgConnectionButton
							organization={{
								connectionId,
								connectionState,
								orgId,
								orgName: '',
								entityId: emblem.id,
								entityName: emblem.displayName,
							}}
						/>
					</ListItemSecondaryAction>
				)}
			</ListItem>
			{isMobile && (
				<Stack pb={2} px={2}>
					<OrgConnectionButton
						organization={{
							connectionId,
							connectionState,
							orgId,
							orgName: '',
							entityId: emblem.id,
							entityName: emblem.displayName,
						}}
					/>
				</Stack>
			)}
		</Stack>
	);
};

const OrgNetworkListLoadingSkeleton = () => {
	return (
		<List>
			<ListItem>
				<Skeleton variant="rectangular" width="100%" height="4rem" />
			</ListItem>
			<ListItem>
				<Skeleton variant="rectangular" width="100%" height="4rem" />
			</ListItem>
			<ListItem>
				<Skeleton variant="rectangular" width="100%" height="4rem" />
			</ListItem>
		</List>
	);
};
