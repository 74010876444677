import { Dialog, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { Fragment } from 'react';
import { OrgConnectionItem } from './OrgConnectionItem';
import { Close } from '@mui/icons-material';
import { OrgConnectionInfo } from './hooks';

export const ManageConnectionsModal = ({
	organizations,
	open,
	onClose,
}: {
	organizations: OrgConnectionInfo[];
	open: boolean;
	onClose: () => void;
}) => {
	return (
		<Dialog open={open} onClose={onClose} transitionDuration={0} fullWidth maxWidth="md">
			<DialogTitle>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography variant="h2">Manage Organization Connections</Typography>
					<IconButton onClick={onClose}>
						<Close />
					</IconButton>
				</Stack>
			</DialogTitle>
			<Divider />
			<Stack>
				{organizations.map((org, index) => (
					<Fragment key={org.orgId}>
						<OrgConnectionItem organization={org} />
						{index !== organizations.length - 1 && <Divider variant="middle" />}
					</Fragment>
				))}
			</Stack>
		</Dialog>
	);
};
