import { ApolloCache } from '@apollo/client';
import {
	FileInformation,
	FolderContents,
	FolderInformation,
	FoldersApiEntityType,
	Query,
	QueryGetFileInstancesArgs,
	QueryRootFolderContentsArgs,
	QuerySearchFoldersArgs,
} from 'middleware-types';
import { GET_FILE_INSTANCES } from '../hooks/files/use-file-instances';
import { GET_ROOT_FOLDER_CONTENTS } from '../hooks/folders/use-folder';
import { FOLDER_INFO_FIELDS } from './fragments.graphql';
import { documentSearchDefaultVariables, SEARCH_DOCUMENTS } from '../hooks/use-documents-search';

export const updateFolderContents = (
	{
		cache,
		folderId,
		entityType,
		entityId,
		searchValue,
	}: {
		cache: ApolloCache<any>;
		folderId: string | undefined;
		entityType: FoldersApiEntityType;
		entityId: string;
		searchValue?: string;
	},
	getNewFolderContents: (existing: FolderContents) => FolderContents
) => {
	if (folderId) {
		cache.updateFragment<FolderInformation>(
			{
				id: `FolderInformation:${folderId}`,
				fragment: FOLDER_INFO_FIELDS,
				fragmentName: 'FolderInfoFields',
			},
			(data) => {
				if (!data) return;
				const newFolderContents = getNewFolderContents(data.contents);
				return {
					...data,
					contents: newFolderContents,
				};
			}
		);
	} else {
		cache.updateQuery<Pick<Query, 'rootFolderContents'>, QueryRootFolderContentsArgs>(
			{ query: GET_ROOT_FOLDER_CONTENTS, variables: { entityId, entityType } },
			(data) => {
				if (!data) return;
				const newFolderContents = getNewFolderContents(data.rootFolderContents);
				return {
					rootFolderContents: newFolderContents,
				};
			}
		);
	}
	// if the seaech value was provided to this function, it means we should update the search results
	if (searchValue) {
		cache.updateQuery<Pick<Query, 'searchFolders'>, QuerySearchFoldersArgs>(
			{
				query: SEARCH_DOCUMENTS,
				variables: {
					entityId,
					entityType,
					request: { searchValue, ...documentSearchDefaultVariables },
				},
			},
			(data) => {
				if (!data) return;
				const newFolderContents = getNewFolderContents(data.searchFolders);
				return {
					searchFolders: newFolderContents,
				};
			}
		);
	}
};

export const addNewFileInstance = ({
	cache,
	file,
}: {
	cache: ApolloCache<any>;
	file: FileInformation;
}) => {
	cache.updateQuery<Pick<Query, 'getFileInstances'>, QueryGetFileInstancesArgs>(
		{ query: GET_FILE_INSTANCES, variables: { fileId: file.id } },
		(data) => {
			if (!data) return;
			return {
				getFileInstances: {
					...data.getFileInstances,
					instances: [...data.getFileInstances.instances, file.currentInstance!],
				},
			};
		}
	);
};
