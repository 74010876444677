import { ArrowBack, ForumOutlined } from '@mui/icons-material';
import { Avatar, IconButton, List, Skeleton, Stack, Typography } from '@mui/material';
import { theme } from 'utils/theme';
import { useSavedConversations } from './hooks';
import { SavedConversationListItem } from './savedConversationListItem';

interface SavedConversationsProps {
	getSavedConversation: (conversationId: string) => void;
	onClose: () => void;
	setConversationName: React.Dispatch<React.SetStateAction<string | null>>;
	warnAboutOverwriting: boolean;
}

export const SavedConversations = ({
	getSavedConversation,
	onClose,
	setConversationName,
	warnAboutOverwriting,
}: SavedConversationsProps) => {
	const { savedConversations, loading } = useSavedConversations();

	const selectConversation = (conversationId: string) => {
		getSavedConversation(conversationId);
		onClose();
	};

	return (
		<Stack height="100%" width="100%" position="relative" id="evox-container">
			<Stack
				px={1}
				py={0.5}
				direction="row"
				alignItems="center"
				spacing={1}
				borderBottom={`1px solid ${theme.palette.divider}`}>
				<IconButton onClick={onClose}>
					<ArrowBack />
				</IconButton>
				<Typography variant="h4">Pinned Conversations</Typography>
			</Stack>
			{loading ? (
				<SavedConversationsSkeleton />
			) : savedConversations.length === 0 ? (
				<NoPromptsMessage />
			) : (
				<List>
					{savedConversations.map((conversation) => (
						<SavedConversationListItem
							key={conversation.conversationId}
							conversation={conversation}
							selectConversation={selectConversation}
							setConversationName={setConversationName}
							warnAboutOverwriting={warnAboutOverwriting}
						/>
					))}
				</List>
			)}
		</Stack>
	);
};

const SavedConversationsSkeleton = () => {
	return (
		<Stack p={2} spacing={1}>
			{[...Array(3)].map((_, index) => (
				<Stack key={index} direction="row" spacing={2} alignItems="center">
					<Skeleton variant="circular" height={48} width={48} />
					<Skeleton variant="text" width={200} />
				</Stack>
			))}
		</Stack>
	);
};

const NoPromptsMessage = () => {
	return (
		<Stack
			flex={1}
			justifyContent="center"
			alignItems="center"
			px={8}
			py={2}
			spacing={2}
			overflow="auto">
			<Avatar sx={{ height: 64, width: 64 }}>
				<ForumOutlined fontSize="large" />
			</Avatar>
			<Typography variant="h4" textAlign="center">
				No pinned conversations.
			</Typography>
			<Typography variant="body2" textAlign="center">
				Pin a conversation to be reloaded and resumed later.
			</Typography>
		</Stack>
	);
};
