import { Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import { FileAccessLevel } from 'middleware-types';
import { Fragment, ReactNode, createContext, useContext, useState } from 'react';
import { theme } from 'utils/theme';
import { v4 as uuid4 } from 'uuid';
import { UploadsDrawerRow } from './uploads-drawer-row';

export enum UploadsDrawerItemState {
	Loading,
	Success,
	Error,
}

export interface UploadsDrawerItem {
	key: string;
	file: File;
	accessLevel: FileAccessLevel;
	updatesFileId?: string;
	subtitle: string;
	url: string;
	onCompleted: (uploadToken: string) => Promise<void>;
	state: UploadsDrawerItemState;
}

export type UploadsDrawerRequest = Omit<UploadsDrawerItem, 'key' | 'state'>;

interface UploadsDrawerContextState {
	drawerOpen: boolean;
	uploadFiles: (items: UploadsDrawerRequest | UploadsDrawerRequest[]) => void;
}

const UploadsDrawerContext = createContext<UploadsDrawerContextState | undefined>(undefined);

export const UploadsDrawer = ({ children }: { children: ReactNode }) => {
	const [open, setOpen] = useState(false);
	const [files, setFiles] = useState<UploadsDrawerItem[]>([]);

	const numCompleted = files.filter(
		(file) => file.state === UploadsDrawerItemState.Success
	).length;
	const canClose = !files.some((file) => file.state === UploadsDrawerItemState.Loading);
	const setState = (key: string, state: UploadsDrawerItemState) =>
		setFiles((current) =>
			current.map((file) => {
				if (file.key === key)
					return {
						...file,
						state,
					};
				return file;
			})
		);

	const uploadFiles = (_items: UploadsDrawerRequest | UploadsDrawerRequest[]) => {
		const items = Array.isArray(_items) ? _items : [_items];
		const newFiles: UploadsDrawerItem[] = items.map((item) => ({
			...item,
			key: uuid4(),
			state: UploadsDrawerItemState.Loading,
		}));
		setFiles((currentFiles) => [...newFiles, ...currentFiles]);
		setOpen(true);
	};

	return (
		<UploadsDrawerContext.Provider value={{ drawerOpen: files.length > 0, uploadFiles }}>
			{children}
			{files.length > 0 && (
				<Stack
					position="fixed"
					overflow="hidden"
					bottom={0}
					right={{ xs: 0, sm: 12 }}
					width={{ xs: '100vw', sm: 600 }}
					borderRadius={2}
					sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
					component={Paper}
					zIndex={theme.zIndex.drawer}>
					<Stack direction="row" alignItems="center" bgcolor="neutral.50" px={2} py={1}>
						<Box flex={1}>
							<Typography variant="h3" display="inline">
								Uploads{' '}
							</Typography>
							<Typography variant="subtitle1" display="inline">
								({numCompleted} of {files.length} completed)
							</Typography>
						</Box>
						<IconButton onClick={() => setOpen(!open)}>
							{open ? <ExpandMore /> : <ExpandLess />}
						</IconButton>
						{canClose && (
							<IconButton onClick={() => setFiles([])}>
								<Close />
							</IconButton>
						)}
					</Stack>
					<Box
						maxHeight="50vh"
						overflow="auto"
						borderTop="1px solid"
						borderColor="divider"
						display={open ? 'block' : 'none'}>
						{files.map((file, index) => (
							<Fragment key={file.key}>
								{index !== 0 && <Divider />}
								<UploadsDrawerRow
									item={file}
									setState={(state: UploadsDrawerItemState) =>
										setState(file.key, state)
									}
								/>
							</Fragment>
						))}
					</Box>
				</Stack>
			)}
		</UploadsDrawerContext.Provider>
	);
};

export const useUploadsDrawer = () => {
	const value = useContext(UploadsDrawerContext);
	if (value === undefined)
		throw new Error('useUploadsDrawerContext must be used inside a UploadsDrawer component');
	return value;
};
