import { LoadingButton } from '@mui/lab';
import { TextField } from 'components/ui/fields';
import { ModalActionButton, ModalActions, ModalContent, useModal } from 'components/ui/modal';
import { Formik, FormikProps } from 'formik';
import { Conversation, UpdateConversationRequest } from 'middleware-types';
import { useValidation } from 'utils/useValidation';
import { useUpdateConversation } from '../hooks/conversations-hooks';

interface UpdateConversationModalProps {
	conversation: Conversation;
}

export const UpdateConversationModal = ({ conversation }: UpdateConversationModalProps) => {
	const { closeModal } = useModal();
	const updateConversation = useUpdateConversation();

	const initialValues: UpdateConversationRequest = {
		subject: conversation.subject,
	};

	const onSubmit = async (values: UpdateConversationRequest) => {
		const success = await updateConversation(values);
		if (success) closeModal();
	};

	const validation = useValidation('UpdateConversationRequest');

	return (
		<Formik<UpdateConversationRequest>
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validation.schema}
			enableReinitialize>
			{(props: FormikProps<UpdateConversationRequest>) => (
				<>
					<ModalContent>
						<TextField
							name="subject"
							label="Conversation Name"
							helperText="This will change the conversation name for all participants."
							required
						/>
					</ModalContent>
					<ModalActions>
						<ModalActionButton variant="outlined">Close</ModalActionButton>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							loading={props.isSubmitting}
							disabled={!props.isValid || !props.dirty || props.isSubmitting}
							onClick={() => {
								props.submitForm();
							}}>
							Save
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};
