import { LocationAddress } from 'middleware-types';
import {
	AccessTimeFilled,
	DeleteOutlined,
	EditOutlined,
	Star,
	StarOutline,
} from '@mui/icons-material';
import {
	Card,
	CardActions,
	CardContent,
	CardHeader,
	IconButton,
	Stack,
	Tooltip,
} from '@mui/material';
import { AddressText } from 'components/ui/fields';
import { format } from 'date-fns';
import { zoneDateOnly } from 'utils/utils';
import { AddressType } from './locations-form';

/**
 * LocationInformationItem - Each location address card item is rendered here
 * It maintains it's own delete, edit and make primary modals.
 *
 * @param {{
 * 	userId: string;
 * 	locationAddress: LocationAddress,
 * 	disabled?: boolean;
 * }} { userId, locationAddress, disabled }
 * @return {*}
 */
export const LocationInformationItem = ({
	labels,
	locationAddress,
	disabled,
	onDelete,
	onMakePrimary,
	onEdit,
}: {
	labels?: Record<string, string>;
	locationAddress: Omit<LocationAddress, 'parentId'>;
	disabled?: boolean;
	onDelete: (locationAddressId: string) => void;
	onMakePrimary: (locationAddress: Omit<LocationAddress, 'parentId'>) => void;
	onEdit: (locationAddress: Omit<LocationAddress, 'parentId'>) => void;
}) => {
	const labelText: Record<string, string> = {
		[AddressType.Primary]: AddressType.Primary,
		[AddressType.Alternate]: AddressType.Alternate,
		[AddressType.Temporary]: AddressType.Temporary,
		...labels,
	};
	return (
		<Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column' }}>
			<CardHeader
				sx={{ paddingBottom: 0.5 }}
				title={
					<Stack direction="row" justifyContent="space-between">
						<span>{labelText[locationAddress.addressType]} Address</span>
						{locationAddress.addressType === AddressType.Primary && (
							<Tooltip title="Primary Location">
								<Star fontSize="small" color="primary" />
							</Tooltip>
						)}
					</Stack>
				}
				titleTypographyProps={{
					variant: 'h5',
				}}
				subheader={
					locationAddress.fromDate && locationAddress.toDate ? (
						<Stack direction="row" alignItems="center" spacing={1}>
							<AccessTimeFilled fontSize="small" color="warning" />
							<span>
								{format(zoneDateOnly(locationAddress.fromDate), 'MMM. d, yyyy')} -{' '}
								{format(zoneDateOnly(locationAddress.toDate), 'MMM. d, yyyy')}
							</span>
						</Stack>
					) : undefined
				}
				subheaderTypographyProps={{
					variant: 'h6',
					sx: {
						color: 'warning[700]',
					},
				}}
			/>
			<CardContent sx={{ flexGrow: 1 }}>
				<Stack>
					<AddressText {...locationAddress.address} />
				</Stack>
			</CardContent>
			{disabled !== false && (
				<CardActions disableSpacing>
					<IconButton onClick={() => onEdit(locationAddress)}>
						<EditOutlined />
					</IconButton>
					{locationAddress.addressType != AddressType.Primary && (
						<>
							<IconButton onClick={() => onDelete(locationAddress.id)}>
								<DeleteOutlined />
							</IconButton>
							{locationAddress.addressType !== AddressType.Temporary && (
								<IconButton onClick={() => onMakePrimary(locationAddress)}>
									<StarOutline />
								</IconButton>
							)}
						</>
					)}
				</CardActions>
			)}
		</Card>
	);
};
