import { LoadingButton } from '@mui/lab';
import { ListItemText, MenuItem, Stack } from '@mui/material';
import { TextField } from 'components/ui/fields';
import { ColorField } from 'components/ui/fields/colorField';
import { ModalActionButton, ModalActions, ModalContent, useModal } from 'components/ui/modal';
import { Formik, FormikProps } from 'formik';
import { LabelCreateRequest } from 'middleware-types';
import { useValidation } from 'utils/useValidation';
import { useCommunicationsContext } from '../communications-provider';
import { LabelWithColor, labelColorOptions } from '../helpers/label-colors';
import { getCustomLabels } from '../helpers/labels';
import { useCreateLabel, useUpdateLabel } from '../hooks/labelsHooks';
import { LabelIcon } from '../shared/label-icon';

interface CreateUpdateLabelModalProps {
	label?: LabelWithColor;
}

export const CreateUpdateLabelModal = ({ label }: CreateUpdateLabelModalProps) => {
	const { closeModal } = useModal();
	const createLabel = useCreateLabel();
	const updateLabel = useUpdateLabel();

	const { labels } = useCommunicationsContext();
	const customLabels = getCustomLabels(labels);

	const initialValues: LabelCreateRequest = {
		name: label?.name ?? '',
		colorName: label?.colorName ?? '',
		parentLabelId: '',
	};

	const onSubmit = async (values: LabelCreateRequest) => {
		let success = false;
		if (label === undefined) {
			success = await createLabel(values);
		} else {
			const { parentLabelId: _parentLabelId, ...updateValues } = values;
			success = await updateLabel(label.id, updateValues);
		}
		if (success) closeModal();
	};

	const validation = useValidation('LabelCreateRequest');

	return (
		<Formik<LabelCreateRequest>
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validation.schema}
			enableReinitialize>
			{(props: FormikProps<LabelCreateRequest>) => (
				<>
					<ModalContent>
						<Stack spacing={2}>
							<TextField name="name" label="Label Name" required />
							<ColorField
								label="Label Color"
								name="colorName"
								required
								options={labelColorOptions.map((option) => ({
									name: option.name,
									hex: option.color[500],
								}))}
								getValueFromOption={(option) => option.name}
							/>
							{label === undefined && (
								<TextField select label="Parent Label" name="parentLabelId">
									{customLabels.length > 0 ? (
										[
											<MenuItem key="" value="">
												<em>None</em>
											</MenuItem>,
											...customLabels.map((option) => (
												<MenuItem
													key={option.label.id}
													value={option.label.id}
													sx={{ pl: 2 + option.path.length * 3 }}>
													<Stack
														direction="row"
														spacing={1.5}
														alignItems="center">
														<LabelIcon label={option.label} />
														<ListItemText primary={option.label.name} />
													</Stack>
												</MenuItem>
											)),
										]
									) : (
										<MenuItem disabled>No options</MenuItem>
									)}
								</TextField>
							)}
						</Stack>
					</ModalContent>
					<ModalActions>
						<ModalActionButton variant="outlined">Close</ModalActionButton>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							loading={props.isSubmitting}
							disabled={!props.isValid || !props.dirty || props.isSubmitting}
							onClick={() => {
								props.submitForm();
							}}>
							Save
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};
