import {
	Delete,
	DeleteOutlined,
	Forum,
	ForumOutlined,
	Inbox,
	InboxOutlined,
	Label as MUILabelIcon,
} from '@mui/icons-material';
import { LabelType } from 'middleware-types';
import { LabelWithColor } from '../helpers/label-colors';

interface LabelIconProps {
	label: LabelWithColor;
	selected?: boolean;
}

export const LabelIcon = ({ label, selected = false }: LabelIconProps) => {
	switch (label.type) {
		case LabelType.All:
			return selected ? (
				<Forum color="primary" />
			) : (
				<ForumOutlined sx={{ color: 'neutral.500' }} />
			);
		case LabelType.Inbox:
			return selected ? (
				<Inbox color="primary" />
			) : (
				<InboxOutlined sx={{ color: 'neutral.500' }} />
			);
		case LabelType.Trash:
			return selected ? (
				<Delete color="primary" />
			) : (
				<DeleteOutlined sx={{ color: 'neutral.500' }} />
			);
		case LabelType.Custom:
			return <MUILabelIcon htmlColor={label.color[500]} />;
	}
};
