import { ReactNode } from 'react';
import { getAccessToken } from 'utils/session';
import { useSession } from 'utils/session';
import { Link as MuiLink } from '@mui/material';

interface LegacyLinkType {
	children: ReactNode;
	systemUrl?: string;
	admin?: boolean;
	to?: string;
}

export const LegacyLink = ({ children, systemUrl, admin, to }: LegacyLinkType) => {
	const { user } = useSession();

	async function postLegacyAuth(systemUrl?: string) {
		const accessToken = await getAccessToken();
		if (typeof accessToken !== 'string') return;
		const form = document.createElement('form');
		form.method = 'POST';
		form.action = `${systemUrl}/evolveLogin.asp`;
		if (admin) {
			form.action = `${systemUrl}/../ORI-FILETRAC-DDRT/evolveAdminLogin.asp`;
		}
		if (to) {
			form.action += `?url=${to}`;
		}

		const accessTokenField = document.createElement('input');
		accessTokenField.type = 'hidden';
		accessTokenField.name = 'access_token';
		accessTokenField.value = accessToken;
		form.appendChild(accessTokenField);

		const userIdField = document.createElement('input');
		userIdField.type = 'hidden';
		userIdField.name = 'user_id';
		userIdField.value = user.userId;
		form.appendChild(userIdField);

		document.body.appendChild(form);
		form.submit();
	}

	if (systemUrl) {
		return (
			<MuiLink
				data-test="system-url"
				underline="none"
				onClick={() => postLegacyAuth(systemUrl)}>
				{children}
			</MuiLink>
		);
	}

	return <>{children}</>;
};
