import { gql, useMutation } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	FolderReference,
	Mutation,
	MutationFolderUpdateArgs,
	UpsertFolderRequest,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useDocumentsContext } from '../../documents-page';
import { FOLDER_REFERENCE_FIELDS } from '../../utils/fragments.graphql';

const UPDATE_FOLDER = gql`
	mutation UpdateFolder(
		$entityType: FoldersApiEntityType!
		$entityId: String!
		$request: UpsertFolderRequest!
		$folderId: String!
	) {
		folderUpdate(
			entityType: $entityType
			entityId: $entityId
			request: $request
			folderId: $folderId
		) {
			id
			name
		}
	}
`;

export const useUpdateFolder = () => {
	const toast = useToast();
	const { entityType, entityId } = useDocumentsContext();

	const [_updateFolder] = useMutation<Pick<Mutation, 'folderUpdate'>, MutationFolderUpdateArgs>(
		UPDATE_FOLDER
	);

	const updateFolder = async (folderId: string, request: UpsertFolderRequest) => {
		return await _updateFolder({
			variables: { entityType, entityId, request, folderId },
			update: (cache, { data }) => {
				if (!data) return;
				cache.updateFragment<FolderReference>(
					{
						id: `FolderReference:${data.folderUpdate.id}`,
						fragment: FOLDER_REFERENCE_FIELDS,
					},
					(existing) => {
						if (!existing) return;
						return {
							...existing,
							name: data.folderUpdate.name,
						};
					}
				);
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Folder renamed successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return updateFolder;
};
