import { EditOutlined, PasswordOutlined } from '@mui/icons-material';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { useModal } from 'components/ui/modal';
import { ChangePasswordModal } from './change-password-modal';

interface PasswordSectionProps {
	canEdit: boolean;
}

export const PasswordSection = ({ canEdit }: PasswordSectionProps) => {
	const { showModal } = useModal();
	return (
		<Grid container item xs={12} alignItems="center">
			<Grid item xs={12} sm={4}>
				<Stack direction="row" spacing={{ xs: 2, sm: 3 }}>
					<PasswordOutlined fontSize="small" />
					<Typography variant="h5">Password</Typography>
				</Stack>
			</Grid>
			<Grid item xs>
				<Typography>********</Typography>
			</Grid>
			{canEdit && (
				<Grid item xs="auto">
					<IconButton
						onClick={() =>
							showModal({
								title: 'Change Password',
								content: <ChangePasswordModal />,
							})
						}>
						<EditOutlined />
					</IconButton>
				</Grid>
			)}
		</Grid>
	);
};
