import { Chip } from '@mui/material';
import { LabelWithPathInfo } from '../helpers/labels';
import { useRemoveLabel } from '../hooks/conversation-labels-hooks';
import { useCommunicationsContext } from '../communications-provider';
import { useIsMobile } from 'utils/useScreenSize';

interface LabelChipProps {
	label: LabelWithPathInfo;
}

export const LabelChip = ({ label }: LabelChipProps) => {
	const isMobile = useIsMobile();
	const { removeLabel, loading } = useRemoveLabel();
	const { readOnly, setSelectedLabelId, setSelectedConversationId } = useCommunicationsContext();

	const fullPath = [...label.path, label.label.name];
	const color = label.label.color;

	const onClick = () => {
		setSelectedLabelId(label.label.id);
		if (isMobile) setSelectedConversationId(null);
	};

	return (
		<Chip
			size="small"
			label={fullPath.join('/')}
			onClick={onClick}
			onDelete={readOnly ? undefined : () => removeLabel(label.label.id)}
			disabled={loading}
			sx={{
				'color': color[700],
				'borderColor': color[700],
				'backgroundColor': color[50],
				'& .MuiChip-deleteIcon': {
					color: color[700],
				},
			}}
		/>
	);
};
