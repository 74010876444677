import { ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, FormControlLabel, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { CheckboxField, TextField } from 'components/ui/fields';
import { Formik, FormikProps } from 'formik';
import { CustomInstructionsInput } from 'middleware-types';
import { theme } from 'utils/theme';
import { useValidation } from 'utils/useValidation';
import { useChatInstructions } from './hooks';
import * as Yup from 'yup';

interface CustomInstructionsProps {
	onClose: () => void;
}

export const CustomInstructions = ({ onClose }: CustomInstructionsProps) => {
	const { chatInstructions, loading, updateChatInstructions } = useChatInstructions();

	const onSubmit = async (input: CustomInstructionsInput) => {
		const success = await updateChatInstructions(input);
		if (success) onClose();
	};

	const defaultValues: CustomInstructionsInput = {
		enableCustomInstructions: false,
		about: '',
		responseTargeting: '',
	};

	const baseValidation = useValidation('CustomInstructions');
	const customValidator = Yup.object().shape({
		about: Yup.string().test('about-not-empty', 'Required field', function (value) {
			const { enableCustomInstructions } = this.parent;
			if (enableCustomInstructions) return value != null && value != '';
			return true;
		}),
		responseTargeting: Yup.string().test(
			'response-targeting-not-empty',
			'Required field',
			function (value) {
				const { enableCustomInstructions } = this.parent;
				if (enableCustomInstructions) return value != null && value != '';
				return true;
			}
		),
	});

	return (
		<Stack height="100%" width="100%">
			<Stack
				px={1}
				py={0.5}
				direction="row"
				alignItems="center"
				spacing={1}
				borderBottom={`1px solid ${theme.palette.divider}`}>
				<IconButton onClick={onClose}>
					<ArrowBack />
				</IconButton>
				<Typography variant="h4">Add Custom Instructions</Typography>
			</Stack>
			{loading || baseValidation.loading ? (
				<CustomInstructionsSkeleton />
			) : (
				<Formik<CustomInstructionsInput>
					onSubmit={onSubmit}
					initialValues={
						chatInstructions?.about != null ||
						chatInstructions?.responseTargeting != null
							? chatInstructions
							: defaultValues
					}
					validationSchema={
						baseValidation.schema
							? baseValidation.schema.concat(customValidator)
							: customValidator
					}
					enableReinitialize>
					{(props: FormikProps<CustomInstructionsInput>) => (
						<Stack flex={1} overflow="auto">
							<Stack spacing={4} p={2} flex={1} overflow="auto">
								<FormControlLabel
									control={<CheckboxField name="enableCustomInstructions" />}
									label="Use Custom Instructions"
								/>
								<TextField
									name="about"
									label="What would you like EVO-X to know about you to provide better responses?"
									placeholder="e.g. Where are you based? What do you do for work?"
									multiline
									minRows={3}
									disabled={!props.values.enableCustomInstructions}
								/>
								<TextField
									name="responseTargeting"
									label="How would you like EVO-X to respond?"
									placeholder="e.g. How formal or casual should EVO-X be? How long or short should responses be? How do you want to be addressed? Should EVO-X have opinions on topics or remain neutral?"
									multiline
									minRows={3}
									disabled={!props.values.enableCustomInstructions}
								/>
							</Stack>
							<Stack direction="row" spacing={1} p={2} justifyContent="flex-end">
								<LoadingButton
									loading={props.isSubmitting}
									variant="outlined"
									color="primary"
									onClick={onClose}>
									Cancel
								</LoadingButton>
								<LoadingButton
									loading={props.isSubmitting}
									variant="contained"
									color="primary"
									disabled={!props.dirty || props.isSubmitting || !props.isValid}
									onClick={() => {
										props.submitForm();
									}}>
									Save
								</LoadingButton>
							</Stack>
						</Stack>
					)}
				</Formik>
			)}
		</Stack>
	);
};

const CustomInstructionsSkeleton = () => {
	return (
		<Stack spacing={2} p={2} flex={1}>
			<Skeleton variant="rounded" sx={{ flex: 1 }} />
			<Skeleton variant="rounded" sx={{ flex: 1 }} />
			<Box height={60} />
		</Stack>
	);
};
