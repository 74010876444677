import { History } from '@mui/icons-material';
import { Box, Dialog, IconButton, Stack, TableCell, TableRow, Tooltip } from '@mui/material';
import { EmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { format } from 'date-fns';
import { filesize } from 'filesize';
import { FileInstanceInformation } from 'middleware-types';
import { useState } from 'react';
import { ExtensionIcon, VirusStatusChip, useDownloadFileInstance } from 'utils/fileUtils';
import { AccessLevelChip } from '../components/access-level';
import { FileMenuButton } from '../components/file-menu-button';
import { FolderLink } from '../components/folder-link';
import { useVersionHistoryContext } from '../components/version-history-provider';
import { VersionHistoryModal } from '../modals/version-history-modal';

interface FileRowProps {
	file: FileInstanceInformation;
}

export const FileRow = ({ file }: FileRowProps) => {
	const dateString = format(new Date(file.uploadedUtc), 'M/d/yy h:mm a');
	const downloadFileInstance = useDownloadFileInstance();
	const { isVersionHistory } = useVersionHistoryContext();
	const [versionHistoryOpen, setVersionHistoryOpen] = useState(false);

	return (
		<>
			<TableRow hover onClick={() => downloadFileInstance(file, true)}>
				<TableCell size="small">
					<Stack direction="row" alignItems="center" gap={1.5}>
						<ExtensionIcon filename={file.fileName} sx={{ color: 'neutral.500' }} />
						<Stack>
							{file.fileName}
							<FolderLink folder={file.folder} />
						</Stack>
						{isVersionHistory ? (
							<Box ml={-1} color="neutral.500">
								(v{file.versionNumber})
							</Box>
						) : (
							<Tooltip title={`Version ${file.versionNumber}`}>
								<IconButton
									sx={{ ml: -1, my: -2 }}
									onClick={(e) => {
										e.stopPropagation();
										setVersionHistoryOpen(true);
									}}
									onMouseDown={(e) => e.stopPropagation()}>
									<History sx={{ fontSize: 20 }} />
								</IconButton>
							</Tooltip>
						)}
					</Stack>
				</TableCell>
				<TableCell>{dateString}</TableCell>
				<TableCell>
					<Stack direction="row" alignItems="center" gap={1.5}>
						<EmblemAvatar emblem={file.uploadedBy} size={24} />
						{file.uploadedBy?.displayName}
					</Stack>
				</TableCell>
				<TableCell>
					<AccessLevelChip level={file.accessLevel} />
				</TableCell>
				<TableCell>
					<VirusStatusChip virusStatus={file.virusStatus} />
				</TableCell>
				<TableCell align="right">{filesize(file.fileSize)}</TableCell>
				<TableCell padding="checkbox">
					<FileMenuButton file={file} />
				</TableCell>
			</TableRow>
			{/** version history modal */}
			<Dialog
				open={versionHistoryOpen}
				fullWidth
				maxWidth="xxl"
				// this is to stop file drop events from propagating to the main table
				slotProps={{
					root: {
						onDragEnter: (e) => e.stopPropagation(),
						onDragOver: (e) => e.stopPropagation(),
						onDragLeave: (e) => e.stopPropagation(),
					},
				}}>
				<VersionHistoryModal file={file} onClose={() => setVersionHistoryOpen(false)} />
			</Dialog>
		</>
	);
};
