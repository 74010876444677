import { GridView, TableRowsOutlined } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export enum FileDisplay {
	List = 'List',
	Grid = 'Grid',
}

interface FileDisplayToggleProps {
	value: FileDisplay;
	setValue: (value: FileDisplay) => void;
}

export const FileDisplayToggle = ({ value, setValue }: FileDisplayToggleProps) => {
	return (
		<ToggleButtonGroup
			size="small"
			color="primary"
			exclusive
			value={value}
			onChange={(_e, newValue) => {
				if (newValue !== null) setValue(newValue);
			}}>
			<ToggleButton value={FileDisplay.List}>
				<TableRowsOutlined />
			</ToggleButton>
			<ToggleButton value={FileDisplay.Grid}>
				<GridView />
			</ToggleButton>
		</ToggleButtonGroup>
	);
};
