import { LightShell } from 'components/ui/shell';
import { logout } from 'utils/session';
import { Button, Typography } from '@mui/material';
import { Container, Stack } from '@mui/system';
import { useRouteError } from 'react-router';

export const ErrorPage = () => {
	const error: any = useRouteError();
	console.error(error);
	return (
		<LightShell right={<Button onClick={logout}>Logout</Button>}>
			<Container maxWidth="xs">
				<Stack justifyContent="center" alignItems="center" textAlign="center" spacing={2}>
					<img src="/img/icon-error.svg" alt="ErrorLogo" />
					<Typography variant="h1">Something went wrong.</Typography>
					<Typography variant="body1">
						An error occurred. Please try again later or contact Support.
					</Typography>
					<Typography fontSize="0.75rem">{error.statusText || error.message}</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={() => (window.location.href = '/')}>
						Return to the Dashboard
					</Button>
				</Stack>
			</Container>
		</LightShell>
	);
};
