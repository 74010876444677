import React, { forwardRef, useEffect } from 'react';
import { Field as FField, FieldAttributes, useFormikContext } from 'formik';
import { TextFieldProps, CheckboxProps, SwitchProps, MenuItem } from '@mui/material';
import { TextField as ConnectedTextField, Checkbox as ConnectedCheckbox, Switch } from 'formik-mui';
import { PhoneNumberField, PhoneNumberText } from 'components/ui/fields/phone';
import { AddressField, AddressFieldSkeleton, AddressText } from 'components/ui/fields/address';
// import { MaskedTextField } from 'components/Fields/MaskedTextField';
// import TreeItemField from './Tree/TreeItemField';
// import TreeField from './Tree/TreeField';
import { FileField } from './file';
import { ButtonGroupField, ButtonGroupItem } from './button-group';
import { NumberField } from './number';
import { DatePickerField } from './datepicker';

export type FieldConfigProps = FieldAttributes<any> & {
	required?: boolean | undefined;
};

/**
 * Wraps the formik field to add required validation checking.
 *
 * @param {FieldConfigProps} props
 * @returns
 */
const Field = forwardRef((props: FieldConfigProps, ref) => {
	const formik = useFormikContext();
	useEffect(() => {
		formik.validateField(props.name);
	}, [props.required]);

	return (
		<FField
			validate={(v: any) => {
				if (props?.required && (!v || v === '')) return 'Required Field';
			}}
			inputRef={ref}
			{...props}
		/>
	);
});

Field.displayName = 'Field';
export { Field };

/**
 * Reuseable TextField with material styles and Formik bindings.
 *
 * @param {FieldAttributes<TextFieldProps>} props
 * @returns TextField component
 */
const TextField = forwardRef((props: FieldAttributes<TextFieldProps>, ref) => {
	return <Field ref={ref} {...props} component={ConnectedTextField} />;
});

TextField.displayName = 'TextField';
export { TextField };

/**
 * Reuseable Checkbox with material styles and Formik bindings.
 *
 * @param {FieldAttributes<CheckboxProps>} props
 * @returns Checkbox component
 */
export const CheckboxField = (props: FieldAttributes<CheckboxProps>): React.ReactElement => {
	return <Field {...props} type="checkbox" component={ConnectedCheckbox} />;
};

/**
 * Reuseable Switch field with material styles and Formik bindings.
 *
 * @param {FieldAttributes<SwitchProps>} props
 * @returns Switch component
 */
export const SwitchField = (props: FieldAttributes<SwitchProps>): React.ReactElement => {
	return <Field {...props} type="checkbox" component={Switch} />;
};

/**
 * Reuseable Native Select wrapper with builtin formik support and validation.
 *
 * @param {*} props
 * @returns
 */
export const SelectField = ({
	children,
	...props
}: FieldAttributes<TextFieldProps>): React.JSX.Element => {
	return (
		<TextField select={true} {...props}>
			{!props.required && !props.SelectProps?.multiple && <MenuItem value="" />}
			{children}
		</TextField>
	);
};

/*
	Export out all of our custom fields.
*/
export {
	PhoneNumberField,
	PhoneNumberText,
	AddressField,
	AddressText,
	AddressFieldSkeleton,
	DatePickerField,
	ButtonGroupField,
	ButtonGroupItem,
	FileField,
	NumberField,
	// MaskedTextField,
	// TreeField,
	// TreeItemField,
};
