import { Stack } from '@mui/material';
import { InfiniteScroll } from 'components/ui/infinite-scroll';
import { Message } from 'middleware-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ConversationMessage } from './conversation-message';

const PAGE_SIZE = 20;

interface MessagesListProps {
	messages: Message[];
}

export const MessagesList = ({ messages }: MessagesListProps) => {
	const [numMessagesShown, setNumMessagesShown] = useState(PAGE_SIZE);
	const messagesToShow = messages.slice(numMessagesShown * -1);
	const loadMore = useCallback(() => setNumMessagesShown((current) => current + PAGE_SIZE), []);

	// scroll to bottom of messages when we get a new one
	const messageAreaRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (!messageAreaRef.current) return;
		messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
	}, [messages.length]);

	// this will disable the infinite scroll on the first render,
	// to avoid loading more before the first messages have rendered
	const [firstRenderDone, setFirstRenderDone] = useState(false);
	useEffect(() => {
		setFirstRenderDone(true);
	}, []);

	return (
		<InfiniteScroll
			ref={messageAreaRef}
			reverse
			threshold={100}
			loadMore={loadMore}
			canLoadMore={numMessagesShown < messages.length && firstRenderDone}
			flex={1}
			bgcolor="neutral.50">
			<Stack spacing={1.5} py={1.5} flex={1}>
				{messagesToShow.map((message) => (
					<ConversationMessage key={message.id} message={message} />
				))}
			</Stack>
		</InfiniteScroll>
	);
};
