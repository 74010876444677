import { Box, Stack, Typography } from '@mui/material';
import { OrgEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { ConnectionState } from 'middleware-types';
import { ConnectionStateExtended } from '../../shared/types';
import { OrgConnectionButton } from './OrgConnectionButton';
import { OrgConnectionInfo } from './hooks';

export const OrgConnectionItem = ({ organization }: { organization: OrgConnectionInfo }) => {
	let connectionStateText = '';
	switch (organization.connectionState) {
		case ConnectionState.Connected:
			connectionStateText = 'is connected to';
			break;
		case ConnectionState.Pending:
			connectionStateText = 'has a pending request from';
			break;
		case ConnectionStateExtended.IncomingRequest:
			connectionStateText = 'has requested to connect with';
			break;
		case ConnectionState.Ignored:
		case ConnectionStateExtended.NotConnected:
			connectionStateText = 'is not connected to';
			break;
	}

	return (
		<Stack direction="row" spacing={2} p={2} alignItems="center">
			<OrgEmblemAvatar id={organization.orgId} />
			<Box flex={1}>
				<Typography variant="h4" color="inherit">
					{organization.orgName}
				</Typography>
				<Typography color="inherit">
					{organization.entityName} {connectionStateText} {organization.orgName}.
				</Typography>
			</Box>
			<OrgConnectionButton organization={organization} />
		</Stack>
	);
};
