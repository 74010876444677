import { LoadingButton } from '@mui/lab';
import {
	AutocompleteRenderInputParams,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Grid,
} from '@mui/material';
import { Alert } from 'components/ui/alert';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { CheckboxField, PhoneNumberField, TextField } from 'components/ui/fields';
import { ModalActionButton, ModalActions, ModalContent, useModal } from 'components/ui/modal';
import { useToast } from 'components/ui/toast';
import { ErrorMessage, Field, Formik, FormikProps } from 'formik';
import { Autocomplete } from 'formik-mui';
import * as Yup from 'yup';
import { useOrgRepresentativeUpsertMutation } from './hooks';
import { useValidation } from 'utils/useValidation';
import { useDefaults } from 'utils/useDefaults';
import { Representative, RepresentativeType, RepresentativeUpdate } from 'middleware-types';

const OrgRepresentativeJobTitles = [
	'Attorney/Advisor',
	'CEO',
	'Claims Executive',
	'Claims Manager',
	'Company Representative',
	'COO',
	'CTO',
	'Financial Manager',
	'IT Manager',
	'Managing Partner',
	'Operations Manager',
	'Owner',
	'President',
];

/**
 * useOrgRepresentativeForm() - Hook for initializing and submitting the org representative form
 *
 * @param {string} orgId
 * @param {OrgRepresentative} [rep]
 * @return {*}
 */
const useOrgRepresentativeForm = (orgId: string, rep?: Representative) => {
	const { orgRepresentativeUpsert, error } = useOrgRepresentativeUpsertMutation();
	const { closeModal } = useModal();
	const defaults = useDefaults();
	const baseValidation = useValidation('RepresentativeUpdate');
	const toast = useToast();

	const initialValues: RepresentativeUpdate = {
		name: {
			firstName: rep?.name.firstName ?? '',
			middleName: rep?.name.middleName ?? '',
			lastName: rep?.name.lastName ?? '',
			suffix: rep?.name.suffix ?? '',
		},
		representativeType: rep?.representativeType ?? [],
		title: rep?.title ?? '',
		emailAddress: rep?.emailAddress ?? '',
		cellPhoneNumber: rep?.cellPhoneNumber ?? {
			countryCode: defaults.country.phonePrefix ?? '1',
			number: '',
		},
		workPhoneNumber: {
			countryCode: rep?.workPhoneNumber?.countryCode ?? '',
			number: rep?.workPhoneNumber?.number ?? '',
		},
	};

	const customValidator = Yup.object().shape({
		representativeType: Yup.array().min(1, 'Please select at least one representative type'),
	});

	const onSubmit = (values: RepresentativeUpdate) =>
		orgRepresentativeUpsert(
			orgId,
			{
				...values,
				workPhoneNumber: values?.workPhoneNumber?.number
					? values.workPhoneNumber
					: undefined,
			},
			rep?.id
		)
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				closeModal();
				toast.push('Representative Information updated successfully.', {
					variant: 'success',
				});
			})
			.catch(() => handleNoResponse({ toast }));

	return {
		initialValues,
		onSubmit,
		validationSchema: baseValidation.schema
			? baseValidation.schema.concat(customValidator)
			: customValidator,
		error,
	};
};

type OrgRepresentativeFormProps = {
	orgId: string;
	rep?: Representative;
};

/**
 * OrgRepresentativeForm - Form for adding or editing an org representative
 *
 * @param {OrgRepresentativeFormProps} { rep, orgId }
 * @return {*}
 */
export const OrgRepresentativeForm = ({ rep, orgId }: OrgRepresentativeFormProps) => {
	const { initialValues, onSubmit, validationSchema, error } = useOrgRepresentativeForm(
		orgId,
		rep
	);

	return (
		<Formik<RepresentativeUpdate>
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validationSchema}>
			{(props: FormikProps<RepresentativeUpdate>) => (
				<>
					<ModalContent>
						<Alert error={error} />
						<Grid container spacing={1} columnSpacing={2}>
							<Grid item xs={12}>
								<FormLabel>Representative Type</FormLabel>
								<FormGroup row>
									<FormControlLabel
										control={
											<CheckboxField
												name="representativeType"
												value={RepresentativeType.Primary}
												disabled={props.isSubmitting}
											/>
										}
										label="Primary"
									/>
									<FormControlLabel
										control={
											<CheckboxField
												name="representativeType"
												value={RepresentativeType.Technical}
												disabled={props.isSubmitting}
											/>
										}
										label="Technical"
									/>
									<FormControlLabel
										control={
											<CheckboxField
												name="representativeType"
												value={RepresentativeType.Billing}
												disabled={props.isSubmitting}
											/>
										}
										label="Billing"
									/>
									<FormControlLabel
										control={
											<CheckboxField
												name="representativeType"
												value={RepresentativeType.Operational}
												disabled={props.isSubmitting}
											/>
										}
										label="Operational"
									/>
								</FormGroup>
								<FormHelperText
									component="span"
									error
									sx={{ right: 'auto', bottom: '-1rem', marginLeft: 0 }}>
									<ErrorMessage name="representativeType" />
								</FormHelperText>
							</Grid>
							<Grid xs={12} md={3} item>
								<TextField label="First Name" name="name.firstName" required />
							</Grid>
							<Grid xs={12} md={3} item>
								<TextField label="Middle Name" name="name.middleName" />
							</Grid>
							<Grid xs={12} md={4} item>
								<TextField label="Last Name" required name="name.lastName" />
							</Grid>
							<Grid xs={12} md={2} item>
								<TextField label="Suffix" name="name.suffix" />
							</Grid>
							<Grid xs={12} md={6} item>
								<Field
									name="title"
									component={Autocomplete}
									options={OrgRepresentativeJobTitles}
									freeSolo
									disableClearable
									renderInput={(params: AutocompleteRenderInputParams) => (
										<TextField
											{...params}
											name="title"
											label="Title"
											variant="outlined"
											required
										/>
									)}
								/>
							</Grid>
							<Grid xs={12} md={6} item>
								<TextField label="Email Address" name="emailAddress" required />
							</Grid>
							<Grid xs={12} md={6} item>
								<PhoneNumberField
									label="Cell Phone Number"
									required
									name="cellPhoneNumber"
								/>
							</Grid>
							<Grid xs={12} md={6} item>
								<PhoneNumberField
									label="Work Phone Number"
									name="workPhoneNumber"
								/>
							</Grid>
						</Grid>
					</ModalContent>
					<ModalActions>
						<Grid container justifyContent="flex-end" spacing={1}>
							<Grid item>
								<ModalActionButton variant="outlined">Cancel</ModalActionButton>
							</Grid>
							<Grid item>
								<LoadingButton
									variant="contained"
									color="primary"
									onClick={props.submitForm}
									disabled={!props.isValid || !props.dirty || props.isSubmitting}
									loading={props.isSubmitting}>
									Save
								</LoadingButton>
							</Grid>
						</Grid>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};
