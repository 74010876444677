import {
	Check,
	Close,
	EmailOutlined,
	InfoOutlined,
	NotificationsNoneOutlined,
	RefreshOutlined,
	SendOutlined,
	ViewHeadline,
} from '@mui/icons-material';
import {
	Avatar,
	Box,
	Divider,
	IconButton,
	Skeleton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { format } from 'date-fns';
import { useNotificationDetails } from './hooks/use-notification-details';
import { NotificationSummariesPageType } from './notification-summaries-page';

// const sentTooltipText =
// 	'The number of users that have had at least one of the following: the In-App notification viewed or the Email/SMS notification sent. For Email and SMS, this does not indicate the message was successfully received or read by the user. For Bulk SMS notifications, the notification will be marked as Sent when it is queued.';
const sentTooltipText =
	'The number of users that have had at least one of the following: the In-App notification viewed or the Email notification sent. For Email, this does not indicate the message was successfully received or read by the user.';
// const sendingTooltipText =
// 	'The number of users that have had at least one of the following (but with nothing yet Sent): the In-App notification delivered (but not yet seen) or the Email/SMS notification still being sent.';
const sendingTooltipText =
	'The number of users that have had at least one of the following (but with nothing yet Sent): the In-App notification delivered (but not yet seen) or the Email notification still being sent.';
// const failedTooltipText =
// 	'The number of users where all notification delivery mechanisms have failed. In-App notifications cannot fail; for Email/SMS, this is a failure to send or deliver.';
const failedTooltipText =
	'The number of users where all notification delivery mechanisms have failed. In-App notifications cannot fail; for Email, this is a failure to send or deliver.';

interface SelectedNotificationPanelProps {
	pageType: NotificationSummariesPageType;
	notificationId: string | undefined;
}

export const SelectedNotificationPanel = ({
	pageType,
	notificationId,
}: SelectedNotificationPanelProps) => {
	const { details, refetch, loading } = useNotificationDetails(pageType, notificationId);

	if (loading) {
		return (
			<Stack p={2} spacing={2}>
				<Skeleton variant="text" width="80%" />
				<Stack direction="row">
					{[...Array(3)].map((_, index) => (
						<Stack key={index} flex={1} direction="row" alignItems="center" spacing={1}>
							<Skeleton variant="circular" height={40} width={40} />
							<Box flex={1}>
								<Skeleton variant="text" width="50%" />
								<Skeleton variant="text" width="80%" />
							</Box>
						</Stack>
					))}
				</Stack>
				{[...Array(3)].map((_, index) => (
					<Box key={index}>
						<Skeleton variant="text" width="30%" />
						<Skeleton variant="text" width="100%" />
						<Skeleton variant="text" width="90%" />
					</Box>
				))}
			</Stack>
		);
	}

	if (!details) {
		return (
			<Stack
				flex={1}
				alignItems="center"
				justifyContent="center"
				textAlign="center"
				spacing={2}
				px={8}>
				<EmptyStateAvatar icon={<NotificationsNoneOutlined />} />
				<Typography variant="h5">
					Select an announcement to see a more detailed summary.
				</Typography>
			</Stack>
		);
	}

	return (
		<Stack>
			<Stack
				direction="row"
				alignItems="center"
				spacing={1}
				px={2}
				py={1}
				justifyContent="space-between">
				<Typography variant="h3">
					{format(new Date(details.createdUtc), 'MMMM d, y h:mm a')}
				</Typography>
				<IconButton onClick={() => refetch()}>
					<RefreshOutlined />
				</IconButton>
			</Stack>
			<Divider />
			<Stack direction="row">
				<Stack flex={1} direction="row" alignItems="center" p={1.5} spacing={1.5}>
					<Avatar sx={{ bgcolor: 'success.light', color: 'success.main' }}>
						<Check />
					</Avatar>
					<Box>
						<Typography variant="h4">{details.numUsersSent}</Typography>
						<Stack direction="row" alignItems="center" spacing={0.5} flexWrap="wrap">
							<Typography variant="subtitle1">Sent</Typography>
							<Tooltip title={sentTooltipText}>
								<InfoOutlined fontSize="small" />
							</Tooltip>
						</Stack>
					</Box>
				</Stack>
				<Divider orientation="vertical" />
				<Stack flex={1} direction="row" alignItems="center" p={1.5} spacing={1.5}>
					<Avatar sx={{ bgcolor: 'warning.light', color: 'warning.main' }}>
						<SendOutlined />
					</Avatar>
					<Box>
						<Typography variant="h4">{details.numUsersSending}</Typography>
						<Stack direction="row" alignItems="center" spacing={0.5} flexWrap="wrap">
							<Typography variant="subtitle1">Sending</Typography>
							<Tooltip title={sendingTooltipText}>
								<InfoOutlined fontSize="small" />
							</Tooltip>
						</Stack>
					</Box>
				</Stack>
				<Divider orientation="vertical" />
				<Stack flex={1} direction="row" alignItems="center" p={1.5} spacing={1.5}>
					<Avatar sx={{ bgcolor: 'error.light', color: 'error.main' }}>
						<Close />
					</Avatar>
					<Box>
						<Typography variant="h4">{details.numUsersFailed}</Typography>
						<Stack direction="row" alignItems="center" spacing={0.5} flexWrap="wrap">
							<Typography variant="subtitle1">Failed</Typography>
							<Tooltip title={failedTooltipText}>
								<InfoOutlined fontSize="small" />
							</Tooltip>
						</Stack>
					</Box>
				</Stack>
			</Stack>
			<Divider />
			<Stack p={2} spacing={3}>
				<Stack spacing={1}>
					<Stack direction="row" alignItems="center" spacing={1}>
						<NotificationsNoneOutlined sx={{ color: 'neutral.500' }} />
						<Typography variant="h5">In App</Typography>
					</Stack>
					<Typography variant="body1">{details.inAppText}</Typography>
				</Stack>
				{/* <Stack spacing={1}>
					<Stack direction="row" alignItems="center" spacing={1}>
						<TextsmsOutlined sx={{ color: 'neutral.500' }} />
						<Typography variant="h5">SMS</Typography>
					</Stack>
					<Typography variant="body1">{details.smsText}</Typography>
				</Stack> */}
				<Stack spacing={1}>
					<Stack direction="row" alignItems="center" spacing={1}>
						<EmailOutlined sx={{ color: 'neutral.500' }} />
						<Typography variant="h5">Email Subject</Typography>
					</Stack>
					<Typography variant="body1">{details.emailSubject}</Typography>
				</Stack>
				<Stack spacing={1}>
					<Stack direction="row" alignItems="center" spacing={1}>
						<ViewHeadline sx={{ color: 'neutral.500' }} />
						<Typography variant="h5">Email Body</Typography>
					</Stack>
					<Typography variant="body1" whiteSpace="pre-wrap">
						{details.emailPlainTextBody}
					</Typography>
				</Stack>
			</Stack>
		</Stack>
	);
};
