import { gql, useMutation } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import { Mutation, MutationOrganizationSetPrivateArgs } from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';

const SET_PRIVATE = gql`
	mutation organizationSetPrivate($orgId: ID!, $private: Boolean!) {
		organizationSetPrivate(orgId: $orgId, private: $private)
	}
`;

export const useSetOrgPrivate = (orgId: string) => {
	const toast = useToast();
	const [_setOrgPrivate, { loading }] = useMutation<
		Pick<Mutation, 'organizationSetPrivate'>,
		MutationOrganizationSetPrivateArgs
	>(SET_PRIVATE, {
		update: (cache) => {
			cache.evict({
				id: `Emblem:${orgId}`,
			});

			cache.evict({
				id: 'ROOT_QUERY',
				fieldName: 'getOrganizationImmediateFamily',
			});

			cache.gc();
		},
	});

	const setOrgPrivate = async (isPrivate: boolean) => {
		return await _setOrgPrivate({
			variables: {
				orgId,
				private: isPrivate,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push(`Org successfully set to ${isPrivate == true ? 'private' : 'public'}.`, {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { setOrgPrivate, loading };
};
