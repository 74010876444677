import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import { EMBLEM_FIELDS } from 'components/ui/emblem/fragments';
import { useToast } from 'components/ui/toast';
import { Query, QuerySiteUserEmblemsArgs, SortDirection } from 'middleware-types';
import { useCallback, useState } from 'react';
import { handleNoResponse, responseHasErrors } from 'utils/errors';

const CREATE_STRESS_TEST = gql`
	mutation userSiteUserCreateTestData {
		userSiteUserCreateTestData
	}
`;

export const useStressTestCreateMutation = () => {
	const toast = useToast();
	const [createStressTestMutation, { loading }] = useMutation(CREATE_STRESS_TEST);

	const createStressTest = async () => {
		try {
			const res = await createStressTestMutation();
			if (responseHasErrors(res.errors, { toast })) {
				console.log(res.errors);
				return false;
			}
			toast.push('Test data is being created and will complete in about 90 minutes.', {
				variant: 'success',
			});
			return true;
		} catch (error) {
			handleNoResponse({ toast });
			return false;
		}
	};

	return { createStressTest, loading };
};

/**
 *  Site user row props.
 */
export interface SiteUser {
	id: string;
	siteUserId: string;
	displayName: string | undefined;
	status: Status;
	role: string | undefined;
}

enum Status {
	Active = 'Active',
	Invited = 'Invited',
	Inactive = 'Inactive',
}

const SITE_USER_EMBLEMS = gql`
	${EMBLEM_FIELDS}
	query siteUserEmblems(
		$pageSize: Float!
		$offset: Float!
		$sortedBy: String!
		$sortDirection: SortDirection!
		$searchFor: String!
	) {
		siteUserEmblems(
			pageSize: $pageSize
			offset: $offset
			sortedBy: $sortedBy
			sortDirection: $sortDirection
			searchFor: $searchFor
		) {
			items {
				...EmblemFields
			}
			totalCount
		}
	}
`;

/**
 * useSiteUsersEmblemsQuery() - Hook to pull all site users' emblems in the system.
 *
 * @param {number} pageSize
 * @param {string} sortedBy
 * @param {('Ascending' | 'Descending')} sortDirection
 * @param {string} searchFor
 * @return {*}
 */
export const useSiteUserEmblemsQuery = (
	pageSize: number,
	sortedBy: string,
	sortDirection: SortDirection,
	searchFor: string
) => {
	const toast = useToast();
	const {
		error,
		data,
		loading,
		fetchMore: _fetchMore,
	} = useQuery<Pick<Query, 'siteUserEmblems'>, QuerySiteUserEmblemsArgs>(SITE_USER_EMBLEMS, {
		// checks cache and fetches (important for updating queries after inviting a user)
		fetchPolicy: 'cache-and-network',
		variables: {
			pageSize: pageSize,
			offset: 0,
			sortedBy,
			sortDirection,
			searchFor,
		},
		onError: (error: ApolloError) => {
			if (!error) return;
			toast.push(`An error occurred. Please try again later or contact Support.`, {
				variant: 'error',
			});
			console.log(error);
		},
	});

	const [isRefetching, setIsRefetching] = useState(false);
	const loadMore = useCallback(async () => {
		if (!data) return;
		setIsRefetching(true);
		await _fetchMore({
			variables: { offset: data.siteUserEmblems.items.length },
		});
		setIsRefetching(false);
	}, [data]);

	const siteUserEmblems = data?.siteUserEmblems.items ?? [];

	const canLoadMore =
		!loading &&
		!isRefetching &&
		data !== undefined &&
		data.siteUserEmblems.items.length < data.siteUserEmblems.totalCount;

	return { siteUserEmblems, loading, error, isRefetching, canLoadMore, loadMore };
};
