import { Divider, List } from '@mui/material';
import { FileInstanceInformation, FolderReference } from 'middleware-types';
import { Fragment } from 'react';
import { FolderListItem } from './folder-list-item';
import { FileListItem } from './file-list-item';
import { useVersionHistoryContext } from '../components/version-history-provider';

interface DocumentsListProps {
	folders: FolderReference[];
	files: FileInstanceInformation[];
}

export const DocumentsList = ({ folders, files }: DocumentsListProps) => {
	const { isVersionHistory } = useVersionHistoryContext();
	return (
		<List disablePadding>
			{folders.map((folder) => (
				<Fragment key={folder.id}>
					<FolderListItem folder={folder} />
					<Divider />
				</Fragment>
			))}
			{files.map((file) => (
				<Fragment key={isVersionHistory ? file.id : file.fileId}>
					<FileListItem file={file} />
					<Divider />
				</Fragment>
			))}
		</List>
	);
};
