import { Link, Stack, Typography } from '@mui/material';
import { OrgEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { useEmblem } from 'components/ui/emblem/hooks';
import { SecondaryNav, SecondaryNavItem } from 'components/ui/secondary-nav';
import { useToast } from 'components/ui/toast';
import { Emblem, EmblemEntityType } from 'middleware-types';
import { OrgPubOrPrivChip } from 'pages/orgs/[handle]/account/hierarchy/hierarchy';
import { useGetOrganizationImmediateFamily } from 'pages/orgs/[handle]/account/hierarchy/hooks';
import { useNavigate } from 'react-router-dom';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useOrgBadgeCounts } from 'utils/useBadgeCounts';
import { useSiteUser } from 'utils/useSiteUser';

/**
 * useAssociateUserNavigationItems - hook that returns the full list of items for a standard user's organization navigation.
 *  @type {SecondaryNavItem[]} */
export const useAssociateUserNavigationItems = (orgId: string, isPrivate?: boolean) => {
	const { inboundRequestsBadgeCount, conversationsBadgeCount } = useOrgBadgeCounts(orgId);

	const associateUserNavigationItems: SecondaryNavItem[] = [
		{
			link: 'dashboard',
			text: 'Dashboard',
			access: Permission.Org_Member,
		},
		{
			link: 'profile',
			text: 'Profile',
			access: isPrivate ? Permission.Org_Profile_U : undefined,
			all: isPrivate,
		},
		{
			link: 'network/connections',
			text: 'Network',
			access: [Permission.Org_Social_Network_R],
			badgeCount: inboundRequestsBadgeCount,
		},
		{
			link: 'conversations',
			text: 'Conversations',
			access: [Permission.Org_Messages_R],
			badgeCount: conversationsBadgeCount,
		},
		{
			link: 'associates',
			text: 'Associates',
			access: [Permission.Org_Assoc_R, Permission.Org_Assoc_Inv_R],
		},
		{
			link: 'roles',
			text: 'Organization Roles',
			access: [Permission.Org_Assoc_Roles_R],
		},
		{
			link: 'documents',
			text: 'Documents',
			access: Permission.Org_Member,
		},
		{
			link: 'associate-notifications',
			text: 'Announcements',
			access: [Permission.Org_Notification_R],
		},
		{
			link: 'account/',
			text: 'Account Settings',
			access: [Permission.Org_Account_R],
		},
	];

	return associateUserNavigationItems;
};

/**
 * useSiteUserNavigationItems - hook that returns the full list of items for a site user's organization navigation.
 *  @type {SecondaryNavItem[]} */
export const useSiteUserNavigationItems = (isPrivate?: boolean) => {
	const siteUserNavigationItems: SecondaryNavItem[] = [
		{
			link: 'dashboard',
			text: 'Dashboard',
			access: [Permission.Site_OrgDashboard_R],
		},
		{
			link: 'profile',
			text: 'Profile',
			access: isPrivate
				? [Permission.Site_OrgProfile_R, Permission.Site_OrgProfile_U]
				: [Permission.Site_OrgProfile_R],
			all: isPrivate,
		},
		{
			link: 'network/connections',
			text: 'Network',
			access: [Permission.Site_OrgNetwork_R],
		},
		{
			link: 'conversations',
			text: 'Conversations',
			access: [Permission.Site_OrgMsgs_R],
		},
		{
			link: 'associates',
			text: 'Associates',
			access: [Permission.Site_OrgAssoc_R, Permission.Site_OrgAssocInv_R],
		},
		{
			link: 'roles',
			text: 'Organization Roles',
			access: [Permission.Site_OrgRoles_R],
		},
		{
			link: 'documents',
			text: 'Documents',
			access: [Permission.Site_OrgFile_R, Permission.Site_OrgFile_Sens_R],
		},
		{
			link: 'associate-notifications',
			text: 'Announcements',
			access: [Permission.Site_OrgNotification_R],
		},
		{
			link: 'account',
			text: 'Account Settings',
			access: [Permission.Site_OrgAcct_R],
		},
	];

	return siteUserNavigationItems;
};

export const OrgNavigation = ({ orgId }: { orgId: string }) => {
	const { user } = useSession();
	const { hasPermission: hasAssociateUserPermission, loading: associateUserLoading } =
		useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission, loading: siteUserLoading } = useSiteUser();
	const { emblem, loading: emblemLoading } = useEmblem(orgId, EmblemEntityType.Organization);
	const associateUserNavigationItems = useAssociateUserNavigationItems(orgId, emblem?.private);
	const siteUserNavigationItems = useSiteUserNavigationItems(emblem?.private);
	const { family } = useGetOrganizationImmediateFamily(orgId);

	const isSiteUser = !!user.siteUserId;
	const userLoading = (isSiteUser && siteUserLoading) || (!isSiteUser && associateUserLoading);

	if (userLoading || emblemLoading) return <></>;

	return (
		<SecondaryNav
			icon={<OrgEmblemAvatar id={orgId} size={32} />}
			hasPermission={isSiteUser ? hasSiteUserPermission : hasAssociateUserPermission}
			navigationItems={isSiteUser ? siteUserNavigationItems : associateUserNavigationItems}
			title={
				<OrgTitle
					displayName={emblem?.displayName}
					isSiteUser={!!user.siteUserId}
					orgIsPrivate={emblem?.private}
					parentEmblem={family?.parentEmblem}
				/>
			}
		/>
	);
};

/**
 * Org Title props.
 */
export type OrgTitleProps = {
	displayName: string | null | undefined;
	isSiteUser: boolean;
	orgIsPrivate?: boolean;
	parentEmblem?: Emblem;
};

const OrgTitle = ({ displayName, isSiteUser, orgIsPrivate, parentEmblem }: OrgTitleProps) => {
	const { hasPermission: hasParentOrgAssociateUserPermission } = useAssociateUser(
		parentEmblem?.id
	);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();

	const navigate = useNavigate();
	const toast = useToast();

	const handleClick = () => {
		if (!parentEmblem) return;

		if (
			hasParentOrgAssociateUserPermission(Permission.Org_Account_R) ||
			hasSiteUserPermission(Permission.Site_OrgAcct_R)
		)
			return navigate(`/orgs/${parentEmblem.handle}/account/`);

		if (
			hasParentOrgAssociateUserPermission(Permission.Org_Member) ||
			hasSiteUserPermission(Permission.Site_OrgDashboard_R)
		)
			return navigate(`/orgs/${parentEmblem.handle}/dashboard/`);

		if (
			!parentEmblem.private &&
			(!isSiteUser || hasSiteUserPermission(Permission.Site_OrgProfile_R))
		)
			return navigate(`/orgs/${parentEmblem.handle}/profile/`);

		return toast.push(
			`You do not have permission to view this organization. Contact an organization admin for assistance.`,
			{
				variant: 'error',
			}
		);
	};

	return (
		<>
			<Stack height={40} justifyContent="center">
				<Typography variant="h5" noWrap>
					{displayName}
				</Typography>
				{parentEmblem && (
					<Stack direction="row" alignItems="center" onClick={handleClick}>
						<Link variant="caption">Parent: {parentEmblem.displayName}</Link>
					</Stack>
				)}
			</Stack>
			<OrgPubOrPrivChip isPrivate={orgIsPrivate} />
		</>
	);
};
