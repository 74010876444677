import { Avatar, SxProps, Theme } from '@mui/material';
import React from 'react';

// A reusable component for displaying an empty state avatar with an icon.
type EmptyStateAvatarProps = {
	icon: React.ReactElement;
	avatarProps?: SxProps<Theme>;
	iconProps?: SxProps<Theme>;
};

export const EmptyStateAvatar: React.FC<EmptyStateAvatarProps> = ({
	icon,
	avatarProps,
	iconProps,
}) => {
	const defaultAvatarProps: SxProps<Theme> = {
		width: 80,
		height: 80,
		...avatarProps,
	};

	const defaultIconProps: SxProps<Theme> = {
		fontSize: 48,
		...iconProps,
	};

	return (
		<Avatar sx={defaultAvatarProps}>
			{React.cloneElement(icon, { sx: defaultIconProps })}
		</Avatar>
	);
};
