import { Stack } from '@mui/material';
import { OrgLocationInformation } from 'components/pages/org/account/location-info';
import { OrgBasicInfo } from 'components/pages/org/account/basic-info';
import { OrgRepresentatives } from 'components/pages/org/account/org-reps/list';
import { useOrgId } from 'components/pages/org/outlet';
import { OrgHierarchyInformation } from './hierarchy/hierarchy';

export const OrgAccountPage = ({ orgId: propsOrgId }: { orgId?: string }) => {
	// When this component is rendered outside org outlet (site user organizations page),
	// orgId is passed explicitly as a prop. Otherwise we can fetch it with this hook,
	// like all the other org components.
	const contextOrgId = useOrgId();
	const orgId = propsOrgId ?? contextOrgId;

	return (
		<>
			<Stack spacing={2}>
				<OrgBasicInfo orgId={orgId} />
				<OrgHierarchyInformation orgId={orgId} />
				<OrgLocationInformation orgId={orgId} />
				<OrgRepresentatives orgId={orgId} />
			</Stack>
		</>
	);
};
