import {
	Button,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { useCognitoDevices } from 'components/pages/user/account/security/hooks';
import { useModal } from 'components/ui/modal';
import { ForgetDevicesModal } from './forget-devices-modal';

interface DevicesTableProps {
	userId: string;
	canEdit: boolean;
}

export const DevicesTable = ({ userId, canEdit }: DevicesTableProps) => {
	const { showModal } = useModal();
	const { devices, loading } = useCognitoDevices(userId);

	if (loading) {
		return <Skeleton variant="rounded" height={24} />;
	}

	if (!devices || devices.length < 1) {
		return <Typography>No Devices To List</Typography>;
	}

	return (
		<>
			<TableContainer sx={{ maxHeight: '80%' }}>
				<Table stickyHeader aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell size="small" width="4%"></TableCell>
							<TableCell width="15%">Name</TableCell>
							<TableCell width="15%">Last IP Used</TableCell>
							<TableCell width="15%">Last Login Date</TableCell>
							<TableCell width="20%">Remembered</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{devices.map((device, i) => {
							return (
								<TableRow key={i}>
									<TableCell size="small" width="4%"></TableCell>
									<TableCell width="15%">{device.name}</TableCell>
									<TableCell width="15%">{device.lastIpUsed}</TableCell>
									<TableCell width="15%">
										{new Date(device.lastAuthenticatedUtc).toString()}
									</TableCell>
									<TableCell width="20%">
										{device.remembered ? 'Yes' : 'No'}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{canEdit && (
				<Button
					variant="outlined"
					onClick={() =>
						showModal({
							title: 'Forget Devices',
							content: <ForgetDevicesModal userId={userId} />,
						})
					}>
					Clear List and Forget All Devices
				</Button>
			)}
		</>
	);
};
