import { gql, useMutation } from '@apollo/client';
import { ToastState, useToast } from 'components/ui/toast';
import { Mutation, MutationMessagingDraftRequestArgs } from 'middleware-types';
import { useState } from 'react';
import { useCommunicationsContext } from '../../communications-provider';

const onError = (toast: ToastState) => {
	toast.push(
		'EVO-X was unable to draft a message for you. Please make sure your prompt is worded clearly, and try again.',
		{
			variant: 'error',
		}
	);
};

const MESSAGING_DRAFT_REQUEST = gql`
	mutation MessagingDraftRequest(
		$entityType: ConversationApiEntityType!
		$entityId: String!
		$request: MessagingDraftRequest!
	) {
		messagingDraftRequest(entityType: $entityType, entityId: $entityId, request: $request) {
			chatConversationId
			draftText
		}
	}
`;

export const useEvoxMessageDraft = (
	initialText: string | undefined,
	sendConversationHistory: boolean
) => {
	const toast = useToast();
	const [state, setState] = useState<'draft' | 'preview'>('draft');
	const [previousPrompt, setPreviousPrompt] = useState('Your current draft');
	const [draftText, setDraftText] = useState('');

	const [_makeRequest, { data, loading }] = useMutation<
		Pick<Mutation, 'messagingDraftRequest'>,
		MutationMessagingDraftRequestArgs
	>(MESSAGING_DRAFT_REQUEST, {
		onError: () => onError(toast),
	});

	const chatConversationId = data?.messagingDraftRequest.chatConversationId;
	const textToShow = draftText || initialText;
	const canGoBack = Boolean(draftText);

	const { entityType, entityId, selectedConversationId } = useCommunicationsContext();
	const makeRequest = (prompt: string, updatePrompt: boolean) => {
		_makeRequest({
			variables: {
				entityId,
				entityType,
				request: {
					prompt,
					chatConversationId,
					initialText: chatConversationId ? undefined : initialText,
					commsConversationId:
						sendConversationHistory && selectedConversationId
							? selectedConversationId
							: undefined,
				},
			},
			onCompleted: (data) => {
				const newDraftText = data.messagingDraftRequest.draftText;
				if (newDraftText) {
					setDraftText(newDraftText);
					setState('preview');
					if (updatePrompt) setPreviousPrompt(prompt);
				} else {
					onError(toast);
				}
			},
		});
	};

	return { makeRequest, textToShow, previousPrompt, state, setState, loading, canGoBack };
};
