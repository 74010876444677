import { ConversationApiEntityType } from 'middleware-types';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CommunicationsProvider } from './communications-provider';
import { useLabels } from './hooks/labelsHooks';
import { CreateConversationModal } from './modals/create-conversation-modal';

interface CommunicationsWrapperProps {
	entityType: ConversationApiEntityType;
	entityId: string;
	readOnly: boolean;
	children: React.ReactNode;
}

export const CommunicationsWrapper = ({
	entityType,
	entityId,
	readOnly,
	children,
}: CommunicationsWrapperProps) => {
	const [selectedLabelId, setSelectedLabelId] = useState<string | null>(null);
	const [searchParams, setSearchParams] = useSearchParams();

	const selectedConversationId = searchParams.get('conversationId');
	const setSelectedConversationId = (value: string | null) =>
		setSearchParams((params) => {
			if (value) params.set('conversationId', value);
			else params.delete('conversationId');
			return params;
		});

	const startOpen =
		searchParams.get('entityId') !== null && searchParams.get('entityType') !== null;
	const [newConversationModalOpen, setNewConversationModalOpen] = useState(startOpen);
	const onClose = () => {
		setNewConversationModalOpen(false);
		setSearchParams((params) => {
			params.delete('entityId');
			params.delete('entityType');
			return params;
		});
	};

	const { labels, loading: labelsLoading } = useLabels(entityType, entityId, setSelectedLabelId);
	const selectedLabel = labels.find((label) => label.id === selectedLabelId);

	return (
		<CommunicationsProvider
			value={{
				entityType,
				entityId,
				readOnly,
				labels,
				labelsLoading,
				selectedLabelId,
				setSelectedLabelId,
				selectedLabel,
				selectedConversationId,
				setSelectedConversationId,
				setNewConversationModalOpen,
			}}>
			{children}
			<CreateConversationModal open={newConversationModalOpen} onClose={onClose} />
		</CommunicationsProvider>
	);
};
