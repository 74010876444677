import { Box, Stack } from '@mui/material';
import { AssumeIdentityBanner } from 'components/pages/assume-identity-banner';
import { UpdateBanner } from 'components/pages/update-banner';
import { ReactNode } from 'react';
import { useIsMobile } from 'utils/useScreenSize';
import { PrimaryNavDesktop } from './primary-nav-desktop';
import { PrimaryNavMobile } from './primary-nav-mobile';

export const PrimaryNav = ({ children }: { children: ReactNode }) => {
	const isMobile = useIsMobile();
	return (
		<Stack height="100vh" width="100vw" flex={1}>
			<UpdateBanner />
			<AssumeIdentityBanner />
			{isMobile ? <PrimaryNavMobile /> : <PrimaryNavDesktop />}
			<Box display="flex" flexDirection="column" flexGrow={1} overflow="auto">
				{children}
			</Box>
		</Stack>
	);
};
