import {
	AccountCircleOutlined,
	EmailOutlined,
	HomeOutlined,
	PhoneOutlined,
} from '@mui/icons-material';
import { Box, Grid, MenuItem, Stack } from '@mui/material';
import { AddressField, PhoneNumberField, SelectField, TextField } from 'components/ui/fields';
import { useFormikContext } from 'formik';
import { AccountEmergencyContactUpdate } from 'middleware-types';

export type EmergencyContactEditProps = Record<string, never>;

/**
 * EmergencyContactEdit is an edit form for the emergency contact in account settings
 * @type {*}
 * */
export const EmergencyContactEdit: React.FC<EmergencyContactEditProps> = () => {
	const formik = useFormikContext<AccountEmergencyContactUpdate>();

	return (
		<Stack spacing={2}>
			<Stack direction="row" spacing={1} alignItems="flex-start">
				<Box>
					<AccountCircleOutlined sx={{ marginTop: 2.5 }} fontSize="small" />
				</Box>
				<Grid container columnSpacing={1}>
					<Grid xs={12} md={4} item>
						<TextField label="First Name" name="firstName" required fullWidth />
					</Grid>
					<Grid xs={12} md={4} item>
						<TextField label="Last Name" required name="lastName" fullWidth />
					</Grid>
					<Grid xs={12} md={4} item>
						<SelectField
							value={formik.values.relationship}
							name="relationship"
							label="Relationship"
							required>
							<MenuItem value="Spouse">Spouse</MenuItem>
							<MenuItem value="Parent">Parent</MenuItem>
							<MenuItem value="Friend">Friend</MenuItem>
							<MenuItem value="Partner">Partner</MenuItem>
							<MenuItem value="Co-Worker">Co-Worker</MenuItem>
							<MenuItem value="Relative">Relative</MenuItem>
							<MenuItem value="Legal Guardian">Legal Guardian</MenuItem>
							<MenuItem value="Other">Other</MenuItem>
						</SelectField>
					</Grid>
				</Grid>
			</Stack>
			<Stack direction="row" spacing={1} alignItems="flex-start">
				<Box>
					<PhoneOutlined sx={{ marginTop: 2.5 }} fontSize="small" />
				</Box>
				<Grid container columnSpacing={1}>
					<Grid xs={12} md={6} item>
						<PhoneNumberField label="Cell Phone Number" name="cellPhone" required />
					</Grid>
					<Grid xs={12} md={6} item>
						<PhoneNumberField label="Other Phone Number" name="otherPhone" />
					</Grid>
				</Grid>
			</Stack>
			<Stack direction="row" spacing={1} alignItems="center">
				<EmailOutlined fontSize="small" />
				<Grid container columnSpacing={1}>
					<Grid xs={12} md={6} item>
						<TextField label="Email Address" type="email" name="email" fullWidth />
					</Grid>
				</Grid>
			</Stack>
			<Stack direction="row" spacing={1} alignItems="flex-start">
				<Box>
					<HomeOutlined sx={{ marginTop: 2.5 }} fontSize="small" />
				</Box>
				<Box p={0.5}>
					<AddressField name="address" required disabled={formik.isSubmitting} />
				</Box>
			</Stack>
		</Stack>
	);
};

export default EmergencyContactEdit;
