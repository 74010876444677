import { Badge, IconButton } from '@mui/material';
import clsx from 'clsx';
import { EmblemEntityType } from 'middleware-types';
import { ReactNode } from 'react';
import { useAvatarOrBannerUploader } from './user-emblem-uploader';
import { OrgEmblemAvatar, UserEmblemAvatar } from './emblem-avatar';
import { useEmblem } from './hooks';
import { useOrganizationEmblemUploader } from './org-emblem-uploader';

interface AvatarUploadButtonProps {
	onClick: () => void;
	variant: 'circular' | 'rectangular';
	disabled?: boolean;
	children: ReactNode;
}

const AvatarUploadButton = ({
	onClick,
	variant,
	disabled = false,
	children,
}: AvatarUploadButtonProps) => {
	return (
		<IconButton
			disabled={disabled}
			className={clsx('group', variant === 'rectangular' && 'rounded-none', '-m-2')}
			onClick={onClick}>
			<Badge
				componentsProps={{
					badge: {
						className:
							'opacity-0 transition-opacity duration-150 group-hover:opacity-100',
					},
				}}
				overlap={variant}
				badgeContent={<img src="/img/camera.svg" alt="Camera Icon" />}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
				{children}
			</Badge>
		</IconButton>
	);
};

export const UserAvatarUploadButton = ({
	id,
	disabled,
	size = 160,
}: {
	id: string;
	disabled?: boolean;
	size?: number;
}) => {
	const { emblem } = useEmblem(id, EmblemEntityType.User);
	const file = emblem?.avatarFile?.file?.currentInstance;
	const { openAvatarOrBannerUploader } = useAvatarOrBannerUploader(id, 'avatar', file);

	return (
		<AvatarUploadButton
			variant="circular"
			onClick={openAvatarOrBannerUploader}
			disabled={disabled}>
			<UserEmblemAvatar id={id} size={size} noDropdown />
		</AvatarUploadButton>
	);
};

export const OrgAvatarUploadButton = ({
	id,
	disabled,
	size = 160,
}: {
	id: string;
	disabled?: boolean;
	size?: number;
}) => {
	const { emblem } = useEmblem(id, EmblemEntityType.Organization);
	const file = emblem?.avatarFile?.file?.currentInstance;
	const { openOrganizationEmblemUploader } = useOrganizationEmblemUploader(id, 'avatar', file);

	return (
		<AvatarUploadButton
			variant="rectangular"
			onClick={openOrganizationEmblemUploader}
			disabled={disabled}>
			<OrgEmblemAvatar id={id} size={size} noDropdown />
		</AvatarUploadButton>
	);
};
