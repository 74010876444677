import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { Query, QueryProfilePrivaciesArgs } from 'middleware-types';
import { useToast } from 'components/ui/toast';

/**
 * Query to pull profile privacies for a given user id.
 * @type {*}
 *
 */
export const PROFILEPRIVACIES = gql`
	query profilePrivacies($userId: ID!) {
		profilePrivacies(userId: $userId) {
			targetUserId
			aspect
			mayAccess
		}
	}
`;
/**
 * User Profile Privacy aspect types.
 *
 * @export
 * @enum {number}
 */
export enum UserProfileAspectType {
	ViewLicensingDetails = 'ViewLicensingDetails',
	AlterLicensing = 'AlterLicensing',
	ImportLicensing = 'ImportLicensing',
}

/**
 * useProfilePrivaciesQuery(userId) - hook to pull server driven privacy options for the profile.
 *
 * @param {string} userId
 * @return {*}
 */
export const usePrivacy = (userId: string) => {
	const toast = useToast();
	const { data } = useQuery<Pick<Query, 'profilePrivacies'>, QueryProfilePrivaciesArgs>(
		PROFILEPRIVACIES,
		{
			fetchPolicy: 'cache-first',
			variables: {
				userId,
			},
			onError: () => {
				toast.push(
					'Error fetching privacy options. Please try again later or contact support.',
					{
						variant: 'error',
					}
				);
			},
		}
	);
	const hasAccess = useCallback(
		(...types: UserProfileAspectType[]) => {
			return (data?.profilePrivacies ?? []).some(
				(p) => types.includes(p.aspect as UserProfileAspectType) && p.mayAccess === true
			);
		},
		[data]
	);

	return { hasAccess };
};
