import { TabContext, TabPanel } from '@mui/lab';
import { Card, CardContent, CardHeader, Chip, Stack, Tab, Tabs, Typography } from '@mui/material';
import { PageTitle } from 'components/ui/page';
import { Search } from 'components/ui/search';
import { ConnectionPageType } from 'middleware-types';
import { useState } from 'react';
import { useSession } from 'utils/session';
import { useIsMediumOrSmallerScreen, useIsMobile } from 'utils/useScreenSize';
import { useUserConnectionsOrRequestsCount } from './UserNetworkScreen/UserConnections.graphql';
import { UserConnectionsOrRequestsList } from './UserNetworkScreen/UserConnectionsOrRequestsList';

export type EntitySearchFormValues = {
	searchText: string;
};

/**
 * Displays a tabbed panel for displaying user connections.
 */
export const UserConnectionsScreen = () => {
	const [tabPage, setTabPage] = useState<ConnectionPageType>(ConnectionPageType.Requests);
	const { user } = useSession();
	const [searchFor, setSearchFor] = useState('');

	const userRequestsCount = useUserConnectionsOrRequestsCount(
		ConnectionPageType.Requests,
		user.userId
	);
	const userConnectionsCount = useUserConnectionsOrRequestsCount(
		ConnectionPageType.MyConnections,
		user.userId
	);
	const userSentRequestsCount = useUserConnectionsOrRequestsCount(
		ConnectionPageType.RequestsISent,
		user.userId
	);

	const isMediumOrSmallerScreen = useIsMediumOrSmallerScreen();
	const isMobile = useIsMobile();

	const tabsConfig = [
		{
			label: 'Requests',
			count: userRequestsCount,
			value: ConnectionPageType.Requests,
		},
		{
			label: 'My Connections',
			count: userConnectionsCount,
			value: ConnectionPageType.MyConnections,
		},
		{
			label: 'Sent Requests',
			count: userSentRequestsCount,
			value: ConnectionPageType.RequestsISent,
		},
	];

	// Handler for search query change
	const handleSearch = (str: string): void => {
		setSearchFor(str);
	};

	// Handler for tab change and search query reset
	const handleTabChange = (_, newValue) => {
		setTabPage(newValue);
		setSearchFor('');
	};
	return (
		<>
			<PageTitle title="Connections" />
			<Card
				sx={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					margin: isMediumOrSmallerScreen ? '-12px -28px' : undefined,
				}}>
				<CardHeader title="Connections" />
				<CardContent
					sx={{
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						padding: '0',
					}}>
					<TabContext value={tabPage}>
						<Tabs value={tabPage} onChange={handleTabChange} sx={{ paddingX: '16px' }}>
							{tabsConfig.map((tab) => (
								<Tab
									key={tab.value}
									label={
										<Stack flexDirection="row" alignItems="center" gap={1}>
											<Typography
												color={
													tabPage !== tab.value
														? 'neutral.500'
														: undefined
												}>
												{tab.label}
											</Typography>

											{(!isMobile || tab.label === 'Requests') &&
												tab.count > 0 && (
													<Chip
														variant="filled"
														label={tab.count}
														size="small"
														sx={{
															bgcolor:
																tabPage !== tab.value
																	? 'neutral.50'
																	: undefined,
															color:
																tabPage !== tab.value
																	? 'neutral.500'
																	: undefined,
														}}
													/>
												)}
										</Stack>
									}
									value={tab.value}
								/>
							))}
						</Tabs>
						{tabPage !== ConnectionPageType.Requests && (
							<Stack width="100%" flexDirection="column" paddingTop={2} paddingX={2}>
								<Search onChange={handleSearch} placeholder="Search" />
							</Stack>
						)}
						{tabsConfig.map((tab) => (
							<TabPanel key={tab.value} value={tab.value} sx={{ flex: 1 }}>
								<UserConnectionsOrRequestsList
									userId={user.userId}
									pageType={tab.value}
									searchFor={searchFor}
								/>
							</TabPanel>
						))}
					</TabContext>
				</CardContent>
			</Card>
		</>
	);
};

export default UserConnectionsScreen;
