import { Close, HistoryOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { FileInstanceInformation } from 'middleware-types';
import { useMakeCurrentInstance } from '../hooks/files/use-make-current-instance';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';

interface MakeCurrentInstanceModalProps {
	file: FileInstanceInformation;
	onClose: () => void;
}

export const MakeCurrentInstanceModal = ({ file, onClose }: MakeCurrentInstanceModalProps) => {
	const { makeCurrentInstance, loading } = useMakeCurrentInstance();
	const onSubmit = () => {
		makeCurrentInstance(file.fileId, { fileInstanceId: file.id }).then((success) => {
			if (!success) return;
			onClose();
		});
	};

	return (
		<Stack>
			<Stack
				px={2}
				py={1}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				spacing={1}>
				<Typography variant="h3">Are you sure?</Typography>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</Stack>
			<Divider />
			<Stack alignItems="center" textAlign="center" px={10} py={3}>
				<EmptyStateAvatar icon={<HistoryOutlined />} />
				<Typography variant="h1" marginTop={2} sx={{ wordBreak: 'break-word' }}>
					Make {file.fileName} (v{file.versionNumber}) current?
				</Typography>
				<Typography variant="body1" sx={{ marginTop: '4px' }}>
					This will create a copy of the old version and mark it as current. The original
					version will stay intact.
				</Typography>
			</Stack>
			<Divider />
			<Stack direction="row" justifyContent="flex-end" px={2} py={1.5} spacing={1.5}>
				<Button size="large" variant="outlined" onClick={onClose}>
					Cancel
				</Button>
				<LoadingButton
					size="large"
					variant="contained"
					color="primary"
					loading={loading}
					onClick={onSubmit}>
					Make Current
				</LoadingButton>
			</Stack>
		</Stack>
	);
};
