import { People, PeopleOutlined } from '@mui/icons-material';
import { Container, Grid } from '@mui/material';
import { NavList, NavListItem } from 'components/ui/nav-list';
import { PageContent, PageTitle } from 'components/ui/page';
import { Outlet } from 'react-router-dom';
import { useIsMediumOrSmallerScreen } from 'utils/useScreenSize';

const UserNetworkOutlet = () => {
	const isMediumOrSmallerScreen = useIsMediumOrSmallerScreen();
	return (
		<>
			<PageTitle title="Network" />
			<PageContent>
				<Container
					sx={{
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
					}}>
					<Grid container spacing={4} flex={1}>
						{!isMediumOrSmallerScreen && (
							<Grid item xs={12} md={2}>
								<NavList title="Network" sticky className="pt-2">
									<NavListItem
										activeIcon={<People color="primary" />}
										icon={<PeopleOutlined sx={{ color: 'neutral.500' }} />}
										text="Connections"
										to="connections"
									/>
								</NavList>
							</Grid>
						)}
						<Grid item xs={12} md={10}>
							<Outlet />
						</Grid>
					</Grid>
				</Container>
			</PageContent>
		</>
	);
};

export default UserNetworkOutlet;
