import { Box, MenuItem, Stack, TextFieldProps, Typography } from '@mui/material';
import {
	amber,
	blue,
	blueGrey,
	brown,
	cyan,
	deepOrange,
	deepPurple,
	green,
	grey,
	indigo,
	lightBlue,
	lightGreen,
	lime,
	orange,
	pink,
	purple,
	red,
	teal,
	yellow,
} from '@mui/material/colors';
import { FieldAttributes } from 'formik';
import { SelectField } from '.';

export interface ColorOption {
	name: string;
	hex: string;
}

export const defaultColorFieldOptions: ColorOption[] = [
	{
		name: 'Red',
		hex: red[500],
	},
	{
		name: 'Pink',
		hex: pink[500],
	},
	{
		name: 'Purple',
		hex: purple[500],
	},
	{
		name: 'Deep Purple',
		hex: deepPurple[500],
	},
	{
		name: 'Indigo',
		hex: indigo[500],
	},
	{
		name: 'Blue',
		hex: blue[500],
	},
	{
		name: 'Light Blue',
		hex: lightBlue[500],
	},
	{
		name: 'Cyan',
		hex: cyan[500],
	},
	{
		name: 'Teal',
		hex: teal[500],
	},
	{
		name: 'Green',
		hex: green[500],
	},
	{
		name: 'Light Green',
		hex: lightGreen[500],
	},
	{
		name: 'Lime',
		hex: lime[500],
	},
	{
		name: 'Yellow',
		hex: yellow[500],
	},
	{
		name: 'Amber',
		hex: amber[500],
	},
	{
		name: 'Orange',
		hex: orange[500],
	},
	{
		name: 'Deep Orange',
		hex: deepOrange[500],
	},
	{
		name: 'Brown',
		hex: brown[500],
	},
	{
		name: 'Grey',
		hex: grey[500],
	},
	{
		name: 'Blue Grey',
		hex: blueGrey[500],
	},
];

const defaultGetValue = (option: ColorOption) => option.hex;

interface ColorFieldProps extends Omit<FieldAttributes<TextFieldProps>, 'children'> {
	options?: ColorOption[];
	getValueFromOption?: (option: ColorOption) => string;
	defaultColorName?: string;
}

export const ColorField = ({
	options = defaultColorFieldOptions,
	getValueFromOption = defaultGetValue,
	defaultColorName,
	...props
}: ColorFieldProps) => {
	return (
		<SelectField {...(props as FieldAttributes<TextFieldProps>)}>
			{options.map((option) => (
				<MenuItem key={option.hex} value={getValueFromOption(option)}>
					<Stack direction="row" spacing={1.5} alignItems="center">
						<Box
							height={20}
							width={20}
							borderRadius="50%"
							sx={{ backgroundColor: option.hex }}
						/>
						<Typography variant="body1" sx={{ height: 22 }}>
							{option.name + (option.name === defaultColorName ? ' (Default)' : '')}
						</Typography>
					</Stack>
				</MenuItem>
			))}
		</SelectField>
	);
};
