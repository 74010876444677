import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { TextField } from 'components/ui/fields';
import { Loading } from 'components/ui/loading';
import { ModalActionButton, ModalActions, ModalContent, useModal } from 'components/ui/modal';
import { useToast } from 'components/ui/toast';
import { Formik } from 'formik';
import {
	AdjustmentCompanyInfo,
	Mutation,
	MutationLegacyAdjustmentCompanyLinkArgs,
	Query,
	QueryLegacyAdjustmentCompanyInfoArgs,
} from 'middleware-types';
import { LegacyAdjustmentCompanyDisplayCard } from 'pages/app/legacy/invitation';
import { useLegacyCanCallerLink } from 'pages/orgs/new-linked';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { refreshToken, useSession } from 'utils/session';
import { theme } from 'utils/theme';
import { ALL_USER_ORG_PERMISSIONS } from 'utils/useAssociateUser';
import * as Yup from 'yup';

export const LinkCompanyModal = ({ orgId }: { orgId: string }) => {
	const { company, findCompany, loading, error } = useLegacyAdjustmentCompanyInfoLazy();
	const { closeModal } = useModal();
	const toast = useToast();
	const { user } = useSession();
	const isSiteUser = !!user.siteUserId;

	if (company) {
		return <LinkCompanyCard company={company} orgId={orgId} />;
	}

	if (loading) return <Loading />;

	if (error) {
		// Site users don't have permission to hit the "adjustmentcompanyinfo" endpoint
		// so their custom error fires on findCompany.
		toast.push(
			isSiteUser
				? 'Please use impersonation to find adjustment companies as a site user.'
				: "You're not authorized to perform this action",
			{
				variant: 'error',
			}
		);
		closeModal();
		return <></>;
	}

	return <FindCompanyToLinkForm onSubmit={findCompany} />;
};

const LinkCompanyCard = ({ company, orgId }: { company: AdjustmentCompanyInfo; orgId: string }) => {
	const {
		canLink,
		error: canLinkError,
		loading: canLinkLoading,
	} = useLegacyCanCallerLink(company.systemId, company.acid);
	const { closeModal } = useModal();
	const toast = useToast();

	const { onSubmit } = useLinkCompany({ orgId, systemId: company.systemId, acid: company.acid });

	if (canLinkLoading) {
		return <Loading />;
	}

	if (!canLink) {
		toast.push("You're not authorized to perform this action", {
			variant: 'error',
		});
		closeModal();
		return <></>;
	}

	// The company already being linked returns a 409 on the can-link check
	if (canLinkError) {
		toast.push('This company could not be linked at this time', {
			variant: 'error',
		});
		closeModal();
		return <></>;
	}

	return (
		<>
			<ModalContent
				sx={{
					padding: 0,
				}}>
				<Stack
					display="flex"
					spacing={3}
					padding={2}
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					borderBottom={`1px solid ${theme.palette.divider}`}>
					<Typography variant="h4">Here&apos;s what we found.</Typography>
					<LegacyAdjustmentCompanyDisplayCard
						company={{
							adjCoLogo: company.logo,
							adjCoName: company.name,
							systemUrl: company.systemUrl,
						}}
					/>
					<Typography variant="body1" className="text-neutral-900">
						Is this the company you would like to link?
					</Typography>
				</Stack>
			</ModalContent>
			<ModalActions
				sx={{
					direction: 'row',
					spacing: 1,
					padding: 2,
					justifyContent: 'flex-end',
				}}>
				<ModalActionButton variant="outlined">Cancel</ModalActionButton>
				<ModalActionButton
					data-test="loading-btn"
					variant="contained"
					color="primary"
					onClick={() => onSubmit(true)}>
					Link Company
				</ModalActionButton>
			</ModalActions>
		</>
	);
};

export const useLinkCompany = ({
	orgId,
	systemId,
	acid,
}: {
	orgId: string;
	systemId: number;
	acid: number;
}) => {
	const toast = useToast();

	const [linkOrg, updateOrgStatus] = useMutation<
		Pick<Mutation, 'legacyAdjustmentCompanyLink'>,
		MutationLegacyAdjustmentCompanyLinkArgs
	>(
		gql`
			mutation legacyAdjustmentCompanyLink($orgId: ID!, $request: OrganizationLinkRequest!) {
				legacyAdjustmentCompanyLink(orgId: $orgId, request: $request)
			}
		`,
		{
			refetchQueries: [ALL_USER_ORG_PERMISSIONS],
		}
	);
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const onSubmit = async (redirect: boolean): Promise<boolean> => {
		return await linkOrg({
			variables: {
				orgId,
				request: {
					systemId,
					acid,
				},
			},
			update: (cache) => {
				cache.evict({
					id: 'ROOT_QUERY',
					fieldName: `affiliatedOrganizations`,
				});
				cache.evict({
					id: 'ROOT_QUERY',
					fieldName: `allUserOrgPermissions`,
				});
			},
		})
			.then(async (res) => {
				await refreshToken();
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Thank you for linking an Organization to Evolve.', {
					variant: 'success',
				});
				redirect && navigate(pathname + '/../../dashboard');
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		onSubmit,
		error: updateOrgStatus.error,
	};
};

const FindCompanyToLinkForm = ({
	onSubmit,
}: {
	onSubmit: ({ systemId, acid }: { systemId: number; acid: number }) => void;
}) => {
	return (
		<Formik<FindCompanyToLinkForm>
			initialValues={{ systemId: '', acid: '' }}
			onSubmit={(vals) => {
				onSubmit({ systemId: Number(vals.systemId), acid: Number(vals.acid) });
			}}
			validationSchema={validationSchema}
			enableReinitialize>
			{({ isSubmitting, errors, setErrors, submitForm, dirty, isValid, touched }) => (
				<>
					<ModalContent
						sx={{
							padding: 0,
						}}>
						<Stack
							display="flex"
							flexDirection="column"
							alignItems="center"
							padding={3}
							spacing={2}
							borderBottom={`1px solid ${theme.palette.divider}`}>
							<Typography variant="body1" className="text-neutral-900">
								You can find the following information on your FileTrac Legacy
								alerts page.
							</Typography>
							<TextField
								label="System ID"
								required
								type="text"
								name="systemId"
								error={touched.systemId && !!errors?.systemId}
								helperText={touched.systemId && errors?.systemId}
								onChangeCapture={() =>
									setErrors({
										...errors,
										systemId: undefined,
									})
								}
								FormHelperTextProps={{
									className: clsx(
										!!errors?.systemId && 'static',
										'text-left mb-1'
									),
								}}
								sx={{
									fieldset: { borderColor: 'neutral.700' },
								}}
							/>
							<TextField
								label="ACID"
								required
								type="text"
								name="acid"
								error={touched.acid && !!errors?.acid}
								helperText={touched.acid && errors?.acid}
								onChangeCapture={() =>
									setErrors({
										...errors,
										acid: undefined,
									})
								}
								FormHelperTextProps={{
									className: clsx(!!errors?.acid && 'static', 'text-left mb-1'),
								}}
								sx={{
									fieldset: { borderColor: 'neutral.700' },
								}}
							/>
						</Stack>
					</ModalContent>
					<ModalActions
						sx={{
							direction: 'row',
							spacing: 1,
							padding: 2,
							justifyContent: 'flex-end',
						}}>
						<ModalActionButton variant="outlined">Cancel</ModalActionButton>
						<LoadingButton
							data-test="loading-btn"
							variant="contained"
							color="primary"
							onClick={() => submitForm()}
							disabled={!dirty || !isValid}
							loading={isSubmitting}>
							Find Company
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};

const validationSchema = Yup.object({
	systemId: Yup.string().required(
		'System ID is required to find FileTrac Legacy Adjustment Company'
	),
	acid: Yup.string().required('ACID is required to find FileTrac Legacy Adjustment Company'),
});

type FindCompanyToLinkForm = {
	systemId: string;
	acid: string;
};

const useLegacyAdjustmentCompanyInfoLazy = () => {
	const [useLegacyAdjustmentCompanyInfoQuery, { data, error, loading }] = useLazyQuery<
		Pick<Query, 'legacyAdjustmentCompanyInfo'>,
		QueryLegacyAdjustmentCompanyInfoArgs
	>(
		gql`
			query legacyAdjustmentCompanyInfo($systemId: Int!, $acid: Int!) {
				legacyAdjustmentCompanyInfo(systemId: $systemId, acid: $acid) {
					name
					logo
					systemUrl
					systemId
					acid
				}
			}
		`,
		{
			fetchPolicy: 'cache-and-network',
			onError: () => {
				console.log(error);
			},
		}
	);

	const findCompany = ({ systemId, acid }: { systemId: number; acid: number }) => {
		useLegacyAdjustmentCompanyInfoQuery({
			variables: {
				systemId,
				acid,
			},
		});
	};

	return {
		findCompany,
		company: data?.legacyAdjustmentCompanyInfo,
		error,
		loading,
	};
};
