import { Box, Stack, StackProps } from '@mui/material';
import { forwardRef, useEffect, useRef } from 'react';

interface InfiniteScrollProps extends StackProps {
	loadMore: () => void;
	canLoadMore: boolean;
	reverse?: boolean;
	threshold?: number;
}

const InfiniteScroll = forwardRef(
	(
		{
			loadMore,
			canLoadMore,
			reverse = false,
			threshold = 0,
			children,
			...stackProps
		}: InfiniteScrollProps,
		ref
	) => {
		const _containerRef = useRef<HTMLDivElement>(null);
		const endElementRef = useRef<HTMLDivElement>(null);
		const containerRef = (ref as React.RefObject<HTMLDivElement>) ?? _containerRef;

		useEffect(() => {
			const observer = new IntersectionObserver(
				(entries) => {
					if (entries[0].isIntersecting && canLoadMore) {
						loadMore();
					}
				},
				{ root: containerRef.current }
			);
			if (endElementRef.current && canLoadMore) observer.observe(endElementRef.current);
			return () => {
				if (endElementRef.current) observer.unobserve(endElementRef.current);
			};
		}, [loadMore, canLoadMore]);

		return (
			<Stack
				{...stackProps}
				ref={containerRef}
				overflow="auto"
				flexDirection={reverse ? 'column-reverse' : 'column'}>
				{children}
				<Box position="relative">
					<Box
						ref={endElementRef}
						position="absolute"
						height={threshold}
						sx={reverse ? { top: 0 } : { bottom: 0 }}
					/>
				</Box>
			</Stack>
		);
	}
);

InfiniteScroll.displayName = 'InfiniteScroll';
export { InfiniteScroll };
