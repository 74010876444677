import { gql, useQuery } from '@apollo/client';
import { PageError } from 'utils/errors';
import {
	EmblemEntityType,
	Query,
	QueryAccountArgs,
	QueryEmblemArgs,
	UserAccount,
} from 'middleware-types';
import { useSession } from './session';

export const NAME_FIELDS = gql`
	fragment NameFields on PersonName {
		firstName
		lastName
		middleName
		suffix
	}
`;

/**
 * ACCOUNT - query to pull a users account information.
 * @type {*}
 * */ /** @type {*} */
const ACCOUNT = gql`
	${NAME_FIELDS}
	query account($userId: ID!, $entityType: EmblemEntityType!, $entityId: ID!) {
		account(userId: $userId) {
			id
			siteUserId
			name {
				...NameFields
			}
			handle
			emailAddress
			secondaryEmailAddress
			birthDate
			languageIds
			lastUpdatedUtc
			deactivated
		}
		emblem(entityType: $entityType, entityId: $entityId) {
			id
			displayName
			avatarFile {
				fileId
				file {
					id
					currentInstance {
						id
						cdnUrl
						fileName
						fileSize
						virusStatus
						uploadedUtc
					}
				}
			}
		}
	}
`;

/**
 * useAccount() - gets the account name, email and profile picture.
 * @returns
 */
export const useAccount = (
	userId?: string
): {
	loading: boolean;
	account?: Pick<UserAccount, 'id' | 'name' | 'emailAddress' | 'avatarFile' | 'handle'>;
} => {
	const { user } = useSession();
	const { loading, data, error } = useQuery<
		Pick<Query, 'account' | 'emblem'>,
		QueryAccountArgs & QueryEmblemArgs
	>(ACCOUNT, {
		fetchPolicy: 'cache-first',
		variables: {
			userId: userId ?? user.userId,
			entityType: EmblemEntityType.User,
			entityId: userId ?? user.userId,
		},
	});

	if (error) throw new PageError(error);
	return { loading, account: data?.account };
};
