import {
	DatePicker,
	DatePickerProps,
	DateTimePicker,
	DateTimePickerProps,
	DateTimeValidationError,
} from '@mui/x-date-pickers';
import { useField, useFormikContext } from 'formik';

/**
 * Date Picker props.
 */
export interface DatePickerFieldProps {
	name: string;
	label?: string;
	required?: boolean;
	masked?: boolean;
	disabled?: boolean;
	className?: string;
	onAccept?: (value: Date | null) => void;
	includeTime?: boolean;
}

/**
 * DatePicker component for Formik forms. Set mask to true to allow the user to hide the value.
 * @param props
 * @returns
 */
export const DatePickerField = (props: DatePickerFieldProps) => {
	const [field, meta, helpers] = useField(props.name);
	const formik = useFormikContext();

	const pickerProps: DatePickerProps<Date> & DateTimePickerProps<Date> = {
		label: props.label,
		value: field.value,
		className: props.className,
		onAccept: props.onAccept,
		slotProps: {
			textField: {
				error: meta.touched && !!meta.error,
				helperText: meta.touched ? meta.error : undefined,
				required: props.required,
				onBlur: (event) => {
					helpers.setTouched(true, true);
					if (props.required && !event.target.value) helpers.setError('Required Field');
				},
			},
		},
		disabled: props.disabled || formik.isSubmitting,
		// Currently the formik-mui-lab package does not handle field level errors, so we need to
		// do our own validation checks and load the errors into Formik. This can be removed if/when
		// formik-mui(-lab) is updated to handle this.
		// For more info on this implementation see: https://next.material-ui-pickers.dev/guides/forms
		onError: (reason: DateTimeValidationError, value: Date | null) => {
			if (props.required && value === null) {
				helpers.setError('Required Field');
				return;
			}
			if (value && isNaN(value.getTime())) {
				// Check if the date is invalid (e.g., MM/13/YYYY would result in an invalid Date object)
				helpers.setError('Invalid date');
				return;
			}
			switch (reason) {
				case 'invalidDate':
				case 'minDate':
				case 'maxDate':
				case 'shouldDisableDate':
					helpers.setError('Invalid date');
					break;
				case 'disablePast':
					helpers.setError('Past dates are not allowed');
					break;
				case 'disableFuture':
					helpers.setError('Future dates are not allowed');
					break;
				default:
					helpers.setError('');
			}
		},
		onChange: (value: Date | null) => {
			// Do not switch this order, otherwise you might cause a race condition
			// See https://github.com/formium/formik/issues/2083#issuecomment-884831583
			helpers.setTouched(true, false);
			helpers.setValue(value);

			// Run "required" validation check here, this is because if we run the validate callback
			// it will clear out our errors from the onError callback.
			if (props.required) {
				if (!value) helpers.setError('Required Field');
				else if (!meta.error || meta.error === 'Required Field')
					helpers.setError(undefined);
			}
		},
	};

	return props.includeTime ? (
		<DateTimePicker {...pickerProps} viewRenderers={{ hours: null, minutes: null }} />
	) : (
		<DatePicker {...pickerProps} />
	);
};
