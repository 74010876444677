import clsx from 'clsx';
import { HTMLAttributes } from 'react';
import 'flag-icons/css/flag-icons.min.css';

// country code regex
const CC_REGEX = /^[a-z]{2}$/i;

// offset between uppercase ascii and regional indicator symbols
const OFFSET = 127397;

/**
 * convert country code to corresponding flag emoji
 * @param {string} cc - country code string
 * @returns {string} flag emoji
 */
export function countryCodeEmoji(cc: string): string | undefined {
	if (!CC_REGEX.test(cc)) {
		return undefined;
	}

	const codePoints = [...cc.toUpperCase()].map((c) => (c.codePointAt(0) ?? 0) + OFFSET);
	return String.fromCodePoint(...codePoints);
}

export const Flag = ({ code, className }: { code: string } & HTMLAttributes<HTMLSpanElement>) => (
	<span className={clsx(`fi fi-${code.toLowerCase()}`, className)} />
);
