import { LoadingButton } from '@mui/lab';
import { Link, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import ResetPassword from 'components/pages/auth/reset-password';
import { emailValidation } from 'components/ui/LoginYups';
import { TextField } from 'components/ui/fields';
import { PageTitle } from 'components/ui/page';
import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import * as Yup from 'yup';

const ForgotPasswordPage = () => {
	const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string | null>(null);
	const largeScreen = useWindowDimensions();

	const handleSubmit = async (
		vals: ForgotPasswordForm,
		helpers: FormikHelpers<ForgotPasswordForm>
	) => {
		try {
			await Auth.forgotPassword(vals.email);
			setForgotPasswordEmail(vals.email);
		} catch (e: any) {
			console.log(e);
			helpers.setErrors({ email: e?.message });
		}
	};

	if (forgotPasswordEmail) return <ResetPassword email={forgotPasswordEmail} />;

	return (
		<>
			<PageTitle title="Forgot Password" />
			<Formik<ForgotPasswordForm>
				initialValues={{ email: '' }}
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
				validateOnBlur={false}
				validateOnChange={false}>
				{({ isSubmitting, errors, setErrors }) => (
					<Form className="m-auto flex w-96 flex-col text-center">
						<img
							className={clsx(' mx-auto mb-10 w-32', {
								hidden: largeScreen,
							})}
							src="/img/evolve-logo.svg"
							alt="FTEvolve"
						/>
						<Typography mb={2} variant="h2" className="text-neutral-900">
							Forgot your password?
						</Typography>
						<Typography mb={2} className="text-neutral-900">
							Enter your Email Address below and we will send a message to reset your
							password.
						</Typography>
						<div className="flex flex-col gap-3">
							<TextField
								label="Email Address *"
								type="email"
								name="email"
								InputLabelProps={{ shrink: true }}
								error={!!errors?.email}
								helperText={errors?.email}
								onChangeCapture={() =>
									setErrors({
										...errors,
										email: undefined,
									})
								}
								FormHelperTextProps={{
									className: clsx(!!errors?.email && 'static', 'text-left mb-1'),
								}}
								sx={{
									fieldset: { borderColor: 'neutral.700' },
								}}
							/>
						</div>
						<LoadingButton
							className="mt-4 text-white "
							sx={{ marginTop: '1rem' }}
							data-test="loading-btn"
							variant="contained"
							color="primary"
							fullWidth
							type="submit"
							loading={isSubmitting}>
							Reset my password
						</LoadingButton>
						<Typography className="mt-5">
							<Link data-test="back-btn" className="text-navy-500" href="/auth/login">
								Back to Log in
							</Link>
						</Typography>
					</Form>
				)}
			</Formik>
		</>
	);
};

const validationSchema = Yup.object({
	email: emailValidation,
});

export type ForgotPasswordForm = {
	email: string;
};

export default ForgotPasswordPage;
