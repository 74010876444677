import { gql, useMutation } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	FolderReference,
	Mutation,
	MutationFolderCreateArgs,
	UpsertFolderRequest,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useDocumentsContext } from '../../documents-page';
import { FOLDER_INFO_FIELDS } from '../../utils/fragments.graphql';
import { updateFolderContents } from '../../utils/cache-helpers';

const CREATE_FOLDER = gql`
	${FOLDER_INFO_FIELDS}
	mutation CreateFolder(
		$entityType: FoldersApiEntityType!
		$entityId: String!
		$request: UpsertFolderRequest!
	) {
		folderCreate(entityType: $entityType, entityId: $entityId, request: $request) {
			...FolderInfoFields
		}
	}
`;

export const useCreateFolder = () => {
	const toast = useToast();
	const { entityType, entityId } = useDocumentsContext();

	const [_createFolder] = useMutation<Pick<Mutation, 'folderCreate'>, MutationFolderCreateArgs>(
		CREATE_FOLDER
	);

	const createFolder = async (request: UpsertFolderRequest) => {
		return await _createFolder({
			variables: { entityType, entityId, request },
			update: (cache, { data }) => {
				if (!data) return;
				updateFolderContents(
					{ cache, folderId: request.parentFolderId, entityType, entityId },
					(existing) => {
						const newFolder: FolderReference = {
							__typename: 'FolderReference',
							id: data.folderCreate.id,
							name: data.folderCreate.name,
							parentFolder: (data.folderCreate.folderAncestors?.[0] ?? null) as any,
						};
						return {
							...existing,
							folders: [...(existing.folders ?? []), newFolder],
						};
					}
				);
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Folder created successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return createFolder;
};
