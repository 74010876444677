import { Close, EmailOutlined, NotificationsNoneOutlined, SendOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Dialog,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import { useEditor } from '@tiptap/react';
import { removeTagsFromString } from 'components/pages/communications/helpers/utils';
import { SelectField, TextField } from 'components/ui/fields';
import { extensions } from 'components/ui/rich-text-editor/extensions';
import { Formik, FormikErrors, FormikProps, useFormikContext } from 'formik';
import {
	OrganizationNotificationAudience,
	OrganizationNotificationRequest,
	SystemNotificationAudience,
	SystemNotificationRequest,
} from 'middleware-types';
import { useIsMobile } from 'utils/useScreenSize';
import { useValidation } from 'utils/useValidation';
import { RichTextContent } from '../rich-text-editor/rich-text-content';
import { RichTextToolbar } from '../rich-text-editor/toolbar/rich-text-toolbar';
import {
	useCreateOrganizationNotification,
	useCreateSystemNotification,
} from './hooks/notifications-hooks';

interface SendNotificationFormValues {
	audience: SystemNotificationAudience[] | OrganizationNotificationAudience[];
	inAppText: string;
	inAppUrl: string;
	emailSubject: string;
	emailHtmlBody: string;
	smsText: string;
	smsSendAsBulk: boolean;
}

interface SendNotificationModalProps {
	open: boolean;
	onClose: () => void;
	orgId?: string;
}

export const SendNotificationModal = ({ open, onClose, orgId }: SendNotificationModalProps) => {
	const isMobile = useIsMobile();
	const notificationType = orgId ? 'Organization' : 'System';

	return (
		<Dialog open={open} onClose={onClose} fullScreen={isMobile} fullWidth maxWidth="md">
			<Stack height={{ xs: '100%', sm: 'auto' }} overflow="hidden">
				<Stack
					px={{ xs: 1.5, sm: 2.5 }}
					py={1}
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					spacing={1}>
					<Typography variant="h4">Send {notificationType} Announcement</Typography>
					<IconButton onClick={onClose}>
						<Close />
					</IconButton>
				</Stack>
				<Divider />
				<Content onClose={onClose} notificationType={notificationType} orgId={orgId} />
			</Stack>
		</Dialog>
	);
};

const Content = ({
	onClose,
	notificationType,
	orgId,
}: {
	onClose: () => void;
	notificationType: string;
	orgId?: string;
}) => {
	const initialValues: SendNotificationFormValues = {
		audience:
			notificationType === 'System'
				? [SystemNotificationAudience.SocialUsers]
				: [OrganizationNotificationAudience.AllAssociates],
		inAppText: '',
		inAppUrl: '',
		emailSubject: '',
		emailHtmlBody: '',
		smsText: 'Not Applicable',
		smsSendAsBulk: true,
	};

	const validate = (values: SendNotificationFormValues) => {
		const errors: FormikErrors<SendNotificationFormValues> = {};
		if (!removeTagsFromString(values.emailHtmlBody)) errors.emailHtmlBody = 'Required Field';
		return errors;
	};

	const validation = useValidation(
		notificationType === 'System'
			? 'SystemNotificationRequest'
			: 'OrganizationNotificationRequest'
	);

	const audienceOptions =
		notificationType === 'System'
			? [
					{ value: SystemNotificationAudience.SocialUsers, label: 'Social Users' },
					{ value: SystemNotificationAudience.OrgAdmins, label: 'Organization Admins' },
			  ]
			: [
					{
						value: OrganizationNotificationAudience.AllAssociates,
						label: 'All Associates',
					},
					{
						value: OrganizationNotificationAudience.Administrators,
						label: 'Organization Admins',
					},
			  ];

	const createSystemNotification = useCreateSystemNotification();
	const createOrganizationNotification = useCreateOrganizationNotification();

	const onSubmit = async (values: SendNotificationFormValues) => {
		if (notificationType === 'System') {
			const request: SystemNotificationRequest = {
				audience: values.audience as SystemNotificationAudience[],
				inAppText: values.inAppText,
				inAppUrl: values.inAppUrl || undefined,
				emailSubject: values.emailSubject,
				emailHtmlBody: values.emailHtmlBody,
				smsText: values.smsText,
				smsSendAsBulk: values.smsSendAsBulk,
			};
			const success = await createSystemNotification(request);
			if (success) {
				onClose();
			}
		} else {
			const request: OrganizationNotificationRequest = {
				audience: values.audience as OrganizationNotificationAudience[],
				inAppText: values.inAppText,
				emailSubject: values.emailSubject,
				emailHtmlBody: values.emailHtmlBody,
				smsText: values.smsText,
				smsSendAsBulk: values.smsSendAsBulk,
			};
			const success = await createOrganizationNotification(orgId!, request);
			if (success) {
				onClose();
			}
		}
	};

	// const toolTipText = `Sending notifications using "Bulk SMS" will allow those users who have chosen to receive ${notificationType.toLowerCase()} notifications via SMS to receive them faster, but limits Evolve's ability to track whether the messages have been delivered. If "Bulk SMS" is not used, detailed status will be available, however it may take minutes to hours for message delivery, depending on the number of recipients.`;

	return (
		<Formik<SendNotificationFormValues>
			onSubmit={onSubmit}
			initialValues={initialValues}
			validate={validate}
			validationSchema={validation.schema}>
			{({
				isValid,
				submitForm,
				isSubmitting,
				dirty,
			}: FormikProps<SendNotificationFormValues>) => (
				<>
					<Stack flex={1} overflow="auto">
						<Stack spacing={2} px={{ xs: 1.5, sm: 2.5 }} py={2.5}>
							<Typography variant="h4">
								Who should this announcement be sent to?
							</Typography>
							<SelectField label="Audience" name="audience" multiple required>
								{audienceOptions.map((audience) => (
									<MenuItem key={audience.label} value={audience.value}>
										{audience.label}
									</MenuItem>
								))}
							</SelectField>
						</Stack>
						<Divider />
						<Stack spacing={2} py={2.5} px={{ xs: 1.5, sm: 2.5 }}>
							<Typography variant="h4">
								{/* Configure announcement text for in app, email and SMS. */}
								Configure announcement text for in app and email.
							</Typography>
							<Stack spacing={2}>
								<Stack flexDirection="row" gap={1} alignItems="center">
									<NotificationsNoneOutlined fontSize="small" />
									<Typography variant="h5">In App</Typography>
								</Stack>
								<TextField label="In App Text" required name="inAppText" />
								{notificationType === 'System' && (
									<TextField label="In App Url" name="inAppUrl" />
								)}
							</Stack>
							{/* <Stack spacing={2}>
								<Stack flexDirection="row" gap={1} alignItems="center">
									<TextsmsOutlined fontSize="small" />
									<Typography variant="h5">SMS</Typography>
								</Stack>
								<Box>
									<TextField label="SMS Text" name="smsText" multiline required />
									<Stack flexDirection="row" alignItems="center">
										<Tooltip title={toolTipText}>
											<FormControlLabel
												control={
													<CheckboxField
														disabled={notificationType === 'Associate'}
														name="smsSendAsBulk"
													/>
												}
												label="Enable Bulk SMS"
											/>
										</Tooltip>
									</Stack>
								</Box>
							</Stack> */}
							<Stack spacing={2}>
								<Stack flexDirection="row" gap={1} alignItems="center">
									<EmailOutlined fontSize="small" />
									<Typography variant="h5">Email</Typography>
								</Stack>
								<TextField label="Email Subject" required name="emailSubject" />
							</Stack>
							<EmailBodyBox />
						</Stack>
					</Stack>
					<Divider />
					<Stack direction="row" justifyContent="flex-end" py={1.5} px={2} gap={1.5}>
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							variant="contained"
							color="primary"
							startIcon={<SendOutlined />}
							disabled={!isValid || !dirty}
							loading={isSubmitting}
							onClick={submitForm}>
							Send Announcement
						</LoadingButton>
					</Stack>
				</>
			)}
		</Formik>
	);
};

const EmailBodyBox = () => {
	const { setFieldValue } = useFormikContext<SendNotificationFormValues>();
	const editor = useEditor({
		extensions,
		onUpdate: ({ editor }) => {
			setFieldValue('emailHtmlBody', editor.getHTML());
		},
	});

	return (
		<Stack spacing={1}>
			<Typography variant="h6">Email Body *</Typography>
			<Stack spacing={1} flex={1} overflow="hidden" minHeight={200} maxHeight={350}>
				<RichTextToolbar editor={editor} />
				<Stack
					position="relative"
					flex={1}
					overflow="hidden"
					border="1px solid"
					borderColor="neutral.200"
					bgcolor="neutral.50"
					borderRadius={1}>
					<RichTextContent editor={editor} flex={1} overflow="auto" p={1.5} />
					{editor?.isEmpty && (
						<Box
							position="absolute"
							top={0}
							width="100%"
							p={1.5}
							sx={{ pointerEvents: 'none' }}>
							<Typography variant="body2" color="GrayText" display="inline">
								Type a message here
							</Typography>
						</Box>
					)}
				</Stack>
			</Stack>
		</Stack>
	);
};
