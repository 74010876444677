import { ApolloError } from '@apollo/client';
import { Alert as MaterialAlert, Typography } from '@mui/material';
import { ErrorOutlineRounded } from '@mui/icons-material';
import { fallbackErrorMessage } from 'utils/errors';
import React from 'react';

export const Alert = (props: {
	error?: ApolloError;
	severity?: 'error' | 'warning' | 'info' | 'success';
}): React.JSX.Element => {
	const extensions: any = props.error?.graphQLErrors[0]?.extensions;
	let errorHeading = null;

	let errorMessages: string[] =
		props && props.error && props.error.graphQLErrors
			? props.error.graphQLErrors
					.filter((e) => !!e.extensions)
					.map((e) => (e.extensions.userMessage as string) ?? fallbackErrorMessage)
			: [];

	if (extensions?.response?.status === 422) {
		errorHeading = extensions?.response?.body.description;
		errorMessages = Object.values(extensions?.response?.body.details);
	}

	if (errorMessages.length === 0) {
		return <></>;
	}

	const alertColor = props.severity || 'error';

	return (
		<MaterialAlert
			icon={<ErrorOutlineRounded fontSize="inherit" color={alertColor} />}
			severity={alertColor}>
			{errorMessages.length === 1 ? (
				errorMessages[0]
			) : (
				<>
					{errorHeading && <Typography variant="body1">{errorHeading}</Typography>}
					<ul>
						{errorMessages.map((err, i) => (
							<li key={i}>{err}</li>
						))}
					</ul>
				</>
			)}
		</MaterialAlert>
	);
};
