import { gql, useLazyQuery } from '@apollo/client';
import {
	ActivationStatus,
	PeopleSearchResult,
	Query,
	QueryPeopleSearchArgs,
	UserType,
} from 'middleware-types';
import { EntitySearchFormValues } from 'pages/site/system-search/system-search';

/* Query for getting a list of users from a peopleSearch. */
export const PEOPLESYSTEMSEARCH = gql`
	query peopleSearch(
		$searchText: String!
		$userType: UserType!
		$activationStatus: ActivationStatus
		$affiliation: String
		$companyName: String
	) {
		peopleSearch(
			searchText: $searchText
			userType: $userType
			activationStatus: $activationStatus
			affiliation: $affiliation
			companyName: $companyName
		) {
			firstName
			lastName
			emailAddress
			secondaryEmailAddress
			cellPhone {
				countryCode
				number
			}
			homePhone {
				countryCode
				number
			}
			companyName
			affiliation
			siteRole
			userActive
			userId
			siteUserId
			siteUserInvitationId
			avatarFile {
				file {
					currentInstance {
						cdnUrl
					}
				}
			}
			handle
		}
	}
`;

/**
 *  People search row props.
 */
type PeopleSearchRowProps = PeopleSearchResult & {
	status: 'Active' | 'Inactive' | undefined;
	refetch: () => void;
	userType?: string;
};

/**
 * usePeopleSystemSearch() is a custom hook to handle all the data-loading
 * and processing for the people search form.
 *
 * @return {*}  {{
 * 	submit: (values: EntitySearchFormValues) => Promise<void>;
 * 	initialValues: EntitySearchFormValues
 * }}
 */

export const usePeopleSystemSearch = () => {
	const [getPeopleSearchResults, { loading, data, error, refetch }] = useLazyQuery<
		Pick<Query, 'peopleSearch'>,
		QueryPeopleSearchArgs
	>(PEOPLESYSTEMSEARCH, {
		fetchPolicy: 'cache-and-network',
	});

	// Performs the search when the Search button, magnifying icon, or enter is pressed.
	const search = async (values: EntitySearchFormValues) => {
		// Prevent searching if the search text is empty.
		if (!values.searchText) {
			return;
		}

		const activationStatus = values.activationStatus.filter((v) => v) as ActivationStatus[];

		await getPeopleSearchResults({
			variables: {
				...values,
				userType: values.entitySystemSearchType as UserType,
				affiliation:
					values.entitySystemSearchType === 'SiteUser' ||
					values.entitySystemSearchType === 'SiteUserInvitation'
						? values.affiliation
						: '',
				companyName:
					values.entitySystemSearchType === 'SocialUser' ? values.companyName : '',
				activationStatus: activationStatus.length === 1 ? activationStatus[0] : undefined,
			},
		});
	};

	const rows: PeopleSearchRowProps[] | undefined = data?.peopleSearch?.map((result) => ({
		...result,
		status: result.userActive ? 'Active' : 'Inactive',
		refetch: () => (refetch ? refetch() : undefined),
	}));

	return { search, loading, error, rows };
};
