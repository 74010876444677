import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { ContainedDrawer } from 'components/ui/containedDrawer';
import { TextField } from 'components/ui/fields';
import { Formik, FormikProps } from 'formik';
import { SaveConversationRequest } from 'middleware-types';
import { useValidation } from 'utils/useValidation';
import { useSaveConversation } from './hooks';

interface SaveDrawerProps {
	open: boolean;
	onClose: () => void;
	conversationId: string;
	conversationName?: string;
	setConversationName: React.Dispatch<React.SetStateAction<string | null>>;
}

export const SaveDrawer = ({
	open,
	onClose,
	conversationId,
	conversationName = '',
	setConversationName,
}: SaveDrawerProps) => {
	const validation = useValidation('SaveConversationRequest');
	const saveConversation = useSaveConversation();

	const onSubmit = async (request: SaveConversationRequest) => {
		const res = await saveConversation(conversationId, request);
		if (res) {
			if (typeof res === 'string') {
				setConversationName(res);
			}
			onClose();
		}
	};

	const isEdit = Boolean(conversationName);

	return (
		<ContainedDrawer
			open={open}
			anchor="top"
			containerid="evox-container"
			PaperProps={{ sx: { borderRadius: 1 } }}>
			<Stack p={2} spacing={1}>
				<Typography variant="h3">
					{isEdit ? 'Edit Name' : 'Pin This Conversation'}
				</Typography>
				{!isEdit && (
					<Typography variant="body2">
						Pinned conversations remain accessible from the EVO-X menu and can be
						reloaded and resumed later.
					</Typography>
				)}
				<Formik<SaveConversationRequest>
					onSubmit={onSubmit}
					validationSchema={validation.schema}
					initialValues={{ conversationName }}
					enableReinitialize>
					{(props: FormikProps<SaveConversationRequest>) => (
						<Stack pt={0.5} spacing={3}>
							<TextField name="conversationName" label="Conversation Name" required />
							<Stack direction="row" spacing={1} justifyContent="flex-end">
								<LoadingButton
									loading={props.isSubmitting}
									variant="outlined"
									color="primary"
									onClick={onClose}>
									Cancel
								</LoadingButton>
								<LoadingButton
									loading={props.isSubmitting}
									variant="contained"
									color="primary"
									disabled={!props.isValid || !props.dirty || props.isSubmitting}
									onClick={() => {
										props.submitForm();
									}}>
									Save
								</LoadingButton>
							</Stack>
						</Stack>
					)}
				</Formik>
			</Stack>
		</ContainedDrawer>
	);
};
