import { ErrorOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Grid, MenuItem, Skeleton, TextField } from '@mui/material';
import { Alert } from 'components/ui/alert';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { SelectField } from 'components/ui/fields';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalContent,
	useModal,
} from 'components/ui/modal';
import { useToast } from 'components/ui/toast';
import { Formik } from 'formik';
import { AssociateOrInvitation, AssociateUpdate } from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useSession } from 'utils/session';
import { useValidation } from 'utils/useValidation';
import {
	useAllOrgRolesQuery,
	useDeactivateAssociatenMutation,
	useReactivateAssociateMutation,
} from '../hooks';

type AssociateProps = {
	orgId: string;
	associate: AssociateOrInvitation;
};

/**
 * useDeactivateAssociateModal- Creates an org associate deactivation modal.
 *
 * @param {string} orgId
 * @param {string} associateId
 * @param {string} displayName
 * @return {*}
 */
export const useDeactivateAssociateModal = (
	orgId: string,
	associateId: string,
	displayName: string
) => {
	const { showModal } = useModal();
	const { deactivateAssociate } = useDeactivateAssociatenMutation();

	const openDeactivateAssociate = () => {
		showModal({
			title: 'Deactivate Associate',
			content: (
				<ConfirmModalContent
					visual={
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'error.50' }}
							iconProps={{ color: 'error.500' }}
							icon={<ErrorOutlined />}
						/>
					}
					subheadline={`Deactivate Associate`}
					informativeContent={`Are you sure you want to deactivate ${displayName}?`}
				/>
			),
			actions: (
				<ModalActions>
					<ModalActionButton variant="outlined">Cancel</ModalActionButton>
					<ModalActionButton
						color="error"
						variant="contained"
						onClick={() => deactivateAssociate(orgId, associateId)}>
						Deactivate
					</ModalActionButton>
				</ModalActions>
			),
		});
	};

	return { openDeactivateAssociate };
};

/**
 * useReactivateAssociate - Custom hook for getting validation schema, handling submit, and loading.
 *
 * @returns {({
 * 	initialValues: AssociateProps;
 * 	roles: AllOrgRoles;
 * 	validationSchema: ValidationSchema | null;
 * 	submit: (values: MAssociateInvitationRequest) => Promise<void>;
 * 	loading: boolean;
 * 	error: ApolloError | undefined;
 * })}
 */
const useReactivateAssociate = ({ orgId, associate }: AssociateProps) => {
	const validation = useValidation('AssociateUpdate');
	const toast = useToast();
	const { closeModal } = useModal();
	const { reactivateAssociate, error } = useReactivateAssociateMutation(
		orgId,
		associate.associateId!
	);
	const rolesQuery = useAllOrgRolesQuery(orgId);

	const initialValues: AssociateUpdate = {
		emailAddress: associate.emailAddress,
		displayName: associate.displayName,
		organizationRoleId: associate.organizationRoleId,
		displayOnProfile: associate.displayOnProfile ?? false,
	};

	/**
	 * When the form submits
	 */
	const submit = async (values: AssociateUpdate) => {
		return await reactivateAssociate(values.organizationRoleId)
			.then(async (res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push(`Successfully updated associate ${values.displayName}.`, {
					variant: 'success',
				});
				closeModal();
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		initialValues,
		roles: rolesQuery.roles,
		submit,
		validationSchema: validation.schema,
		loading: validation.loading || rolesQuery.loading,
		error,
	};
};

/**
 * Associate Reactivate Modal
 *
 * @param {AssociateProps}
 * @returns
 */
export const useReactivateAssociateModal = (props: AssociateProps) => {
	const { showModal } = useModal();

	const reactivateAssociate = () => {
		showModal({
			title: `Reactivate Associate ${props.associate.displayName}`,
			content: <ReactivateAssociateForm {...props} />,
		});
	};
	return { reactivateAssociate };
};

/**
 * Associate Reactivation Form
 *
 * @param {UpdateAssociateProps} associate
 * @returns
 */
const ReactivateAssociateForm = (props: AssociateProps) => {
	const { user } = useSession();
	const { initialValues, roles, loading, submit, validationSchema, error } =
		useReactivateAssociate(props);

	if (loading) return <SkeletonForm />;

	return (
		<Formik<AssociateUpdate>
			onSubmit={submit}
			validationSchema={validationSchema}
			initialValues={initialValues}
			enableReinitialize>
			{(formik) => (
				<>
					<ModalContent>
						<Grid container spacing={1} columnSpacing={2}>
							<Grid xs={12} sm={6} md={6} item>
								<SelectField
									label="Role"
									required
									name="organizationRoleId"
									value={formik.values.organizationRoleId ?? ''}
									disabled={user.userId === props.associate.userId}
									fullWidth>
									{roles.map((src, i) => (
										<MenuItem key={i} value={src.id}>
											{src.name !== '' ? src.name : <em>None</em>}
										</MenuItem>
									))}
								</SelectField>
							</Grid>
							{error && (
								<Grid xs={12} md={12} item>
									<Alert error={error} />
								</Grid>
							)}
						</Grid>
					</ModalContent>
					<ModalActions>
						<ModalActionButton variant="outlined">Cancel</ModalActionButton>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
							loading={formik.isSubmitting}
							onClick={() => formik.submitForm()}>
							Reactivate Associate
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};

const SkeletonForm = (): React.JSX.Element => (
	<ModalContent>
		<Grid container spacing={1} columnSpacing={2}>
			<Grid xs={12} md={6} item>
				<Skeleton animation="wave" width="100%">
					<TextField fullWidth />
				</Skeleton>
			</Grid>
			<Grid xs={12} sm={6} md={6} item>
				<Skeleton animation="wave" width="100%">
					<TextField fullWidth />
				</Skeleton>
			</Grid>
			<Grid xs={12} sm={6} md={6} item>
				<Skeleton animation="wave" width="100%">
					<TextField fullWidth />
				</Skeleton>
			</Grid>
		</Grid>
	</ModalContent>
);
