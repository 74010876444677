import { CommunicationsPage } from 'components/pages/communications/communications-page';
import { useOrgId } from 'components/pages/org/outlet';
import { ConversationApiEntityType } from 'middleware-types';
import { Permission } from 'utils/permissions';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';

export const OrgMessagesPage = () => {
	const orgId = useOrgId();

	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const canUpdate =
		hasAssociateUserPermission(Permission.Org_Messages_U) ||
		hasSiteUserPermission(Permission.Site_OrgMsgs_U);

	return (
		<CommunicationsPage
			entityType={ConversationApiEntityType.Organizations}
			entityId={orgId}
			readOnly={!canUpdate}
		/>
	);
};
