import { TableCell, TableSortLabel } from '@mui/material';
import React from 'react';

/**
 * SortableColumnCell - A TableCell column with a label that can be clicked to sort.
 * This can be generalized into a separate file, however, at the moment
 * This is the only place that uses it.
 *
 * @param {SortableColumnCellProps} props
 * @returns
 */
const SortableColumnCell = (props: SortableColumnCellProps): React.JSX.Element => {
	return (
		<TableCell key={props.column} align="left" className={props.className}>
			<TableSortLabel
				onClick={(): void => props.onSort(props.column)}
				active={props.active}
				direction={props.direction}>
				{props.children}
			</TableSortLabel>
		</TableCell>
	);
};

export type SortableColumnCellProps = {
	style?: React.CSSProperties;
	className?: string;
	column: string;
	active: boolean;
	children: React.JSX.Element | React.JSX.Element[] | string | undefined | null;
	direction: 'asc' | 'desc' | undefined;
	onSort: (key: string) => void;
};

export default SortableColumnCell;
