import { gql, useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	Mutation,
	MutationPatchOrgConfigurationSettingsArgs,
	OrgDashboardConfigurationPatch,
	Query,
	QueryGetOrgConfigurationSettingsArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';

const ORG_DASHBOARD_SETTINGS_FIELDS = gql`
	fragment OrgDashboardSettingsFields on OrgDashboardConfigurationResponse {
		id
		primaryColor
		secondaryColor
		title
		subTitle
	}
`;

const GET_ORG_DASHBOARD_SETTINGS = gql`
	${ORG_DASHBOARD_SETTINGS_FIELDS}
	query GetOrgDashboardSettings($orgId: ID!) {
		getOrgConfigurationSettings(orgId: $orgId) {
			...OrgDashboardSettingsFields
		}
	}
`;

export const useOrgDashboardSettings = (orgId: string) => {
	const { data, loading } = useQuery<
		Pick<Query, 'getOrgConfigurationSettings'>,
		QueryGetOrgConfigurationSettingsArgs
	>(GET_ORG_DASHBOARD_SETTINGS, {
		variables: { orgId },
	});

	return {
		settings: data?.getOrgConfigurationSettings,
		loading,
	};
};

const PATCH_ORG_DASHBOARD_SETTINGS = gql`
	${ORG_DASHBOARD_SETTINGS_FIELDS}
	mutation PatchOrgDashboardSettings($orgId: ID!, $patch: OrgDashboardConfigurationPatch!) {
		patchOrgConfigurationSettings(orgId: $orgId, patch: $patch) {
			...OrgDashboardSettingsFields
		}
	}
`;

export const usePatchOrgDashboardSettings = () => {
	const toast = useToast();

	const [_patchOrgDashboardSettings] = useMutation<
		Pick<Mutation, 'patchOrgConfigurationSettings'>,
		MutationPatchOrgConfigurationSettingsArgs
	>(PATCH_ORG_DASHBOARD_SETTINGS);

	const patchOrgDashboardSettings = async (
		orgId: string,
		patch: OrgDashboardConfigurationPatch
	) => {
		return await _patchOrgDashboardSettings({ variables: { orgId, patch } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Dashboard theme updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { patchOrgDashboardSettings };
};
