import { Stack } from '@mui/material';
import EmergencyContact from 'components/pages/user/account/emergency-contact/emergency-contact';
import { LocationInformation } from 'components/pages/user/account/location-info';
import { PersonalInfo } from 'components/pages/user/account/personal/personal-info';

/**
 * AccountScreen - The root account settings screen and routes.
 * @returns
 */
export const UserAccountPage = ({ userId }: { userId: string }) => {
	return (
		<Stack spacing={2}>
			<PersonalInfo userId={userId} />
			<LocationInformation userId={userId} />
			<EmergencyContact userId={userId} />
		</Stack>
	);
};
