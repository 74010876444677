import { LoadingButton } from '@mui/lab';
import { Box, FormControlLabel, Radio, Stack, Typography } from '@mui/material';
import { DatePickerField } from 'components/ui/fields';
import { RadioGroupField } from 'components/ui/fields/radio-group';
import { ModalActionButton, ModalActions, ModalContent, useModal } from 'components/ui/modal';
import { Formik, FormikErrors, FormikProps } from 'formik';
import {
	ConversationParticipant,
	Emblem,
	Participant,
	ParticipantHistoryType,
} from 'middleware-types';
import { convertEntityType } from '../helpers/utils';
import { useAddParticipants } from '../hooks/participants-hooks';
import { ParticipantSearchField } from '../shared/participant-search-field';

interface AddParticipantsFormValues {
	participants: Emblem[];
	historyType: ParticipantHistoryType;
	historyShareFromUtc: Date | null;
}

interface AddParticipantsModalProps {
	existingParticipants: ConversationParticipant[];
}

export const AddParticipantsModal = ({ existingParticipants }: AddParticipantsModalProps) => {
	const { closeModal } = useModal();

	const initialValues: AddParticipantsFormValues = {
		participants: [],
		historyType: ParticipantHistoryType.All,
		historyShareFromUtc: null,
	};

	const validate = (values: AddParticipantsFormValues) => {
		const errors: FormikErrors<AddParticipantsFormValues> = {};
		if (values.participants.length === 0) errors.participants = 'Required Field';
		if (values.historyType === ParticipantHistoryType.Partial) {
			if (values.historyShareFromUtc === null) errors.historyType = 'Required Field';
			else if (isNaN(values.historyShareFromUtc.getTime()))
				errors.historyType = 'Invalid Date';
		}
		return errors;
	};

	const addParticipants = useAddParticipants();
	const onSubmit = async (values: AddParticipantsFormValues) => {
		const participants: Participant[] = values.participants.map((p) => ({
			entityId: p.id,
			entityType: convertEntityType(p.entityType),
		}));
		const historyShareFromUtc =
			values.historyType === ParticipantHistoryType.Partial
				? values.historyShareFromUtc
				: null;
		await addParticipants(participants, values.historyType, historyShareFromUtc);
		closeModal();
	};

	return (
		<Formik<AddParticipantsFormValues>
			onSubmit={onSubmit}
			initialValues={initialValues}
			validate={validate}>
			{(props: FormikProps<AddParticipantsFormValues>) => (
				<>
					<ModalContent>
						<Stack spacing={1}>
							<ParticipantSearchField
								name="participants"
								label="New Participants"
								required
								omittedIds={existingParticipants.map((p) => p.entityId)}
							/>
							<Box>
								<Typography variant="h6">
									How much chat history should be shared with these new
									participants?
								</Typography>
								<RadioGroupField name="historyType">
									<FormControlLabel
										value={ParticipantHistoryType.All}
										control={<Radio />}
										label="All chat history"
									/>
									<FormControlLabel
										value={ParticipantHistoryType.None}
										control={<Radio />}
										label="No chat history"
									/>
									<FormControlLabel
										value={ParticipantHistoryType.Partial}
										control={<Radio />}
										label={
											<Stack direction="row" alignItems="center" spacing={2}>
												<Typography>Starting from</Typography>
												<Box flex={1}>
													<DatePickerField
														name="historyShareFromUtc"
														includeTime
														disabled={
															props.values.historyType !==
															ParticipantHistoryType.Partial
														}
													/>
												</Box>
											</Stack>
										}
										sx={{ mt: -0.5 }}
									/>
								</RadioGroupField>
							</Box>
						</Stack>
					</ModalContent>
					<ModalActions>
						<ModalActionButton variant="outlined">Cancel</ModalActionButton>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							disabled={!props.isValid || !props.dirty}
							loading={props.isSubmitting}
							onClick={() => {
								props.submitForm();
							}}>
							Add Participants
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};
