import { LoadingButton } from '@mui/lab';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	useModal,
} from 'components/ui/modal';
import { LabelType } from 'middleware-types';
import { useCommunicationsContext } from '../communications-provider';
import { useApplyLabel } from '../hooks/conversation-labels-hooks';
import { ErrorOutlined } from '@mui/icons-material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';

interface TrashConversationModalProps {
	conversationName: string;
}

export const TrashConversationModal = ({ conversationName }: TrashConversationModalProps) => {
	const { closeModal } = useModal();
	const { applyLabel, loading } = useApplyLabel();
	const { labels } = useCommunicationsContext();

	const onSubmit = () => {
		const trashLabel = labels.find((label) => label.type === LabelType.Trash);
		if (!trashLabel) return;

		applyLabel(trashLabel.id).then((success) => {
			if (!success) return;
			closeModal();
		});
	};

	return (
		<>
			<ConfirmModalContent
				visual={
					<EmptyStateAvatar
						avatarProps={{ bgcolor: 'error.50' }}
						iconProps={{ color: 'error.500' }}
						icon={<ErrorOutlined />}
					/>
				}
				subheadline={`Trash "${conversationName}"?`}
				informativeContent="This conversation will be moved to trash for 30 days before being permanently deleted from your inbox."
			/>
			<ModalActions>
				<ModalActionButton size="large" variant="outlined">
					Cancel
				</ModalActionButton>
				<LoadingButton
					size="large"
					variant="contained"
					color="error"
					loading={loading}
					onClick={onSubmit}>
					Trash
				</LoadingButton>
			</ModalActions>
		</>
	);
};
