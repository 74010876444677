import { LoadingButton } from '@mui/lab';
import { Link, Typography } from '@mui/material';
import clsx from 'clsx';
import { emailValidation, passwordValidation } from 'components/ui/LoginYups';
import { TextField } from 'components/ui/fields';
import { PageTitle } from 'components/ui/page';
import { Form, Formik, FormikHelpers } from 'formik';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import * as Yup from 'yup';

export const LoginForm = ({
	onSubmit,
}: {
	onSubmit: ({ email, password }: LoginForm, helpers: FormikHelpers<LoginForm>) => Promise<void>;
}) => {
	const largeScreen = useWindowDimensions();
	return (
		<>
			<PageTitle title="Log In" />
			<Formik<LoginForm>
				initialValues={{ email: '', password: '' }}
				onSubmit={onSubmit}
				validationSchema={loginValidationSchema}
				validateOnBlur={false}
				validateOnChange={false}>
				{({ isSubmitting, errors, setErrors }) => (
					<Form className="m-auto flex w-96 flex-col text-center">
						<img
							className={clsx(' mx-auto mb-10 w-32', {
								hidden: largeScreen,
							})}
							src="/img/evolve-logo.svg"
							alt="FTEvolve"
						/>
						<Typography mb={2} variant="h2" className="text-neutral-900">
							Log In
						</Typography>
						<div className="flex flex-col gap-3">
							<TextField
								label="Email address *"
								type="email"
								name="email"
								InputLabelProps={{ shrink: true }}
								error={!!errors.email}
								helperText={errors?.email}
								onChangeCapture={() =>
									setErrors({
										...errors,
										email: undefined,
									})
								}
								FormHelperTextProps={{
									className: clsx(!!errors?.email && 'static', 'text-left mb-1'),
								}}
								sx={{
									fieldset: { borderColor: 'neutral.700' },
								}}
							/>
							<TextField
								label="Password *"
								type="password"
								name="password"
								InputLabelProps={{ shrink: true }}
								error={!!errors?.password}
								helperText={errors?.password}
								onChangeCapture={() =>
									setErrors({
										...errors,
										password: undefined,
									})
								}
								FormHelperTextProps={{
									className: clsx(
										!!errors?.password && 'static',
										'text-left mb-1'
									),
								}}
								sx={{
									fieldset: { borderColor: 'neutral.700' },
								}}
							/>
						</div>
						<div className="my-2 text-left">
							<Link href="/auth/forgot-password" className="text-navy-500 mr-auto">
								Forgot password?
							</Link>
						</div>
						<LoadingButton
							className="mt-2 text-white"
							variant="contained"
							color="primary"
							fullWidth
							type="submit"
							data-test="log-in-btn"
							loading={isSubmitting}>
							Log In
						</LoadingButton>
						<Typography className="mt-5">
							Don{"'"}t have an account yet?{' '}
							<Link href="/auth/sign-up" className="text-navy-500 mr-auto">
								Sign up
							</Link>
						</Typography>
					</Form>
				)}
			</Formik>
		</>
	);
};

export type LoginForm = {
	email: string;
	password: string;
};

const loginValidationSchema = Yup.object({
	email: emailValidation,
	password: passwordValidation,
});
