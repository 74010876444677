import { Close } from '@mui/icons-material';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { CopyButton } from 'components/pages/evoxchat/copyButton';
import { FileInstanceInformation } from 'middleware-types';
import { useVersionHistoryContext } from '../components/version-history-provider';

interface MetadataModalProps {
	file: FileInstanceInformation;
	onClose: () => void;
}

export const MetadataModal = ({ file, onClose }: MetadataModalProps) => {
	const { isVersionHistory } = useVersionHistoryContext();
	return (
		<Stack>
			<Stack
				px={2}
				py={1}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				spacing={1}>
				<Typography variant="h3" noWrap>
					{file.fileName}
					{isVersionHistory && ` (v${file.versionNumber})`} (Metadata)
				</Typography>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</Stack>
			<Divider />
			<Stack p={2} spacing={3.5}>
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<Stack flex={1}>
						<Typography variant="h5">File ID</Typography>
						<Typography variant="body1">{file.fileId}</Typography>
					</Stack>
					<CopyButton text={file.fileId} />
				</Stack>
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<Stack flex={1}>
						<Typography variant="h5">File Instance ID</Typography>
						<Typography variant="body1">{file.id}</Typography>
					</Stack>
					<CopyButton text={file.id} />
				</Stack>
				{file.cdnUrl && (
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<Stack flex={1}>
							<Typography variant="h5">URL</Typography>
							<Typography variant="body1">{file.cdnUrl}</Typography>
						</Stack>
						<CopyButton text={file.cdnUrl} />
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};
