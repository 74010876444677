import { ThumbDownAlt, ThumbDownOffAlt, ThumbUpAlt, ThumbUpOffAlt } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { ChatMessageRating, UserChatMessage } from 'middleware-types';
import { useState } from 'react';
import { useEvoxFeedback } from './hooks';

interface FeedbackButtonsProps {
	message: UserChatMessage;
	readOnly: boolean;
}

export const FeedbackButtons = ({ message, readOnly }: FeedbackButtonsProps) => {
	const [currentFeedback, setCurrentFeedback] = useState<ChatMessageRating | null | undefined>(
		message.rating
	);

	const { leaveFeedback: _leaveFeedback } = useEvoxFeedback();
	const leaveFeedback = (rating: ChatMessageRating) => {
		const newRating = currentFeedback === rating ? ChatMessageRating.None : rating;
		setCurrentFeedback(newRating);
		_leaveFeedback(message.chatMessageId, newRating);
	};

	return (
		<Stack direction="row">
			<IconButton
				disabled={readOnly}
				onClick={() => leaveFeedback(ChatMessageRating.ThumbsUp)}>
				{currentFeedback === ChatMessageRating.ThumbsUp ? (
					<ThumbUpAlt />
				) : (
					<ThumbUpOffAlt />
				)}
			</IconButton>
			<IconButton
				disabled={readOnly}
				onClick={() => leaveFeedback(ChatMessageRating.ThumbsDown)}>
				{currentFeedback === ChatMessageRating.ThumbsDown ? (
					<ThumbDownAlt />
				) : (
					<ThumbDownOffAlt />
				)}
			</IconButton>
		</Stack>
	);
};
