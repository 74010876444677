import { gql, useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField as MTextField, MenuItem, Skeleton } from '@mui/material';
import { Alert } from 'components/ui/alert';
import { SelectField, TextField } from 'components/ui/fields';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalContent,
	useModal,
} from 'components/ui/modal';
import { useToast } from 'components/ui/toast';
import { Formik, FormikProps } from 'formik';
import {
	Mutation,
	MutationUserSiteUserInvitationSendArgs,
	Query,
	QuerySiteRolesArgs,
	SiteUserInvitationRequest,
	SortDirection,
} from 'middleware-types';
import React from 'react';
import { PageError, handleNoResponse, responseHasErrors } from 'utils/errors';
import { useValidation } from 'utils/useValidation';

/**
 * useSiteUserInvitation - Custom hook for getting all roles, validation schema, handling submit, and loading.
 *
 * @returns {({
 * 	roles: SiteRoleItem[];
 * 	validationSchema: ValidationSchema | null;
 * 	submit: (values: MSiteUserInvitationRequest) => Promise<void>;
 * 	loading: boolean;
 * })}
 */
export const useSiteUserInvitation = () => {
	const { showModal } = useModal();
	const validation = useValidation('RepresentativeUpdate');
	const toast = useToast();
	const rolesQuery = useQuery<Pick<Query, 'siteRoles'>, QuerySiteRolesArgs>(
		gql`
			query siteRolesList(
				$pageSize: Float!
				$offset: Float!
				$sortedBy: String!
				$sortDirection: SortDirection!
				$searchFor: String!
			) {
				siteRoles(
					pageSize: $pageSize
					offset: $offset
					sortedBy: $sortedBy
					sortDirection: $sortDirection
					searchFor: $searchFor
				) {
					items {
						id
						name
					}
				}
			}
		`,
		{
			variables: {
				pageSize: 100,
				offset: 0,
				sortedBy: 'name',
				sortDirection: SortDirection.Ascending,
				searchFor: '',
			},
			fetchPolicy: 'cache-and-network',
		}
	);

	const [inviteMutation, mutationState] = useMutation<
		Pick<Mutation, 'userSiteUserInvitationSend'>,
		MutationUserSiteUserInvitationSendArgs
	>(
		gql`
			mutation invitation($siteUserInvitationRequest: SiteUserInvitationRequest!) {
				userSiteUserInvitationSend(siteUserInvitationRequest: $siteUserInvitationRequest) {
					invitationId
				}
			}
		`
	);

	if (rolesQuery.error) throw new PageError('Error loading site roles');

	const onSubmit = async (values: SiteUserInvitationRequest): Promise<false | undefined | void> =>
		inviteMutation({
			variables: { siteUserInvitationRequest: values },
		})
			.then((res) => {
				// TODO: When testable, update deprecated MutationErrorText with ResponseHasErrors and HandleNoResponse pattern
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				showModal({
					title: 'Invite Site User',
					content: (
						<ConfirmModalContent
							visual="/img/InviteConfirm.svg"
							subheadline={`Invitation Sent to ${values.firstName} ${values.lastName}`}
							informativeContent={`The invite was sent to ${values.invitedEmailAddress}.`}
						/>
					),
					actions: (
						<ModalActions>
							<ModalActionButton variant="outlined">Close</ModalActionButton>
							<Button
								onClick={() => {
									showModal({
										title: 'Invite Site User',
										content: <InvitationForm />,
									});
								}}
								color="primary"
								variant="contained">
								Invite Another User
							</Button>
						</ModalActions>
					),
				});
				toast.push('Invitation sent successfully.', {
					variant: 'success',
				});
			})
			.catch(() => {
				handleNoResponse({ toast });
			});

	return {
		validationSchema: validation.schema,
		roles: rolesQuery.data?.siteRoles?.items ?? [],
		loading: validation.loading || rolesQuery?.loading,
		error: mutationState.error,
		onSubmit,
	};
};

/**
 * Invitation Modal
 *
 * @param {InvitationModalProps} props
 * @returns
 */
export const InvitationForm: React.FC = () => {
	const { loading, roles, onSubmit, validationSchema, error } = useSiteUserInvitation();

	const roleItems: React.JSX.Element[] = roles.map((src, i) => (
		<MenuItem key={i} value={src.id}>
			{src.name !== '' ? src.name : <em>None</em>}
		</MenuItem>
	));

	return (
		<div>
			<Formik<SiteUserInvitationRequest>
				onSubmit={onSubmit}
				validationSchema={validationSchema}
				initialValues={{
					firstName: '',
					lastName: '',
					invitedEmailAddress: '',
					affiliation: '',
					roleId: '',
				}}
				enableReinitialize>
				{(props: FormikProps<SiteUserInvitationRequest>) => (
					<>
						<ModalContent>
							{loading ? (
								<SkeletonForm />
							) : (
								<Grid container spacing={1} columnSpacing={2}>
									<Grid xs={12} md={6} item>
										<TextField
											label="First Name"
											required
											type="text"
											name="firstName"
										/>
									</Grid>
									<Grid xs={12} md={6} item>
										<TextField
											label="Last Name"
											required
											type="text"
											name="lastName"
										/>
									</Grid>
									<Grid xs={12} md={6} item>
										<TextField
											label="Email Address"
											required
											type="email"
											name="invitedEmailAddress"
										/>
									</Grid>
									<Grid xs={12} md={6} item>
										<SelectField
											name="roleId"
											label="Role"
											required
											value={props.values.roleId ?? ''}
											fullWidth>
											{roleItems}
										</SelectField>
									</Grid>
									<Grid xs={12} md={12} item>
										<TextField
											label="Affiliation"
											required
											type="email"
											name="affiliation"
										/>
									</Grid>
									{error && (
										<Grid xs={12} md={12} item>
											<Alert error={error} />
										</Grid>
									)}
								</Grid>
							)}
						</ModalContent>
						<ModalActions>
							<Grid container justifyContent="flex-end" spacing={1}>
								<Grid item>
									<ModalActionButton variant="outlined">Cancel</ModalActionButton>
								</Grid>
								<Grid item>
									<LoadingButton
										variant="contained"
										color="primary"
										onClick={props.submitForm}
										disabled={
											!props.isValid || !props.dirty || props.isSubmitting
										}
										loading={props.isSubmitting}>
										Send Invite
									</LoadingButton>
								</Grid>
							</Grid>
						</ModalActions>
					</>
				)}
			</Formik>
		</div>
	);
};

const SkeletonForm = (): React.JSX.Element => (
	<Grid container spacing={1} columnSpacing={2}>
		<Grid xs={12} md={6} item>
			<Skeleton animation="wave" width="100%">
				<MTextField />
			</Skeleton>
		</Grid>
		<Grid xs={12} md={6} item>
			<Skeleton animation="wave" width="100%">
				<MTextField />
			</Skeleton>
		</Grid>
		<Grid xs={12} md={6} item>
			<Skeleton animation="wave" width="100%">
				<MTextField />
			</Skeleton>
		</Grid>
		<Grid xs={12} md={6} item>
			<Skeleton animation="wave" width="100%">
				<MTextField />
			</Skeleton>
		</Grid>
		<Grid xs={12} md={12} item>
			<Skeleton animation="wave" width="100%">
				<MTextField />
			</Skeleton>
		</Grid>
	</Grid>
);
