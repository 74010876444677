import { gql, useMutation } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	Mutation,
	MutationOrganizationCreateAssociateNotificationArgs,
	MutationUserSiteUserCreateSystemNotificationArgs,
	OrganizationNotificationRequest,
	SystemNotificationRequest,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';

/**
 * hook to create a system notification
 */
const CREATE_SYSTEM_NOTIFICATION = gql`
	mutation CreateSystemNotification($request: SystemNotificationRequest!) {
		userSiteUserCreateSystemNotification(request: $request)
	}
`;

const CREATE_ORGANIZATION_NOTIFICATION = gql`
	mutation CreateOrganizationNotification(
		$organizationId: String!
		$request: OrganizationNotificationRequest!
	) {
		organizationCreateAssociateNotification(organizationId: $organizationId, request: $request)
	}
`;

export const useCreateSystemNotification = () => {
	const toast = useToast();
	const [_createSystemNotification] = useMutation<
		Pick<Mutation, 'userSiteUserCreateSystemNotification'>,
		MutationUserSiteUserCreateSystemNotificationArgs
	>(CREATE_SYSTEM_NOTIFICATION);

	const createSystemNotification = async (request: SystemNotificationRequest) => {
		return await _createSystemNotification({ variables: { request } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('System announcement has been queued. Check back later for status.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return createSystemNotification;
};

export const useCreateOrganizationNotification = () => {
	const toast = useToast();
	const [_createOrganizationNotification] = useMutation<
		Pick<Mutation, 'organizationCreateAssociateNotification'>,
		MutationOrganizationCreateAssociateNotificationArgs
	>(CREATE_ORGANIZATION_NOTIFICATION);

	const createOrganizationNotification = async (
		organizationId: string,
		request: OrganizationNotificationRequest
	) => {
		return await _createOrganizationNotification({ variables: { organizationId, request } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Announcement has been queued. Check back later for status.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return createOrganizationNotification;
};
