import { Business, CheckOutlined, KeyboardArrowLeft } from '@mui/icons-material';
import {
	Avatar,
	Box,
	Button,
	Chip,
	Divider,
	IconButton,
	Skeleton,
	Stack,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import {
	ClaimSummaryRoleType,
	ClaimSummarySubset,
	LegacyUserAdjustmentCompanyWithMetrics,
} from 'middleware-types';
import { useState } from 'react';
import { getCompanyJobsText, getCompanyRolePermissions, getMyJobsText } from 'utils/companyRoles';
import { postLegacyAuth, useSession } from 'utils/session';
import { ClaimSummaryCard } from './ClaimSummaryCard';
import { CompanyAvatar } from './CompanyAvatar';
import { useClaimSummaries } from './hooks';

const getSubsetString = (subset: ClaimSummarySubset) => {
	switch (subset) {
		case ClaimSummarySubset.Overdue:
			return 'Overdue';
		case ClaimSummarySubset.DueNext3:
			return 'Due Next 3 Days';
		case ClaimSummarySubset.RecLast3:
			return 'Received Last 3 Days';
	}
};

export const PreviewPanel = ({
	company,
	onClose,
}: {
	company: LegacyUserAdjustmentCompanyWithMetrics | null;
	onClose?: () => void;
}) => {
	if (company === null) return <PreviewPanelEmpty />;
	const { hasUserPermission, hasGroupPermission } = getCompanyRolePermissions(company.userRole);
	if ((!hasUserPermission && !hasGroupPermission) || company.userRole === 'AdminHR')
		return <PreviewPanelNoPermission onClose={onClose} />;
	return (
		<PreviewPanelContent
			key={company.userId}
			company={company}
			hasUserPermission={hasUserPermission}
			hasGroupPermission={hasGroupPermission}
			onClose={onClose}
		/>
	);
};

const PreviewPanelEmpty = () => {
	return (
		<Stack spacing={2} justifyContent="center" alignItems="center" height="100%" px={6} py={2}>
			<Avatar sx={{ height: 80, width: 80 }}>
				<Business sx={{ fontSize: 48 }} />
			</Avatar>
			<Stack textAlign="center" spacing={1}>
				<Typography variant="h3">No company selected.</Typography>
				<Typography>
					Click one of the cards on the left to see a preview of your jobs.
				</Typography>
			</Stack>
		</Stack>
	);
};

const PreviewPanelNoPermission = ({ onClose }: { onClose?: () => void }) => {
	return (
		<Stack
			spacing={2}
			justifyContent="center"
			alignItems="center"
			height="100%"
			px={6}
			py={2}
			position="relative">
			{onClose !== undefined && (
				<IconButton onClick={onClose} sx={{ position: 'absolute', top: 16, left: 16 }}>
					<KeyboardArrowLeft />
				</IconButton>
			)}
			<Avatar sx={{ height: 80, width: 80 }}>
				<Business sx={{ fontSize: 48 }} />
			</Avatar>
			<Stack textAlign="center" spacing={1}>
				<Typography variant="h3">No data to show.</Typography>
				<Typography>
					You don&apos;t have permission to see this information. Contact your company
					administrator if this is a mistake.
				</Typography>
			</Stack>
		</Stack>
	);
};

interface SubsetOption {
	subset: ClaimSummarySubset;
	count: number | undefined;
}

const PreviewPanelContent = ({
	company,
	hasUserPermission,
	hasGroupPermission,
	onClose,
}: {
	company: LegacyUserAdjustmentCompanyWithMetrics;
	hasUserPermission: boolean;
	hasGroupPermission: boolean;
	onClose?: () => void;
}) => {
	const { user } = useSession();
	// options for the selected role type
	const [selectedRoleType, setSelectedRoleType] = useState<ClaimSummaryRoleType>(
		hasUserPermission ? ClaimSummaryRoleType.User : ClaimSummaryRoleType.Group
	);

	const totalClaims =
		selectedRoleType === ClaimSummaryRoleType.User
			? company.userClaimCount
			: company.companyClaimCount;

	const showTabs = hasUserPermission && hasGroupPermission;

	// subset options for user claims
	const userSubsetOptions: SubsetOption[] = [
		{ subset: ClaimSummarySubset.Overdue, count: company.userOverdueClaimCount },
		{ subset: ClaimSummarySubset.DueNext3, count: company.userImpendingClaimCount },
		{ subset: ClaimSummarySubset.RecLast3, count: company.userRecentlyReceivedClaimCount },
	];

	const [selectedUserSubsetOption, setSelectedUserSubsetOption] = useState<SubsetOption>(
		userSubsetOptions.find((option) => option.count !== undefined && option.count > 0) ??
			userSubsetOptions[0]
	);

	// subset options for company claims
	const groupSubsetOptions: SubsetOption[] = [
		{ subset: ClaimSummarySubset.Overdue, count: company.companyOverdueClaimCount },
		{ subset: ClaimSummarySubset.DueNext3, count: company.companyImpendingClaimCount },
		{ subset: ClaimSummarySubset.RecLast3, count: company.companyRecentlyReceivedClaimCount },
	];

	const [selectedGroupSubsetOption, setSelectedGroupSubsetOption] = useState<SubsetOption>(
		groupSubsetOptions.find((option) => option.count !== undefined && option.count > 0) ??
			groupSubsetOptions[0]
	);

	// the subset options to use
	const [subsetOptions, selectedSubsetOption, setSelectedSubsetOption] =
		selectedRoleType === ClaimSummaryRoleType.User
			? [userSubsetOptions, selectedUserSubsetOption, setSelectedUserSubsetOption]
			: [groupSubsetOptions, selectedGroupSubsetOption, setSelectedGroupSubsetOption];

	// get the claims
	const { claims, loading } = useClaimSummaries(
		company,
		selectedRoleType,
		selectedSubsetOption.subset
	);

	return (
		<Stack height="100%">
			<Stack
				p={2}
				pb={showTabs ? 0 : undefined}
				spacing={2}
				direction={{ xs: 'column', sm: 'row' }}
				alignItems={{ xs: 'stretch', sm: 'center' }}>
				<Stack direction="row" spacing={1.5} alignItems="center" flex={1}>
					{onClose !== undefined && (
						<IconButton onClick={onClose}>
							<KeyboardArrowLeft />
						</IconButton>
					)}
					<CompanyAvatar company={company} />
					<Box>
						<Typography variant="h3">{company.adjCoName}</Typography>
						{!showTabs && (
							<Typography>
								{totalClaims} assigned job{totalClaims === 1 ? '' : 's'}
							</Typography>
						)}
					</Box>
				</Stack>
				<Button
					variant="contained"
					color="primary"
					size="large"
					onClick={() =>
						postLegacyAuth(company.systemUrl, company.userId, 'claimList.asp')
					}>
					See All Jobs
				</Button>
			</Stack>
			{showTabs && (
				<Box px={2}>
					<Tabs
						value={selectedRoleType}
						onChange={(_e, value) => setSelectedRoleType(value)}>
						<Tab
							label={`${getMyJobsText(company.userRole)} (${company.userClaimCount})`}
							value={ClaimSummaryRoleType.User}
						/>
						<Tab
							label={`${getCompanyJobsText(company.userRole)} (${
								company.companyClaimCount
							})`}
							value={ClaimSummaryRoleType.Group}
						/>
					</Tabs>
				</Box>
			)}
			<Divider />
			<Stack direction="row" p={2} gap={1} flexWrap="wrap">
				{subsetOptions.map((option) => {
					if (option.count === undefined || option.count < 1) return null;
					return (
						<Chip
							key={option.subset}
							color={
								selectedSubsetOption.subset === option.subset
									? 'primary'
									: undefined
							}
							onClick={() => setSelectedSubsetOption(option)}
							label={`${getSubsetString(option.subset)} (${option.count})`}
							icon={
								selectedSubsetOption.subset === option.subset ? (
									<CheckOutlined />
								) : undefined
							}
						/>
					);
				})}
			</Stack>
			<Stack flex={1} spacing={2} overflow="auto" px={2} pb={2}>
				{loading
					? [...Array(5)].map((_, index) => (
							<Skeleton key={index} variant="rounded" height={100} />
					  ))
					: claims.map((claim) => (
							<ClaimSummaryCard
								key={claim.claimId}
								claim={claim}
								company={company}
								subset={selectedSubsetOption.subset}
							/>
					  ))}
				{!loading &&
					selectedSubsetOption.count !== undefined &&
					selectedSubsetOption.count > 25 && (
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							spacing={2}>
							<Typography>1-25 of {selectedSubsetOption.count} jobs</Typography>
							<Button
								variant="outlined"
								size="large"
								onClick={() =>
									postLegacyAuth(
										company.systemUrl,
										company.userId,
										user.userId,
										'claimList.asp'
									)
								}>
								See All Jobs
							</Button>
						</Stack>
					)}
			</Stack>
		</Stack>
	);
};
