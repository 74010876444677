import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { ContainedDrawer } from 'components/ui/containedDrawer';

interface DeletePromptDrawerProps {
	open: boolean;
	onSubmit: () => void;
	onClose: () => void;
}

export const ConfirmNewConversationDrawer = ({
	open,
	onSubmit,
	onClose,
}: DeletePromptDrawerProps) => {
	const onClick = () => {
		onSubmit();
		onClose();
	};

	return (
		<ContainedDrawer
			open={open}
			anchor="top"
			containerid="evox-container"
			PaperProps={{ sx: { borderRadius: 1 } }}>
			<Stack p={2} spacing={1}>
				<Typography variant="h3">Discard Current Conversation?</Typography>
				<Typography variant="body2">
					Your current conversation has not been pinned, so you will not be able to access
					it once you start a new conversation or resume a pinned one.
				</Typography>
				<Stack direction="row" spacing={1} justifyContent="flex-end">
					<LoadingButton variant="outlined" color="primary" onClick={onClose}>
						Cancel
					</LoadingButton>
					<LoadingButton variant="contained" color="error" onClick={onClick}>
						Discard
					</LoadingButton>
				</Stack>
			</Stack>
		</ContainedDrawer>
	);
};
