import { useLazyQuery } from '@apollo/client';
import { SearchOutlined } from '@mui/icons-material';
import {
	Autocomplete,
	CircularProgress,
	InputAdornment,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Stack,
	TextField,
	TextFieldProps,
} from '@mui/material';
import { EmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { gql } from 'graphql-tag';
import { debounce } from 'lodash';
import {
	EmblemEntityType,
	EntitySearchMode,
	EntitySearchType,
	Query,
	QueryQuickSearchArgs,
	QuickSearchEntity,
} from 'middleware-types';
import { useCallback, useState } from 'react';

const RESULTS_LIMIT = 10;
const DEBOUNCE_TIMER_IN_MS = 750;

type BaseQuickSearchProps = {
	/** Search for users, orgs, or all(default).
	 * @default EntitySearchMode.All
	 */
	entitySearchMode?: EntitySearchMode;

	/** Callback fired when selecting a QuickSearchEntity option.
	 * @param entity - The selected entity
	 */
	onEntitySelect: (entity: QuickSearchEntity) => void;

	/** If true, the QuickSearch will not maintain any internal state, and will clear the input after selecting an entity.
	 * @default false
	 */
	clearOnSelect?: boolean;
} & TextFieldProps;

// If clearOnSelect is false, consumer must specify what happens when cleared
type QuickSearchProps = BaseQuickSearchProps &
	(
		| { clearOnSelect: true; onEntityClear?: undefined }
		| { clearOnSelect?: false; onEntityClear: () => void }
	);

export const entitySearchTypeToEmblemEntityType = (entitySearchType: EntitySearchType) => {
	switch (entitySearchType) {
		case EntitySearchType.User:
			return EmblemEntityType.User;
		case EntitySearchType.Organization:
			return EmblemEntityType.Organization;
	}
};

export const QuickSearch = ({
	entitySearchMode = EntitySearchMode.All,
	onEntitySelect,
	onEntityClear,
	disabled,
	clearOnSelect = false,
	...textFieldProps
}: QuickSearchProps) => {
	const [inputValue, setInputValue] = useState('');
	const { quickSearch, results, loading, called } = useQuickSearchQuery();

	const debouncedSearch = useCallback(
		debounce((searchText) => {
			if (searchText.length < 1) {
				return;
			}
			quickSearch(searchText, RESULTS_LIMIT, entitySearchMode);
		}, DEBOUNCE_TIMER_IN_MS),
		[]
	);

	return (
		<Autocomplete
			options={results}
			value={clearOnSelect ? null : undefined}
			// The reason we toggle freeSolo here is because when it is enabled, the no options message never displays.
			// We dont want to show the noOptionsText when the user hasnt even searched yet. Thats what this does.
			freeSolo={inputValue === '' || !called}
			noOptionsText="No results found"
			blurOnSelect
			loading={loading}
			forcePopupIcon={false}
			getOptionLabel={(option) => (typeof option === 'string' ? option : option.displayName)}
			onInputChange={(_, newInputValue, reason) => {
				setInputValue(newInputValue);
				// Prevent a new search when user selects an option
				reason === 'input' && debouncedSearch(newInputValue);
			}}
			inputValue={inputValue}
			onChange={(e, val, reason) => reason === 'clear' && onEntityClear && onEntityClear()}
			ListboxComponent={List}
			disabled={disabled}
			isOptionEqualToValue={(option, val) => option.id === val.id}
			renderOption={(props, entity) => (
				<ListItem {...props} key={entity.id} className="p-0">
					<ListItemButton
						selected={Boolean(props['aria-selected'])}
						className="border-0"
						onClick={() => onEntitySelect(entity)}>
						{entity.id && entity.entityType && (
							<ListItemAvatar>
								<EmblemAvatar
									entityId={entity.id}
									entityType={entitySearchTypeToEmblemEntityType(
										entity.entityType
									)}
									noDropdown
								/>
							</ListItemAvatar>
						)}
						<ListItemText
							primary={entity.displayName}
							secondary={
								entity.entityType +
								(entity.handle ? ' (' + entity.handle + ')' : '') +
								' - ' +
								entity.location
							}
						/>
					</ListItemButton>
				</ListItem>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					{...textFieldProps}
					helperText={
						textFieldProps.error && textFieldProps.required
							? 'Required field'
							: textFieldProps.helperText
					}
					margin="none"
					fullWidth
					InputProps={{
						...params?.InputProps,
						endAdornment: (
							<InputAdornment position="end">
								<Stack direction="row" gap="0.75rem" alignItems="center">
									{loading && (
										<CircularProgress
											style={{ height: `1rem`, width: `1rem` }}
										/>
									)}
									{params.InputProps.endAdornment}
									{params.inputProps.value?.toString().length === 0 && (
										<SearchOutlined />
									)}
								</Stack>
							</InputAdornment>
						),
					}}
					onKeyDown={(key) =>
						key.code === 'Enter' && results.length > 0 && onEntitySelect(results[0])
					}
				/>
			)}
		/>
	);
};

/**
 * Hook for running the quickSearch query and getting results
 *
 * @return {*}  {useQuickSearchQueryReturnValues}
 */
export const useQuickSearchQuery = () => {
	const [quickSearchQuery, { data, loading, called }] = useLazyQuery<
		Pick<Query, 'quickSearch'>,
		QueryQuickSearchArgs
	>(gql`
		query quickSearch($query: String!, $limit: Float!, $entitySearchMode: EntitySearchMode) {
			quickSearch(query: $query, limit: $limit, entitySearchMode: $entitySearchMode) {
				id
				entityType
				handle
				displayName
				location
				relevance
			}
		}
	`);

	/**
	 * Wrapper for the quickSearch graphql query, takes in search text and a results limit
	 *
	 * @param {string} searchText
	 * @param {number} limit
	 */
	const quickSearch = (searchText: string, limit: number, entitySearchMode: EntitySearchMode) => {
		quickSearchQuery({ variables: { query: searchText, limit, entitySearchMode } });
	};

	return { quickSearch, results: data?.quickSearch ?? [], loading, called };
};
