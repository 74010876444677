import { gql, useQuery } from '@apollo/client';
import { Query, QuerySiteUserArgs } from 'middleware-types';
import { PageError } from 'utils/errors';
import { useSession } from './session';
import { Permission } from './permissions';

/**
 * useSiteUser() - get the site user permission handler.
 * @returns Hook for loading a site user, the id and has permission.
 */
export const useSiteUser = (): {
	loading: boolean;
	id: string | undefined;
	hasPermission: (permission: Permission | Permission[], options?: { all?: boolean }) => boolean;
} => {
	const { user } = useSession();
	const {
		data,
		loading: siteLoading,
		error,
	} = useQuery<Pick<Query, 'siteUser'>, QuerySiteUserArgs>(
		gql`
			query siteUser($id: ID!) {
				siteUser(id: $id) {
					id
					roles {
						id
						isAdminRole
						permissionKeys
					}
				}
			}
		`,
		{
			fetchPolicy: 'cache-first',
			skip: !user.siteUserId,
			variables: {
				id: user.siteUserId as string,
			},
		}
	);

	const loading = siteLoading || !data?.siteUser;
	if (error) throw new PageError(error.graphQLErrors);

	/**
	 * Checks if a site user has the required permissions, takes a permission or an array of permissions.
	 * If 'id' is passed in options object, function will return true if id matches user's own.
	 * If 'all' is passed in options object, function will return true only if user has ALL the permissions specified,
	 * otherwise this function will return true if user has ANY of the permissions specified.
	 *
	 * @param {(string | string[])} permission
	 * @param {{ id?: string; all?: boolean }} [options]
	 * @return {*}  {boolean}
	 */
	const hasPermission = (
		permission: Permission | Permission[],
		options?: { all?: boolean }
	): boolean => {
		if (loading) return false;
		if (data?.siteUser === undefined) return false;
		if (!data?.siteUser?.roles?.length) return false;

		// Super admin roles always have permission.
		if (data?.siteUser?.roles[0].isAdminRole) {
			return true;
		}

		const permissions = data?.siteUser?.roles?.flatMap((r) => r.permissionKeys);

		if (Array.isArray(permission)) {
			return (
				permission.length === 0 ||
				(options?.all
					? permission.every((p) => permissions.includes(p))
					: permission.some((p) => permissions.includes(p)))
			);
		} else {
			return permissions.some((p) => p === permission);
		}
	};

	return {
		loading: loading || !data?.siteUser,
		id: data?.siteUser?.id,
		hasPermission,
	};
};
