import { gql, useMutation } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import { Mutation, MutationDeleteFileArgs } from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useDocumentsContext } from '../../documents-page';
import { updateFolderContents } from '../../utils/cache-helpers';
import { useDocumentSearchContext } from '../../components/document-search-provider';

const DELETE_FILE = gql`
	mutation DeleteFile($entityType: FoldersApiEntityType!, $entityId: String!, $fileId: String!) {
		deleteFile(entityType: $entityType, entityId: $entityId, fileId: $fileId)
	}
`;

export const useDeleteFile = () => {
	const toast = useToast();
	const { entityType, entityId } = useDocumentsContext();
	const { searchValue } = useDocumentSearchContext();

	const [_deleteFile, { loading }] = useMutation<
		Pick<Mutation, 'deleteFile'>,
		MutationDeleteFileArgs
	>(DELETE_FILE);

	const deleteFile = async (fileId: string, folderId: string | undefined) => {
		return await _deleteFile({
			variables: { entityType, entityId, fileId },
			update: (cache, { data }) => {
				if (!data) return;
				updateFolderContents(
					{ cache, folderId, entityType, entityId, searchValue },
					(existing) => ({
						...existing,
						files: existing.files?.filter((f) => f.id !== fileId),
					})
				);
			},
			onError: (e) => console.log(JSON.stringify(e)),
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('File deleted successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { deleteFile, loading };
};
