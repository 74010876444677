import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import { Box, Link, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { AllowedTransports, NotificationSettingDefaultGroupResponse } from 'middleware-types';
import { useState } from 'react';
import { GroupCheckbox } from './group-checkbox';
import { NotificationSettingsRow } from './notification-settings-row';
import { NotificationTableColumns } from './notification-settings-table';

interface NotificationSettingsGroupProps {
	group: NotificationSettingDefaultGroupResponse;
	columns: NotificationTableColumns;
}

export const NotificationSettingsGroup = ({ group, columns }: NotificationSettingsGroupProps) => {
	const includesMethods = columns === 'methods' || columns === 'all';
	const includesDigest = columns === 'emailDigest' || columns === 'all';

	const [open, setOpen] = useState(true);

	return (
		<>
			<TableRow sx={{ bgcolor: 'neutral.50' }}>
				<TableCell onClick={() => setOpen((value) => !value)}>
					<Box px={{ xs: 2, sm: 2.5 }}>
						<Typography variant="h6">{group.groupName}</Typography>
						<Stack direction="row" alignItems="center" spacing={0.5}>
							<Link>{open ? 'Hide' : 'Show'} sub categories</Link>
							{open ? (
								<KeyboardArrowUpOutlined color="primary" />
							) : (
								<KeyboardArrowDownOutlined color="primary" />
							)}
						</Stack>
					</Box>
				</TableCell>
				{includesMethods && (
					<>
						<TableCell align="center">
							<GroupCheckbox
								label="In App"
								columnName="inApp"
								allowedTransportType={AllowedTransports.InApp}
								items={group.items}
							/>
						</TableCell>
						<TableCell align="center">
							<GroupCheckbox
								label="SMS"
								columnName="sms"
								allowedTransportType={AllowedTransports.Sms}
								items={group.items}
							/>
						</TableCell>
						<TableCell align="center">
							<GroupCheckbox
								label="Email"
								columnName="email"
								allowedTransportType={AllowedTransports.Email}
								items={group.items}
							/>
						</TableCell>
					</>
				)}
				{includesDigest && (
					<TableCell align="center">
						<Stack direction="row" spacing={1.5} justifyContent="center">
							<GroupCheckbox
								label="Hourly"
								columnName="emailDigestHourly"
								allowedTransportType={AllowedTransports.HourlyDigest}
								items={group.items}
							/>
							<GroupCheckbox
								label="Daily"
								columnName="emailDigestDaily"
								allowedTransportType={AllowedTransports.DailyDigest}
								items={group.items}
							/>
						</Stack>
					</TableCell>
				)}
			</TableRow>
			{group.items.map((item) => (
				<NotificationSettingsRow
					key={item.notificationType}
					item={item}
					columns={columns}
					open={open}
				/>
			))}
		</>
	);
};
