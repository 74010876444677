import { LoadingButton } from '@mui/lab';
import { Link, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { codeValidationWhiteSpaceDisallowed } from 'components/ui/LoginYups';
import { TextField } from 'components/ui/fields';
import { PageTitle } from 'components/ui/page';
import { useToast } from 'components/ui/toast';
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import * as Yup from 'yup';

/**
 * Page for confirming the users email.
 */
const ConfirmEmail = ({
	email,
	onSubmit,
	setLoginCredentials,
}: {
	email: string;
	onSubmit: (code: string, helpers: FormikHelpers<ConfirmEmailForm>) => void;
	setLoginCredentials?: any;
}) => {
	const toast = useToast();
	const largeScreen = useWindowDimensions();
	const handleResendCode = async () => {
		try {
			await Auth.resendSignUp(email);
			toast.push('Code was successfully resent', { variant: 'success' });
		} catch (e) {
			console.error(e);
			toast.push('There was an issue resending the code', { variant: 'error' });
		}
	};

	return (
		<>
			<PageTitle title="Log In" />
			<Formik<ConfirmEmailForm>
				initialValues={initialValues}
				onSubmit={(vals, helpers) => onSubmit(vals.code, helpers)}
				validationSchema={confirmEmailValidationSchema}
				validateOnBlur={false}
				validateOnChange={false}>
				{({ isSubmitting, errors, setErrors }) => (
					<Form className="m-auto flex w-96 flex-col text-center">
						<img
							className={clsx(' mx-auto mb-10 w-32', {
								hidden: largeScreen,
							})}
							src="/img/evolve-logo.svg"
							alt="FTEvolve"
						/>
						<Typography mb={2} variant="h2" className="text-neutral-900">
							Confirm Your Account
						</Typography>

						<Typography mb={2} className="text-neutral-900">
							We have sent a code by email to {censorEmail(email)}. Enter it below to
							confirm your account.
						</Typography>
						<TextField
							label="Verification Code *"
							name="code"
							InputLabelProps={{ shrink: true }}
							error={!!errors?.code}
							helperText={<ErrorMessage name="code" />}
							onChangeCapture={() =>
								setErrors({
									...errors,
									code: undefined,
								})
							}
							FormHelperTextProps={{
								className: clsx(!!errors?.code && 'static', 'text-left mb-1'),
							}}
							sx={{
								fieldset: { borderColor: 'neutral.700' },
							}}
							focused
						/>
						<LoadingButton
							className="mt-4 text-white"
							variant="contained"
							color="primary"
							fullWidth
							type="submit"
							loading={isSubmitting}>
							Submit
						</LoadingButton>

						<Typography className=" mt-4 ">
							Didn{"'"}t receive a code?{' '}
							<Link className="text-navy-500" onClick={handleResendCode}>
								Resend
							</Link>
						</Typography>
						<Typography className="mt-4 ">
							<Link
								data-test="back-btn"
								onClick={() => setLoginCredentials(null)}
								className="text-navy-500"
								href="/auth/login">
								Back to Log in
							</Link>
						</Typography>
					</Form>
				)}
			</Formik>
		</>
	);
};

const confirmEmailValidationSchema = Yup.object({
	code: codeValidationWhiteSpaceDisallowed,
});

export type ConfirmEmailForm = { code: string };
const initialValues = { code: '' };

export const censorEmail = (email: string): string => {
	const emailParts = email.split('@');
	const [localPart, domainPart] = emailParts;
	const censoredLocal = localPart.charAt(0) + '***';
	const censoredDomain = domainPart.charAt(0) + '***';

	return `${censoredLocal}@${censoredDomain}`;
};

export default ConfirmEmail;
