import { People, PeopleOutlined } from '@mui/icons-material';
import { Container, Grid } from '@mui/material';
import { OrgOutletContext } from 'components/pages/org/outlet';
import { NavList, NavListItem } from 'components/ui/nav-list';
import { PageContent, PageTitle } from 'components/ui/page';
import { Outlet, useOutletContext } from 'react-router-dom';
import { useIsMediumOrSmallerScreen } from 'utils/useScreenSize';

const OrgNetworkOutlet = () => {
	// We need to forward the context from this outlet to the next
	const orgId = useOutletContext<OrgOutletContext>();

	const isMediumOrSmallerScreen = useIsMediumOrSmallerScreen();

	return (
		<>
			<PageTitle title="Organization Network" />
			<PageContent>
				<Container
					sx={{
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
					}}>
					<Grid container spacing={4} flex={1}>
						{!isMediumOrSmallerScreen && (
							<Grid item xs={12} md={2}>
								<NavList title="Network" sticky className="pt-2">
									<NavListItem
										activeIcon={<People color="primary" />}
										icon={<PeopleOutlined sx={{ color: 'neutral.500' }} />}
										text="Connections"
										to="connections"
									/>
								</NavList>
							</Grid>
						)}
						<Grid item xs={12} md={10}>
							<Outlet context={orgId satisfies OrgOutletContext} />
						</Grid>
					</Grid>
				</Container>
			</PageContent>
		</>
	);
};

export default OrgNetworkOutlet;
