import { gql } from '@apollo/client';
import { EMBLEM_FIELDS } from 'components/ui/emblem/fragments';

export const LABEL_FIELDS = gql`
	fragment LabelFields on Label {
		id
		name
		type
		colorName
		parentLabelId
	}
`;

export const CONVERSATION_SUMMARY_FIELDS = gql`
	${EMBLEM_FIELDS}
	fragment ConversationSummaryFields on ConversationSummary {
		conversationId
		lastMessageDateTimeUtc
		entityIdToDisplay
		entityTypeToDisplay
		lastMessagePreview
		subject
		unreadMessageCount
		participantsCount
		lastMessageIsFromCurrentEntity
		emblemToDisplay {
			...EmblemFields
		}
	}
`;

export const CONVERSATION_FIELDS = gql`
	fragment ConversationFields on Conversation {
		id
		subject
		participants {
			entityId
			entityType
			isCurrentParticipant
			# emblem {
			# 	...EmblemFields
			# }
			participantLabels {
				id
				type
				name
			}
		}
		messages {
			id
			createdUtc
			creatorEntityId
			creatorEntityType
			messageText
			# creatorEmblem {
			# 	...EmblemFields
			# }
			messageParticipants {
				entityId
				entityType
				firstReadUtc
			}
			attachments {
				id
				currentInstance {
					id
					cdnUrl
					fileName
					fileSize
					virusStatus
					uploadedUtc
				}
			}
		}
	}
`;

export const CONVERSATION_AND_SUMMARY_FIELDS = gql`
	${CONVERSATION_FIELDS}
	${CONVERSATION_SUMMARY_FIELDS}
	fragment ConversationAndSummaryFields on ConversationAndSummary {
		conversation {
			...ConversationFields
		}
		conversationSummary {
			...ConversationSummaryFields
		}
	}
`;
