import { ForumOutlined } from '@mui/icons-material';
import { Avatar, Stack, Typography } from '@mui/material';

export const EmptyConversationColumn = () => {
	return (
		<Stack
			height="100%"
			alignItems="center"
			justifyContent="center"
			textAlign="center"
			p={4}
			spacing={2}>
			<Avatar sx={{ height: 72, width: 72 }}>
				<ForumOutlined fontSize="large" />
			</Avatar>
			<Typography variant="h5">No conversation selected</Typography>
		</Stack>
	);
};
