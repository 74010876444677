import { useField } from 'formik';
import { NumericFormat } from 'react-number-format';
import { TextField as MTTextField } from '@mui/material';

/**
 * NumberField is a number input.
 *
 * @param {NumberFieldProps} props
 * @returns
 */
export const NumberField = (props: NumberFieldProps) => {
	const [, , helpers] = useField({
		name: props.name,
		validate: (value: number | undefined) => {
			if (value === undefined) {
				if (props.required === true) return 'Required Field';
				return;
			}
			if (value < 0 && props.positiveNumber) return 'Positive numbers only';
			if (Math.floor(value) !== value && props.integerOnly) return 'Whole numbers only';
		},
	});
	return (
		<NumericFormat
			name={props.name}
			label={props.label}
			disabled={props.disabled}
			required={props.required}
			customInput={MTTextField}
			maxLength={props.maxLength}
			onValueChange={(values) => {
				helpers.setValue(values.value ? Number(values.value) : undefined);
			}}
			fullWidth
		/>
	);
};

type NumberFieldProps = {
	name: string;
	label: string | undefined;
	disabled?: boolean;
	required?: boolean;
	positiveNumber?: boolean;
	integerOnly?: boolean;
	maxLength?: number;
};
