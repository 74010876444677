import { Box, Stack } from '@mui/material';

export const TypingIndicator = () => {
	return (
		<Stack direction="row" spacing={1}>
			<Dot />
			<Dot delay={0.25} />
			<Dot delay={0.5} />
		</Stack>
	);
};

const Dot = ({ delay }: { delay?: number }) => {
	return (
		<Box
			height={8}
			width={8}
			borderRadius={100}
			sx={{
				'backgroundColor': 'neutral.200',
				'animation': 'bounce 1s infinite',
				'animationDelay': delay ? `${delay}s` : undefined,
				'@keyframes bounce': {
					'0%': {
						transform: 'translateY(-25%)',
						animationTimingFunction: 'cubic-bezier(0.8, 0, 1, 1)',
					},
					'50%': {
						transform: 'translateY(0)',
						animationTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
					},
					'100%': {
						transform: 'translateY(-25%)',
						animationTimingFunction: 'cubic-bezier(0.8, 0, 1, 1)',
					},
				},
			}}
		/>
	);
};
