import { ErrorOutlined } from '@mui/icons-material';
import { Auth } from 'aws-amplify';
import { useGlobalSignOut } from 'components/pages/user/account/security/hooks';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalLoadingButton,
} from 'components/ui/modal';
import { useSession } from 'utils/session';

interface GlobalSignoutModalProps {
	userId: string;
}

export const GlobalSignoutModal = ({ userId }: GlobalSignoutModalProps) => {
	const { user } = useSession();
	const { globalSignOut } = useGlobalSignOut();

	return (
		<>
			<ConfirmModalContent
				visual={
					<EmptyStateAvatar
						avatarProps={{ bgcolor: 'error.50' }}
						iconProps={{ color: 'error.500' }}
						icon={<ErrorOutlined />}
					/>
				}
				subheadline="Are you sure?"
				informativeContent={
					user.userId == userId
						? `Do you really want to log out of all devices?`
						: `Do you really want to log this user out of all of their devices?`
				}
			/>
			<ModalActions>
				<ModalActionButton size="large" variant="outlined">
					Cancel
				</ModalActionButton>
				<ModalLoadingButton
					size="large"
					variant="contained"
					color="error"
					onClick={async () => {
						if (user.userId !== userId) {
							return await globalSignOut(userId);
						}
						await Auth.signOut({ global: true });
						window.location.href = '/auth/login';
						return false;
					}}>
					Sign Out
				</ModalLoadingButton>
			</ModalActions>
		</>
	);
};
