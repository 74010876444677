import { useQuery } from '@apollo/client';
import { EditOutlined, WorkOutlineRounded, AccountTreeOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { Alert } from 'components/ui/alert';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { SelectField, TextField } from 'components/ui/fields';
import { useToast } from 'components/ui/toast';
import { connect, Formik } from 'formik';
import { useState } from 'react';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { useValidation } from 'utils/useValidation';
import { useSiteUser } from 'utils/useSiteUser';
import { ApolloError, FetchResult, useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import {
	Mutation,
	MutationUserSiteUserUpdateArgs,
	Query,
	QuerySiteUserArgs,
	SiteRole,
	SiteUser,
} from 'middleware-types';

/**
 * useSiteUserInformationQuery() - Hook for fetching a site user and getting a list of site roles
 *
 * @param {string} siteUserId
 * @return {*}  {({ siteUser: SiteUser | undefined; siteRoles: SiteRole[] | undefined; loading: boolean; error?: ApolloError })}
 */
export const useSiteUserInformationQuery = (
	siteUserId: string
): {
	siteUser: SiteUser | undefined;
	loading: boolean;
	error?: ApolloError;
} => {
	const {
		data: siteUser,
		loading: loadingSiteUser,
		error: siteUserQueryError,
	} = useQuery<Pick<Query, 'siteUser'>, QuerySiteUserArgs>(SITE_USER, {
		variables: { id: siteUserId },
	});

	return {
		siteUser: siteUser?.siteUser,
		loading: loadingSiteUser,
		error: siteUserQueryError,
	};
};

const SITE_USER = gql`
	query siteUser($id: ID!) {
		siteUser(id: $id) {
			id
			affiliation
			roles {
				id
				name
			}
		}
	}
`;

export const SITE_ROLES = gql`
	query siteRoles {
		siteRoles {
			items {
				id
				name
			}
		}
	}
`;

/**
 * useSiteUserInformationMutation() - Hook for updating a site user's site user information
 *
 * @return {*}  {{
 * 	updateSiteUser: (siteUserId: string, update: SiteUserUpdate) => Promise<FetchResult<SiteUserUpdate>>;
 * 	error?: ApolloError
 * }}
 */
export const useSiteUserInformationMutation = (): {
	updateSiteUser: (
		siteUserId: string,
		update: SiteUserUpdate
	) => Promise<FetchResult<Pick<Mutation, 'userSiteUserUpdate'>>>;
	error?: ApolloError;
} => {
	const [updateSiteUserMutation, { error }] = useMutation<
		Pick<Mutation, 'userSiteUserUpdate'>,
		MutationUserSiteUserUpdateArgs
	>(UPDATE_SITE_USER);

	const updateSiteUser = (siteUserId: string, update: SiteUserUpdate) => {
		return updateSiteUserMutation({
			variables: {
				siteUserId,
				update: {
					affiliation: update.affiliation,
					siteRoleIds: [update.role],
				},
			},
		});
	};

	return { updateSiteUser, error };
};

export type SiteUserUpdate = {
	role: string;
	affiliation: string;
};

const UPDATE_SITE_USER = gql`
	mutation userSiteUserUpdate($siteUserId: ID!, $update: SiteUserUpdate!) {
		userSiteUserUpdate(siteUserId: $siteUserId, update: $update) {
			id
			affiliation
			roles {
				id
				name
			}
		}
	}
`;

/**
 * useSiteUserInformation() - Hook used for loading and editing the site user information
 *
 * @param {string} siteUserId
 * @return {*}
 */
const useSiteUserInformation = (siteUserId: string) => {
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const { updateSiteUser, error: mutationError } = useSiteUserInformationMutation();
	const { siteUser, loading, error: queryError } = useSiteUserInformationQuery(siteUserId);
	const validation = useValidation('UserAccountUpdate');
	const toast = useToast();

	const initialValues = {
		role: siteUser?.roles?.[0].id ?? '',
		affiliation: siteUser?.affiliation ?? '',
	};

	const onSubmit = async (values: SiteUserUpdate) => {
		await updateSiteUser(siteUserId, values)
			.then(async (res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Site User Information updated successfully.', {
					variant: 'success',
				});
				setIsEditing(false);
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		isEditing,
		setIsEditing,
		initialValues,
		onSubmit,
		siteUser,
		loading,
		error: queryError || mutationError,
		validationSchema: validation.schema,
	};
};

/**
 * useCanEditSiteUserInformation(siteUserId) - This hook calculates if a user can edit Site User information.
 *
 * @param {(string | undefined)} siteUserId
 * @return {*}
 */
const useCanEditSiteUserInformation = (siteUserId: string | undefined) => {
	const { user } = useSession();
	const { hasPermission } = useSiteUser();
	const canEdit = () => {
		// Cannot edit siteUser information of yourself
		if (siteUserId === user.siteUserId) return false;
		// Can only edit if you have site user update.
		if (hasPermission(Permission.Site_User_U)) return true;
		return false;
	};

	return { canEdit };
};

/**
 * SiteUserInformation - Display the user's site user information: role and affiliation
 *
 * @param {{ siteUserId: string }} { siteUserId }
 * @return {*}
 */
export const SiteUserInformation = ({ siteUserId }: { siteUserId: string }) => {
	const {
		isEditing,
		setIsEditing,
		initialValues,
		onSubmit,
		siteUser,
		loading,
		error,
		validationSchema,
	} = useSiteUserInformation(siteUserId);
	const { canEdit } = useCanEditSiteUserInformation(siteUserId);

	return (
		<Formik<SiteUserUpdate>
			initialValues={initialValues}
			onSubmit={onSubmit}
			enableReinitialize
			validationSchema={validationSchema}>
			{(fProps) => (
				<Card>
					<CardHeader
						title={
							<Typography variant="h2" height="2rem">
								Site User Information
							</Typography>
						}
						action={
							isEditing ? (
								<Grid container spacing={1}>
									<Grid item>
										<Button
											variant="outlined"
											onClick={() => {
												fProps.resetForm();
												setIsEditing(false);
											}}>
											Cancel
										</Button>
									</Grid>
									<Grid item>
										<LoadingButton
											disabled={
												!fProps.isValid ||
												!fProps.dirty ||
												fProps.isSubmitting
											}
											color="primary"
											onClick={fProps.submitForm}
											loading={fProps.isSubmitting}
											variant="contained">
											Save
										</LoadingButton>
									</Grid>
								</Grid>
							) : (
								<>
									{canEdit() && (
										<IconButton onClick={() => setIsEditing(true)}>
											<EditOutlined />
										</IconButton>
									)}
								</>
							)
						}
					/>
					<Divider />
					<CardContent>
						<Box pt={1}>
							{error && <Alert error={error} />}
							{loading || !siteUser ? (
								<SiteUserInformationLoadingSkeleton />
							) : isEditing ? (
								<SiteUserInformationEdit />
							) : (
								<SiteUserInformationRead siteUser={siteUser} />
							)}
						</Box>
					</CardContent>
				</Card>
			)}
		</Formik>
	);
};

/**
 * SiteUserInformationRead - Used for displaying the site user information data
 *
 * @param {*} { siteUser }
 * @return {*}
 */
const SiteUserInformationRead = ({ siteUser }: { siteUser: SiteUser }) => (
	<Stack spacing={2} justifyContent="flex-start">
		<Grid container alignContent="flex-start">
			<Grid item pr={{ xs: 2, sm: 4 }}>
				<AccountTreeOutlined fontSize="small" />
			</Grid>
			<Grid item sm={2}>
				<Typography variant="h5">Role</Typography>
			</Grid>
			<Grid item xs={12} sm={9}>
				{siteUser?.roles?.[0]?.name}
			</Grid>
		</Grid>
		<Grid container alignContent="flex-start">
			<Grid item pr={{ xs: 2, sm: 4 }}>
				<WorkOutlineRounded fontSize="small" />
			</Grid>
			<Grid item sm={2}>
				<Typography variant="h5">Affiliation</Typography>
			</Grid>
			<Grid item xs={12} sm={9}>
				{siteUser?.affiliation}
			</Grid>
		</Grid>
	</Stack>
);

/**
 * SiteUserInformationEdit - Displays the Site User Information form
 *
 * @param {*} { siteRole[] }
 * @return {*}
 */
const SiteUserInformationEdit = connect<Record<string, never>, SiteUserUpdate>(() => {
	const { data, loading } = useQuery<Pick<Query, 'siteRoles'>>(SITE_ROLES);
	return (
		<Stack spacing={2}>
			{loading ? (
				<SiteUserInformationLoadingSkeleton />
			) : (
				<>
					<Grid container alignItems="center">
						<Grid
							item
							pr="2rem"
							sx={{
								display: {
									sm: 'block',
									xs: 'none',
								},
							}}>
							<AccountTreeOutlined fontSize="small" />
						</Grid>

						<Grid item xs={12} sm={3}>
							<SelectField
								name="role"
								label="Role"
								required
								InputLabelProps={{ shrink: true }}>
								{data?.siteRoles.items.map((siteRole: SiteRole) => (
									<MenuItem key={siteRole.id} value={siteRole.id}>
										{siteRole.name}
									</MenuItem>
								))}
							</SelectField>
						</Grid>
					</Grid>
					<Grid container alignItems="center">
						<Grid
							item
							pr="2rem"
							sx={{
								display: {
									sm: 'block',
									xs: 'none',
								},
							}}>
							<WorkOutlineRounded fontSize="small" />
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField required label="Affiliation" name="affiliation" />
						</Grid>
					</Grid>
				</>
			)}
		</Stack>
	);
});

const SiteUserInformationLoadingSkeleton = () => (
	<Grid container spacing={2}>
		<Grid item xs={12}>
			<Skeleton width="40%" height="3rem" />
		</Grid>
		<Grid item xs={12}>
			<Skeleton width="40%" height="3rem" />
		</Grid>
	</Grid>
);
