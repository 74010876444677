import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Query, QueryAdjusterLicensingSummaryArgs } from 'middleware-types';
export type LicensingProvenance = 'Nipr' | 'ManuallyEntered';

export const ADJUSTER_LICENSE_SUMMARIES = gql`
	query adjusterLicensingSummary($userId: ID!) {
		adjusterLicensingSummary(userId: $userId) {
			userId
			summaries {
				userId
				countryId
				licensingProvenance
				adjusterLicensingText
				adjusterLicensingTextSensitive
				numLicenses
				numActiveLicenses
				numExpiredLicenses
				numExpiredWithin60DaysLicenses
				licenseHoldersName
				activeRegionIds
				expiredRegionIds
				inactiveRegionIds
			}
		}
	}
`;

/**
 * useAdjusterLicenseSummariesQuery - This query provides get all the adjuster license summaries for a
 * given user.
 *
 * @param {NiprLookupArgs} lookup
 * @return {*}
 */
export const useAdjusterLicenseSummariesQuery = (userId: string) => {
	const { loading, data, error } = useQuery<
		Pick<Query, 'adjusterLicensingSummary'>,
		QueryAdjusterLicensingSummaryArgs
	>(ADJUSTER_LICENSE_SUMMARIES, {
		fetchPolicy: 'cache-and-network',
		variables: { userId },
	});

	return {
		summaries: data?.adjusterLicensingSummary?.summaries ?? [],
		loading,
		error,
	};
};
