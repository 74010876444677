import { Box, Card, Divider, Stack, Tooltip } from '@mui/material';
import { AdjusterLicensingSummary } from 'middleware-types';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { useUsStates } from 'utils/useCountries';
import { useMapColors } from 'utils/useMapColors';
import { LicensingSummaryDetails } from './licensing-summary-details';
import { UsaContextOptions } from './usa/modal';
import { MapKey } from './map-key';

interface UsaLicensingSummaryProps {
	summary: AdjusterLicensingSummary;
}

export const UsaLicensingSummary = ({ summary }: UsaLicensingSummaryProps) => {
	const { statesLookupMapById } = useUsStates();
	const activeStateNames =
		summary.activeRegionIds?.map((id) => statesLookupMapById.get(id)?.displayName) ?? [];
	const expiredStateNames =
		summary.expiredRegionIds?.map((id) => statesLookupMapById.get(id)?.displayName) ?? [];
	const inactiveStateNames =
		summary.inactiveRegionIds?.map((id) => statesLookupMapById.get(id)?.displayName) ?? [];

	const { defaultColor, activeColor, expiredColor, inactiveColor, borderColor } = useMapColors();

	return (
		<Card>
			<Stack direction="row" justifyContent="space-between" alignItems="center" p={1.5}>
				<LicensingSummaryDetails summary={summary} />
				<UsaContextOptions summary={summary} />
			</Stack>
			<Divider />
			<Box p={2}>
				<ComposableMap projection="geoAlbersUsa">
					<Geographies geography="/maps/usa-topo.json">
						{({ geographies }) =>
							geographies.map((geo) => {
								const isActive = activeStateNames.includes(geo.properties.name);
								const isExpired = expiredStateNames.includes(geo.properties.name);
								const isInactive = inactiveStateNames.includes(geo.properties.name);
								const fill = isActive
									? activeColor
									: isExpired
									? expiredColor
									: isInactive
									? inactiveColor
									: defaultColor;
								return (
									<Tooltip
										key={geo.id}
										title={geo.properties.name}
										followCursor
										arrow>
										<Geography
											geography={geo}
											stroke={borderColor}
											fill={fill}
											tabIndex={-1}
											style={{
												default: { outline: 'none' },
												hover: { outline: 'none' },
												pressed: { outline: 'none' },
											}}
										/>
									</Tooltip>
								);
							})
						}
					</Geographies>
				</ComposableMap>
				<MapKey includeInactive />
			</Box>
		</Card>
	);
};
