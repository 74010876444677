import { LoadingButton } from '@mui/lab';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { ContainedDrawer } from 'components/ui/containedDrawer';
import { theme } from 'utils/theme';
import { useLocalStorage } from 'utils/useLocalStorage';

interface EvoxFileUploadInfoMessageDrawerProps {
	open: boolean;
	onClose: () => void;
}

export const EvoxFileUploadInfoMessageDrawer = ({
	open,
	onClose,
}: EvoxFileUploadInfoMessageDrawerProps) => {
	const [checked, setChecked] = useLocalStorage('evoxFileUploadInfoMessageDismissed', false);

	return (
		<ContainedDrawer
			transitionDuration={0}
			open={open}
			anchor="bottom"
			containerid="evox-container"
			PaperProps={{ sx: { borderRadius: 1 } }}>
			<Stack spacing={1} p={2} borderTop={`1px solid ${theme.palette.divider}`}>
				<Typography variant="h2" fontSize={14}>
					File Upload Disclaimer
				</Typography>
				<Typography variant="body1" fontSize={12}>
					All files uploaded through EVO-X will be uploaded and processed by OpenAI’s API.
					However, your files will not be used to train OpenAI’s models.
				</Typography>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<FormControlLabel
						control={
							<Checkbox
								checked={checked}
								onChange={(_e, checked) => setChecked(checked)}
							/>
						}
						label="Don't show this again"
					/>
					<LoadingButton
						color="primary"
						variant="contained"
						sx={{ alignSelf: 'stretch' }}
						onClick={onClose}>
						Dismiss
					</LoadingButton>
				</Stack>
			</Stack>
		</ContainedDrawer>
	);
};
