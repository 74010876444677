import { ApolloProvider } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/react';
import { ToastContainer } from 'components/ui/toast';
import { router } from 'pages/_routes';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import {
	RouterProvider,
	createBrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import { version } from '../package.json';
import './index.css';
import { client } from './utils/apollo';
import { AppThemeProvider } from './utils/theme';
import { LoginRedirectProvider } from 'utils/loginRedirect';

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
	ignoreErrors: [/^4\d{2}$/],
	tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_SAMPLE_RATE ?? '0.0'),
	integrations: [
		new Sentry.BrowserTracing({
			tracePropagationTargets: [
				'localhost',
				import.meta.env.VITE_BFF,
				/http[s]?:\/\/.*?\.ftevolve\..+/,
			],
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			),
		}),
	],
	release: `fte-frontend-${version}`,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const sentryRouter = sentryCreateBrowserRouter(router.routes);

const App: React.FC = () => {
	return (
		<AppThemeProvider>
			<HelmetProvider>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<ApolloProvider client={client}>
						<ToastContainer>
							<LoginRedirectProvider>
								<RouterProvider router={sentryRouter} />
							</LoginRedirectProvider>
						</ToastContainer>
					</ApolloProvider>
				</LocalizationProvider>
			</HelmetProvider>
		</AppThemeProvider>
	);
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
