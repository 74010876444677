import { Card, Stack, Typography } from '@mui/material';
import {
	useCanEditPersonalInformation,
	usePersonalInformationQuery,
} from 'components/pages/user/account/personal/personal-info-hooks';
import { getAssumeIdentity } from 'utils/session';
import { DevicesCard } from './devices/devices-card';
import { LoginSettingsCard } from './login-settings/login-settings-card';
import { SessionSettingsCard } from './session-settings/session-settings-card';

export const SecurityPage = ({ userId }: { userId: string }) => {
	const { account } = usePersonalInformationQuery(userId);
	const { canEdit } = useCanEditPersonalInformation(account);
	const assumedIdentity = getAssumeIdentity();

	if (assumedIdentity) {
		return (
			<Card>
				<Stack alignItems="center" p={3}>
					<Typography variant="h2">
						Security settings unavailable during impersonation.
					</Typography>
				</Stack>
			</Card>
		);
	}

	return (
		<Stack spacing={2}>
			<LoginSettingsCard userId={userId} canEdit={canEdit} />
			<DevicesCard userId={userId} canEdit={canEdit} />
			<SessionSettingsCard userId={userId} canEdit={canEdit} />
		</Stack>
	);
};
