import { PageError } from 'utils/errors';
import { gql, useQuery } from '@apollo/client';
import { Country, Query } from 'middleware-types';

/**
 * Lookup countries and languages in the preloaded cache and
 * default to the first item in the list.
 * @returns a set of default countries and languages
 */
export const useDefaults = (): {
	language: string;
	country: Pick<Country, 'id' | 'phonePrefix' | 'iso3CountryCode'>;
	loading: boolean;
} => {
	const { data, loading, error } = useQuery<Pick<Query, 'countries' | 'languages'>>(
		gql`
			query {
				countries {
					id
					iso3CountryCode
					name
					phonePrefix
					phoneNumberFormat
					address1Label
					address2Label
					municipalityLabel
					adminArea1Label
					adminArea2Label
					postalCodeLabel
					address1Visibility
					address2Visibility
					municipalityVisibility
					adminArea1Visibility
					adminArea2Visibility
					postalCodeVisibility
					addressFieldLookups {
						id
						field
						value
						displayName
						displayOrder
					}
					nationalIdentificationNumberRegex
				}
				languages {
					id
					name
					code
				}
			}
		`,
		{
			fetchPolicy: 'cache-first',
		}
	);

	if (error) throw new PageError(error.graphQLErrors);

	const usa = data?.countries?.find((c) => c.iso3CountryCode === 'USA');

	return {
		language: data?.languages[0]?.id ?? '',
		country: {
			id: usa?.id ?? data?.countries[0]?.id ?? '',
			phonePrefix: '1',
			iso3CountryCode: 'USA',
		},
		loading: loading,
	};
};
