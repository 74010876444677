import { CheckOutlined, ContentCopyOutlined } from '@mui/icons-material';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { useState } from 'react';

interface CopyButtonProps extends Omit<IconButtonProps, 'onClick'> {
	text: string;
}

export const CopyButton = ({ text, ...props }: CopyButtonProps) => {
	const [copied, setCopied] = useState(false);
	const copy = () => {
		navigator.clipboard.writeText(text);
		setCopied(true);
		setTimeout(() => setCopied(false), 2000);
	};

	return (
		<Tooltip title={copied ? 'Copied!' : 'Copy'}>
			<IconButton onClick={copy} {...props}>
				{copied ? <CheckOutlined /> : <ContentCopyOutlined />}
			</IconButton>
		</Tooltip>
	);
};
