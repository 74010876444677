import { Box, Button, Stack } from '@mui/material';
import { MaskIcon } from 'components/ui/icons';
import { assume, getAssumeIdentity } from 'utils/session';
import { useAccount } from 'utils/useAccount';

export const AssumeIdentityBanner = () => {
	const assumedIdentity = getAssumeIdentity();
	const { account, loading } = useAccount();
	return (
		<>
			{assumedIdentity && !loading && (
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					sx={{
						p: '0.25rem 1rem',
						backgroundColor: 'warning.500',
						color: 'warning.contrastText',
					}}>
					<Box />
					<Stack direction="row" alignItems="center">
						<MaskIcon />
						<strong>&nbsp;Assumed Identity:&nbsp;</strong>
						<span>{`${account?.name?.firstName} ${account?.name?.lastName}`}</span>
					</Stack>
					<Button onClick={() => assume(null)}>Revert Identity</Button>
				</Stack>
			)}
		</>
	);
};
