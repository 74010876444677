import { DomainDisabled } from '@mui/icons-material';
import { Avatar, Badge, Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { OrgEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { EmblemAvatarGroup } from 'components/ui/emblem/emblem-avatar-group';
import { Connection, ConnectionState } from 'middleware-types';
import { ReactNode, useState } from 'react';
import { teal } from 'utils/theme';
import { ConnectionStateExtended } from '../../shared/types';
import { useOrgConnections } from './hooks';
import { ManageConnectionsModal } from './ManageConnectionsModal';
import { OrgConnectionItem } from './OrgConnectionItem';

interface OrgConnectionsBarProps {
	connections: Connection[];
	entityId: string;
	entityName: string;
	entityLoading: boolean;
	showConnectedOnly?: boolean;
}

export const OrgConnectionsBar = ({
	connections,
	entityId,
	entityName,
	entityLoading,
	showConnectedOnly = false,
}: OrgConnectionsBarProps) => {
	const [modalOpen, setModalOpen] = useState(false);
	const { organizations: allOrganizations, loading: connectionsLoading } = useOrgConnections(
		connections,
		entityId,
		entityName
	);

	const connectedOrgs = allOrganizations.filter(
		(org) => org.connectionState === ConnectionState.Connected
	);

	const orgsWithRequests = allOrganizations.filter(
		(org) => org.connectionState === ConnectionStateExtended.IncomingRequest
	);

	const organizations = showConnectedOnly ? connectedOrgs : allOrganizations;

	const loading = entityLoading || connectionsLoading;

	if (loading) {
		return (
			<ConnectionsBarContainer>
				<Stack direction="row" spacing={2} alignItems="center">
					<Skeleton variant="rounded" height={40} width={40} />
					<Box flex={1}>
						<Skeleton variant="text" width={100} />
						<Skeleton variant="text" width={300} />
					</Box>
					<Skeleton variant="rounded" height={40} width={100} />
				</Stack>
			</ConnectionsBarContainer>
		);
	}

	if (organizations.length === 0) {
		return null;
	}

	if (organizations.length === 1) {
		return (
			<ConnectionsBarContainer>
				<OrgConnectionItem organization={organizations[0]} />
			</ConnectionsBarContainer>
		);
	}

	return (
		<>
			<ConnectionsBarContainer>
				<Stack
					spacing={2}
					direction={{ xs: 'column', sm: 'row' }}
					alignItems={{ xs: 'flex-start', sm: 'center' }}
					justifyContent="space-between">
					<Stack
						spacing={2}
						direction={{ xs: 'column', sm: 'row' }}
						alignItems={{ xs: 'flex-start', sm: 'center' }}>
						{connectedOrgs.length > 0 ? (
							<EmblemAvatarGroup max={4} variant="square">
								{connectedOrgs.map((org) => (
									<OrgEmblemAvatar key={org.orgId} id={org.orgId} />
								))}
							</EmblemAvatarGroup>
						) : (
							<Avatar variant="square" sx={{ height: 40, width: 40 }}>
								<DomainDisabled />
							</Avatar>
						)}
						<Typography flex={1} color="inherit">
							{connectedOrgs.length > 0
								? `${entityName} is connected to ${
										connectedOrgs.length
								  } organization${
										connectedOrgs.length === 1 ? '' : 's'
								  } you manage.`
								: `${entityName} is not connected to any organizations you manage.`}
						</Typography>
					</Stack>
					<Badge badgeContent={orgsWithRequests.length} color="error">
						<Button variant="outlined" onClick={() => setModalOpen(true)}>
							Manage Connections
						</Button>
					</Badge>
				</Stack>
			</ConnectionsBarContainer>
			<ManageConnectionsModal
				organizations={organizations}
				open={modalOpen}
				onClose={() => setModalOpen(false)}
			/>
		</>
	);
};

const ConnectionsBarContainer = ({ children }: { children: ReactNode }) => {
	return (
		<Box
			sx={{
				p: 2,
				borderRadius: { xs: 0, sm: 1 },
				borderWidth: '1px',
				borderStyle: 'solid',
				borderRightStyle: { xs: 'none', sm: 'solid' },
				borderLeftStyle: { xs: 'none', sm: 'solid' },
				borderColor: teal[800],
				backgroundColor: teal[50],
				color: teal[800],
			}}>
			{children}
		</Box>
	);
};
