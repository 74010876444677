import { DeleteForeverOutlined, InfoOutlined, Search } from '@mui/icons-material';
import {
	Box,
	CircularProgress,
	Divider,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { InfiniteScroll } from 'components/ui/infinite-scroll';
import { debounce } from 'lodash';
import { LabelType } from 'middleware-types';
import { useCallback, useState } from 'react';
import { useCommunicationsContext } from '../communications-provider';
import { LabelWithColor } from '../helpers/label-colors';
import { useConversationSearch } from '../hooks/conversation-search-hooks';
import { useCommunicationsModal } from '../hooks/use-communications-modal';
import { EmptyTrashModal } from '../modals/empty-trash-modal';
import { LabelIcon } from '../shared/label-icon';
import { SummariesColumnSkeleton } from './summaries-column-skeleton';
import { SummariesListSkeleton } from './summaries-list-skeleton';
import { SummaryListItem } from './summary-list-item';

interface SummariesColumnProps {
	selectedLabel: LabelWithColor;
}

const _SummariesColumn = ({ selectedLabel }: SummariesColumnProps) => {
	const [searchText, setSearchText] = useState('');
	const debouncedSetSearchText = useCallback(debounce(setSearchText, 500), []);

	const { showModal } = useCommunicationsModal();
	const { summaries, loading, loadMore, canLoadMore, isRefetching } = useConversationSearch(
		selectedLabel.id,
		searchText
	);

	return (
		<Stack height="100%">
			<Stack p={1.5} spacing={1.5}>
				<Stack
					direction="row"
					spacing={1}
					alignItems="center"
					justifyContent="space-between">
					<Stack direction="row" spacing={1} alignItems="center">
						<LabelIcon label={selectedLabel} selected />
						<Typography variant="h4" flex={1}>
							{selectedLabel.name}
						</Typography>
						{selectedLabel.type === LabelType.Trash && (
							<Tooltip title="Items in Trash will be permanently deleted from your inbox after 30 days.">
								<InfoOutlined sx={{ color: 'neutral.500' }} />
							</Tooltip>
						)}
					</Stack>
					{selectedLabel.type === LabelType.Trash ? (
						<IconButton
							size="small"
							onClick={() =>
								showModal({
									title: 'Are you sure?',
									content: <EmptyTrashModal />,
								})
							}>
							<DeleteForeverOutlined />
						</IconButton>
					) : (
						// <IconButton size="small">
						// 	<FilterList />
						// </IconButton>
						<Box height={34} />
					)}
				</Stack>
				<TextField
					name="conversation-search"
					placeholder="Search"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
					}}
					onChange={(e) => debouncedSetSearchText(e.target.value)}
				/>
			</Stack>
			<Divider />
			{loading ? (
				<SummariesListSkeleton />
			) : summaries.length === 0 ? (
				<Box p={2} textAlign="center">
					<Typography variant="caption">No conversations</Typography>
				</Box>
			) : (
				<InfiniteScroll loadMore={loadMore} threshold={10} canLoadMore={canLoadMore}>
					<List disablePadding>
						{summaries.map((summary) => (
							<SummaryListItem key={summary.conversationId} summary={summary} />
						))}
						{isRefetching && (
							<ListItem>
								<Stack width="100%" alignItems="center" py={1}>
									<CircularProgress size={30} />
								</Stack>
							</ListItem>
						)}
					</List>
				</InfiniteScroll>
			)}
		</Stack>
	);
};

/**
 * wrapper for this component
 */
export const SummariesColumn = () => {
	const { selectedLabel } = useCommunicationsContext();
	if (selectedLabel === undefined) return <SummariesColumnSkeleton />;
	return <_SummariesColumn selectedLabel={selectedLabel} />;
};
