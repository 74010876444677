import { useQuery, gql } from '@apollo/client';
import { Query } from 'middleware-types';

export const useLegacyDatabasesQuery = () => {
	const { data, error, loading } = useQuery<Pick<Query, 'legacyDatabases'>>(
		gql`
			query legacyDatabases {
				legacyDatabases {
					name
					systemId
					systemUrl
				}
			}
		`,
		{
			fetchPolicy: 'cache-first',
			onError: () => {
				console.log(error);
			},
		}
	);

	return {
		systems: data?.legacyDatabases || [],
		error,
		loading,
	};
};
