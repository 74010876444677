import React, { useState } from 'react';
import {
	Collapse,
	Table,
	TableContainer,
	TableRow,
	TableBody,
	TableCell,
	Tooltip,
	Typography,
	IconButton,
	Checkbox as MTCheckbox,
	Skeleton,
	Link,
	Stack,
	Box,
} from '@mui/material';
import {
	InfoOutlined,
	KeyboardArrowDownOutlined,
	KeyboardArrowUpOutlined,
} from '@mui/icons-material';
import { CheckboxField } from 'components/ui/fields';
import { Field, FieldProps } from 'formik';
import { PermissionCategory } from 'middleware-types';

export type RoleCategory = {
	id: string;
	name: string;
};

/**
 * Permission group types by operation.
 *
 * @enum {number}
 */
export enum OperationType {
	Read = 'Read',
	Create = 'Create',
	Update = 'Update',
	Delete = 'Delete',
}

/**
 * Permission group type, Create/View/Update/Delete contain the permission key.
 *
 */
type PermissionGroupKeys = {
	name: string;
	description: string;
	Create?: string;
	Read?: string;
	Update?: string;
	Delete?: string;
};

/**
 * Permission category grouping.
 *
 */
type CategoricalPermission = {
	name: string;
	permissions: PermissionGroupKeys[];
};

/**
 * Map Permission into a category model.
 *
 * @param {*} data
 * @returns {Category[]}
 */
const MapPermissionCategories = (cats: PermissionCategory[]): CategoricalPermission[] => {
	const originalCats = [...cats].sort((a, b) => a.displayOrder - b.displayOrder);
	return originalCats.map((c) => {
		let cat: CategoricalPermission = {
			name: c.name,
			permissions: [],
		};

		const sortedPermissionGroups = [...c.permissionGroups].sort(
			(a, b) => a.displayOrder - b.displayOrder
		);

		for (const pg of sortedPermissionGroups) {
			let pgk: PermissionGroupKeys = {
				name: pg.name,
				description: pg.description,
				Create: pg.permissions.find((p) => p.operationType === OperationType.Create)
					?.permissionKey,
				Update: pg.permissions.find((p) => p.operationType === OperationType.Update)
					?.permissionKey,
				Read: pg.permissions.find((p) => p.operationType === OperationType.Read)
					?.permissionKey,
				Delete: pg.permissions.find((p) => p.operationType === OperationType.Delete)
					?.permissionKey,
			};
			cat.permissions.push(pgk);
		}

		return cat;
	});
};

/**
 * IndeterminateCheckallBox - a check all box that can be indeterminate based on the options provided.
 *
 * @param {IndeterminateCheckallBoxProps} props
 * @returns {React.JSX.Element}
 */
export const IndeterminateCheckallBox = React.memo(
	(props: IndeterminateCheckallBoxProps): React.JSX.Element => (
		<Field name={props.name}>
			{({
				field: { name, value },
				form: { setFieldValue, isSubmitting },
			}: FieldProps<string[]>) => {
				// Calculate checkbox state.
				let state: boolean | undefined | null = null;
				// If there are no permissions in the group, set it to null (disabled).
				if (props.options.length === 0) {
					state = null;
					// If Every permission is selected, set to true (checked).
				} else if (props.options.every((p) => value.includes(p))) {
					state = true;
					// If some permissions are selected, set it to (undefined) indeterminate.
				} else if (props.options.some((p) => value.includes(p))) {
					state = undefined;
					// If no permissions are selected, set it to false (unchecked).
				} else {
					state = false;
				}

				return (
					<MTCheckbox
						color="primary"
						checked={state === true || state === undefined}
						disabled={state === null || isSubmitting || props?.disabled}
						indeterminate={state === undefined}
						onChange={(_, b) => {
							if (b) {
								setFieldValue(name, [...new Set(value.concat(props.options))]);
							} else {
								setFieldValue(
									name,
									value.filter((p) => !props.options.includes(p))
								);
							}
						}}
					/>
				);
			}}
		</Field>
	)
);

IndeterminateCheckallBox.displayName = 'IndeterminateCheckallBox';

type IndeterminateCheckallBoxProps = {
	options: string[];
	name: string;
	disabled?: boolean;
};

/**
 * Renders a Permission category and all of it's child permissions.
 *
 * @returns a React fragment of all the table rows for permissions.
 */
export const CategoryPermission = (props: CategoryPermissionProps) => {
	const [open, setOpen] = useState(true);

	// Using an iterator over map as I can then check the CRUD operation type
	let rows: React.JSX.Element[] = [];
	for (let i in props.category.permissions) {
		const p = props.category.permissions[i];
		rows.push(
			<TableRow
				key={i}
				sx={{
					// 'borderBottom': open ? '1px solid' : 'unset',
					// 'borderBottomColor': 'neutral.200',
					// Using a collapse inside a table row doesn't fully collapse each cell.
					// This is because each cell has some margin to it.
					'& >.MuiTableCell-root': {
						padding: 0,
						borderBottom: open ? 'neutral.200' : 'none',
					},
					'& >.MuiTableCell-root:nth-of-type(even)': {
						backgroundColor: 'neutral.50',
					},
				}}>
				<TableCell>
					<Collapse in={open}>
						<Box marginY={1} marginX={2}>
							{p.name}
							<Tooltip title={p.description} arrow placement="right">
								<IconButton>
									<InfoOutlined fontSize="small" />
								</IconButton>
							</Tooltip>
						</Box>
					</Collapse>
				</TableCell>
				<TableCell align="center">
					{p.Read !== undefined && (
						<Collapse in={open}>
							<Box marginY={1} marginX={2}>
								<CheckboxField
									color="primary"
									name={props.name}
									value={p.Read}
									disabled={props.disabled}
								/>
							</Box>
						</Collapse>
					)}
				</TableCell>
				<TableCell align="center">
					{p.Create !== undefined && (
						<Collapse in={open}>
							<Box marginY={1} marginX={2}>
								<CheckboxField
									color="primary"
									name={props.name}
									value={p.Create}
									disabled={props.disabled}
								/>
							</Box>
						</Collapse>
					)}
				</TableCell>
				<TableCell align="center">
					{p.Update !== undefined && (
						<Collapse in={open}>
							<Box marginY={1} marginX={2}>
								<CheckboxField
									color="primary"
									name={props.name}
									value={p.Update}
									disabled={props.disabled}
								/>
							</Box>
						</Collapse>
					)}
				</TableCell>
				<TableCell align="center">
					{p.Delete !== undefined && (
						<Collapse in={open}>
							<Box marginY={1} marginX={2}>
								<CheckboxField
									color="primary"
									name={props.name}
									value={p.Delete}
									disabled={props.disabled}
								/>
							</Box>
						</Collapse>
					)}
				</TableCell>
			</TableRow>
		);
	}
	return (
		<>
			<TableRow className="bg-neutral-50 text-neutral-900">
				<TableCell onClick={() => setOpen(!open)}>
					<Typography variant="h5" color="inherit">
						{props.category.name}
					</Typography>
					<Stack direction="row" alignItems="center">
						<Link>
							{open ? 'Hide' : 'Show'} {rows.length} permissions
						</Link>
						{open ? (
							<KeyboardArrowUpOutlined color="primary" />
						) : (
							<KeyboardArrowDownOutlined color="primary" />
						)}
					</Stack>
				</TableCell>
				<TableCell size="small" align="center">
					<div>View</div>
					<IndeterminateCheckallBox
						name={props.name}
						options={
							props.category.permissions
								.map((p) => p.Read)
								.filter((p) => p) as string[]
						}
						disabled={props.disabled}
					/>
				</TableCell>
				<TableCell align="center">
					<div>Create</div>
					<IndeterminateCheckallBox
						name={props.name}
						options={
							props.category.permissions
								.map((p) => p.Create)
								.filter((p) => p) as string[]
						}
						disabled={props.disabled}
					/>
				</TableCell>
				<TableCell align="center">
					<div>Update</div>
					<IndeterminateCheckallBox
						name={props.name}
						options={
							props.category.permissions
								.map((p) => p.Update)
								.filter((p) => p) as string[]
						}
						disabled={props.disabled}
					/>
				</TableCell>
				<TableCell align="center">
					<div>Delete</div>
					<IndeterminateCheckallBox
						name={props.name}
						options={
							props.category.permissions
								.map((p) => p.Delete)
								.filter((p) => p) as string[]
						}
						disabled={props.disabled}
					/>
				</TableCell>
			</TableRow>
			{rows}
		</>
	);
};

type CategoryPermissionProps = {
	category: CategoricalPermission;
	name: string;
	disabled?: boolean;
};

/**
 * Permissions - Builds a permissions table with it's children being the categories.
 *
 * @param {*} {children}
 * @returns
 */
export const PermissionsTable = React.memo((props: PermissionProps) => {
	const cats = MapPermissionCategories(props.permissions);
	const permissionCatItems: React.JSX.Element[] = cats.map((c, i) => {
		return (
			<CategoryPermission key={i} name={props.name} category={c} disabled={props.disabled} />
		);
	});

	return (
		<TableContainer sx={{ maxHeight: '100%' }}>
			<Table size="small">
				<TableBody>{permissionCatItems}</TableBody>
			</Table>
		</TableContainer>
	);
});

PermissionsTable.displayName = 'PermissionsTable';

type PermissionProps = {
	permissions: PermissionCategory[];
	name: string;
	disabled?: boolean;
};

export const PermissionRowsSkeleton = (): React.JSX.Element => (
	<TableContainer sx={{ height: '100%' }}>
		<Table>
			<TableBody>
				{
					// 10 is an estimate of how many Skeletons are needed to fill the screen
					Array(10)
						.fill(null)
						.map((_, i) => {
							return (
								<TableRow key={i}>
									<TableCell>
										<Skeleton animation="wave" height={'2rem'} />
									</TableCell>
								</TableRow>
							);
						})
				}
			</TableBody>
		</Table>
	</TableContainer>
);
