import {
	CloseOutlined,
	ErrorOutlined,
	LockOutlined,
	MoreHorizOutlined,
	NavigateNextOutlined,
	PublicOutlined,
} from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	useModal,
} from 'components/ui/modal';
import { useToast } from 'components/ui/toast';
import { Emblem } from 'middleware-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';
import { ConfirmChangeOrgVisibilityModal } from './confirmChangeOrgVisibilityModal';
import { DrawerOrDialog } from './drawerOrDialog';
import { useDeleteRelationship } from './hooks';

/**
 * The Row Action.
 *
 * @enum {number}
 */
enum RowAction {
	RemoveChild,
	MakePrivate,
	MakePublic,
	GoToChild,
}

/**
 * The org status.
 *
 * @enum {number}
 */
enum Status {
	Active = 'Active',
	Inactive = 'Inactive',
}

export enum Visibility {
	Public = 'Public',
	Private = 'Private',
}

/**
 * Renders the context menu for child organizations.
 * @param props
 * @returns
 */
export const OrgHierarchyContextMenu = ({
	childEmblem,
	parentId,
}: {
	childEmblem: Emblem;
	parentId: string;
}): React.JSX.Element => {
	const { user } = useSession();
	const isSiteUser = !!user.siteUserId;
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(parentId);
	const hasPermission = isSiteUser ? hasSiteUserPermission : hasAssociateUserPermission;
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void =>
		setAnchorEl(event.currentTarget);
	const { showModal } = useModal();
	const { removeChild } = useDeleteRelationship(parentId);
	const [confirmMakeChildOrgPublicModalOpen, setConfirmSetChildOrgPublicModalOpen] =
		useState(false);
	const [confirmMakeChildOrgPrivateModalOpen, setConfirmSetChildOrgPrivateModalOpen] =
		useState(false);
	const navigate = useNavigate();
	const toast = useToast();
	const { hasPermission: hasChildOrgAssociateUserPermission } = useAssociateUser(childEmblem.id);

	const onAction = (action: RowAction): void => {
		switch (action) {
			case RowAction.RemoveChild: {
				showModal({
					title: 'Delete Invitation',
					content: (
						<ConfirmModalContent
							visual={
								<EmptyStateAvatar
									avatarProps={{ bgcolor: 'error.50' }}
									iconProps={{ color: 'error.500' }}
									icon={<ErrorOutlined />}
								/>
							}
							subheadline="Are you sure?"
							informativeContent={`Do you really want to remove ${childEmblem.displayName} as a child?`}
						/>
					),
					actions: (
						<ModalActions>
							<ModalActionButton size="large" variant="outlined">
								Cancel
							</ModalActionButton>
							<ModalActionButton
								size="large"
								variant="contained"
								color="error"
								onClick={() => {
									if (!childEmblem?.id) return;
									removeChild(childEmblem.id);
								}}>
								Release Child
							</ModalActionButton>
						</ModalActions>
					),
				});
				break;
			}
			case RowAction.MakePublic:
			case RowAction.MakePrivate: {
				action === RowAction.MakePublic
					? setConfirmSetChildOrgPublicModalOpen(true)
					: setConfirmSetChildOrgPrivateModalOpen(true);
				break;
			}
			case RowAction.GoToChild: {
				if (
					hasChildOrgAssociateUserPermission(Permission.Org_Account_R) ||
					hasSiteUserPermission(Permission.Site_OrgAcct_R)
				)
					return navigate(`/orgs/${childEmblem.handle}/account/`);

				if (
					hasChildOrgAssociateUserPermission(Permission.Org_Member) ||
					hasSiteUserPermission(Permission.Site_OrgDashboard_R)
				)
					return navigate(`/orgs/${childEmblem.handle}/dashboard/`);

				if (
					!childEmblem.private &&
					(!isSiteUser || hasSiteUserPermission(Permission.Site_OrgProfile_R))
				)
					return navigate(`/orgs/${childEmblem.handle}/profile/`);

				return toast.push(
					`You do not have permission to view this organization. Contact an organization admin for assistance.`,
					{
						variant: 'error',
					}
				);
			}
		}
	};

	const handleRowAction = (action: RowAction): void => {
		setAnchorEl(null);
		if (onAction) {
			onAction(action);
		}
	};

	/**
	 * The actions possible for a site user at the hierarchy context menu.
	 *  @type {*} */
	const siteUserTableActions = [
		{
			status: [Status.Active, Status.Inactive],
			visibility: [Visibility.Private, Visibility.Public],
			permissions: [Permission.Site_OrgHierarchy_U],
			action: RowAction.RemoveChild,
			text: 'Release Child',
			icon: <CloseOutlined />,
			disabled: false,
		},
		{
			status: [Status.Active],
			visibility: [Visibility.Public],
			disabled: false,
			permissions: [Permission.Site_OrgAcct_U],
			action: RowAction.MakePrivate,
			text: 'Make Private',
			icon: <LockOutlined />,
		},
		{
			status: [Status.Active],
			visibility: [Visibility.Private],
			disabled: false,
			permissions: [Permission.Site_OrgAcct_U],
			action: RowAction.MakePublic,
			text: 'Make Public',
			icon: <PublicOutlined />,
		},
		{
			status: [Status.Active, Status.Inactive],
			visibility: [Visibility.Public, Visibility.Private],
			permissions: [
				Permission.Site_OrgAcct_R,
				Permission.Site_OrgDashboard_R,
				Permission.Site_OrgProfile_R,
			],
			disabled: false,
			action: RowAction.GoToChild,
			text: 'Go to Child',
			icon: <NavigateNextOutlined />,
		},
	];

	/**
	 * The actions possible for an org associate at the hierarchy context menu.
	 *  @type {*} */
	const associateTableActions = [
		{
			status: [Status.Active, Status.Inactive],
			visibility: [Visibility.Public, Visibility.Private],
			permissions: [],
			disabled: false,
			action: RowAction.GoToChild,
			text: 'Go to Child',
			icon: <NavigateNextOutlined />,
		},
	];

	const tableActions = isSiteUser ? siteUserTableActions : associateTableActions;

	let menu = tableActions
		.filter((ta) => {
			return (
				hasPermission(ta.permissions ?? [], { all: false }) &&
				ta.status &&
				ta.status.includes(childEmblem.deactivated ? Status.Inactive : Status.Active) &&
				ta.visibility &&
				ta.visibility.includes(childEmblem.private ? Visibility.Private : Visibility.Public)
			);
		})
		.map((ta, k) => {
			return (
				<MenuItem
					key={k}
					onClick={
						ta.action !== undefined ? (): void => handleRowAction(ta.action) : undefined
					}
					disabled={ta.disabled}>
					<ListItemIcon>{ta.icon}</ListItemIcon>
					<ListItemText>{ta.text}</ListItemText>
				</MenuItem>
			);
		});

	return (
		<>
			{menu.length > 0 && (
				<IconButton
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					onClick={handleClick}>
					<MoreHorizOutlined />
				</IconButton>
			)}
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={(): void => setAnchorEl(null)}>
				{menu}
			</Menu>
			{childEmblem.id && (
				<DrawerOrDialog open={confirmMakeChildOrgPublicModalOpen}>
					<ConfirmChangeOrgVisibilityModal
						organizationId={childEmblem.id}
						onClose={() => setConfirmSetChildOrgPublicModalOpen(false)}
						displayName={childEmblem.displayName}
						visibilityToSet={Visibility.Public}
					/>
				</DrawerOrDialog>
			)}
			{childEmblem.id && (
				<DrawerOrDialog open={confirmMakeChildOrgPrivateModalOpen}>
					<ConfirmChangeOrgVisibilityModal
						organizationId={childEmblem.id}
						onClose={() => setConfirmSetChildOrgPrivateModalOpen(false)}
						displayName={childEmblem.displayName}
						visibilityToSet={Visibility.Private}
					/>
				</DrawerOrDialog>
			)}
		</>
	);
};
