import { NotificationSummariesPage } from 'components/pages/notification-summaries/notification-summaries-page';
import { PageTitle } from 'components/ui/page';

export const AssociateNotifications = () => {
	return (
		<>
			<PageTitle title="Organization Announcements" />
			<NotificationSummariesPage type="organization" />
		</>
	);
};
