import { useQuery } from '@apollo/client';
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import gql from 'graphql-tag';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PageContent, PageTitle } from '../../components/ui/page';
import { HistoryEntry } from './HistoryEntry';

/**
 * History type.
 */
export type QHistory = {
	entityId: string;
	entityName: string;
	entityCurrentValue: string | undefined | null;
	parent:
		| {
				entityId: string;
				entityName: string | undefined | null;
				entityCurrentValue: string | undefined | null;
		  }
		| undefined
		| null;
	historyLogs: {
		historyLogId: string;
		changeUtc: Date;
		siteUserName: string | undefined | null;
		userName: string | undefined | null;
		isAssumedIdentity: boolean;
		changes: {
			entityId: string | undefined | null;
			changeType:
				| 'NoChange'
				| 'EntityAdded'
				| 'EntityModified'
				| 'EntityDeleted'
				| 'SubEntityChanged';
			fieldName: string;
			oldValue: string | undefined | null;
			newValue: string | undefined | null;
		}[];
	}[];
};

/**
 * Query to get the history log of the entityId provided.
 */
export const HISTORY = gql`
	query history($entityId: String!, $historyLogId: String) {
		entity(entityId: $entityId, historyLogId: $historyLogId) {
			entityId
			entityName
			entityCurrentValue
			parent {
				entityId
				entityName
				entityCurrentValue
			}
			historyLogs {
				historyLogId
				changeUtc
				siteUserName
				userName
				isAssumedIdentity
				changes {
					entityId
					changeType
					fieldName
					oldValue
					newValue
				}
			}
		}
	}
`;

const useHistoryLog = (
	entityId,
	historyLogId
): {
	loading: boolean;
	rows: HistoryRowProps[];
	error: any;
	date: string;
	title: string;
} => {
	const { loading, data, error } = useQuery(HISTORY, {
		fetchPolicy: 'cache-and-network',
		variables: { entityId: entityId, historyLogId: historyLogId },
	});

	let rows: HistoryRowProps[] = [];
	let date = '';
	let title = '';

	if (data && !loading) {
		const history = data.entity as QHistory;

		if (!history || error) {
			return {
				loading,
				rows,
				error,
				date,
				title,
			};
		}

		const logs = [...history.historyLogs]
			.sort((a, b) => +new Date(a.changeUtc) - +new Date(b.changeUtc))
			.reverse();
		const currentValue = history?.entityCurrentValue ?? history?.parent?.entityCurrentValue;
		title = `${history?.entityName ?? history?.parent?.entityName} ${
			currentValue ? `- ${currentValue}` : ''
		}`;

		if (historyLogId) {
			date = format(zonedTimeToUtc(logs[0].changeUtc, 'UTC'), 'MM/dd/yyyy h:mm aaa');
		}

		rows = logs
			.map((entry) =>
				entry.changes.map((row, i) => ({
					isImpersonated: entry.isAssumedIdentity,
					siteUserName: entry?.siteUserName,
					userName: entry?.userName,
					changeUtc: entry.changeUtc,
					changeType: entry.changes[i].changeType,
					fieldName: entry.changes[i].fieldName,
					oldValue: entry.changes[i].oldValue,
					newValue: entry.changes[i].newValue,
					entityName: history.entityName,
					entityCurrentValue: history.entityCurrentValue,
					parentValue: history.parent?.entityCurrentValue,
					historyLogId: entry.historyLogId,
					entityId: history.entityId,
					changeEntityId: entry.changes[i].entityId,
				}))
			)
			.flat();
	}

	return {
		loading,
		rows,
		error,
		date,
		title,
	};
};

/**
 * The History Row props.
 */
type HistoryRowProps = {
	changeUtc: any;
	siteUserName: string | undefined | null;
	userName: string | undefined | null;
	isImpersonated: boolean;
	historyLogId: string;
	changeType:
		| 'NoChange'
		| 'EntityAdded'
		| 'EntityModified'
		| 'EntityDeleted'
		| 'SubEntityChanged';
	fieldName: string;
	oldValue: string | undefined | null;
	newValue: string | undefined | null;
	entityName: string;
	parentValue: string | undefined | null;
	entityCurrentValue: string | undefined | null;
	entityId: string;
	changeEntityId: string | undefined | null;
};

/**
 * Renders a History Row.
 *
 * @param {HistoryRowProps} props
 * @return {*}  {React.JSX.Element}
 */
const HistoryRow = (props: HistoryRowProps): React.JSX.Element => {
	const navigate = useNavigate();
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	let date = format(utcToZonedTime(props.changeUtc, timeZone), 'MM/dd/yyyy h:mm aaa');
	let subentityRow = props.changeType === 'SubEntityChanged';

	const newHistoryUrl =
		'/app/history/' +
		props.changeEntityId +
		(props.historyLogId ? '/' + props.historyLogId : '');

	return (
		<TableRow
			onClick={subentityRow ? () => navigate(newHistoryUrl) : undefined}
			className={subentityRow ? 'hover:bg-navy-50 cursor-pointer no-underline' : ''}>
			<TableCell size="small"></TableCell>
			<TableCell className="align-top">{date}</TableCell>
			<HistoryEntry {...props} />
		</TableRow>
	);
};

/**
 * Renders the History Table.
 *
 * @param {*} props
 * @return {*}
 */
export const History = () => {
	const navigate = useNavigate();
	const { entityId, historyLogId } = useParams();
	const { rows, loading, error, date, title } = useHistoryLog(entityId, historyLogId);

	const viewAll = '/app/history/' + entityId;

	return (
		<>
			<PageTitle title="History" />
			<PageContent>
				<Card className="h-screen">
					<CardHeader
						title={'History - ' + (historyLogId ? date : 'All')}
						action={
							<>
								{historyLogId && (
									<Button size="medium">
										<Link to={viewAll}>View All</Link>
									</Button>
								)}
								<Button
									size="medium"
									variant="outlined"
									onClick={() => navigate(-1)}>
									Back
								</Button>
							</>
						}></CardHeader>
					<CardContent className="h-full">
						<TableContainer style={{ maxHeight: 'calc(100% - 60px)' }}>
							<Table stickyHeader aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell
											colSpan={3}
											align="center"
											height="57px"
											width={'100%'}>
											{title}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell
											size="small"
											style={{ width: '4%', top: '57px' }}></TableCell>
										<TableCell style={{ width: '11%', top: '57px' }}>
											Date
										</TableCell>
										<TableCell style={{ width: '85%', top: '57px' }}>
											Change
										</TableCell>
									</TableRow>
								</TableHead>
								{!error && rows ? (
									loading ? (
										<SkeletonRows rows={5} />
									) : (
										<TableBody>
											{rows.map((row, i) => (
												<HistoryRow {...row} key={i} />
											))}
										</TableBody>
									)
								) : (
									<TableBody>
										<TableRow>
											<TableCell colSpan={3} align="center">
												No history found
											</TableCell>
										</TableRow>
									</TableBody>
								)}
							</Table>
						</TableContainer>
					</CardContent>
				</Card>
			</PageContent>
		</>
	);
};

export default History;

/**
 * Skeleton row props.
 */
type SkeletonProps = {
	rows: number;
};

// Will generate however many placeholders depending on page size
// Actual number of rows may be fewer
const SkeletonRows = (props: SkeletonProps): React.JSX.Element => (
	<TableBody>
		{Array(props.rows)
			.fill(null)
			.map((_, i) => {
				return (
					<TableRow key={i}>
						<TableCell colSpan={8}>
							<Skeleton animation="wave" height={'2em'} />
						</TableCell>
					</TableRow>
				);
			})}
	</TableBody>
);
