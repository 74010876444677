import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { EmblemEntityType } from 'middleware-types';
import { useRedirectToOrgConversation } from './hooks';

export interface OrgMessageButtonProps {
	entityId: string;
	entityType: EmblemEntityType;
	orgId: string;
	orgHandle: string;
}

// TODO - If using OrgMessageButton outside associates table, add a check to ensure the org can message the entity
// Refer to UserMessageButton for an example

export const OrgMessageButton = (props: OrgMessageButtonProps) => {
	const { redirectToOrgConversation, loading } = useRedirectToOrgConversation(props);
	return (
		<Box>
			<LoadingButton variant="outlined" loading={loading} onClick={redirectToOrgConversation}>
				Organization Message
			</LoadingButton>
		</Box>
	);
};
