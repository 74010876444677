import { BusinessOutlined, ChevronRight, Close } from '@mui/icons-material';
import {
	Avatar,
	Button,
	Dialog,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { useToast } from 'components/ui/toast';
import { LegacyUserAdjustmentCompanyWithMetrics } from 'middleware-types';
import { useState } from 'react';
import { postLegacyAuth } from 'utils/session';
import { useIsMobile } from 'utils/useScreenSize';
import { ChatResponseBase, ChatResponseProps } from './chatResponse';
import { FeedbackButtons } from './feedbackButtons';
import { useGetAuthorizedLegacyUsersForClaimByCognitoId } from './hooks';

interface ClaimGridResponseProps extends ChatResponseProps {
	claims: Claim[];
}

export const ClaimGridResponse = ({
	claims,
	message,
	selected,
	readOnly = false,
}: ClaimGridResponseProps) => {
	if (!claims) return <></>;

	return (
		<ChatResponseBase selected={selected} showBubble={message.showBubble}>
			<List component={Stack} spacing={1} overflow="auto" sx={{ overflowX: 'hidden' }}>
				{claims.map((claim: Claim, idx: number) => (
					<ClaimListItem claim={claim} key={idx} />
				))}
			</List>
			<Stack direction="row">
				<FeedbackButtons message={message} readOnly={readOnly} />
			</Stack>
		</ChatResponseBase>
	);
};

export interface Claim {
	ClaimNumber?: string;
	ClientCompany?: string;
	Insured?: string;
	LossCity?: string;
	LossState?: string;
	ClaimKey?: string;
}

const ClaimListItem = ({ claim }: { claim: Claim }) => {
	const [showUserPickerModal, setShowUserPickerModal] = useState(false);
	const isMobile = useIsMobile();
	const parts = claim?.ClaimKey?.split('-');
	const systemId = parts ? parts[0] : undefined;
	const acid = parts ? parts[1] : undefined;
	const claimId = parts ? parts[2] : undefined;

	return (
		<>
			<ListItem disablePadding>
				<ListItemButton className="p-0" onClick={() => setShowUserPickerModal(true)}>
					<Stack
						sx={{
							backgroundColor: 'white',
							borderRadius: 1,
							border: '1px solid',
							borderColor: 'neutral.200',
						}}
						width="100%"
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center">
						<Stack p={1} overflow="hidden">
							<Typography variant="h5">Claim #{claim.ClaimNumber}</Typography>
							<Typography variant="body1" sx={{ color: 'neutral.500' }} noWrap>
								{claim.Insured} • {claim.LossCity}, {claim.LossState}
							</Typography>
							<Typography variant="body1" sx={{ color: 'neutral.500' }}>
								{claim.ClientCompany}
							</Typography>
						</Stack>
						<ChevronRight sx={{ color: 'neutral.500' }} />
					</Stack>
				</ListItemButton>
			</ListItem>
			{!systemId || !acid || !claimId ? (
				// Pop a toast here?
				<></>
			) : isMobile ? (
				<Drawer open={showUserPickerModal} anchor="bottom" sx={{ zIndex: 10000 }}>
					<LegacyUserSelectionModal
						onClose={() => setShowUserPickerModal(false)}
						systemId={systemId}
						acid={acid}
						claimId={claimId}
					/>
				</Drawer>
			) : (
				<Dialog open={showUserPickerModal} fullWidth maxWidth="sm">
					<LegacyUserSelectionModal
						onClose={() => setShowUserPickerModal(false)}
						systemId={systemId}
						acid={acid}
						claimId={claimId}
					/>
				</Dialog>
			)}
		</>
	);
};

interface MakeCurrentInstanceModalProps {
	onClose: () => void;
	systemId: string;
	acid: string;
	claimId: string;
}

const LegacyUserSelectionModal = ({
	onClose,
	systemId,
	acid,
	claimId,
}: MakeCurrentInstanceModalProps) => {
	const toast = useToast();
	const { users, loading } = useGetAuthorizedLegacyUsersForClaimByCognitoId(
		parseInt(systemId),
		parseInt(acid)
	);

	const onSubmit = (userId: number, claimId: string, systemUrl?: string) => {
		if (!systemUrl) {
			toast.push(`An error occurred. Please try again later or contact Support.`, {
				variant: 'error',
			});
		}
		postLegacyAuth(
			systemUrl,
			userId,
			`claimList.asp?allBranches=1&expand=${claimId}&searchType=claimID&searchTgt=${claimId}`
		);
	};

	return (
		<Stack>
			<Stack
				px={2}
				py={1}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				spacing={1}>
				<Typography variant="h3">
					Which user would you like to view the claim as?
				</Typography>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</Stack>
			<Divider />
			<List component={Stack} disablePadding height={{ xs: '100vh', md: 350 }}>
				{loading && (
					<Stack display="flex" alignItems="center">
						<Skeleton height="2.5rem" width="95%" variant="text" />
					</Stack>
				)}
				{users.map((user: LegacyUserAdjustmentCompanyWithMetrics, idx) => (
					<ListItem
						key={idx}
						disablePadding
						onClick={() => onSubmit(user.userId, claimId, user.systemUrl)}>
						<ListItemButton>
							<ListItemAvatar>
								<Avatar
									variant="square"
									sx={{ '& img': { objectFit: 'contain' } }}
									{...(user.adjCoLogo && {
										src: `${user.systemUrl}/../images/logos/${user.adjCoLogo}`,
									})}>
									<BusinessOutlined sx={{ width: '75%', height: '75%' }} />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary={user.userFirstName + ' ' + user.userLastName} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<Divider />
			<Stack direction="row" justifyContent="flex-end" px={2} py={1.5} spacing={1.5}>
				<Button size="large" variant="outlined" onClick={onClose}>
					Cancel
				</Button>
			</Stack>
		</Stack>
	);
};
