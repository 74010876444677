import {
	AccountCircle,
	ErrorOutlined,
	LockOutlined,
	MoreHorizRounded,
	PowerSettingsNewOutlined,
	PublicOutlined,
} from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { DeactivateIcon } from 'components/ui/icons';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	useModal,
} from 'components/ui/modal';
import { ConfirmChangeOrgVisibilityModal } from 'pages/orgs/[handle]/account/hierarchy/confirmChangeOrgVisibilityModal';
import { DrawerOrDialog } from 'pages/orgs/[handle]/account/hierarchy/drawerOrDialog';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Permission } from 'utils/permissions';
import { useIsMobile } from 'utils/useScreenSize';
import { useSiteUser } from 'utils/useSiteUser';
import { useUpdateOrganizationActivationMutation } from '../org/account/basic-info-hooks';
import { useSetOrgPrivate } from '../org/account/context-menu-hooks';
import { Status } from 'pages/site/system-search/system-search';
import { ActivationOperation } from '../user/account/personal/personal-info-hooks';

/**
 * Props for the context menu.
 *
 * @interface OrgContextMenuProps
 */
interface OrgContextMenuProps {
	id: string | undefined | null;
	refetch: () => void;
	status: Status;
	handle: string | undefined | null;
	displayName: string | undefined;
	visibility: Visibility;
}

// This doesn't need as many keys
// but removing some causes TypeScript issues
/**
 * The org status.
 *
 * @enum {number}
 */

/**
 * The Row Action.
 *
 * @enum {number}
 */
enum RowAction {
	ActivateOrganization,
	DeactivateOrganization,
	MakePrivate,
	MakePublic,
}

export enum Visibility {
	Public = 'Public',
	Private = 'Private',
}

/**
 * Renders the context menu for site user rows.
 * @param props
 * @returns
 */
export const OrgContextMenu = (props: OrgContextMenuProps): React.JSX.Element => {
	const { hasPermission } = useSiteUser();
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void =>
		setAnchorEl(event.currentTarget);
	const { showModal } = useModal();
	const isMobile = useIsMobile();
	const [confirmMakeOrgPublicModalOpen, setConfirmSetOrgPublicModalOpen] = useState(false);
	const [confirmMakeOrgPrivateModalOpen, setConfirmSetOrgPrivateModalOpen] = useState(false);

	const { updateOrganizationActivation } = useUpdateOrganizationActivationMutation(props.id!);
	const { setOrgPrivate } = useSetOrgPrivate(props.id!);

	const onAction = (action: RowAction): void => {
		switch (action) {
			case RowAction.ActivateOrganization:
			case RowAction.DeactivateOrganization: {
				const verb = action === RowAction.ActivateOrganization ? 'Activate' : 'Deactivate';
				const inverseVerb =
					action === RowAction.ActivateOrganization ? 'Deactivate' : 'Reactivate';
				const btnColor = action === RowAction.ActivateOrganization ? 'primary' : 'error';
				const modalVisual =
					verb === 'Activate' ? (
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'primary.50' }}
							iconProps={{ color: 'primary.500' }}
							icon={<PowerSettingsNewOutlined />}
						/>
					) : (
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'error.50' }}
							iconProps={{ color: 'error.500' }}
							icon={<ErrorOutlined />}
						/>
					);
				showModal({
					title: `${verb} Organization`,
					content: (
						<ConfirmModalContent
							visual={modalVisual}
							subheadline="Are you sure?"
							informativeContent={`Do you really want to ${verb.toLowerCase()}
							${props.displayName}? You can ${inverseVerb.toLowerCase()} this organization anytime.`}
						/>
					),
					actions: (
						<ModalActions>
							<ModalActionButton size="large" variant="outlined">
								Cancel
							</ModalActionButton>
							<ModalActionButton
								size="large"
								variant="contained"
								color={btnColor}
								onClick={() => {
									if (!props.id) return;
									return updateOrganizationActivation(
										action === RowAction.ActivateOrganization
											? ActivationOperation.Activate
											: ActivationOperation.Deactivate
									);
								}}>
								{`${verb} Organization`}
							</ModalActionButton>
						</ModalActions>
					),
				});
				break;
			}
			case RowAction.MakePublic:
			case RowAction.MakePrivate: {
				action === RowAction.MakePublic
					? setConfirmSetOrgPublicModalOpen(true)
					: setConfirmSetOrgPrivateModalOpen(true);
				break;
			}
		}
	};

	/**
	 * The actions possible for a context menu.
	 *  @type {*} */
	const tableActions = [
		{
			status: ['Active'],
			permissions: [Permission.Site_OrgProfile_R],
			link: `../../orgs/${props.handle}`,
			text: 'View Profile',
			icon: <AccountCircle />,
			visibility: [Visibility.Public, Visibility.Private],
		},
		{
			status: ['Active'],
			permissions: [Permission.Site_OrgAcct_R],
			link: `../../orgs/${props.handle}/account`,
			text: 'View Account',
			icon: <AccountCircle />,
			visibility: [Visibility.Public, Visibility.Private],
		},
		{
			status: ['Active'],
			permissions: [Permission.Site_OrgAcct_U],
			action: RowAction.DeactivateOrganization,
			text: 'Deactivate Organization',
			icon: <DeactivateIcon />,
			visibility: [Visibility.Public, Visibility.Private],
		},
		{
			status: ['Inactive'],
			permissions: [Permission.Site_OrgAcct_U],
			action: RowAction.ActivateOrganization,
			text: 'Activate Organization',
			icon: <PowerSettingsNewOutlined />,
			visibility: [Visibility.Public, Visibility.Private],
		},
		{
			status: ['Active'],
			permissions: [Permission.Site_OrgAcct_U],
			action: RowAction.MakePrivate,
			text: 'Make Private',
			icon: <LockOutlined />,
			visibility: [Visibility.Public],
		},
		{
			status: ['Active'],
			permissions: [Permission.Site_OrgAcct_U],
			action: RowAction.MakePublic,
			text: 'Make Public',
			icon: <PublicOutlined />,
			visibility: [Visibility.Private],
		},
	];

	const handleRowAction = (action: RowAction): void => {
		setAnchorEl(null);
		if (onAction) {
			onAction(action);
		}
	};

	let menu = tableActions
		.filter(
			(ta) =>
				hasPermission(ta.permissions ?? []) &&
				props.status &&
				ta.status.includes(props.status) &&
				props.visibility &&
				ta.visibility.includes(props.visibility)
		)
		.map((ta, k) => {
			if (ta.link) {
				return (
					<MenuItem key={k} component={Link} to={ta.link}>
						<ListItemIcon>{ta.icon}</ListItemIcon>
						<ListItemText>{ta.text}</ListItemText>
					</MenuItem>
				);
			}

			return (
				<MenuItem
					key={k}
					onClick={
						ta.action !== undefined ? (): void => handleRowAction(ta.action) : undefined
					}>
					<ListItemIcon>{ta.icon}</ListItemIcon>
					<ListItemText>{ta.text}</ListItemText>
				</MenuItem>
			);
		});

	return (
		<>
			{menu.length > 0 && (
				<IconButton
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					onClick={handleClick}>
					<MoreHorizRounded />
				</IconButton>
			)}
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={(): void => setAnchorEl(null)}>
				{menu}
			</Menu>
			{props.id && (
				<DrawerOrDialog open={confirmMakeOrgPublicModalOpen}>
					<ConfirmChangeOrgVisibilityModal
						organizationId={props.id}
						onClose={() => setConfirmSetOrgPublicModalOpen(false)}
						displayName={props.displayName}
						visibilityToSet={Visibility.Public}
					/>
				</DrawerOrDialog>
			)}
			{props.id && (
				<DrawerOrDialog open={confirmMakeOrgPrivateModalOpen}>
					<ConfirmChangeOrgVisibilityModal
						organizationId={props.id}
						onClose={() => setConfirmSetOrgPrivateModalOpen(false)}
						displayName={props.displayName}
						visibilityToSet={Visibility.Private}
					/>
				</DrawerOrDialog>
			)}
		</>
	);
};
