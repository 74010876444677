import { gql, useLazyQuery } from '@apollo/client';
import { Address, AddressInput, Query, QueryGeocodeArgs } from 'middleware-types';
import { client } from 'utils/apollo';

const GEOCODE = gql`
	query geocode($address: AddressInput!) {
		geocode(address: $address) {
			location {
				latitude
				longitude
			}
			viewport {
				northeast {
					latitude
					longitude
				}
				southwest {
					latitude
					longitude
				}
			}
		}
	}
`;

/**
 * Hook for lazy geocoding an address
 *
 * @return {*}
 */
export const useGeocoder = () => {
	const [geocodeQuery, { data, loading }] = useLazyQuery<
		Pick<Query, 'geocode'>,
		QueryGeocodeArgs
	>(GEOCODE);

	const geocode = (address: Address) => {
		return geocodeQuery({ variables: { address } });
	};

	return { geocode, coordinates: data?.geocode.location, loading };
};

/**
 * geocode(client, address) - this geocode endpoint does a direct query call to fetch a lat/log for
 * a given address and apollo client.
 *
 * @param {ApolloClient<any>} client
 * @param {Address} address
 * @return {*}
 */
export const geocode = async (address: AddressInput) => {
	// Run geolocate query on primary address in background before registering
	const res = await client.query({
		query: GEOCODE,
		variables: { address: address },
	});

	return res.data?.geocode?.location;
};

/**
 * Hook for lazy geocoding an address
 *
 * @return {*}
 */
export const useGeocoderLazy = () => {
	const [geocodeQuery, { data, loading }] = useLazyQuery<
		Pick<Query, 'geocode'>,
		QueryGeocodeArgs
	>(GEOCODE);

	const geocode = (address: AddressInput) => {
		return geocodeQuery({ variables: { address } });
	};

	return { geocode, coordinates: data?.geocode.location, loading };
};
