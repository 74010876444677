import { LoadingButton } from '@mui/lab';
import { ConfirmModalContent, ModalActions, useModal } from 'components/ui/modal';
import { ConnectionState } from 'middleware-types';
import { ConnectionButton } from '../../shared/ConnectionButton';
import { ComprehensiveConnectionState, ConnectionStateExtended } from '../../shared/types';
import {
	useAcceptUserConnection,
	useCancelUserConnectionRequest,
	useIgnoreUserConnection,
	useRemoveUserConnection,
	useUserConnectionRequest,
} from './hooks';
import { ErrorOutlined } from '@mui/icons-material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';

interface Props {
	user: UserConnectionInfo;
}

//types
export interface UserConnectionInfo {
	connectionId?: string | undefined;
	connectionState?: ComprehensiveConnectionState;
	entityId: string;
	entityName: string;
}

export const UserConnectionButton = ({ user }: Props) => {
	switch (user.connectionState) {
		case ConnectionState.Connected:
			return <RemoveConnectionButton user={user} />;
		case ConnectionState.Pending:
			return <CancelRequestButton user={user} />;
		case ConnectionStateExtended.IncomingRequest:
			return <AcceptDeclineButtons user={user} />;
		case ConnectionState.Ignored:
		case ConnectionStateExtended.NotConnected:
			return <RequestButton user={user} />;
		default:
			return <></>;
	}
};

const RequestButton = ({ user }: Props) => {
	const { requestConnection, loading } = useUserConnectionRequest();
	return (
		<ConnectionButton
			connectionState={ConnectionStateExtended.NotConnected}
			onClick={() => requestConnection(user.entityId)}
			loading={loading}
			color="primary"
		/>
	);
};

const CancelRequestButton = ({ user }: Props) => {
	const { cancelConnectionRequest, loading } = useCancelUserConnectionRequest();
	return (
		<ConnectionButton
			connectionState={ConnectionState.Pending}
			onClick={() => cancelConnectionRequest(user.connectionId!)}
			loading={loading}
			color="primary"
		/>
	);
};

const AcceptDeclineButtons = ({ user }: Props) => {
	const { acceptConnection, loading: acceptLoading } = useAcceptUserConnection();
	const { ignoreConnection, loading: ignoreLoading } = useIgnoreUserConnection();
	const loading = acceptLoading || ignoreLoading;

	return (
		<ConnectionButton
			connectionState={ConnectionStateExtended.IncomingRequest}
			onClick={() => acceptConnection(user.connectionId!)}
			onClick2={() => ignoreConnection(user.connectionId!)}
			loading={loading}
			color="primary"
		/>
	);
};

const RemoveConnectionButton = ({ user }: Props) => {
	const { showModal } = useModal();
	return (
		<ConnectionButton
			connectionState={ConnectionState.Connected}
			onClick={() =>
				showModal({
					title: 'Remove Connection',
					content: <DisconnectModal user={user} />,
				})
			}
			color="primary"
		/>
	);
};

const DisconnectModal = ({ user }: Props) => {
	const { closeModal } = useModal();
	const { removeConnection, loading } = useRemoveUserConnection();
	const onClick = () => {
		removeConnection(user.connectionId!).then((success) => {
			if (success) closeModal();
		});
	};

	return (
		<>
			<ConfirmModalContent
				visual={
					<EmptyStateAvatar
						avatarProps={{ bgcolor: 'error.50' }}
						iconProps={{ color: 'error.500' }}
						icon={<ErrorOutlined />}
					/>
				}
				subheadline={`Disconnect with ${user.entityName}?`}
				informativeContent={`Do you really want to remove your connection with ${user.entityName}? This process cannot be undone.`}
			/>
			<ModalActions justifyContent="flex-end">
				<LoadingButton
					size="large"
					variant="outlined"
					loading={loading}
					onClick={closeModal}>
					Cancel
				</LoadingButton>
				<LoadingButton
					size="large"
					variant="contained"
					color="error"
					loading={loading}
					onClick={onClick}>
					Disconnect
				</LoadingButton>
			</ModalActions>
		</>
	);
};
