import { useEffect, useState } from 'react';
import { uploadFile, useGetUploadUrl } from 'utils/fileUtils';
import {
	FormMessageAttachment,
	NewMessageFormValues,
} from '../conversation-column/conversation-message-box';
import { useFormikContext } from 'formik';
import { useToast } from 'components/ui/toast';

export const useMessageAttachments = () => {
	const toast = useToast();
	const { setFieldValue } = useFormikContext<NewMessageFormValues>();
	const [attachments, setAttachments] = useState<FormMessageAttachment[]>([]);
	const [getUploadUrl] = useGetUploadUrl();

	// formik does not allow us to set a field value based on the previous value.
	// because the file uploading is async, we need to do this.
	// instead, we mantain a local copy of the files, and update formik with a useEffect.
	useEffect(() => {
		setFieldValue('messageAttachments', attachments);
	}, [attachments]);

	const onDropAccepted = (files: File[]) => {
		files.forEach(async (file) => {
			const result = await getUploadUrl({
				fetchPolicy: 'network-only',
				variables: { fileName: file.name, fileSize: file.size },
				onError: (e) =>
					toast.push(
						(e.graphQLErrors[0].extensions.userMessage as any) ??
							'An error occurred while trying to upload the file.',
						{
							variant: 'error',
						}
					),
			});
			const uploadUrl = result.data?.getUploadUrl;
			if (!uploadUrl) return;
			setAttachments((prev) => [
				...prev,
				{ file, uploadToken: uploadUrl.fileUploadToken, uploadProgress: 0 },
			]);
			uploadFile(uploadUrl.blobUploadUrl, file, (e) => {
				setAttachments((prev) =>
					prev.map((a) => {
						if (a.uploadToken === uploadUrl.fileUploadToken) {
							return {
								...a,
								uploadProgress: e.progress ?? 0,
							};
						}
						return a;
					})
				);
			});
		});
	};

	const onRemove = (uploadToken: string) =>
		setAttachments((prev) => prev.filter((a) => a.uploadToken !== uploadToken));

	const clearAttachments = () => setAttachments([]);

	return { attachments, onDropAccepted, onRemove, clearAttachments };
};
