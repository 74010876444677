import { gql } from '@apollo/client';
import { Address, AddressFieldLookup, GeoAddress } from 'middleware-types';
import { Grid, TextField as MTTextField, MenuItem, Skeleton } from '@mui/material';
import { SelectField, TextField } from 'components/ui/fields';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useCountries } from 'utils/useCountries';

const AddressFields = `
        countryId
        address1
        address2
        municipality
        adminArea1Id
        adminArea2Id
        postalCode
        coordinate {
            latitude
            longitude
        }
`;

/**
 * ADDRESS_FIELDS shorthand fragment for addresses.
 */
export const ADDRESS_FIELDS = gql`
	fragment AddressFields on Address {
        ${AddressFields}
	}
`;

/**
 * GEOADDRESS_FIELDS shorthand fragment for geoaddresses.
 */
export const GEOADDRESS_FIELDS = gql`
	fragment GeoAddressFields on GeoAddress {
        ${AddressFields}
	}
`;

type AddressFieldHookField = {
	visible: boolean;
	form: {
		label: string;
		name: string;
		disabled: boolean;
		required: boolean;
		validate?: (values: string) => string | undefined;
		onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	};
};

type AddressFieldHookFieldLookup = AddressFieldHookField & {
	lookups?: AddressFieldLookup[];
};

type AddressFieldsHook = {
	loading: boolean;
	disabled: boolean;
	required: boolean;
	country: Omit<AddressFieldHookFieldLookup, 'visible'>;
	address1: AddressFieldHookField;
	address2: AddressFieldHookField;
	municipality: AddressFieldHookField;
	postalCode: AddressFieldHookField;
	adminArea1: AddressFieldHookFieldLookup;
	adminArea2: AddressFieldHookFieldLookup;
};

type Visibility = 'Hidden' | 'Optional' | 'Required';

/**
 * useAddressFields(options) is a custom hook to handle all the loading of country
 * data, validation, and visibility.
 *
 * @param {AddressFieldProps} options
 * @returns {AddressFieldsHook}
 */
export const useAddressFields = (options: AddressFieldProps): AddressFieldsHook => {
	const required = options.required === true;
	const disabled = options.disabled === true;
	const formik = useFormikContext();
	useEffect(() => {
		formik.validateForm();
	}, [options.required]);

	const value = formik.getFieldMeta<{
		countryId: string;
	}>(options.name).value.countryId;
	const { loading, countries } = useCountries();

	// Get the list of countries and set the validation/visibility rules.
	const selectedCountry = countries.filter((c) => c.id === value)[0];

	const createForm = (name: string, label?: string | null, visibility?: Visibility) => {
		return {
			name: `${options.name}.${name}`,
			label: label ?? '',
			disabled,
			required: required && visibility === 'Required',
			validate: (values: string | undefined) =>
				required && visibility === 'Required' && (!values || values.length === 0)
					? 'Required field'
					: undefined,
		};
	};

	return {
		required,
		loading,
		disabled,
		country: {
			// TODO: Windows 10 doesn't support emoji flags yet
			// ${countryCodeEmoji(c.iso2CountryCode) ?? ' '}
			lookups: countries.map((c) => ({ displayName: `${c.name}`, id: c.id })),
			form: {
				name: options.name + '.countryId',
				label: 'Country',
				disabled,
				required,
				onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					formik.setFieldValue(options.name + '.countryId', event.target.value);
					formik.setFieldValue(options.name + '.address1', '');
					formik.setFieldValue(options.name + '.address2', '');
					formik.setFieldValue(options.name + '.municipality', '');
					formik.setFieldValue(options.name + '.adminArea1Id', '');
					formik.setFieldValue(options.name + '.adminArea2Id', '');
					formik.setFieldValue(options.name + '.postalCode', '');
				},
			},
		},
		address1: {
			visible: selectedCountry?.address1Visibility !== 'Hidden',
			form: createForm(
				'address1',
				selectedCountry?.address1Label,
				selectedCountry?.address1Visibility as Visibility
			),
		},
		address2: {
			visible: selectedCountry?.address2Visibility !== 'Hidden',
			form: createForm(
				'address2',
				selectedCountry?.address2Label,
				selectedCountry?.address2Visibility as Visibility
			),
		},
		municipality: {
			visible: selectedCountry?.municipalityVisibility !== 'Hidden',
			form: createForm(
				'municipality',
				selectedCountry?.municipalityLabel,
				selectedCountry?.municipalityVisibility as Visibility
			),
		},
		adminArea1: {
			visible: selectedCountry?.adminArea1Visibility !== 'Hidden',
			lookups: selectedCountry?.addressFieldLookups
				?.filter((l) => l.field === 'AdminArea1')
				?.sort((a, b) => {
					if (a.displayOrder === undefined && b.displayOrder === undefined) {
						return 0;
					}
					if (a.displayOrder === undefined) {
						return -1;
					}
					if (b.displayOrder === undefined) {
						return 1;
					}
					return a.displayOrder - b.displayOrder;
				}),
			form: createForm(
				'adminArea1Id',
				selectedCountry?.adminArea1Label,
				selectedCountry?.adminArea1Visibility as Visibility
			),
		},
		adminArea2: {
			visible: selectedCountry?.adminArea2Visibility !== 'Hidden',
			lookups: selectedCountry?.addressFieldLookups
				?.filter((l) => l.field === 'AdminArea2')
				?.sort((a, b) => {
					if (a.displayOrder === undefined && b.displayOrder === undefined) {
						return 0;
					}
					if (a.displayOrder === undefined) {
						return -1;
					}
					if (b.displayOrder === undefined) {
						return 1;
					}
					return a.displayOrder - b.displayOrder;
				}),
			form: createForm(
				'adminArea2Id',
				selectedCountry?.adminArea2Label,
				selectedCountry?.adminArea2Visibility as Visibility
			),
		},
		postalCode: {
			visible: selectedCountry?.postalCodeVisibility !== 'Hidden',
			form: {
				...createForm(
					'postalCode',
					selectedCountry?.postalCodeLabel,
					selectedCountry?.postalCodeVisibility as Visibility
				),
				validate: (values) => {
					if (
						required &&
						selectedCountry?.postalCodeVisibility === 'Required' &&
						(!values || values.length === 0)
					)
						return 'Required field';
					if (selectedCountry?.postalCodeRegex && values !== '')
						if (!values.match(new RegExp(selectedCountry.postalCodeRegex)))
							return `Invalid ${selectedCountry.postalCodeLabel?.toLocaleLowerCase()}`;
				},
			},
		},
	};
};

export type AddressFieldProps = {
	name: string;
	required?: boolean;
	disabled?: boolean;
};

/**
 * <AddressField> is an basic address with no geolocation.
 *
 * @param {AddressFieldProps} props
 * @returns
 */
export const AddressField = (props: AddressFieldProps) => {
	const fields = useAddressFields(props);
	if (fields.loading) return <AddressFieldSkeleton />;
	return (
		<Grid container spacing={1} columnSpacing={2}>
			<Grid item container spacing={1} columnSpacing={2}>
				<Grid xs={12} item>
					<SelectField {...fields.country.form}>
						{fields?.country?.lookups?.map((l) => (
							<MenuItem key={l.id} value={l.id}>
								{l.displayName}
							</MenuItem>
						))}
					</SelectField>
				</Grid>
				{fields.address1.visible && (
					<Grid xs={12} md={12} item>
						<TextField {...fields.address1.form} />
					</Grid>
				)}
				{fields.address2.visible && (
					<Grid xs={12} md={12} item>
						<TextField {...fields.address2.form} />
					</Grid>
				)}
				{fields.municipality.visible && (
					<Grid xs={12} sm={6} md={4} item>
						<TextField {...fields.municipality.form} />
					</Grid>
				)}
				{fields.adminArea1.visible && (
					<Grid xs={12} sm={6} md={4} item>
						<SelectField {...fields.adminArea1.form}>
							{fields?.adminArea1?.lookups?.map((l) => (
								<MenuItem key={l.id} value={l.id}>
									{l.displayName}
								</MenuItem>
							))}
						</SelectField>
					</Grid>
				)}
				{fields.adminArea2.visible && (
					<Grid xs={12} sm={6} md={4} item>
						<SelectField {...fields.adminArea2.form}>
							{fields.adminArea2?.lookups?.map((l) => (
								<MenuItem key={l.id} value={l.id}>
									{l.displayName ?? <>&nbsp;</>}
								</MenuItem>
							))}
						</SelectField>
					</Grid>
				)}
				{fields.postalCode.visible && (
					<Grid xs={12} sm={6} md={4} item>
						<TextField {...fields.postalCode.form} />
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

/**
 * Returns a readonly address for a fieldgroup section.
 * @param props
 * @returns
 */
export const AddressText = (props: Address | GeoAddress | undefined) => {
	const { loading, countries } = useCountries();
	let stateOrProvince = '';
	let country = '';

	if (!loading && countries) {
		country = countries.find((c) => c.id === props?.countryId)?.name ?? '';
		stateOrProvince =
			countries
				.find((c) => c.id === props?.countryId)
				?.addressFieldLookups?.find((s) => s.id === props?.adminArea1Id)?.value ?? '';
	}

	return (
		<>
			<div>{props?.address1}</div>
			<div>{props?.address2}</div>
			<div>
				{props?.municipality}
				{props?.municipality ? ',' : ''} {stateOrProvince} {props?.postalCode}
			</div>
			<div>{country}</div>
		</>
	);
};

/**
 * Skeleton loader for the address form.
 *
 * @returns
 */
export const AddressFieldSkeleton = () => {
	return (
		<Grid container spacing={1} columnSpacing={2}>
			<Grid xs={12} md={12} item>
				<Skeleton width="100%">
					<MTTextField fullWidth />
				</Skeleton>
			</Grid>
			<Grid xs={12} md={12} item>
				<Skeleton width="100%">
					<MTTextField fullWidth />
				</Skeleton>
			</Grid>
			<Grid xs={12} md={12} item>
				<Skeleton width="100%">
					<MTTextField fullWidth />
				</Skeleton>
			</Grid>
			<Grid xs={12} sm={6} md={4} item>
				<Skeleton width="100%">
					<MTTextField fullWidth />
				</Skeleton>
			</Grid>
			<Grid xs={12} sm={6} md={4} item>
				<Skeleton width="100%">
					<MTTextField fullWidth />
				</Skeleton>
			</Grid>
			<Grid xs={12} sm={6} md={4} item>
				<Skeleton width="100%">
					<MTTextField fullWidth />
				</Skeleton>
			</Grid>
		</Grid>
	);
};
