import { Button } from '@mui/material';

interface SuggestedPromptProps {
	text: string;
	sendRequest: (request: string) => void;
}

export const SuggestedPropmt = ({ text, sendRequest }: SuggestedPromptProps) => {
	return (
		<Button
			color="primary"
			variant="outlined"
			sx={{ textAlign: 'left' }}
			onClick={() => sendRequest(text)}>
			{text}
		</Button>
	);
};
