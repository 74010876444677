import { gql, useQuery } from '@apollo/client';
import {
	Query,
	QueryChatConversationGetArgs,
	QueryChatLastMessagesByUserArgs,
	QueryEvoxReviewArgs,
} from 'middleware-types';

export const useGetConversation = (conversationId: string | undefined) => {
	const { data, loading } = useQuery<
		Pick<Query, 'chatConversationGet'>,
		QueryChatConversationGetArgs
	>(
		gql`
			query GetChatConversation($conversationId: ID!) {
				chatConversationGet(conversationId: $conversationId) {
					chatConversationId
					userId
					openAiThreadId
					cumulativeTokens
					chatMessages {
						chatMessageId
						dateTimeUtc
						content
						rating
						role
						sequence
						functionName
						functionCallName
						functionCallArguments
						dateTimeUtc
						files {
							chatFileId
							name
						}
						footnotes {
							chatFootnoteId
							url
							fileName
							snippet
							urlDisplayName
						}
					}
				}
			}
		`,
		{
			skip: conversationId === undefined,
			variables: { conversationId: conversationId! },
		}
	);

	const userId = data?.chatConversationGet.userId;
	const openAiThreadId = data?.chatConversationGet.openAiThreadId;
	const cumulativeTokens = data?.chatConversationGet.cumulativeTokens ?? 0;
	const messages = data?.chatConversationGet.chatMessages ?? [];
	return { messages, userId, openAiThreadId, cumulativeTokens, loading };
};

export const useEvoxReview = (variables: QueryEvoxReviewArgs) => {
	const { data, loading } = useQuery<Pick<Query, 'evoxReview'>, QueryEvoxReviewArgs>(
		gql`
			query evoxReview(
				$pageSize: Float!
				$offset: Float!
				$sortDirection: SortDirection!
				$chatMessageRating: ChatMessageRating
				$conversationTypeFilter: [ConversationTypeFilter!]
			) {
				evoxReview(
					pageSize: $pageSize
					offset: $offset
					sortDirection: $sortDirection
					chatMessageRating: $chatMessageRating
					conversationTypeFilter: $conversationTypeFilter
				) {
					totalCount
					items {
						chatConversationId
						chatMessageId
						content
						dateTimeUtc
					}
				}
			}
		`,
		{ variables }
	);

	const messages = data?.evoxReview?.items ?? [];
	const totalCount = data?.evoxReview?.totalCount ?? 0;

	return { messages, totalCount, loading };
};

export const useChatLastMessagesByUser = (userId: string) => {
	const { data, loading } = useQuery<
		Pick<Query, 'chatLastMessagesByUser'>,
		QueryChatLastMessagesByUserArgs
	>(
		gql`
			query ChatLastMessagesByUser($userId: ID!) {
				chatLastMessagesByUser(userId: $userId) {
					relevantChatMessages {
						chatConversationId
						chatMessageId
						content
						dateTimeUtc
					}
				}
			}
		`,
		{ variables: { userId } }
	);

	const messages = data?.chatLastMessagesByUser.relevantChatMessages ?? [];
	return { messages, loading };
};
