import React from 'react';
import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useField } from 'formik';

const theme = createTheme();
const ToggleButtonGroupStyle = createTheme({
	components: {
		MuiToggleButtonGroup: {
			styleOverrides: {
				grouped: {
					'margin': theme.spacing(0.5),
					'border': 'none',
					'textTransform': 'none',
					'color': '#000',
					'backgroundColor': '#DFE0DF',
					'minWidth': '4.9rem',
					'lineHeight': '0.9rem',
					'&:not(:first-of-type)': {
						borderRadius: theme.shape.borderRadius,
					},
					'&:first-of-type': {
						borderRadius: theme.shape.borderRadius,
					},
					'&.Mui-selected': {
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,
					},
					'&.Mui-selected:hover': {
						backgroundColor: theme.palette.primary.light,
					},
				},
			},
		},
	},
});

/**
 * ButtonGroupItem - ButtonGroupItem is just a toggle button.
 *
 * @param {*} {name, required, ...other}
 * @returns
 */
export const ButtonGroupItem = ToggleButton;

/**
 * ButtonGroupField - A group of toggle buttons.
 *
 * @param {*} {name, required, ...other}
 * @returns
 */
export const ButtonGroupField = ({
	name,
	required,
	...other
}: ToggleButtonGroupProps & { name: string; required?: boolean }) => {
	const [field, , helpers] = useField(name);
	const onChange = (_: React.MouseEvent<HTMLElement, MouseEvent>, newValue: any) => {
		if (required && newValue === null) return;
		helpers.setValue(newValue);
	};
	return (
		<ThemeProvider theme={ToggleButtonGroupStyle}>
			<ToggleButtonGroup onChange={onChange} value={field.value} {...other} />
		</ThemeProvider>
	);
};
