import {
	Card,
	CardActionArea,
	CardContent,
	Chip,
	Stack,
	SvgIconTypeMap,
	Typography,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Link } from 'react-router-dom';
import { theme } from 'utils/theme';

interface LinkCardProps {
	Icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>> | string;
	text: string;
	link?: string;
	externalLink?: boolean;
	disabled?: boolean;
	accentColor?: string;
}

export const LinkCard = ({
	Icon,
	text,
	link,
	externalLink,
	disabled = false,
	accentColor = theme.palette.secondary.main,
}: LinkCardProps) => {
	return (
		<Card
			sx={
				disabled
					? undefined
					: {
							borderBottomWidth: 8,
							borderBottomStyle: 'solid',
							borderBottomColor: accentColor,
					  }
			}>
			<CardActionArea
				disabled={disabled}
				component={Link}
				target={externalLink ? '_blank' : undefined}
				to={link ?? ''}
				sx={{ height: '100%' }}>
				<CardContent sx={{ height: '100%' }}>
					<Stack alignItems="center" justifyContent="center" spacing={1} height={120}>
						{typeof Icon === 'string' ? (
							<img src={Icon} alt={text} style={{ width: 48, height: 48 }} />
						) : (
							<Icon
								fontSize="large"
								sx={{ color: disabled ? 'neutral.300' : 'neutral.500' }}
							/>
						)}
						<Typography
							textAlign="center"
							color={disabled ? 'GrayText' : undefined}
							maxWidth="80%"
							sx={{
								overflowWrap: 'break-word',
								typography: { xs: 'h3', sm: 'h2' },
							}}>
							{text}
						</Typography>
						{disabled && <Chip label="COMING SOON" size="small" />}
					</Stack>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};
