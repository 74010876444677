import { gql, useLazyQuery } from '@apollo/client';
import { Cached } from '@mui/icons-material';
import { Box, Link, Stack, Typography } from '@mui/material';
import { Query } from 'middleware-types';
import { useEffect, useState } from 'react';
import { version } from '../../../package.json';

export const UpdateBanner = () => {
	if (version === '0.0.0') return null;
	return <_UpdateBanner />;
};

const _UpdateBanner = () => {
	const [isOutOfDate, setIsOutOfDate] = useState(false);
	const [getVersionNumber] = useLazyQuery<Pick<Query, 'versionNumber'>>(
		gql`
			query GetVersionNumber {
				versionNumber
			}
		`,
		{
			fetchPolicy: 'no-cache',
		}
	);

	useEffect(() => {
		const interval = setInterval(() => {
			getVersionNumber().then((data) => {
				if (!data.data) return;
				setIsOutOfDate(data.data.versionNumber !== version);
			});
		}, 15000);
		return () => clearInterval(interval);
	}, []);

	if (!isOutOfDate) return null;
	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			px={2}
			py={1.25}
			spacing={2}
			sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText' }}>
			<Box />
			<Stack direction="row" spacing={1} alignItems="center">
				<Cached color="inherit" />
				<Box>
					<Typography variant="h5" color="inherit" display="inline">
						This site has been updated.{' '}
					</Typography>
					<Typography color="inherit" display="inline">
						Please save any current work and click the Refresh link to update.
					</Typography>
				</Box>
			</Stack>
			<Link color="inherit" component="button" onClick={() => window.location.reload()}>
				Refresh
			</Link>
		</Stack>
	);
};
