import { RefObject, useEffect, useRef, useState } from 'react';

export const useElementSize = <T extends HTMLElement>(): [
	RefObject<T>,
	{ clientHeight: number | undefined; clientWidth: number | undefined }
] => {
	const ref = useRef<T>(null);
	const [clientHeight, setClientHeight] = useState<number | undefined>(undefined);
	const [clientWidth, setClientWidth] = useState<number | undefined>(undefined);

	const setDimensions = (element: T) => {
		setClientHeight(element.clientHeight);
		setClientWidth(element.clientWidth);
	};

	// set the initial dimensions
	useEffect(() => {
		if (ref.current) setDimensions(ref.current);
	}, []);

	// listen for changes
	useEffect(() => {
		if (!ref.current) return;
		const resizeObserver = new ResizeObserver(() => {
			if (ref.current) setDimensions(ref.current);
		});
		resizeObserver.observe(ref.current);
		return () => resizeObserver.disconnect();
	}, []);

	return [ref, { clientHeight, clientWidth }];
};
