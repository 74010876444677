import { ErrorOutlined } from '@mui/icons-material';
import { Auth } from 'aws-amplify';
import { useResetMfa } from 'components/pages/user/account/security/hooks';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalLoadingButton,
} from 'components/ui/modal';
import { useNavigate } from 'react-router-dom';
import { useSession } from 'utils/session';

interface ResetMfaModalProps {
	userId: string;
}

export const ResetMfaModal = ({ userId }: ResetMfaModalProps) => {
	const navigate = useNavigate();
	const { user } = useSession();
	const { resetMfa } = useResetMfa();

	return (
		<>
			<ConfirmModalContent
				visual={
					<EmptyStateAvatar
						avatarProps={{ bgcolor: 'error.50' }}
						iconProps={{ color: 'error.500' }}
						icon={<ErrorOutlined />}
					/>
				}
				subheadline="Are you sure?"
				informativeContent={
					user.userId == userId
						? `Do you really want to reset your MFA? Doing so will log you out. When you next log in you will be required to reconfigure your MFA.`
						: `Do you really want to reset this user's MFA? When this user logs in next they will be required to reconfigure their MFA.`
				}
			/>
			<ModalActions>
				<ModalActionButton size="large" variant="outlined">
					Cancel
				</ModalActionButton>
				<ModalLoadingButton
					size="large"
					variant="contained"
					color="error"
					onClick={async () => {
						return await resetMfa(userId).then(async (res) => {
							if (!res) return false;

							// Don't sign out after MFA reset if reset was triggered
							// from an account page that isn't one's own (e.g. a site user
							// visiting a social user's account page).
							if (user.userId !== userId) return true;

							await Auth.signOut();
							navigate('/auth/login');
							return false;
						});
					}}>
					Reset MFA
				</ModalLoadingButton>
			</ModalActions>
		</>
	);
};
