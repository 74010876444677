import { gql, useQuery } from '@apollo/client';
import { FILE_INSTANCE_INFO_FIELDS } from '../../utils/fragments.graphql';
import { Query, QueryGetFileInstancesArgs } from 'middleware-types';

export const GET_FILE_INSTANCES = gql`
	${FILE_INSTANCE_INFO_FIELDS}
	query GetFileInstances($fileId: String!) {
		getFileInstances(fileId: $fileId) {
			fileId
			instances {
				...FileInstanceInfoFields
			}
		}
	}
`;

export const useFileInstances = (fileId: string) => {
	const { data, loading } = useQuery<Pick<Query, 'getFileInstances'>, QueryGetFileInstancesArgs>(
		GET_FILE_INSTANCES,
		{
			variables: { fileId },
		}
	);

	const files = data?.getFileInstances.instances ?? [];
	return { files, loading };
};
