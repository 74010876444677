import { useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { ContainedDrawer } from 'components/ui/containedDrawer';
import { Query, QueryChatConversationGetLatestArgs } from 'middleware-types';
import { useSession } from 'utils/session';
import { GET_LATEST, useDeleteSavedConversation } from './hooks';

interface DeletePromptDrawerProps {
	open: boolean;
	onClose: () => void;
	conversationId: string;
	conversationName?: string;
	setConversationName: React.Dispatch<React.SetStateAction<string | null>>;
}

const useGetLatestConversationName = (userId: string) => {
	const [getLatestConversationName] = useLazyQuery<
		Pick<Query, 'chatConversationGetLatest'>,
		QueryChatConversationGetLatestArgs
	>(GET_LATEST, {
		variables: {
			userId: userId,
		},
		fetchPolicy: 'no-cache',
	});

	return { getLatestConversationName };
};

export const DeleteConversationDrawer = ({
	open,
	onClose,
	conversationId,
	conversationName = '',
	setConversationName,
}: DeletePromptDrawerProps) => {
	const { deleteConversation: _deleteConversation, loading } = useDeleteSavedConversation();
	const { user } = useSession();
	const { getLatestConversationName } = useGetLatestConversationName(user.userId);

	const deleteConversation = async () => {
		const success = await _deleteConversation(conversationId);
		if (success) {
			getLatestConversationName().then((res) =>
				setConversationName(
					res.data?.chatConversationGetLatest.conversation?.chatConversationName ?? null
				)
			);
			onClose();
		}
	};

	return (
		<ContainedDrawer
			open={open}
			anchor="top"
			containerid="evox-container"
			PaperProps={{ sx: { borderRadius: 1 } }}>
			<Stack p={2} spacing={1}>
				<Typography variant="h3">Unpin &quot;{conversationName}&quot;?</Typography>
				<Typography variant="body2">
					You will not be able to access the conversation later.
				</Typography>
				<Stack direction="row" spacing={1} justifyContent="flex-end">
					<LoadingButton
						loading={loading}
						variant="outlined"
						color="primary"
						onClick={onClose}>
						Cancel
					</LoadingButton>
					<LoadingButton
						loading={loading}
						variant="contained"
						color="error"
						onClick={deleteConversation}>
						Yes, unpin
					</LoadingButton>
				</Stack>
			</Stack>
		</ContainedDrawer>
	);
};
