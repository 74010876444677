import {
	AccountCircleOutlined,
	ArrowDropDownOutlined,
	ExitToAppOutlined,
	MarkAsUnreadOutlined,
	SettingsOutlined,
} from '@mui/icons-material';
import {
	Badge,
	Button,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout, useSession } from 'utils/session';
import { useAccount } from 'utils/useAccount';
import { useUserBadgeCounts } from 'utils/useBadgeCounts';
import { UserEmblemAvatar } from './emblem/emblem-avatar';

/**
 * UserPanel is the user dropdown on the top right.
 * @returns
 */
export const UserPanel = ({ fullWidth = false }: { fullWidth?: boolean }) => {
	const navigate = useNavigate();
	const { user } = useSession();
	const { account, loading } = useAccount();
	const { userBadgeCounts } = useUserBadgeCounts();
	const associateInvitationsBadgeCount = userBadgeCounts?.associateInvitationsBadgeCount ?? 0;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	let name = '';
	if (!loading && account) {
		name = account?.name?.firstName + ' ' + account?.name?.lastName;
	}

	const handleClick = (path: string) => {
		setAnchorEl(null);
		navigate(path);
	};

	return (
		<>
			<Button
				onClick={(el) => setAnchorEl(el.currentTarget)}
				sx={{
					'cursor': 'pointer',
					'backgroundColor': anchorEl ? 'neutral.100' : undefined,
					':hover': {
						backgroundColor: 'neutral.100',
					},
					'borderRadius': fullWidth ? undefined : 1,
					'paddingX': fullWidth ? 2 : 1,
					'paddingY': fullWidth ? 1.5 : 0.5,
					'display': 'flex',
					'justifyContent': 'space-between',
					'gap': 1,
					':focus-visible': {
						backgroundColor: 'neutral.100',
					},
				}}>
				<Stack direction="row" spacing={1} alignItems="center">
					{account && (
						<Badge
							variant="dot"
							overlap="circular"
							color="primary"
							badgeContent={anchorEl ? 0 : associateInvitationsBadgeCount}>
							<UserEmblemAvatar id={account.id} noDropdown />
						</Badge>
					)}
					<Typography
						flex={1}
						noWrap
						sx={{
							display: {
								sm: 'none',
								lg: 'block',
							},
						}}>
						{name}
					</Typography>
				</Stack>
				<ArrowDropDownOutlined />
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
				disableAutoFocus
				disableAutoFocusItem
				disableRestoreFocus>
				{account?.id && !user.siteUserId && (
					<MenuItem
						sx={{ minWidth: '10.5rem' }}
						onClick={() => handleClick(`/${account.handle}`)}>
						<ListItemIcon>
							<AccountCircleOutlined />
						</ListItemIcon>
						<ListItemText>Profile</ListItemText>
					</MenuItem>
				)}
				{account?.id && (
					<MenuItem
						sx={{ minWidth: '10.5rem' }}
						onClick={() => handleClick(`/${account.handle}/account/`)}>
						<ListItemIcon>
							<SettingsOutlined />
						</ListItemIcon>
						<ListItemText>Account</ListItemText>
					</MenuItem>
				)}
				{!user.siteUserId && (
					<MenuItem
						sx={{ minWidth: '10.5rem' }}
						onClick={() => handleClick(`/orgs/invitations/`)}>
						<ListItemIcon>
							<MarkAsUnreadOutlined />
						</ListItemIcon>
						<ListItemText>Organization Invitations</ListItemText>
						<Badge
							sx={{
								marginLeft: 2,
							}}
							variant="dot"
							color="primary"
							badgeContent={associateInvitationsBadgeCount}></Badge>
					</MenuItem>
				)}
				<MenuItem sx={{ minWidth: '10.5rem' }} onClick={() => logout()}>
					<ListItemIcon>
						<ExitToAppOutlined />
					</ListItemIcon>
					<ListItemText>Sign Out</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
};
