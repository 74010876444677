import { FormControl, FormHelperText, FormLabel, RadioGroup, RadioGroupProps } from '@mui/material';
import { useField } from 'formik';

interface RadioGroupFieldProps extends Omit<RadioGroupProps, 'name' | 'value' | 'onChange'> {
	name: string;
	label?: string;
	helperText?: string;
}

export const RadioGroupField = ({
	name,
	label,
	helperText,
	children,
	...props
}: RadioGroupFieldProps) => {
	const [{ value }, { error }, { setValue }] = useField(name);
	const helperTextValue = error ?? helperText;
	return (
		<FormControl error={Boolean(error)}>
			{label && <FormLabel>{label}</FormLabel>}
			<RadioGroup
				name={name}
				value={value}
				onChange={(_e, newValue) => setValue(newValue)}
				{...props}>
				{children}
			</RadioGroup>
			{helperTextValue && <FormHelperText>{helperTextValue}</FormHelperText>}
		</FormControl>
	);
};
