import {
	Check,
	Close,
	HowToRegOutlined,
	PersonAddAlt1Outlined,
	SendOutlined,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { HoverButton } from 'components/ui/hover-button';
import { ConnectionState } from 'middleware-types';
import { ComprehensiveConnectionState, ConnectionStateExtended } from './types';

interface ConnectionButtonProps {
	connectionState: ComprehensiveConnectionState;
	onClick: () => void;
	onClick2?: () => void;
	loading?: boolean;
	color: 'primary' | 'darkSecondary';
}

export const ConnectionButton = ({
	connectionState,
	onClick,
	onClick2,
	loading = false,
	color,
}: ConnectionButtonProps) => {
	switch (connectionState) {
		case ConnectionState.Connected:
			return (
				<Stack direction="row">
					<HoverButton
						variant="contained"
						color={color}
						startIcon={<HowToRegOutlined />}
						hoverChildren="Disconnect"
						hoverStartIcon={<Close />}
						onClick={onClick}>
						Connected
					</HoverButton>
				</Stack>
			);
		case ConnectionState.Pending:
			return (
				<Stack direction="row">
					<HoverButton
						variant="contained"
						color={color}
						startIcon={<SendOutlined />}
						loading={loading}
						hoverChildren="Cancel Request?"
						hoverStartIcon={<Close />}
						onClick={onClick}>
						Request Sent
					</HoverButton>
				</Stack>
			);
		case ConnectionStateExtended.IncomingRequest:
			return (
				<Stack direction="row" spacing={1.5}>
					<LoadingButton
						variant="contained"
						color={color}
						startIcon={<Check />}
						loading={loading}
						onClick={onClick}>
						Accept
					</LoadingButton>
					<LoadingButton
						variant="outlined"
						startIcon={<Close />}
						loading={loading}
						onClick={onClick2}>
						Decline
					</LoadingButton>
				</Stack>
			);
		case ConnectionState.Ignored:
		case ConnectionStateExtended.NotConnected:
			return (
				<Stack direction="row">
					<LoadingButton
						variant="contained"
						color={color}
						startIcon={<PersonAddAlt1Outlined />}
						loading={loading}
						onClick={onClick}>
						Connect
					</LoadingButton>
				</Stack>
			);
	}
};
