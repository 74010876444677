import { gql, useMutation } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	FileSetCurrentInstanceRequest,
	Mutation,
	MutationFileSetCurrentInstanceArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { addNewFileInstance } from '../../utils/cache-helpers';
import { FILE_INFO_FIELDS } from '../../utils/fragments.graphql';

const MAKE_CURRENT_INSTANCE = gql`
	${FILE_INFO_FIELDS}
	mutation MakeCurrentInstance($fileId: String!, $request: FileSetCurrentInstanceRequest!) {
		fileSetCurrentInstance(fileId: $fileId, request: $request) {
			...FileInfoFields
		}
	}
`;

export const useMakeCurrentInstance = () => {
	const toast = useToast();

	const [_makeCurrentInstance, { loading }] = useMutation<
		Pick<Mutation, 'fileSetCurrentInstance'>,
		MutationFileSetCurrentInstanceArgs
	>(MAKE_CURRENT_INSTANCE, { onError: (e) => console.log(JSON.stringify(e)) });

	const makeCurrentInstance = async (fileId: string, request: FileSetCurrentInstanceRequest) => {
		return await _makeCurrentInstance({
			variables: { fileId, request },
			update: (cache, { data }) => {
				if (!data) return;
				addNewFileInstance({ cache, file: data.fileSetCurrentInstance });
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('File updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { makeCurrentInstance, loading };
};
