import {
	Chat,
	Check,
	CheckTwoTone,
	ChevronLeft,
	Close,
	Delete,
	EmojiEmotions,
	ExitToApp,
	RemoveRedEyeOutlined,
	Repeat,
	Tune,
	WorkOutline,
} from '@mui/icons-material';
import {
	Box,
	Button,
	Divider,
	IconButton,
	InputAdornment,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Popover,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { Editor } from '@tiptap/react';
import { EvoxLogoIcon } from 'components/ui/icons';
import { useState } from 'react';
import { useIsMobile } from 'utils/useScreenSize';
import { EvoxPlaceholderBox } from './evox-placeholder-box';
import { useEvoxMessageDraft } from './hooks';

interface ConversationEvoxProps {
	onClose: () => void;
	editor: Editor | null;
	isNewConversation: boolean;
}

export const ConversationEvox = ({ onClose, editor, isNewConversation }: ConversationEvoxProps) => {
	const [prompt, setPrompt] = useState('');
	const [quickRefineAnchor, setQuickRefineAnchor] = useState<Element | null>(null);
	const [promptPopoverAnchor, setPromptPopoverAnchor] = useState<Element | null>(null);
	const [sendConversationHistoryToAi, setSendConversationHistoryToAi] = useState(false);

	const isMobile = useIsMobile();

	const { makeRequest, textToShow, previousPrompt, state, setState, loading, canGoBack } =
		useEvoxMessageDraft(
			editor?.getText({ blockSeparator: '\n' }) || undefined,
			sendConversationHistoryToAi && !isNewConversation
		);

	const sendRequest = () => {
		if (!prompt) return;
		makeRequest(prompt, true);
	};

	const handleSendConversationHistoryClicked = () => {
		setSendConversationHistoryToAi(true);
	};

	const keepDraft = () => {
		if (!textToShow) return;
		// render each line as a separate paragraph to maintain formatting
		const lines = textToShow
			.split('\n')
			.map((line) => line.trim())
			.filter((line) => line);
		editor?.commands.setContent(
			{
				type: 'doc',
				content: lines.map((line) => ({
					type: 'paragraph',
					content: [
						{
							type: 'text',
							text: line,
						},
					],
				})),
			},
			true
		);
		onClose();
	};

	return (
		<Stack flex={1} spacing={1.5} overflow="auto">
			<Stack direction="row" spacing={1} alignItems="center">
				<EvoxLogoIcon />
				<Typography variant="h5" flex={1}>
					EVO-X Mode
				</Typography>
				{!isNewConversation && (
					<Tooltip title="Opt-in to share the full history of this conversation with OpenAI when generating drafts (message attachments will NOT be shared). Once opted-in, this setting will stay toggled on for just this EVO-X Mode instance.">
						{sendConversationHistoryToAi ? (
							<Stack direction="row" spacing={1} alignItems="center">
								<CheckTwoTone sx={{ color: 'neutral.500' }} />
								<Typography sx={{ color: 'neutral.500' }}>
									Conversation History Included
								</Typography>
							</Stack>
						) : (
							<Button
								variant="contained"
								color="primary"
								onClick={handleSendConversationHistoryClicked}>
								Include Conversation History
							</Button>
						)}
					</Tooltip>
				)}
				<IconButton onClick={onClose}>{isMobile ? <ExitToApp /> : <Close />}</IconButton>
			</Stack>
			{loading ? (
				<EvoxPlaceholderBox loading />
			) : textToShow ? (
				<Stack
					flex={1}
					border="1px solid"
					borderColor="neutral.200"
					borderRadius={1}
					overflow="auto">
					<Stack direction="row" alignItems="center" spacing={1} px={1.5} py={0.5}>
						<Typography variant="h6" flex={1} noWrap>
							{previousPrompt}
						</Typography>
						<IconButton
							size="small"
							onClick={(e) => setPromptPopoverAnchor(e.currentTarget)}>
							<RemoveRedEyeOutlined />
						</IconButton>
					</Stack>
					<Divider />
					<Box flex={1} p={1.5} overflow="auto">
						<Typography variant="body2" whiteSpace="pre-wrap">
							{textToShow}
						</Typography>
					</Box>
				</Stack>
			) : (
				<EvoxPlaceholderBox loading={false} />
			)}
			{state === 'draft' ? (
				<Stack
					direction={{ xs: 'column', sm: 'row' }}
					spacing={1}
					alignItems={{ xs: 'stretch', sm: 'flex-end' }}>
					<Stack direction="row" spacing={1} alignItems="flex-end" flex={1}>
						{canGoBack && (
							<IconButton
								disabled={loading}
								onClick={() => setState('preview')}
								sx={{
									border: '1px solid',
									borderColor: 'neutral.200',
									borderRadius: 1,
									height: 40,
								}}>
								<ChevronLeft />
							</IconButton>
						)}
						<TextField
							disabled={loading}
							value={prompt}
							onChange={(e) => setPrompt(e.target.value)}
							placeholder={
								textToShow
									? 'Ask EVO-X to refine this draft...'
									: 'Ask EVO-X to draft a message for you...'
							}
							multiline
							maxRows={5}
							sx={{ bgcolor: 'neutral.50' }}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<EvoxLogoIcon />
									</InputAdornment>
								),
							}}
							onKeyDown={(e) => {
								if (e.key === 'Enter' && !e.shiftKey) {
									e.preventDefault();
									sendRequest();
								}
							}}
						/>
						{textToShow && (
							<IconButton
								disabled={loading}
								onClick={(e) => setQuickRefineAnchor(e.currentTarget)}
								sx={{
									border: '1px solid',
									borderColor: 'neutral.200',
									borderRadius: 1,
									height: 40,
								}}>
								<Tune />
							</IconButton>
						)}
					</Stack>
					<Button
						disabled={loading || !prompt}
						variant="contained"
						color="primary"
						sx={{ minWidth: 'fit-content', height: 40 }}
						onClick={sendRequest}>
						{textToShow ? 'Refine Draft' : 'Create Draft'}
					</Button>
				</Stack>
			) : (
				<Stack direction="row" spacing={1} overflow="auto" flexShrink={0}>
					<Button
						disabled={loading}
						sx={{ flexShrink: 0 }}
						variant="contained"
						color="primary"
						startIcon={<Check />}
						onClick={keepDraft}>
						Keep
					</Button>
					<Button
						disabled={loading}
						sx={{ flexShrink: 0 }}
						variant="outlined"
						startIcon={<Chat sx={{ color: 'neutral.500' }} />}
						onClick={() => {
							setPrompt('');
							setState('draft');
						}}>
						Refine
					</Button>
					<Button
						disabled={loading}
						sx={{ flexShrink: 0 }}
						variant="outlined"
						startIcon={<Repeat sx={{ color: 'neutral.500' }} />}
						onClick={() => makeRequest('Please try again', false)}>
						Regenerate
					</Button>
					<Button
						disabled={loading}
						sx={{ flexShrink: 0 }}
						variant="outlined"
						startIcon={<Delete sx={{ color: 'neutral.500' }} />}
						onClick={onClose}>
						Discard
					</Button>
				</Stack>
			)}
			{/** quick refine options */}
			<Menu
				anchorEl={quickRefineAnchor}
				open={Boolean(quickRefineAnchor)}
				onClose={() => setQuickRefineAnchor(null)}
				onClick={() => setQuickRefineAnchor(null)}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'top',
				}}
				transformOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}>
				<MenuItem onClick={() => makeRequest('Please make it more professional', false)}>
					<ListItemIcon>
						<WorkOutline />
					</ListItemIcon>
					<ListItemText>More Professional</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => makeRequest('Please make it more casual', false)}>
					<ListItemIcon>
						<EmojiEmotions />
					</ListItemIcon>
					<ListItemText>More Casual</ListItemText>
				</MenuItem>
			</Menu>
			{/** popover to view previous prompt */}
			<Popover
				anchorEl={promptPopoverAnchor}
				open={Boolean(promptPopoverAnchor)}
				onClose={() => setPromptPopoverAnchor(null)}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
				transformOrigin={{
					horizontal: 'right',
					vertical: 'top',
				}}>
				<Box p={1.5} maxWidth={800}>
					<Typography variant="body2">{previousPrompt}</Typography>
				</Box>
			</Popover>
		</Stack>
	);
};
