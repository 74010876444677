import {
	Business,
	InfoOutlined,
	LockOutlined,
	Public,
	SvgIconComponent,
} from '@mui/icons-material';
import { Chip, Stack, Tooltip, Typography } from '@mui/material';
import { AllowableFileAccessLevels, FileAccessLevel, FoldersApiEntityType } from 'middleware-types';
import { ComponentProps } from 'react';
import { useDocumentsContext } from '../documents-page';

export const accessLevelOptions = [
	FileAccessLevel.Sensitive,
	FileAccessLevel.Confidential,
	FileAccessLevel.Public,
];

export const getAccessLevelOptions = (
	allowedOptions?: (FileAccessLevel | AllowableFileAccessLevels)[]
) => {
	return accessLevelOptions.map((option) => ({
		option,
		disabled: allowedOptions && !allowedOptions.includes(option),
	}));
};

export const useDefaultAccessLevel = () => {
	const { entityType } = useDocumentsContext();
	switch (entityType) {
		case FoldersApiEntityType.Organization:
			return FileAccessLevel.Sensitive;
		default:
			return FileAccessLevel.Sensitive;
	}
};

interface AccessLevelIconProps extends ComponentProps<SvgIconComponent> {
	level: FileAccessLevel;
}

export const AccessLevelIcon = ({ level, ...props }: AccessLevelIconProps) => {
	switch (level) {
		case FileAccessLevel.Public:
			return <Public {...props} />;
		case FileAccessLevel.Confidential:
			return <Business {...props} />;
		case FileAccessLevel.Sensitive:
			return <LockOutlined {...props} />;
	}
};

export const AccessLevelChip = ({ level }: { level: FileAccessLevel }) => {
	return <Chip size="small" icon={<AccessLevelIcon level={level} />} label={level} />;
};

export const AccessLevelDescription = ({ level }: { level: FileAccessLevel }) => {
	const { entityType } = useDocumentsContext();
	switch (level) {
		case FileAccessLevel.Sensitive:
			switch (entityType) {
				case FoldersApiEntityType.Organization:
					return (
						<>
							Only associates of your organization with{' '}
							<strong>read permissions for sensitive files</strong> can see this
							document.
						</>
					);
				default:
					return <></>;
			}
		case FileAccessLevel.Confidential:
			switch (entityType) {
				case FoldersApiEntityType.Organization:
					return (
						<>
							Only associates of your organization with{' '}
							<strong>read permissions</strong> can see this document.
						</>
					);
				default:
					return <></>;
			}
		case FileAccessLevel.Public:
			switch (entityType) {
				case FoldersApiEntityType.Organization:
					return (
						<>
							All associates can <strong>view</strong> and <strong>share</strong> a
							public link to this document.
						</>
					);
				default:
					return <></>;
			}
	}
};

export const AccessLevelTooltip = (props: ComponentProps<SvgIconComponent>) => {
	const defaultLevel = useDefaultAccessLevel();
	return (
		<Tooltip
			title={
				<Stack p={0.5} spacing={2.5}>
					{accessLevelOptions.map((level) => (
						<Stack key={level} spacing={0.5}>
							<Stack direction="row" alignItems="center" spacing={1}>
								<AccessLevelIcon level={level} />
								<Typography variant="h6" color="inherit">
									{level} {level === defaultLevel && '(Default)'}
								</Typography>
							</Stack>
							<Typography variant="body2" color="inherit">
								<AccessLevelDescription level={level} />
							</Typography>
						</Stack>
					))}
				</Stack>
			}>
			<InfoOutlined {...props} />
		</Tooltip>
	);
};
