import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useSession } from 'utils/session';

export const SiteUserRedirect = ({ children }: { children: ReactNode }) => {
	const { user } = useSession();

	if (user.siteUserId) return <Navigate to="/site" />;
	return <>{children}</>;
};
