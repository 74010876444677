import { gql, useQuery } from '@apollo/client';
import {
	Info,
	InfoOutlined,
	Lock,
	LockOutlined,
	Notifications,
	NotificationsNoneOutlined,
} from '@mui/icons-material';
import { Container, Grid } from '@mui/material';
import { DelayedLoading } from 'components/ui/delayed-loading';
import { ADDRESS_FIELDS } from 'components/ui/fields/address';
import { PHONE_FIELDS } from 'components/ui/fields/phone';
import { EvolutionConnectLogoIcon } from 'components/ui/icons';
import { NavList, NavListItem } from 'components/ui/nav-list';
import { PageContent, PageTitle } from 'components/ui/page';
import { Query, QueryAccountArgs } from 'middleware-types';
import { Outlet } from 'react-router-dom';
import { PageError } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { NAME_FIELDS } from 'utils/useAccount';
import { useSiteUser } from 'utils/useSiteUser';

/**
 * Query that precaches the account.
 * @type {*}
 **/
export const ACCOUNTCACHEFILL = gql`
	${NAME_FIELDS}
	${PHONE_FIELDS}
	${ADDRESS_FIELDS}
	query account($userId: ID!) {
		account(userId: $userId) {
			id
			siteUserId
			name {
				...NameFields
			}
			handle
			homePhone {
				...PhoneFields
			}
			cellPhone {
				...PhoneFields
			}
			emailAddress
			secondaryEmailAddress
			birthDate
			languageIds
			lastUpdatedUtc
			deactivated
			emergencyContact {
				userId
				firstName
				lastName
				relationship
				cellPhone {
					...PhoneFields
				}
				otherPhone {
					...PhoneFields
				}
				email
				address {
					...AddressFields
				}
			}
			avatarFile {
				fileId
				file {
					id
					currentInstance {
						id
						cdnUrl
						fileName
						fileSize
						virusStatus
						uploadedUtc
					}
				}
			}
		}
	}
`;
/**
 * AccountScreen - The root account settings screen and routes.
 * @returns
 */
export const UserAccountOutlet = ({ userId }: { userId: string }) => {
	const { hasPermission } = useSiteUser();
	const {
		data,
		loading: accountLoading,
		error,
	} = useQuery<Pick<Query, 'account'>, QueryAccountArgs>(ACCOUNTCACHEFILL, {
		variables: {
			userId,
		},
		fetchPolicy: 'cache-and-network',
	});

	const account = data?.account;
	if (error) throw new PageError(error);
	if (accountLoading) return <DelayedLoading />;

	return (
		<>
			<PageTitle title="Account Settings" />
			<PageContent mobileNoPadding>
				<Container disableGutters>
					<Grid container spacing={{ xs: 2, sm: 4 }}>
						<Grid item xs={12} md={3}>
							<NavList title="Settings" sticky px={{ xs: 1.5, sm: 0 }}>
								<NavListItem
									activeIcon={<Info color="primary" />}
									icon={<InfoOutlined sx={{ color: 'neutral.500' }} />}
									text="Account Information"
									end
									to=""
								/>
								{account?.siteUserId && hasPermission(Permission.Site_User_R) && (
									<NavListItem
										activeIcon={
											<EvolutionConnectLogoIcon className="fill-navy-500" />
										}
										icon={
											<EvolutionConnectLogoIcon className="fill-neutral-500" />
										}
										text="Site User Settings"
										to="siteuser"
									/>
								)}
								<NavListItem
									activeIcon={<Lock color="primary" />}
									icon={<LockOutlined sx={{ color: 'neutral.500' }} />}
									text="Security"
									end
									to="security"
								/>
								{!account?.siteUserId && (
									<NavListItem
										activeIcon={<Notifications color="primary" />}
										icon={
											<NotificationsNoneOutlined
												sx={{ color: 'neutral.500' }}
											/>
										}
										text="Notifications"
										to="notifications"
									/>
								)}
							</NavList>
						</Grid>
						<Grid item xs={12} md={9}>
							<Outlet />
						</Grid>
					</Grid>
				</Container>
			</PageContent>
		</>
	);
};
