import { gql, useQuery } from '@apollo/client';
import { LegacyUserAdjustmentCompany, Query } from 'middleware-types';
import { BusinessOutlined } from '@mui/icons-material';
import {
	Avatar,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Paper,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { getAccessToken } from 'utils/session';

const useUserLegacyAdjustmentCompaniesQuery = () => {
	const { data, error, loading } = useQuery<Pick<Query, 'userLegacyAdjustmentCompanies'>>(
		gql`
			query userLegacyAdjustmentCompanies {
				userLegacyAdjustmentCompanies {
					adjustmentCompanies {
						adjCoName
						adjCoLogo
						systemUrl
						userId
						userRole
						clientCompany {
							companyName
							companyCity
							companyState
						}
					}
				}
			}
		`,
		{
			// checks cache and fetches (important for updating queries after updating a role)
			fetchPolicy: 'cache-and-network',
			onError: () => {
				console.log(error);
			},
		}
	);

	return {
		companies: data?.userLegacyAdjustmentCompanies.adjustmentCompanies || [],
		error,
		loading,
	};
};

const CompaniesSkeleton = () => (
	<>
		<Skeleton height="4.5rem" width="100%" />
		<Skeleton height="4.5rem" width="100%" />
		<Skeleton height="4.5rem" width="100%" />
		<Skeleton height="4.5rem" width="100%" />
		<Skeleton height="4.5rem" width="100%" />
	</>
);

export default function LinkedLegacyUserList() {
	const { companies, loading } = useUserLegacyAdjustmentCompaniesQuery();
	return (
		<Stack spacing={1}>
			<Typography variant="h2" marginBottom="1rem">
				Linked Companies
			</Typography>

			<List component={Stack} spacing={loading ? 1 : 2.5}>
				{loading && !!companies?.length && <CompaniesSkeleton />}
				{companies.map((company: LegacyUserAdjustmentCompany, idx) => (
					<ListItem key={idx} component={Paper} disablePadding>
						<ListItemButton
							onClick={() => postLegacyAuth(company.systemUrl, company.userId)}>
							<ListItemAvatar>
								<Avatar
									variant="square"
									sx={{ '& img': { objectFit: 'contain' } }}
									{...(company.adjCoLogo && {
										src: `${company.systemUrl}/../images/logos/${company.adjCoLogo}`,
									})}>
									<BusinessOutlined sx={{ width: '75%', height: '75%' }} />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary={company.adjCoName}
								secondary={`${company.userRole} ${
									company.clientCompany
										? `- ${company.clientCompany?.companyName}, ${company.clientCompany?.companyCity} ${company.clientCompany?.companyState}`
										: ''
								}`}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Stack>
	);
}

async function postLegacyAuth(systemUrl?: string, userId?: number) {
	const accessToken = await getAccessToken();
	const form = document.createElement('form');
	form.method = 'POST';
	form.action = `${systemUrl}/evolveLogin.asp`;

	const userIdField = document.createElement('input');
	userIdField.type = 'hidden';
	userIdField.name = 'userId';
	userIdField.value = `${userId}`;
	form.appendChild(userIdField);

	const accessTokenField = document.createElement('input');
	accessTokenField.type = 'hidden';
	accessTokenField.name = 'access_token';
	accessTokenField.value = accessToken as string;
	form.appendChild(accessTokenField);

	document.body.appendChild(form);
	form.submit();
}
