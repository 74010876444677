import { DeleteOutlined, ModeEditOutlined, SvgIconComponent } from '@mui/icons-material';
import { useMemo, useState } from 'react';
import { useDocumentsContext } from '../documents-page';

interface FolderMenuItem {
	label: string;
	icon: SvgIconComponent;
	onClick: () => void;
}

export const useFolderOptions = () => {
	const { permissions } = useDocumentsContext();

	const [renameOpen, setRenameOpen] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);

	const items = useMemo(() => {
		const items: FolderMenuItem[] = [];
		// rename
		if (permissions.update.length > 0) {
			items.push({
				label: 'Rename',
				icon: ModeEditOutlined,
				onClick: () => setRenameOpen(true),
			});
		}
		// delete
		if (permissions.delete.length > 0) {
			items.push({
				label: 'Delete',
				icon: DeleteOutlined,
				onClick: () => setDeleteOpen(true),
			});
		}
		return items;
	}, []);

	return {
		items,
		renameOpen,
		setRenameOpen,
		deleteOpen,
		setDeleteOpen,
	};
};
