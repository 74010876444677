import { DeleteOutline, EditOutlined, ForumOutlined, MoreHoriz } from '@mui/icons-material';
import {
	Avatar,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
} from '@mui/material';
import { PromptClippingResponse } from 'middleware-types';
import { useState } from 'react';
import { DeleteClippingDrawer } from './deleteClippingDrawer';

interface PromptClippingListItemProps {
	clipping: PromptClippingResponse;
	setClippingToUpdate: (value: React.SetStateAction<PromptClippingResponse | null>) => void;
	setRequestText: (value: React.SetStateAction<string>) => void;
	onClose: () => void;
}

export const PromptClippingListItem = ({
	clipping,
	setClippingToUpdate,
	setRequestText,
	onClose,
}: PromptClippingListItemProps) => {
	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
	const [deleteDrawerOpen, setDeleteDrawerOpen] = useState(false);

	const onSubmit = () => {
		setRequestText(clipping?.content ?? '');
		onClose();
	};

	return (
		<ListItem
			key={clipping.id}
			disablePadding
			secondaryAction={
				<IconButton edge="end" onClick={(e) => setMenuAnchor(e.currentTarget)}>
					<MoreHoriz />
				</IconButton>
			}>
			<ListItemButton onClick={onSubmit}>
				<ListItemAvatar>
					<Avatar>
						<ForumOutlined />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primaryTypographyProps={{
						style: {
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						},
					}}
					primary={clipping.title}
				/>
			</ListItemButton>
			{/* Menu */}
			<Menu
				open={Boolean(menuAnchor)}
				anchorEl={menuAnchor}
				onClick={() => setMenuAnchor(null)}
				onClose={() => setMenuAnchor(null)}>
				<MenuItem onClick={() => setClippingToUpdate(clipping)}>
					<ListItemIcon>
						<EditOutlined />
					</ListItemIcon>
					<ListItemText>Edit Clipping</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => setDeleteDrawerOpen(true)}>
					<ListItemIcon>
						<DeleteOutline />
					</ListItemIcon>
					<ListItemText>Delete</ListItemText>
				</MenuItem>
			</Menu>
			{/* Delete Drawer */}
			<DeleteClippingDrawer
				open={deleteDrawerOpen}
				onClose={() => setDeleteDrawerOpen(false)}
				clipping={clipping}
			/>
		</ListItem>
	);
};
