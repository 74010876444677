import { MoreHoriz, RssFeed } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { ListItemIcon, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import { FollowingIcon } from 'components/ui/icons';
import { useToast } from 'components/ui/toast';
import { UserProfile } from 'middleware-types';
import { useState } from 'react';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useSession } from 'utils/session';
import { UserProfileUserConnectionButton } from '../UserConnectionButton/wrappers';
import { useFollowUserMutation, useUnfollowUserMutation } from './SocialInteractionBar.graphql';

/**
 * SocialInteractionBar - This is a panel which shows The social buttons.
 */
export const SocialInteractionBar = (props: { profile: UserProfile }) => {
	const { user } = useSession();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setMenuOpen(true);
	};
	const follow = useFollow(props.profile);

	if (props.profile.id === user?.userId || user?.siteUserId) return <></>;
	return (
		<Stack direction="row" spacing={1}>
			<Stack direction="row" gap={1.5}>
				<UserProfileUserConnectionButton userId={props.profile.id} />
			</Stack>
			<LoadingButton
				size="medium"
				loading={follow.loading}
				variant="text"
				onClick={handleClick}
				sx={{ width: '2.5rem', minWidth: 'unset' }}>
				<MoreHoriz />
			</LoadingButton>
			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={menuOpen}
				onClose={() => setMenuOpen(false)}>
				<MenuItem
					onClick={() => {
						setMenuOpen(false);
						follow.toggleFollow();
					}}>
					<ListItemIcon>
						{!follow.isFollowing ? <RssFeed /> : <FollowingIcon />}
					</ListItemIcon>
					<ListItemText>{!follow.isFollowing ? 'Follow' : 'Following'}</ListItemText>
				</MenuItem>
			</Menu>
		</Stack>
	);
};

/**
 * useFollow(profile) - Hook to toggle the following state with a user.
 */
const useFollow = (profile: UserProfile) => {
	const { user } = useSession();
	const toast = useToast();
	const { followUser, loading: followLoad } = useFollowUserMutation(user!.userId);
	const { unfollowUser, loading: unfollowLoad } = useUnfollowUserMutation(user!.userId);
	const toggleFollow = async () => {
		const success = (res) => {
			if (responseHasErrors(res.errors, { toast })) {
				return false;
			}
			return true;
		};
		const failed = (err) => {
			console.log(err);
			handleNoResponse({ toast });
			return false;
		};

		if (profile.following) {
			return unfollowUser(profile.id).then(success).catch(failed);
		}

		return followUser(profile.id).then(success).catch(failed);
	};

	return {
		isFollowing: profile.following,
		toggleFollow,
		loading: followLoad || unfollowLoad,
	};
};
