import { gql, useMutation } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import { FileRenameRequest, Mutation, MutationFileRenameArgs } from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { FILE_INFO_FIELDS } from '../../utils/fragments.graphql';
import { addNewFileInstance } from '../../utils/cache-helpers';

const RENAME_FILE = gql`
	${FILE_INFO_FIELDS}
	mutation RenameFile($fileId: String!, $request: FileRenameRequest!) {
		fileRename(fileId: $fileId, request: $request) {
			...FileInfoFields
		}
	}
`;

export const useRenameFile = () => {
	const toast = useToast();

	const [_renameFile] = useMutation<Pick<Mutation, 'fileRename'>, MutationFileRenameArgs>(
		RENAME_FILE
	);

	const renameFile = async (fileId: string, request: FileRenameRequest) => {
		return await _renameFile({
			variables: { fileId, request },
			update: (cache, { data }) => {
				if (!data) return;
				addNewFileInstance({ cache, file: data.fileRename });
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('File renamed successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return renameFile;
};
