import { OpenInNewOutlined } from '@mui/icons-material';
import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material';
import { useOrgTeam } from '../hooks';
import { TeamMemberCard } from './team-member-card';
import { TeamMemberSkeleton } from './team-member-skeleton';

interface TeamCardProps {
	orgId: string;
	canEdit: boolean;
}

export const TeamCard = ({ orgId, canEdit }: TeamCardProps) => {
	const { teamMembers, loading } = useOrgTeam(orgId);
	return (
		<Card sx={{ borderRadius: { xs: 0, sm: 1 } }}>
			<Stack
				direction="row"
				px={{ xs: 2, sm: 2.5 }}
				py={1}
				alignItems="center"
				justifyContent="space-between">
				<Typography variant="h3">Our Team</Typography>
				{canEdit && (
					<Button
						variant="outlined"
						startIcon={<OpenInNewOutlined />}
						href="../associates">
						Manage Associates
					</Button>
				)}
			</Stack>
			<Divider />
			<Box
				p={{ xs: 2, sm: 2.5 }}
				display="grid"
				gridTemplateColumns={{
					xs: '1fr',
					md: 'repeat(2, 1fr)',
				}}
				gridAutoRows="1fr"
				gap={2.5}>
				{loading ? (
					<TeamMemberSkeleton />
				) : (
					teamMembers.map((member) => <TeamMemberCard key={member.id} member={member} />)
				)}
			</Box>
		</Card>
	);
};
