import { DevicesOutlined, ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { DevicesTable } from './devices-table';

interface DevicesCardProps {
	userId: string;
	canEdit: boolean;
}

export const DevicesCard = ({ userId, canEdit }: DevicesCardProps) => {
	const [showDevices, setShowDevices] = useState(false);
	return (
		<Card>
			<CardHeader title="Device Settings" />
			<Divider />
			<CardContent>
				<Stack spacing={2}>
					<Stack direction="row" alignItems="center" spacing={{ xs: 2, sm: 3 }}>
						<DevicesOutlined fontSize="small" />
						<Typography variant="h5" flex={1}>
							Devices
						</Typography>
						<IconButton onClick={() => setShowDevices((value) => !value)}>
							{showDevices ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
						</IconButton>
					</Stack>
					{showDevices && <DevicesTable userId={userId} canEdit={canEdit} />}
				</Stack>
			</CardContent>
		</Card>
	);
};
