import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { codeValidationWhiteSpaceDisallowed } from 'components/ui/LoginYups';
import { TextField } from 'components/ui/fields';
import { PhoneNumberField } from 'components/ui/fields/phone';
import { Form, Formik, FormikHelpers } from 'formik';
import { PhoneNumberInput } from 'middleware-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import * as Yup from 'yup';
import { useUser } from '../auth';

const Sms = ({ onBackClick }: { onBackClick: () => void }) => {
	const [phoneNumber, setPhoneNumber] = useState<PhoneNumberInput | null>(null);
	const user = useUser();
	const largeScreen = useWindowDimensions();
	const navigate = useNavigate();

	const handlePhoneNumberFormSubmit = async (
		vals: PhoneNumberForm,
		helpers: FormikHelpers<PhoneNumberForm>
	) => {
		try {
			await Auth.updateUserAttributes(user, {
				phone_number: '+' + vals.phoneNumber.countryCode + vals.phoneNumber.number,
			});
			await Auth.verifyCurrentUserAttribute('phone_number');
			setPhoneNumber(vals.phoneNumber);
		} catch (e: any) {
			helpers.setErrors({ phoneNumber: e?.message });
		}
	};

	const handleVerifyCodeFormSubmit = async (
		vals: VerifyCodeForm,
		helpers: FormikHelpers<VerifyCodeForm>
	) => {
		try {
			await Auth.verifyCurrentUserAttributeSubmit('phone_number', vals.code);
			await Auth.updateUserAttributes(user, {
				'custom:mfa_configured': 'true',
			});
			await Auth.setPreferredMFA(user, 'SMS');

			navigate('/');
		} catch (e: any) {
			helpers.setErrors({ code: e?.message });
		}
	};

	return phoneNumber ? (
		<VerifyCodeForm
			onBackClick={() => setPhoneNumber(null)}
			onSubmit={handleVerifyCodeFormSubmit}
			number={phoneNumber.number}
			largeScreen={largeScreen}
		/>
	) : (
		<PhoneNumberForm
			onSubmit={handlePhoneNumberFormSubmit}
			onBackClick={onBackClick}
			largeScreen={largeScreen}
		/>
	);
};

const PhoneNumberForm = ({
	onSubmit,
	onBackClick,
	largeScreen,
}: {
	onSubmit: (vals: PhoneNumberForm, helpers: FormikHelpers<PhoneNumberForm>) => void;
	onBackClick: () => void;
	largeScreen: boolean;
}) => {
	return (
		<Formik<PhoneNumberForm>
			initialValues={{ phoneNumber: { countryCode: '', number: '' } }}
			onSubmit={(vals, helpers) => onSubmit(vals, helpers)}
			validateOnBlur={false}
			validateOnChange={false}>
			{({ isSubmitting, errors }) => (
				<Form className="m-auto flex w-96 flex-col text-center">
					<img
						className={clsx(' mx-auto mb-10 w-32', {
							hidden: largeScreen,
						})}
						src="/img/evolve-logo.svg"
						alt="FTEvolve"
					/>
					<Typography mb={2} variant="h2" className="text-neutral-900">
						Set up SMS MFA
					</Typography>
					<Typography mb={2} className="text-neutral-900">
						Enter your phone number.
					</Typography>

					<PhoneNumberField
						name="phoneNumber"
						label="Phone Number"
						InputLabelProps={{ shrink: true }}
						required
						FormHelperTextProps={{
							className: clsx(!!errors?.phoneNumber && 'static', 'text-left mb-1'),
						}}
						sx={{
							fieldset: { borderColor: 'neutral.700' },
						}}
					/>
					<LoadingButton
						variant="contained"
						color="primary"
						className="mb-2 mt-4 text-white "
						type="submit"
						loading={isSubmitting}>
						Send Code
					</LoadingButton>
					<Button className="mt-1" onClick={onBackClick} variant="outlined">
						Back
					</Button>
				</Form>
			)}
		</Formik>
	);
};

const VerifyCodeForm = ({
	onBackClick,
	onSubmit,
	number,
	largeScreen,
}: {
	onBackClick: () => void;
	onSubmit: (vals: VerifyCodeForm, helpers: FormikHelpers<VerifyCodeForm>) => void;
	number: string;
	largeScreen: boolean;
}) => {
	return (
		<Formik<VerifyCodeForm>
			initialValues={{ code: '' }}
			onSubmit={(vals, helpers) => onSubmit(vals, helpers)}
			validationSchema={verifySmsCodeValidationSchema}
			validateOnBlur={false}
			validateOnChange={false}
			enableReinitialize>
			{({ errors, isSubmitting, setErrors }) => (
				<Form className="m-auto flex w-96 flex-col text-center">
					<img
						className={clsx(' mx-auto mb-10 w-32', {
							hidden: largeScreen,
						})}
						src="/img/evolve-logo.svg"
						alt="FTEvolve"
					/>
					<Typography mb={2} variant="h2" className="text-neutral-900">
						Set up SMS MFA
					</Typography>
					<Typography mb={2} className="text-neutral-900">
						We have delivered the authentication code by SMS to +******{number.slice(6)}
						. Please enter the code to complete authentication.
					</Typography>
					<TextField
						name="code"
						label="Code"
						required
						InputLabelProps={{ shrink: true }}
						error={!!errors.code}
						helperText={errors.code}
						onChangeCapture={() =>
							setErrors({
								...errors,
								code: undefined,
							})
						}
						FormHelperTextProps={{
							className: clsx(!!errors?.code && 'static', 'text-left mb-1'),
						}}
						sx={{
							fieldset: { borderColor: 'neutral.700' },
						}}
						focused
					/>
					<LoadingButton
						loading={isSubmitting}
						variant="contained"
						color="primary"
						className="mb-2 mt-6 text-white"
						type="submit">
						Log In
					</LoadingButton>
					<Button onClick={onBackClick} variant="outlined">
						Back
					</Button>
				</Form>
			)}
		</Formik>
	);
};

const verifySmsCodeValidationSchema = Yup.object({
	code: codeValidationWhiteSpaceDisallowed,
});

type PhoneNumberForm = { phoneNumber: PhoneNumberInput };
type VerifyCodeForm = { code: string };

export default Sms;
