import {
	Box,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { NotificationSettingDefaultGroupResponse } from 'middleware-types';
import { NotificationSettingsGroup } from './notification-settings-group';
import { InfoOutlined } from '@mui/icons-material';

export type NotificationTableColumns = 'methods' | 'emailDigest' | 'all';

interface NotificationSettingsTable {
	defaults: NotificationSettingDefaultGroupResponse[];
	columns: NotificationTableColumns;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NotificationSettingsTable = ({ defaults, columns }: NotificationSettingsTable) => {
	return (
		<TableContainer sx={{ border: 'none' }}>
			<Table
				sx={{
					'& .MuiTableCell-root': {
						borderBottom: 'none',
						px: 0,
						py: 1,
					},
				}}>
				<TableBody>
					{columns === 'all' && (
						<TableRow sx={{ verticalAlign: 'bottom' }}>
							<TableCell>
								<Box px={{ xs: 2, sm: 2.5 }}>
									<Typography variant="h5">Notification Types</Typography>
									<Typography variant="subtitle2">
										Opt out of specific notifications below.
									</Typography>
								</Box>
							</TableCell>
							<TableCell align="center">
								<Typography variant="h5">In App</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography variant="h5">SMS</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography variant="h5">Email</Typography>
							</TableCell>
							<TableCell align="center">
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="center"
									spacing={1}>
									<Typography variant="h5">Email Digest</Typography>
									<Tooltip title="A user curated list of notifications in your inbox exactly when you want them.">
										<InfoOutlined sx={{ color: 'neutral.500' }} />
									</Tooltip>
								</Stack>
							</TableCell>
						</TableRow>
					)}
					{defaults.map((group) => (
						<NotificationSettingsGroup
							key={group.groupName}
							group={group}
							columns={columns}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
