import { useState, useEffect } from 'react';
import { Loading } from './loading';

export const DelayedLoading = ({ timeout = 500 }: { timeout?: number }) => {
	const [showSpinner, setShowSpinner] = useState(false);
	useEffect(() => {
		const timer = setTimeout(() => setShowSpinner(true), timeout);
		return () => clearTimeout(timer);
	}, [timeout]);

	return showSpinner ? <Loading /> : <></>;
};
