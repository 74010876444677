import { gql, useQuery } from '@apollo/client';
import { Language, Query } from 'middleware-types';
import { PageError } from 'utils/errors';

/**
 * useLanguages() - Hook returns an array of languages.
 *
 * @return {*}  {{
 * 	loading: boolean;
 * 	languages: QLanguage[];
 * }}
 */
export const useLanguages = (): {
	loading: boolean;
	languages: Language[];
} => {
	const { loading, error, data } = useQuery<Pick<Query, 'languages'>>(gql`
		query languages {
			languages {
				id
				name
				code
			}
		}
	`);

	if (error) throw new PageError(error.graphQLErrors);
	const languages = data?.languages ?? [];

	return { loading, languages };
};
