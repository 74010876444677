import { Card, Skeleton, Stack } from '@mui/material';

export const TeamMemberSkeleton = () => {
	return (
		<Card variant="outlined">
			<Stack p={3} spacing={1} alignItems="center">
				<Skeleton variant="circular" height={80} width={80} />
				<Stack alignItems="center" alignSelf="stretch">
					<Skeleton variant="text" width="50%" />
					<Skeleton variant="text" width="70%" />
				</Stack>
			</Stack>
		</Card>
	);
};
