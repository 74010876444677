import { LoadingButton } from '@mui/lab';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	useModal,
} from 'components/ui/modal';
import { useEmptyTrash } from '../hooks/conversation-labels-hooks';
import { ErrorOutlined } from '@mui/icons-material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';

export const EmptyTrashModal = () => {
	const { closeModal } = useModal();
	const { emptyTrash, loading } = useEmptyTrash();

	const onSubmit = () => {
		emptyTrash().then((success) => {
			if (!success) return;
			closeModal();
		});
	};

	return (
		<>
			<ConfirmModalContent
				visual={
					<EmptyStateAvatar
						avatarProps={{ bgcolor: 'error.50' }}
						iconProps={{ color: 'error.500' }}
						icon={<ErrorOutlined />}
					/>
				}
				subheadline="Empty Trash?"
				informativeContent="All conversations will be permanently deleted from your inbox. This cannot be undone."
			/>
			<ModalActions>
				<ModalActionButton size="large" variant="outlined">
					Cancel
				</ModalActionButton>
				<LoadingButton
					size="large"
					variant="contained"
					color="error"
					loading={loading}
					onClick={onSubmit}>
					Empty
				</LoadingButton>
			</ModalActions>
		</>
	);
};
