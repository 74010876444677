import { gql, useQuery } from '@apollo/client';
import { Container, Stack, Typography } from '@mui/material';
import { EvoxConversationDisplay } from 'components/pages/site/evox/conversation-display';
import { useChatLastMessagesByUser } from 'components/pages/site/evox/hooks';
import { EvoxMessageList } from 'components/pages/site/evox/message-list';
import { PageTitle } from 'components/ui/page';
import { EmblemEntityType, Query, QueryEmblemArgs, UserChatMessage } from 'middleware-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageError } from 'utils/errors';
import { MessagesListSkeleton } from './review';

export const UserEvoxConversationsPage = () => {
	const { id } = useParams();
	if (id === undefined) throw PageError;

	const { data } = useQuery<Pick<Query, 'emblem'>, QueryEmblemArgs>(
		gql`
			query GetUserName($entityType: EmblemEntityType!, $entityId: ID!) {
				emblem(entityType: $entityType, entityId: $entityId) {
					id
					displayName
				}
			}
		`,
		{ variables: { entityType: EmblemEntityType.User, entityId: id } }
	);

	const userName = data?.emblem.displayName ?? '';

	const { messages, loading } = useChatLastMessagesByUser(id);
	const [selectedMessage, setSelectedMessage] = useState<UserChatMessage | null>(null);

	return (
		<Stack direction="column" height="92%" overflow="auto" padding={2}>
			{loading ? (
				<MessagesListSkeleton />
			) : (
				<>
					<PageTitle title="EVO-X User Conversations" />
					<Container maxWidth="xl" sx={{ height: '100%' }}>
						<Stack direction="row" spacing={4} height={'100%'} justifyContent="center">
							<Stack spacing={2} width={500}>
								<Typography variant="h2">
									{userName}&apos;s EVO-X Conversations
								</Typography>
								<EvoxMessageList
									loading={loading}
									messages={messages}
									selectedMessageId={selectedMessage?.chatMessageId}
									onMessageSelected={(message) => setSelectedMessage(message)}
								/>
							</Stack>
							<EvoxConversationDisplay
								conversationId={selectedMessage?.chatConversationId}
							/>
						</Stack>
					</Container>
				</>
			)}
		</Stack>
	);
};
