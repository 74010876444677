import {
	Box,
	Grid,
	MenuItem,
	Link as MuiLink,
	TextField as MuiTextField,
	Typography,
} from '@mui/material';
import { PhoneNumberField, SelectField, TextField } from 'components/ui/fields';
import { Address, EmployeeCount, Industry, OrganizationProfile } from 'middleware-types';
import { employeeCountToString, industryToString } from './enum-display';
import { Link } from 'react-router-dom';
import { useCountries } from 'utils/useCountries';

interface OrgAboutFormProps {
	profile: OrganizationProfile;
}

const useSingleLineAddressText = (address: Address | undefined) => {
	const { loading, countries } = useCountries();

	if (!address) return '';

	let stateOrProvince = '';
	let country = '';

	if (!loading && countries) {
		country = countries.find((c) => c.id === address.countryId)?.name ?? '';
		stateOrProvince =
			countries
				.find((c) => c.id === address.countryId)
				?.addressFieldLookups?.find((s) => s.id === address.adminArea1Id)?.value ?? '';
	}

	return (
		(address.address1 ?? '') +
		(address.address2 ? ', ' + address.address2 : '') +
		(address.municipality ? ', ' + address.municipality : '') +
		(stateOrProvince ? ', ' + stateOrProvince : '') +
		(address.adminArea2?.displayName ? ', ' + address.adminArea2 : '') +
		(address.postalCode ? ' ' + address.postalCode : '')
	);
};

export const OrgAboutForm = ({ profile }: OrgAboutFormProps) => {
	const specialties = profile.businessLines?.filter((line) => line.specialty) ?? [];
	const specialtiesText = specialties.map((s) => s.line ?? '').join(', ');

	const primaryAddressText = useSingleLineAddressText(profile.primaryAddress);

	return (
		<Box p={2.5}>
			<Grid container rowSpacing={0.5} columnSpacing={1.5}>
				<Grid item xs={12}>
					<MuiTextField
						value={primaryAddressText}
						label="Primary Address"
						required
						disabled
					/>
					<Typography variant="caption">
						Edit your primary address in{' '}
						<MuiLink component={Link} to="../account/">
							Account Settings.
						</MuiLink>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField name="websiteUrl" label="Website" />
				</Grid>
				<Grid item xs={12} sm={6}>
					<PhoneNumberField label="Phone" name="displayPhoneNumber" />
				</Grid>
				<Grid item xs={12} sm={6}>
					<SelectField name="industry" label="Industry">
						{Object.values(Industry).map((option) => (
							<MenuItem key={option} value={option}>
								{industryToString(option)}
							</MenuItem>
						))}
					</SelectField>
				</Grid>
				<Grid item xs={12} sm={6}>
					<SelectField name="numberOfEmployees" label="Company Size">
						{Object.values(EmployeeCount).map((option) => (
							<MenuItem key={option} value={option}>
								{employeeCountToString(option)}
							</MenuItem>
						))}
					</SelectField>
				</Grid>
				<Grid item xs={12}>
					<TextField name="yearFounded" label="Founding Year" type="number" />
				</Grid>
				<Grid item xs={12}>
					<MuiTextField value={specialtiesText} label="Specialties" disabled />
					<Typography variant="caption">
						This is based on your lines of business. Edit your specialties in the Lines
						of Business tab.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField name="about" label="About" multiline />
				</Grid>
			</Grid>
		</Box>
	);
};
