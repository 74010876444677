import { CommunicationsPage } from 'components/pages/communications/communications-page';
import { ConversationApiEntityType } from 'middleware-types';
import { useSession } from 'utils/session';

export const UserMessagesPage = () => {
	const { user } = useSession();
	return (
		<CommunicationsPage entityType={ConversationApiEntityType.Users} entityId={user.userId} />
	);
};
