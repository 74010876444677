import {
	AccountCircleOutlined,
	DeleteOutline,
	EditOutlined,
	MoreHorizOutlined,
	PowerSettingsNewOutlined,
} from '@mui/icons-material';
import {
	Box,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';
import { OrgMessageButton } from 'components/pages/network/org/OrgMessageButton/OrgMessageButton';
import { UserMessageButton } from 'components/pages/network/user/UserMessageButton/UserMessageButton';
import { Status } from 'components/pages/org/associates/hooks';
import {
	useDeactivateAssociateModal,
	useReactivateAssociateModal,
} from 'components/pages/org/associates/modals/activation';
import { useDeleteAssociateInvitationModal } from 'components/pages/org/associates/modals/invitation';
import { useUpdateAssociateModal } from 'components/pages/org/associates/modals/update';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { DeactivateIcon } from 'components/ui/icons';
import { AssociateOrInvitation, EmblemEntityType } from 'middleware-types';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';

/**
 *  Associate row props.
 */
type AssociateRowProps = {
	orgId: string;
	associate: AssociateOrInvitation;
};

/**
 * This defines all the colors used for the status text.
 * It is a mapping of Status -> MUI Theme color (see Theme.tsx)
 * @type {Record<Status, string>} Map of Status to MUI theme color string.
 */
const statusColor: Record<Status, string> = {
	Invited: 'neutral.200',
	Active: 'primary.dark',
	Inactive: 'error.dark',
	Declined: 'error.dark',
};

/**
 * AssociateRow - A single row on the organization associates table.
 *
 * @param {AssociateRowProps} props
 * @returns
 */
export const AssociateRow = ({ orgId, associate }: AssociateRowProps) => {
	const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
	const { user } = useSession();
	const { handle } = useParams<{
		handle: string;
	}>();
	const associateUserId = associate.userId ? associate.userId : '';

	const currentUser = user?.userId === associate.userId;
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const canUpdate =
		hasAssociateUserPermission(Permission.Org_Assoc_U) ||
		hasSiteUserPermission(Permission.Site_OrgAssoc_U);

	const canMessageAsOrg = hasAssociateUserPermission(Permission.Org_Messages_U);

	const canDelete =
		hasAssociateUserPermission(Permission.Org_Assoc_Inv_D) ||
		hasSiteUserPermission(Permission.Site_OrgAssocInv_D);

	const handleAssociateActionsMenuClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): void => setAnchorEl(event.currentTarget);

	const { updateAssociate } = useUpdateAssociateModal({
		orgId,
		associate,
	});

	const { openDeleteInvitation } = useDeleteAssociateInvitationModal(
		orgId,
		associate.invitationId!,
		associate.displayName
	);

	const { openDeactivateAssociate } = useDeactivateAssociateModal(
		orgId,
		associate.associateId!,
		associate.displayName
	);

	const { reactivateAssociate } = useReactivateAssociateModal({
		orgId,
		associate,
	});

	const handleUpdateAssociateClick = (): void => {
		setAnchorEl(null);
		updateAssociate();
	};

	const handleDeactivateAssociateClick = (): void => {
		setAnchorEl(null);
		openDeactivateAssociate();
	};

	const handleReactivateAssociateClick = (): void => {
		setAnchorEl(null);
		reactivateAssociate();
	};

	const displayOnProfile =
		associate.status === Status.Active ? (associate.displayOnProfile ? 'Yes' : 'No') : 'N/A';

	const isUserDeactivated = associate.deactivated;

	const showUserMessageButton =
		associate.status === Status.Active &&
		user.userId !== associate.userId &&
		!isUserDeactivated;

	const showOrganizationMessageButton =
		associate.status === Status.Active && !isUserDeactivated && canMessageAsOrg;

	return (
		<TableRow>
			<TableCell>
				<Stack flexDirection="row" gap={2} alignItems="center">
					{associate.userId && associate.status !== 'Invited' ? (
						<UserEmblemAvatar id={associate.userId} />
					) : (
						<Box sx={{ width: 40 }} />
					)}
					<Stack>
						<Box>{associate.externalUserDisplayName}</Box>
						<Typography variant="subtitle2">
							{associate.displayName != associate.externalUserDisplayName &&
								associate.displayName}
						</Typography>
					</Stack>
				</Stack>
			</TableCell>
			<TableCell>
				<Stack flexDirection="row" alignItems="center" gap={1}>
					{associate.status === 'Invited' && associate.emailAddress}
					{showUserMessageButton && (
						<UserMessageButton
							entityId={associateUserId}
							entityType={EmblemEntityType.User}
							handle={handle}
							alwaysEnabled
						/>
					)}
					{showOrganizationMessageButton && (
						<OrgMessageButton
							entityId={associateUserId}
							entityType={EmblemEntityType.User}
							orgHandle={handle!}
							orgId={orgId}
						/>
					)}
				</Stack>
			</TableCell>
			<TableCell>{associate.organizationRoleName}</TableCell>
			<TableCell
				sx={{
					color: statusColor[associate.status],
				}}>
				{associate.status}
			</TableCell>
			<TableCell>{displayOnProfile}</TableCell>
			<TableCell size="small">
				{associate.associateId && associate.status != 'Invited' && (
					<>
						<IconButton onClick={handleAssociateActionsMenuClick}>
							<MoreHorizOutlined />
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={(): void => setAnchorEl(null)}>
							<MenuItem component={Link} to={`/${associate.externalUserHandle}`}>
								<ListItemIcon>
									<AccountCircleOutlined />
								</ListItemIcon>
								<ListItemText>View Profile</ListItemText>
							</MenuItem>
							{associate.status === 'Active' && canUpdate && (
								<MenuItem onClick={handleUpdateAssociateClick}>
									<ListItemIcon>
										<EditOutlined />
									</ListItemIcon>
									<ListItemText>Update Associate</ListItemText>
								</MenuItem>
							)}
							{associate.status === 'Active' && !currentUser && canUpdate && (
								<MenuItem onClick={handleDeactivateAssociateClick}>
									<ListItemIcon>
										<DeactivateIcon />
									</ListItemIcon>
									<ListItemText>Deactivate Associate</ListItemText>
								</MenuItem>
							)}
							{associate.status === 'Inactive' && !currentUser && canUpdate && (
								<MenuItem onClick={handleReactivateAssociateClick}>
									<ListItemIcon>
										<PowerSettingsNewOutlined />
									</ListItemIcon>
									<ListItemText>Reactivate Associate</ListItemText>
								</MenuItem>
							)}
						</Menu>
					</>
				)}
				{canDelete && associate.invitationId && (
					<>
						<IconButton onClick={handleAssociateActionsMenuClick}>
							<MoreHorizOutlined />
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={(): void => setAnchorEl(null)}>
							<MenuItem onClick={openDeleteInvitation}>
								<ListItemIcon>
									<DeleteOutline />
								</ListItemIcon>
								<ListItemText>Delete Invitation</ListItemText>
							</MenuItem>
						</Menu>
					</>
				)}
			</TableCell>
		</TableRow>
	);
};
