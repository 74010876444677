import { LoadingButton } from '@mui/lab';
import { Alert, Button, Divider, Link, Stack, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { codeValidationWhiteSpaceAllowed } from 'components/ui/LoginYups';
import { TextField } from 'components/ui/fields';
import { Loading } from 'components/ui/loading';
import { Form, Formik, FormikHelpers } from 'formik';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import * as Yup from 'yup';
import { useUser } from '../auth';

const Authenticator = ({ onBackClick }: { onBackClick: () => void }) => {
	const user = useUser();
	const largeScreen = useWindowDimensions();
	// Code returned from Cognito, used to configure the Authenticator app
	const [secretCode, setSecretCode] = useState<string | null>(null);
	const [showSecretCode, setShowSecretCode] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		const getSecretCode = async () => {
			try {
				const secretCodeRes = await Auth.setupTOTP(user);
				setSecretCode(secretCodeRes);
				setLoading(false);
			} catch (e) {
				console.error(e);
				setError(true);
			}

			setLoading(false);
		};

		getSecretCode();
	}, []);

	const handleSubmit = async (
		vals: AuthenticatorCodeForm,
		helpers: FormikHelpers<AuthenticatorCodeForm>
	) => {
		try {
			await Auth.verifyTotpToken(user, vals.code);
			await Auth.setPreferredMFA(user, 'TOTP');
			await Auth.updateUserAttributes(user, {
				'custom:mfa_configured': 'true',
			});

			navigate('/');
		} catch (e: any) {
			helpers.setErrors({ code: e?.message });
		}
	};

	if (loading) return <Loading />;

	if (error)
		return (
			<Stack spacing={2} margin="auto" width={96 * 4}>
				<Alert severity="error">There was an error fetching your authenticator code.</Alert>
				<Button onClick={onBackClick} variant="outlined">
					Back
				</Button>
			</Stack>
		);

	return (
		<Formik<AuthenticatorCodeForm>
			initialValues={{ code: '' }}
			onSubmit={async (vals, helpers) => {
				vals.code = vals.code.replace(/\s/g, '');
				await handleSubmit(vals, helpers);
			}}
			enableReinitialize
			validationSchema={validationSchema}
			validateOnBlur={false}
			validateOnChange={false}>
			{({ isSubmitting, errors }) => (
				<Form className="m-auto flex w-96 flex-col text-center">
					<img
						className={clsx(' mx-auto mb-10 w-32', {
							hidden: largeScreen,
						})}
						src="/img/evolve-logo.svg"
						alt="FTEvolve"
					/>
					<Typography mb={2} variant="h2" className="text-neutral-900">
						Set Up Authenticator App MFA
					</Typography>
					<div className="my-4 flex items-center gap-8">
						<Typography mb={2} variant="h4" className="text-neutral-900">
							1
						</Typography>
						<img src="/img/app-mfa.png" alt="Mfa App" />
						<Typography mb={2} variant="h5" className="text-left text-neutral-600">
							Install an authenticator app on your mobile device.
						</Typography>
					</div>

					<Divider />
					<div className="my-4 flex items-center gap-8">
						<Typography mb={2} variant="h4" className="text-neutral-900">
							2
						</Typography>
						<QRCodeSVG
							value={
								'otpauth://totp/FileTrac Evolve' +
								(import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'production'
									? ` (${import.meta.env.VITE_SENTRY_ENVIRONMENT})`
									: '') +
								`:` +
								user.attributes.email +
								'?secret=' +
								secretCode +
								'&issuer=' +
								'FileTrac Evolve'
							}
						/>
						<Typography
							mb={2}
							variant="h5"
							className="w-52 break-words text-left text-neutral-600">
							Scan this QR code with your authenticator app. Alternatively, you can
							manually enter a secret key in your authenticator app.
							<br />
							<Link
								onClick={() => setShowSecretCode(!showSecretCode)}
								className="mt-4 block">
								Show secret key
							</Link>
							{showSecretCode && secretCode}
						</Typography>
					</div>
					<Divider />
					<div className="my-4 flex items-center gap-8">
						<Typography mb={2} variant="h4" className="text-neutral-900">
							3
						</Typography>

						<Typography mb={2} variant="h5" className="text-neutral-600">
							Enter a code from your authenticator app.
						</Typography>
					</div>
					<TextField
						name="code"
						label="Code"
						InputLabelProps={{ shrink: true }}
						required
						error={!!errors.code}
						helperText={errors.code}
						FormHelperTextProps={{
							className: clsx(!!errors?.code && 'static', 'text-left mb-1'),
						}}
						sx={{
							fieldset: { borderColor: 'neutral.700' },
						}}
					/>
					<LoadingButton
						loading={isSubmitting}
						variant="contained"
						color="primary"
						className="mb-2 mt-6 text-white "
						type="submit">
						Sign In
					</LoadingButton>
					<Button onClick={onBackClick} variant="outlined">
						Back
					</Button>
				</Form>
			)}
		</Formik>
	);
};

type AuthenticatorCodeForm = {
	code: string;
};

const validationSchema = Yup.object({
	code: codeValidationWhiteSpaceAllowed,
});

export default Authenticator;
