import { Skeleton, Stack } from '@mui/material';

export const LabelsColumnSkeleton = () => {
	return (
		<Stack spacing={2.5}>
			<Skeleton variant="text" />
			<Skeleton variant="rounded" height={40} />
			{[...Array(4)].map((_, index) => (
				<Stack key={index} direction="row" spacing={2} alignItems="center">
					<Skeleton variant="rounded" height={25} width={25} />
					<Skeleton variant="text" sx={{ flex: 1 }} />
				</Stack>
			))}
		</Stack>
	);
};
