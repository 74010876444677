import { FolderOutlined, UploadOutlined } from '@mui/icons-material';
import { Card, CardActionArea, Dialog, Divider, Snackbar, Stack, Typography } from '@mui/material';
import { FileDropOutline } from 'components/ui/file-drop-outline';
import { FolderReference } from 'middleware-types';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDocumentsContext } from '../documents-page';
import { useCurrentFolderId } from '../hooks/use-current-folder-id';
import { UploadModal } from '../modals/upload-modal';
import { FolderMenuButton } from './folder-menu-button';
import { FolderLink } from './folder-link';

interface FolderGridItemProps {
	folder: FolderReference;
}

export const FolderGridItem = ({ folder }: FolderGridItemProps) => {
	const [, setCurrentFolderId] = useCurrentFolderId();
	const { permissions } = useDocumentsContext();
	const [uploadModalOpen, setUploadModalOpen] = useState(false);

	const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
	const onDropAccepted = (files: File[]) => {
		setFilesToUpload(files);
		setUploadModalOpen(true);
	};

	const { getRootProps, isDragActive } = useDropzone({
		onDropAccepted,
		noClick: true,
		noDragEventsBubbling: true,
		disabled: permissions.update.length === 0,
	});

	return (
		<>
			<Card
				variant="outlined"
				sx={{ position: 'relative', height: '100%' }}
				{...getRootProps()}>
				<CardActionArea
					component="div"
					onClick={() => setCurrentFolderId(folder.id)}
					sx={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'stretch',
					}}>
					<Stack
						alignItems="center"
						justifyContent="center"
						height={{ xs: 112, sm: 196 }}
						bgcolor="neutral.50">
						<FolderOutlined sx={{ color: 'neutral.500', fontSize: 48 }} />
					</Stack>
					<Divider />
					<Stack
						direction="row"
						alignItems="center"
						spacing={1.5}
						px={2}
						py={0.75}
						flex={1}>
						<FolderOutlined sx={{ color: 'neutral.500' }} />
						<Stack flex={1} overflow="hidden">
							<Typography variant="body1" noWrap>
								{folder.name}
							</Typography>
							<FolderLink folder={folder.parentFolder} />
						</Stack>
						<FolderMenuButton folder={folder} />
					</Stack>
				</CardActionArea>
				{/** file drop outline */}
				{isDragActive && <FileDropOutline />}
			</Card>
			{/** snackbar */}
			<Snackbar
				open={isDragActive}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				message={
					<Stack direction="row" alignItems="center">
						<UploadOutlined sx={{ mr: 1 }} />
						Drop file(s) to upload to &quot;{folder.name}&quot;
					</Stack>
				}
				sx={{ '&.MuiSnackbar-root': { bottom: 48 } }}
			/>
			{/** upload files modal */}
			<Dialog open={uploadModalOpen} fullWidth maxWidth="md">
				<UploadModal
					files={filesToUpload}
					folderId={folder.id}
					folderName={folder.name}
					onClose={() => setUploadModalOpen(false)}
				/>
			</Dialog>
		</>
	);
};
