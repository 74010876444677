import { CircularProgress, Stack, Typography } from '@mui/material';

export const EvoxPlaceholderBox = ({ loading }: { loading: boolean }) => {
	return (
		<Stack
			p={2}
			flex={1}
			alignItems="center"
			justifyContent="center"
			borderRadius={1}
			border="1px solid"
			borderColor="neutral.200"
			overflow="hidden">
			{loading ? (
				<CircularProgress />
			) : (
				<>
					<Typography variant="h5">No output yet.</Typography>
					<Typography variant="body2">EVO-X output will appear here.</Typography>
				</>
			)}
		</Stack>
	);
};
