import { InfoOutlined, ManageSearchOutlined } from '@mui/icons-material';
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { OrgSystemSearchResults } from 'components/pages/search/org-system-search';
import { useOrgSystemSearch } from 'components/pages/search/org-system-search-hooks';
import { usePeopleSystemSearch } from 'components/pages/search/people-system-search.hooks';
import { SiteUserInvitationSystemSearchResults } from 'components/pages/search/site-user-invitation-system-search';
import { SiteUserSystemSearchResults } from 'components/pages/search/site-user-system-search';
import { SocialUserSystemSearchResults } from 'components/pages/search/social-user-system-search';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { PageContent, PageTitle } from 'components/ui/page';
import { useToast } from 'components/ui/toast';
import { Formik, FormikProps } from 'formik';
import { ActivationStatus, UserType } from 'middleware-types';
import React, { useState } from 'react';
import { Permission } from 'utils/permissions';
import { useSiteUser } from 'utils/useSiteUser';
import { SearchFields } from './search-fields';

/**
 * The org system search type.
 *
 * @enum {number}
 */
export enum OrgType {
	Organization = 'Organization',
}

/**
 * The possible entity system search types.
 *
 * @enum {number}
 */
export const EntitySystemSearchType = { ...UserType, ...OrgType };

/**
 * The entity status.
 *
 * @enum {number}
 */
export enum Status {
	Invited = 'Invited',
	Active = 'Active',
	Inactive = 'Inactive',
	SocialActive = 'SocialActive',
	SocialInactive = 'SocialInactive',
}

export enum EntityType {
	SiteUser = 'Site Users',
	SocialUser = 'Social Users',
	Organization = 'Organizations',
	SiteUserInvitation = 'Invited Site Users',
}

/**
 * Entity search form values.
 */
export type EntitySearchFormValues = {
	entitySystemSearchType: UserType | OrgType;
	searchText: string;
	activationStatus: (ActivationStatus | '')[];
	affiliation?: string;
	companyName?: string;
	location?: string;
};

/**
 * This defines all the colors used for the status text.
 * It is a mapping of Status -> MUI Theme color (see Theme.tsx)
 * @type {Record<Status, string>} Map of Status to MUI theme color string.
 */
export const statusColor: Record<'Active' | 'Inactive', string> = {
	Active: 'primary.dark',
	Inactive: 'error.dark',
};

const useInitialValues = () => {
	const { hasPermission } = useSiteUser();

	const initialValues: EntitySearchFormValues = {
		entitySystemSearchType: EntitySystemSearchType.SocialUser,
		searchText: '',
		activationStatus: [''],
		affiliation: '',
		companyName: '',
		location: '',
	};

	// Updates the initial User Type dropdown value based on user permissions. This ensures the
	// correct filter data is shown.
	if (
		hasPermission(Permission.Site_User_R) &&
		!hasPermission([Permission.SocialUser_Account_R, Permission.SocialUser_Profile_R])
	) {
		initialValues.entitySystemSearchType = EntitySystemSearchType.SiteUser;
	} else if (
		hasPermission(Permission.Site_Inv_R) &&
		!hasPermission([
			Permission.Site_User_R,
			Permission.SocialUser_Account_R,
			Permission.SocialUser_Profile_R,
		])
	) {
		initialValues.entitySystemSearchType = EntitySystemSearchType.SiteUserInvitation;
	} else if (
		hasPermission(Permission.Site_OrgAcct_R || Permission.Site_OrgProfile_R) &&
		!hasPermission([
			Permission.Site_User_R,
			Permission.SocialUser_Account_R,
			Permission.SocialUser_Profile_R,
			Permission.Site_Inv_R,
		])
	) {
		initialValues.entitySystemSearchType = EntitySystemSearchType.Organization;
	}

	return { initialValues };
};

/**
 * Renders the system search page.
 */
const SystemSearch: React.FC = () => {
	// When the dropdown changes, we don't want to show the user the
	// previous query results so we need to know what the last user type that was queried was.
	const [queryEntityType, setQueryEntityType] = useState('');
	const {
		search: peopleSearch,
		loading: peopleLoading,
		error: peopleError,
		rows: peopleRows,
	} = usePeopleSystemSearch();
	const {
		search: orgSearch,
		loading: orgLoading,
		error: orgError,
		rows: orgRows,
	} = useOrgSystemSearch();
	const toast = useToast();

	const { initialValues } = useInitialValues();

	if (peopleError || orgError) {
		toast.push('An error occurred. Please try again later or contact Support.', {
			variant: 'error',
		});
	}

	const loading = peopleLoading || orgLoading;
	const error = peopleError || orgError;
	const entityType = EntityType[queryEntityType];

	return (
		<>
			<PageTitle title="Search" />
			<PageContent>
				<Formik<EntitySearchFormValues>
					initialValues={initialValues}
					onSubmit={async (values) => {
						setQueryEntityType(values.entitySystemSearchType);
						if (
							Object.values(UserType).includes(
								values.entitySystemSearchType as UserType
							)
						) {
							await peopleSearch(values);
						}
						if (
							Object.values(OrgType).includes(
								values.entitySystemSearchType as OrgType
							)
						) {
							await orgSearch(values);
						}
					}}>
					{(props: FormikProps<EntitySearchFormValues>) => (
						<>
							<Card
								sx={{
									height: '100%',
									display: 'flex',
									flexDirection: 'column',
								}}>
								<CardHeader
									title="Search"
									action={
										<Stack direction="row" spacing={2} alignItems="center">
											<Tooltip
												title='To search only within an email, prefix the search term with "@", i.e. @example or @filetrac both match example@filetrac.net'
												arrow>
												<InfoOutlined fontSize="small" />
											</Tooltip>
											<SearchFields />
											<Button
												size="medium"
												color={'primary'}
												variant="contained"
												disabled={
													props.isSubmitting || !props.values.searchText
												}
												onClick={() => props.submitForm()}>
												Search
											</Button>
										</Stack>
									}></CardHeader>
								{!loading && !error
									? peopleRows &&
									  peopleRows.length !== 0 &&
									  props.values.entitySystemSearchType ==
											EntitySystemSearchType.SiteUser &&
									  queryEntityType == props.values.entitySystemSearchType && (
											<SiteUserSystemSearchResults rows={peopleRows} />
									  )
									: !error && <SkeletonTable rows={5} />}
								{!loading && !error
									? peopleRows &&
									  peopleRows.length !== 0 &&
									  props.values.entitySystemSearchType ==
											EntitySystemSearchType.SocialUser &&
									  queryEntityType == props.values.entitySystemSearchType && (
											<SocialUserSystemSearchResults rows={peopleRows} />
									  )
									: !error && <SkeletonTable rows={5} />}
								{!loading && !error
									? peopleRows &&
									  peopleRows.length !== 0 &&
									  props.values.entitySystemSearchType ==
											EntitySystemSearchType.SiteUserInvitation &&
									  queryEntityType === props.values.entitySystemSearchType && (
											<SiteUserInvitationSystemSearchResults
												rows={peopleRows}
											/>
									  )
									: !error && <SkeletonTable rows={5} />}
								{!loading && !error
									? orgRows &&
									  orgRows.length !== 0 &&
									  props.values.entitySystemSearchType ==
											EntitySystemSearchType.Organization &&
									  queryEntityType === props.values.entitySystemSearchType && (
											<OrgSystemSearchResults rows={orgRows} />
									  )
									: !error && <SkeletonTable rows={5} />}

								{(!queryEntityType ||
									queryEntityType !== props.values.entitySystemSearchType) &&
									!loading &&
									!error && (
										<Stack
											width="50%"
											m="auto"
											alignItems="center"
											spacing={1}
											p={4}>
											<EmptyStateAvatar
												icon={<ManageSearchOutlined />}
												avatarProps={{ bgcolor: 'primary.50' }}
												iconProps={{ color: 'primary.500' }}
											/>
											<Typography variant="h3">
												Looking for something?
											</Typography>
											<Typography variant="body1" textAlign="center">
												Use the search, entity type, and filter criteria to
												find what you&apos;re looking for.
											</Typography>
										</Stack>
									)}
								{((queryEntityType === props.values.entitySystemSearchType &&
									((Object.values(UserType).includes(
										queryEntityType as UserType
									) &&
										peopleRows &&
										peopleRows.length === 0) ||
										(Object.values(OrgType).includes(
											queryEntityType as OrgType
										) &&
											orgRows &&
											orgRows.length === 0)) &&
									!loading) ||
									error) && (
									<Stack
										width="50%"
										m="auto"
										alignItems="center"
										spacing={1}
										p={4}>
										<EmptyStateAvatar
											icon={<ManageSearchOutlined />}
											avatarProps={{ bgcolor: 'primary.50' }}
											iconProps={{ color: 'primary.500' }}
										/>
										<Typography variant="h3">No results found.</Typography>
										<Typography variant="body1" textAlign="center">
											No {entityType} matched your search. Try changing the
											entity type, search criteria, or filter criteria to find
											what you&apos;re looking for.
										</Typography>
									</Stack>
								)}
							</Card>
						</>
					)}
				</Formik>
			</PageContent>
		</>
	);
};

export default SystemSearch;

/**
 * Skeleton table props.
 */
type SkeletonProps = {
	rows: number;
};

/**
 * Renders a skeleton table as search results load.
 *
 * @param {SkeletonProps} props
 * @return {*}  {React.JSX.Element}
 */
const SkeletonTable = (props: SkeletonProps): React.JSX.Element => {
	return (
		<CardContent
			sx={{
				flex: '1 1 auto',
				overflow: 'hidden',
			}}>
			<TableContainer>
				<Table stickyHeader aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell style={{ width: '100%' }}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{Array(props.rows)
							.fill(null)
							.map((_, i) => {
								return (
									<TableRow key={i}>
										<TableCell colSpan={8}>
											<Skeleton animation="wave" height={'2em'} />
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
		</CardContent>
	);
};
