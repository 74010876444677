import { gql, useMutation, useQuery } from '@apollo/client';
import { Business } from '@mui/icons-material';
import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import { OrgForm, OrganizationCreationExtended } from 'components/pages/org/account/new/org-form';
import { Loading } from 'components/ui/loading';
import { PageContent, PageTitle } from 'components/ui/page';
import { useToast } from 'components/ui/toast';
import {
	Mutation,
	MutationLegacyAdjustmentCompanyCreateAndLinkArgs,
	Query,
	QueryLegacyAdjustmentCompanyInfoArgs,
	QueryLegacyCanCallerLinkArgs,
} from 'middleware-types';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageError, handleNoResponse, responseHasErrors } from 'utils/errors';
import { refreshToken } from 'utils/session';
import { ALL_USER_ORG_PERMISSIONS } from 'utils/useAssociateUser';
import { useDefaults } from 'utils/useDefaults';
import { geocode } from 'utils/useGeocode';
import { useValidation } from 'utils/useValidation';

/**
 * The OrgScreen is a create organization page.
 */
export const LinkedOrgCreatePage: React.FC = () => {
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const systemId = params.get('system') ?? params.get('System');
	const acid = params.get('acid') ?? params.get('Acid') ?? params.get('ACID');
	if (!systemId || !acid) throw new PageError('System ID and ACID required');
	const systemIdNum = Number(systemId);
	const acidNum = Number(acid);

	const {
		canLink,
		error: canLinkError,
		loading: canLinkLoading,
	} = useLegacyCanCallerLink(systemIdNum, acidNum);

	if (canLinkError || !canLink) {
		return <LinkedOrgErrorScreen />;
	}

	if (canLinkLoading) {
		return <Loading />;
	}

	return <LinkedOrgCreateForm systemId={systemIdNum} acid={acidNum} />;
};

const LinkedOrgCreateForm = ({ systemId, acid }: { systemId: number; acid: number }) => {
	const { initialValues, loading, validationSchema, onSubmit, error } = useLinkedOrgForm({
		systemId,
		acid,
	});

	if (error) {
		return <LinkedOrgErrorScreen />;
	}

	return (
		<PageContent>
			<PageTitle title="Create Linked Organization" />
			<Container>
				<Grid container justifyContent="center">
					<Grid item xs={12} sm={10} md={8}>
						<AcHeading systemId={systemId} acid={acid} />
						<Stack alignItems="center" justifyContent="center" mt={2} spacing={2}>
							<Typography variant="h1">
								Create a linked Evolve organization.
							</Typography>
							<Typography variant="body1" textAlign="center">
								This Evolve organization will be linked to your FileTrac Legacy
								adjustment company.
							</Typography>
							<OrgForm
								initialValues={initialValues}
								onSubmit={onSubmit}
								validationSchema={validationSchema}
								loading={loading}
								error={error}
								// Set to true when import logo button is ready
								linkedOrg={false}
							/>
						</Stack>
					</Grid>
				</Grid>
			</Container>
		</PageContent>
	);
};

const AcHeading = ({ systemId, acid }: { systemId: number; acid: number }) => {
	const { company } = useLegacyAdjustmentCompanyInfo(systemId, acid);

	return (
		<Stack display="flex" flexDirection="column" alignItems="center" justifyContent="center">
			<img
				className="mb-4 max-h-5 bg-transparent"
				src={
					company?.logo && company?.systemUrl
						? `${company?.systemUrl}/../images/logos/${company?.logo}`
						: undefined
				}
			/>
			{(!company?.logo || !company.systemUrl) && (
				<Business
					sx={{
						marginBottom: 2,
					}}
				/>
			)}
			<Typography variant="h4">{company?.name}</Typography>
		</Stack>
	);
};

/**
 * useLinkedOrgForm() - a custom hook to handle the data logic for organization onboarding.
 *
 * @returns
 */
export const useLinkedOrgForm = ({ systemId, acid }: { systemId: number; acid: number }) => {
	const toast = useToast();
	const validation = useValidation('OrganizationCreateAndLinkRequest');
	const [updateLinkedOrg, updateOrgStatus] = useMutation<
		Pick<Mutation, 'legacyAdjustmentCompanyCreateAndLink'>,
		MutationLegacyAdjustmentCompanyCreateAndLinkArgs
	>(
		gql`
			mutation legacyAdjustmentCompanyCreateAndLink(
				$request: OrganizationCreateAndLinkRequest!
			) {
				legacyAdjustmentCompanyCreateAndLink(request: $request) {
					handle
				}
			}
		`,
		{
			refetchQueries: [ALL_USER_ORG_PERMISSIONS],
		}
	);
	const defaults = useDefaults();
	const navigate = useNavigate();

	const initialValues: OrganizationCreationExtended = {
		legalName: '',
		displayName: '',
		systemEmailAddress: '',
		mainPhoneNumber: {
			countryCode: defaults.country.phonePrefix,
			number: '',
		},
		mailingAddress: {
			countryId: defaults.country.id,
			address1: '',
			address2: '',
			municipality: '',
			adminArea1Id: '',
			adminArea2Id: '',
			postalCode: '',
			coordinate: undefined,
		},
		// Uncomment when import logo button is ready:
		// importLogo: false,
	};

	const onSubmit = async (
		values: OrganizationCreationExtended,
		redirect: boolean
	): Promise<boolean> => {
		if (!values.mailingAddress) return Promise.resolve(false);
		return await geocode(values.mailingAddress).then((location) =>
			updateLinkedOrg({
				variables: {
					request: {
						...values,
						systemId,
						acid,
						mailingAddress: {
							...values.mailingAddress,
							coordinate: location,
						},
					},
				},
				update: (cache) => {
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: `affiliatedOrganizations`,
					});
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: `allUserOrgPermissions`,
					});
				},
			})
				.then(async (res) => {
					await refreshToken();
					if (responseHasErrors(res.errors, { toast })) {
						return false;
					}
					toast.push('Thank you for adding a linked Organization to Evolve.', {
						variant: 'success',
					});
					redirect &&
						navigate(`/../${res.data?.legacyAdjustmentCompanyCreateAndLink.handle}`);
					return true;
				})
				.catch(() => {
					handleNoResponse({ toast });
					return false;
				})
		);
	};

	return {
		loading: validation.loading,
		validationSchema: validation.schema,
		initialValues,
		onSubmit,
		error: updateOrgStatus.error,
	};
};

export const useLegacyCanCallerLink = (systemId: number, acid: number) => {
	const { data, error, loading } = useQuery<
		Pick<Query, 'legacyCanCallerLink'>,
		QueryLegacyCanCallerLinkArgs
	>(
		gql`
			query legacyCanCallerLink($systemId: Int!, $acid: Int!) {
				legacyCanCallerLink(systemId: $systemId, acid: $acid) {
					canLink
				}
			}
		`,
		{
			variables: {
				systemId,
				acid,
			},
			fetchPolicy: 'cache-and-network',
			onError: () => {
				console.log(error);
			},
		}
	);

	return {
		canLink: data?.legacyCanCallerLink.canLink,
		error,
		loading,
	};
};

export const useLegacyAdjustmentCompanyInfo = (systemId: number, acid: number) => {
	const { data, error, loading } = useQuery<
		Pick<Query, 'legacyAdjustmentCompanyInfo'>,
		QueryLegacyAdjustmentCompanyInfoArgs
	>(
		gql`
			query legacyAdjustmentCompanyInfo($systemId: Int!, $acid: Int!) {
				legacyAdjustmentCompanyInfo(systemId: $systemId, acid: $acid) {
					name
					logo
					systemUrl
				}
			}
		`,
		{
			variables: {
				systemId,
				acid,
			},
			fetchPolicy: 'cache-and-network',
			onError: () => {
				console.log(error);
			},
		}
	);

	return {
		company: data?.legacyAdjustmentCompanyInfo,
		error,
		loading,
	};
};

const LinkedOrgErrorScreen = () => {
	return (
		<PageContent
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}>
			<PageTitle title="Create Linked Organization" />
			<Container maxWidth="sm">
				<Stack justifyContent="center" alignItems="center" textAlign="center" spacing={2}>
					<img width={100} src="/img/logo-colored.svg" alt="FTEvolve" />
					<Typography variant="h2">
						We are unable to create a linked Evolve organization for this adjustment
						company.
					</Typography>
					<Typography variant="h5">
						If you believe this is an error, please contact Evolution Global support for
						further assistance.
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={() => (window.location.href = '/')}>
						Return to the Dashboard
					</Button>
				</Stack>
			</Container>
		</PageContent>
	);
};
