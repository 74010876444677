import { ChevronRightOutlined, SettingsOutlined } from '@mui/icons-material';
import {
	Card,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { Permission } from 'utils/permissions';
import { useSiteUser } from 'utils/useSiteUser';

/**
 * ProfileAdministration - Profile administration navigation.
 *
 * @param {{userId : string}} props
 * @return {*}
 */
export const ProfileAdministration = () => {
	const { hasPermission } = useSiteUser();
	const params = useParams<{ handle: string }>();
	if (!hasPermission(Permission.SocialUser_Account_R)) return <></>;
	return (
		<Stack spacing={3} width="100%">
			<Card>
				<List disablePadding>
					<ListItemButton component={Link} to={`/${params.handle}/account`}>
						<ListItem disablePadding>
							<ListItemIcon>
								<SettingsOutlined />
							</ListItemIcon>
							<ListItemText>View Account Settings</ListItemText>
							<ChevronRightOutlined />
						</ListItem>
					</ListItemButton>
				</List>
			</Card>
		</Stack>
	);
};
