import { LoadingButton } from '@mui/lab';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	useModal,
} from 'components/ui/modal';
import { ConversationParticipant } from 'middleware-types';
import { useCommunicationsContext } from '../communications-provider';
import { useRemoveParticipant } from '../hooks/participants-hooks';
import { ErrorOutlined } from '@mui/icons-material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { useEmblem } from 'components/ui/emblem/hooks';
import { convertParticipantType } from '../helpers/utils';

export const RemoveParticipantModal = ({
	participant,
}: {
	participant: ConversationParticipant;
}) => {
	const { closeModal } = useModal();
	const { entityId } = useCommunicationsContext();
	const { removeParticipant, loading } = useRemoveParticipant();

	const { emblem } = useEmblem(
		participant.entityId,
		convertParticipantType(participant.entityType)
	);

	const isSelf = entityId === participant.entityId;

	const onSubmit = () => {
		removeParticipant({
			entityType: participant.entityType,
			entityId: participant.entityId,
		}).then((success) => {
			if (!success) return;
			closeModal();
		});
	};

	return (
		<>
			<ConfirmModalContent
				visual={
					<EmptyStateAvatar
						avatarProps={{ bgcolor: 'error.50' }}
						iconProps={{ color: 'error.500' }}
						icon={<ErrorOutlined />}
					/>
				}
				subheadline={isSelf ? 'Leave Conversation?' : `Remove ${emblem?.displayName}`}
				informativeContent={`${
					isSelf ? 'You' : emblem?.displayName
				} will lose access to this conversation and its history.`}
			/>
			<ModalActions>
				<ModalActionButton size="large" variant="outlined">
					Cancel
				</ModalActionButton>
				<LoadingButton
					size="large"
					variant="contained"
					color="error"
					loading={loading}
					onClick={onSubmit}>
					{isSelf ? 'Leave' : 'Remove'}
				</LoadingButton>
			</ModalActions>
		</>
	);
};
