import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
	CanAdjusterLicensingInput,
	Mutation,
	MutationUserProfileCanAdjusterLicensingDeleteArgs,
	MutationUserProfileCanAdjusterLicensingSetManualArgs,
	Query,
	QueryCanAdjusterLicensingArgs,
	QueryCanLicenseTypesArgs,
} from 'middleware-types';
import { PageError, handleNoResponse, responseHasErrors } from 'utils/errors';
import { useAccount } from 'utils/useAccount';
import { useCanProvinces } from 'utils/useCountries';
import { ADJUSTER_LICENSE_SUMMARIES } from '../hooks';
import { useToast } from 'components/ui/toast';

export const DELETE_CAN_ADJUSTER_LICENSING = gql`
	mutation userProfileCanAdjusterLicensingDelete($userId: ID!) {
		userProfileCanAdjusterLicensingDelete(userId: $userId)
	}
`;

/**
 * useDeleteUsaAdjusterLicensesMutation(userId) - Hook to delete the user usa licenses.
 *
 * @param {string} userId
 * @return {*}
 */
export const useDeleteCanAdjusterLicensesMutation = (userId: string) => {
	const [updateLicensingMut, { error, loading }] = useMutation<
		Pick<Mutation, 'userProfileCanAdjusterLicensingDelete'>,
		MutationUserProfileCanAdjusterLicensingDeleteArgs
	>(DELETE_CAN_ADJUSTER_LICENSING, {
		refetchQueries: [{ query: ADJUSTER_LICENSE_SUMMARIES, variables: { userId } }],
		awaitRefetchQueries: true,
	});

	const deleteLicenses = async () => {
		return updateLicensingMut({
			variables: {
				userId,
			},
		});
	};

	return { deleteLicenses, error, loading };
};

/** Gets the existing CAN Licensing information for the requested standard user. */
export const CANADJUSTERLICENSING = gql`
	query canAdjusterLicensing($userId: ID!) {
		canAdjusterLicensing(userId: $userId) {
			ciprNumber
			provincesLicensing {
				provinceAddressFieldLookupId
				provinceLicenses {
					number
					adjusterAlias
					supervisingEntity
					originalIssueDate
					expirationDate
					licenseTypeId
					designation
				}
			}
		}
	}
`;

/**
 * useCanLicensingQuery - This query provides the license info for a user.
 *
 * @param {userId} userid to query.
 * @return {*}
 */
export const useCanLicensingQuery = (userId: string) => {
	const { account, loading: accountLoading } = useAccount();
	const { loading: loadingProvinces, provinces } = useCanProvinces();
	const { loading, data, error } = useQuery<
		Pick<Query, 'canAdjusterLicensing'>,
		QueryCanAdjusterLicensingArgs
	>(CANADJUSTERLICENSING, {
		fetchPolicy: 'cache-and-network',
		variables: {
			userId,
		},
	});

	if (error) throw new PageError(error);

	return {
		licensing: data?.canAdjusterLicensing
			? {
					...data.canAdjusterLicensing,
					provincesLicensing: [...data.canAdjusterLicensing.provincesLicensing].sort(
						(a, b) => {
							const aOrder = provinces.find(
								(province) => province.id === a.provinceAddressFieldLookupId
							)?.displayOrder;
							const bOrder = provinces.find(
								(province) => province.id === b.provinceAddressFieldLookupId
							)?.displayOrder;
							if (aOrder === undefined || bOrder === undefined) return 0;
							return aOrder - bOrder;
						}
					),
			  }
			: undefined,
		displayName: `${account?.name?.firstName ?? ''} ${account?.name?.middleName ?? ''} ${
			account?.name?.lastName ?? ''
		} ${account?.name?.suffix ?? ''}`,
		loading: loading || accountLoading || loadingProvinces,
		error,
	};
};

/* Mutation for setting the CAN licensing data manually. */
export const SET_CAN_ADJUSTER_LICENSING_MANUAL = gql`
	mutation UserProfileCanAdjusterLicensingSetManual(
		$userId: ID!
		$canadianLicensing: CanAdjusterLicensingInput!
	) {
		userProfileCanAdjusterLicensingSetManual(
			userId: $userId
			canadianLicensing: $canadianLicensing
		) {
			userId
			licensingProvenance
			countryId
			adjusterLicensingText
			adjusterLicensingTextSensitive
			numLicenses
			numActiveLicenses
			numExpiredLicenses
			numExpiredWithin60DaysLicenses
		}
	}
`;

/**
 * useCanAdjusterLicensingManualMutation(userId) - Hook to update the user licenses using manual licenses.
 *
 * @param {string} userId
 * @return {*}
 */
export const useCanAdjusterLicensingManualMutation = (userId: string) => {
	const toast = useToast();
	const [updateLicensingMut, { error, loading }] = useMutation<
		Pick<Mutation, 'userProfileCanAdjusterLicensingSetManual'>,
		MutationUserProfileCanAdjusterLicensingSetManualArgs
	>(SET_CAN_ADJUSTER_LICENSING_MANUAL, {
		refetchQueries: [{ query: CANADJUSTERLICENSING, variables: { userId } }],
		awaitRefetchQueries: true,
	});
	const updateLicenses = async (canadianLicensing: CanAdjusterLicensingInput) => {
		return await updateLicensingMut({
			variables: {
				userId,
				canadianLicensing,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { updateLicenses, error, loading };
};

/* Gets the CAN license types for the requested province */
export const CANLICENSETYPES = gql`
	query canLicenseTypes($province: String) {
		canLicenseTypes(province: $province) {
			id
			provinceId
			name
		}
	}
`;

/**
 * Queries for the license types of a given province
 *
 * @param {*} province
 * @return {*}
 */
export const useLicenseTypesQuery = (province: string | undefined) => {
	const toast = useToast();
	const { data, loading } = useQuery<Pick<Query, 'canLicenseTypes'>, QueryCanLicenseTypesArgs>(
		CANLICENSETYPES,
		{
			variables: { province: province },
			skip: !province,
			onError: () => {
				toast.push('Unable to load license types', {
					variant: 'error',
				});
			},
		}
	);

	return {
		loading,
		licenseTypes: [...(data?.canLicenseTypes ?? [])].sort((a, b) =>
			a.name.localeCompare(b.name)
		),
	};
};
