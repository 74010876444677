import { gql, useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	Mutation,
	MutationPatchUserDashboardConfigurationSettingsArgs,
	Query,
	QueryUserDashboardConfigurationSettingsArgs,
	UserDashboardConfigurationPatch,
} from 'middleware-types';
import { responseHasErrors, handleNoResponse } from 'utils/errors';
import { useSession } from 'utils/session';

const USER_DASHBOARD_SETTINGS_FIELDS = gql`
	fragment UserDashboardSettingsFields on UserDashboardConfigurationResponse {
		id
		primaryColor
		secondaryColor
		title
		subTitle
	}
`;

const GET_USER_DASHBOARD_CONFIGURATION_SETTINGS = gql`
	${USER_DASHBOARD_SETTINGS_FIELDS}
	query userDashboardConfigurationSettings($userId: ID!) {
		userDashboardConfigurationSettings(userId: $userId) {
			...UserDashboardSettingsFields
		}
	}
`;

export const useUserDashboardConfigurationSettings = () => {
	const { user } = useSession();
	const toast = useToast();
	const { data, loading } = useQuery<
		Pick<Query, 'userDashboardConfigurationSettings'>,
		QueryUserDashboardConfigurationSettingsArgs
	>(GET_USER_DASHBOARD_CONFIGURATION_SETTINGS, {
		variables: { userId: user.userId },
		onError: () =>
			toast.push('Unable to load dashboard configuration settings', { variant: 'error' }),
	});

	return { settings: data?.userDashboardConfigurationSettings, loading };
};

const PATCH_USER_DASHBOARD_CONFIGURATION_SETTINGS = gql`
	${USER_DASHBOARD_SETTINGS_FIELDS}
	mutation patchUserDashboardConfigurationSettings(
		$userId: ID!
		$patch: UserDashboardConfigurationPatch!
	) {
		patchUserDashboardConfigurationSettings(userId: $userId, patch: $patch) {
			...UserDashboardSettingsFields
		}
	}
`;

export const usePatchUserDashboardConfigurationSettings = () => {
	const toast = useToast();

	const [_patchUserDashboardConfigurationSettings] = useMutation<
		Pick<Mutation, 'patchUserDashboardConfigurationSettings'>,
		MutationPatchUserDashboardConfigurationSettingsArgs
	>(PATCH_USER_DASHBOARD_CONFIGURATION_SETTINGS);

	const patchUserDashboardConfigurationSettings = async (
		userId: string,
		patch: UserDashboardConfigurationPatch
	) => {
		return await _patchUserDashboardConfigurationSettings({ variables: { userId, patch } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Dashboard theme updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { patchUserDashboardConfigurationSettings };
};
