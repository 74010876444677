import { LogoutOutlined, PasswordOutlined } from '@mui/icons-material';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import { useModal } from 'components/ui/modal';
import { GlobalSignoutModal } from './global-signout-modal';

interface SessionSettingsCardProps {
	userId: string;
	canEdit: boolean;
}

export const SessionSettingsCard = ({ userId, canEdit }: SessionSettingsCardProps) => {
	const { showModal } = useModal();
	return (
		<Card>
			<CardHeader title="Session Settings" />
			<Divider />
			<CardContent>
				<Stack direction="row" alignItems="center" spacing={{ xs: 2, sm: 3 }}>
					<PasswordOutlined fontSize="small" />
					<Typography variant="h5" flex={1}>
						Sign Out Of All Devices
					</Typography>
					{canEdit && (
						<IconButton
							onClick={() =>
								showModal({
									title: 'Sign Out Of All Devices',
									content: <GlobalSignoutModal userId={userId} />,
								})
							}>
							<LogoutOutlined />
						</IconButton>
					)}
				</Stack>
			</CardContent>
		</Card>
	);
};
