import { useEmblem } from 'components/ui/emblem/hooks';
import { EmblemEntityType } from 'middleware-types';
import { useOrgProfileConnections, useUserProfileConnections } from './hooks';
import { OrgConnectionsBar } from './OrgConnectionsBar';

export const UserProfileOrgConnectionsBar = ({ userId }: { userId: string }) => {
	const { profile, loading } = useUserProfileConnections(userId);
	return (
		<OrgConnectionsBar
			connections={profile?.affiliatedOrganizationConnections ?? []}
			entityId={profile?.id ?? ''}
			entityName={`${profile?.name.firstName} ${profile?.name.lastName}`}
			entityLoading={loading}
		/>
	);
};

export const OrgProfileOrgConnectionsBar = ({ orgId }: { orgId: string }) => {
	const { profile, loading } = useOrgProfileConnections(orgId);
	const { emblem } = useEmblem(orgId, EmblemEntityType.Organization);

	return (
		<OrgConnectionsBar
			connections={profile?.affiliatedOrganizationConnections ?? []}
			entityId={profile?.id ?? ''}
			entityName={profile?.displayName ?? ''}
			entityLoading={loading}
			showConnectedOnly={!!emblem?.private}
		/>
	);
};
