import { useState } from 'react';

export const useLocalStorage = <T>(key: string, initialValue: T): [T, (newValue: T) => void] => {
	const [value, _setValue] = useState<T>(() => {
		try {
			const storedValue = window.localStorage.getItem(key);
			return storedValue === null ? initialValue : (JSON.parse(storedValue) as T);
		} catch {
			return initialValue;
		}
	});
	const setValue = (newValue: T) => {
		window.localStorage.setItem(key, JSON.stringify(newValue));
		_setValue(newValue);
	};
	return [value, setValue];
};
