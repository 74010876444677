import * as Icons from '@mui/icons-material';
import {
	Autocomplete,
	Box,
	Button,
	Grid,
	InputAdornment,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Link as MuiLink,
	TextField as MuiTextField,
	Stack,
	Typography,
} from '@mui/material';
import { TextField } from 'components/ui/fields';
import { ColorField } from 'components/ui/fields/colorField';
import { useField, useFormikContext } from 'formik';
import { OrganizationProfile } from 'middleware-types';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { CalloutFormFields, IconOption, emptyCalloutFields } from './form-helpers';

interface OrgHeaderFormProps {
	profile: OrganizationProfile;
}

export const OrgHeaderForm = ({ profile }: OrgHeaderFormProps) => {
	const specialties = profile.businessLines?.filter((line) => line.specialty) ?? [];
	const specialtiesText = specialties.map((s) => s.line ?? '').join(', ');
	return (
		<Stack spacing={3}>
			<Stack spacing={1}>
				<Typography variant="h3">General Information</Typography>
				<Box>
					<Grid container rowSpacing={0.5} columnSpacing={1.5}>
						<Grid item xs={12}>
							<TextField
								name="displayName"
								label="Organization Display Name"
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField name="tagline" label="Tagline" />
						</Grid>
						<Grid item xs={12} sm={6}>
							<MuiTextField label="Industry" disabled select>
								<MenuItem>test</MenuItem>
							</MuiTextField>
							<Typography variant="caption">
								Edit your industry in the About tab below.
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6}>
							<MuiTextField label="Company Size" disabled select>
								<MenuItem>test</MenuItem>
							</MuiTextField>
							<Typography variant="caption">
								Edit your company size in the About tab below.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<MuiTextField
								value={profile.displayLocation ?? ''}
								label="Primary Location"
								required
								disabled
							/>
							<Typography variant="caption">
								Edit your address in{' '}
								<MuiLink component={Link} to="../account/">
									Account Settings.
								</MuiLink>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<MuiTextField value={specialtiesText} label="Specialties" disabled />
							<Typography variant="caption">
								Edit your specialties in the Lines of Business tab below.
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</Stack>
			<Stack spacing={1}>
				<Box>
					<Typography variant="h3">Callouts</Typography>
					<Typography variant="body1">
						Add up to three callouts with a limit of 30 characters each. See full icon
						library{' '}
						<MuiLink target="_blank" href="https://mui.com/material-ui/material-icons/">
							here.
						</MuiLink>
					</Typography>
				</Box>
				<Stack spacing={{ xs: 2, sm: 0 }}>
					<CalloutFields name="callout1" />
					<CalloutFields name="callout2" />
					<CalloutFields name="callout3" />
				</Stack>
			</Stack>
			<Stack spacing={1}>
				<Typography variant="h3">Social Media</Typography>
				<Box>
					<Grid container rowSpacing={0.5} columnSpacing={1.5}>
						<Grid item xs={12} sm={6}>
							<TextField name="xProfileLink" label="X Profile Link" />
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField name="linkedinProfileLink" label="LinkedIn Profile Link" />
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField name="facebookProfileLink" label="Facebook Profile Link" />
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								name="conversationInsuranceProfileLink"
								label="Conversation Insurance Profile Link"
							/>
						</Grid>
					</Grid>
				</Box>
			</Stack>
		</Stack>
	);
};

const CalloutFields = ({ name }: { name: string }) => {
	const [{ value }, , { setValue }] = useField<CalloutFormFields>(name);
	const isRequired = Boolean(value.icon) || Boolean(value.color) || Boolean(value.text);

	return (
		<Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.5}>
			<Box flex={1}>
				<Grid container rowSpacing={0.5} columnSpacing={1.5}>
					<Grid item xs={12} sm={4}>
						<IconSelectionField name={`${name}.icon`} required={isRequired} />
					</Grid>
					<Grid item xs={12} sm={4}>
						<ColorField
							name={`${name}.color`}
							label={`Color${isRequired ? ' *' : ''}`}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField name={`${name}.text`} label={`Text${isRequired ? ' *' : ''}`} />
					</Grid>
				</Grid>
			</Box>
			<Box pt={{ xs: 0, sm: 1 }}>
				<Button
					variant="outlined"
					sx={{ height: 40 }}
					onClick={() => setValue(emptyCalloutFields, true)}>
					Clear
				</Button>
			</Box>
		</Stack>
	);
};

const IconSelectionField = ({ name, required }: { name: string; required: boolean }) => {
	const { isSubmitting } = useFormikContext();
	const [{ value }, { error }, { setValue }] = useField<IconOption | null>(name);

	const [searchText, setSearchText] = useState('');
	const options: IconOption[] = useMemo(() => {
		if (!searchText) return [];
		const searchTextLower = searchText.toLowerCase();

		const iconEntries = Object.entries(Icons);
		const matchingEntries = iconEntries.filter((entry) =>
			entry[0].toLowerCase().includes(searchTextLower)
		);
		const orderedEntries = matchingEntries.sort((a, b) => a[0].length - b[0].length);
		const first20 = orderedEntries.slice(0, 20);
		return first20.map((entry) => ({ name: entry[0], Icon: entry[1] }));
	}, [searchText]);

	return (
		<Autocomplete
			value={value}
			onChange={(_e, newValue) => setValue(newValue)}
			noOptionsText={searchText ? undefined : 'Start typing to search for an icon'}
			options={options}
			inputValue={searchText}
			onInputChange={(_e, value) => setSearchText(value)}
			getOptionLabel={(option) => option.name}
			isOptionEqualToValue={(option, value) => option.name === value.name}
			disabled={isSubmitting}
			renderInput={({ InputProps, ...props }) => (
				<MuiTextField
					{...props}
					label="Icon"
					required={required}
					error={Boolean(error)}
					helperText={error}
					InputProps={{
						...InputProps,
						startAdornment: value?.Icon ? (
							<InputAdornment position="start">
								<value.Icon />
							</InputAdornment>
						) : undefined,
					}}
				/>
			)}
			renderOption={(props, option) => (
				<MenuItem {...props}>
					<ListItemIcon>
						<option.Icon />
					</ListItemIcon>
					<ListItemText>{option.name}</ListItemText>
				</MenuItem>
			)}
		/>
	);
};
