import { useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import { gql } from 'graphql-tag';
import { Query, QueryCognitoDevicesArgs } from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';

/**
 * useResetMfa - hook that resets a user's MFA preference.
 *
 * @return {*}
 */
export const useResetMfa = () => {
	const toast = useToast();
	const [resetMfaMut, { loading, data, error }] = useMutation(RESETMFA);

	const resetMfa = (userId: string) => {
		return resetMfaMut({
			variables: { userId },
			update: (cache) => {
				cache.evict({
					id: 'ROOT_QUERY',
					fieldName: 'getCognitoUser',
				});

				cache.gc();
			},
		})
			.then(async (res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('MFA was reset successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { resetMfa, loading, data, error };
};

const RESETMFA = gql`
	mutation resetMfa($userId: ID!) {
		resetMfa(userId: $userId)
	}
`;

/**
 * useGetCognitoUser - hook that retrieves a user from Cognito. Currently used to retrieve their up to date preferred MFA setting.
 *
 * @param {string} userId
 * @return {*}
 */
export const useGetCognitoUser = (userId: string) => {
	const toast = useToast();
	const { data, loading, error } = useQuery<Pick<Query, 'getCognitoUser'>>(GETCOGNITOUSER, {
		fetchPolicy: 'cache-first',
		variables: {
			userId,
		},
	});

	if (error) {
		toast.push(
			'An error occurred while displaying MFA preference. Displayed MFA preference may be out of date.',
			{
				variant: 'error',
			}
		);
	}

	return { user: data?.getCognitoUser, loading, error };
};

const GETCOGNITOUSER = gql`
	query getCognitoUser($userId: ID!) {
		getCognitoUser(userId: $userId) {
			preferredMfaSetting
		}
	}
`;

/**
 * useGlobalSignOut - hook that signs a user out of all devices in Cognito.
 *
 * @return {*}
 */
export const useGlobalSignOut = () => {
	const toast = useToast();
	const [globalSignOutMut, { error }] = useMutation(GLOBALSIGNOUT);

	const globalSignOut = (userId: string) => {
		return globalSignOutMut({
			variables: { userId },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('User globally signed out.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { globalSignOut, error };
};

const GLOBALSIGNOUT = gql`
	mutation cognitoUserGlobalSignOut($userId: ID!) {
		cognitoUserGlobalSignOut(userId: $userId)
	}
`;

/**
 * useCognitoDevices - hook that retrieves a user's devices from Cognito.
 *
 * @return {*}
 */
export const useCognitoDevices = (userId: string) => {
	const toast = useToast();
	const { loading, data } = useQuery<Pick<Query, 'cognitoDevices'>, QueryCognitoDevicesArgs>(
		GETCOGNITODEVICES,
		{
			fetchPolicy: 'cache-and-network',
			variables: { userId },
			onError: () =>
				toast.push(
					'An error occurred while fetching devices. Please try again later or contact Support.',
					{
						variant: 'error',
					}
				),
		}
	);

	return { devices: data?.cognitoDevices.cognitoDevices, loading };
};

const GETCOGNITODEVICES = gql`
	query cognitoDevices($userId: ID!) {
		cognitoDevices(userId: $userId) {
			cognitoDevices {
				name
				remembered
				lastIpUsed
				lastAuthenticatedUtc
				createdAtUtc
			}
		}
	}
`;

/**
 * useForgetCognitoDevicesMutation - hook that forgets a user's devices completely in Cognito. Distinct from setting the remembered attribute on a device to false.
 *
 * @param {string} userId
 * @return {*}
 */
export const useForgetCognitoDevicesMutation = (userId: string) => {
	const [getCognitoDevicesMut, { loading, error }] = useMutation(FORGETCOGNITODEVICES);
	const toast = useToast();

	const forgetCognitoDevices = async () => {
		return await getCognitoDevicesMut({
			variables: { userId },

			update: (cache) => {
				cache.evict({
					id: 'ROOT_QUERY',
					fieldName: 'cognitoDevices',
				});

				cache.gc();
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('All Devices Have Been Forgotten', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { forgetCognitoDevices, loading, error };
};

const FORGETCOGNITODEVICES = gql`
	mutation forgetCognitoDevices($userId: ID!) {
		forgetCognitoDevices(userId: $userId)
	}
`;
