import { ReactNode, createContext, useContext } from 'react';

interface DocumentSearchProviderProps {
	isSearchActive: boolean;
	searchValue: string;
}

const DocumentSearchContext = createContext<DocumentSearchProviderProps | undefined>(undefined);

export const DocumentSearchProvider = ({
	children,
	...props
}: DocumentSearchProviderProps & { children: ReactNode }) => {
	return (
		<DocumentSearchContext.Provider value={props}>{children}</DocumentSearchContext.Provider>
	);
};

export const useDocumentSearchContext = () => {
	const value = useContext(DocumentSearchContext);
	if (value === undefined)
		throw new Error('useDocumentSearchContext must be used inside a DocumentSearchProvider');
	return value;
};
