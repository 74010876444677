import { Dialog, Drawer } from '@mui/material';
import { ReactNode } from 'react';
import { useIsMobile } from 'utils/useScreenSize';

export const DrawerOrDialog = ({ children, open }: { children: ReactNode; open: boolean }) => {
	const isMobile = useIsMobile();

	return isMobile ? (
		<Drawer open={open} anchor="bottom">
			{children}
		</Drawer>
	) : (
		<Dialog open={open}>{children}</Dialog>
	);
};
