import { ErrorOutline, InfoOutlined } from '@mui/icons-material';
import type {} from '@mui/lab/themeAugmentation';
import {
	Color,
	CssBaseline,
	LinkProps,
	Paper,
	StyledEngineProvider,
	ThemeProvider,
} from '@mui/material';
import { PaletteColorOptions, createTheme } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';
import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import twConfig from '../../tailwind.config.js';

const rootElement = document.getElementById('root');

/**
 * Globally change the MUI link behavior into router links.
 * https://mui.com/guides/routing/#global-theme-link
 * @type {*}
 **/
const LinkBehavior = React.forwardRef<
	HTMLAnchorElement,
	Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
	const { href, ...other } = props;
	// Used for Link's without a 'to' prop.
	if (!href) return <a ref={ref} {...other} />;
	// Map href (MUI) -> to (react-router)
	return <RouterLink ref={ref} to={href} {...other} />;
});

LinkBehavior.displayName = 'LinkBehavior';

/**
 * DEFAULTPAGESIZES is the Default Pagination sizes for tables.
 * @type {number[]}
 */
export const DEFAULTPAGESIZES: number[] = [25, 50, 100];

/**
 * Green Semantic color
 * Besides success snackbars/alerts, green is also used as a semantic color
 * for background to display legibility purposes
 */
export const green = twConfig.theme.colors.green;

/**
 * Navy Semantic color
 * Primary color of the application.
 * Besides informational alerts/snackbars, Navy is also used as a
 * semantic color to highlight important information.
 * For example, the Navy star icon associated with a
 * user’s primary address is used consistently on the profile page
 * and settings page. This consistency conveys and emphasizes meaning.
 * Navy is also used for selected textfields and dropdowns.
 */
export const navy = twConfig.theme.colors.navy;

/**
 * Teal Semantic color
 * Because of teal’s inaccessibility, only use teal
 * for decorative purposes (e.g. illustrations and empty states).
 */
export const teal = twConfig.theme.colors.teal;

/**
 * Yellow Semantic color
 * Besides warning snackbars/alerts and ratings, yellow is also used as
 * a semantic color to highlight temporary information.
 * For example, the yellow clock icon associated with a user’s current temporary address
 * is used consistently on the profile page and settings page.
 * This consistency conveys and emphasizes meaning.
 */
export const yellow = twConfig.theme.colors.yellow;

/**
 * Red Semantic color
 * Besides error snackbars/alerts and ratings, red is also used as a semantic color
 * to emergency contact information, since emergencies are often associated with the color red.
 * Like the teal star and yellow clock for primary and temporary addresses, this iconography
 * should be used consistently throughout the site, if emergency contact is referenced outside
 * of settings. This consistency conveys and emphasizes meaning.
 */
export const red = twConfig.theme.colors.red;

/**
 * Neutral colors are used for backgrounds, borders, dividers, iconography, and the typography system.
 * Neutral colors are also used for text on disabled elements.
 */
export const neutral = twConfig.theme.colors.neutral;

/**
 * shadows define the elevation of a card.
 * Elevation 0 has no shadow
 * Elevation 1 is a light glow, this is common around cards
 * Elevation 2 is a more directed shadow, this is common on dropdowns.
 * Elevation 3-25 are unset.
 */
export const shadows = [
	'none',
	`0px 0px 5px ${neutral[400]}59;`,
	`0px 2px 8px ${neutral[400]}59;`,
	...Array(22).fill('none'),
] as Shadows;

declare module '@mui/material/styles' {
	interface Palette {
		neutral: Pick<Color, 900 | 800 | 700 | 600 | 500 | 400 | 300 | 200 | 100 | 50>;
		darkSecondary: PaletteColorOptions;
	}

	interface PaletteOptions {
		neutral: Pick<Color, 900 | 800 | 700 | 600 | 500 | 400 | 300 | 200 | 100 | 50>;
		darkSecondary: PaletteColorOptions;
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		darkSecondary: true;
	}
}

declare module '@mui/material/styles' {
	interface BreakpointOverrides {
		xxl: true;
	}
}

const { palette } = createTheme();
const { augmentColor } = palette;

export const theme = createTheme({
	palette: {
		// Primaries
		primary: {
			dark: navy[600],
			main: navy[500],
			light: navy[50],
			700: navy[700],
			500: navy[500],
			50: navy[50],
			contrastText: '#fff',
		},
		secondary: {
			main: teal[500],
			light: teal[50],
			500: teal[500],
			50: teal[50],
			contrastText: '#fff',
		},
		warning: {
			dark: yellow[600],
			main: yellow[500],
			light: yellow[50],
			800: yellow[800],
			700: yellow[700],
			500: yellow[500],
			50: yellow[50],
			contrastText: '#fff',
		},
		error: {
			dark: red[600],
			main: red[500],
			light: red[50],
			700: red[700],
			500: red[500],
			50: red[50],
			contrastText: '#fff',
		},
		info: {
			dark: navy[600],
			main: navy[500],
			light: navy[50],
			700: navy[700],
			500: navy[500],
			50: navy[50],
			contrastText: '#fff',
		},
		success: {
			dark: green[600],
			main: green[500],
			light: green[50],
			700: green[700],
			500: green[500],
			50: green[50],
			contrastText: '#fff',
		},

		// Neutrals
		neutral,

		// Presets
		divider: neutral[200],
		background: {
			default: '#F5F5F6',
		},
		text: {
			primary: neutral[900],
		},

		// custom button variant
		// https://stackoverflow.com/questions/46486565/material-ui-customize-button-color
		darkSecondary: augmentColor({ color: { main: teal[800], dark: teal[900] } }),
	},
	shadows,
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
			xxl: 1872,
		},
	},
	typography: {
		h1: {
			fontSize: '1.75rem', // 28pt
			color: neutral[900],
			fontWeight: 500,
		},
		h2: {
			fontSize: '1.5rem', // 24pt
			color: neutral[900],
			fontWeight: 500,
		},
		h3: {
			fontSize: '1.25rem', // 20 pt
			color: neutral[900],
			fontWeight: 500,
		},
		h4: {
			fontSize: '1.125rem', // 18pt
			color: neutral[900],
			fontWeight: 500,
		},
		h5: {
			fontSize: '1rem', // 16pt
			color: neutral[900],
			fontWeight: 500,
			lineHeight: '1.5',
		},
		h6: {
			fontSize: '0.875rem', // 14pt
			color: neutral[900],
			fontWeight: 500,
		},
		subtitle1: {
			fontSize: '1rem', // 16pt
			color: neutral[600],
			fontWeight: 400,
		},
		subtitle2: {
			fontSize: '0.875rem', // 14pt
			color: neutral[600],
			fontWeight: 400,
		},
		body1: {
			fontSize: '1rem', // 16pt
			color: neutral[800],
			lineHeight: '1.5',
			fontWeight: 400,
		},
		body2: {
			color: neutral[800],
			fontSize: '0.875rem', // 14pt
			fontWeight: 400,
		},
		caption: {
			fontSize: '0.75rem', // 12pt
			color: neutral[700],
			fontWeight: 400,
		},
		overline: {
			fontSize: '0.75rem', // 12pt
			color: neutral[700],
			fontWeight: 500,
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				'@global': {
					body: {
						paddingRight: '0px !important',
					},
				},
			},
		},
		MuiOutlinedInput: {
			defaultProps: {
				color: 'primary',
			},
			styleOverrides: {
				notchedOutline: {
					borderColor: neutral[300],
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				LinkComponent: LinkBehavior,
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
				color: 'inherit',
				LinkComponent: LinkBehavior,
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					'borderColor': 'rgba(0, 0, 0, 0.23)',
					'textTransform': 'none',
					'& .MuiSvgIcon-fontSizeSmall': {
						color: 'inherit !important',
					},
					'&:focus-visible': {
						'&.MuiButton-containedPrimary': {
							backgroundColor: navy[600],
						},
						'&.MuiButton-containedSecondary': {
							backgroundColor: teal[600],
						},
						'&.MuiButton-containedDarkSecondary': {
							backgroundColor: teal[900],
						},
						'&.MuiButton-outlinedInherit': {
							backgroundColor: neutral[50],
						},
					},
				},
				outlined: {
					'backgroundColor': 'white',
					'&.MuiButton-outlinedInherit:hover': {
						backgroundColor: neutral[50],
					},
					'&.MuiButton-outlinedError:hover': {
						backgroundColor: red[50],
					},
					'&.MuiButton-outlinedSecondary:hover': {
						backgroundColor: navy[50],
					},
					'&.MuiButton-outlinedPrimary:hover': {
						backgroundColor: navy[50],
					},
					'&.MuiButton-outlinedWarning:hover': {
						backgroundColor: yellow[50],
					},
					'&.MuiButton-outlinedInfo:hover': {
						backgroundColor: navy[50],
					},
				},
				outlinedInherit: {
					color: neutral[900],
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					// If the icon is small and not colored, make it dark grey by default.
					'&.MuiSvgIcon-fontSizeSmall:not([class*="MuiSvgIcon-color"])': {
						color: neutral[500],
					},
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					'color': neutral[500],
					'&:hover': {
						backgroundColor: neutral[100],
					},
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					color: neutral[900],
					fontSize: '1.25rem', // 20pt
					paddingBottom: '0.5rem',
					paddingRight: '0.5rem',
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					marginRight: '1rem',
					paddingBottom: '1.5rem',
				},
			},
		},
		MuiCardHeader: {
			defaultProps: {
				titleTypographyProps: {
					variant: 'h2',
				},
			},
			styleOverrides: {
				root: {
					'& + .MuiCardContent-root': {
						paddingTop: '0',
					},
				},
				action: {
					marginRight: 'unset',
				},
			},
		},
		MuiTabPanel: {
			styleOverrides: {
				root: {
					padding: 0,
				},
			},
		},
		MuiTab: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					'textTransform': 'none',
					'minHeight': 'auto',
					'minWidth': 'unset',
					'padding': '1.25rem .5rem',
					'color': neutral[600],
					'fontSize': '1rem',
					'fontWeight': 400,
					'&.Mui-selected': {
						color: neutral[900],
						fontSize: '1rem',
						fontWeight: 500,
					},
					'&:focus-visible': {
						backgroundColor: neutral[100],
					},
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				flexContainer: {
					gap: '1rem',
				},
				indicator: {
					background: navy[500],
					height: '3px',
				},
			},
		},
		MuiFormControl: {
			defaultProps: {
				variant: 'outlined',
				margin: 'dense',
			},
		},

		MuiTextField: {
			defaultProps: {
				variant: 'outlined',
				margin: 'dense',
				fullWidth: true,
				size: 'small',
				color: 'primary',
			},
		},
		MuiSelect: {
			defaultProps: {
				margin: 'dense',
				variant: 'outlined',
				fullWidth: true,
				color: 'primary',
			},
		},
		MuiLink: {
			// Apply Link behavior to all MUI Links.
			// https://mui.com/guides/routing/#global-theme-link
			defaultProps: {
				underline: 'hover',
				component: LinkBehavior,
			} as LinkProps,
			styleOverrides: {
				root: {
					cursor: 'pointer',
					fontWeight: 500,
					width: 'max-content',
				},
			},
		},
		MuiAutocomplete: {
			defaultProps: {
				PaperComponent: (props) => <Paper elevation={2} {...props} />,
			},
		},
		MuiDialog: {
			defaultProps: {
				container: rootElement,
			},
		},
		MuiPopover: {
			defaultProps: {
				elevation: 2,
				container: rootElement,
			},
		},
		MuiPopper: {
			defaultProps: {
				container: rootElement,
			},
		},
		MuiMenu: {
			defaultProps: {
				elevation: 2,
			},
		},
		MuiListItemButton: {
			defaultProps: {
				LinkComponent: LinkBehavior,
			},
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						border: `2px solid ${navy[500]}`,
						backgroundColor: navy[50],
					},
					'&.disableHover:hover': {
						backgroundColor: 'inherit',
					},
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				primary: {
					// Primary selection only matters when
					'&:not(:only-child)': {
						color: neutral[900],
						fontSize: '1.125rem', // 18pt
						fontWeight: 500,
					},
				},
				secondary: {
					color: neutral[600],
					fontSize: '1rem', // 16pt
					lineHeight: '1.5',
					fontWeight: 400,
				},
			},
		},
		MuiMenuItem: {
			defaultProps: {
				dense: true,
				LinkComponent: LinkBehavior,
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					color: neutral[500],
					backgroundColor: neutral[50],
				},
			},
		},
		MuiCircularProgress: {
			styleOverrides: {
				root: {
					color: navy[500],
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					color: neutral[200],
				},
				vertical: {
					borderRightWidth: '1px',
				},
			},
		},
		MuiChip: {
			defaultProps: {
				variant: 'outlined',
			},
			styleOverrides: {
				sizeSmall: {
					fontWeight: 500,
				},
				outlinedPrimary: {
					background: navy[50],
					color: navy[700],
					borderColor: navy[300],
				},
				outlined: {
					'color': neutral[700],
					'borderColor': neutral[300],
					'background': neutral[50],
					'&.MuiChip-outlinedError': {
						background: red[50],
						color: red[700],
						borderColor: red[300],
					},
					'&.MuiChip-outlinedWarning': {
						background: yellow[50],
						color: yellow[700],
						borderColor: yellow[300],
					},
					'&.MuiChip-outlinedSuccess': {
						background: green[50],
						color: green[700],
						borderColor: green[300],
					},
				},
			},
		},
		MuiTable: {
			styleOverrides: {
				root: {
					border: 'none',
				},
			},
		},
		MuiTableContainer: {
			styleOverrides: {
				root: {
					border: `1px solid ${neutral[200]}`,
					borderRadius: '5px',
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					'&:last-of-type > .MuiTableCell-body': {
						borderBottom: 'unset',
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					border: 'none',
					borderBottom: `1px solid ${neutral[200]}`,
				},
				head: {
					borderBottom: `1px solid ${neutral[200]}`,
					background: neutral[50],
					color: neutral[900],
				},
			},
		},
		MuiTablePagination: {
			defaultProps: {
				showFirstButton: true,
				showLastButton: true,
				rowsPerPageOptions: DEFAULTPAGESIZES,
				SelectProps: {
					native: true,
					fullWidth: false,
				},
			},
			styleOverrides: {
				select: {
					'&:focus': {
						backgroundColor: 'unset',
					},
				},
			},
		},
		MuiAlert: {
			defaultProps: {
				iconMapping: {
					error: <ErrorOutline fontSize="inherit" color="error" />,
					warning: <InfoOutlined fontSize="inherit" color="warning" />,
				},
			},
			styleOverrides: {
				icon: {
					alignItems: 'center',
				},
				action: {
					padding: '0',
					paddingRight: '0.5rem',
					alignItems: 'center',
				},
				// Standard Alert
				standardSuccess: {
					'& .MuiAlertTitle-root': {
						color: green[700],
					},
					'background': green[50],
					'color': green[700],
				},
				standardInfo: {
					'& .MuiAlertTitle-root': {
						color: navy[700],
					},
					'background': navy[50],
					'color': navy[700],
				},
				standardWarning: {
					'& .MuiAlertTitle-root': {
						color: yellow[700],
					},
					'background': yellow[50],
					'color': yellow[700],
				},
				standardError: {
					'& .MuiAlertTitle-root': {
						color: red[700],
					},
					'background': red[50],
					'color': red[700],
				},

				// Filled Alert
				filledSuccess: {
					'& .MuiAlertTitle-root': {
						color: green[700],
					},
				},
				filledInfo: {
					'& .MuiAlertTitle-root': {
						color: navy[700],
					},
				},
				filledWarning: {
					'& .MuiAlertTitle-root': {
						color: yellow[700],
					},
				},
				filledError: {
					'& .MuiAlertTitle-root': {
						color: red[700],
					},
					'background': red[600],
					'color': 'white',
				},

				// Outlined Alert
				outlinedInfo: {
					'& .MuiAlertTitle-root': {
						color: navy[700],
					},
					'background': navy[50],
					'color': navy[700],
					'borderColor': navy[500],
				},
				outlinedSuccess: {
					'& .MuiAlertTitle-root': {
						color: green[700],
					},
					'background': green[50],
					'color': green[700],
					'borderColor': green[500],
				},
				outlinedWarning: {
					'& .MuiAlertTitle-root': {
						color: yellow[700],
					},
					'background': yellow[50],
					'color': yellow[700],
					'borderColor': yellow[500],
				},
				outlinedError: {
					'& .MuiAlertTitle-root': {
						color: red[700],
					},
					'background': red[50],
					'color': red[700],
					'borderColor': red[500],
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				paperAnchorBottom: {
					maxHeight: 'calc(100% - 24px)',
					borderTopLeftRadius: 12,
					borderTopRightRadius: 12,
				},
			},
		},
	},
});

// set the mobile typography
// we need to declare the rest of the theme first, so that we can use breakpoints
theme.typography.h1[theme.breakpoints.down('sm')] = { fontSize: '1.5rem' }; // 24pt
theme.typography.h2[theme.breakpoints.down('sm')] = { fontSize: '1.375rem' }; // 22pt

export const AppThemeProvider = ({
	children,
}: {
	children?: React.ReactNode[] | React.ReactNode;
}) => {
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	);
};
