import { gql, useMutation, useQuery } from '@apollo/client';
import {
	Mutation,
	MutationOrganizationAccountUpdateConversationInsuranceArgs,
	Query,
	QueryOrganizationAccountGetConversationInsuranceArgs,
} from 'middleware-types';

export const GET_ORGANIZATION_CONVERSATION_INSURANCE = gql`
	query getOrganizationConversationInsurance($organizationId: ID!) {
		organizationAccountGetConversationInsurance(organizationId: $organizationId) {
			conversationInsuranceUrl
		}
	}
`;

export const useOrgAccountGetConversationInsurance = (organizationId: string) => {
	const { data, loading, error } = useQuery<
		Pick<Query, 'organizationAccountGetConversationInsurance'>,
		QueryOrganizationAccountGetConversationInsuranceArgs
	>(GET_ORGANIZATION_CONVERSATION_INSURANCE, {
		variables: {
			organizationId,
		},
	});

	return {
		conversationInsurance: data?.organizationAccountGetConversationInsurance,
		loading,
		error,
	};
};

const UPDATE_ORGANIZATION_CONVERSATION_INSURANCE = gql`
	mutation updateOrganizationConversationInsurance(
		$organizationId: ID!
		$request: ConversationInsuranceInput!
	) {
		organizationAccountUpdateConversationInsurance(
			organizationId: $organizationId
			request: $request
		)
	}
`;

export const useOrgAccountUpdateConversationInsurance = () => {
	const [updateConversationInsurance, { loading, error }] = useMutation<
		Pick<Mutation, 'organizationAccountUpdateConversationInsurance'>,
		MutationOrganizationAccountUpdateConversationInsuranceArgs
	>(UPDATE_ORGANIZATION_CONVERSATION_INSURANCE);

	return {
		updateConversationInsurance,
		loading,
		error,
	};
};
