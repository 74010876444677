import { Box, Stack } from '@mui/material';
import React from 'react';

/**
 * LightShell contains a light, out of app experience.
 *
 * @param {LightShellProps} props
 * @returns
 */
export const LightShell = (props: LightShellProps) => (
	<Stack width="100vw" height="100vh" flex={1}>
		{Boolean(props.banner) && props.banner}
		{(Boolean(props.right) || Boolean(props.left)) && (
			<Stack
				component="nav"
				direction="row"
				justifyContent="space-between"
				overflow="auto"
				sx={{
					minHeight: '3.75rem',
					paddingX: '1rem',
					backgroundColor: 'background.paper',
					borderBottom: '1px solid',
					borderBottomColor: 'neutral.200',
				}}>
				<Stack direction="row" alignItems="center" spacing={2}>
					<img style={{ height: '2.5rem' }} src="/img/evolve-logo.svg" alt="FTEvolve" />
					<Stack direction="row" spacing={1} alignItems="center">
						{props.left}
					</Stack>
				</Stack>
				<Stack direction="row" spacing={1} alignItems="center">
					{props.right}
				</Stack>
			</Stack>
		)}

		<Box display="flex" flexDirection="column" flexGrow={1} overflow="auto">
			{props.children}
		</Box>
	</Stack>
);

type LightShellProps = {
	left?: React.ReactNode;
	right?: React.ReactNode;
	children?: React.ReactNode[] | React.ReactNode;
	banner?: React.ReactNode;
};
