import { gql, useQuery } from '@apollo/client';
import {
	Query,
	QueryOrganizationNotificationsArgs,
	QuerySystemNotificationsArgs,
} from 'middleware-types';
import { useOrgId } from '../../org/outlet';
import { NotificationSummariesPageType } from '../notification-summaries-page';

const NOTIFICATION_SUMMARY_RESPONSE_FIELDS = gql`
	fragment NotificationSummaryResponseFields on PagingResponseOfNotificationSummary {
		totalCount
		items {
			id
			createdUtc
			inAppText
			smsText
			emailSubject
			emailPlainTextBody
		}
	}
`;

/** system notifications */
export const GET_SYSTEM_NOTIFICATIONS = gql`
	${NOTIFICATION_SUMMARY_RESPONSE_FIELDS}
	query GetSystemNotifications($searchText: String!, $pageSize: Int!, $offset: Int!) {
		systemNotifications(searchText: $searchText, pageSize: $pageSize, offset: $offset) {
			...NotificationSummaryResponseFields
		}
	}
`;

const useSystemNotifications = (searchText: string, pageSize: number, offset: number) => {
	const { data, previousData, refetch, loading } = useQuery<
		Pick<Query, 'systemNotifications'>,
		QuerySystemNotificationsArgs
	>(GET_SYSTEM_NOTIFICATIONS, {
		fetchPolicy: 'cache-and-network',
		variables: { searchText, pageSize, offset },
	});

	const response = data?.systemNotifications;
	const notificationSummaries = response?.items ?? [];
	const totalCount = response?.totalCount ?? previousData?.systemNotifications.totalCount ?? 0;
	return { notificationSummaries, totalCount, refetch, loading };
};

/** organization notifications */
export const GET_ORGANIZATION_NOTIFICATIONS = gql`
	${NOTIFICATION_SUMMARY_RESPONSE_FIELDS}
	query GetOrganizationNotifications(
		$organizationId: ID!
		$searchText: String!
		$pageSize: Int!
		$offset: Int!
	) {
		organizationNotifications(
			organizationId: $organizationId
			searchText: $searchText
			pageSize: $pageSize
			offset: $offset
		) {
			...NotificationSummaryResponseFields
		}
	}
`;

const useOrganizationNotifications = (searchText: string, pageSize: number, offset: number) => {
	const organizationId = useOrgId();
	const { data, previousData, refetch, loading } = useQuery<
		Pick<Query, 'organizationNotifications'>,
		QueryOrganizationNotificationsArgs
	>(GET_ORGANIZATION_NOTIFICATIONS, {
		fetchPolicy: 'cache-and-network',
		variables: { organizationId, searchText, pageSize, offset },
	});

	const response = data?.organizationNotifications;
	const notificationSummaries = response?.items ?? [];
	const totalCount =
		response?.totalCount ?? previousData?.organizationNotifications.totalCount ?? 0;
	return { notificationSummaries, totalCount, refetch, loading };
};

/** choose which hook to use based on which page we are looking at */
export const useNotificationSummaries = (
	type: NotificationSummariesPageType,
	searchText: string,
	pageSize: number,
	offset: number
) => {
	const hook = type === 'system' ? useSystemNotifications : useOrganizationNotifications;
	return hook(searchText, pageSize, offset);
};
