import { Box, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

/**
 * Full Loading indicator
 */
export const Loading = ({ message }: { message?: string }): React.JSX.Element => (
	<Box height="100%" display="flex" alignItems="center">
		<Stack width="100%" alignItems="center" spacing={2}>
			<img style={{ width: '5rem' }} src="/img/evolve-logo.svg" alt="FTEvolve" />
			<Stack direction="row" spacing={3} ml={2.5}>
				<div className={clsx('h-4 w-4 animate-bounce rounded-full bg-neutral-200')}></div>
				<div
					className={clsx(
						'animation-delay-[0.25s] h-4 w-4 animate-bounce rounded-full bg-neutral-200 '
					)}></div>
				<div
					className={clsx(
						'animation-delay-[0.5s] h-4 w-4 animate-bounce rounded-full bg-neutral-200'
					)}></div>
			</Stack>
			{message && <Typography variant="h3">{message}</Typography>}
		</Stack>
	</Box>
);
