import { useTheme } from '@mui/material';

export const useMapColors = () => {
	const { palette } = useTheme();

	return {
		defaultColor: palette.neutral[50],
		activeColor: palette.primary.main,
		expiredColor: palette.secondary.dark,
		inactiveColor: palette.neutral[400],
		borderColor: palette.neutral[300],
	};
};
