import { gql, useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	Mutation,
	MutationUserAccountQuickLinkCreateArgs,
	MutationUserAccountQuickLinkDeleteArgs,
	MutationUserAccountQuickLinkUpdateArgs,
	Query,
	QueryQuickLinksArgs,
	QuickLinkCreate,
	QuickLinkPatch,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useSession } from 'utils/session';

// this fragment is also used by orgQuickLinkHooks
export const QUICK_LINK_FIELDS = gql`
	fragment QuickLinkFields on QuickLink {
		id
		title
		url
		iconFileId
		iconFile {
			currentInstance {
				id
				fileName
				fileSize
				uploadedUtc
				virusStatus
			}
		}
	}
`;

const GET_QUICK_LINKS = gql`
	${QUICK_LINK_FIELDS}
	query GetQuickLinks($userId: ID!) {
		quickLinks(userId: $userId) {
			...QuickLinkFields
		}
	}
`;

const ADD_QUICK_LINK = gql`
	${QUICK_LINK_FIELDS}
	mutation AddQuickLink($userId: ID!, $request: QuickLinkCreate!) {
		userAccountQuickLinkCreate(userId: $userId, request: $request) {
			...QuickLinkFields
		}
	}
`;

const UPDATE_QUICK_LINK = gql`
	${QUICK_LINK_FIELDS}
	mutation UpdateQuickLink($userId: ID!, $quickLinkId: ID!, $request: QuickLinkPatch!) {
		userAccountQuickLinkUpdate(userId: $userId, quickLinkId: $quickLinkId, request: $request) {
			...QuickLinkFields
		}
	}
`;

const DELETE_QUICK_LINK = gql`
	mutation DeleteQuickLink($userId: ID!, $quickLinkId: ID!) {
		userAccountQuickLinkDelete(userId: $userId, quickLinkId: $quickLinkId)
	}
`;

export const useUserQuickLinks = () => {
	const { user } = useSession();
	const toast = useToast();

	// GET
	const { data, loading } = useQuery<Pick<Query, 'quickLinks'>, QueryQuickLinksArgs>(
		GET_QUICK_LINKS,
		{
			variables: {
				userId: user.userId,
			},
			onError: () => toast.push('Unable to load Quick Links', { variant: 'error' }),
		}
	);

	const quickLinks = data?.quickLinks ?? [];

	// ADD
	const [_addQuickLink] = useMutation<
		Pick<Mutation, 'userAccountQuickLinkCreate'>,
		MutationUserAccountQuickLinkCreateArgs
	>(ADD_QUICK_LINK, {
		refetchQueries: [GET_QUICK_LINKS],
		awaitRefetchQueries: true,
	});

	const addQuickLink = async (request: QuickLinkCreate) => {
		return await _addQuickLink({ variables: { userId: user.userId, request } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Quick Link added successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	// UPDATE
	const [_updateQuickLink] = useMutation<
		Pick<Mutation, 'userAccountQuickLinkUpdate'>,
		MutationUserAccountQuickLinkUpdateArgs
	>(UPDATE_QUICK_LINK);

	const updateQuickLink = async (quickLinkId: string, request: QuickLinkPatch) => {
		return await _updateQuickLink({ variables: { userId: user.userId, quickLinkId, request } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Quick Link updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	// DELETE
	const [_deleteQuickLink, { loading: deleteLoading }] = useMutation<
		Pick<Mutation, 'userAccountQuickLinkDelete'>,
		MutationUserAccountQuickLinkDeleteArgs
	>(DELETE_QUICK_LINK, {
		refetchQueries: [GET_QUICK_LINKS],
		awaitRefetchQueries: true,
	});

	const deleteQuickLink = async (quickLinkId: string) => {
		return await _deleteQuickLink({ variables: { userId: user.userId, quickLinkId } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Quick Link deleted successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		quickLinks,
		loading,
		addQuickLink,
		updateQuickLink,
		deleteQuickLink,
		deleteLoading,
	};
};
