import { LoadingButton } from '@mui/lab';
import { Link, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import {
	codeValidationWhiteSpaceDisallowed,
	confirmPasswordValidation,
	passwordValidation,
} from 'components/ui/LoginYups';
import { TextField } from 'components/ui/fields';
import { Form, Formik, FormikHelpers } from 'formik';
import { censorEmail } from 'pages/auth/confirm-email';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import * as Yup from 'yup';

export type ResetPasswordProps = {
	email: string;
};

const ResetPassword = ({ email }: ResetPasswordProps) => {
	const navigate = useNavigate();
	const largeScreen = useWindowDimensions();

	const handleSubmit = async (
		vals: ResetPasswordForm,
		helpers: FormikHelpers<ResetPasswordForm>
	) => {
		try {
			await Auth.forgotPasswordSubmit(email, vals.code, vals.password);
			navigate('/auth/login');
		} catch (e: any) {
			helpers.setErrors({ code: e?.message });
			if (e?.code === 'InvalidPasswordException') helpers.setErrors({ password: e?.message });
		}
	};

	return (
		<Formik<ResetPasswordForm>
			initialValues={{ code: '', password: '', confirmPassword: '' }}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			validateOnBlur={false}
			validateOnChange={false}>
			{({ isSubmitting, errors, setErrors }) => (
				<Form className="m-auto flex w-96 flex-col text-center">
					<img
						className={clsx(' mx-auto mb-10 w-32', {
							hidden: largeScreen,
						})}
						src="/img/evolve-logo.svg"
						alt="FTEvolve"
					/>
					<Typography className="text-neutral-900" mb={2} variant="h2">
						Reset Password
					</Typography>
					<Typography className="text-neutral-900" mb={2}>
						We have sent a code by email to {censorEmail(email)}. Enter it below to
						reset the account&apos;s password.
					</Typography>
					<div className="mb-4 flex flex-col gap-3">
						<TextField
							label="Code *"
							type="code"
							name="code"
							InputLabelProps={{ shrink: true }}
							error={!!errors.code}
							helperText={errors?.code}
							onChangeCapture={() =>
								setErrors({
									...errors,
									code: undefined,
								})
							}
							FormHelperTextProps={{
								className: clsx(!!errors?.code && 'static', 'text-left mb-1'),
							}}
							autoComplete="one-time-code"
							sx={{
								fieldset: { borderColor: 'neutral.700' },
							}}
							focused
						/>
						{/* These inputs prevent browsers from autofilling
							the MFA input with email and password */}
						<input type="hidden" />
						<input type="hidden" />

						<TextField
							label="Password *"
							type="password"
							name="password"
							InputLabelProps={{ shrink: true }}
							error={!!errors?.password}
							helperText={errors?.password}
							onChangeCapture={() =>
								setErrors({
									...errors,
									password: undefined,
								})
							}
							FormHelperTextProps={{
								className: clsx(!!errors?.password && 'static', 'text-left mb-1'),
							}}
							sx={{
								fieldset: { borderColor: 'neutral.700' },
							}}
						/>
						<TextField
							label="Confirm Password *"
							type="password"
							name="confirmPassword"
							InputLabelProps={{ shrink: true }}
							error={!!errors?.confirmPassword}
							helperText={errors?.confirmPassword}
							onChangeCapture={() =>
								setErrors({
									...errors,
									confirmPassword: undefined,
								})
							}
							FormHelperTextProps={{
								className: clsx(
									!!errors?.confirmPassword && 'static',
									'text-left mb-1'
								),
							}}
							sx={{
								fieldset: { borderColor: 'neutral.700' },
							}}
						/>
					</div>
					<LoadingButton
						className="mt-5 text-white "
						data-test="submit-btn"
						variant="contained"
						color="primary"
						fullWidth
						type="submit"
						loading={isSubmitting}>
						Change Password
					</LoadingButton>
					<Typography className="mt-5">
						<Link data-test="back-btn" className="text-navy-500" href="/auth/login">
							Back to Log in
						</Link>
					</Typography>
				</Form>
			)}
		</Formik>
	);
};

const validationSchema = Yup.object({
	// Whitespace not allowed in validation, and not filtered out in submission
	code: codeValidationWhiteSpaceDisallowed,
	password: passwordValidation,
	confirmPassword: confirmPasswordValidation,
});

export type ResetPasswordForm = {
	code: string;
	password: string;
	confirmPassword: string;
};

export default ResetPassword;
