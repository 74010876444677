import { ApolloError } from '@apollo/client';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	FormControlLabel,
	Grid,
	TextField as MTTextField,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { Alert } from 'components/ui/alert';
import { CheckboxField, PhoneNumberField, TextField } from 'components/ui/fields';
import { AddressField, AddressFieldSkeleton } from 'components/ui/fields/address';
import { Formik, FormikProps } from 'formik';
import { OrganizationCreation } from 'middleware-types';
import { theme } from 'utils/theme';
import { AnyObjectSchema } from 'yup';

interface OrgFormProps {
	initialValues: OrganizationCreation;
	onSubmit: (values: OrganizationCreation, redirect: boolean) => Promise<boolean>;
	validationSchema?: AnyObjectSchema | null;
	loading: boolean;
	error: ApolloError | undefined;
	linkedOrg?: boolean;
}

export interface OrganizationCreationExtended extends OrganizationCreation {
	importLogo?: boolean;
}

/**
 * OrgForm - The organization form.
 *
 */
export const OrgForm = (props: OrgFormProps) => {
	const { initialValues, onSubmit, validationSchema, loading, error, linkedOrg } = props;
	return (
		<Formik<OrganizationCreationExtended>
			initialValues={initialValues}
			onSubmit={(vals) => onSubmit(vals, true)}
			validationSchema={validationSchema}
			enableReinitialize>
			{(props: FormikProps<OrganizationCreationExtended>) => (
				<Card id="card-parent">
					<CardContent
						id="card-content-child"
						sx={{
							padding: 0,
						}}>
						<Stack>
							{loading ? (
								<OrgFormSkeleton />
							) : (
								<>
									<Stack
										borderBottom={`1px solid ${theme.palette.divider}`}
										padding={2}>
										<Typography variant="h2" marginBottom={1}>
											Organization Details
										</Typography>
										<Grid container spacing={1} columnSpacing={2}>
											<Grid xs={12} md={6} item>
												<TextField
													label="Legal Name"
													required
													name="legalName"
													disabled={props.isSubmitting}
												/>
											</Grid>
											<Grid xs={12} md={6} item>
												<TextField
													label="Display Name"
													required
													name="displayName"
													disabled={props.isSubmitting}
												/>
											</Grid>
										</Grid>
									</Stack>
									<Stack
										borderBottom={`1px solid ${theme.palette.divider}`}
										padding={2}>
										<Typography variant="h2" marginBottom={1}>
											Primary Address
										</Typography>
										<AddressField
											name="mailingAddress"
											required
											disabled={props.isSubmitting}
										/>
									</Stack>
									<Stack
										borderBottom={`1px solid ${theme.palette.divider}`}
										padding={2}>
										<Typography variant="h2" marginBottom={1}>
											Contact Information
										</Typography>
										<Grid container spacing={1} columnSpacing={2}>
											<Grid xs={12} md={4} item>
												<TextField
													label="Email Address"
													required
													name="systemEmailAddress"
													type="email"
													disabled={props.isSubmitting}
												/>
											</Grid>
											<Grid xs={12} md={4} item>
												<PhoneNumberField
													label="Phone Number"
													required
													name="mainPhoneNumber"
													disabled={props.isSubmitting}
												/>
											</Grid>
										</Grid>
									</Stack>
								</>
							)}
						</Stack>
					</CardContent>
					{error && <Alert error={error} />}
					<CardActions
						sx={{
							width: '100%',
							padding: 2,
						}}>
						<Grid
							container
							display="flex"
							justifyContent={linkedOrg ? 'space-between' : 'flex-end'}>
							<Grid item>
								{linkedOrg && (
									<FormControlLabel
										control={<CheckboxField name="importLogo" />}
										label="Import logo image"
									/>
								)}
							</Grid>
							<Grid item>
								<Button
									color="primary"
									variant="contained"
									disabled={!props.isValid || !props.dirty || props.isSubmitting}
									onClick={props.submitForm}>
									{`Create Organization`}
								</Button>
							</Grid>
						</Grid>
					</CardActions>
				</Card>
			)}
		</Formik>
	);
};

/**
 * OrgFormSkeleton - The skeleton loading for organization forms.
 *
 */
const OrgFormSkeleton = () => (
	<Stack padding={2}>
		<Typography variant="h2">Organization Details</Typography>
		<Grid container spacing={1} columnSpacing={2}>
			<Grid xs={12} md={6} item>
				<Skeleton width="100%">
					<MTTextField />
				</Skeleton>
			</Grid>
			<Grid xs={12} md={6} item>
				<Skeleton width="100%">
					<MTTextField />
				</Skeleton>
			</Grid>
		</Grid>
		<Typography variant="h2">Organization Primary Address</Typography>
		<AddressFieldSkeleton />
		<Typography variant="h2">Organization Contact Information</Typography>
		<Grid container spacing={1} columnSpacing={2}>
			<Grid xs={12} md={4} item>
				<Skeleton width="100%">
					<MTTextField />
				</Skeleton>
			</Grid>
			<Grid xs={12} md={4} item>
				<Skeleton width="100%">
					<MTTextField />
				</Skeleton>
			</Grid>
		</Grid>
	</Stack>
);
