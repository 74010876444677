import { Box, Divider, Skeleton, Stack } from '@mui/material';
import { Fragment } from 'react';

export const SummariesListSkeleton = () => {
	return (
		<Box>
			{[...Array(5)].map((_, index) => (
				<Fragment key={index}>
					<Stack p={1.5} spacing={1.5} direction="row" alignItems="center">
						<Skeleton variant="circular" height={40} width={40} />
						<Stack flex={1}>
							<Stack direction="row" justifyContent="space-between">
								<Skeleton variant="text" width={100} />
								<Skeleton variant="text" width={30} />
							</Stack>
							<Skeleton variant="text" />
						</Stack>
					</Stack>
					<Divider />
				</Fragment>
			))}
		</Box>
	);
};
