import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { AddressFieldLookup, Country, Query } from 'middleware-types';
import { PageError } from 'utils/errors';

/* Query for fetching country data. */
export const COUNTRIES = gql`
	query countries {
		countries {
			id
			iso2CountryCode
			iso3CountryCode
			name
			phonePrefix
			phoneNumberFormat
			address1Label
			address2Label
			municipalityLabel
			adminArea1Label
			adminArea2Label
			postalCodeLabel
			postalCodeRegex
			address1Visibility
			address2Visibility
			municipalityVisibility
			adminArea1Visibility
			adminArea2Visibility
			postalCodeVisibility
			addressFieldLookups {
				id
				field
				value
				displayName
				displayOrder
			}
			nationalIdentificationNumberRegex
		}
	}
`;
NetworkStatus;
/**
 * Returns a country list from the integrated countries query.
 *
 * @returns {{loading: boolean; countries: Country[]}}
 */
export const useCountries = (): {
	loading: boolean;
	countries: Country[];
	defaultId: string | undefined;
	countriesLookupMapById: Map<string, Country>;
	countriesLookupMapByIso: Map<string, Country>;
} => {
	let countries: Country[] = [];
	const countriesQuery = useQuery<Pick<Query, 'countries'>>(COUNTRIES, {
		fetchPolicy: 'cache-first',
	});

	if (!countriesQuery.loading && countriesQuery.data)
		countries = [...countriesQuery.data.countries];
	countries = countries.filter(
		(country) => country.name === 'United States' || country.name === 'Canada'
	);
	if (countriesQuery.error) throw new PageError(countriesQuery.error.graphQLErrors);

	// Maps country ID's to country objects for easy lookup
	const countriesLookupMapById = countries.reduce((map, country) => {
		map.set(country.id, country);
		return map;
	}, new Map<string, Country>());

	// Maps country ISO3's to country objects for easy lookup
	const countriesLookupMapByIso = countries.reduce((map, country) => {
		map.set(country.iso3CountryCode, country);
		return map;
	}, new Map<string, Country>());

	return {
		loading: countriesQuery.loading,
		countries,
		defaultId: countriesQuery.data?.countries[0]?.id,
		countriesLookupMapById: countriesLookupMapById,
		countriesLookupMapByIso: countriesLookupMapByIso,
	};
};

/**
 * Returns a list of states.
 * @returns
 */
export const useUsStates = (): {
	loading: boolean;
	states: AddressFieldLookup[];
	statesLookupMapById: Map<string, AddressFieldLookup>;
} => {
	const { loading, countries } = useCountries();
	const states = countries.find((c) => c.iso2CountryCode === 'US')?.addressFieldLookups ?? [];

	const statesLookupMapById = states.reduce((map, state) => {
		map.set(state.id, state);
		return map;
	}, new Map());

	return {
		loading,
		states,
		statesLookupMapById,
	};
};

/**
 * Returns a list of provinces.
 * @returns
 */
export const useCanProvinces = (): {
	loading: boolean;
	provinces: AddressFieldLookup[];
	provincesLookupMapById: Map<string, AddressFieldLookup>;
} => {
	const { loading, countries } = useCountries();
	const provinces = countries.find((c) => c.iso2CountryCode === 'CA')?.addressFieldLookups ?? [];

	const provincesLookupMapById = provinces.reduce((map, province) => {
		map.set(province.id, province);
		return map;
	}, new Map());

	return {
		loading,
		provinces,
		provincesLookupMapById,
	};
};
