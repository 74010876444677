import { CheckOutlined, Search } from '@mui/icons-material';
import {
	Box,
	Card,
	Chip,
	Drawer,
	FormControl,
	InputAdornment,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
	Typography,
} from '@mui/material';
import { PageTitle } from 'components/ui/page';
import { LegacyUserAdjustmentCompanyWithMetrics } from 'middleware-types';
import { useState } from 'react';
import { CompanyRoleCategory, getCompanyRoleCategory } from 'utils/companyRoles';
import { useIsMobile } from 'utils/useScreenSize';
import CompanyCard from './CompanyCard';
import CompanyCardSkeleton from './CompanyCardSkeleton';
import { PreviewPanel } from './PreviewPanel';
import { useLinkedCompanies } from './hooks';

enum SortBy {
	A_TO_Z,
	MY_OVERDUE_JOBS,
	MY_JOBS_DUE_SOON,
	COMPANY_OVERDUE_JOBS,
	COMPANY_JOBS_DUE_SOON,
}

const LinkedCompaniesPage = () => {
	const isMobile = useIsMobile();

	const { companies, loading } = useLinkedCompanies();
	const [searchText, setSearchText] = useState('');
	const [sortBy, setSortBy] = useState(SortBy.A_TO_Z);
	const [roleFilter, setRoleFilter] = useState<CompanyRoleCategory | null>(null);
	const [selectedCompany, setSelectedCompany] =
		useState<LegacyUserAdjustmentCompanyWithMetrics | null>(null);

	const roleCounts: Record<CompanyRoleCategory, number> = {
		'Admin': 0,
		'Adjuster': 0,
		'Clerical': 0,
		'Claims Representative': 0,
		'Other': 0,
	};

	companies.forEach((company) => {
		const roleCategory = getCompanyRoleCategory(company.userRole);
		roleCounts[roleCategory]++;
	});

	const hasTwoOrMoreRoles = Object.values(roleCounts).filter((value) => value !== 0).length >= 2;

	const filteredAndSortedCompanies = companies
		.filter((company) => {
			if (searchText) {
				if (company.adjCoName === undefined) return false;
				const searchTextLower = searchText.toLowerCase();
				const companyNameLower = company.adjCoName.toLowerCase();
				if (!companyNameLower.includes(searchTextLower)) return false;
			}
			if (roleFilter !== null) {
				const roleCategory = getCompanyRoleCategory(company.userRole);
				if (roleCategory !== roleFilter) return false;
			}
			return true;
		})
		.sort((a, b) => {
			if (sortBy === SortBy.A_TO_Z) {
				return (a.adjCoName as string).localeCompare(b.adjCoName as string);
			}
			let aValue: number | undefined = undefined;
			let bValue: number | undefined = undefined;
			switch (sortBy) {
				case SortBy.MY_OVERDUE_JOBS:
					aValue = a.userOverdueClaimCount;
					bValue = b.userOverdueClaimCount;
					break;
				case SortBy.MY_JOBS_DUE_SOON:
					aValue = a.userImpendingClaimCount;
					bValue = b.userImpendingClaimCount;
					break;
				case SortBy.COMPANY_OVERDUE_JOBS:
					aValue = a.companyOverdueClaimCount;
					bValue = b.companyOverdueClaimCount;
					break;
				case SortBy.COMPANY_JOBS_DUE_SOON:
					aValue = a.companyImpendingClaimCount;
					bValue = b.companyImpendingClaimCount;
					break;
			}
			return (bValue ?? -1) - (aValue ?? -1);
		});

	return (
		<>
			<PageTitle title="FileTrac Legacy Linked Companies" />
			<Stack
				direction="row"
				gap={4}
				overflow="auto"
				padding={{ xs: 1.5, sm: 3 }}
				justifyContent="center"
				flex={1}>
				<Stack width={{ xs: '650px', xl: '1100px' }} gap={2} alignSelf="flex-start">
					<Stack
						direction={{ xs: 'column', xl: 'row' }}
						alignItems={{ xs: 'stretch', xl: 'center' }}
						spacing={2}>
						<Stack flexDirection="row" alignItems="center" gap={1.5}>
							<img src="/img/FT-logo.svg" alt="FileTrac Logo" />
							<Typography variant="h1" flex={1}>
								FileTrac Linked Companies
							</Typography>
						</Stack>
						<OutlinedInput
							size="small"
							placeholder="Search"
							value={searchText}
							onChange={(e) => setSearchText(e.target.value)}
							startAdornment={
								<InputAdornment position="start">
									<Search />
								</InputAdornment>
							}
						/>
						<FormControl size="small">
							<Select
								sx={{ backgroundColor: 'white' }}
								value={sortBy}
								onChange={(e) => setSortBy(e.target.value as SortBy)}>
								<MenuItem value={SortBy.A_TO_Z}>Sort by Company Name A-Z</MenuItem>
								{(roleCounts.Admin > 0 || roleCounts.Adjuster > 0) && (
									<MenuItem value={SortBy.MY_OVERDUE_JOBS}>
										Sort by My Overdue Jobs
									</MenuItem>
								)}
								{(roleCounts.Admin > 0 || roleCounts.Adjuster > 0) && (
									<MenuItem value={SortBy.MY_JOBS_DUE_SOON}>
										Sort by My Jobs Due Soon
									</MenuItem>
								)}
								{roleCounts.Admin > 0 && (
									<MenuItem value={SortBy.COMPANY_OVERDUE_JOBS}>
										Sort by Company Overdue Jobs
									</MenuItem>
								)}
								{roleCounts.Admin > 0 && (
									<MenuItem value={SortBy.COMPANY_JOBS_DUE_SOON}>
										Sort by Company Jobs Due Soon
									</MenuItem>
								)}
							</Select>
						</FormControl>
					</Stack>
					{hasTwoOrMoreRoles && (
						<Stack direction="row" gap={1} flexWrap="wrap">
							<Chip
								color={roleFilter === null ? 'primary' : undefined}
								onClick={() => setRoleFilter(null)}
								label={`All Roles (${companies.length})`}
								icon={roleFilter === null ? <CheckOutlined /> : undefined}
							/>
							{Object.entries(roleCounts).map(([key, value]) => {
								if (value === 0) return null;
								return (
									<Chip
										key={key}
										color={roleFilter === key ? 'primary' : undefined}
										onClick={() => setRoleFilter(key as CompanyRoleCategory)}
										label={`${key} (${value})`}
										icon={roleFilter === key ? <CheckOutlined /> : undefined}
									/>
								);
							})}
						</Stack>
					)}
					<Box
						gap={2}
						display="grid"
						gridAutoRows="1fr"
						gridTemplateColumns={{ xs: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}>
						{loading && companies.length === 0
							? [...Array(6)].map((_, index) => <CompanyCardSkeleton key={index} />)
							: filteredAndSortedCompanies.map((company, index) => (
									<CompanyCard
										key={index}
										company={company}
										loading={loading}
										selected={selectedCompany === company}
										onSelected={() => {
											setSelectedCompany(company);
										}}
									/>
							  ))}
					</Box>
				</Stack>
				{isMobile ? (
					<Drawer
						open={selectedCompany !== null}
						anchor="right"
						PaperProps={{ sx: { width: '100%' } }}>
						<PreviewPanel
							company={selectedCompany}
							onClose={() => setSelectedCompany(null)}
						/>
					</Drawer>
				) : (
					<Card variant="outlined" sx={{ position: 'sticky', top: 0, width: '650px' }}>
						<PreviewPanel company={selectedCompany} />
					</Card>
				)}
			</Stack>
		</>
	);
};

export default LinkedCompaniesPage;
