import { CampaignOutlined, Close, RefreshOutlined, Search } from '@mui/icons-material';
import {
	Box,
	Button,
	Divider,
	Drawer,
	IconButton,
	InputAdornment,
	Paper,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import { SendNotificationModal } from 'components/ui/notifications/send-notification-modal';
import { PageContent } from 'components/ui/page';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { DEFAULTPAGESIZES } from 'utils/theme';
import { useIsMobile } from 'utils/useScreenSize';
import { useOrgId } from '../org/outlet';
import { useNotificationSummaries } from './hooks/use-notification-summaries';
import { SelectedNotificationPanel } from './selected-notification-panel';

const DEBOUNCE_TIMER_IN_MS = 500;

export type NotificationSummariesPageType = 'system' | 'organization';

interface NotificationSummariesPageProps {
	type: NotificationSummariesPageType;
}

export const NotificationSummariesPage = ({ type }: NotificationSummariesPageProps) => {
	const isMobile = useIsMobile();

	// paging options
	const [pageSize, setPageSize] = useState(DEFAULTPAGESIZES[0]);
	const [page, setPage] = useState(0);

	// search text
	const [searchFieldValue, _setSearchFieldValue] = useState('');
	const [searchText, setSearchText] = useState('');

	const debouncedSetSearchValue = useCallback(
		debounce((value: string) => {
			if (!value) return;
			setSearchText(value);
		}, DEBOUNCE_TIMER_IN_MS),
		[]
	);

	const setSearchFieldValue = (value: string) => {
		_setSearchFieldValue(value);
		debouncedSetSearchValue(value);
		if (!value) setSearchText(value);
	};

	// options for new notification dialog
	const [sendModalOpen, setSendModalOpen] = useState(false);
	const orgId = type === 'system' ? undefined : useOrgId();

	const { notificationSummaries, totalCount, refetch, loading } = useNotificationSummaries(
		type,
		searchText,
		pageSize,
		page * pageSize
	);

	// selected notification
	const [selectedNotificationId, setSelectedNotificationId] = useState<string>();

	return (
		<PageContent noScroll mobileNoPadding>
			<Stack height="100%" direction="row" spacing={2}>
				<Stack
					component={Paper}
					flex={1}
					p={2}
					pb={0}
					spacing={2}
					borderRadius={{ xs: 0, sm: 1 }}>
					<Stack
						spacing={1.5}
						direction={{ xs: 'column', sm: 'row' }}
						alignItems={{ xs: 'stretch', sm: 'center' }}
						justifyContent="space-between">
						<Typography variant="h2">
							{type === 'system' ? 'System Announcements' : 'Announcements'}
						</Typography>
						<Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1.5, sm: 2 }}>
							<TextField
								value={searchFieldValue}
								onChange={(e) => setSearchFieldValue(e.target.value)}
								placeholder="Search"
								fullWidth={false}
								sx={{ width: { sm: 300 } }}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Search />
										</InputAdornment>
									),
									endAdornment: searchFieldValue ? (
										<InputAdornment position="end">
											<IconButton onClick={() => setSearchFieldValue('')}>
												<Close />
											</IconButton>
										</InputAdornment>
									) : undefined,
								}}
							/>
							<Button
								variant="outlined"
								startIcon={<RefreshOutlined />}
								onClick={() => refetch()}>
								Refresh List
							</Button>
							<Button
								color="primary"
								variant="contained"
								startIcon={<CampaignOutlined />}
								onClick={() => setSendModalOpen(true)}>
								{type === 'system'
									? 'Send System Announcement'
									: 'Send Announcement'}
							</Button>
						</Stack>
					</Stack>
					<Stack flex={1} overflow="hidden">
						<TableContainer>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell>Timestamp</TableCell>
										<TableCell>In-App Message</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{loading ? (
										[...Array(3)].map((_, rowIndex) => (
											<TableRow key={rowIndex}>
												{[...Array(2)].map((_, cellIndex) => (
													<TableCell key={cellIndex}>
														<Skeleton variant="text" />
													</TableCell>
												))}
											</TableRow>
										))
									) : notificationSummaries.length === 0 ? (
										<TableRow>
											<TableCell colSpan={3} align="center">
												No results
											</TableCell>
										</TableRow>
									) : (
										notificationSummaries.map((notificationSummary) => (
											<TableRow
												key={notificationSummary.id}
												hover
												selected={
													notificationSummary.id ===
													selectedNotificationId
												}
												onClick={() =>
													setSelectedNotificationId(
														notificationSummary.id
													)
												}>
												<TableCell>
													{format(
														new Date(notificationSummary.createdUtc),
														'MMMM d, y h:mm a'
													)}
												</TableCell>
												<TableCell>
													{notificationSummary.inAppText}
												</TableCell>
											</TableRow>
										))
									)}
								</TableBody>
							</Table>
						</TableContainer>
						<Box flex={1} />
						<TablePagination
							component={Stack}
							flexShrink={0}
							count={totalCount}
							page={page}
							onPageChange={(_e, v) => setPage(v)}
							rowsPerPage={pageSize}
							onRowsPerPageChange={(e) => setPageSize(parseInt(e.target.value))}
							rowsPerPageOptions={DEFAULTPAGESIZES}
						/>
					</Stack>
				</Stack>
				{!isMobile && (
					<Stack component={Paper} width={500} overflow="auto">
						<SelectedNotificationPanel
							pageType={type}
							notificationId={selectedNotificationId}
						/>
					</Stack>
				)}
			</Stack>
			{/** modal to send a new notification */}
			<SendNotificationModal
				open={sendModalOpen}
				onClose={() => setSendModalOpen(false)}
				orgId={orgId}
			/>
			{/** drawer for mobile */}
			{isMobile && (
				<Drawer open={selectedNotificationId !== undefined} anchor="right">
					<Stack overflow="hidden">
						<Stack
							direction="row"
							alignItems="center"
							px={2}
							py={1}
							justifyContent="space-between">
							<Typography variant="h3">Notification Details</Typography>
							<IconButton onClick={() => setSelectedNotificationId(undefined)}>
								<Close />
							</IconButton>
						</Stack>
						<Divider />
						<Stack flex={1} overflow="auto">
							<SelectedNotificationPanel
								pageType={type}
								notificationId={selectedNotificationId}
							/>
						</Stack>
					</Stack>
				</Drawer>
			)}
		</PageContent>
	);
};
