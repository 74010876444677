import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { ContainedDrawer } from 'components/ui/containedDrawer';
import { PromptClippingResponse } from 'middleware-types';
import { useDeleteClipping } from './hooks';

interface DeletePromptDrawerProps {
	open: boolean;
	onClose: () => void;
	clipping: PromptClippingResponse;
}

export const DeleteClippingDrawer = ({ open, onClose, clipping }: DeletePromptDrawerProps) => {
	const { deleteClipping, loading } = useDeleteClipping();

	const onSubmit = () => {
		deleteClipping(clipping.id).then((success) => {
			if (success) onClose();
		});
	};

	return (
		<ContainedDrawer
			open={open}
			anchor="top"
			containerid="evox-container"
			PaperProps={{ sx: { borderRadius: 1 } }}>
			<Stack p={2} spacing={1}>
				<Typography variant="h3">Delete &quot;{clipping.title}&quot;?</Typography>
				<Typography variant="body2">This action cannot be undone.</Typography>
				<Stack direction="row" spacing={1} justifyContent="flex-end">
					<LoadingButton
						loading={loading}
						variant="outlined"
						color="primary"
						onClick={onClose}>
						Cancel
					</LoadingButton>
					<LoadingButton
						loading={loading}
						variant="contained"
						color="error"
						onClick={onSubmit}>
						Yes, delete
					</LoadingButton>
				</Stack>
			</Stack>
		</ContainedDrawer>
	);
};
