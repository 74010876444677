import { useState } from 'react';
import { ArrowRightAltOutlined } from '@mui/icons-material';
import { OrangeMaskIcon } from '../../components/ui/icons';
import { Grid, Button, Collapse, TableCell } from '@mui/material';

const changeType = {
	EntityAdded: 'created',
	EntityModified: 'updated',
	SubEntityChanged: 'updated',
	EntityDeleted: 'deleted',
};

/**
 * History Change props.
 */
type HistoryChangeProps = {
	changeType:
		| 'NoChange'
		| 'EntityAdded'
		| 'EntityModified'
		| 'EntityDeleted'
		| 'SubEntityChanged';
	fieldName: string;
	oldValue: string | undefined | null;
	newValue: string | undefined | null;
	siteUserName: string | undefined | null;
	userName: string | undefined | null;
	isImpersonated: boolean;
	parentValue: string | undefined | null;
	entityName: string;
	entityCurrentValue: string | undefined | null;
};

/**
 * Renders a history Entry for the Change column of the History log page.
 * @param props
 */
export const HistoryEntry = (props: HistoryChangeProps): React.JSX.Element => {
	const changeAction = changeType[props.changeType];
	const collapsibleRow =
		(props.oldValue && props.oldValue.length > 600) ||
		(props.newValue && props.newValue.length > 600)
			? true
			: false;
	const [open, setOpen] = useState(false);
	let impersonator = '';
	let name = '';
	let isSiteUser = false;
	let oldValueBlurb;
	let newValueBlurb;

	if (collapsibleRow) {
		oldValueBlurb = props.oldValue
			?.slice(0, props.oldValue.length >= 150 ? 150 : props.oldValue.length)
			.concat(props.oldValue.length >= 150 ? '...' : '');
		newValueBlurb = props.newValue
			?.slice(0, props.newValue.length >= 150 ? 150 : props.newValue.length)
			.concat(props.newValue.length >= 150 ? '...' : '');
	}

	// This logic is used to determine if the user's name should be displayed as
	// a site user, standard user, or impersonator.
	name =
		props.siteUserName ?? props.userName ?? props.entityCurrentValue ?? props.parentValue ?? '';

	if (props.siteUserName) {
		isSiteUser = true;

		if (props.isImpersonated) {
			impersonator = props.siteUserName;
			name = props.userName ?? props.entityCurrentValue ?? props.parentValue ?? '';
		}
	}

	return (
		<TableCell>
			{collapsibleRow ? (
				<>
					<Grid container spacing={0} className={open ? 'hidden' : ''}>
						<Grid item xs={12}>
							{props.isImpersonated ? (
								<span>
									<span className="font-bold text-yellow-600">
										{impersonator}
									</span>
									<OrangeMaskIcon className="ml-px h-4 align-middle" />{' '}
									<span>as</span> <span className="font-bold">{name}</span>
								</span>
							) : (
								<span
									className={
										'font-bold' + ' ' + (isSiteUser ? 'text-yellow-600' : '')
									}>
									{name}
								</span>
							)}
							<span> {changeAction} </span>
							<span className="font-bold">{props.fieldName}</span>
							{props.changeType !== 'SubEntityChanged' && (
								<Grid container spacing={0}>
									<Grid
										item
										className="max-w-2/5 my-1 min-w-min whitespace-pre-wrap">
										{oldValueBlurb ? oldValueBlurb : 'None'}
									</Grid>
									<Grid item className="mx-1 my-px">
										<ArrowRightAltOutlined className="mx-1 align-middle" />
									</Grid>
									<Grid
										item
										className="max-w-2/5 my-1 min-w-min whitespace-pre-wrap">
										{newValueBlurb ? newValueBlurb : 'None'}
									</Grid>
								</Grid>
							)}
						</Grid>
					</Grid>
					<Collapse in={open} timeout={0} unmountOnExit>
						<Grid container spacing={0} className="max-h-1/2 overflow-y-auto">
							<Grid item xs={12}>
								{props.isImpersonated ? (
									<span>
										<span className="font-bold text-yellow-600">
											{impersonator}
										</span>
										<OrangeMaskIcon className="ml-px h-4 align-middle" />{' '}
										<span>as</span> <span className="font-bold">{name}</span>
									</span>
								) : (
									<span
										className={
											'font-bold' +
											' ' +
											(isSiteUser ? 'text-yellow-600' : '')
										}>
										{name}
									</span>
								)}
								<span> {changeAction} </span>
								<span className="font-bold">{props.fieldName}</span>
								{props.changeType !== 'SubEntityChanged' && (
									<>
										<Grid container spacing={0}>
											<Grid
												item
												className="max-w-2/5 my-1 min-w-min whitespace-pre-wrap">
												{props.oldValue ? props.oldValue : 'None'}
											</Grid>
											<Grid item className="mx-1 my-px">
												<ArrowRightAltOutlined className="mx-1 align-middle" />
											</Grid>
											<Grid
												item
												className="max-w-2/5 my-1 min-w-min whitespace-pre-wrap">
												{props.newValue ? props.newValue : 'None'}
											</Grid>
										</Grid>
									</>
								)}
							</Grid>
						</Grid>
					</Collapse>
					<Button onClick={() => setOpen(!open)}>{open ? 'Collapse' : 'See more'}</Button>
				</>
			) : (
				<Grid container spacing={0}>
					<Grid item xs={12}>
						{props.isImpersonated ? (
							<span>
								<span className="font-bold text-yellow-600">{impersonator}</span>
								<OrangeMaskIcon className="ml-px h-5 align-middle" />{' '}
								<span>as</span> <span className="font-bold">{name}</span>
							</span>
						) : (
							<span
								className={
									'font-bold' + ' ' + (isSiteUser ? 'text-yellow-600' : '')
								}>
								{name}
							</span>
						)}
						<span> {changeAction} </span>
						<span
							className={
								'font-bold' +
								' ' +
								(props.changeType === 'SubEntityChanged'
									? 'text-navy-500 hover:underline'
									: '')
							}>
							{props.fieldName}
						</span>
						{props.changeType !== 'SubEntityChanged' && (
							<Grid container spacing={0}>
								<Grid item className="max-w-2/5 my-1 min-w-min whitespace-pre-wrap">
									{props.oldValue ? props.oldValue : 'None'}
								</Grid>
								<Grid item className="mx-1 my-px">
									<ArrowRightAltOutlined className="mx-1 align-middle" />
								</Grid>
								<Grid item className="max-w-2/5 my-1 min-w-min whitespace-pre-wrap">
									{props.newValue ? props.newValue : 'None'}
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			)}
		</TableCell>
	);
};
