import { Editor } from '@tiptap/react';
import { useState } from 'react';
import { RichTextToolbarButton } from './rich-text-toolbar-button';
import { FormatSize } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';

interface RichTextFontSizeButtonProps {
	editor: Editor;
}

export const RichTextFontSizeButton = ({ editor }: RichTextFontSizeButtonProps) => {
	const [anchor, setAnchor] = useState<HTMLElement | null>(null);
	const menuOpen = Boolean(anchor);
	const closeMenu = () => setAnchor(null);
	return (
		<>
			<RichTextToolbarButton
				tooltipText="Text Size"
				selected={menuOpen}
				onClick={(e) => setAnchor(e.currentTarget)}>
				<FormatSize />
			</RichTextToolbarButton>
			<Menu open={menuOpen} anchorEl={anchor} onClose={closeMenu} onClick={closeMenu}>
				<MenuItem
					selected={editor.isActive('paragraph')}
					onClick={() => editor.chain().focus().setParagraph().run()}>
					Normal
				</MenuItem>
				<MenuItem
					selected={editor.isActive('heading', { level: 1 })}
					onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}>
					Heading 1
				</MenuItem>
				<MenuItem
					selected={editor.isActive('heading', { level: 2 })}
					onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}>
					Heading 2
				</MenuItem>
			</Menu>
		</>
	);
};
