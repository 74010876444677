import { Badge, Box, MenuItem, Select, Stack } from '@mui/material';
import React from 'react';
import {
	matchPath,
	resolvePath,
	useLocation,
	useNavigate,
	useResolvedPath,
} from 'react-router-dom';
import { Permission } from 'utils/permissions';
import { BADGE_COUNT_MAX } from 'utils/useBadgeCounts';
import { useIsLargeOrSmallerScreen } from 'utils/useScreenSize';
import NavLink from './nav-link';

/**
 * The data for a nav item
 */
export interface SecondaryNavItem {
	text: string;
	link: string;
	access?: Permission | Permission[];
	end?: boolean;
	badgeCount?: number;
	all?: boolean;
}

/**
 * Secondary Navigation props.
 */
type SecondaryNavProps = {
	title: React.ReactNode;
	icon: React.ReactElement;
	navigationItems: SecondaryNavItem[];
	hasPermission?: (
		permission: Permission | Permission[],
		options?:
			| {
					all?: boolean | undefined;
			  }
			| undefined
	) => boolean;
};

/**
 * The Secondary Navigation bar.
 *
 * @returns
 */
export const SecondaryNav = ({
	title,
	icon,
	navigationItems,
	hasPermission,
}: SecondaryNavProps) => {
	let filteredItems =
		hasPermission === undefined
			? navigationItems
			: navigationItems.filter((item) => hasPermission(item.access ?? [], { all: item.all }));

	return (
		<Stack
			component="nav"
			direction="row"
			px={2}
			spacing={2}
			sx={{
				backgroundColor: 'background.paper',
				borderBottom: '1px solid',
				borderBottomColor: 'divider',
			}}>
			<Stack
				direction="row"
				alignItems="center"
				spacing={1}
				flex={1}
				py={1.5}
				overflow="hidden">
				{icon}
				{title}
			</Stack>
			<NavItems items={filteredItems} />
		</Stack>
	);
};

const NavItems = ({ items }: { items: SecondaryNavItem[] }) => {
	const isLargeOrSmaller = useIsLargeOrSmallerScreen();

	if (items.length < 2) return null;

	if (isLargeOrSmaller) {
		return <NavItemsDropdown items={items} />;
	}

	return (
		<Stack direction="row" spacing={1}>
			{items.map((item) => (
				<NavLink key={item.link} to={item.link} end={item.end}>
					{item.text}
					<Badge
						badgeContent={item.badgeCount}
						max={BADGE_COUNT_MAX}
						color="primary"
						sx={{
							position: 'absolute',
						}}></Badge>
				</NavLink>
			))}
		</Stack>
	);
};

const NavItemsDropdown = ({ items }: { items: SecondaryNavItem[] }) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { pathname: relativePathName } = useResolvedPath('');

	const activeItem = items.find((item) => {
		const { pathname: absolutePathname } = resolvePath(item.link, relativePathName);
		const match = matchPath({ path: absolutePathname, end: item.end ?? false }, pathname);
		return match !== null;
	});

	const activePath = activeItem?.link ?? '';

	return (
		<Box py={1.5}>
			<Select
				value={activePath}
				onChange={(e) => navigate(e.target.value)}
				variant="standard"
				disableUnderline>
				{items.map((item) => (
					<MenuItem key={item.link} value={item.link}>
						{item.text}
					</MenuItem>
				))}
			</Select>
		</Box>
	);
};
