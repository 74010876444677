import { Box, Button, Card, CardActionArea, CardContent, Stack, Typography } from '@mui/material';
import { LegacyUserAdjustmentCompanyWithMetrics } from 'middleware-types';
import { getCompanyRoleDisplayName } from 'utils/companyRoles';
import { postLegacyAuth, useSession } from 'utils/session';
import { theme } from 'utils/theme';
import { CompanyAvatar } from './CompanyAvatar';
import CompanyInfo from './CompanyInfo';

const CompanyCard = ({
	company,
	loading,
	selected,
	onSelected,
}: {
	company: LegacyUserAdjustmentCompanyWithMetrics;
	loading: boolean;
	selected: boolean;
	onSelected: () => void;
}) => {
	const { user } = useSession();
	const buttonText = company.userRole === 'AdminHR' ? 'Go to Company' : 'See Jobs';
	const redirectString = company.userRole === 'AdminHR' ? undefined : 'claimList.asp';
	return (
		<Card
			variant="outlined"
			sx={
				selected
					? {
							background: theme.palette.primary.light,
							border: `2px solid ${theme.palette.primary.main}`,
					  }
					: undefined
			}>
			<CardActionArea sx={{ height: '100%' }} component="div" onClick={onSelected}>
				<CardContent>
					<Stack gap={2}>
						<Stack direction="row" gap={1.5} alignItems="center">
							<CompanyAvatar company={company} />
							<Box flex={1} overflow="hidden">
								<Typography variant="h4" noWrap>
									{company.adjCoName}
								</Typography>
								<Typography noWrap>
									{getCompanyRoleDisplayName(company.userRole)}
								</Typography>
							</Box>
							<Button
								variant="contained"
								color="primary"
								size="large"
								onMouseDown={(e) => e.stopPropagation()}
								onClick={(e) => {
									e.stopPropagation();
									postLegacyAuth(
										company.systemUrl,
										company.userId,
										user.userId,
										redirectString
									);
								}}>
								{buttonText}
							</Button>
						</Stack>
						<CompanyInfo company={company} loading={loading} />
					</Stack>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

export default CompanyCard;
