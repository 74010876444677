import { ReactNode, createContext, useContext } from 'react';

interface VersionHistoryProviderProps {
	currentInstanceId: string;
}

const VersionHistoryContext = createContext<VersionHistoryProviderProps | undefined>(undefined);

export const VersionHistoryProvider = ({
	children,
	...props
}: VersionHistoryProviderProps & { children: ReactNode }) => {
	return (
		<VersionHistoryContext.Provider value={props}>{children}</VersionHistoryContext.Provider>
	);
};

export const useVersionHistoryContext = () => {
	const value = useContext(VersionHistoryContext);
	return { isVersionHistory: value !== undefined, currentInstanceId: value?.currentInstanceId };
};
