import { ArrowDropDown } from '@mui/icons-material';
import {
	Box,
	Button,
	Checkbox,
	Divider,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
} from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';

export interface AssociateStatusFilters {
	includeActive: boolean;
	includeInactive: boolean;
	includeInvited: boolean;
	includeDeclined: boolean;
}

const statusFilterOptions: { key: keyof AssociateStatusFilters; label: string }[] = [
	{ key: 'includeActive', label: 'Active' },
	{ key: 'includeInactive', label: 'Inactive' },
	{ key: 'includeInvited', label: 'Invited' },
	{ key: 'includeDeclined', label: 'Declined' },
];

interface AssociateStatusFilterProps {
	currentFilters: AssociateStatusFilters;
	setCurrentFilters: React.Dispatch<React.SetStateAction<AssociateStatusFilters>>;
}

export const AssociateStatusFilter = ({
	currentFilters,
	setCurrentFilters,
}: AssociateStatusFilterProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const selectedKeys = Object.keys(currentFilters).filter((key) => currentFilters[key]);
	const selectedOptions = statusFilterOptions.filter((option) =>
		selectedKeys.includes(option.key)
	);

	const allSelected = selectedOptions.length === statusFilterOptions.length;
	const statusButtonText = allSelected
		? 'All'
		: selectedOptions.length === 1
		? selectedOptions[0].label
		: selectedOptions.length;

	const onSubmit = (data: AssociateStatusFilters) => {
		setAnchorEl(null);
		setCurrentFilters(data);
	};

	return (
		<>
			<Button
				variant="outlined"
				endIcon={<ArrowDropDown />}
				onClick={(e) => setAnchorEl(e.currentTarget)}>
				Filter by Status ({statusButtonText})
			</Button>
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
				<Formik<AssociateStatusFilters> initialValues={currentFilters} onSubmit={onSubmit}>
					{({ values, setFieldValue, dirty, submitForm }) => {
						const allSelected = Object.values(values).every((value) => value);
						const someSelected = Object.values(values).some((value) => value);
						const indeterminate = someSelected && !allSelected;

						const toggleAll = () => {
							statusFilterOptions.forEach((option) =>
								setFieldValue(option.key, !allSelected)
							);
						};

						return (
							<>
								<MenuItem onClick={toggleAll}>
									<ListItemIcon>
										<Checkbox
											edge="start"
											tabIndex={-1}
											checked={allSelected}
											indeterminate={indeterminate}
										/>
									</ListItemIcon>
									<ListItemText>All</ListItemText>
								</MenuItem>
								<Divider />
								{statusFilterOptions.map((option) => (
									<MenuItem
										key={option.label}
										onClick={() =>
											setFieldValue(option.key, !values[option.key])
										}>
										<ListItemIcon>
											<Checkbox
												edge="start"
												tabIndex={-1}
												checked={values[option.key]}
											/>
										</ListItemIcon>
										<ListItemText>{option.label}</ListItemText>
									</MenuItem>
								))}
								<Divider />
								<Box px={1}>
									<Button
										variant="contained"
										color="primary"
										fullWidth
										disabled={!dirty}
										onClick={submitForm}>
										Apply
									</Button>
								</Box>
							</>
						);
					}}
				</Formik>
			</Menu>
		</>
	);
};
