import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	ActivationStatus,
	Mutation,
	MutationDeleteRelationshipArgs,
	MutationSeedOrgOrAncestorArgs,
	MutationSetChildArgs,
	OrgSearchResult,
	Query,
	QueryGetOrganizationAncestorsArgs,
	QueryGetOrganizationImmediateFamilyArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { OrgSearchFormValues } from './hierarchy';

const SET_CHILD = gql`
	mutation setChild($parentOrgId: ID!, $childOrgId: ID!) {
		setChild(parentOrgId: $parentOrgId, childOrgId: $childOrgId)
	}
`;

export const useSetChild = (parentOrgId: string) => {
	const toast = useToast();
	const [_setChild, { loading }] = useMutation<Pick<Mutation, 'setChild'>, MutationSetChildArgs>(
		SET_CHILD,
		{
			update: (cache) => {
				cache.evict({
					id: 'ROOT_QUERY',
					fieldName: 'getOrganizationImmediateFamily',
				});
			},
		}
	);

	const setChild = async (childOrgId: string) => {
		return await _setChild({
			variables: {
				parentOrgId,
				childOrgId,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push(`Child set successfully.`, {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { setChild, loading };
};

const DELETE_RELATIONSHIP = gql`
	mutation deleteRelationship($parentOrgId: ID!, $childOrgId: ID!) {
		deleteRelationship(parentOrgId: $parentOrgId, childOrgId: $childOrgId)
	}
`;

export const useDeleteRelationship = (parentOrgId: string) => {
	const toast = useToast();
	const [_deleteRelationship, { loading }] = useMutation<
		Pick<Mutation, 'deleteRelationship'>,
		MutationDeleteRelationshipArgs
	>(DELETE_RELATIONSHIP, {
		update: (cache) => {
			cache.evict({
				id: 'ROOT_QUERY',
				fieldName: 'getOrganizationImmediateFamily',
			});
		},
	});

	const removeChild = async (childOrgId: string) => {
		return await _deleteRelationship({
			variables: {
				parentOrgId,
				childOrgId,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push(`Child released successfully.`, {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { removeChild, loading };
};

const SEED_ORG_OR_ANCESTOR = gql`
	mutation seedOrgOrAncestor($sourceOrgId: ID!, $orgToSeedId: ID!) {
		seedOrgOrAncestor(sourceOrgId: $sourceOrgId, orgToSeedId: $orgToSeedId)
	}
`;

export const useSeedOrgOrAncestor = (sourceOrgId: string) => {
	const toast = useToast();
	const [_seedOrgOrAncestor, { loading }] = useMutation<
		Pick<Mutation, 'seedOrgOrAncestor'>,
		MutationSeedOrgOrAncestorArgs
	>(SEED_ORG_OR_ANCESTOR, {
		update: (cache) => {
			cache.evict({
				id: 'ROOT_QUERY',
				fieldName: 'getOrganizationAncestors',
			});
		},
	});

	const seedOrgOrAncestor = async (orgToSeedId: string) => {
		return await _seedOrgOrAncestor({
			variables: {
				sourceOrgId,
				orgToSeedId,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push(`Org seeded successfully.`, {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { seedOrgOrAncestor, loading };
};

const GET_ORGANIZATION_IMMEDIATE_FAMILY = gql`
	query getOrganizationImmediateFamily($orgId: ID!) {
		getOrganizationImmediateFamily(orgId: $orgId) {
			organizationId
			emblem {
				id
				private
				deactivated
				displayName
			}
			parentOrganizationId
			childOrganizationIds
			parentEmblem {
				id
				displayName
				private
				handle
			}
			childEmblems {
				id
				displayName
				private
				handle
				deactivated
			}
		}
	}
`;

export const useGetOrganizationImmediateFamily = (orgId: string) => {
	const { data, loading, error } = useQuery<
		Pick<Query, 'getOrganizationImmediateFamily'>,
		QueryGetOrganizationImmediateFamilyArgs
	>(GET_ORGANIZATION_IMMEDIATE_FAMILY, {
		variables: {
			orgId,
		},
	});

	return {
		family: data?.getOrganizationImmediateFamily,
		loading,
		error,
	};
};

const ORG_OR_DESCENDENT_IS_LINKED = gql`
	query orgOrDescendentIsLinked($orgId: ID!) {
		orgOrDescendentIsLinked(orgId: $orgId) {
			isLinked
		}
	}
`;

export const useOrgOrDescendentIsLinked = (orgId: string) => {
	const { data, loading, error } = useQuery<
		Pick<Query, 'orgOrDescendentIsLinked'>,
		QueryGetOrganizationImmediateFamilyArgs
	>(ORG_OR_DESCENDENT_IS_LINKED, {
		variables: {
			orgId,
		},
	});

	return {
		orgOrDescendentIsLinked: data?.orgOrDescendentIsLinked.isLinked,
		loading,
		error,
	};
};

const GET_ORGANIZATION_ANCESTORS = gql`
	query getOrganizationAncestors($orgId: ID!, $onlyAssociateCreate: Boolean) {
		getOrganizationAncestors(orgId: $orgId, onlyAssociateCreate: $onlyAssociateCreate) {
			organizationId
			emblem {
				id
				displayName
				private
			}
			ancestorToSeedId
			ancestors {
				organizationId
				ancestorLevel
				emblem {
					id
					displayName
				}
			}
		}
	}
`;

export const useGetOrganizationAncestors = (orgId: string, onlyAssociateCreate?: boolean) => {
	const { data, loading, error } = useQuery<
		Pick<Query, 'getOrganizationAncestors'>,
		QueryGetOrganizationAncestorsArgs
	>(GET_ORGANIZATION_ANCESTORS, {
		variables: {
			orgId,
			onlyAssociateCreate,
		},
	});

	return {
		data: data?.getOrganizationAncestors,
		loading,
		error,
	};
};

const GET_ANCESTORS_TO_BE_SEEDED_COUNT = gql`
	query getAssociatesToBeSeededCount($orgId: ID!) {
		getAssociatesToBeSeededCount(orgId: $orgId) {
			count
		}
	}
`;

export const useGetAssociatesToBeSeeded = (orgId: string) => {
	const { data, loading, error } = useQuery<
		Pick<Query, 'getAssociatesToBeSeededCount'>,
		QueryGetOrganizationAncestorsArgs
	>(GET_ANCESTORS_TO_BE_SEEDED_COUNT, {
		variables: {
			orgId,
		},
	});

	return {
		count: data?.getAssociatesToBeSeededCount?.count,
		loading,
		error,
	};
};

/* Query for getting a list of users from a peopleSearch. */
export const ORG_SEARCH = gql`
	query orgSearch($searchText: String!) {
		orgSystemSearch(searchText: $searchText) {
			id
			organizationEmblem {
				id
				displayName
			}
		}
	}
`;

/**
 *  Org search row props.
 */
type OrgSearchRowProps = OrgSearchResult & {
	refetch: () => void;
};

/**
 * useOrgSearch() is a custom hook to handle all the data-loading
 * and processing for the org system search.
 *
 * @return {*}  {{
 * 	submit: (values: PeopleSearchFormValues) => Promise<void>;
 * 	initialValues: PeopleSearchFormValues;
 * }}
 */
export const useChildOrgSearch = (parentOrganizationId: string) => {
	const [getOrgSearchResults, { loading, data, error, refetch }] = useLazyQuery(ORG_SEARCH, {
		fetchPolicy: 'cache-and-network',
	});

	// Performs the search when the Search button, magnifying icon, or enter is pressed.
	const search = async (values: OrgSearchFormValues) => {
		// Prevent searching if the search text is empty.
		if (!values.searchText) {
			return;
		}

		await getOrgSearchResults({
			variables: {
				...values,
				activationStatus: ActivationStatus.Active,
			},
		});
	};

	const orgs: OrgSearchRowProps[] | undefined = data?.orgSystemSearch
		.filter((o) => o.id != parentOrganizationId)
		.map((result) => ({
			...result,
			refetch: () => (refetch ? refetch() : undefined),
		}));

	return { search, loading, error, orgs };
};
