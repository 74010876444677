import { ClearOutlined, Edit, ForumOutlined, MoreHoriz } from '@mui/icons-material';
import {
	Avatar,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
} from '@mui/material';
import { SavedConversation } from 'middleware-types';
import { useState } from 'react';
import { ConfirmNewConversationDrawer } from './confirmNewConversationDrawer';
import { DeleteConversationDrawer } from './deleteConversationDrawer';
import { SaveDrawer } from './saveDrawer';

interface SavedConversationListItemProps {
	conversation: SavedConversation;
	selectConversation: (conversationId: string) => void;
	setConversationName: React.Dispatch<React.SetStateAction<string | null>>;
	warnAboutOverwriting: boolean;
}

export const SavedConversationListItem = ({
	conversation,
	selectConversation,
	setConversationName,
	warnAboutOverwriting,
}: SavedConversationListItemProps) => {
	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
	const [deleteDrawerOpen, setDeleteDrawerOpen] = useState(false);
	const [confirmNewConversationDrawerOpen, setConfirmNewConversationDrawerOpen] = useState(false);
	const [editDrawerOpen, setEditDrawerOpen] = useState(false);

	return (
		<ListItem
			key={conversation.conversationId}
			disablePadding
			secondaryAction={
				<IconButton edge="end" onClick={(e) => setMenuAnchor(e.currentTarget)}>
					<MoreHoriz />
				</IconButton>
			}>
			<ListItemButton
				onClick={() =>
					warnAboutOverwriting
						? setConfirmNewConversationDrawerOpen(true)
						: selectConversation(conversation.conversationId)
				}>
				<ListItemAvatar>
					<Avatar>
						<ForumOutlined />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={conversation.conversationName}
					primaryTypographyProps={{
						style: {
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						},
					}}
				/>
			</ListItemButton>
			{/* Menu */}
			<Menu
				open={Boolean(menuAnchor)}
				anchorEl={menuAnchor}
				onClick={() => setMenuAnchor(null)}
				onClose={() => setMenuAnchor(null)}>
				<MenuItem onClick={() => setEditDrawerOpen(true)}>
					<ListItemIcon>
						<Edit />
					</ListItemIcon>
					<ListItemText>Edit Name</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => setDeleteDrawerOpen(true)}>
					<ListItemIcon>
						<ClearOutlined />
					</ListItemIcon>
					<ListItemText>Unpin</ListItemText>
				</MenuItem>
			</Menu>
			{/* Delete Drawer */}
			<DeleteConversationDrawer
				open={deleteDrawerOpen}
				onClose={() => setDeleteDrawerOpen(false)}
				conversationId={conversation.conversationId}
				conversationName={conversation.conversationName}
				setConversationName={setConversationName}
			/>
			{/* Edit Drawer */}
			<SaveDrawer
				open={editDrawerOpen}
				onClose={() => setEditDrawerOpen(false)}
				conversationId={conversation.conversationId}
				conversationName={conversation.conversationName}
				setConversationName={setConversationName}
			/>
			{/* Confirm New Conversation Drawer */}
			<ConfirmNewConversationDrawer
				open={confirmNewConversationDrawerOpen}
				onSubmit={() => selectConversation(conversation.conversationId)}
				onClose={() => setConfirmNewConversationDrawerOpen(false)}
			/>
		</ListItem>
	);
};
