import { gql, useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	Mutation,
	MutationSocialUserAcceptConnectionArgs,
	MutationSocialUserCancelConnectionRequestArgs,
	MutationSocialUserDisconnectArgs,
	MutationSocialUserIgnoreConnectionArgs,
	MutationSocialUserRequestConnectionArgs,
	Query,
	QueryOrganizationProfileArgs,
	QueryProfileArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useSession } from 'utils/session';
import { SINGLE_PARTICIPANT_SEARCH } from 'utils/useCanMessage';
import {
	USER_CONNECTIONS_OR_REQUESTS,
	USER_CONNECTIONS_OR_REQUESTS_COUNT,
} from '../UserNetworkScreen/UserConnections.graphql';

// fragments
const USER_CONNECTION_FIELDS = gql`
	fragment UserConnectionFields on Connection {
		id
		state
		targetUserId
	}
`;

// get the current user's connection to an org
const CURRENT_USER_ORG_CONNECTION = gql`
	${USER_CONNECTION_FIELDS}
	query CurrentUserOrgConnection($organizationId: ID!) {
		organizationProfile(organizationId: $organizationId) {
			id
			displayName
			connection {
				...UserConnectionFields
			}
		}
	}
`;

export const useCurrentUserOrgConnection = (organizationId: string) => {
	const { data, loading } = useQuery<
		Pick<Query, 'organizationProfile'>,
		QueryOrganizationProfileArgs
	>(CURRENT_USER_ORG_CONNECTION, {
		variables: { organizationId },
	});
	return { profile: data?.organizationProfile, loading };
};

// get the current user's connection to another user
const CURRENT_USER_USER_CONNECTION = gql`
	${USER_CONNECTION_FIELDS}
	query CurrentUserUserConnection($userId: ID!) {
		profile(userId: $userId) {
			id
			name {
				firstName
				lastName
			}
			connection {
				...UserConnectionFields
			}
		}
	}
`;

export const useCurrentUserUserConnection = (userId: string, skip = false) => {
	const { data, loading } = useQuery<Pick<Query, 'profile'>, QueryProfileArgs>(
		CURRENT_USER_USER_CONNECTION,
		{
			skip,
			variables: { userId },
		}
	);
	return { profile: data?.profile, loading };
};

// request a connection
const USER_CONNECTION_REQUEST = gql`
	${USER_CONNECTION_FIELDS}
	mutation UserConnectionRequest($userId: ID!, $userOrOrganizationId: ID!) {
		socialUserRequestConnection(userId: $userId, userOrOrganizationId: $userOrOrganizationId) {
			...UserConnectionFields
		}
	}
`;

export const useUserConnectionRequest = () => {
	const toast = useToast();
	const { user } = useSession();

	const [_requestConnection, { loading }] = useMutation<
		Pick<Mutation, 'socialUserRequestConnection'>,
		MutationSocialUserRequestConnectionArgs
	>(USER_CONNECTION_REQUEST, {
		refetchQueries: [
			CURRENT_USER_ORG_CONNECTION,
			CURRENT_USER_USER_CONNECTION,
			USER_CONNECTIONS_OR_REQUESTS,
			USER_CONNECTIONS_OR_REQUESTS_COUNT,
		],
		awaitRefetchQueries: true,
	});

	const requestConnection = async (entityId: string) => {
		return await _requestConnection({
			variables: { userId: user.userId, userOrOrganizationId: entityId },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) return false;
				toast.push('Connection request sent successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		requestConnection,
		loading,
	};
};

// cancel connection request
const CANCEL_USER_CONNECTION_REQUEST = gql`
	mutation CancelUserConnectionRequest($userId: ID!, $connectionId: ID!) {
		socialUserCancelConnectionRequest(userId: $userId, connectionId: $connectionId)
	}
`;

export const useCancelUserConnectionRequest = () => {
	const toast = useToast();
	const { user } = useSession();

	const [_cancelConnectionRequest, { loading }] = useMutation<
		Pick<Mutation, 'socialUserCancelConnectionRequest'>,
		MutationSocialUserCancelConnectionRequestArgs
	>(CANCEL_USER_CONNECTION_REQUEST, {
		refetchQueries: [
			CURRENT_USER_ORG_CONNECTION,
			CURRENT_USER_USER_CONNECTION,
			USER_CONNECTIONS_OR_REQUESTS,
			USER_CONNECTIONS_OR_REQUESTS_COUNT,
		],
		awaitRefetchQueries: true,
	});

	const cancelConnectionRequest = async (connectionId: string) => {
		return await _cancelConnectionRequest({
			variables: { userId: user.userId, connectionId },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) return false;
				toast.push('Connection request cancelled successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		cancelConnectionRequest,
		loading,
	};
};

// remove connection
const REMOVE_USER_CONNECTION = gql`
	mutation RemoveUserConnection($userId: ID!, $connectionId: ID!) {
		socialUserDisconnect(userId: $userId, connectionId: $connectionId)
	}
`;

export const useRemoveUserConnection = () => {
	const toast = useToast();
	const { user } = useSession();

	const [_removeConnection, { loading }] = useMutation<
		Pick<Mutation, 'socialUserDisconnect'>,
		MutationSocialUserDisconnectArgs
	>(REMOVE_USER_CONNECTION, {
		refetchQueries: [
			CURRENT_USER_ORG_CONNECTION,
			CURRENT_USER_USER_CONNECTION,
			USER_CONNECTIONS_OR_REQUESTS,
			USER_CONNECTIONS_OR_REQUESTS_COUNT,
			SINGLE_PARTICIPANT_SEARCH,
		],
		awaitRefetchQueries: true,
	});

	const removeConnection = async (connectionId: string) => {
		return await _removeConnection({
			variables: { userId: user.userId, connectionId },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) return false;
				toast.push('Connection removed successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		removeConnection,
		loading,
	};
};

// accept connection
const ACCEPT_USER_CONNECTION = gql`
	${USER_CONNECTION_FIELDS}
	mutation AcceptUserConnection($userId: ID!, $connectionId: ID!) {
		socialUserAcceptConnection(userId: $userId, connectionId: $connectionId) {
			...UserConnectionFields
		}
	}
`;

export const useAcceptUserConnection = () => {
	const toast = useToast();
	const { user } = useSession();

	const [_acceptConnection, { loading }] = useMutation<
		Pick<Mutation, 'socialUserAcceptConnection'>,
		MutationSocialUserAcceptConnectionArgs
	>(ACCEPT_USER_CONNECTION, {
		refetchQueries: [
			USER_CONNECTIONS_OR_REQUESTS,
			USER_CONNECTIONS_OR_REQUESTS_COUNT,
			SINGLE_PARTICIPANT_SEARCH,
		],
		awaitRefetchQueries: true,
	});

	const acceptConnection = async (connectionId: string) => {
		return await _acceptConnection({
			variables: { userId: user.userId, connectionId },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) return false;
				toast.push('Connection accepted successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		acceptConnection,
		loading,
	};
};

// ignore connection
const IGNORE_USER_CONNECTION = gql`
	mutation IgnoreUserConnection($userId: ID!, $connectionId: ID!) {
		socialUserIgnoreConnection(userId: $userId, connectionId: $connectionId)
	}
`;

export const useIgnoreUserConnection = () => {
	const toast = useToast();
	const { user } = useSession();

	const [_ignoreConnection, { loading }] = useMutation<
		Pick<Mutation, 'socialUserIgnoreConnection'>,
		MutationSocialUserIgnoreConnectionArgs
	>(IGNORE_USER_CONNECTION, {
		refetchQueries: [
			CURRENT_USER_ORG_CONNECTION,
			CURRENT_USER_USER_CONNECTION,
			USER_CONNECTIONS_OR_REQUESTS,
			USER_CONNECTIONS_OR_REQUESTS_COUNT,
		],
		awaitRefetchQueries: true,
	});

	const ignoreConnection = async (connectionId: string) => {
		return await _ignoreConnection({
			variables: { userId: user.userId, connectionId },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) return false;
				toast.push('Connection declined successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		ignoreConnection,
		loading,
	};
};
