import { Avatar, Box, Divider, ListItem, ListItemButton, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { ConversationSummary } from 'middleware-types';
import { useCommunicationsContext } from '../communications-provider';
import { currentEntityStrings, removeTagsFromString } from '../helpers/utils';
import { EmblemAvatar } from 'components/ui/emblem/emblem-avatar';

interface SummaryListItemProps {
	summary: ConversationSummary;
}

export const SummaryListItem = ({ summary }: SummaryListItemProps) => {
	const { entityType, selectedConversationId, setSelectedConversationId } =
		useCommunicationsContext();

	const unread = summary.unreadMessageCount > 0;
	const dateString = format(new Date(summary.lastMessageDateTimeUtc), 'M/d');

	return (
		<>
			<ListItem disableGutters disablePadding>
				<ListItemButton
					selected={summary.conversationId === selectedConversationId}
					onClick={() => setSelectedConversationId(summary.conversationId)}
					sx={{
						'&.Mui-selected': { border: 'none' },
					}}>
					<Stack direction="row" width="100%" alignItems="center" spacing={1.5} py={0.5}>
						<Box
							height={8}
							width={8}
							borderRadius="50%"
							bgcolor={unread ? 'primary.main' : 'transparent'}
						/>
						{summary.participantsCount > 1 ? (
							<Box height={40} width={40} position="relative">
								<EmblemAvatar
									emblem={summary.emblemToDisplay}
									size={24}
									sx={{
										border: '1px solid',
										borderColor: 'neutral.50',
										boxSizing: 'content-box',
									}}
								/>
								<Avatar
									sx={{
										height: 24,
										width: 24,
										fontSize: 12,
										position: 'absolute',
										bottom: 0,
										right: 0,
										border: '1px solid',
										borderColor: 'neutral.50',
										boxSizing: 'content-box',
										zIndex: 1,
									}}>
									+{summary.participantsCount - 1}
								</Avatar>
							</Box>
						) : (
							<EmblemAvatar emblem={summary.emblemToDisplay} />
						)}
						<Box flex={1} overflow="hidden">
							<Stack
								direction="row"
								alignItems="baseline"
								justifyContent="space-between"
								spacing={1.5}>
								<Typography
									variant="h6"
									noWrap
									color={unread ? 'primary.main' : undefined}>
									{summary.subject}
								</Typography>
								<Typography variant="body2" color="neutral.600">
									{dateString}
								</Typography>
							</Stack>
							<Typography variant="body2" noWrap color="neutral.600">
								{summary.lastMessageIsFromCurrentEntity &&
									`${currentEntityStrings[entityType]}: `}
								{removeTagsFromString(summary.lastMessagePreview ?? '')}
							</Typography>
						</Box>
					</Stack>
				</ListItemButton>
			</ListItem>
			<Divider />
		</>
	);
};
