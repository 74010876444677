import {
	AccountCircleOutlined,
	AlternateEmail,
	CakeOutlined,
	EmailOutlined,
	PhoneOutlined,
	TranslateOutlined,
} from '@mui/icons-material';
import { Box, Grid, TextField as MTTextField, MenuItem, Skeleton, Stack } from '@mui/material';
import { DatePickerField, PhoneNumberField, SelectField, TextField } from 'components/ui/fields';
import { useFormikContext } from 'formik';
import { UserAccountUpdate } from 'middleware-types';
import { useLanguages } from 'utils/useLanguages';
import { PersonalInformation } from './personal-info-hooks';

export type PersonalInfoEditProps = { account: PersonalInformation };

/**
 * PersonalInfoEdit is a an edit form
 * for the personal info on account settings
 * @type {*} Edit form
 * */
const PersonalInfoEdit = (props: PersonalInfoEditProps) => {
	const formik = useFormikContext<UserAccountUpdate>();
	const { languages, loading } = useLanguages();

	return (
		<Stack spacing={2}>
			<Stack direction="row" spacing={1} alignItems="flex-start">
				<Box>
					<AccountCircleOutlined sx={{ marginTop: 3 }} fontSize="small" />
				</Box>
				<Grid container columnSpacing={2} spacing={1}>
					<Grid xs={12} md={3} item>
						<TextField
							label="First Name"
							name="name.firstName"
							required
							fullWidth
							disabled={formik.isSubmitting}
							data-test="firstName"
						/>
					</Grid>
					<Grid xs={12} md={3} item>
						<TextField
							label="Middle Name"
							name="name.middleName"
							fullWidth
							disabled={formik.isSubmitting}
							data-test="middleName"
						/>
					</Grid>
					<Grid xs={12} md={3} item>
						<TextField
							label="Last Name"
							required
							name="name.lastName"
							fullWidth
							disabled={formik.isSubmitting}
							data-test="lastName"
						/>
					</Grid>
					<Grid xs={12} md={3} item>
						<TextField
							label="Suffix"
							name="name.suffix"
							fullWidth
							disabled={formik.isSubmitting}
							data-test="suffix"
						/>
					</Grid>
				</Grid>
			</Stack>
			<Stack direction="row" spacing={1} alignItems="center">
				<AlternateEmail fontSize="small" />
				<Grid container columnSpacing={1}>
					<Grid xs={12} md={6} item>
						<TextField
							label="Handle"
							required
							name="handle"
							fullWidth
							disabled={formik.isSubmitting}
							data-test="handle"
						/>
					</Grid>
				</Grid>
			</Stack>
			<Stack direction="row" spacing={1} alignItems="flex-start">
				<Box>
					<PhoneOutlined sx={{ marginTop: 2.5 }} fontSize="small" />
				</Box>
				<Grid container columnSpacing={1}>
					<Grid xs={12} md={6} item>
						<PhoneNumberField
							label="Cell Phone Number"
							required
							name="cellPhone"
							disabled={formik.isSubmitting}
							data-test="cellPhone"
						/>
					</Grid>
					<Grid xs={12} md={6} item>
						<PhoneNumberField
							label="Home Phone Number"
							name="homePhone"
							disabled={formik.isSubmitting}
							data-test="homePhone"
						/>
					</Grid>
				</Grid>
			</Stack>
			<Stack direction="row" spacing={1} alignItems="flex-start">
				<Box>
					<EmailOutlined sx={{ marginTop: 2.5 }} fontSize="small" />
				</Box>
				<Grid container columnSpacing={1}>
					<Grid xs={12} md={6} item>
						<MTTextField
							label="Primary Email Address"
							required
							value={props.account.emailAddress}
							disabled
							fullWidth
							data-test="emailAddress"
						/>
					</Grid>
					<Grid xs={12} md={6} item>
						<TextField
							label="Secondary Email Address"
							name="secondaryEmailAddress"
							fullWidth
							disabled={formik.isSubmitting}
							data-test="secondaryEmailAddress"
						/>
					</Grid>
				</Grid>
			</Stack>
			<Stack direction="row" spacing={2} alignItems="center">
				<TranslateOutlined fontSize="small" />
				{!loading ? (
					<SelectField
						name="languageIds"
						label="Languages"
						fullWidth
						SelectProps={{ multiple: true }}
						disabled={formik.isSubmitting}
						data-test="languageIds">
						{languages.map((l) => (
							<MenuItem key={l.id} value={l.id}>
								{l.name}
							</MenuItem>
						))}
					</SelectField>
				) : (
					<Skeleton>
						<MTTextField />
					</Skeleton>
				)}
			</Stack>
			<Stack direction="row" spacing={1} alignItems="center">
				<CakeOutlined fontSize="small" />
				<Grid container columnSpacing={1}>
					<Grid xs={12} md={6} item>
						<DatePickerField
							label="Date of Birth"
							name="birthDate"
							disabled={formik.isSubmitting}
							data-test="birthDate"
						/>
					</Grid>
				</Grid>
			</Stack>
		</Stack>
	);
};

export default PersonalInfoEdit;
