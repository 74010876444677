import { Box, Divider, Paper, Skeleton, Stack } from '@mui/material';

export const ConversationColumnSkeleton = () => {
	return (
		<Stack height="100%">
			<Paper elevation={1} sx={{ borderRadius: 0, zIndex: 100 }}>
				<Stack direction="row" px={2} py={1.5} alignItems="center" spacing={1}>
					<Skeleton variant="text" width={200} />
					<Skeleton variant="circular" height={30} width={30} />
					<Box flex={1} />
					<Skeleton variant="circular" height={30} width={30} />
				</Stack>
				<Divider />
				<Stack direction="row" px={2} py={1.5} gap={1}>
					{[...Array(3)].map((_, index) => (
						<Skeleton
							key={index}
							variant="rounded"
							height={20}
							width={80}
							sx={{ borderRadius: 100 }}
						/>
					))}
				</Stack>
			</Paper>
			<Divider />
			<Box flex={1} bgcolor="neutral.50" />
			<Divider />
			<Paper elevation={1} sx={{ borderRadius: 0, p: 1.5 }}>
				<Stack spacing={1}>
					<Stack direction="row" spacing={1}>
						{[...Array(2)].map((_, index) => (
							<Skeleton key={index} variant="text" width={60} />
						))}
						{[...Array(4)].map((_, index) => (
							<Skeleton key={index} variant="text" width={20} />
						))}
					</Stack>
					<Skeleton variant="rounded" height={100} />
					<Stack direction="row" spacing={1} alignItems="center">
						{[...Array(3)].map((_, index) => (
							<Skeleton key={index} variant="circular" height={30} width={30} />
						))}
						<Box flex={1} />
						<Skeleton variant="rounded" height={40} width={90} />
					</Stack>
				</Stack>
			</Paper>
		</Stack>
	);
};
