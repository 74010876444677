import { FoldersApiEntityType } from 'middleware-types';

export const getRootFolderName = (value: FoldersApiEntityType): string => {
	switch (value) {
		case FoldersApiEntityType.Organization:
			return 'Organization Documents';
		default:
			return 'N/A';
	}
};
