import * as Yup from 'yup';
import { gql, useQuery } from '@apollo/client';
import { PageError } from 'utils/errors';
import { KeyValuePair, Query, QueryValidationRulesArgs, ValidationRules } from 'middleware-types';

/**
 * Helper function to find validation rule values.
 *
 * @param {string} key
 * @param {{
 *     key: string;
 *     value: string;
 * }[]} values
 * @returns {(string | undefined)}
 */
const FindRuleValue = (key: string, values: KeyValuePair[]) =>
	values.find((v) => v.key === key)?.value;

export type ValidationSchema = Yup.AnyObjectSchema;

/**
 * Converts the backend provided schema into a yup schema for form validation
 *
 * @param {ValidationRules} vr
 * @returns {ValidationSchema}
 */
export const BuildValidationSchema = (vr: ValidationRules) => {
	const schema: Record<string, any> = {};
	// Iterate through each property
	for (const prop of vr.properties) {
		// ! We're abusing dynamic types here as fields can be any type.
		// ! The type is passed in as a part of the rule.
		// ! Please be concious to not call a yup function on an invalid type.
		let field: Yup.Schema | undefined = undefined;
		// Build the schema based on type
		switch (prop.type) {
			case 'String':
				field = Yup.string();
				break;
			case 'Number':
				field = Yup.number();
				break;
			case 'Array':
				field = Yup.array();
				break;
			case 'Boolean':
				field = Yup.boolean();
				break;
			case 'Object':
				// Object types work recursively.
				if (!prop.shape) break;
				field = BuildValidationSchema(prop.shape!).nullable();
		}

		if (field !== undefined) {
			// Apply each rule to the schema property.
			for (const rule of prop.rules) {
				switch (rule.rule) {
					case 'Required':
						// Requiredness conflicts with nested hierarchies of object validation
						// Because of this we handle all required checks manually defined within
						// the client forms.
						break;
					case 'EmailAddress':
						field = (field as Yup.StringSchema).email('Invalid email');
						break;
					case 'EnumerableNotNullOrEmpty':
						field = (field as Yup.StringSchema).min(1, 'Required field');
						break;
					case 'MaxLength':
						{
							const max = parseInt(FindRuleValue('Length', rule.values) ?? '0');
							field = (field as Yup.StringSchema).max(
								max,
								`Cannot exceed ${max} characters.`
							);
						}
						break;
					case 'MinLength':
						{
							const min = parseInt(FindRuleValue('Length', rule.values) ?? '0');
							field = (field as Yup.StringSchema).min(
								min,
								`Cannot be fewer than ${min} characters.`
							);
						}
						break;
					case 'RegularExpression':
						{
							const regex = FindRuleValue('Pattern', rule.values);
							if (regex) {
								field = (field as Yup.StringSchema).matches(
									RegExp(regex),
									'Invalid character(s) or format'
								);
							}
						}
						break;
					case 'Url': {
						field = (field as Yup.StringSchema).url('Invalid URL');
						break;
					}
					case 'Range': {
						const minStr = FindRuleValue('Minimum', rule.values);
						const maxStr = FindRuleValue('Maximum', rule.values);
						if (minStr === undefined || maxStr === undefined) break;
						const min = parseInt(minStr);
						const max = parseInt(maxStr);
						field = (field as Yup.NumberSchema)
							.min(min, `Cannot be less than ${min}.`)
							.max(max, `Cannot be greater than ${max}.`);
						break;
					}
				}
			}
		}

		schema[prop.property] = field;
	}
	return Yup.object(schema);
};

/**
 * This custom hook will pull down the validation schema and build it for a
 * given type.
 *
 * @param {string} typeName Type name in the backend
 * @returns {({
 * 	schema: ValidationSchema | null;
 * 	loading: boolean;
 * })}
 */
export const useValidation = (
	typeName: string
): {
	schema: ValidationSchema | null;
	loading: boolean;
} => {
	const query = useQuery<Pick<Query, 'validationRules'>, QueryValidationRulesArgs>(
		gql`
			fragment ValidationRuleProperties on PropertyValidationRules {
				property
				type
				rules {
					rule
					values {
						key
						value
					}
				}
			}

			query validationRules($typeName: String!) {
				validationRules(typeName: $typeName) {
					typeName
					properties {
						...ValidationRuleProperties
						shape {
							properties {
								...ValidationRuleProperties
								shape {
									properties {
										...ValidationRuleProperties
										shape {
											properties {
												...ValidationRuleProperties
												shape {
													properties {
														...ValidationRuleProperties
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`,
		{ variables: { typeName } }
	);
	if (query.error) throw new PageError(query.error.graphQLErrors);

	let schema: ValidationSchema | null = null;
	if (!query.loading && !query.error && query?.data?.validationRules) {
		const rules = query?.data?.validationRules;
		schema = BuildValidationSchema(rules);
	}

	return { loading: query.loading, schema };
};
