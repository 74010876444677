import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { UserChatMessage } from 'middleware-types';
import { useState } from 'react';

export const InternalMessage = ({ message }: { message: UserChatMessage }) => {
	const [open, setOpen] = useState(false);
	return (
		<Stack borderRadius={1} p={1} spacing={1} sx={{ backgroundColor: 'warning.light' }}>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Typography variant="body2">Internal Message</Typography>
				<IconButton size="small" onClick={() => setOpen((value) => !value)}>
					{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
				</IconButton>
			</Stack>
			{open && (
				<>
					<Divider />
					<Stack spacing={1.5}>
						<InfoRow label="Role" value={message.role} />
						<InfoRow label="Name" value={message.functionName} />
						<InfoRow label="Function Call Name" value={message.functionCallName} />
						<InfoRow
							label="Function Call Arguments"
							value={message.functionCallArguments}
						/>
						<InfoRow label="Content" value={message.content} />
					</Stack>
				</>
			)}
		</Stack>
	);
};

const InfoRow = ({ label, value }: { label: string; value: string | undefined }) => {
	return (
		<Box>
			<Typography variant="body2" fontWeight="bold">
				{label}:
			</Typography>
			<Typography variant="body2">{value ?? '<null>'}</Typography>
		</Box>
	);
};
