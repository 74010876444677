import { gql, useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	ConversationApiEntityType,
	LabelCreateRequest,
	LabelEditRequest,
	LabelType,
	Mutation,
	MutationConversationLabelCreateArgs,
	MutationConversationLabelDeleteArgs,
	MutationConversationLabelUpdateArgs,
	Query,
	QueryConversationLabelSummariesArgs,
} from 'middleware-types';
import { useMemo } from 'react';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useCommunicationsContext } from '../communications-provider';
import { LABEL_FIELDS } from '../fragments.graphql';
import { mapLabelToColor } from '../helpers/label-colors';

/**
 * Hook to get the labels for a given entity
 */
const GET_LABELS = gql`
	${LABEL_FIELDS}
	query ConversationLabelSummaries($entityType: ConversationApiEntityType!, $entityId: String!) {
		conversationLabelSummaries(entityType: $entityType, entityId: $entityId) {
			labels {
				...LabelFields
			}
		}
	}
`;

export const useLabels = (
	entityType: ConversationApiEntityType,
	entityId: string,
	setLabelId: (value: string) => void
) => {
	const toast = useToast();
	const { data, loading } = useQuery<
		Pick<Query, 'conversationLabelSummaries'>,
		QueryConversationLabelSummariesArgs
	>(GET_LABELS, {
		variables: { entityType, entityId },
		onCompleted: (data) => {
			const labels = data.conversationLabelSummaries.labels;
			const inboxLabel = labels.find((label) => label.type === LabelType.Inbox);
			if (inboxLabel) setLabelId(inboxLabel.id);
		},
		onError: () => toast.push('Unable to load labels', { variant: 'error' }),
	});

	const labels = data?.conversationLabelSummaries.labels ?? [];
	const labelsWithColor = useMemo(() => labels.map(mapLabelToColor), [labels]);
	return { labels: labelsWithColor, loading };
};

/**
 * Hook to create a new label
 */
const CREATE_LABEL = gql`
	${LABEL_FIELDS}
	mutation ConversationLabelCreate(
		$entityType: ConversationApiEntityType!
		$entityId: String!
		$labelRequest: LabelCreateRequest!
	) {
		conversationLabelCreate(
			entityType: $entityType
			entityId: $entityId
			labelRequest: $labelRequest
		) {
			...LabelFields
		}
	}
`;

export const useCreateLabel = () => {
	const toast = useToast();

	const { entityType, entityId } = useCommunicationsContext();
	const [_createLabel] = useMutation<
		Pick<Mutation, 'conversationLabelCreate'>,
		MutationConversationLabelCreateArgs
	>(CREATE_LABEL, { refetchQueries: [GET_LABELS], awaitRefetchQueries: true });

	const createLabel = async (labelRequest: LabelCreateRequest) => {
		return await _createLabel({ variables: { entityType, entityId, labelRequest } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Label created successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return createLabel;
};

/**
 * Hook to update an existing label
 */
const UPDATE_LABEL = gql`
	${LABEL_FIELDS}
	mutation ConversationLabelUpdate(
		$entityType: ConversationApiEntityType!
		$entityId: String!
		$labelId: String!
		$labelRequest: LabelEditRequest!
	) {
		conversationLabelUpdate(
			entityType: $entityType
			entityId: $entityId
			labelId: $labelId
			labelRequest: $labelRequest
		) {
			...LabelFields
		}
	}
`;

export const useUpdateLabel = () => {
	const toast = useToast();

	const { entityType, entityId } = useCommunicationsContext();
	const [_updateLabel] = useMutation<
		Pick<Mutation, 'conversationLabelUpdate'>,
		MutationConversationLabelUpdateArgs
	>(UPDATE_LABEL, { onError: (e) => console.log(JSON.stringify(e)) });

	const updateLabel = async (labelId: string, labelRequest: LabelEditRequest) => {
		return await _updateLabel({ variables: { labelId, entityType, entityId, labelRequest } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Label updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return updateLabel;
};

/**
 * Hook to delete a label
 */
const DELETE_LABEL = gql`
	mutation ConversationLabelDelete(
		$entityType: ConversationApiEntityType!
		$entityId: String!
		$labelId: String!
	) {
		conversationLabelDelete(entityType: $entityType, entityId: $entityId, labelId: $labelId)
	}
`;

export const useDeleteLabel = () => {
	const toast = useToast();

	const { entityType, entityId } = useCommunicationsContext();
	const [_deleteLabel, { loading }] = useMutation<
		Pick<Mutation, 'conversationLabelDelete'>,
		MutationConversationLabelDeleteArgs
	>(DELETE_LABEL, {
		refetchQueries: [GET_LABELS],
		awaitRefetchQueries: true,
		onError: (e) => console.log(JSON.stringify(e)),
	});

	const deleteLabel = async (labelId: string) => {
		return await _deleteLabel({ variables: { entityType, entityId, labelId } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Label deleted successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { deleteLabel, loading };
};
