import { AddCircle } from '@mui/icons-material';
import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { useModal } from 'components/ui/modal';
import { QuickLink, QuickLinkCreate, QuickLinkPatch } from 'middleware-types';
import { QuickLinkCard } from './quickLinkCard';
import { AddUpdateQuickLinkModal } from './quickLinkModals';

export interface QuickLinksMethods {
	quickLinks: QuickLink[];
	loading: boolean;
	addQuickLink: (request: QuickLinkCreate) => Promise<boolean>;
	updateQuickLink: (quickLinkId: string, request: QuickLinkPatch) => Promise<boolean>;
	deleteQuickLink: (quickLinkId: string) => Promise<boolean>;
	deleteLoading: boolean;
}

export type ExtendedQuickLink = QuickLink & {
	presetIcon?: string;
	isPresetLink?: boolean;
};

interface QuickLinksProps {
	methods: QuickLinksMethods;
	canEdit: boolean;
	presetQuickLinks?: ExtendedQuickLink[];
}

export const QuickLinks = ({ methods, canEdit, presetQuickLinks }: QuickLinksProps) => {
	const { showModal } = useModal();
	const { quickLinks, loading } = methods;

	return (
		<Stack spacing={2}>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Typography variant="h1">Quick Links</Typography>
				{canEdit && (
					<IconButton
						onClick={() =>
							showModal({
								title: 'Add New Link',
								content: <AddUpdateQuickLinkModal methods={methods} />,
							})
						}>
						<AddCircle color="primary" fontSize="large" />
					</IconButton>
				)}
			</Stack>
			{loading ? (
				<Stack spacing={2} direction="row">
					{[...Array(4)].map((_, index) => (
						<Skeleton key={index} variant="rounded" height={80} sx={{ flex: 1 }} />
					))}
				</Stack>
			) : (
				<Box
					display="grid"
					gridTemplateColumns={{
						xs: '1fr',
						sm: 'repeat(2, 1fr)',
						lg: 'repeat(3, 1fr)',
						xl: 'repeat(4, 1fr)',
					}}
					gridAutoRows="1fr"
					gap={2}>
					{presetQuickLinks &&
						presetQuickLinks.map((presetQuickLink) => (
							<QuickLinkCard
								key={presetQuickLink.id}
								methods={methods}
								quickLink={presetQuickLink}
								canEdit={canEdit}
							/>
						))}
					{quickLinks.map((quickLink) => (
						<QuickLinkCard
							key={quickLink.id}
							methods={methods}
							quickLink={quickLink}
							canEdit={canEdit}
						/>
					))}
				</Box>
			)}
		</Stack>
	);
};
