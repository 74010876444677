import {
	AccessTimeFilled,
	EditOutlined,
	Facebook,
	LinkedIn,
	LockOutlined,
	Public,
	Star,
} from '@mui/icons-material';
import {
	Box,
	Chip,
	Divider,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Link as MuiLink,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { UserAvatarUploadButton } from 'components/ui/emblem/avatar-upload-button';
import { UserEmblemBanner } from 'components/ui/emblem/emblem-banner';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { TwitterX } from 'components/ui/icons';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalContent,
	ModalLoadingButton,
	useModal,
} from 'components/ui/modal';
import { format } from 'date-fns';
import { DisplayTemporaryLocation, UserProfile } from 'middleware-types';
import { useState } from 'react';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { useIsMobile } from 'utils/useScreenSize';
import { useSiteUser } from 'utils/useSiteUser';
import { zoneDateOnly } from 'utils/utils';
import { useUpdateUserPrivacy, useWorkTypesQuery } from './hooks';

/**
 * Temporary Location Display
 *
 * @param {{location : DisplayTemporaryLocation}} props
 */
const TemporaryLocationDisplay = (props: { location: DisplayTemporaryLocation }) => (
	<Stack direction="row" spacing={1} alignItems="flex-start">
		<Stack pt={0.25}>
			<AccessTimeFilled fontSize="small" color="warning" />
		</Stack>
		<Box>
			{props.location.startDate ? 'Will be' : 'Currently'} in&nbsp;
			<Typography fontWeight="medium" display="inline">
				{props.location.displayLocation}
			</Typography>
			&nbsp;
			{props.location.startDate
				? `from ${format(zoneDateOnly(props.location.startDate), 'MM/dd/yyyy')} to ${format(
						zoneDateOnly(props.location.endDate),
						'MM/dd/yyyy'
				  )}`
				: `until ${format(zoneDateOnly(props.location.endDate), 'MM/dd/yyyy')}`}
		</Box>
	</Stack>
);

/**
 * ProfileHeaderRead - A read only view of the Profile Header
 *
 * @param {ProfileHeaderReadProps} { profile, onBeginEdit }
 */
export const ProfileHeaderRead = (props: {
	profile: UserProfile;
	onBeginEdit: (initialFocusedField?: string) => void;
}) => {
	const { user } = useSession();
	const { profile } = props;
	const { hasPermission } = useSiteUser();
	const { workTypes } = useWorkTypesQuery();
	const isMobile = useIsMobile();

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const { showModal } = useModal();
	const { updatePrivacy } = useUpdateUserPrivacy(profile.id);

	const openChangePrivacyModal = () => {
		const settingToPrivate = !profile.private;
		showModal({
			title: 'Are you sure?',
			content: (
				<ModalContent>
					<ConfirmModalContent
						visual={
							<EmptyStateAvatar
								icon={settingToPrivate ? <LockOutlined /> : <Public />}
							/>
						}
						subheadline={`Set Profile to ${settingToPrivate ? 'Private' : 'Public'}?`}
						informativeContent={
							settingToPrivate
								? 'Your profile will be only be visible to users that are a part of your organization, and have the necessary permissions to view associates.'
								: 'Your profile will be visible to all users on the FileTrac Evolve platform.'
						}
					/>
				</ModalContent>
			),
			actions: (
				<ModalActions>
					<ModalActionButton variant="outlined" size="large">
						Cancel
					</ModalActionButton>
					<ModalLoadingButton
						size="large"
						color="primary"
						variant="contained"
						onClick={async () => await updatePrivacy(settingToPrivate)}>
						Confirm
					</ModalLoadingButton>
				</ModalActions>
			),
		});
	};

	const canEdit =
		props.profile.id == user.userId || hasPermission(Permission.SocialUser_Profile_U);

	const anyLinks =
		Boolean(profile.xProfileLink) ||
		Boolean(profile.linkedinProfileLink) ||
		Boolean(profile.facebookProfileLink) ||
		Boolean(profile.conversationInsuranceProfileLink);

	return (
		<Box>
			<UserEmblemBanner id={props.profile.id} enableUpload={canEdit} />
			<Stack px={{ xs: 2, sm: 2.5 }} py={2} spacing={{ xs: 1, sm: 2 }}>
				<Stack direction="row" justifyContent="space-between">
					<Box position="relative">
						<Box position="absolute" bottom={0} left={0}>
							<UserAvatarUploadButton
								id={profile.id}
								disabled={!canEdit}
								size={isMobile ? 100 : undefined}
							/>
						</Box>
					</Box>
					<Stack direction="row" spacing={1}>
						{profile.xProfileLink && (
							<IconButton href={profile.xProfileLink} target="_blank">
								<TwitterX />
							</IconButton>
						)}
						{profile.linkedinProfileLink && (
							<IconButton href={profile.linkedinProfileLink} target="_blank">
								<LinkedIn />
							</IconButton>
						)}
						{profile.facebookProfileLink && (
							<IconButton href={profile.facebookProfileLink} target="_blank">
								<Facebook />
							</IconButton>
						)}
						{profile.conversationInsuranceProfileLink && (
							<IconButton
								href={profile.conversationInsuranceProfileLink}
								target="_blank">
								<img
									src={'/img/conversation-insurance-color-logo.svg'}
									style={{ width: 24, height: 24 }}
								/>
							</IconButton>
						)}
						{anyLinks && <Divider orientation="vertical" flexItem />}
						{canEdit && (
							<IconButton edge="end" onClick={(e) => setAnchorEl(e.currentTarget)}>
								<EditOutlined />
							</IconButton>
						)}
					</Stack>
				</Stack>
				<Stack spacing={0.5}>
					<Stack direction="row" alignItems="center" spacing={1.5}>
						<Typography variant="h2">
							{profile.name.firstName}{' '}
							{profile.name.middleName && profile.name.middleName}{' '}
							{profile.name.lastName && profile.name.lastName}{' '}
							{profile.name.suffix && profile.name.suffix}
						</Typography>
						{profile.private && <Chip size="small" label="Private" color="error" />}
					</Stack>
					{profile.companyTitle ? (
						<Typography variant="subtitle1">{`${profile.companyTitle} at ${profile.companyName}`}</Typography>
					) : (
						canEdit && (
							<Typography variant="subtitle1">
								<MuiLink
									component={'button'}
									onClick={() => props.onBeginEdit('companyTitle')}>
									Add Current Title...
								</MuiLink>
							</Typography>
						)
					)}
					{profile.displayTemporaryLocation && (
						<Typography variant="subtitle2">
							<TemporaryLocationDisplay location={profile.displayTemporaryLocation} />
						</Typography>
					)}
					<Typography variant="subtitle2">
						<Stack direction="row" alignItems="flex-start" spacing={1}>
							<Stack pt={0.25}>
								<Star color="primary" fontSize="small" />
							</Stack>
							<Box>
								Based in&nbsp;
								<Typography fontWeight="medium" display="inline">
									{profile.displayLocation}
								</Typography>
							</Box>
						</Stack>
					</Typography>
					{profile.workCodes.length > 0 ? (
						workTypes ? (
							<Stack direction="row" spacing={0.5} paddingTop={0.5}>
								{profile.workCodes.map((wc, i) => (
									<Chip key={i} label={workTypes?.get(wc)} size="small" />
								))}
							</Stack>
						) : (
							<Skeleton width={300} height={50} />
						)
					) : (
						canEdit && (
							<Typography variant="subtitle2" color="primary">
								<MuiLink
									component={'button'}
									onClick={() => props.onBeginEdit('workCodes')}>
									Add Work...
								</MuiLink>
							</Typography>
						)
					)}
				</Stack>
				{profile.about && (
					<Stack paddingTop={1} spacing={1}>
						<Typography variant="body1">{profile.about}</Typography>
					</Stack>
				)}
			</Stack>
			{/** edit menu */}
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				onClick={() => setAnchorEl(null)}>
				<MenuItem onClick={() => props.onBeginEdit()}>
					<ListItemIcon>
						<EditOutlined />
					</ListItemIcon>
					<ListItemText>Edit Profile</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => openChangePrivacyModal()}>
					<ListItemIcon>{profile.private ? <Public /> : <LockOutlined />}</ListItemIcon>
					<ListItemText>
						Set Profile to {profile.private ? 'Public' : 'Private'}
					</ListItemText>
				</MenuItem>
			</Menu>
		</Box>
	);
};
