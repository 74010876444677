import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { TextField } from 'components/ui/fields';
import { FileField, FileType, FileValue } from 'components/ui/fields/file';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalContent,
	useModal,
} from 'components/ui/modal';
import { Formik, FormikProps } from 'formik';
import { QuickLink } from 'middleware-types';
import { useState } from 'react';
import { useValidation } from 'utils/useValidation';
import { QuickLinksMethods } from './quickLinks';
import { ErrorOutlined } from '@mui/icons-material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';

interface QuickLinkFormValues {
	title: string;
	url: string;
	icon?: FileValue;
}

export const AddUpdateQuickLinkModal = ({
	methods,
	quickLink,
}: {
	methods: QuickLinksMethods;
	quickLink?: QuickLink;
}) => {
	const { closeModal } = useModal();
	const { addQuickLink, updateQuickLink } = methods;
	const [isUploading, setIsUploading] = useState(false);

	const fileInstance = quickLink?.iconFile?.currentInstance;
	const initialValues: QuickLinkFormValues = {
		title: quickLink?.title ?? '',
		url: quickLink?.url ?? '',
		icon: {
			uploadToken: null,
			id: fileInstance?.id,
			fileName: fileInstance?.fileName,
			fileSize: fileInstance?.fileSize,
			uploadedUtc: fileInstance?.uploadedUtc,
			virusStatus: fileInstance?.virusStatus,
		},
	};

	const onSubmit = async (values: QuickLinkFormValues) => {
		let success = false;
		const uploadToken = values.icon?.uploadToken ?? undefined;
		if (quickLink === undefined) {
			success = await addQuickLink({
				title: values.title,
				url: values.url,
				iconUploadToken: uploadToken,
			});
		} else {
			success = await updateQuickLink(quickLink.id, {
				title: values.title,
				url: values.url,
				clearIconFlag: uploadToken === undefined && values.icon?.id === undefined,
				iconUploadToken: uploadToken,
			});
		}
		if (success) closeModal();
	};

	const validation = useValidation(
		quickLink === undefined ? 'QuickLinkCreate' : 'QuickLinkPatch'
	);

	return (
		<Formik<QuickLinkFormValues>
			onSubmit={onSubmit}
			validationSchema={validation.schema}
			initialValues={initialValues}
			enableReinitialize>
			{(props: FormikProps<QuickLinkFormValues>) => (
				<>
					<ModalContent>
						<Stack spacing={3}>
							<TextField label="Link Title" required name="title" fullWidth />
							<TextField label="URL" required name="url" fullWidth />
							<Stack spacing={0.5}>
								<Typography variant="h4">
									Upload a custom icon for your link. (Optional)
								</Typography>
								<Typography variant="subtitle2">
									For best display, choose an image with a 1:1 ratio (i.e. a
									square shaped image). Avoid overly complex or decorative icons.
								</Typography>
								<FileField
									name="icon"
									type={FileType.Image}
									updatesFileId={quickLink?.iconFileId}
									allowDelete
									setIsUploading={setIsUploading}
								/>
							</Stack>
						</Stack>
					</ModalContent>
					<ModalActions>
						<ModalActionButton variant="outlined">Cancel</ModalActionButton>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							loading={props.isSubmitting || isUploading}
							disabled={
								!props.isValid || !props.dirty || props.isSubmitting || isUploading
							}
							onClick={() => {
								props.submitForm();
							}}>
							Save
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};

export const DeleteQuickLinkModal = ({
	methods,
	quickLinkId,
}: {
	methods: QuickLinksMethods;
	quickLinkId: string;
}) => {
	const { closeModal } = useModal();
	const { deleteQuickLink, deleteLoading } = methods;

	const onSubmit = () => {
		deleteQuickLink(quickLinkId).then((success) => {
			if (!success) return;
			closeModal();
		});
	};

	return (
		<>
			<ConfirmModalContent
				visual={
					<EmptyStateAvatar
						avatarProps={{ bgcolor: 'error.50' }}
						iconProps={{ color: 'error.500' }}
						icon={<ErrorOutlined />}
					/>
				}
				subheadline="Delete Quick Link"
				informativeContent="Do you really want to delete this link? This process cannot be undone."
			/>
			<ModalActions>
				<ModalActionButton size="large" variant="outlined">
					Cancel
				</ModalActionButton>
				<LoadingButton
					size="large"
					variant="contained"
					color="error"
					loading={deleteLoading}
					onClick={onSubmit}>
					Delete
				</LoadingButton>
			</ModalActions>
		</>
	);
};
