import { ModalProps, useModal } from 'components/ui/modal';
import { useCommunicationsContext, CommunicationsProvider } from '../communications-provider';

/**
 * this hook allows a modal to access the info inside the communications provider,
 * since modals exist outside the normal component heirarchy
 */
export const useCommunicationsModal = () => {
	const contextValue = useCommunicationsContext();
	const { showModal: _showModal } = useModal();

	const showModal = ({ content, actions, ...rest }: ModalProps) => {
		_showModal({
			content: content ? (
				<CommunicationsProvider value={contextValue}>{content}</CommunicationsProvider>
			) : (
				content
			),
			actions: actions ? (
				<CommunicationsProvider value={contextValue}>{actions}</CommunicationsProvider>
			) : (
				actions
			),
			...rest,
		});
	};

	return { showModal };
};
