import { Box, Stack, StackProps, Typography } from '@mui/material';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useIsMediumOrSmallerScreen } from 'utils/useScreenSize';

export type NavListItemProps = {
	text: string;
	activeIcon?: React.ReactNode;
	icon?: React.ReactNode;
} & NavLinkProps;

/**
 * NavListItem - A navigation item that sits inside a NavList.
 * This matches on to and exact to render a selected style.
 *
 * @param {{ text: string; to: string; exact?: boolean}} { text, to, exact}
 * @return {*}
 */
export const NavListItem = ({ text, activeIcon, icon, ...navLinkProps }: NavListItemProps) => {
	const isMediumOrSmallerScreen = useIsMediumOrSmallerScreen();

	return (
		<NavLink {...navLinkProps}>
			{({ isActive }) => (
				<Box
					sx={{
						'justifyContent': 'flex-start',
						'marginY': '0.25rem',
						':hover': {
							backgroundColor: 'unset',
						},
					}}>
					{isMediumOrSmallerScreen ? (
						<Typography
							variant="h5"
							sx={{
								pb: '1rem',
								fontWeight: isActive ? '500' : '400',
								color: isActive ? 'neutral.900' : 'neutral.600',
								borderBottom: isActive ? '3px solid' : 0,
								borderColor: (theme) => theme.palette.primary.main,
								mb: isActive ? '-3px' : '',
							}}>
							{text}
						</Typography>
					) : (
						<Stack
							alignItems={'center'}
							padding={1.5}
							flexDirection={'row'}
							gap={1}
							borderRadius={1}
							sx={{
								'backgroundColor': isActive ? 'primary.50' : 'unset',
								':hover': {
									backgroundColor: 'neutral.100',
								},
							}}>
							{isActive ? activeIcon : icon}
							<Typography
								variant="h5"
								sx={{
									fontWeight: isActive ? '500' : '400',
								}}>
								{text}
							</Typography>
						</Stack>
					)}
				</Box>
			)}
		</NavLink>
	);
};

/**
 * NavListProps - the proptypes of a NavList
 * It accepts all of the base Stack props from material and
 * has an optional title.
 *
 * @export
 * @interface NavListProps
 * @extends {StackProps}
 */
export interface NavListProps extends StackProps {
	title?: string;
	sticky?: boolean;
}

/**
 * NavList - A nav list is a navigation tab container.
 * It uses NavListItem's which match on a url and render accordingly.
 *
 * @param {NavListProps} props
 * @return {*}
 */
export const NavList = (props: NavListProps) => {
	const { sticky, ...stackProps } = props;
	return (
		<Stack
			component="nav"
			spacing={1.25}
			alignItems="flex-start"
			position={sticky ? 'sticky' : 'unset'}
			top={sticky ? 0 : 'unset'}
			{...stackProps}>
			{props.title && (
				<Box p="0.5rem" pl={0}>
					<Typography variant="h2">{props.title}</Typography>
				</Box>
			)}
			<Stack spacing={{ xs: 2, md: 'unset' }} direction={{ xs: 'row', md: 'column' }}>
				{props.children}
			</Stack>
		</Stack>
	);
};
