import { FolderReference, SortDirection } from 'middleware-types';
import { useMemo } from 'react';
import { FilesSortColumn } from '../components/sort-options-dropdown';

export const useSortFolders = (
	folders: FolderReference[],
	column: FilesSortColumn,
	direction: SortDirection
) => {
	const sortedFolders = useMemo(() => {
		return [...folders].sort((a, b) => {
			const result = a.name.localeCompare(b.name);
			const sortDescending =
				column === FilesSortColumn.Name && direction === SortDirection.Descending;
			return result * (sortDescending ? -1 : 1);
		});
	}, [folders, column, direction]);
	return sortedFolders;
};
