import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { IconProps } from '@mui/material';
import clsx from 'clsx';

/**
 * The hover button props
 * @extends {LoadingButtonProps}
 */
interface HoverButtonProps extends LoadingButtonProps {
	startIcon?: React.ReactElement<IconProps>;
	hoverStartIcon?: React.ReactElement<IconProps>;
	hoverChildren?: React.ReactNode;
}

/**
 * Hover Button is a HOC of LoadingButton that can display an alternative icon and content on hover
 */
export const HoverButton = (props: HoverButtonProps) => {
	const { startIcon, hoverStartIcon, hoverChildren, className, ...buttonProps } = props;

	return (
		<LoadingButton
			{...buttonProps}
			className={clsx(className, 'group transition-none hover:bg-red-500')}
			startIcon={
				<>
					<span className="flex group-hover:hidden">{startIcon}</span>
					<span className="hidden group-hover:flex">{hoverStartIcon}</span>
				</>
			}>
			<>
				<span className="flex group-hover:hidden">{props.children}</span>
				<span className="hidden group-hover:flex">{hoverChildren}</span>
			</>
		</LoadingButton>
	);
};
