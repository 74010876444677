import { gql, useMutation } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import { Mutation, MutationFileFolderChangeArgs } from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useDocumentSearchContext } from '../../components/document-search-provider';
import { useDocumentsContext } from '../../documents-page';
import { updateFolderContents } from '../../utils/cache-helpers';

const CHANGE_FILE_FOLDER = gql`
	mutation MoveFile($fileId: String!, $request: ChangeFileFolderRequest!) {
		fileFolderChange(fileId: $fileId, request: $request) {
			id
			folder {
				id
			}
		}
	}
`;

export const useMoveFile = () => {
	const toast = useToast();
	const { entityType, entityId } = useDocumentsContext();
	const { searchValue } = useDocumentSearchContext();

	const [_moveFile, { loading }] = useMutation<
		Pick<Mutation, 'fileFolderChange'>,
		MutationFileFolderChangeArgs
	>(CHANGE_FILE_FOLDER);

	const moveFile = async (fileId: string, oldFolderId?: string, newFolderId?: string) => {
		return await _moveFile({
			variables: { fileId, request: { folderId: newFolderId } },
			update: (cache, { data }) => {
				if (!data) return;
				updateFolderContents(
					{ cache, folderId: oldFolderId, entityType, entityId, searchValue },
					(existing) => ({
						...existing,
						files: existing.files?.filter((f) => f.id !== fileId),
					})
				);
			},
			onError: (e) => console.log(JSON.stringify(e)),
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('File moved successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { moveFile, loading };
};
