import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

/**
 * Hook used to follow a user.
 */
export const useFollowUserMutation = (userId: string) => {
	const [followUserMut, { error, loading }] = useMutation(FOLLOW_USER);

	const followUser = async (userOrOrganizationId: string) => {
		return followUserMut({
			variables: {
				userId,
				userOrOrganizationId,
			},
			update: (cache, { data }) => {
				cache.modify({
					id: `UserProfile:${userOrOrganizationId}`,
					fields: {
						following() {
							return data?.socialUserFollow;
						},
					},
				});
			},
		});
	};

	return { followUser, error, loading };
};

/**
 * Mutation to follow a user.
 */
export const FOLLOW_USER = gql`
	mutation socialUserFollow($userId: ID!, $userOrOrganizationId: ID!) {
		socialUserFollow(userId: $userId, userOrOrganizationId: $userOrOrganizationId)
	}
`;

/**
 * Hook used to unfollow a user.
 */
export const useUnfollowUserMutation = (userId: string) => {
	const [unfollowUserMut, { error, loading }] = useMutation(UNFOLLOW_USER);

	const unfollowUser = async (userOrOrganizationId: string) => {
		return unfollowUserMut({
			variables: {
				userId,
				userOrOrganizationId,
			},
			update: (cache, { data }) => {
				cache.modify({
					id: `UserProfile:${userOrOrganizationId}`,
					fields: {
						following() {
							return !data?.socialUserUnfollow;
						},
					},
				});
			},
		});
	};

	return { unfollowUser, error, loading };
};

/**
 * Mutation to unfollow a user.
 */
export const UNFOLLOW_USER = gql`
	mutation socialUserUnfollow($userId: ID!, $userOrOrganizationId: ID!) {
		socialUserUnfollow(userId: $userId, userOrOrganizationId: $userOrOrganizationId)
	}
`;
