import { useSearchParams } from 'react-router-dom';

type UseCurrentFolderIdReturnType = [
	string | undefined,
	(value: string | undefined, clearSearch?: boolean) => void
];

export const useCurrentFolderId = (): UseCurrentFolderIdReturnType => {
	const [searchParams, setSearchParams] = useSearchParams();
	const currentFolderId = searchParams.get('folderId') ?? undefined;
	const setCurrentFolderId = (value: string | undefined, clearSearch: boolean = true) =>
		setSearchParams((params) => {
			if (value) params.set('folderId', value);
			else params.delete('folderId');
			if (clearSearch) params.set('clearSearch', 'true');
			return params;
		});

	return [currentFolderId, setCurrentFolderId];
};
