import { gql, useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	Mutation,
	MutationNotificationSettingsUpdateArgs,
	NotificationSettingsInput,
	Query,
	QueryNotificationSettingForUserArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';

/**
 * notification defaults
 */
const GET_NOTIFICATION_DEFAULTS = gql`
	query GetNotificationDefaults {
		notificationSettingDefaults {
			groupName
			items {
				displayName
				displayOrder
				email
				emailDigestDaily
				emailDigestHourly
				groupName
				inApp
				notificationType
				requiresAtLeastOne
				sms
				description
				allowedTransportsList
			}
		}
	}
`;

export const useNotificationDefaults = () => {
	const { data, loading } =
		useQuery<Pick<Query, 'notificationSettingDefaults'>>(GET_NOTIFICATION_DEFAULTS);

	const defaults = data?.notificationSettingDefaults ?? [];
	return { defaults, loading };
};

/**
 * user notification settings
 */
const GET_USER_NOTIFICATION_SETTINGS = gql`
	query GetUserNotificationSettings($userId: ID!) {
		notificationSettingForUser(userId: $userId) {
			settings {
				email
				emailDigestDaily
				emailDigestHourly
				inApp
				notificationType
				sms
			}
		}
	}
`;

export const useNotificationSettings = (userId: string) => {
	const { data, loading } = useQuery<
		Pick<Query, 'notificationSettingForUser'>,
		QueryNotificationSettingForUserArgs
	>(GET_USER_NOTIFICATION_SETTINGS, {
		variables: { userId },
	});

	const settings = data?.notificationSettingForUser.settings ?? [];
	return { settings, loading };
};

/**
 * updating notification settings
 */
const UPDATE_NOTIFICATION_SETTINGS = gql`
	mutation UpdateNotificationSettings($userId: ID!, $update: NotificationSettingsInput!) {
		notificationSettingsUpdate(userId: $userId, update: $update)
	}
`;

export const useUpdateNotificationSettings = (userId: string) => {
	const toast = useToast();
	const [_updateSettings] = useMutation<
		Pick<Mutation, 'notificationSettingsUpdate'>,
		MutationNotificationSettingsUpdateArgs
	>(UPDATE_NOTIFICATION_SETTINGS, {
		refetchQueries: [GET_USER_NOTIFICATION_SETTINGS],
		awaitRefetchQueries: true,
	});

	const updateSettings = async (update: NotificationSettingsInput) => {
		return await _updateSettings({ variables: { userId, update } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Notification settings updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return updateSettings;
};
