import { Close, Menu } from '@mui/icons-material';
import { Box, Button, Dialog, Divider, Drawer, IconButton, Link, List, Stack } from '@mui/material';
import { EvoX } from 'components/pages/evoxchat/evox';
import { OrgDropDown } from 'components/pages/org-dropdown';
import RootQuickSearch from 'components/pages/root-quick-search';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSession } from 'utils/session';
import { isDemo } from 'utils/utils';
import { EvoxLogoIcon } from '../icons';
import { UserPanel } from '../user-menu';
import { siteAdminLinkInfo, usePrimaryNavLinks } from './hooks';
import { MobilePrimaryNavLink } from './mobile-primary-nav-link';
import { NotificationsPopup } from '../notifications/notifications-popup';

export const PrimaryNavMobile = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	return (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			px={2}
			py={1.5}
			sx={{
				backgroundColor: 'background.paper',
				borderBottom: '1px solid',
				borderBottomColor: 'neutral.200',
			}}>
			<Link href="/" lineHeight={0}>
				<Box component="img" height="2.5rem" src="/img/evolve-logo.svg" alt="FTEvolve" />
			</Link>
			<IconButton onClick={() => setMenuOpen(true)}>
				<Menu />
			</IconButton>
			<Drawer open={menuOpen} anchor="right" PaperProps={{ sx: { width: '100%' } }}>
				<DrawerContent onClose={() => setMenuOpen(false)} />
			</Drawer>
		</Stack>
	);
};

const DrawerContent = ({ onClose }: { onClose: () => void }) => {
	const { user } = useSession();
	const isSiteUser = Boolean(user.siteUserId);

	const [evoxOpen, setEvoxOpen] = useState(false);
	const [notificationsOpen, setNotificationsOpen] = useState(false);

	const links = usePrimaryNavLinks(notificationsOpen, () => setNotificationsOpen(true));

	// close the menu any time we navigate to a new page
	const location = useLocation();
	const storedPathname = useRef(location.pathname);
	useEffect(() => {
		if (location.pathname !== storedPathname.current) onClose();
		storedPathname.current = location.pathname;
	}, [location.pathname]);

	const showSearch = isDemo();
	const showEvoxButton = !isSiteUser;
	const showOrgDropdown = !isSiteUser;

	return (
		<Stack height="100%">
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				px={2}
				py={1.5}>
				<Link href="/" lineHeight={0}>
					<Box
						component="img"
						height="2.5rem"
						src="/img/evolve-logo.svg"
						alt="FTEvolve"
					/>
				</Link>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</Stack>
			<Divider />
			<Stack flex={1} overflow="auto" py={2} spacing={1}>
				{(showSearch || showEvoxButton || showOrgDropdown) && (
					<Stack spacing={1.5} px={2}>
						{showSearch && <RootQuickSearch />}
						{showEvoxButton && (
							<Button
								variant="contained"
								color="primary"
								fullWidth
								startIcon={<EvoxLogoIcon />}
								onClick={() => setEvoxOpen(true)}>
								Chat with EVOX
							</Button>
						)}
						{showOrgDropdown && <OrgDropDown />}
					</Stack>
				)}
				<List disablePadding>
					{links.map((link, index) => (
						<MobilePrimaryNavLink key={index} linkInfo={link} />
					))}
				</List>
				{isSiteUser && (
					<>
						<Box>
							<Divider variant="middle" />
						</Box>
						<List disablePadding>
							<MobilePrimaryNavLink linkInfo={siteAdminLinkInfo} />
						</List>
					</>
				)}
			</Stack>
			<Divider />
			<UserPanel fullWidth />
			{/** EvoX popup */}
			{evoxOpen && (
				<Dialog open={evoxOpen} fullScreen>
					<EvoX onClose={() => setEvoxOpen(false)} />
				</Dialog>
			)}
			{/** Notifications Popup */}
			{notificationsOpen && (
				<Dialog open={notificationsOpen} fullScreen>
					<NotificationsPopup onClose={() => setNotificationsOpen(false)} />
				</Dialog>
			)}
		</Stack>
	);
};
