import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { NavLink as RRNavLink, NavLinkProps } from 'react-router-dom';

/**
 * Pre-styled wrapper for React Router \<NavLink>
 *
 * @param {NavLinkProps} props
 * @return {*}
 */
const NavLink = (props: Omit<NavLinkProps, 'children'> & { children: React.ReactNode }) => {
	return (
		<RRNavLink {...props}>
			{({ isActive }) => (
				<Box
					className={clsx(
						'flex h-full items-center justify-center px-1.5',
						isActive
							? 'border-b-navy-600 border-0 border-b-[3px] border-solid font-medium text-neutral-900'
							: 'text-neutral-600'
					)}>
					<Typography
						noWrap
						variant="h5"
						className={clsx(
							'text-sm font-normal text-neutral-500',
							isActive && 'mb-[-3px] !font-medium !text-neutral-900'
						)}>
						{props.children}
					</Typography>
				</Box>
			)}
		</RRNavLink>
	);
};

export default NavLink;
