import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { TextField } from 'components/ui/fields';
import { Formik, FormikProps } from 'formik';
import { FileInstanceInformation, FileRenameRequest } from 'middleware-types';
import { useValidation } from 'utils/useValidation';
import { useRenameFile } from '../hooks/files/use-rename-file';
import { useEffect, useRef } from 'react';

interface RenameFileModalProps {
	file: FileInstanceInformation;
	onClose: () => void;
}

export const RenameFileModal = ({ file, onClose }: RenameFileModalProps) => {
	const renameFile = useRenameFile();

	const initialValues: FileRenameRequest = {
		fileName: file.fileName,
	};

	const onSubmit = async (values: FileRenameRequest) => {
		const success = await renameFile(file.fileId, values);
		if (success) onClose();
	};

	const validation = useValidation('FileRenameRequest');

	const inputRef = useRef<HTMLInputElement>(null);
	useEffect(() => {
		if (!inputRef.current) return;
		inputRef.current.focus();
		inputRef.current.setSelectionRange(0, file.fileName.lastIndexOf('.'));
	}, []);

	return (
		<Formik<FileRenameRequest>
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validation.schema}>
			{({ isSubmitting, isValid, dirty, submitForm }: FormikProps<FileRenameRequest>) => (
				<Stack>
					<Stack
						px={2}
						py={1}
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={1}>
						<Typography variant="h3">Rename File</Typography>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</Stack>
					<Divider />
					<Stack p={2.5}>
						<TextField
							ref={inputRef}
							required
							name="fileName"
							label="File Name"
							helperText="This will create a new version of the file."
						/>
					</Stack>
					<Divider />
					<Stack direction="row" justifyContent="flex-end" px={2} py={1.5} spacing={1.5}>
						<Button size="large" variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							size="large"
							variant="contained"
							color="primary"
							loading={isSubmitting}
							disabled={!isValid || !dirty}
							onClick={submitForm}>
							Save
						</LoadingButton>
					</Stack>
				</Stack>
			)}
		</Formik>
	);
};
