import {
	BugReport,
	Close,
	DownloadOutlined,
	MoreHoriz,
	RemoveRedEyeOutlined,
	Warning,
} from '@mui/icons-material';
import {
	Avatar,
	Box,
	ButtonBase,
	CircularProgress,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import { filesize } from 'filesize';
import { FileInstanceInformation, VirusStatus } from 'middleware-types';
import { useState } from 'react';
import { ExtensionIcon, downloadFileObject, useDownloadFileInstance } from 'utils/fileUtils';

interface MessageFileChipProps {
	fileData: File | FileInstanceInformation;
	uploadProgress?: number;
	submitting?: boolean;
	onRemove?: () => void;
}

export const MessageFileChip = ({
	fileData,
	uploadProgress = 1,
	submitting,
	onRemove,
}: MessageFileChipProps) => {
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const closeMenu = () => {
		setAnchorEl(null);
	};

	const downloadFileInstance = useDownloadFileInstance();
	const downloadFile = (inline: boolean) => {
		if (fileData instanceof File) {
			downloadFileObject(fileData, inline);
		} else {
			downloadFileInstance(fileData, inline);
		}
	};

	let name = '';
	let size = 0;
	let virusStatus = VirusStatus.Clean;
	if (fileData instanceof File) {
		name = fileData.name;
		size = fileData.size;
	} else {
		name = fileData.fileName;
		size = fileData.fileSize;
		virusStatus = fileData.virusStatus;
	}

	const uploading = uploadProgress !== 1;

	return (
		<>
			<ButtonBase
				component="div"
				disabled={uploading || submitting}
				onClick={() => downloadFile(true)}
				sx={{
					border: '1px solid',
					borderColor: 'neutral.200',
					borderRadius: 1,
					flexShrink: 0,
				}}>
				<Stack direction="row" alignItems="center" spacing={1} p={1}>
					<Avatar>
						<ExtensionIcon filename={name} />
					</Avatar>
					<Box textAlign="left">
						<Typography variant="h6" sx={{ color: 'neutral.700' }}>
							{name}
						</Typography>
						{virusStatus === VirusStatus.Infected ? (
							<Stack direction="row" alignItems="center">
								<BugReport fontSize="small" color="error" sx={{ mb: 0.5 }} />
								<Typography variant="body2" color="error.dark">
									Infected
								</Typography>
							</Stack>
						) : virusStatus === VirusStatus.TooLargeToScan ? (
							<Stack direction="row" alignItems="center">
								<Warning fontSize="small" color="warning" sx={{ mb: 0.5 }} />
								<Typography variant="body2" color="warning.dark">
									Too Large to Scan
								</Typography>
							</Stack>
						) : virusStatus === VirusStatus.ScanFailed ? (
							<Stack direction="row" alignItems="center">
								<Warning fontSize="small" color="warning" sx={{ mb: 0.5 }} />
								<Typography variant="body2" color="warning.dark">
									Scan Failed
								</Typography>
							</Stack>
						) : (
							<Typography variant="body2" sx={{ color: 'neutral.700' }}>
								{filesize(size)}
							</Typography>
						)}
					</Box>
					{uploading ? (
						<Stack height={40} width={40} justifyContent="center" alignItems="center">
							<CircularProgress
								size={30}
								variant="determinate"
								value={uploadProgress * 100}
							/>
						</Stack>
					) : (
						<IconButton
							onClick={(e) => {
								e.stopPropagation();
								setAnchorEl(e.currentTarget);
							}}
							onMouseDown={(e) => e.stopPropagation()}>
							<MoreHoriz />
						</IconButton>
					)}
				</Stack>
			</ButtonBase>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={closeMenu}
				onClick={closeMenu}>
				<MenuItem onClick={() => downloadFile(true)}>
					<ListItemIcon>
						<RemoveRedEyeOutlined />
					</ListItemIcon>
					<ListItemText>View</ListItemText>
				</MenuItem>

				<MenuItem onClick={() => downloadFile(false)}>
					<ListItemIcon>
						<DownloadOutlined />
					</ListItemIcon>
					<ListItemText>Download</ListItemText>
				</MenuItem>
				{onRemove && (
					<MenuItem onClick={onRemove}>
						<ListItemIcon>
							<Close />
						</ListItemIcon>
						<ListItemText>Remove</ListItemText>
					</MenuItem>
				)}
			</Menu>
		</>
	);
};
