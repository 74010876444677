import { Avatar, AvatarProps, Stack, StackProps, SxProps } from '@mui/material';
import React from 'react';
import { EmblemAvatarBaseProps } from './emblem-avatar';

const borderSx: SxProps = {
	border: '2px solid',
	borderColor: 'neutral.50',
	boxSizing: 'content-box',
};

interface EmblemAvatarGroupProps extends StackProps {
	size?: number;
	max?: number;
	variant?: AvatarProps['variant'];
}

const EmblemAvatarGroup = React.forwardRef(
	(
		{ size = 40, max, variant, children, ...stackProps }: EmblemAvatarGroupProps,
		ref: React.ForwardedRef<HTMLDivElement>
	) => {
		const avatars = React.Children.toArray(children);
		const numAvatars = avatars.length;
		const maxAvatars = max ?? numAvatars;
		const numAvatarsToShow = numAvatars > maxAvatars ? maxAvatars - 1 : numAvatars;
		const numHiddenAvatars = numAvatars - numAvatarsToShow;
		const avatarsToShow = avatars.slice(0, numAvatarsToShow);
		return (
			<Stack ref={ref} direction="row" spacing={-1} {...stackProps}>
				{numHiddenAvatars > 0 && (
					<Avatar
						variant={variant}
						sx={{ ...borderSx, height: size, width: size, fontSize: size / 2 }}>
						+{numHiddenAvatars}
					</Avatar>
				)}
				{avatarsToShow.map((avatar) => {
					if (React.isValidElement<EmblemAvatarBaseProps>(avatar)) {
						return React.cloneElement(avatar, {
							size,
							sx: { ...borderSx, ...avatar.props.sx },
						});
					}
				})}
			</Stack>
		);
	}
);

EmblemAvatarGroup.displayName = 'EmblemAvatarGroup';
export { EmblemAvatarGroup };
