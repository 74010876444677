import { HomeOutlined, PinDropOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardContent, Collapse, Grid, Stack, Typography } from '@mui/material';
import {
	AddressField,
	ButtonGroupField,
	ButtonGroupItem,
	DatePickerField,
} from 'components/ui/fields';
import { ModalActionButton, ModalActions, ModalContent, useModal } from 'components/ui/modal';
import { Formik } from 'formik';
/**
 * Leaflet imports.  Leaflet requires it's css be imported at the
 * root level.  Webpack however mangles the default icon image.
 * We correct this here.
 * Reference: https://stackoverflow.com/questions/49441600/react-leaflet-marker-files-not-found
 */
import {
	GeoAddressInput,
	Location,
	LocationAddress,
	LocationAddressUpdate,
} from 'middleware-types';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import { useRef, useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { useUsStates } from 'utils/useCountries';
import { useDefaults } from 'utils/useDefaults';
import { useGeocoderLazy } from 'utils/useGeocode';
import { useValidation } from 'utils/useValidation';
import * as yup from 'yup';

/**
 * The address type for a user account.
 *
 * @export
 * @enum {number}
 */
export enum AddressType {
	Primary = 'Primary',
	Alternate = 'Alternate',
	Temporary = 'Temporary',
}

const DefaultIcon = L.icon({
	iconUrl: icon,
	shadowUrl: iconShadow,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
});
L.Marker.prototype.options.icon = DefaultIcon;

/**
 * Entry point for the Location Address Form.
 * Hook for rendering the Address Input page.
 *
 * @return {*}
 */
export const useAddressInputPage = () => {
	const { showModal } = useModal();

	const showAddressInputPage = (
		onSubmit: LocationAddressSubmit,
		title: string,
		locationAddress?: LocationAddressFormValues
	) => {
		showModal({
			title: `${title} (1/2)`,
			maxWidth: 'lg',
			content: (
				<LocationAddressForm
					onSubmit={onSubmit}
					title={title}
					locationAddress={locationAddress}
				/>
			),
		});
	};
	return { showAddressInputPage };
};

export type LocationAddressFormValues = Partial<Omit<LocationAddressUpdate, 'address'>> & {
	temporaryAddress?: boolean;
	address?: Partial<GeoAddressInput>;
} & Partial<Pick<LocationAddress, 'id' | 'parentId'>>;

/**
 * Hook for editing the Location Address form
 *
 * @param {*} id
 * @param {*} onClose
 * @return {*}
 */
export const useLocationAddressForm = (locationAddress?: LocationAddressFormValues) => {
	const { schema } = useValidation('LocationAddressUpdate');
	const defaults = useDefaults();

	const validationSchema = schema?.shape({
		fromDate: yup
			.date()
			.nullable()
			.test('fromDate', 'Start Date must be before End Date', function (value) {
				const { toDate } = this.parent;
				if (toDate && value && value >= toDate) {
					return false;
				}
				return true;
			})
			.typeError('Please finish inputting your date'),
		toDate: yup
			.date()
			.nullable()
			.test('toDate', 'End Date must be after Start Date', function (value) {
				const { fromDate } = this.parent;
				if (fromDate && value && value <= fromDate) {
					return false;
				}
				return true;
			})
			.typeError('Please finish inputting your date'),
	});

	const initialValues: LocationAddressFormValues = {
		addressType: locationAddress?.addressType ?? AddressType.Alternate,
		temporaryAddress: locationAddress?.fromDate ? true : false,
		fromDate: locationAddress?.fromDate ?? null,
		toDate: locationAddress?.toDate ?? null,
		address: {
			countryId: locationAddress?.address?.countryId ?? defaults.country.id,
			address1: locationAddress?.address?.address1 ?? '',
			address2: locationAddress?.address?.address2 ?? '',
			municipality: locationAddress?.address?.municipality ?? '',
			adminArea1Id: locationAddress?.address?.adminArea1Id ?? '',
			adminArea2Id: locationAddress?.address?.adminArea2Id ?? '',
			postalCode: locationAddress?.address?.postalCode ?? '',
			coordinate: locationAddress?.address?.coordinate,
		},
	};

	return { initialValues, validationSchema };
};

export type LocationAddressSubmit = (
	location: LocationAddressFormValues,
	locationId?: string
) => Promise<boolean>;

type LocationAddressFormProps = {
	onSubmit: LocationAddressSubmit;
	title: string;
	locationAddress?: LocationAddressFormValues;
};

/**
 * Renders the form for adding or updating a new Location Address in an account settings.
 *
 * @param {LocationAddressFormProps} props
 * @return {*}
 */
export const LocationAddressForm = (props: LocationAddressFormProps) => {
	const { initialValues, validationSchema } = useLocationAddressForm(props.locationAddress);
	const { showCoordinateSelectPage } = useCoordinateSelectPage();
	const { geocode, loading } = useGeocoderLazy();

	return (
		<Formik<LocationAddressFormValues>
			initialValues={initialValues}
			// Formik doesnt ever actually submit the form, rather it passes it to CoordinateSelect.
			// The onSubmit function here just makes TypeScript OK.
			onSubmit={(vals) => props.onSubmit(vals)}
			validationSchema={validationSchema}
			enableReinitialize>
			{(fProps) => (
				<>
					<ModalContent>
						{props.locationAddress?.addressType !== AddressType.Primary && (
							<Box mb={2}>
								<Typography>Is this a temporary address?</Typography>
								<ButtonGroupField name="temporaryAddress" exclusive required>
									<ButtonGroupItem value={true}>Yes</ButtonGroupItem>
									<ButtonGroupItem value={false}>No</ButtonGroupItem>
								</ButtonGroupField>
								<Collapse in={fProps.values.temporaryAddress}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={6}>
											<DatePickerField
												label="Start Date"
												name="fromDate"
												required={fProps.values.temporaryAddress}
												onAccept={() => fProps.validateForm()}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<DatePickerField
												label="End Date"
												name="toDate"
												required={fProps.values.temporaryAddress}
												onAccept={() => fProps.validateForm()}
											/>
										</Grid>
									</Grid>
								</Collapse>
							</Box>
						)}

						<AddressField name="address" required />
					</ModalContent>
					<ModalActions>
						<ModalActionButton variant="outlined" disabled={fProps.isSubmitting}>
							Cancel
						</ModalActionButton>
						<LoadingButton
							color="primary"
							variant="contained"
							disabled={!fProps.isValid}
							loading={loading}
							onClick={async () => {
								// Don't overwrite the user's custom coordinates unless they make a change
								if (fProps.dirty) {
									const res = await geocode(
										fProps.values.address as GeoAddressInput
									);
									showCoordinateSelectPage(props.onSubmit, props.title, {
										...fProps.values,
										id: props.locationAddress?.id,
										address: {
											...fProps.values.address,
											coordinate: res.data?.geocode?.location,
										},
									});
									return;
								}
								showCoordinateSelectPage(props.onSubmit, props.title, {
									...fProps.values,
									id: props.locationAddress?.id,
									address: {
										...fProps.values.address,
									},
								});
							}}>
							Next
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};

/**
 * Hook for rendering the Location Select page
 *
 * @return {*}
 */
const useCoordinateSelectPage = () => {
	const { showModal } = useModal();

	const showCoordinateSelectPage = (
		onSubmit: LocationAddressSubmit,
		title: string,
		locationAddress: LocationAddressFormValues
	) => {
		showModal({
			title: `${title} (2/2)`,
			maxWidth: 'lg',
			content: (
				<CoordinateSelect
					onSubmit={onSubmit}
					locationAddress={locationAddress}
					title={title}
				/>
			),
		});
	};

	return { showCoordinateSelectPage };
};

type CoordinateSelectProps = {
	onSubmit: LocationAddressSubmit;
	title: string;
	locationAddress: LocationAddressFormValues;
};

/**
 * Second page of the Location Address Form, allows the user to select a precise coordinate and submit the form.
 *
 * @param {CoordinateSelectProps} props
 * @return {*}
 */
const CoordinateSelect = (props: CoordinateSelectProps) => {
	const { showAddressInputPage } = useAddressInputPage();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [coordinate, setCoordinate] = useState<Location>({
		latitude: props.locationAddress?.address?.coordinate?.latitude ?? 0,
		longitude: props.locationAddress?.address?.coordinate?.longitude ?? 0,
	});
	const { closeModal } = useModal();

	const submit = async (values: LocationAddressFormValues) => {
		const {
			//eslint-disable-next-line
			temporaryAddress = false,
			id = undefined,
			...update
		} = {
			...values,
			addressType: values.temporaryAddress
				? AddressType.Temporary
				: values.addressType === AddressType.Temporary
				? AddressType.Alternate
				: values.addressType,
			fromDate: values.temporaryAddress ? values.fromDate : null,
			toDate: values.temporaryAddress ? values.toDate : null,
		};
		if (!update.address?.coordinate) return false;

		return await props.onSubmit(update, id);
	};

	const handleBackClick = () =>
		showAddressInputPage(props.onSubmit, props.title, props.locationAddress);

	return (
		<>
			<ModalContent>
				<Box position="relative">
					<MapContainer
						center={[coordinate?.latitude ?? 0, coordinate?.longitude ?? 0]}
						zoom={14}
						style={{ height: '65vh', width: '100%' }}>
						<GeolocateMarker
							coordinate={coordinate}
							setCoordinate={setCoordinate}
							draggable={!submitting}
						/>
						<TileLayer
							attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						/>
					</MapContainer>
					<MapInfoDisplay
						coordinate={coordinate}
						address={props.locationAddress?.address as GeoAddressInput}
						resetCoordinate={() => {
							setCoordinate({
								latitude: props.locationAddress?.address?.coordinate?.latitude ?? 0,
								longitude:
									props.locationAddress?.address?.coordinate?.longitude ?? 0,
							});
						}}
					/>
				</Box>
			</ModalContent>
			<ModalActions>
				<>
					<Button variant="outlined" onClick={handleBackClick}>
						Back
					</Button>
					<LoadingButton
						variant="contained"
						color="primary"
						loading={submitting}
						onClick={async () => {
							setSubmitting(true);
							await submit({
								...props.locationAddress,
								address: {
									...props.locationAddress.address,
									coordinate,
								},
							});
							closeModal();
						}}>
						Save Address
					</LoadingButton>
				</>
			</ModalActions>
		</>
	);
};

type GeolocateMarkerProps = {
	coordinate: Location;
	setCoordinate: (coord: Location) => void;
	draggable: boolean;
};

/**
 * Displays the pin on the map that is draggable by the user, updates the coordinates in CoordinateSelect.
 *
 * @param {GeolocateMarkerProps} props
 * @return {*}
 */
const GeolocateMarker = (props: GeolocateMarkerProps) => {
	const markerRef = useRef<L.Marker | null>(null);

	const eventHandlers = {
		drag() {
			const currentCoords = markerRef.current?.getLatLng();
			currentCoords &&
				props.setCoordinate({
					latitude: currentCoords.lat,
					longitude: currentCoords.lng,
				});
		},
	};

	return (
		<Marker
			ref={markerRef}
			eventHandlers={eventHandlers}
			draggable={props.draggable}
			position={{
				lat: props.coordinate.latitude,
				lng: props.coordinate.longitude,
			}}
		/>
	);
};

type MapInfoDisplayProps = {
	coordinate: Location;
	address: GeoAddressInput;
	resetCoordinate: () => void;
};

/**
 * Displays info about the users location and allows them to reset the coordinates.
 *
 * @param {MapInfoDisplayProps} { coordinate, address, resetCoordinate }
 * @return {*}
 */
const MapInfoDisplay = ({ coordinate, address, resetCoordinate }: MapInfoDisplayProps) => {
	const { statesLookupMapById } = useUsStates();

	return (
		<Card
			sx={{
				width: '25%',
				minWidth: 'unset !important',
				zIndex: 999,
				position: 'absolute',
				bottom: '1.5rem',
				left: '1.5rem',
			}}>
			<CardContent>
				<Stack spacing={2}>
					<strong>
						Please confirm that the pin is placed correctly for the address you entered.
					</strong>
					<Typography variant="body1">
						You can drag the pin to reposition it, if needed.
					</Typography>
					<Stack direction="row" alignItems="center" gap={1.5}>
						<HomeOutlined />
						<span>
							{address.address1}, {address.municipality}{' '}
							{address.adminArea1Id &&
								statesLookupMapById.get(address.adminArea1Id)?.displayName}{' '}
							{address.postalCode}
						</span>
					</Stack>
					<Stack direction="row" alignItems="center" gap={1.5}>
						<PinDropOutlined />
						<span>
							{coordinate.latitude.toPrecision(5)},{' '}
							{coordinate.longitude.toPrecision(5)}
						</span>
					</Stack>
					<Button
						color="primary"
						variant="contained"
						onClick={resetCoordinate}
						sx={{ mt: '1rem' }}>
						Reset Pin Location
					</Button>
				</Stack>
			</CardContent>
		</Card>
	);
};
