import { useMutation, useQuery } from '@apollo/client';
import {
	Mutation,
	MutationOrganizationRepresentativeDeleteArgs,
	MutationOrganizationRepresentativeUpsertArgs,
	Query,
	QueryOrganizationAccountArgs,
	RepresentativeType,
	RepresentativeUpdate,
} from 'middleware-types';
import { PHONE_FIELDS } from 'components/ui/fields/phone';
import { gql } from 'graphql-tag';
import { NAME_FIELDS } from 'utils/useAccount';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useToast } from 'components/ui/toast';

/**
 * useOrgRepresentativesQuery() - Hook for loading the org representatives
 *
 * @param {string} orgId
 * @return {*}
 */
export const useOrgRepresentativesQuery = (orgId: string) => {
	const toast = useToast();
	const { data, loading } = useQuery<
		Pick<Query, 'organizationAccount'>,
		QueryOrganizationAccountArgs
	>(ORG_REPRESENTATIVES, {
		variables: { organizationId: orgId },
		onError: () => {
			toast.push('Unable to load org representatives', {
				variant: 'error',
			});
		},
	});

	return {
		representatives: data?.organizationAccount?.representatives
			? [...data.organizationAccount.representatives].sort((a) =>
					a.representativeType.includes(RepresentativeType.Primary) ? -1 : 1
			  )
			: [],
		loading,
	};
};

const ORG_REPRESENTATIVES = gql`
	${NAME_FIELDS}
	${PHONE_FIELDS}
	query OrgRepresentatives($organizationId: ID!) {
		organizationAccount(organizationId: $organizationId) {
			id
			representatives {
				id
				organizationId
				representativeType
				name {
					...NameFields
				}
				title
				emailAddress
				cellPhoneNumber {
					...PhoneFields
				}
				workPhoneNumber {
					...PhoneFields
				}
			}
		}
	}
`;

/**
 * useOrgRepresentativesDeleteMutation() - Hook for deleting an org representative
 *
 * @return {*}
 */
export const useOrgRepresentativesDeleteMutation = () => {
	const toast = useToast();
	const [orgRepresentativeDeleteMutation, { error, loading }] = useMutation<
		Pick<Mutation, 'organizationRepresentativeDelete'>,
		MutationOrganizationRepresentativeDeleteArgs
	>(ORG_REPRESENTATIVE_DELETE);
	const orgRepresentativeDelete = (organizationId: string, representativeId: string) => {
		return orgRepresentativeDeleteMutation({
			variables: { organizationId, representativeId },
			refetchQueries: [{ query: ORG_REPRESENTATIVES, variables: { organizationId } }],
			awaitRefetchQueries: true,
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Representative deleted successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { orgRepresentativeDelete, error, loading };
};

/**
 * useOrgRepresentativesUpsertMutation() - Hook for upserting an org representative
 *
 * @return {*}
 */
export const useOrgRepresentativeUpsertMutation = () => {
	const [orgRepresentativeUpsertMutation, { error, loading }] = useMutation<
		Pick<Mutation, 'organizationRepresentativeUpsert'>,
		MutationOrganizationRepresentativeUpsertArgs
	>(ORG_REPRESENTATIVE_UPSERT);
	const orgRepresentativeUpsert = (
		organizationId: string,
		update: RepresentativeUpdate,
		representativeId?: string
	) => {
		return orgRepresentativeUpsertMutation({
			variables: { organizationId, update, representativeId },
			// When adding a new rep, we need to refetch the full list of reps to update the cache,
			// when doing an update it's unnecessary because apollo will recognize the rep id and update itself accordingly
			refetchQueries: !representativeId
				? [{ query: ORG_REPRESENTATIVES, variables: { organizationId } }]
				: undefined,
			awaitRefetchQueries: true,
		});
	};

	return { orgRepresentativeUpsert, error, loading };
};

const ORG_REPRESENTATIVE_UPSERT = gql`
	${NAME_FIELDS}
	${PHONE_FIELDS}
	mutation organizationRepresentativeUpsert(
		$organizationId: ID!
		$representativeId: ID
		$update: RepresentativeUpdate!
	) {
		organizationRepresentativeUpsert(
			organizationId: $organizationId
			representativeId: $representativeId
			update: $update
		) {
			id
			organizationId
			representativeType
			name {
				...NameFields
			}
			title
			emailAddress
			cellPhoneNumber {
				...PhoneFields
			}
			workPhoneNumber {
				...PhoneFields
			}
		}
	}
`;

const ORG_REPRESENTATIVE_DELETE = gql`
	mutation organizationRepresentativeDelete($organizationId: ID!, $representativeId: ID!) {
		organizationRepresentativeDelete(
			organizationId: $organizationId
			representativeId: $representativeId
		)
	}
`;
