import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
	Mutation,
	MutationOrganizationRoleCreateArgs,
	MutationOrganizationRoleUpdateArgs,
	OrganizationRoleUpdate,
	Query,
	QueryRoleArgs,
} from 'middleware-types';
import { PageError } from 'utils/errors';

/**
 * Query to pull the org permissions and categories
 */
export const ORGROLELOOKUP = gql`
	query roleLookup {
		roleCategories {
			id
			name
		}
		permissionCategories {
			categories {
				name
				displayOrder
				permissionGroups {
					name
					description
					displayOrder
					permissions {
						operationType
						permissionKey
					}
				}
			}
		}
	}
`;

/**
 * useOrgRoleCategoriesQuery() - Pulls the lookup data for org role categories.
 *
 * @return {*}
 */
export const useOrgRoleCategoriesQuery = () => {
	const { loading, data, error } =
		useQuery<Pick<Query, 'roleCategories' | 'permissionCategories'>>(ORGROLELOOKUP);
	if (error) {
		throw new PageError([error]);
	}

	return {
		loading,
		roleCategories: data?.roleCategories ?? [],
		permissionCategories: data?.permissionCategories?.categories ?? [],
	};
};

/**
 * Query to pull the provided org and role's settings.
 */
export const ORGROLE = gql`
	query role($organizationId: ID!, $roleId: ID!) {
		role(organizationId: $organizationId, roleId: $roleId) {
			id
			name
			isAdminRole
			permissionKeys
			category {
				id
			}
		}
	}
`;

/**
 * useOrgRoleQuery(orgId, roleId) - Hook to pull the provided org and role's settings.
 *
 * @param {string} organizationId
 * @param {(string | undefined)} roleId
 * @return {*}
 */
export const useOrgRoleQuery = (organizationId: string, roleId: string | undefined) => {
	const { loading, data, error } = useQuery<Pick<Query, 'role'>, QueryRoleArgs>(ORGROLE, {
		fetchPolicy: 'cache-and-network',
		variables: {
			organizationId,
			roleId: roleId as string,
		},
		skip: !roleId,
	});

	if (error) {
		throw new PageError([error]);
	}

	return {
		loading,
		role: data?.role,
	};
};

/* Mutation for adding a site role. */
export const ADD_ORGROLE = gql`
	mutation AddOrgRole($organizationId: ID!, $role: OrganizationRoleUpdate!) {
		organizationRoleCreate(organizationId: $organizationId, role: $role) {
			id
			organizationId
			category {
				id
				name
			}
			name
			isAdminRole
			permissionKeys
			associateCount
		}
	}
`;

export const UPDATE_ORGROLE = gql`
	mutation UpdateOrgRole($organizationId: ID!, $roleId: ID!, $role: OrganizationRoleUpdate!) {
		organizationRoleUpdate(organizationId: $organizationId, roleId: $roleId, role: $role) {
			id
			organizationId
			category {
				id
				name
			}
			name
			isAdminRole
			permissionKeys
			associateCount
		}
	}
`;

/**
 * useOrgRoleMutation(orgId) - Send an invitation to an associate.
 *
 * @param {string} organizationId
 * @return {*}
 */
export const useOrgRoleMutation = (organizationId: string, roleId: string | undefined) => {
	const [addRole] = useMutation<
		Pick<Mutation, 'organizationRoleCreate'>,
		MutationOrganizationRoleCreateArgs
	>(ADD_ORGROLE);
	const [updateRole] = useMutation<
		Pick<Mutation, 'organizationRoleUpdate'>,
		MutationOrganizationRoleUpdateArgs
	>(UPDATE_ORGROLE);
	const upsertRole = async (values: OrganizationRoleUpdate) => {
		if (roleId) {
			return updateRole({
				variables: {
					roleId,
					organizationId,
					role: values,
				},
			});
		}

		return addRole({
			variables: {
				organizationId,
				role: values,
			},
		});
	};

	return { upsertRole };
};
