import * as Icons from '@mui/icons-material';
import { FormikErrors } from 'formik';
import { CalloutUpdate, OrganizationProfile, OrganizationProfileUpdate } from 'middleware-types';

/** types */
export interface IconOption {
	name: string;
	Icon: Icons.SvgIconComponent;
}

export interface CalloutFormFields {
	icon: IconOption | null;
	color: string;
	text: string;
}

export const emptyCalloutFields: CalloutFormFields = {
	icon: null,
	color: '',
	text: '',
};

export interface OrgHeaderFormFields {
	displayName: string;
	tagline: string;
	xProfileLink: string;
	linkedinProfileLink: string;
	facebookProfileLink: string;
	conversationInsuranceProfileLink: string;
	callout1: CalloutFormFields;
	callout2: CalloutFormFields;
	callout3: CalloutFormFields;
}

/** convert from MW object to form values */
export const orgHeaderToForm = (profile: OrganizationProfile): OrgHeaderFormFields => ({
	displayName: profile.displayName,
	tagline: profile.tagline ?? '',
	xProfileLink: profile.xProfileLink ?? '',
	linkedinProfileLink: profile.linkedinProfileLink ?? '',
	facebookProfileLink: profile.facebookProfileLink ?? '',
	conversationInsuranceProfileLink: profile.conversationInsuranceProfileLink ?? '',
	callout1: calloutToForm(profile.callouts, 0),
	callout2: calloutToForm(profile.callouts, 1),
	callout3: calloutToForm(profile.callouts, 2),
});

export const calloutToForm = (
	callouts: OrganizationProfile['callouts'],
	index: number
): CalloutFormFields => {
	const callout = callouts?.[index];
	if (!callout || !callout.icon || !callout.color || !callout.text) return emptyCalloutFields;

	return {
		color: callout.color,
		text: callout.text,
		icon: {
			name: callout.icon,
			Icon: Icons[callout.icon],
		},
	};
};

/** convert from form values to update object */
export const formToOrgHeader = (values: OrgHeaderFormFields): OrganizationProfileUpdate => {
	const {
		callout1,
		callout2,
		callout3,
		xProfileLink,
		linkedinProfileLink,
		facebookProfileLink,
		conversationInsuranceProfileLink,
		...rest
	} = values;
	const callouts: CalloutUpdate[] = [];
	[callout1, callout2, callout3].forEach((callout) => {
		const calloutUpdate = formToCallout(callout);
		if (calloutUpdate) callouts.push(calloutUpdate);
	});
	return {
		...rest,
		callouts,
		xProfileLink: xProfileLink || undefined,
		linkedinProfileLink: linkedinProfileLink || undefined,
		facebookProfileLink: facebookProfileLink || undefined,
		conversationInsuranceProfileLink: conversationInsuranceProfileLink || undefined,
	};
};

export const formToCallout = (callout: CalloutFormFields): CalloutUpdate | null => {
	if (!callout.icon || !callout.color || !callout.text) return null;
	return {
		icon: callout.icon.name,
		color: callout.color,
		text: callout.text,
	};
};

/** validation */
export const validateOrgHeaderForm = (values: OrgHeaderFormFields) => {
	const errors: FormikErrors<OrgHeaderFormFields> = {};
	['callout1', 'callout2', 'callout3'].forEach((key) => {
		const callout: CalloutFormFields = values[key];
		if (callout.text.length > 30) errors[key] = { text: 'Cannot exceed 30 characters' };
		const anyFilled = callout.icon || callout.color || callout.text;
		const allFilled = callout.icon && callout.color && callout.text;
		if (allFilled || !anyFilled) return;
		if (!errors[key]) errors[key] = {};
		if (!callout.icon) errors[key].icon = 'Required Field';
		if (!callout.color) errors[key].color = 'Required Field';
		if (!callout.text) errors[key].text = 'Required Field';
	});
	return errors;
};
