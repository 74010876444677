import { gql, useQuery } from '@apollo/client';
import { EntitySearchType, Query, QueryParticipantSearchArgs } from 'middleware-types';
import { useSession } from './session';

export const SINGLE_PARTICIPANT_SEARCH = gql`
	query SingleParticipantSearch(
		$entityType: EntitySearchType!
		$entityId: String!
		$query: String
		$limit: Float!
	) {
		participantSearch(
			query: $query
			limit: $limit
			entityType: $entityType
			entityId: $entityId
		) {
			id
			handle
		}
	}
`;

export const useCanMessage = (handle: string | undefined, skip = false) => {
	const { user } = useSession();
	const { data, loading } = useQuery<
		Pick<Query, 'participantSearch'>,
		QueryParticipantSearchArgs
	>(SINGLE_PARTICIPANT_SEARCH, {
		skip: !handle || skip,
		fetchPolicy: 'no-cache',
		variables: {
			entityType: EntitySearchType.User,
			entityId: user.userId,
			query: handle,
			limit: 1,
		},
	});

	const participant = data?.participantSearch?.[0];
	return !loading && participant && participant.handle === handle;
};
